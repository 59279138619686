import { firstValueFrom, isObservable, Observable } from 'rxjs';

/**
 * Creates an awaitable delay base on an input time interval
 * @param ms time to delay in milliseconds
 */
export async function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

declare const Zone: any;

/**
 * Waits for some function to complete inside angular, can be used to wait with component load
 * @param prom promise or observable
 */
export async function waitFor<T>(prom: Promise<T> | Observable<T>): Promise<T> {
  if (isObservable(prom)) {
    prom = firstValueFrom(prom);
  }
  const macroTask = Zone.current.scheduleMacroTask(
    `WAITFOR-${Math.random()}`,
    () => {},
    {},
    () => {},
  );
  return prom.then((p: T) => {
    macroTask.invoke();
    return p;
  });
}

/**
 * To process Observables synchronously
 * @param observable
 * @returns
 */
export async function syncObservableHandler<T>(observable: Observable<T>): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    observable.subscribe({
      next: (value) => {
        resolve(value);
      },
      error: (error) => {
        reject(error);
      },
    });
  });
}

/**
 * To process Promises synchronously
 * @param promise
 * @returns
 */
export async function syncPromiseHandler<T>(promise: Promise<T>): Promise<T> {
  try {
    const result = await promise;
    return result;
  } catch (error) {
    throw error;
  }
}
