import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccountService } from '@/data/src/lib/services/account.service';
import { ApplicationService } from '@/view/src/app/app.service';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { XRScene, XRSceneInteraction } from '@/data/src/lib/models/data/scene';
import { SceneInteractionType } from '@/data/src/lib/enums/scene-interaction';
import { Account } from '@/data/src/lib/models/data/account';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { MobileGuestbookCommentComponent } from './m-guestbook-comment/m-guestbook-comment.component';
import { TInteractableElement } from '@/data/src/lib/view-manager';
import { MobileConfirmationComponent } from '@/ui/src/lib/modal/mobile/m-confirmation/m-confirmation.component';

@UntilDestroy()
@Component({
  selector: 'ui-m-guestbook-tool',
  templateUrl: './m-guestbook-tool.component.html',
  styleUrls: ['./m-guestbook-tool.component.scss'],
  providers: [ModalService],
})
export class MobileGuestbookToolComponent implements OnInit {
  modalRef: ModalService;

  isEmojiSetOpen = false;

  selectedSceneId = '';
  selectedScene: XRScene | undefined;
  activeAccount: Account | undefined = this._accountService.account;
  selectedElement: TInteractableElement;
  interactions!: XRSceneInteraction[];

  newComment: string = '';
  selectedEmoji: string = '';

  data: { [key: string]: string };

  get happyCount(): number {
    return this.interactions?.filter((x) => x.Interaction.Emoji === 'happy')?.length ?? 0;
  }

  get surpriseCount(): number {
    return this.interactions?.filter((x) => x.Interaction.Emoji === 'surprise')?.length ?? 0;
  }

  get congratsCount(): number {
    return this.interactions?.filter((x) => x.Interaction.Emoji === 'congrats')?.length ?? 0;
  }

  get sadCount(): number {
    return this.interactions?.filter((x) => x.Interaction.Emoji === 'sad')?.length ?? 0;
  }

  get awesomeCount(): number {
    return this.interactions?.filter((x) => x.Interaction.Emoji === 'awesome')?.length ?? 0;
  }

  get isEnterpriseOrAdmin() {
    return this._accountService.isEnterpriseOrAdmin;
  }

  constructor(
    private _modalService: ModalService,
    private _appService: ApplicationService,
    private _sceneService: SceneService,
    private _accountService: AccountService,
    private _translateService: TranslateService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.selectedSceneId) {
      this.selectedScene = await this._sceneService.getById(this.selectedSceneId);
      this.updateInteractions();
    }

    this._appService.viewElementsSubject.pipe(untilDestroyed(this)).subscribe((elements) => {
      this.selectedElement = elements.find(({ id }) => id === this.selectedElement?.id) as TInteractableElement;
    });
  }

  modalClose(result: boolean) {
    this.modalRef.close(result);
  }

  private async updateInteractions() {
    if (!this.selectedElement) {
      return;
    }
    this.interactions = (
      await this._sceneService.getInteractions(this.selectedSceneId, SceneInteractionType.Comment, this.selectedElement.id)
    )
      .filter((e) => !e.Username.includes('Removed@'))
      .sort((a, b) => (a.Date < b.Date ? -1 : 1));

    if (this.data['access'] === 'private') {
      if (this.activeAccount) {
        if (!this.isEnterpriseOrAdmin) {
          return;
        }

        if (this.activeAccount.Id !== this.selectedScene?.CreatorUser?.Id) {
          this.interactions = this.interactions.filter((x) => x.Username === this.activeAccount?.Username);
        }
      } else {
        this.interactions = [];
      }
    }
  }

  onSelectionChanged(emoji: string) {
    this.selectedEmoji = emoji;
    this.isEmojiSetOpen = !this.isEmojiSetOpen;

    if (this.newComment) {
      return;
    } else {
      this.openPopup();
    }
  }

  openPopup() {
    const modalRef = this._modalService.open(MobileGuestbookCommentComponent, { closeOnBackdropClick: false, closeOnDestroy: false });

    if (modalRef) {
      modalRef.instance.modalRef = this._modalService;
      modalRef.instance.data = this.data;
      modalRef.instance.newComment = this.newComment;
      modalRef.result.then(async (res) => {
        if (res) {
          this.newComment = modalRef.instance.newComment;
        }
      });
    }
  }

  async addComment() {
    if (this.newComment) {
      const interaction = new XRSceneInteraction(this.selectedSceneId, this.selectedElement.id, SceneInteractionType.Comment, {
        Comment: await this._sceneService.CheckCommentOfInteraction(this.newComment),
        Emoji: this.selectedEmoji,
      });
      this.newComment = '';
      this.selectedEmoji = '';
      await this._sceneService.postInteraction(interaction);
      await this.updateInteractions();
    }
  }

  async deleteComment(interactionId: string) {
    const message = this._modalService.open(MobileConfirmationComponent);
    message.instance.body = this._translateService.instant('shared.confirmation.deletionConfirmationMessage');
    message.instance.confirmAction = 'shared.confirmation.confirm';
    message.instance.cancelAction = 'shared.confirmation.cancel';

    if (await message.result) {
      const array: string[] = [];
      array.push(interactionId);
      await this._sceneService.deleteInteraction(array);
      await this.updateInteractions();
    }
  }
}
