import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable, of } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { IModel, ModelType } from '../models/data/base';
import { privateFieldReplacer } from '../utils/json-utils';
import { newModel } from '../factories/model-factory';
import { AccountService } from './account.service';
import { environment } from 'projects/app/src/environments/environment';

export class PostResponseOptions {
  public headers?: HttpHeaders | { [header: string]: string | string[] } | undefined;
  public observe: 'response' = 'response';
  public params?: HttpParams | { [param: string]: string | string[] } | undefined;
  public reportProgress?: boolean | undefined;
  public responseType?: 'json' | undefined;
  public withCredentials?: boolean | undefined;
}

export const RETRY_COUNT = 3;

export enum State {
  Attached,
  Removed,
  Modified,
  Added,
}

export class ModelState<T extends IModel> {
  constructor(
    public data: T,
    public state: State,
  ) {}

  get Id() {
    return this.data.Id;
  }
}

export interface IApiService {
  /**
   * Attaches an array of data to the local data cache and updates the behaviour subject
   * @param data
   */
  set(model: IModel[]): void;

  /**
   * Attaches a model to the local data cache
   * @param model
   */
  attach(model: IModel): void;

  /**
   * Detaches a model from the local data cache
   * @param model
   */
  detach(model: IModel): void;

  /**
   * Adds a model to the local data cache and updates the state for when data is commited to the backend
   * @param model
   */
  add(model: IModel): void;

  /**
   * Removes a model from the local data cache and updates the state for when data is commited to the backend
   * @param model
   */
  remove(model: IModel): void;

  /**
   * Updates the model state for when data is commited to the backend
   * @param model
   */
  modify(model: IModel): void;

  /**
   * Get a model by it's Id, checks if the item is in the local cache otherwise fetches it from the backend and then adds it to the cache
   * @param id Id of model to get
   * @param forceFromServer true if you want to ignore the local cache and get from the server
   * @param cachedOnly true to only check the local cache for the model
   * @returns the model
   */
  getById(id: string, forceServer?: boolean, cached?: boolean): Promise<IModel | undefined>;

  /**
   * Gets all available models for the active user
   * @param cached true if you only want to get cached models
   * @returns a list of models
   */
  getAll(cached?: boolean): Promise<IModel[]>;

  /**
   * Gets all cached data
   * @returns
   */
  getCached(): IModel[];

  /**
   * Gets a cached item by id, alternative to GetById which has to be called async
   * @param id Id of model to get
   * @returns the model
   */
  getCachedById(id: string): IModel | undefined;

  /**
   * Set the selected subject by the model id
   * @param id
   */
  selectById(id: string): void;

  /**
   * Set the into model as selected and notify observers
   * @param obj
   */
  select(model: IModel | undefined): void;

  /**
   * Commits all added, removed and modified data to the backend
   */
  commit(): Promise<void>;
}

/**
 * Abstract base class for local and backend CRUD operations. Tracks model state, can notify observers of data changes and model selection
 */
@Injectable({
  providedIn: 'root',
})
export class BaseApiService<T extends IModel> implements IApiService {
  private queryMap: Map<string, Promise<T[] | undefined>> = new Map<string, Promise<T[] | undefined>>();
  protected endPoint!: string;

  protected dataSubject = new BehaviorSubject<T[]>([]);
  data$: Observable<T[]> = this.dataSubject.asObservable();

  protected modelState: Map<string, ModelState<T>> = new Map<string, ModelState<T>>();

  protected selectedSubject = new BehaviorSubject<T | undefined>(undefined);
  selectedItem$: Observable<T | undefined> = this.selectedSubject.asObservable();

  previousLoad: string | string[] = 'None';
  protected httpHeaders: HttpHeaders;

  protected postOptions = {
    headers: { 'Content-Type': 'application/json' },
    // observe: "response",
    // responseType: "json"
  };

  constructor(
    public http: HttpClient,
    protected accountService: AccountService,
    @Inject(ModelType) public modelType: ModelType,
  ) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.endPoint = `${environment[this.accountService.region].crudApiUrl}/${this.modelType}`;
  }

  selectById(id: string) {
    this.selectedSubject.next(this.getCachedById(id));
  }

  getSelected() {
    return this.selectedSubject.value;
  }

  getCachedById(id: string): T | undefined {
    return this.modelState.get(id)?.data;
  }

  getCached(): T[] {
    return this.dataSubject.value;
  }

  select(obj: T | undefined) {
    this.selectedSubject.next(obj);
  }

  set(data: T[]) {
    data = data.map((a) => newModel(a, this.modelType));
    data.forEach((i) => this.attach(i));

    this.updateDataSubject(data);
  }

  async getAll(cached?: boolean): Promise<T[]> {
    if (cached) {
      return this.dataSubject.getValue();
    } else {
      return await this.get();
    }
  }

  async getById(id: string, forceFromServer = false, cachedOnly = false): Promise<T | undefined> {
    if (id) {
      const result = this.modelState.get(id);
      if (!forceFromServer && result) {
        this.selectedSubject.next(result.data);
        return result.data;
      } else if (!cachedOnly && !this.accountService.trialOnly) {
        return lastValueFrom(
          this.http.get<T>(`${environment[this.accountService.region].crudApiUrl}/${this.modelType}/${id}`).pipe(
            retry(RETRY_COUNT),
            map((data) => {
              if (data) {
                data = newModel(data, this.modelType) as T;
                this.attach(data);

                this.updateDataSubject(data);
                this.selectedSubject.next(data);
              }
              return data;
            }),
          ),
        ).catch(() => {
          return undefined;
        });
      }
    }
    return undefined;
  }

  /**
   * Updates the data subject which notifies observers, adds/removes new data, replaces existing data
   * @param data data to update
   * @param remove true if the input data is to be removed
   */
  updateDataSubject(data: T | T[], remove = false, clearOldData = false) {
    let selections = this.dataSubject.getValue();

    if (clearOldData) {
      selections.length = 0;
    }

    if (!Array.isArray(data)) {
      data = [data];
    }
    data.forEach((item) => {
      if (item) {
        const selIndex = item.Id ? selections.findIndex((x) => x.Id === item.Id) : -1;
        if (remove) {
          if (selIndex >= 0) {
            selections.splice(selIndex, 1);
          }
        } else {
          if (selIndex >= 0) {
            selections[selIndex] = item;
          } else {
            selections.unshift(item);
          }
        }
      }
    });
    this.dataSubject.next([...selections]);
  }

  attach(model: T) {
    if (model) {
      const currentState = this.modelState.get(model.Id);
      if (!currentState) {
        this.modelState.set(model.Id, new ModelState(model, State.Attached));
      } else {
        currentState.data = model;
      }
    }
  }

  detach(model: T) {
    model?.Id && this.modelState.delete(model.Id);
    this.updateDataSubject(model, true);
  }

  reset() {
    this.dataSubject.next([]);
  }

  add(model: T) {
    const currentState = this.modelState.get(model.Id);

    if (!currentState) {
      this.modelState.set(model.Id, new ModelState(model, State.Added));
    } else if (currentState.state < State.Modified) {
      this.modelState.set(model.Id, new ModelState(model, State.Modified));
    }
    this.updateDataSubject(model);
  }

  remove(model: T) {
    const currentState = this.modelState.get(model.Id);
    if (currentState && currentState?.state !== State.Added) {
      this.modelState.set(model.Id, new ModelState(model, State.Removed));
    } else if (currentState) {
      this.modelState.delete(model.Id);
    }
    this.updateDataSubject(model, true);
  }

  modify(model: T) {
    const currentState = this.modelState.get(model.Id);
    if (!currentState) {
      this.modelState.set(model.Id, new ModelState(model, State.Added));
    } else if (currentState.state < State.Modified) {
      this.modelState.set(model.Id, new ModelState(model, State.Modified));
    }
    this.updateDataSubject(model);
  }

  async commit() {
    await this.post(
      Array.from(this.modelState.values())
        .filter((s) => s.state == State.Added)
        .map((s) => s.data),
    );
    await this.delete(
      Array.from(this.modelState.values())
        .filter((s) => s.state == State.Removed)
        .map((s) => s.data),
    );
    await this.put(
      Array.from(this.modelState.values())
        .filter((s) => s.state == State.Modified)
        .map((s) => s.data),
    );

    for (const state of this.modelState.values()) {
      state.state = State.Attached;
    }
  }

  async get(path?: string, query = '', noAuthHttp?: HttpClient, ignoreExistingQuery = false, clearOldData = false): Promise<T[]> {
    const load = path + query;
    const existingQuery = this.queryMap.get(load);
    if (!existingQuery || ignoreExistingQuery) {
      this.previousLoad = load;
      const httpClient = noAuthHttp ?? this.http;
      const http = httpClient.get<T[]>(`${this.endPoint}${path ? '/' + path : ''}${query ? '?' + query : ''}`).pipe(
        retry(RETRY_COUNT),
        map((data) => {
          if (data && data.length) {
            data = data.map((item) => {
              {
                const newItem = newModel(item, this.modelType);
                this.attach(newItem);
                return newItem;
              }
            });
            this.updateDataSubject(data, false, clearOldData);

            return data.slice(0);
          } else {
            this.dataSubject.next([]);
            return [];
          }
        }),
        catchError(async () => {
          this.dataSubject.next([]);
          return [];
        }),
      );
      const promise = lastValueFrom(http);
      this.queryMap.set(load, promise);
      return await promise;
    } else {
      return (await existingQuery) ?? [];
    }
  }

  /**
   * Post data to the service endpoint and updates the behaviour subject which notifies observers
   * @param data Data to post
   * @returns Post result
   */
  async post(data: T | T[]) {
    if (data && (await this.accountService.getActiveAccount())) {
      if (!Array.isArray(data)) {
        data = [data];
      }
      if (data.length > 0) {
        return await this.http
          .post(`${this.endPoint}`, JSON.stringify(data, privateFieldReplacer), this.postOptions)
          .toPromise()
          .then((result) => {
            this.updateDataSubject(data);
            return result;
          })
          .catch(() => {
            return undefined;
          });
      }
    }
    return undefined;
  }

  /**
   * Update data to the service endpoint and updates the behaviour subject which notifies observers
   * @param data Data to post
   * @returns Put result
   */
  async put(data: T | T[]) {
    await this.accountService.getActiveAccount();
    if (data) {
      if (!Array.isArray(data)) {
        data = [data];
      }
      if (data.length > 0) {
        return await this.http
          .put(`${this.endPoint}`, JSON.stringify(data, privateFieldReplacer), this.postOptions)
          .toPromise()
          .then((result) => {
            this.updateDataSubject(data);
            return result;
          })
          .catch(() => {
            return undefined;
          });
      }
    }
    return undefined;
  }

  /**
   * Deletes data from the service endpoint and updates the behaviour subject which notifies observers
   * @param data Data to post
   * @returns Put result
   */
  async delete(data: T | T[], removeItem = true) {
    if (!Array.isArray(data)) {
      data = [data];
    }
    if (data.length > 0) {
      await lastValueFrom(
        this.http.post(`${this.endPoint}/Delete`, JSON.stringify(data.map((d) => d.Id)), this.postOptions).pipe(
          catchError(this.catchError),
          tap(() => this.updateDataSubject(data, removeItem)),
        ),
      );
    }
  }

  /**
   * Error function called when http request fails
   * @param err
   * @param obs
   * @returns
   */
  catchError(err: any, obs: any) {
    return of(undefined);
  }
}
