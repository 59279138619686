import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModelType } from '../models/data/base';
import { BaseApiService } from './base-api.service';
import { AccountService } from './account.service';
import { XRNotification } from '../models/data/notification';

/**
 * Tracks and manages the state of category models and commits changes to the backend
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseApiService<XRNotification> {
  constructor(public http: HttpClient, accountService: AccountService) {
    super(http, accountService, ModelType.Notification);
  }

  async setAsViewed(notifications: XRNotification[]) {
    if (notifications.length > 0) {
      await this.http
        .post(`${this.endPoint}/SetAsSeen`, JSON.stringify(notifications.map((n) => n.Id)), this.postOptions)
        .toPromise()
        .then((result) => {
          notifications.forEach((n) => (n.HasBeenViewed = true));
          this.updateDataSubject(notifications);
          return result;
        })
        .catch(() => {});
    }
  }
}
