import { v4 as uuid } from 'uuid';
import { Color3, Material, Nullable, PBRMaterial, StandardMaterial } from '../../babylon';
import { deepCopy } from '../../utils/data';
import { ViewManagerUtils } from '../../utils/view-manager-utils';
import { ObjectRenderable } from '../renderables';
import { TCustomization } from '../types';

export class Customization {
  public descriptor: TCustomization;

  private materials: { [origin: string]: Nullable<Material> } = {};
  private originalMaterials: { [origin: string]: Nullable<Material> } = {};

  meshToOriginalMaterial: { [uniqueId: string]: Nullable<Material> } = {};

  _isSubjectPbr = false;
  _isSubjectStd = false;
  _subjectPbr: Nullable<PBRMaterial>;
  _originalPbr: Nullable<PBRMaterial>;
  _subjectStd: Nullable<StandardMaterial>;
  _originalStd: Nullable<StandardMaterial>;

  subjectDiffuse: Nullable<Color3>;
  subjectEmissive: Nullable<Color3>;
  subjectAmbient: Nullable<Color3>;
  originalDiffuse: Nullable<Color3>;
  originalEmissive: Nullable<Color3>;
  originalAmbient: Nullable<Color3>;

  get viewManager() {
    return this.renderable.viewManager;
  }

  get scene() {
    return this.viewManager.scene;
  }

  constructor(
    private renderable: ObjectRenderable,
    descriptor: TCustomization,
  ) {
    this.update = this.update.bind(this);
    this.setup = this.setup.bind(this);
    this.setup(renderable, descriptor);
  }

  setup(renderable: ObjectRenderable, descriptor: TCustomization) {
    renderable.helper
      ?.getChildMeshes(false)
      .concat(renderable.helper)
      .forEach((mesh) => {
        if (!mesh.material) {
          return;
        }
        const materialId = this.meshToOriginalMaterial[mesh.uniqueId]?.id || mesh.material.id;
        if (!this.originalMaterials[materialId]) {
          this.originalMaterials[materialId] = mesh.material;
        }
        if (!this.materials[materialId]) {
          this.materials[materialId] = mesh.material.clone(uuid());
          ViewManagerUtils.AttachMetadata(this.materials[materialId]!, { helperOf: renderable, origin: mesh.material });
        }
        this.meshToOriginalMaterial[mesh.uniqueId] = this.originalMaterials[materialId];
        mesh.material = this.materials[materialId];
      });

    this.update(renderable, descriptor);
  }

  update(renderable: ObjectRenderable, descriptor: TCustomization) {
    this.descriptor = deepCopy(descriptor);

    // TODO: Restore previous to default values
    this.descriptor.materials &&
      Object.entries(this.descriptor.materials).forEach(
        ([
          materialId,
          {
            parameters: { diffuseColor, emissiveColor, ambientColor, unlit },
          },
        ]) => {
          this._isSubjectPbr = this.materials[materialId] instanceof PBRMaterial;
          this._isSubjectStd = this.materials[materialId] instanceof StandardMaterial;

          this._subjectPbr = this._isSubjectPbr ? (this.materials[materialId] as PBRMaterial) : null;
          this._originalPbr =
            this.originalMaterials[materialId] instanceof PBRMaterial ? (this.originalMaterials[materialId] as PBRMaterial) : null;
          this._subjectStd = this._isSubjectStd ? (this.materials[materialId] as StandardMaterial) : null;
          this._originalStd =
            this.originalMaterials[materialId] instanceof StandardMaterial
              ? (this.originalMaterials[materialId] as StandardMaterial)
              : null;

          if (this._isSubjectPbr && this._subjectPbr) {
            this.subjectDiffuse = this._subjectPbr.albedoColor as Color3;
            this.subjectEmissive = this._subjectPbr.emissiveColor as Color3;
            this.subjectAmbient = this._subjectPbr.ambientColor as Color3;

            this.originalDiffuse = this._originalPbr?.albedoColor as Color3;
            this.originalEmissive = this._originalPbr?.emissiveColor as Color3;
            this.originalAmbient = this._originalPbr?.ambientColor as Color3;

            this._subjectPbr.unlit = !!unlit;
            this._subjectPbr.disableLighting = this._subjectPbr.unlit;
          } else if (this._isSubjectStd && this._subjectStd) {
            this.subjectDiffuse = this._subjectStd.diffuseColor as Color3;
            this.subjectEmissive = this._subjectStd.emissiveColor as Color3;
            this.subjectAmbient = this._subjectStd.ambientColor as Color3;

            this.originalDiffuse = this._originalStd?.diffuseColor as Color3;
            this.originalEmissive = this._originalStd?.emissiveColor as Color3;
            this.originalAmbient = this._originalStd?.ambientColor as Color3;

            this._subjectStd.disableLighting = !!unlit;
          }

          diffuseColor ? this.subjectDiffuse?.fromArray(diffuseColor) : this.subjectDiffuse?.copyFrom(this.originalDiffuse!);
          if (diffuseColor?.every((x, i) => x === this.originalDiffuse?.asArray()?.[i])) {
            delete this.descriptor.materials![materialId].parameters.diffuseColor;
          }

          emissiveColor ? this.subjectEmissive?.fromArray(emissiveColor) : this.subjectEmissive?.copyFrom(this.originalEmissive!);
          if (emissiveColor?.every((x, i) => x === this.originalEmissive?.asArray()?.[i])) {
            delete this.descriptor.materials![materialId].parameters.emissiveColor;
          }

          ambientColor ? this.subjectAmbient?.fromArray(ambientColor) : this.subjectAmbient?.copyFrom(this.originalAmbient!);
          if (ambientColor?.every((x, i) => x === this.originalAmbient?.asArray()?.[i])) {
            delete this.descriptor.materials![materialId].parameters.ambientColor;
          }

          if ((this._originalPbr?.disableLighting || this._originalStd?.disableLighting) === !!unlit) {
            delete this.descriptor.materials![materialId].parameters.unlit;
          }

          if (!Object.keys(this.descriptor.materials![materialId].parameters).length) {
            delete this.descriptor.materials![materialId];
          }

          if (!Object.keys(this.descriptor.materials!).length) {
            delete this.descriptor.materials;
          }

          if (!Object.keys(this.descriptor).length) {
            renderable.disposeCustomization();
          }
        },
      );
  }

  dispose() {
    this.renderable.helper.getChildMeshes(false).forEach((mesh) => {
      if (!mesh.material) {
        return;
      }
      mesh.material = this.meshToOriginalMaterial[mesh.uniqueId];
    });
    Object.keys(this.materials).forEach((key) => {
      this.materials[key]?.dispose();
      delete this.materials[key];
    });
  }
}
