import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface SettingData {
  description: string;
  mainTitle: string;
  outButtonTitle: string; //this is default title.
  overButtonTitle: string; //this is title when mouse over.
  tooltip: string;
  index: string;
}

@Component({
  selector: 'ui-domain-card-description',
  templateUrl: './domain-card-description.component.html',
  styleUrls: ['./domain-card-description.component.scss'],
})
export class DomainCardDescriptionComponent implements OnInit {
  @Input() settingData: SettingData;
  @Input() showingRecommend = false;
  @Output() clicked: EventEmitter<string> = new EventEmitter();
  @Input() showComingSoonBtn = false;
  @Input() loading = false;

  buttonTitle = '';

  constructor() {}

  ngOnInit(): void {
    this.buttonTitle = this.settingData.outButtonTitle;
  }

  onClick() {
    if (!this.loading) {
      this.clicked.emit(this.settingData.index);
    }
  }

  onHover(move: string) {
    this.buttonTitle =
      move === 'over'
        ? this.settingData.overButtonTitle === ''
          ? this.settingData.outButtonTitle
          : this.settingData.overButtonTitle
        : this.settingData.outButtonTitle;
  }
}
