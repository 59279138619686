import { XRAssetVersion } from '../../models/data/asset';
import { deepCopy } from '../../utils/data';

import { AbstractMesh, ISceneLoaderAsyncResult, Quaternion, Vector3 } from '../../babylon';
import { ViewManager } from '..';
import { TElement, TStageElement, ViewMode } from '../types';
import { STAGE_POSITION, STAGE_ROTATION, STAGE_SCALING } from '../constants';
import { ViewManagerUtils } from '../../utils/view-manager-utils';

export class StageRenderable {
  public elementId: string;
  public helper: AbstractMesh;
  public assetVersion: XRAssetVersion | undefined;
  public assetError = false;

  private _element: TStageElement;
  private _initialScaling = Vector3.One();

  constructor(
    element: TStageElement,
    public viewManager: ViewManager,
  ) {
    this.elementId = element.id;
    this.element = element;
    this.load = this.load.bind(this);
    this.update = this.update.bind(this);
    this.updateElement = this.updateElement.bind(this);
    this.dispose = this.dispose.bind(this);
    this.load().then(() => {
      this.update();
    });
  }

  get asset() {
    return this.assetVersion?.asset;
  }

  get element() {
    return this._element as TStageElement;
  }

  set element(payload) {
    this._element = deepCopy(payload) as TStageElement;
  }

  async load() {
    if (this.element.parameters.assetVersionId) {
      this.assetVersion = await this.viewManager.appService.apiv2.getAssetVersionById(this.element.parameters.assetVersionId);
    }
    let meshSubject: ISceneLoaderAsyncResult | undefined;
    if (this.asset) {
      meshSubject = await this.viewManager.appService.loadObjectAsset(this.asset);
    } else {
      this.assetError = true;
    }
    if (!this.asset) {
      this.assetError = true;
      return;
    }

    if (meshSubject && meshSubject.meshes) {
      this.helper = meshSubject.meshes[0];
      ViewManagerUtils.ProcessRenderableHierarchy(this, {
        checkCollisions: true,
        isPickable: this.viewManager.activeMode === ViewMode.Player,
      });
      this._initialScaling.copyFrom(this.helper.scaling);
      this.viewManager.lights.forEach((light) => {
        light.update();
      });

      this.viewManager.activeMode === ViewMode.Editor && ViewManagerUtils.FocusOnRenderable(this);
    }
  }

  update(element?: TElement) {
    if (element) {
      this.element = deepCopy(element as TStageElement);
    }

    if (this.assetError) {
      this.viewManager.appService.removeViewElements([this.element]);
      console.warn(`MISSING ASSET! Name: "${this.element.name}" ID: ${this.element.parameters.assetVersionId}`);
      return;
    }

    const dirty = this.assetVersion?.Id !== this.element.parameters.assetVersionId;
    if (dirty) {
      this.load().then(() => {
        this.update();
      });
      return;
    }

    if (this.helper) {
      this.helper.position.set(...(this.element.parameters?.position ?? STAGE_POSITION));
      this.helper.rotationQuaternion?.copyFrom(Quaternion.FromEulerAngles(0, this.element.parameters?.rotation ?? STAGE_ROTATION, 0));
      this.helper.scaling.copyFrom(this._initialScaling.multiplyByFloats(...(this.element.parameters?.scaling ?? STAGE_SCALING)));
    }
  }

  updateElement() {
    this.viewManager.scene.onBeforeRenderObservable.addOnce(() => {
      this.viewManager.appService.updateViewElement({
        ...this.element,
        parameters: {
          ...this.element.parameters,
          rotation: this.helper.rotation.y,
          scaling: this.helper.scaling.divide(this._initialScaling).asArray() as TStageElement['parameters']['scaling'],
        },
      });
    });
  }

  setPicking(flag: boolean) {
    this.helper
      ?.getChildMeshes(false)
      .concat(this.helper)
      .forEach((mesh) => {
        mesh.isPickable = flag;
      });
  }

  dispose() {
    if (!this.helper) {
      return;
    }
    this.viewManager.lights.forEach((light) => {
      light.shadowGenerator?.removeShadowCaster(this.helper);
    });
    // this.helper?.dispose();
    this.helper
      ?.getChildren(undefined, false)
      .concat(this.helper)
      .forEach((child) => {
        child.setEnabled(false);
      });
  }
}
