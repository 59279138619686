<div
  class="icon-wrapper"
  [ngClass]="{ selected: selected, disabled: disabled }"
  uiTooltip="{{ tooltip | translate }}"
  [tooltipPosition]="toolTipPositions.Right"
  (click)="onClick()"
>
  @if (badge) {
    <div class="notification-badge">{{ badge }}</div>
  }
  <div class="icon md" [ngClass]="icon"></div>
  @if (text) {
    <span class="font-menu-2" [innerHtml]="text | translate"></span>
  }
</div>
@if (selected) {
  <ng-content></ng-content>
}
