import { Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { environment } from 'projects/app/src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { TransactionHistory } from '../../../../view/src/app/transactions/transaction-history';
import { AccountService } from './account.service';

/**
 * Sends and receives transactions through signal r to allow for real time model collaboration
 */
@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  public history: Map<string, TransactionHistory> = new Map<string, TransactionHistory>();

  private started = false;
  private historyBehaviour = new BehaviorSubject<TransactionHistory>(<TransactionHistory>{});
  public historyObservable = this.historyBehaviour.asObservable();

  constructor(private accountService: AccountService) {}

  private hubConnection!: signalR.HubConnection;

  /**
   * Checks if the connection has started
   * @returns true for started false otherwise
   */
  public connectionStarted(): boolean {
    return this.started;
  }

  /**
   * Starts the hub connection
   */
  public startConnection = async () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment[this.accountService.region].messengerUrl + '/TransactionHub')
      .build();
    await this.hubConnection
      .start()
      .then(() => {
        console.log('Connection started');
        this.started = true;
      })
      .catch((err) => console.log('Error while starting connection: ' + err));
  };

  /**
   * Adds a signal r listener to the hub connection
   */
  public addTransactionListener = () => {
    this.hubConnection.on('ReceiveTransaction', (transaction: TransactionHistory) => {
      if (!this.history.get(transaction.Id)) {
        this.historyBehaviour.next(transaction);
      }
    });
  };

  /**
   * Sends a transaction to the hub connection
   * @param modelId model id the scene which the transaction was performed on
   * @param transaction the transaction made
   */
  public sendTransaction = async (modelId: string, transaction: TransactionHistory) => {
    this.history.set(transaction.Id, transaction);
    this.hubConnection.invoke('SendTransaction', modelId, {
      Id: transaction.Id,
      ViewId: transaction.ViewId,
      UserId: this.accountService.getAccountId(),
      TransactionString: transaction.TransactionString,
      TransactionType: transaction.TransactionType,
    });
  };

  /**
   * Begin listening to model changes
   * @param modelId model to listen for changes
   */
  public listenToModelChanges = async (modelId: string) => {
    await this.hubConnection.invoke('BeginModelTransactions', modelId);
  };
}
