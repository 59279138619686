import { FileType } from '../enums/file-type';
import {
  CheckpointRenderable,
  EnvironmentRenderable,
  LandscapeRenderable,
  LightRenderable,
  TextRenderable,
  StageRenderable,
  ObjectRenderable,
  ScreenRenderable,
} from './renderables';

export enum ViewMode {
  Creator = 'Creator',
  Editor = 'Editor',
  Player = 'Player',
  VR = 'VR',
}

export enum ViewTool {
  Customize = 'CUSTOMIZE',
  Insert = 'INSERT',
  Select = 'SELECT',
  Snap = 'SNAP',
  Text = 'TEXT',
}

export enum TransformationMode {
  None = 0,
  Translate = 1,
  Rotate = 2,
  Scale = 4,
  BoundingBox = 8,
  TranslateX = 16,
  TranslateY = 32,
  TranslateZ = 64,
  PlaneNormalX = 128,
  PlaneNormalY = 256,
  PlaneNormalZ = 512,
  PlaneRotationX = 1024,
  PlaneRotationY = 2048,
  PlaneRotationZ = 4096,

  DefaultScene = TranslateY | PlaneNormalY | PlaneRotationY,
  DefaultAsset = Translate,
}

export type TransformationInfo = { x: number | undefined; y: number | undefined; z: number | undefined };

export type MediaDimensions = {
  width: number;
  height: number;
  rotate90: boolean;
};

export enum ElementType {
  Object = 'Object',
  Stage = 'Stage',
  Landscape = 'Landscape',
  Environment = 'Environment',
  Screen = 'Screen',
  Camera = 'Camera',
  Light = 'Light',
  Checkpoint = 'Checkpoint',
  Interaction = 'Interaction',
  Text = 'Text',
}

export enum CheckpointType {
  Start = 'Start',
}

export enum EnvironmentType {
  Background = 'Background',
  Illumination = 'Illumination',
  Combined = 'Combined',
}

export enum LightType {
  Point = 'Point',
  Directional = 'Directional',
  Ambient = 'Ambient',
  Spot = 'Spot',
}

export enum TextAlignment {
  Left = 'Left',
  Center = 'Center',
  Right = 'Right',
}

export enum InteractionTrigger {
  Load = 'Load',
  Click = 'Click',
  Hover = 'Hover',
}

export enum EffectType {
  Blink = 'Blink',
  Roll = 'Roll',
  Shine = 'Shine',
  Move = 'Move',
  Spin = 'Spin',
  Inflate = 'Inflate',
  Bounce = 'Bounce',
}

export enum MoveMode {
  SideToSide = 'Side to Side',
  ForwardBack = 'Forward Back',
}

export enum EffectAction {
  MouseOver = 'mouseover',
  Click = 'click',
  Repeat = 'repeat',
}

export enum PopupType {
  Contact = 'Contact',
  Gallery = 'Gallery',
  GoogleForm = 'GoogleForm',
  GuestBook = 'GuestBook',
  Link = 'Link',
  Text = 'Text',
  Video = 'Video',
}

export enum MediaControl {
  Play = 'Play',
  Pause = 'Pause',
  Stop = 'Stop',
  FastForward = 'FastForward',
  SoundMax = 'SoundMax',
  SoundMin = 'SoundMin',
  SoundZero = 'SoundZero',
  Unmute = 'Unmute',
  // Loop = 'Loop',
  Fullscreen = 'Fullscreen',
}

export enum InteractionDataType {
  String,
  Number,
  Boolean,
  Vector,
  Color,
}

export type TInteractionParameter = {
  key: string;
  value: any;
  kind?: InteractionDataType;
  location?: number;
};

export type TCustomizationMaterial = {
  materialId: string;
  // type: 'Standard' | 'PBR';
  name?: string;
  parameters: {
    diffuseColor?: number[];
    emissiveColor?: number[];
    ambientColor?: number[];
    reflectivityColor?: number[];
    reflectanceColor?: number[];
    unlit?: boolean;
  };
};

export type TCustomizationMesh = {
  meshId: string; // TODO: Check for customizations
  parameters: {
    position?: [number, number, number];
    quaternion?: [number, number, number, number];
    scaling?: [number, number, number];
    visibility?: boolean;
  };
};

export type TCustomization = {
  materials?: { [materialId: string]: TCustomizationMaterial };
  meshes?: { [meshId: string]: TCustomizationMesh };
};

export type TEffect = {
  id: string;
  type: EffectType;
  trigger: InteractionTrigger;
  parameters: TInteractionParameter[];
};

export type TPopup = {
  id: string;
  type: PopupType;
  thumbnail?: string;
  color?: [number, number, number];
  parameters: TInteractionParameter[];
};

export type TMedia = {
  id: string;
  fileId: string;
  type: FileType;
  url: string;
  volume?: number;
  brightness?: number;
  autoplay?: boolean;
  repeat?: boolean;
};

export type TBaseElement = {
  id: string;
  type: ElementType;
  name: string;
};

export type TCheckpointElement = TBaseElement & {
  parameters: {
    type: CheckpointType;
    position?: [number, number, number];
    quaternion?: [number, number, number, number];
    avatar?: string;
    assetId?: string;
  };
};

export type TEnvironmentElement = TBaseElement & {
  parameters: {
    type: EnvironmentType;
    assetVersionId?: string;
    intensity?: number;
    level?: number;
    tint?: number[];
    rotation?: number;
  };
};

export type TLandscapeElement = TBaseElement & {
  parameters: {
    assetVersionId?: string;
    position?: [number, number, number];
    rotation?: number;
    scaling?: [number, number, number];
  };
};

export type TLightElement = TBaseElement & {
  parameters: {
    assetId?: string;
    type: LightType;
    intensity?: number;
    angle?: number;
    color?: number[];
    bias?: number;
    normalBias?: number;
    groundColor?: number[];
    direction?: [number, number, number];
    position?: [number, number, number];
  };
};

export type TObjectElement = TBaseElement & {
  parameters: {
    assetVersionId?: string;
    popups?: TPopup[];
    effects?: TEffect[];
    customization?: TCustomization;
    position?: [number, number, number];
    quaternion?: [number, number, number, number];
    scaling?: [number, number, number];
    collisions?: boolean;
    shadows?: boolean;
  };
};

export type TScreenElement = TBaseElement & {
  parameters: {
    assetId?: string;
    width?: number;
    height?: number;
    popups?: TPopup[];
    effects?: TEffect[];
    collisions?: boolean;
    shadows?: boolean;
    position?: [number, number, number];
    quaternion?: [number, number, number, number];
    scaling?: [number, number];
    media?: TMedia;
    doubleSided?: boolean;
  };
};

export type TStageElement = TBaseElement & {
  parameters: {
    assetVersionId?: string;
    position?: [number, number, number];
    rotation?: number;
    scaling?: [number, number, number];
  };
};

export type TTextElement = TBaseElement & {
  parameters: {
    text: string;
    font?: string;
    color?: [number, number, number, number];
    background?: [number, number, number, number];
    alignment?: TextAlignment;
    doubleSided?: boolean;
    lineHeight?: number;
    letterSpacing?: number;
    padding?: [number, number, number, number];
    popups?: TPopup[];
    effects?: TEffect[];
    position?: [number, number, number];
    quaternion?: [number, number, number, number];
    scaling?: [number, number];
    collisions?: boolean;
    shadows?: boolean;
  };
};

export type TElement =
  | TCheckpointElement
  | TEnvironmentElement
  | TLandscapeElement
  | TLightElement
  | TObjectElement
  | TScreenElement
  | TStageElement
  | TTextElement;

export type TInteractableElement = TObjectElement | TScreenElement | TTextElement;
export const INTERACTABLE_TYPES = [ElementType.Object, ElementType.Screen, ElementType.Text];
export const COLLIDABLE_TYPES = [ElementType.Landscape, ElementType.Stage];
export const SNAPPABLE_TYPES = [...INTERACTABLE_TYPES, ...COLLIDABLE_TYPES];
export const STATIC_TYPES = [ElementType.Light, ElementType.Environment];

export type TRenderable =
  | CheckpointRenderable
  | EnvironmentRenderable
  | LandscapeRenderable
  | LightRenderable
  | ScreenRenderable
  | StageRenderable
  | ObjectRenderable
  | TextRenderable;
