import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ElementType,
  TEXT_BACKGROUND,
  TEXT_COLOR,
  TEXT_FONT_FAMILY,
  TEXT_FONT_SIZE,
  TEXT_FONT_WEIGHT,
  TEXT_LETTER_SPACING,
  TEXT_LINE_HEIGHT,
  TEXT_PADDING,
  TTextElement,
  TextAlignment,
} from '@/data/src/lib/view-manager';
import { ApplicationService } from '@/view/src/app/app.service';
import { ViewManagerUtils } from '@/data/src/lib/utils/view-manager-utils';
import { TooltipPosition } from '../../../directive/tooltip.directive';
import { deepCopy } from '@/data/src/lib/utils/data';
import { PanelComponent } from '@/ui/src/lib/components/panel/panel.component';
import { DOCUMENT } from '@angular/common';

const DEFAULT_WEIGHTS = [
  { name: 'Thin', id: '100' },
  { name: 'Extra Light', id: '200' },
  { name: 'Light', id: '300' },
  { name: 'Regular', id: '400' },
  { name: 'Medium', id: '500' },
  { name: 'Semi Bold', id: '600' },
  { name: 'Bold', id: '700' },
  { name: 'Extra Bold', id: '800' },
  { name: 'Black', id: '900' },
];
const PADDING_FACTOR = 0.1;

enum PaddingMask {
  None = 0,
  Top = 1,
  Right = 2,
  Bottom = 4,
  Vertical = 5,
  Left = 8,
  Horizontal = 10,
}

@UntilDestroy()
@Component({
  selector: 'ui-text-panel',
  templateUrl: './text-panel.component.html',
  styleUrls: ['./text-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextPanelComponent extends PanelComponent {
  element: TTextElement | undefined;
  textAlignment = TextAlignment;
  toolTipPositions = TooltipPosition;

  fontWeight: string;
  fontSize: string;
  fontFamily: string;
  lineHeight: number;
  letterSpacing: number;
  paddingTop: number;
  paddingRight: number;
  paddingBottom: number;
  paddingLeft: number;
  isPaddingLinked = false;
  opacity: string;
  color = ViewManagerUtils.GetColorString(TEXT_COLOR);
  background = ViewManagerUtils.GetColorString(TEXT_BACKGROUND);

  weight: any;
  weights = deepCopy(DEFAULT_WEIGHTS);
  paddingMask = PaddingMask;

  fonts = ['Arial', 'Bai Jamjuree', 'paybooc', 'Noto Sans', 'Roboto', 'Seoul Namsan', 'Serif', 'Monospace'];

  _color = this.color;
  _background = this.background;

  get text() {
    return this.element?.parameters.text ?? '';
  }

  get font() {
    return [this.fontWeight, this.fontSize, this.fontFamily].join(' ');
  }

  get size() {
    return this.fontSize?.replace('px', '');
  }

  constructor(
    protected _appService: ApplicationService,
    private _cd: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {
    super(_appService);
  }

  ngOnInit() {
    this._appService.selectedElementsSubject.pipe(untilDestroyed(this)).subscribe((elements) => {
      this.element = elements.find(({ type }) => type === ElementType.Text) as TTextElement;
      this._cd.detectChanges();
    });

    this._appService.viewElementsSubject.pipe(untilDestroyed(this)).subscribe((elements) => {
      if (this.element) {
        this.element = elements.find(({ id }) => (this.element as TTextElement).id === id) as TTextElement;
        const font = this.element?.parameters.font?.split(' ');
        this.fontWeight = font?.shift() ?? TEXT_FONT_WEIGHT;
        this.weight = this.weights.find(({ id }) => id === this.fontWeight)?.name ?? '';
        this.fontSize = font?.shift() ?? TEXT_FONT_SIZE;
        this.fontFamily = font?.join(' ') ?? TEXT_FONT_FAMILY;
        !this.weight && this.checkWeights();

        this.lineHeight = this.element.parameters.lineHeight ?? TEXT_LINE_HEIGHT;
        this.letterSpacing = this.element.parameters.letterSpacing ?? TEXT_LETTER_SPACING;
        this.paddingTop = (this.element.parameters.padding?.[0] ?? TEXT_PADDING[0]) * PADDING_FACTOR;
        this.paddingRight = (this.element.parameters.padding?.[1] ?? TEXT_PADDING[1]) * PADDING_FACTOR;
        this.paddingBottom = (this.element.parameters.padding?.[2] ?? TEXT_PADDING[2]) * PADDING_FACTOR;
        this.paddingLeft = (this.element.parameters.padding?.[3] ?? TEXT_PADDING[3]) * PADDING_FACTOR;

        this.color = ViewManagerUtils.GetColorString(this.element?.parameters.color ?? TEXT_COLOR);
        this.background = ViewManagerUtils.GetColorString(this.element?.parameters.background ?? TEXT_BACKGROUND);

        this._color = this.color;
        this._background = this.background;
      }
      this._cd.detectChanges();
    });
  }

  async checkWeights() {
    this.weights.length = 0;
    await Promise.all(
      DEFAULT_WEIGHTS.map((weight) => {
        this._document.fonts
          .load([weight.id, this.fontSize, this.fontFamily].join(' '))
          .then(() => {
            this._document.fonts.check([weight.id, this.fontSize, this.fontFamily].join(' ')) && this.weights.push(weight);
          })
          .catch(() => {
            this._document.fonts.check([weight.id, this.fontSize, this.fontFamily].join(' ')) && this.weights.push(weight);
          });
      }),
    );
    if (!this.weights.find(({ name }) => name === this.weight)) {
      this.fontWeight = TEXT_FONT_WEIGHT;
      this.weight = deepCopy(DEFAULT_WEIGHTS.find(({ id }) => id === this.fontWeight)!.name);
    }
    this._cd.detectChanges();
  }

  onTextChange(text: string) {
    this.element &&
      this._appService.updateViewRenderable({
        ...this.element,
        parameters: { ...this.element.parameters, text },
      });
  }

  onFontFamilyChange(fontFamily: string) {
    this.fontFamily = fontFamily;
    this._document.fonts.load(this.font).then(() => {
      this.checkWeights().then(() => {
        this.element &&
          this._appService.updateViewElement({
            ...this.element,
            parameters: { ...this.element.parameters, font: this.font },
          });
      });
    });
  }

  onFontSizeChange(value: string) {
    if (!this.element || isNaN(Number(value))) {
      return;
    }
    this.fontSize = `${value}px`;
    this._appService.updateViewRenderable({
      ...this.element,
      parameters: { ...this.element.parameters, font: this.font },
    });
  }

  onFontWeightChange(weight: string) {
    this.fontWeight = weight;
    this.weight = this.weights.find(({ id }) => id === this.fontWeight)?.name ?? '';
    this.element &&
      this._appService.updateViewElement({
        ...this.element,
        parameters: { ...this.element.parameters, font: this.font },
      });
  }

  onAlignment(alignment: TextAlignment) {
    this.element &&
      this._appService.updateViewElement({
        ...this.element,
        parameters: { ...this.element.parameters, alignment },
      });
  }

  onLineHeightChange(value: string) {
    if (!this.element || isNaN(Number(value))) {
      return;
    }
    this.lineHeight = Number(value);
    this._appService.updateViewRenderable({
      ...this.element,
      parameters: { ...this.element.parameters, lineHeight: this.lineHeight },
    });
  }

  onLetterSpacingChange(value: string) {
    if (!this.element || isNaN(Number(value))) {
      return;
    }
    this.letterSpacing = Number(value);
    this._appService.updateViewRenderable({
      ...this.element,
      parameters: { ...this.element.parameters, letterSpacing: this.letterSpacing },
    });
  }

  onDoubleSideToggle(doubleSided: boolean) {
    this.element &&
      this._appService.updateViewElement({
        ...this.element,
        parameters: { ...this.element.parameters, doubleSided },
      });
  }

  onTextColorChange(color: string) {
    this._appService.setUtility(false);
    const colorArray = ViewManagerUtils.GetColorArray(color);
    colorArray[3] = colorArray[3] || 1;
    this._color = ViewManagerUtils.GetColorString(colorArray);
    this.element &&
      this._appService.updateViewRenderable({
        ...this.element,
        parameters: {
          ...this.element.parameters,
          color: ViewManagerUtils.GetColorArray(this._color) as TTextElement['parameters']['color'],
        },
      });
  }

  onTextOpacityChange(color: string) {
    this._appService.setUtility(false);
    this._color = color;
    this.element &&
      this._appService.updateViewRenderable({
        ...this.element,
        parameters: { ...this.element.parameters, color: ViewManagerUtils.GetColorArray(color) as TTextElement['parameters']['color'] },
      });
  }

  onBackgroundColorChange(color: string) {
    this._appService.setUtility(false);
    const colorArray = ViewManagerUtils.GetColorArray(color);
    colorArray[3] = colorArray[3] || 1;
    this._background = ViewManagerUtils.GetColorString(colorArray);
    this.element &&
      this._appService.updateViewRenderable({
        ...this.element,
        parameters: {
          ...this.element.parameters,
          background: ViewManagerUtils.GetColorArray(this._background) as TTextElement['parameters']['background'],
        },
      });
  }

  onBackgroundOpacityChange(color: string) {
    this._appService.setUtility(false);
    this._background = color;
    this.element &&
      this._appService.updateViewRenderable({
        ...this.element,
        parameters: {
          ...this.element.parameters,
          background: ViewManagerUtils.GetColorArray(color) as TTextElement['parameters']['background'],
        },
      });
  }

  onPaddingLink() {
    this.isPaddingLinked = !this.isPaddingLinked;
    if (!this.isPaddingLinked) {
      return;
    }
    let dirty = this.paddingBottom !== this.paddingTop || this.paddingLeft !== this.paddingRight;
    this.paddingBottom = this.paddingTop;
    this.paddingLeft = this.paddingRight;

    dirty &&
      this.element &&
      this._appService.updateViewElement({
        ...this.element,
        parameters: {
          ...this.element.parameters,
          padding: [
            this.paddingTop / PADDING_FACTOR,
            this.paddingRight / PADDING_FACTOR,
            this.paddingBottom / PADDING_FACTOR,
            this.paddingLeft / PADDING_FACTOR,
          ],
        },
      });
  }

  onPaddingChange(padding: string, mask: PaddingMask) {
    if (!this.element || isNaN(Number(padding))) {
      return;
    }
    if (mask & PaddingMask.Top) {
      this.paddingTop = Number(padding);
    }
    if (mask & PaddingMask.Right) {
      this.paddingRight = Number(padding);
    }
    if (mask & PaddingMask.Bottom) {
      this.paddingBottom = Number(padding);
    }
    if (mask & PaddingMask.Left) {
      this.paddingLeft = Number(padding);
    }
    this.element &&
      this._appService.updateViewRenderable({
        ...this.element,
        parameters: {
          ...this.element.parameters,
          padding: [
            this.paddingTop / PADDING_FACTOR,
            this.paddingRight / PADDING_FACTOR,
            this.paddingBottom / PADDING_FACTOR,
            this.paddingLeft / PADDING_FACTOR,
          ],
        },
      });
  }

  onChangeEnd() {
    if (!this.element) {
      return;
    }
    const actualElement = this._appService.getViewRenderable(this.element)?.element as TTextElement;
    if (!actualElement) {
      return;
    }
    if (!actualElement.parameters.text || !this.size) {
      this._appService.removeViewElements([actualElement]);
      return;
    }
    this._appService.setUtility(true);
    if (
      actualElement.parameters.text !== this.text ||
      this.element.parameters.font !== this.font ||
      this.element.parameters.lineHeight !== this.lineHeight ||
      this.element.parameters.letterSpacing !== this.letterSpacing ||
      ViewManagerUtils.GetColorArray(this._color).some((x, i) => this.element?.parameters?.color?.[i] !== x) ||
      ViewManagerUtils.GetColorArray(this._background).some((x, i) => this.element?.parameters?.background?.[i] !== x) ||
      this.element.parameters.padding?.[0] !== this.paddingTop ||
      this.element.parameters.padding?.[1] !== this.paddingRight ||
      this.element.parameters.padding?.[2] !== this.paddingBottom ||
      this.element.parameters.padding?.[3] !== this.paddingLeft
    ) {
      this._appService.updateViewElement(actualElement);
    }
  }

  ngOnDestroy(): void {
    this.onChangeEnd();
    super.ngOnDestroy();
  }
}
