import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Account, AccountOption, AccountOptionType, AccountSetting, B2CAttribute, Friend } from '../models/data/account';
import { ModelType } from '../models/data/base';
import { privateFieldReplacer } from '../utils/json-utils';
import { BehaviorSubject, lastValueFrom, Observable, of } from 'rxjs';
import { newModel } from '../factories/model-factory';
import { LocalStorageService } from './local-storage.service';
import { environment } from 'projects/app/src/environments/environment';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import * as uuid from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { UserRoles } from '../enums/user';
import { LocalStorageKeys } from '../enums/storage-keys';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EnterpriseAccount } from '../models/data/enterprise';
import { syncPromiseHandler } from '../utils/async-utils';
import * as publicIp from 'public-ip';
import { DOCUMENT } from '@angular/common';
import { RedirectRequest } from '@azure/msal-browser';

export enum Region {
  us = 'us',
  kr = 'kr',
}

export enum LanguageCode {
  English = 'en',
  Korean = 'ko',
}

class UserResponseOptions {
  constructor(
    public responseType: 'text',
    public headers?: HttpHeaders | { [header: string]: string | string[] } | undefined,
    public observe?: 'body' | undefined,
    public params?: HttpParams | { [param: string]: string | string[] } | undefined,
    public reportProgress?: boolean | undefined,
    public withCredentials?: boolean | undefined,
  ) {}
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private _accountSubject: BehaviorSubject<Account | undefined> = new BehaviorSubject<Account | undefined>(undefined);
  public activeAccount$ = this._accountSubject.asObservable();

  private friendSubject: BehaviorSubject<Friend[]> = new BehaviorSubject<Friend[]>([]);
  public friends$ = this.friendSubject.asObservable();

  private requestSubject: BehaviorSubject<Friend[]> = new BehaviorSubject<Friend[]>([]);
  public requests$ = this.requestSubject.asObservable();

  private languageSubject = new BehaviorSubject<LanguageCode | undefined>(undefined);
  public language$: Observable<any> = this.languageSubject.asObservable().pipe(filter((value) => !!value));

  public accountOptions = new BehaviorSubject<AccountOption[]>([]);

  public currentAccountOption: BehaviorSubject<AccountOption | null> = new BehaviorSubject<AccountOption | null>({
    OptionId: `${AccountOptionType.Individual}`,
    Type: AccountOptionType.Individual,
    Id: '',
  });
  public currentAccountOption$ = this.currentAccountOption.asObservable();

  private currentId!: string;
  private tokens!: number;
  private noAuthHttp: HttpClient;
  private publicIp = publicIp;

  public trialOnly = false;
  public region: Region = Region.us;
  public countries = [
    {
      id: 'us',
      name: 'USA',
      checked: false,
    },
    {
      id: 'kr',
      name: 'South Korea',
      checked: false,
    },
  ];
  public languages = [
    {
      id: LanguageCode.English,
      name: 'English',
      checked: false,
    },
    {
      id: LanguageCode.Korean,
      name: '한국어',
      checked: false,
    },
  ];

  constructor(
    public http: HttpClient,
    private authService: MsalService,
    private _localStorageService: LocalStorageService,
    private _translateService: TranslateService,
    handler: HttpBackend,
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(MSAL_GUARD_CONFIG) private _msalGuardConfig: MsalGuardConfiguration,
  ) {
    this.noAuthHttp = new HttpClient(handler);
  }

  /**
   * If there is no setting for 'Language' in Account Settings, the language is set based on the browser language
   */
  async setInitialLanguage() {
    let accountSettingLang: AccountSetting | undefined = undefined;
    let browserLang = this._translateService.getBrowserLang();

    const supportedLangs = ['en', 'ko'];
    this._translateService.addLangs(supportedLangs);
    this._translateService.setDefaultLang('en');

    if (this.account) {
      accountSettingLang = await this.getAccountSettings().then(async (accountSettings) => {
        let setting = accountSettings.find((accountSetting: AccountSetting) => accountSetting.Name === 'Language');
        if (!setting) {
          setting = AccountSetting.create(this.getAccountId(), 'Language', browserLang!);
          await this.postAccountSettings([setting]);
        }
        return setting;
      });
    }

    const currentLang = accountSettingLang?.Value ?? (browserLang?.match(/en|ko/) ? browserLang : 'en');
    await this.setLanguage(currentLang as LanguageCode);
  }

  /**
   * Sets the language for the application and updates account settings and UI elements.
   * @param language The language code to set for the application
   */
  async setLanguage(language: LanguageCode) {
    let accountSettingLang: AccountSetting | undefined = undefined;

    if (this.account) {
      accountSettingLang = await this.getAccountSettings().then(async (accountSettings) => {
        let setting = accountSettings.find((accountSetting: AccountSetting) => accountSetting.Name === 'Language');
        if (!setting) {
          setting = AccountSetting.create(this.getAccountId(), 'Language', language);
          await this.postAccountSettings([setting]);
        }
        return setting;
      });

      if (accountSettingLang) {
        accountSettingLang.Value = language;
        await this.putAccountSettings([accountSettingLang]);
      }
    }

    const htmlEl = this._document.getElementsByTagName('html')[0];
    if (htmlEl) {
      htmlEl.setAttribute('lang', language);
    }

    this._document.body.classList.remove('korean', 'english');
    this._document.body.classList.add(language === LanguageCode.Korean ? 'korean' : 'english');

    this._translateService.use(language);
    this.languageSubject.next(language);
  }

  /**
   * Sets the region for the application based on the user's server.
   * or, if not available, retrieves it from local storage or fetches it.
   * The selected region is then stored in local storage.
   */
  async setRegion() {
    const server = this.account?.Server;

    if (server) {
      this.region = server as Region;
    } else {
      const storageRegion = this._localStorageService.getItem(LocalStorageKeys.REGION) as Region;
      this.region = storageRegion ?? (await syncPromiseHandler(this.getCountryCode()));
    }
    this._localStorageService.setItem(LocalStorageKeys.REGION, this.region);
  }

  /**
   * Gets the IP address
   * @returns IPV4 Address (0.0.0.0)
   */
  async getUserIP() {
    return await this.publicIp.v4();
  }

  /**
   * Gets the country code based on IP address
   * @returns Region
   */
  async getCountryCode() {
    const ip = await this.getUserIP();
    if (ip) {
      return await lastValueFrom(
        this.noAuthHttp.get(`${environment[this.region].crudApiUrl}/Ping/Country/${ip}`, { observe: 'body', responseType: 'text' }).pipe(
          catchError(() => of(undefined)),
          map((region) => (region === 'kr' ? Region.kr : Region.us)),
        ),
      );
    }

    return Region.us;
  }

  protected postOptions: UserResponseOptions = {
    headers: { 'Content-Type': 'application/json' },
    observe: 'body',
    responseType: 'text',
  };

  public get language() {
    return this.languageSubject.value;
  }

  public get account() {
    return this._accountSubject.value;
  }

  public get isAdmin(): boolean {
    return !!this._accountSubject.value?.UserRoles?.some((role) => role.Role === UserRoles.Admin);
  }

  public get isEnterprise(): boolean {
    return !!this._accountSubject.value?.UserRoles?.some((role) => role.Role === UserRoles.Enterprise);
  }

  public get isEnterpriseOrAdmin(): boolean {
    return !!this._accountSubject.value?.UserRoles?.some((role) => role.Role === UserRoles.Admin || role.Role === UserRoles.Enterprise);
  }

  /**
   * Gets the email address from the JWT claims
   * @returns The email address of the active user
   */
  public async getEmail() {
    const user = await this.authService.instance.getActiveAccount();
    if (user && user.idTokenClaims) {
      return (user.idTokenClaims['emails'] as string[])[0] as string;
    }
    return user?.username;
  }

  /**
   * Gets the account id of the active user
   * @returns Account id
   */
  public getAccountId() {
    return this.account?.Id ?? '';
  }

  /**
   * Gets the username of the active user
   * @returns username
   */
  getUsername(): string | undefined {
    return this.account?.Username;
  }

  /**
   * Sets the account subject to a value
   * @returns void
   */
  setAccountSubject(account: Account | undefined): void {
    this._accountSubject.next(account);
  }

  /**
   * Gets a session Id for the active user
   * @returns Session Id
   */
  public async getSessionId() {
    this.currentId = this._localStorageService.getItem(LocalStorageKeys.USER_ID) ?? '';
    if (!this.currentId) {
      this.currentId = uuid.v4();
      this._localStorageService.setItem(LocalStorageKeys.USER_ID, this.currentId);
    }
    return this.currentId;
  }

  /**
   * Gets the active account for the authorised user
   * @param forceHttp Set to true to force http call to the server
   * @returns The active account
   */
  async getActiveAccount(forceHttp = false): Promise<Account | undefined> {
    if (forceHttp) {
      const user = await lastValueFrom(this.http.get<Account>(`${environment[this.region].crudApiUrl}/User/Active`));
      this._accountSubject.next(user ? newModel(user, ModelType.Account) : undefined);
    }
    return this._accountSubject.value;
  }

  getActiveAccountObservable(msalProcessTriggered = false): Observable<Account | undefined> {
    return this.http.get<Account>(`${environment[this.region].crudApiUrl}/User/Active`).pipe(
      map((user) => {
        if (!msalProcessTriggered) {
          this._accountSubject.next(newModel(user, ModelType.Account));
          return this._accountSubject.value;
        }
        return undefined;
      }),
    );
  }

  /**
   * Determines if the active user has creator priviledges
   * @returns true if the user can create, false otherwise
   */
  async isCreator() {
    return true; // await this.http.get<boolean>(`${environment[this.region].crudApiUrl}/User/IsCreator`)
  }

  async canEdit(modelType: ModelType, assetId: string) {
    return true; // await this.http.get<boolean>(`${environment.crudApiUrl}/User/CanEdit${modelType}/:${assetId}`)
  }

  /**
   * Checks if a username is available
   * @param username User to check
   * @returns true if username is available, false otherwise
   */
  async checkUsername(username: string) {
    const available = await lastValueFrom(
      this.http.get<boolean>(`${environment[this.region].crudApiUrl}/User/IsUsernameAvaliable/${username}`),
    );

    return available;
  }

  /**
   * Creates a user account
   * If result is null - server returned an error
   * @param account
   */
  createAccount(account: Partial<Account>): Observable<Account | null> {
    return this.http
      .post<Account>(`${environment[this.region].crudApiUrl}/User`, JSON.stringify(account, privateFieldReplacer), {
        headers: { 'Content-Type': 'application/json' },
      })
      .pipe(
        /* Proper error handling will be implemented later */
        catchError((error) => {
          console.log(error);
          return of(null);
        }),
        tap((account: Account | null) => {
          if (account) {
            this._accountSubject.next(account);
          }
        }),
      );
  }

  /**
   * Gets the tokens for the active user
   * @returns number of tokens
   */
  async getTokens(): Promise<number> {
    if (!this.tokens) {
      this.tokens = await lastValueFrom(
        this.http.get<number>(`${environment[this.region].crudApiUrl}/User/Tokens`).pipe(catchError(() => of(0.001))),
      );
    }
    return this.tokens;
  }

  /**
   * Gets the number of scenes and assets that the user has
   * @param Username
   * @returns scene and asset count
   */
  async getUserStats(Username: string): Promise<{ Scenes: number; Assets: number }> {
    return await lastValueFrom(
      this.http.get<{ Scenes: number; Assets: number }>(`${environment[this.region].crudApiUrl}/User/Stats/${Username}`).pipe(
        map((r) => {
          return r;
        }),
      ),
    );
  }

  /**
   * Gets a user by their id
   * @param id
   * @returns The User account metadata
   */
  async getUserById(id: string) {
    return await this.http.get<Account>(`${environment[this.region].crudApiUrl}/User/${id}`).toPromise();
  }

  /**
   * Search all registered users where the start of their name or email matches the input text
   * @param startsWith Text to match against user metadata
   * @returns List of matching user accounts
   */
  async searchUser(startsWith: string) {
    if (startsWith) {
      const available = await this.http.get<Account[]>(`${environment[this.region].crudApiUrl}/User/Search/${startsWith}`).toPromise();
      return available;
    }
    return undefined;
  }

  /**
   * Updates the account
   * @param account
   */
  async updateAccount(account: Account) {
    return await lastValueFrom(
      this.http.put(`${environment[this.region].crudApiUrl}/User`, JSON.stringify(account, privateFieldReplacer), this.postOptions),
    );
  }

  async updateAccountB2CAttribute(b2CAttributes: B2CAttribute[]) {
    const transformedAttributes = b2CAttributes.map((item) => {
      return {
        AttrName: item.AttrName,
        AttrValue: item.AttrValue.ValueKind,
      };
    });

    return await lastValueFrom(
      this.http.put(`${environment[this.region].crudApiUrl}/User/B2C/Attribute`, JSON.stringify(transformedAttributes), this.postOptions),
    );
  }

  /**
   * Deletes the active account
   */
  async postFeedback(message: string) {
    return await lastValueFrom(
      this.http.post(`${environment[this.region].crudApiUrl}/UserFeedback`, [{ message: message }], {
        headers: { 'Content-Type': 'application/json' },
      }),
    );
  }

  /**
   * Deletes the active account
   */
  async deleteAccount() {
    return await lastValueFrom(
      this.http.delete(`${environment[this.region].crudApiUrl}/User`, { headers: { 'Content-Type': 'application/json' } }),
    );
  }

  /**
   * Gets the friends of the active user
   * @returns List of Friends
   */
  async getFriends() {
    return await this.http
      .get<Friend[]>(`${environment[this.region].crudApiUrl}/User/Friends`)
      .pipe(
        map((data) => {
          this.friendSubject.next(data);
        }),
      )
      .toPromise();
  }

  /**
   * Get Friend requests for the active user
   * @returns List of Friend requests
   */
  async getRequests() {
    return await this.http
      .get<Friend[]>(`${environment[this.region].crudApiUrl}/User/Friends/Requests`)
      .pipe(
        map((data) => {
          this.requestSubject.next(data);
        }),
      )
      .toPromise();
  }

  /**
   * Accepts a friend request
   * @param username Username of request
   */
  async acceptRequest(username: string) {
    await this.addFriend(username);
    const req = this.requestSubject.value;
    const index = req.map((r) => r.Username).indexOf(username);
    req.splice(index, 1);
    this.requestSubject.next(req);
  }

  /**
   * Ignores a friend request
   * @param username
   */
  async ignoreRequest(username: string) {
    await this.http
      .put<Friend>(`${environment[this.region].crudApiUrl}/User/${username}/Friends/Ignored`, '')
      .pipe(
        map((friend) => {
          const data = this.friendSubject.value.slice(0);
          data.push(friend);
          this.friendSubject.next(data);
        }),
      )
      .toPromise();
  }

  /**
   * Add a friend based on their username
   * @param username
   */
  async addFriend(username: string) {
    await this.http
      .post<Friend>(`${environment[this.region].crudApiUrl}/User/Friends/${username}`, '')
      .pipe(
        map((friend) => {
          const data = this.friendSubject.value.slice(0);
          data.push(friend);
          this.friendSubject.next(data);
        }),
      )
      .toPromise();
  }

  /**
   * Remove a friend base on their username
   * @param username
   */
  async removeFriend(username: string) {
    await this.http
      .delete(`${environment[this.region].crudApiUrl}/User/Friends/${username}`)
      .pipe(
        map(() => {
          const data = this.friendSubject.value.slice(0);
          const index = data.map((d) => d.Username).indexOf(username);
          if (index >= 0) {
            data.splice(index, 1);
          }
          this.friendSubject.next(data);
        }),
      )
      .toPromise();
  }

  /**
   * Logs out the active user
   */
  logout() {
    this._localStorageService.clear();
    this.authService.logout();
  }

  loginRedirect() {
    if (this._msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this._msalGuardConfig.authRequest,
        extraQueryParameters: { ui_locales: this.language === LanguageCode.Korean ? 'ko-KR' : 'en-US' },
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  getCurrentAccount() {
    return this.currentAccountOption.getValue();
  }

  setCurrentAccount(optionId) {
    const option = this.accountOptions.getValue().find((a) => a.OptionId === optionId);
    if (option) {
      this._localStorageService.setItem(LocalStorageKeys.SELECTED_ACCOUNT_OPTION_ID, option.OptionId);
      this.currentAccountOption.next(option);
    }
  }

  setAccountOptions(options: EnterpriseAccount[]) {
    let list: AccountOption[] = [];
    if (this.account) {
      const idvAccount = this.account;
      list.push({
        OptionId: `${AccountOptionType.Individual}`,
        Id: idvAccount.Id,
        Type: AccountOptionType.Individual,
        Name: idvAccount.DisplayName,
      });
    }
    options.forEach((enterprise) => {
      list.push({
        OptionId: enterprise.Id,
        Id: enterprise.EnterpriseId,
        Name: enterprise.EnterpriseContract.Enterprise.Name,
        Type: AccountOptionType.Enterprise,
        Role: enterprise.Role,
        EnterpriseContract: enterprise.EnterpriseContract,
      });
    });

    this.accountOptions.next(list);
  }

  async getAccountSettings() {
    return await lastValueFrom(this.http.get<AccountSetting[]>(`${environment[this.region].crudApiUrl}/UserSetting`));
  }

  async postAccountSettings(settings: AccountSetting[]) {
    if (settings && settings.length) {
      return await this.http
        .post(`${environment[this.region].crudApiUrl}/UserSetting`, JSON.stringify(settings), {
          headers: { 'Content-Type': 'application/json' },
        })
        .toPromise();
    }
    return undefined;
  }

  async putAccountSettings(settings: AccountSetting[]) {
    if (settings && settings.length) {
      return await this.http
        .put(`${environment[this.region].crudApiUrl}/UserSetting`, JSON.stringify(settings), {
          headers: { 'Content-Type': 'application/json' },
        })
        .toPromise();
    }
    return undefined;
  }
}
