<figure #container (mouseover)="showControls = true" (mouseleave)="showControls = false">
  @if (!isStarted && !autoplay) {
    <div class="big-play-button" (click)="onPlayPause()">
      <div class="icon icon-video-play-2"></div>
    </div>
  }
  <div class="video-thumbnail" [style.background-image]="videoData.thumbnail ? 'url(' + videoData.thumbnail + ')' : 'black'"></div>
  <video
    #video
    preload="auto"
    (canplay)="isLoading = false"
    (loadedmetadata)="progress.setAttribute('max', '' + video.duration); videoDuration = video.duration"
    (timeupdate)="onTimeUpdate()"
    (click)="onPlayPause()"
    (contextmenu)="onContext($event)"
    [loop]="isLoop"
    [autoplay]="autoplay"
  >
    <source [src]="videoData.videoUrl" type="video/mp4" />
    {{ videoData.altText }}
  </video>
  @if (isLoading) {
    <ui-loader></ui-loader>
  }
  <div class="controls-section" [class.show]="isStarted && showControls">
    <div class="progress" (mouseover)="showProgressCircle = true" (mouseleave)="showProgressCircle = false">
      <progress #progress value="0" min="0" (click)="skip($event)">
        <span #progressBar></span>
      </progress>
    </div>
    <div #controls class="controls">
      <div class="left">
        <div #playPause class="button" (click)="onPlayPause()">
          @if (video.paused || video.ended) {
            <div class="icon icon-video-play"></div>
          }
          @if (!video.paused && !video.ended) {
            <div class="icon icon-pause"></div>
          }
        </div>
        <div class="button" (click)="onSkip()">
          <div class="icon icon-skip"></div>
        </div>
        <div class="volume-container">
          <div #mute class="button" (click)="onMute()">
            @if (video.muted) {
              <div class="icon icon-mute"></div>
            }
            @if (!video.muted) {
              <div class="icon icon-volume"></div>
            }
          </div>
          <div #volumeSlider class="volume-slider" (mousedown)="setVolume($event)">
            <div class="value" [style.width]="video.muted ? '0' : video.volume * 100 + 'px'">
              <div class="volume-circle"></div>
            </div>
          </div>
        </div>
        <div class="duration-container">
          <span>{{ currentTime * 1000 | date: 'mm:ss' }}</span
          >/<span>{{ videoDuration * 1000 | date: 'mm:ss' }}</span>
        </div>
      </div>
      <div class="right">
        <div class="button" (click)="isLoop = !isLoop">
          @if (!isLoop) {
            <div class="icon icon-replay-none"></div>
          }
          @if (isLoop) {
            <div class="icon icon-replay"></div>
          }
        </div>
        <div #fs class="button" (click)="onFS()">
          @if (!isFullscreen) {
            <div class="icon icon-fullscreen"></div>
          }
          @if (isFullscreen) {
            <div class="icon icon-exit-fullscreen"></div>
          }
        </div>
      </div>
    </div>
  </div>
</figure>
