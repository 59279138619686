<div class="tags-container">
  @if (editMode) {
    <input
      #input
      class="input"
      type="text"
      [maxlength]="lengthLimit > 0 ? lengthLimit : null"
      [alreadyAddedValidator]="tagsArray"
      pattern="[A-Za-z0-9\-._]*"
      [ngModel]="tag"
      (ngModelChange)="checkValidity($event)"
      [disabled]="tagsArray.length > 4"
      (keydown)="onKeydown($event, input)"
      placeholder="{{ 'shared.fields.tagPlaceholder' | translate }}"
      [ngStyle]="{ color: colour, background: bgColour }"
    />
  }
  <div class="tags">
    <div
      *ngFor="let tag of displayedTags; let i = index"
      class="tag"
      (click)="selectTag(tag)"
      [ngStyle]="{ color: colour, background: bgColour }"
    >
      <span>{{ '#' + tag }}</span>
      <div class="delete icon icon-close" *ngIf="editMode" (click)="deleteTag(i, $event)"></div>
    </div>
  </div>
</div>
