/**
 * Class for holding and downloading glTF file data
 */
export class GLTFData {
  /**
   * Initializes the glTF file object
   */
  constructor() {
    this.glTFFiles = {};
  }
  /**
   * Downloads the glTF data as files based on their names and data
   */
  downloadFiles() {
    /**
     * Checks for a matching suffix at the end of a string (for ES5 and lower)
     * @param str Source string
     * @param suffix Suffix to search for in the source string
     * @returns Boolean indicating whether the suffix was found (true) or not (false)
     */
    function endsWith(str, suffix) {
      return str.indexOf(suffix, str.length - suffix.length) !== -1;
    }
    for (const key in this.glTFFiles) {
      const link = document.createElement("a");
      document.body.appendChild(link);
      link.setAttribute("type", "hidden");
      link.download = key;
      const blob = this.glTFFiles[key];
      let mimeType;
      if (endsWith(key, ".glb")) {
        mimeType = {
          type: "model/gltf-binary"
        };
      } else if (endsWith(key, ".bin")) {
        mimeType = {
          type: "application/octet-stream"
        };
      } else if (endsWith(key, ".gltf")) {
        mimeType = {
          type: "model/gltf+json"
        };
      } else if (endsWith(key, ".jpeg") || endsWith(key, ".jpg")) {
        mimeType = {
          type: "image/jpeg" /* ImageMimeType.JPEG */
        };
      } else if (endsWith(key, ".png")) {
        mimeType = {
          type: "image/png" /* ImageMimeType.PNG */
        };
      }
      link.href = window.URL.createObjectURL(new Blob([blob], mimeType));
      link.click();
    }
  }
}
