import { Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, takeUntil } from 'rxjs';

import { ApplicationService } from '@/view/src/app/app.service';
import { delay } from '@/data/src/lib/utils/async-utils';
import { FileService } from '@/data/src/lib/services/file.service';
import { AccountPanelService } from '@/ui/src/lib/layout/account-panel/account-panel.service';
import { OxrSettingsService } from '@/ui/src/lib/layout/oxr-settings/oxr-settings.service';
import { IScene } from '@/data/src/lib/models/data/scene';
import { IAsset } from '@/data/src/lib/models/data/asset';
import { ElementType } from '@/data/src/lib/view-manager';

@UntilDestroy()
@Component({
  selector: 'view-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
})
export class CanvasComponent implements OnInit, OnDestroy {
  @ViewChild('viewCanvas', { static: true })
  viewCanvasRef!: ElementRef<HTMLCanvasElement>;
  @Input() activeModel!: IAsset | IScene;
  @Input() readonly = false;
  @Input() isHandHeldDevice = false;

  private _destroy$ = new Subject<void>();

  private canvas: HTMLCanvasElement;
  private webglContext: WebGL2RenderingContext | null;

  public progress = 0;
  public isSceneReady = false;

  isResizeHandled = false;

  get showProgressBar() {
    return Math.round(this.progress) < 100;
  }

  constructor(
    private appService: ApplicationService,
    private ngZone: NgZone,
    private _fileService: FileService,
    private _cd: ChangeDetectorRef,
    private _accountPanelService: AccountPanelService,
    private _oxrSettingsService: OxrSettingsService,
  ) {
    this.restoreContext = this.restoreContext.bind(this);
  }

  async ngOnInit(): Promise<void> {
    if (this.activeModel) {
      this.canvas = this.viewCanvasRef.nativeElement;
      this.canvas.addEventListener('focus', this.onCanvasFocus.bind(this));
      this.webglContext = this.canvas.getContext('webgl2');

      this.canvas.addEventListener('webglcontextlost', this.restoreContext);

      this.appService.setActiveModel(this.activeModel);
      await this.appService.launchViewManager(this.canvas, this.activeModel, this.isHandHeldDevice);

      this.appService.viewManagerSubject.pipe(untilDestroyed(this)).subscribe((viewManager) => {
        if (!this.isSceneReady && viewManager?.engine && viewManager?.scene) {
          if (!this.isResizeHandled) {
            const timeout = setTimeout(() => {
              viewManager.engine.resize();
              this.isResizeHandled = true;
              clearTimeout(timeout);
            }, 10);
          }
          this._cd.detectChanges();
          this.isSceneReady = true;
          this.animate();
          delay(500);
        }
      });

      this.appService.progressMap$.pipe(takeUntil(this._destroy$)).subscribe((progressMap) => {
        if (!progressMap.size) {
          this.progress = 100;
          return;
        }
        this.progress = 0;
        progressMap.forEach((progress) => {
          this.progress += Number((progress / progressMap.size).toFixed(3));
        });
      });
    }
  }

  setViewElements(elements) {
    this._fileService
      .getByIds(
        elements
          .filter(({ type, parameters: { media } }) => type === ElementType.Screen && !!media?.fileId)
          .map(
            ({
              parameters: {
                media: { fileId },
              },
            }) => fileId,
          ),
      )
      .then((thumbnails) => {
        thumbnails &&
          thumbnails.forEach(({ Id, Thumbnail }) => {
            this.appService.updateThumbnailMap(Id, Thumbnail);
          });
        this.appService.setViewElements(elements);
      });
  }

  onMove(ev) {
    ev.preventDefault();
    ev.stopPropagation();
  }

  onResize() {
    this.appService.getViewManager()?.scene?.getEngine().resize();
  }

  /**
   * Creates the animation render loop for the scene
   */
  public animate(): void {
    const viewManager = this.appService.getViewManager();
    if (!viewManager) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      const rendererLoopCallback = () => {
        viewManager.scene.render();
      };
      if (window.document.readyState !== 'loading') {
        viewManager.engine.runRenderLoop(rendererLoopCallback);
      } else {
        window.addEventListener('DOMContentLoaded', () => {
          viewManager.engine.runRenderLoop(rendererLoopCallback);
        });
      }

      window.addEventListener('resize', () => {
        viewManager.engine.resize();
      });
    });
  }

  restoreContext(event: Event) {
    event?.preventDefault();
    delay(3000);
    this.webglContext?.getExtension('WEBGL_lose_context')?.restoreContext();
    window.location.reload();
    this.canvas.removeEventListener('webglcontextlost', this.restoreContext);
  }

  ngOnDestroy(): void {
    this.appService.destroyViewManager();
    this._destroy$.next();
    this._destroy$.complete();
  }

  onCanvasFocus() {
    this._accountPanelService.closePanel();
    this._oxrSettingsService.closePanel();
  }
}
