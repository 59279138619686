import { Button, StackPanel, VirtualKeyboard } from '@babylonjs/gui';
import { GuiManager } from '../../utils/gui-manager';

const PRIMARY_1 = ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'];
const PRIMARY_2 = ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'];
const PRIMARY_3 = ['\u21E7', 'z', 'x', 'c', 'v', 'b', 'n', 'm', '\u2190'];
const SECONDARY_1 = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
const SECONDARY_2 = ['-', '/', ':', ';', '(', ')', '$', '€', '¥'];
const SECONDARY_3 = ['@', '&', '"', '.', ',', '?', '!', "'", '\u2190'];

export class MultiLayoutKeyboard extends VirtualKeyboard {
  symbolKey: Button;
  isShowingSymbols: boolean = false;

  constructor(private guiManager: GuiManager) {
    super(...arguments);
    this.addKeysRow(PRIMARY_1);
    this.addKeysRow(PRIMARY_2);
    this.addKeysRow(
      PRIMARY_3,
      PRIMARY_3.map((_row, i) => (i === PRIMARY_3.length - 1 ? { width: '80px' } : {})),
    );
    this.addKeysRow(SECONDARY_1);
    this.addKeysRow(SECONDARY_2);
    this.addKeysRow(
      SECONDARY_3,
      SECONDARY_3.map((_row, i) => (i === SECONDARY_3.length - 1 ? { width: '80px' } : {})),
    );
    this.addKeysRow(['#+=', ' ', '\u21B5'], [{ width: '80px' }, { width: '200px' }, { width: '80px' }]);

    this.symbolKey = (this.children[this.children.length - 1] as StackPanel).children[0] as Button;
    this.guiManager.assignClick(this.symbolKey, () => {
      if (!this.symbolKey?.textBlock) {
        return;
      }
      this.switchLayout();
      this.symbolKey.textBlock.text = this.isShowingSymbols ? (this.shiftState ? 'ABC' : 'abc') : '#+=';
    });
    this.switchLayout(false);
  }

  switchLayout(isShowingSymbols?: boolean) {
    this.isShowingSymbols = isShowingSymbols ?? !this.isShowingSymbols;
    this.children[0].isVisible = !this.isShowingSymbols;
    this.children[1].isVisible = !this.isShowingSymbols;
    this.children[2].isVisible = !this.isShowingSymbols;
    this.children[3].isVisible = this.isShowingSymbols;
    this.children[4].isVisible = this.isShowingSymbols;
    this.children[5].isVisible = this.isShowingSymbols;
  }
}
