import { NodeMaterialBlock } from "../nodeMaterialBlock.js";
import { NodeMaterialBlockConnectionPointTypes } from "../Enums/nodeMaterialBlockConnectionPointTypes.js";
import { NodeMaterialBlockTargets } from "../Enums/nodeMaterialBlockTargets.js";
import { RegisterClass } from "../../../Misc/typeStore.js";
/**
 * Block used to get the value of the first parameter raised to the power of the second
 */
export class PowBlock extends NodeMaterialBlock {
  /**
   * Creates a new PowBlock
   * @param name defines the block name
   */
  constructor(name) {
    super(name, NodeMaterialBlockTargets.Neutral);
    this.registerInput("value", NodeMaterialBlockConnectionPointTypes.AutoDetect);
    this.registerInput("power", NodeMaterialBlockConnectionPointTypes.AutoDetect);
    this.registerOutput("output", NodeMaterialBlockConnectionPointTypes.BasedOnInput);
    this._outputs[0]._typeConnectionSource = this._inputs[0];
    this._linkConnectionTypes(0, 1);
  }
  /**
   * Gets the current class name
   * @returns the class name
   */
  getClassName() {
    return "PowBlock";
  }
  /**
   * Gets the value operand input component
   */
  get value() {
    return this._inputs[0];
  }
  /**
   * Gets the power operand input component
   */
  get power() {
    return this._inputs[1];
  }
  /**
   * Gets the output component
   */
  get output() {
    return this._outputs[0];
  }
  _buildBlock(state) {
    super._buildBlock(state);
    const output = this._outputs[0];
    state.compilationString += this._declareOutput(output, state) + ` = pow(${this.value.associatedVariableName}, ${this.power.associatedVariableName});\n`;
    return this;
  }
}
RegisterClass("BABYLON.PowBlock", PowBlock);
