<ui-panel [title]="'oxr.elements.screen' | translate" [collapsing]="true" [panelId]="panelMask.Screen">
  <ui-radio-group
    [label]="'oxr.creatingSpace.panels.screen.ratio' | translate"
    [options]="rateOptions"
    [value]="rate"
    (onChecked)="onRateChange($event)"
  ></ui-radio-group>
  @if (isCustom) {
    <div>
      <div class="rate">
        <label for="rate-x">{{ 'oxr.creatingSpace.panels.screen.width' | translate }}: </label>
        <input uiInput id="rate-x" type="text" [(ngModel)]="width" (ngModelChange)="onWidthChange($event)" (focusout)="onChangeEnd()" />
        <span>mm</span>
      </div>
      <div class="rate">
        <label for="rate-y">{{ 'oxr.creatingSpace.panels.screen.height' | translate }}: </label>
        <input uiInput id="rate-y" type="text" [(ngModel)]="height" (ngModelChange)="onHeightChange($event)" (focusout)="onChangeEnd()" />
        <span>mm</span>
      </div>
    </div>
  }
</ui-panel>
