import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-quill-editor-custom-toolbar',
  templateUrl: './quill-editor-custom-toolbar.component.html',
  styleUrls: ['./quill-editor-custom-toolbar.component.scss'],
})
export class QuillEditorCustomToolbarComponent implements OnInit {
  @Input() hide: string[] = [];
  constructor() {}

  get isHidden() {
    return this.hide.reduce((acc, current) => {
      acc[current] = true;
      return acc;
    }, {});
  }

  ngOnInit(): void {}
}
