import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageCode } from '../services/account.service';
@Pipe({
  name: 'dateAsAgo',
})
export class DateAsAgoPipe implements PipeTransform {
  constructor(private _translateService: TranslateService) {}

  transform(value: any, ...args: unknown[]): unknown {
    if (!value) {
      return this._translateService.instant('shared.time.longTimeAgo');
    }
    let time = (Date.now() - Date.parse(value)) / 1000;
    if (time < 10) {
      return this._translateService.instant('shared.time.justNow');
    } else if (time < 60) {
      return this._translateService.instant('shared.time.momentAgo');
    }
    const divider = [60, 60, 24, 30, 12];
    const string = [
      ' ' + this._translateService.instant('shared.time.second'),
      ' ' + this._translateService.instant('shared.time.minute'),
      ' ' + this._translateService.instant('shared.time.hour'),
      ' ' + this._translateService.instant('shared.time.day'),
      ' ' + this._translateService.instant('shared.time.month'),
      ' ' + this._translateService.instant('shared.time.year'),
    ];
    let i;
    for (i = 0; Math.floor(time / divider[i]) > 0; i++) {
      time /= divider[i];
    }
    if (this._translateService.currentLang === LanguageCode.English) {
      const plural = Math.floor(time) > 1 ? 's' : '';
      return Math.floor(time) + string[i] + plural + ' ago';
    }
    return Math.floor(time) + string[i];
  }
}
