var cachedPromise;
function loadScript(src, namespace) {
  var existingElement = document.querySelector("[src=\"".concat(src, "\"]"));
  if (existingElement != null && cachedPromise !== undefined) {
    return cachedPromise;
  }
  if (existingElement != null && getNamespace(namespace) !== undefined) {
    return Promise.resolve(getNamespace(namespace));
  }
  var script = document.createElement('script');
  script.src = src;
  cachedPromise = new Promise(function (resolve, reject) {
    document.head.appendChild(script);
    window.addEventListener("TossPayments:initialize:".concat(namespace), function () {
      if (getNamespace(namespace) !== undefined) {
        resolve(getNamespace(namespace));
      } else {
        reject(new Error("[TossPayments SDK] Failed to load script: [".concat(src, "]")));
      }
    });
  });
  return cachedPromise;
}
function getNamespace(name) {
  return window[name];
}
var SCRIPT_URL = 'https://js.tosspayments.com/v1';
function loadTossPayments(clientKey) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    _ref$src = _ref.src,
    src = _ref$src === void 0 ? SCRIPT_URL : _ref$src;

  // SSR 지원
  if (typeof window === 'undefined') {
    return Promise.resolve({
      requestPayment: function requestPayment() {
        throw new Error("[TossPayments SDK] It looks like runtime is not from browser. This method is only executable on browser.");
      },
      requestBillingAuth: function requestBillingAuth() {
        throw new Error("[TossPayments SDK] It looks like runtime is not from browser. This method is only executable on browser.");
      },
      cancelPayment: function cancelPayment() {
        throw new Error("[TossPayments SDK] It looks like runtime is not from browser. This method is only executable on browser.");
      }
    });
  } // regenerator-runtime 의존성을 없애기 위해 `async` 키워드를 사용하지 않는다

  return loadScript(src, 'TossPayments').then(function (TossPayments) {
    return TossPayments(clientKey);
  });
}
function clearTossPayments() {
  var _script$parentElement;
  var script = document.querySelector("script[src=\"".concat(SCRIPT_URL, "\"]"));
  script === null || script === void 0 ? void 0 : (_script$parentElement = script.parentElement) === null || _script$parentElement === void 0 ? void 0 : _script$parentElement.removeChild(script);
  window.TossPayments = undefined;
}
export { clearTossPayments, loadTossPayments };