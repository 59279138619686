import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApplicationService } from '@/view/src/app/app.service';
import { XRAsset } from '@/data/src/lib/models/data/asset';
import { ElementType, TElement } from '@/data/src/lib/view-manager';
import { deepCopy } from '@/data/src/lib/utils/data';
import { AssetService } from '@/data/src/lib/services/asset.service';
import { PanelComponent } from '@/ui/src/lib/components/panel/panel.component';
import { AssetType } from '@/data/src/lib/models/data/asset';

const MAX_TAGS = 5;

@UntilDestroy()
@Component({
  selector: 'ui-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoPanelComponent extends PanelComponent implements OnInit {
  _element: TElement;
  assetName: string;
  bounds?: number[];
  polygonCount?: number;
  intensity: number | undefined;

  MAX_TAGS = MAX_TAGS;

  @Input() asset: XRAsset;
  @Input() set element(input: TElement | undefined) {
    if (!input) {
      return;
    }
    this._element = deepCopy(input) as TElement;
    this.assetName = this._element.name;
    if (this._element) {
      this.bounds = this._appService.getRenderableBounds(this._element);
    }
    this.intensity = this._appService.getEditorIntensity();
    this._cd.detectChanges();
  }

  get element() {
    return this._element;
  }

  get bounding() {
    return this.bounds ? `X: ${this.bounds[0].toFixed(2)}m Y: ${this.bounds[1].toFixed(2)}m Z: ${this.bounds[2].toFixed(2)}m` : '';
  }

  get isEnvironmentAsset() {
    return this.asset?.Type === AssetType.Environment;
  }

  get assetType() {
    return this.asset.Type.toLowerCase();
  }

  get tags() {
    return (
      this.asset.Tags?.split(',')
        .map((tag) => tag.trim())
        .filter((tag) => tag) || []
    );
  }

  constructor(
    protected _appService: ApplicationService,
    private _assetService: AssetService,
    private _cd: ChangeDetectorRef,
  ) {
    super(_appService);
  }

  ngOnInit() {
    this._appService.polygonCountSubject.pipe(untilDestroyed(this)).subscribe((count) => {
      this.polygonCount = count;
      this._cd.detectChanges();
    });
  }

  onFileChange(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (!file) {
      this._appService.showNotification('editor.creatingAsset.noFileSelected', 'error');
      return;
    }
    switch (this.asset.Type) {
      case AssetType.Object:
        if (file.name.split('.').pop()?.toLowerCase() !== 'glb') {
          this._appService.showNotification('editor.creatingAsset.invalidFileExtension', 'error');
          return;
        }
        this.element && this._appService.getViewRenderable(this.element)?.dispose(true);
        this._appService.setViewElements(this._appService.getViewElements(ElementType.Environment));
        this.asset.file = file;
        this.asset.Name = file.name;
        this._appService.importGeometryFromFile(file);
        break;
      case AssetType.Environment:
        if (file.name.split('.').pop()?.toLowerCase() !== 'hdr') {
          this._appService.showNotification('editor.creatingAsset.invalidFileExtension', 'error');
          return;
        }
        break;
      default:
        return;
    }
    this._cd.detectChanges();
  }

  onIntensityChange(intensity: number) {
    this._appService.setEditorIntensity(intensity);
  }

  onTagInput(event: KeyboardEvent) {
    if (event.key !== 'Enter' && event.key !== ',') {
      return;
    }
    event.preventDefault();
    const tag = (event.target as HTMLInputElement).value.replaceAll(',', '').trim().toLowerCase();
    if (tag && !this.asset.Tags?.toLowerCase().includes(tag)) {
      this.asset.addTag(tag);
    }
    (event.target as HTMLInputElement).value = '';
    this._cd.detectChanges();
  }

  removeTag(tag: string) {
    this.asset.Tags =
      this.asset.Tags?.replace(tag, '')
        .split(',')
        .filter((t) => t.trim())
        .join(',') || '';
    // this._assetService.modify(this.asset as XRAsset);
    this._cd.detectChanges();
  }

  onChangeEnd() {
    if (!this._element) {
      return;
    }
    this.asset.Name = this.assetName;
    this._appService.updateViewElement({
      ...this.element,
      name: this.assetName,
    } as TElement);
  }
}
