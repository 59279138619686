<div class="container">
  <div class="desc" [innerHTML]="'shared.information.welcome' | translate"></div>
  @if (!listOpen) {
    <div class="domain-transfer-guide" [innerHTML]="'shared.information.domainTransferGuide' | translate"></div>
  }
  <div class="supported-browser" [ngClass]="{ 'list-open': listOpen }">
    <div class="title" (click)="listOpen = !listOpen">
      <span>{{ 'shared.information.supportedBrowser' | translate }}</span>
      <div class="icon" [class.icon-arrow-down]="!listOpen" [class.icon-arrow-up]="listOpen"></div>
    </div>
    @if (listOpen) {
      <div class="info">
        <span class="os">{{ 'shared.information.supportedList.os1' | translate }}</span>
        <span class="browser">{{ 'shared.information.supportedList.browser1' | translate }}</span>
      </div>
      <div class="info">
        <span class="os">{{ 'shared.information.supportedList.os2' | translate }}</span>
        <span class="browser">{{ 'shared.information.supportedList.browser2' | translate }}</span>
      </div>
      <div class="info">
        <span class="os">{{ 'shared.information.supportedList.os3' | translate }}</span>
        <span class="browser">{{ 'shared.information.supportedList.browser3' | translate }}</span>
      </div>
      <div class="info">
        <span class="os">{{ 'shared.information.supportedList.os4' | translate }}</span>
        <span class="browser">{{ 'shared.information.supportedList.browser4' | translate }}</span>
      </div>
    }
  </div>
  <button md uiAutofocus ui-button (click)="onClick()">{{ 'shared.actions.explore' | translate }}</button>
  <div class="checkbox">
    <ui-check-box class="xs white-grey-500" [checked]="checkboxChecked" (checkedChange)="checkboxChecked = !checkboxChecked">{{
      'guideModal.dontShowAgain' | translate
    }}</ui-check-box>
  </div>
</div>
