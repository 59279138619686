<section class="body">
  <table>
    <thead>
      <tr>
        <td class="column-1">{{ 'subscription.tableHeader.title' | translate }}</td>
        <td class="column-2">
          <div class="flex-column-center">
            <div class="icon"></div>
            {{ 'subscription.tableHeader.free' | translate }}
          </div>
        </td>
        <td class="column-3">
          <div class="flex-column-center">
            <div class="icon"></div>
            {{ 'subscription.tableHeader.pro' | translate }}
          </div>
        </td>
        <td class="column-4">
          <div class="flex-column-center">
            <div class="icon"></div>
            {{ 'subscription.tableHeader.enterprise' | translate }}
          </div>
        </td>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td [innerHTML]="'subscription.tableBody.numberOfSpaceLimit.title' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.numberOfSpaceLimit.free' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.numberOfSpaceLimit.pro' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.numberOfSpaceLimit.enterprise' | translate"></td>
      </tr>
      <tr>
        <td [innerHTML]="'subscription.tableBody.object.title' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.object.free' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.object.pro' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.object.enterprise' | translate"></td>
      </tr>
      <tr>
        <td [innerHTML]="'subscription.tableBody.toolsEffect.title' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.toolsEffect.free' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.toolsEffect.pro' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.toolsEffect.enterprise' | translate"></td>
      </tr>
      <tr>
        <td [innerHTML]="'subscription.tableBody.buyUploadingAsset.title' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.buyUploadingAsset.free' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.buyUploadingAsset.pro' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.buyUploadingAsset.enterprise' | translate"></td>
      </tr>
      <tr>
        <td [innerHTML]="'subscription.tableBody.mediaStorage.title' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.mediaStorage.free' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.mediaStorage.pro' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.mediaStorage.enterprise' | translate"></td>
      </tr>
      <tr>
        <td [innerHTML]="'subscription.tableBody.publishing.title' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.publishing.free' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.publishing.pro' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.publishing.enterprise' | translate"></td>
      </tr>
      <tr>
        <td [innerHTML]="'subscription.tableBody.domain.title' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.domain.free' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.domain.pro' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.domain.enterprise' | translate"></td>
      </tr>
      <tr>
        <td [innerHTML]="'subscription.tableBody.chat.title' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.chat.free' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.chat.pro' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.chat.enterprise' | translate"></td>
      </tr>
      <tr>
        <td [innerHTML]="'subscription.tableBody.collaborator.title' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.collaborator.free' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.collaborator.pro' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.collaborator.enterprise' | translate"></td>
      </tr>
      <tr>
        <td [innerHTML]="'subscription.tableBody.mediaLibrary.title' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.mediaLibrary.free' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.mediaLibrary.pro' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.mediaLibrary.enterprise' | translate"></td>
      </tr>
      <tr>
        <td [innerHTML]="'subscription.tableBody.advertisement.title' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.advertisement.free' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.advertisement.pro' | translate"></td>
        <td [innerHTML]="'subscription.tableBody.advertisement.enterprise' | translate"></td>
      </tr>
    </tbody>
  </table>
</section>
