import { deepCopy } from '@/data/src/lib/utils/data';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TElement, TInteractableElement } from '@/data/src/lib/view-manager';
import { ApplicationService } from '@/view/src/app/app.service';
import { PanelComponent } from '@/ui/src/lib/components/panel/panel.component';

@UntilDestroy()
@Component({
  selector: 'ui-scene-interactions-panel',
  templateUrl: './scene-interactions-panel.component.html',
  styleUrls: ['./scene-interactions-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SceneInteractionsPanelComponent extends PanelComponent {
  _element: TInteractableElement;
  collisionsEnabled = false;
  shadowsEnabled = false;

  @Input() set element(input: TElement | undefined) {
    if (!input) {
      return;
    }
    this._element = deepCopy(input) as TInteractableElement;
    this.collisionsEnabled = !!this.element?.parameters.collisions;
    this.shadowsEnabled = !!this.element?.parameters.shadows;
    this._cd.detectChanges();
  }

  get element(): TInteractableElement {
    return this._element as TInteractableElement;
  }

  constructor(
    protected _appService: ApplicationService,
    private _cd: ChangeDetectorRef,
  ) {
    super(_appService);
  }

  onCollisionToggle(enableCollisions: boolean) {
    this.collisionsEnabled = enableCollisions;
    this.element &&
      this._appService.updateViewElement({
        ...this.element,
        parameters: { ...this.element.parameters, collisions: enableCollisions },
      } as TInteractableElement);
  }

  onShadowToggle(enableShadows: boolean) {
    this.shadowsEnabled = enableShadows;
    this.element &&
      this._appService.updateViewElement({
        ...this.element,
        parameters: { ...this.element.parameters, shadows: enableShadows },
      } as TInteractableElement);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
