<main [formGroup]="form">
  <div class="card favicon">
    @if (!image) {
      <div class="ownxr">
        <div class="empty-favicon"></div>
      </div>
    }
    @if (image) {
      <img class="loadedImage" [src]="image" />
    }
    <span class="text">{{ 'shared.fields.favicon' | translate }}</span>
    @if (scene.Plan === plan.Free) {
      <div class="icon icon-info xs" uiTooltip="{{ 'shared.fields.basicFaviconInfo' | translate }}"></div>
    } @else {
      <div class="icon icon-info xs" uiTooltip="{{ 'shared.fields.faviconInfo' | translate }}"></div>
    }
    <div class="right gap-8">
      @if (scene.Plan !== plan.Free) {
        <button ui-outlined-button xxs secondary (click)="onPngUpload()">{{ 'shared.actions.pngUpload' | translate }}</button>
        <button ui-outlined-button xxs secondary (click)="onDeleteFavicon()">{{ 'shared.actions.delete' | translate }}</button>
      }
    </div>
  </div>
  <div class="card name">
    <div class="top">
      <span class="title">{{ 'oxr.settings.spaceTitle' | translate }}</span>
      @if (!editName) {
        <div class="right icon icon-edit xs" (click)="onClickEdit('editName')"></div>
      }
    </div>
    <div class="bottom">
      @if (!editName && !form.get('Name')?.value) {
        <span class="text">{{ 'oxr.settings.enterTitle' | translate }}</span>
      }
      @if (!editName && !!form.get('Name')?.value) {
        <span class="text name">{{ form.get('Name')?.value }}</span>
      }
      @if (editName) {
        <input
          uiInput
          sm
          dark
          placeholder="{{ 'oxr.settings.titlePlaceholder' | translate }}"
          formControlName="Name"
          [maxlength]="sceneNamelengthLimit"
          required
        />
      }
    </div>
  </div>
  <div class="card category">
    <div class="left">
      <div class="icon icon-lock xxs"></div>
      {{ 'shared.fields.category' | translate }}
    </div>
    <!-- <input uiInput sm dark placeholder="{{ 'shared.fields.category' | translate }}" formControlName="Category" /> -->
    @if (!editCategory) {
      <div class="right icon icon-edit xs" (click)="onClickEdit('editCategory')"></div>
    }
  </div>
  <div class="card tags">
    <div class="top">
      <div class="left">
        {{ 'shared.fields.tags' | translate }}
      </div>
      @if (!editTags) {
        <div class="right icon icon-edit xs" (click)="onClickEdit('editTags')"></div>
      }
    </div>
    @if (!scene.Tags && !editTags) {
      <span class="text">{{ 'oxr.settings.enterTags' | translate }}</span>
    }
    @if (scene.Tags || editTags) {
      <ui-tag [tagsString]="scene.Tags" [lengthLimit]="lengthLimit" [editMode]="editTags" (tagsChanged)="getTagsString($event)"></ui-tag>
    }
  </div>
  <div class="card description">
    <div class="top">
      <div class="left">
        {{ 'oxr.settings.spaceDescription' | translate }}
      </div>
      @if (!editDescription) {
        <div class="right icon icon-edit xs" (click)="onClickEdit('editDescription')"></div>
      }
    </div>
    @if (!scene.Description && !editDescription) {
      <span class="text">{{ 'oxr.settings.enterDescription' | translate }}</span>
    }
    @if (!!scene.Description && !editDescription) {
      <textarea readonly uiTextarea nonResizable class="description text">{{ scene.Description }}</textarea>
    }
    @if (editDescription) {
      <textarea
        uiTextarea
        nonResizable
        rows="4"
        formControlName="Description"
        placeholder="{{ 'oxr.settings.descriptionPlaceholder' | translate }}"
        maxlength="150"
      ></textarea>
    }
  </div>
</main>
<section class="footer">
  <button ui-button fixed [disabled]="!form.valid" (click)="onSave()">{{ 'shared.actions.save' | translate }}</button>
</section>
