import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { TCustomization, TCustomizationMaterial, TElement, TObjectElement } from '@/data/src/lib/view-manager';
import { ApplicationService } from '@/view/src/app/app.service';
import { ViewManagerUtils } from '@/data/src/lib/utils/view-manager-utils';
import { PanelComponent } from '@/ui/src/lib/components/panel/panel.component';
import { deepCopy } from '@/data/src/lib/utils/data';

@Component({
  selector: 'ui-color-panel',
  templateUrl: './color-panel.component.html',
  styleUrls: ['./color-panel.component.scss'],
})
export class ColorPanelComponent extends PanelComponent {
  _element: TObjectElement;
  _material?: TCustomizationMaterial;

  diffuseColor = 'rgb(255, 255, 255)';
  emissiveColor = 'rgb(0, 0, 0)';
  ambientColor = 'rgb(0, 0, 0)';
  reflectivityColor = 'rgb(255, 255, 255)';
  reflectanceColor = 'rgb(255, 255, 255)';
  unlit = false;

  @Input() set element(input: TElement | undefined) {
    if (!input) {
      return;
    }
    this._element = deepCopy(input) as TObjectElement;
    this.material =
      this._material && this._element.parameters?.customization?.materials
        ? this._element.parameters.customization.materials[this._material.materialId]
        : undefined;
    this._cd.detectChanges();
  }

  get element(): TObjectElement {
    return this._element as TObjectElement;
  }

  @Input() set material(input: TCustomizationMaterial | undefined) {
    if (!input) {
      return;
    }
    this._material = deepCopy(input);
    this.diffuseColor = ViewManagerUtils.GetColorString(this._material.parameters.diffuseColor) ?? 'rgb(255, 255, 255)';
    this.emissiveColor = ViewManagerUtils.GetColorString(this._material.parameters.emissiveColor) ?? 'rgb(0, 0, 0)';
    this.ambientColor = ViewManagerUtils.GetColorString(this._material.parameters.ambientColor) ?? 'rgb(0, 0, 0)';
    this.reflectivityColor = ViewManagerUtils.GetColorString(this._material.parameters.reflectivityColor) ?? 'rgb(255, 255, 255)';
    this.reflectanceColor = ViewManagerUtils.GetColorString(this._material.parameters.reflectanceColor) ?? 'rgb(255, 255, 255)';
    this.unlit = this._material.parameters.unlit ?? false;
    this._cd.detectChanges();
  }

  get material(): TCustomizationMaterial | undefined {
    return this._material;
  }

  constructor(
    protected _appService: ApplicationService,
    private _cd: ChangeDetectorRef,
  ) {
    super(_appService);
  }

  // TODO: Make generic
  onColorChange(color: string, property: string) {
    this._appService.setOutlines(false);
    this[property] = color;
    this.element &&
      this.material &&
      this._appService.updateViewRenderable({
        ...this.element,
        parameters: {
          ...this.element.parameters,
          customization: {
            ...(this.element.parameters.customization ?? {}),
            materials: {
              ...(this.element.parameters.customization?.materials ?? {}),
              [this.material.materialId]: {
                ...this.material,
                parameters: {
                  ...this.material.parameters,
                  [property]: ViewManagerUtils.GetColorArray(color).slice(0, 3) as TCustomizationMaterial['parameters']['diffuseColor'],
                },
              },
            },
          } as TCustomization,
        },
      });
  }

  onUnlitToggle(unlit: boolean) {
    this.unlit = unlit;
    this.element &&
      this.material &&
      this._appService.updateViewElement({
        ...this.element,
        parameters: {
          ...this.element.parameters,
          customization: {
            ...(this.element.parameters.customization ?? {}),
            materials: {
              ...(this.element.parameters.customization?.materials ?? {}),
              [this.material.materialId]: {
                ...this.material,
                parameters: {
                  ...this.material.parameters,
                  unlit,
                },
              },
            },
          } as TCustomization,
        },
      });
  }

  onChangeEnd() {
    if (!this.element) {
      return;
    }
    const actualElement = this._appService.getViewRenderable(this.element)?.element as TObjectElement;
    if (!actualElement) {
      return;
    }
    if (
      !this.element.parameters.customization?.materials ||
      this.unlit !== !!this.material?.parameters?.unlit ||
      ViewManagerUtils.GetColorArray(this.diffuseColor)
        .slice(0, 3)
        .some((x, i) => this.material?.parameters.diffuseColor?.[i] !== x) ||
      ViewManagerUtils.GetColorArray(this.emissiveColor)
        .slice(0, 3)
        .some((x, i) => this.material?.parameters.emissiveColor?.[i] !== x) ||
      ViewManagerUtils.GetColorArray(this.ambientColor)
        .slice(0, 3)
        .some((x, i) => this.material?.parameters.ambientColor?.[i] !== x)
    ) {
      this._appService.setOutlines(true);
      this._appService.updateViewElement(actualElement);
    }
  }

  ngOnDestroy(): void {
    this.onChangeEnd();
    super.ngOnDestroy();
  }
}
