import { XRScene } from '@/data/src/lib/models/data/scene';
import { AccountService } from '@/data/src/lib/services/account.service';
import { ChatService } from '@/data/src/lib/services/chat.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  OnDestroy,
  HostListener,
  Inject,
  NgZone,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChatRoom, Message, MessageSender } from '@/data/src/lib/models/data/chat';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationComponent } from '../../modal/confirmation/confirmation.component';
import { ModalService } from '../../modal/modal.service';
import { Subscription, interval, startWith, takeWhile, timer } from 'rxjs';
import * as moment from 'moment';
import { WINDOW } from '@ng-web-apis/common';

enum limitType {
  Spam = 'Spam',
  AutoReport = 'AutoReport',
}

@UntilDestroy()
@Component({
  selector: 'ui-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent implements OnInit, OnDestroy {
  @Input() activeScene: XRScene;
  @ViewChild('scroll') scrollElementRef: ElementRef;
  @ViewChild('inputArea') inputElementRef: ElementRef;

  isLoggedInUser: boolean;
  isExpanded = true;
  showTimeStamp = false;
  inputMessage = '';
  messages: Message[] = [];
  chatRoom: ChatRoom;
  messageSender: MessageSender;

  limitDate: Date;
  blockedList: string[] = [];
  remainingTime: number;
  spamCondition = { time: 3, count: 10, timeOut: 3 * 60 * 1000 };

  spamSubscription: Subscription;
  autoReportSubscription: Subscription;

  @HostListener('window:beforeunload') handleBeforeUnload() {
    this._chatService.stopConnection();
  }

  constructor(
    private _translateService: TranslateService,
    private _modalService: ModalService,
    private _cd: ChangeDetectorRef,
    private _chatService: ChatService,
    private _accountService: AccountService,
    private _ngZone: NgZone,
    @Inject(WINDOW) private readonly _window: Window,
  ) {}

  ngOnInit() {
    this._chatService.startConnection();

    this._accountService.activeAccount$.pipe(untilDestroyed(this)).subscribe((res) => (this.isLoggedInUser = !!res));

    this._chatService.connection$.pipe(untilDestroyed(this)).subscribe((isConnected: boolean) => {
      if (isConnected) {
        this._chatService.joinChatRoom(this.activeScene, this.isLoggedInUser);
      }
    });

    this._chatService.chatRoom$.pipe(untilDestroyed(this)).subscribe((res: ChatRoom | undefined) => {
      if (res) {
        this.chatRoom = res;
        this.messageSender = res.messageSender;
        this._cd.detectChanges();
      }
    });

    this._chatService.message$.pipe(untilDestroyed(this)).subscribe((res: Message | undefined) => {
      if (res) {
        if (
          !this.blockedList.includes(res.id) ||
          (this.blockedList.includes(res.id) && res.connectionId === this.messageSender.connectionId)
        ) {
          this.messages.push(res);
          this._cd.detectChanges();
          this.scrollToBottom();

          const setCheckDate = new Date(res.dateCreated);
          setCheckDate.setSeconds(setCheckDate.getSeconds() - this.spamCondition.time);
          const spamList = this.messages.filter(
            (message) =>
              message.nickName === (this.messageSender.alias || this.messageSender.nickName) &&
              setCheckDate < new Date(message.dateCreated),
          );

          if (spamList.length >= this.spamCondition.count && !this.autoReportSubscription) {
            this.inputMessage = '';
            this.inputElementRef.nativeElement.disabled = true;
            this._cd.detectChanges();

            this._ngZone.runOutsideAngular(() => {
              const observable = interval(1000).pipe(startWith(0));
              this.remainingTime = this.spamCondition.timeOut;
              this.spamSubscription = observable
                .pipe(
                  takeWhile(() => this.updateRemainingTime(limitType.Spam, this.remainingTime)),
                  untilDestroyed(this),
                )
                .subscribe(() => {
                  this._ngZone.run(() => {});
                });
            });
          }
        }
      }
    });

    this._chatService.remainingTime$.pipe(untilDestroyed(this)).subscribe((res: number) => {
      if (res) {
        if (this.spamSubscription) {
          this.spamSubscription.unsubscribe();
        }

        this._ngZone.runOutsideAngular(() => {
          const observable = interval(1000).pipe(startWith(0));
          this.remainingTime = res;
          this.autoReportSubscription = observable
            .pipe(
              takeWhile(() => this.updateRemainingTime(limitType.AutoReport, this.remainingTime)),
              untilDestroyed(this),
            )
            .subscribe(() => {
              this._ngZone.run(() => {});
            });
        });
      }
    });
  }

  ngOnDestroy(): void {
    this._window.removeEventListener('beforeunload', this.handleBeforeUnload.bind(this));
  }

  updateRemainingTime(type: limitType, remainingTime: number): boolean {
    const inputEl = this.inputElementRef.nativeElement;
    this.remainingTime -= 1000;

    if (remainingTime <= 0) {
      inputEl.disabled = false;
      inputEl.placeholder = this._translateService.instant('chat.pleaseEnter');
      this._cd.detectChanges();
      return false;
    } else {
      inputEl.disabled = true;
      const isHours = moment.utc(remainingTime).hours() ? 'HH:mm:ss' : 'mm:ss';
      inputEl.placeholder = this._translateService.instant(`chat.blockMessageBy${type}`, {
        timeOut: moment.utc(remainingTime).format(isHours),
      });
      this._cd.detectChanges();
      return true;
    }
  }

  sendMessage() {
    if (this.inputMessage) {
      this._chatService.sendToGroup(this.inputMessage);
      this.inputMessage = '';
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.isComposing || event.keyCode === 229) return;

    if (event.code === 'Enter' && this.inputMessage) {
      this.sendMessage();
    }
  }

  scrollToBottom() {
    this.scrollElementRef?.nativeElement.lastElementChild?.scrollIntoView({ behaviour: 'smooth', block: 'end' });
  }

  onExpand() {
    this.isExpanded = !this.isExpanded;
    this._cd.detectChanges();
    this.scrollToBottom();
  }

  toggleTimeStamp() {
    this.showTimeStamp = !this.showTimeStamp;
    this._cd.detectChanges();
    this.scrollToBottom();
  }

  onBlock(id: string, nickName: string | undefined) {
    if (!this.blockedList.includes(id) && nickName) {
      const modal = this._modalService.open(ConfirmationComponent, { closeOnBackdropClick: false });

      if (modal) {
        modal.instance.title = this._translateService.instant('shared.information.information');
        modal.instance.body = this._translateService.instant('shared.confirmation.reportUser', { nickName: nickName });
        modal.instance.confirmAction = this._translateService.instant('shared.confirmation.yes');
        modal.instance.cancelAction = this._translateService.instant('shared.confirmation.cancel');
        modal.result.then((confirmed) => {
          if (confirmed) {
            this.blockedList.push(id);
            this._cd.detectChanges();
          }
        });
      }
    }
  }

  getThumbnailText(nickName: string): string {
    const words = nickName.split(' ');
    return words[words.length - 1].charAt(0);
  }
}
