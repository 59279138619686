import { Options } from 'ngx-slider-v2';
import { deepCopy } from '@/data/src/lib/utils/data';
import { ScreenRenderable, TElement, TScreenElement } from '@/data/src/lib/view-manager';
import { ApplicationService } from '@/view/src/app/app.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FileType } from '@/data/src/lib/enums/file-type';
import { PanelComponent } from '@/ui/src/lib/components/panel/panel.component';

@Component({
  selector: 'ui-media-panel',
  templateUrl: './media-panel.component.html',
  styleUrls: ['./media-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaPanelComponent extends PanelComponent implements OnInit {
  _element: TScreenElement;
  name: string;
  autoplay: boolean;
  repeat: boolean;
  volume: number;
  brightness: number;
  doubleSided: boolean;

  isVideo: boolean;

  volumeOptions: Options = { floor: 0, ceil: 100, step: 1, showSelectionBar: true };
  brightnessOptions: Options = { floor: 0, ceil: 1, step: 0.01, showSelectionBar: true };

  @Input() set element(input: TElement | undefined) {
    if (!input) {
      return;
    }
    this._element = deepCopy(input as TScreenElement);
    this.name = this._element.parameters.media?.url?.split('/').slice(-1)[0] ?? '';
    this.volume = (this._element.parameters.media?.volume ?? 1) * 100;
    this.brightness = this._element.parameters.media?.brightness ?? 1;
    this.autoplay = !!this._element.parameters.media?.autoplay;
    this.repeat = !!this._element.parameters.media?.repeat;
    this.doubleSided = !!this._element.parameters?.doubleSided;

    this.isVideo = this._element.parameters.media?.type === FileType.Video;
    this._cd.detectChanges();
  }

  get element(): TScreenElement {
    return this._element as TScreenElement;
  }

  constructor(
    protected _appService: ApplicationService,
    private _cd: ChangeDetectorRef,
  ) {
    super(_appService);
  }

  ngOnInit(): void {}

  onDelete() {
    this.element &&
      this._appService.updateViewElement({
        ...this.element,
        parameters: {
          ...this.element.parameters,
          media: undefined,
        },
      } as TScreenElement);
  }

  resize() {
    const renderable = this._appService.getViewRenderable(this.element) as ScreenRenderable;
    if (!renderable?.media || !this.element.parameters.media) {
      return;
    }
    renderable.media.update(this.element.parameters.media, false, true);
  }

  toggleAutoplay(autoplay: boolean) {
    this.element.parameters.media &&
      this._appService.updateViewElement(
        {
          ...this.element,
          parameters: {
            ...this.element.parameters,
            media: {
              ...this.element.parameters.media,
              autoplay,
            },
          },
        } as TScreenElement,
        true,
      );
  }

  toggleRepeat(repeat: boolean) {
    this.element.parameters.media &&
      this._appService.updateViewElement(
        {
          ...this.element,
          parameters: {
            ...this.element.parameters,
            media: {
              ...this.element.parameters.media,
              repeat,
            },
          },
        } as TScreenElement,
        true,
      );
  }

  onDoubleSideToggle(doubleSided: boolean) {
    this.doubleSided = doubleSided;
    this.element &&
      this._appService.updateViewElement({
        ...this.element,
        parameters: {
          ...this.element.parameters,
          doubleSided,
        },
      } as TScreenElement);
  }

  onVolumeChange(volume: number) {
    this.volume = volume;
    this.element &&
      this._appService.updateViewRenderable({
        ...this.element,
        parameters: {
          ...this.element.parameters,
          media: { ...this.element.parameters.media, volume: volume / 100 },
        },
      } as TScreenElement);
  }

  onBrightnessChange(brightness: number) {
    this.brightness = brightness;
    this.element &&
      this._appService.updateViewRenderable({
        ...this.element,
        parameters: {
          ...this.element.parameters,
          media: { ...this.element.parameters.media, brightness },
        },
      } as TScreenElement);
  }

  onChangeEnd() {
    if (!this.element) {
      return;
    }
    const actualElement = this.element && this._appService.getViewRenderable(this.element)?.element;
    if (!actualElement) {
      return;
    }
    if (this.element.parameters.media?.volume !== this.volume / 100 || this.element.parameters.media?.brightness !== this.brightness) {
      this._appService.updateViewElement(actualElement);
    }
  }
}
