import { GLTFLoader } from "../glTFLoader.js";
const NAME = "KHR_xmp_json_ld";
/**
 * [Specification](https://github.com/KhronosGroup/glTF/blob/main/extensions/2.0/Khronos/KHR_xmp_json_ld/README.md)
 * @since 5.0.0
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class KHR_xmp_json_ld {
  /**
   * @internal
   */
  constructor(loader) {
    /**
     * The name of this extension.
     */
    this.name = NAME;
    /**
     * Defines a number that determines the order the extensions are applied.
     */
    this.order = 100;
    this._loader = loader;
    this.enabled = this._loader.isExtensionUsed(NAME);
  }
  /** @internal */
  dispose() {
    this._loader = null;
  }
  /**
   * Called after the loader state changes to LOADING.
   */
  onLoading() {
    var _a, _b, _c;
    if (this._loader.rootBabylonMesh === null) {
      return;
    }
    const xmp_gltf = (_a = this._loader.gltf.extensions) === null || _a === void 0 ? void 0 : _a.KHR_xmp_json_ld;
    const xmp_node = (_c = (_b = this._loader.gltf.asset) === null || _b === void 0 ? void 0 : _b.extensions) === null || _c === void 0 ? void 0 : _c.KHR_xmp_json_ld;
    if (xmp_gltf && xmp_node) {
      const packet = +xmp_node.packet;
      if (xmp_gltf.packets && packet < xmp_gltf.packets.length) {
        this._loader.rootBabylonMesh.metadata = this._loader.rootBabylonMesh.metadata || {};
        this._loader.rootBabylonMesh.metadata.xmp = xmp_gltf.packets[packet];
      }
    }
  }
}
GLTFLoader.RegisterExtension(NAME, loader => new KHR_xmp_json_ld(loader));
