import { __decorate } from "@babylonjs/core/tslib.es6.js";
import { Color4 } from "@babylonjs/core/Maths/math.color.js";
import { Constants } from "@babylonjs/core/Engines/constants.js";
import { EffectFallbacks } from "@babylonjs/core/Materials/effectFallbacks.js";
import { MaterialDefines } from "@babylonjs/core/Materials/materialDefines.js";
import { MaterialHelper } from "@babylonjs/core/Materials/materialHelper.js";
import { PushMaterial } from "@babylonjs/core/Materials/pushMaterial.js";
import { RegisterClass } from "@babylonjs/core/Misc/typeStore.js";
import { SerializationHelper, serialize } from "@babylonjs/core/Misc/decorators.js";
import { Vector3 } from "@babylonjs/core/Maths/math.vector.js";
import { VertexBuffer } from "@babylonjs/core/Buffers/buffer.js";
import { Texture } from "@babylonjs/core/Materials/Textures/texture.js";
import "./shaders/mrdlFrontplate.fragment.js";
import "./shaders/mrdlFrontplate.vertex.js";
/** @hidden */
class MRDLFrontplateMaterialDefines extends MaterialDefines {
  constructor() {
    super();
    /**
     * Sets default value for "SMOOTH_EDGES"
     */
    this.SMOOTH_EDGES = true;
    this._needNormals = true;
    this._needUVs = true;
    this.rebuild();
  }
}
export class MRDLFrontplateMaterial extends PushMaterial {
  constructor(name, scene) {
    super(name, scene);
    /**
     * Gets or sets the corner radius on the frontplate. If this value is changed, update the lineWidth to match.
     */
    this.radius = 0.12;
    /**
     * Gets or sets the line width of the frontplate.
     */
    this.lineWidth = 0.01;
    /**
     * Gets or sets whether the scale is relative to the frontplate height.
     */
    this.relativeToHeight = false;
    /** @hidden */
    this._filterWidth = 1.0;
    /**
     * Gets or sets the edge color of the frontplate.
     */
    this.edgeColor = new Color4(0.53, 0.53, 0.53, 1);
    /**
     * Gets or sets whether to enable blob effects on the frontplate.
     */
    this.blobEnable = true;
    /**
     * Gets or sets the blob position on the frontplate.
     */
    this.blobPosition = new Vector3(100, 100, 100);
    /**
     * Gets or sets the blob intensity of the frontplate.
     */
    this.blobIntensity = 0.5;
    /**
     * Gets or sets the blob near size of the frontplate.
     */
    this.blobNearSize = 0.032;
    /**
     * Gets or sets the blob far size of the frontplate.
     */
    this.blobFarSize = 0.048;
    /**
     * Gets or sets the blob near distance of the frontplate.
     */
    this.blobNearDistance = 0.008;
    /**
     * Gets or sets the blob far distance of the frontplate.
     */
    this.blobFarDistance = 0.064;
    /**
     * Gets or sets the blob fade length of the frontplate.
     */
    this.blobFadeLength = 0.04;
    /**
     * Gets or sets the blob inner fade of the frontplate.
     */
    this.blobInnerFade = 0.01;
    /**
     * Gets or sets the blob pulse of the frontplate.
     */
    this.blobPulse = 0.0;
    /**
     * Gets or sets the blob fade effect on the frontplate.
     */
    this.blobFade = 1.0;
    /**
     * Gets or sets the maximum size of the blob pulse on the frontplate.
     */
    this.blobPulseMaxSize = 0.05;
    /**
     * Gets or sets whether to enable extra blob effects of the frontplate.
     */
    this.blobEnable2 = true;
    /**
     * Gets or sets blob2 position of the frontplate.
     */
    this.blobPosition2 = new Vector3(10, 10.1, -0.6);
    /**
     * Gets or sets the blob2 near size of the frontplate.
     */
    this.blobNearSize2 = 0.008;
    /**
     * Gets or sets the blob2 inner fade of the frontplate.
     */
    this.blobInnerFade2 = 0.1;
    /**
     * Gets or sets the blob2 pulse of the frontplate.
     */
    this.blobPulse2 = 0.0;
    /**
     * Gets or sets the blob2 fade effect on the frontplate.
     */
    this.blobFade2 = 1.0;
    /**
     * Gets or sets the gaze intensity of the frontplate.
     */
    this.gazeIntensity = 0.8;
    /**
     * Gets or sets the gaze focus of the frontplate.
     */
    this.gazeFocus = 0.0;
    /**
     * Gets or sets the selection fuzz of the frontplate.
     */
    this.selectionFuzz = 0.5;
    /**
     * Gets or sets the fade intensity of the frontplate.
     */
    this.selected = 1.0;
    /**
     * Gets or sets the selection fade intensity of the frontplate.
     */
    this.selectionFade = 0.2;
    /**
     * Gets or sets the selection fade size of the frontplate.
     */
    this.selectionFadeSize = 0.0;
    /**
     * Gets or sets the selected distance of the frontplate.
     */
    this.selectedDistance = 0.08;
    /**
     * Gets or sets the selected fade length of the frontplate.
     */
    this.selectedFadeLength = 0.08;
    /**
     * Gets or sets the proximity maximum intensity of the frontplate.
     */
    this.proximityMaxIntensity = 0.45;
    /**
     * Gets or sets the proximity far distance of the frontplate.
     */
    this.proximityFarDistance = 0.16;
    /**
     * Gets or sets the proximity near radius of the frontplate.
     */
    this.proximityNearRadius = 0.016;
    /**
     * Gets or sets the proximity anisotropy of the frontplate.
     */
    this.proximityAnisotropy = 1.0;
    /**
     * Gets or sets whether to use global left index on the frontplate.
     */
    this.useGlobalLeftIndex = true;
    /**
     * Gets or sets  whether to use global right index of the frontplate.
     */
    this.useGlobalRightIndex = true;
    /**
     * Gets or sets the opacity of the frontplate (0.0 - 1.0).
     */
    this.fadeOut = 1.0;
    this.alphaMode = Constants.ALPHA_ADD;
    this.disableDepthWrite = true;
    this.backFaceCulling = false;
    this._blobTexture = new Texture(MRDLFrontplateMaterial.BLOB_TEXTURE_URL, scene, true, false, Texture.NEAREST_SAMPLINGMODE);
  }
  needAlphaBlending() {
    return true;
  }
  needAlphaTesting() {
    return false;
  }
  getAlphaTestTexture() {
    return null;
  }
  // Methods
  isReadyForSubMesh(mesh, subMesh) {
    if (this.isFrozen) {
      if (subMesh.effect && subMesh.effect._wasPreviouslyReady) {
        return true;
      }
    }
    if (!subMesh.materialDefines) {
      subMesh.materialDefines = new MRDLFrontplateMaterialDefines();
    }
    const defines = subMesh.materialDefines;
    const scene = this.getScene();
    if (this._isReadyForSubMesh(subMesh)) {
      return true;
    }
    const engine = scene.getEngine();
    // Attribs
    MaterialHelper.PrepareDefinesForAttributes(mesh, defines, false, false);
    // Get correct effect
    if (defines.isDirty) {
      defines.markAsProcessed();
      scene.resetCachedMaterial();
      // Fallbacks
      const fallbacks = new EffectFallbacks();
      if (defines.FOG) {
        fallbacks.addFallback(1, "FOG");
      }
      MaterialHelper.HandleFallbacksForShadows(defines, fallbacks);
      defines.IMAGEPROCESSINGPOSTPROCESS = scene.imageProcessingConfiguration.applyByPostProcess;
      //Attributes
      const attribs = [VertexBuffer.PositionKind];
      if (defines.NORMAL) {
        attribs.push(VertexBuffer.NormalKind);
      }
      if (defines.UV1) {
        attribs.push(VertexBuffer.UVKind);
      }
      if (defines.UV2) {
        attribs.push(VertexBuffer.UV2Kind);
      }
      if (defines.VERTEXCOLOR) {
        attribs.push(VertexBuffer.ColorKind);
      }
      if (defines.TANGENT) {
        attribs.push(VertexBuffer.TangentKind);
      }
      MaterialHelper.PrepareAttributesForInstances(attribs, defines);
      // Legacy browser patch
      const shaderName = "mrdlFrontplate";
      const join = defines.toString();
      const uniforms = ["world", "worldView", "worldViewProjection", "view", "projection", "viewProjection", "cameraPosition", "_Radius_", "_Line_Width_", "_Relative_To_Height_", "_Filter_Width_", "_Edge_Color_", "_Fade_Out_", "_Smooth_Edges_", "_Blob_Enable_", "_Blob_Position_", "_Blob_Intensity_", "_Blob_Near_Size_", "_Blob_Far_Size_", "_Blob_Near_Distance_", "_Blob_Far_Distance_", "_Blob_Fade_Length_", "_Blob_Inner_Fade_", "_Blob_Pulse_", "_Blob_Fade_", "_Blob_Pulse_Max_Size_", "_Blob_Enable_2_", "_Blob_Position_2_", "_Blob_Near_Size_2_", "_Blob_Inner_Fade_2_", "_Blob_Pulse_2_", "_Blob_Fade_2_", "_Gaze_Intensity_", "_Gaze_Focus_", "_Blob_Texture_", "_Selection_Fuzz_", "_Selected_", "_Selection_Fade_", "_Selection_Fade_Size_", "_Selected_Distance_", "_Selected_Fade_Length_", "_Proximity_Max_Intensity_", "_Proximity_Far_Distance_", "_Proximity_Near_Radius_", "_Proximity_Anisotropy_", "Global_Left_Index_Tip_Position", "Global_Right_Index_Tip_Position", "_Use_Global_Left_Index_", "_Use_Global_Right_Index_"];
      const samplers = [];
      const uniformBuffers = [];
      MaterialHelper.PrepareUniformsAndSamplersList({
        uniformsNames: uniforms,
        uniformBuffersNames: uniformBuffers,
        samplers: samplers,
        defines: defines,
        maxSimultaneousLights: 4
      });
      subMesh.setEffect(scene.getEngine().createEffect(shaderName, {
        attributes: attribs,
        uniformsNames: uniforms,
        uniformBuffersNames: uniformBuffers,
        samplers: samplers,
        defines: join,
        fallbacks: fallbacks,
        onCompiled: this.onCompiled,
        onError: this.onError,
        indexParameters: {
          maxSimultaneousLights: 4
        }
      }, engine), defines);
    }
    if (!subMesh.effect || !subMesh.effect.isReady()) {
      return false;
    }
    defines._renderId = scene.getRenderId();
    subMesh.effect._wasPreviouslyReady = true;
    return true;
  }
  bindForSubMesh(world, mesh, subMesh) {
    const scene = this.getScene();
    const defines = subMesh.materialDefines;
    if (!defines) {
      return;
    }
    const effect = subMesh.effect;
    if (!effect) {
      return;
    }
    this._activeEffect = effect;
    // Matrices
    this.bindOnlyWorldMatrix(world);
    this._activeEffect.setMatrix("viewProjection", scene.getTransformMatrix());
    this._activeEffect.setVector3("cameraPosition", scene.activeCamera.position);
    // "Round Rect"
    this._activeEffect.setFloat("_Radius_", this.radius);
    this._activeEffect.setFloat("_Line_Width_", this.lineWidth);
    this._activeEffect.setFloat("_Relative_To_Height_", this.relativeToHeight ? 1.0 : 0.0);
    this._activeEffect.setFloat("_Filter_Width_", this._filterWidth);
    this._activeEffect.setDirectColor4("_Edge_Color_", this.edgeColor);
    // "Fade"
    this._activeEffect.setFloat("_Fade_Out_", this.fadeOut);
    // "Blob"
    this._activeEffect.setFloat("_Blob_Enable_", this.blobEnable ? 1.0 : 0.0);
    this._activeEffect.setVector3("_Blob_Position_", this.blobPosition);
    this._activeEffect.setFloat("_Blob_Intensity_", this.blobIntensity);
    this._activeEffect.setFloat("_Blob_Near_Size_", this.blobNearSize);
    this._activeEffect.setFloat("_Blob_Far_Size_", this.blobFarSize);
    this._activeEffect.setFloat("_Blob_Near_Distance_", this.blobNearDistance);
    this._activeEffect.setFloat("_Blob_Far_Distance_", this.blobFarDistance);
    this._activeEffect.setFloat("_Blob_Fade_Length_", this.blobFadeLength);
    this._activeEffect.setFloat("_Blob_Inner_Fade_", this.blobInnerFade);
    this._activeEffect.setFloat("_Blob_Pulse_", this.blobPulse);
    this._activeEffect.setFloat("_Blob_Fade_", this.blobFade);
    this._activeEffect.setFloat("_Blob_Pulse_Max_Size_", this.blobPulseMaxSize);
    // "Blob 2"
    this._activeEffect.setFloat("_Blob_Enable_2_", this.blobEnable2 ? 1.0 : 0.0);
    this._activeEffect.setVector3("_Blob_Position_2_", this.blobPosition2);
    this._activeEffect.setFloat("_Blob_Near_Size_2_", this.blobNearSize2);
    this._activeEffect.setFloat("_Blob_Inner_Fade_2_", this.blobInnerFade2);
    this._activeEffect.setFloat("_Blob_Pulse_2_", this.blobPulse2);
    this._activeEffect.setFloat("_Blob_Fade_2_", this.blobFade2);
    // "Gaze"
    this._activeEffect.setFloat("_Gaze_Intensity_", this.gazeIntensity);
    this._activeEffect.setFloat("_Gaze_Focus_", this.gazeFocus);
    // "Blob Texture"
    this._activeEffect.setTexture("_Blob_Texture_", this._blobTexture);
    // "Selection"
    this._activeEffect.setFloat("_Selection_Fuzz_", this.selectionFuzz);
    this._activeEffect.setFloat("_Selected_", this.selected);
    this._activeEffect.setFloat("_Selection_Fade_", this.selectionFade);
    this._activeEffect.setFloat("_Selection_Fade_Size_", this.selectionFadeSize);
    this._activeEffect.setFloat("_Selected_Distance_", this.selectedDistance);
    this._activeEffect.setFloat("_Selected_Fade_Length_", this.selectedFadeLength);
    // "Proximity"
    this._activeEffect.setFloat("_Proximity_Max_Intensity_", this.proximityMaxIntensity);
    this._activeEffect.setFloat("_Proximity_Far_Distance_", this.proximityFarDistance);
    this._activeEffect.setFloat("_Proximity_Near_Radius_", this.proximityNearRadius);
    this._activeEffect.setFloat("_Proximity_Anisotropy_", this.proximityAnisotropy);
    // "Global"
    this._activeEffect.setFloat("_Use_Global_Left_Index_", this.useGlobalLeftIndex ? 1.0 : 0.0);
    this._activeEffect.setFloat("_Use_Global_Right_Index_", this.useGlobalRightIndex ? 1.0 : 0.0);
    // "Antialiasing"
    //define SMOOTH_EDGES true;
    this._afterBind(mesh, this._activeEffect);
  }
  /**
   * Get the list of animatables in the material.
   * @returns the list of animatables object used in the material
   */
  getAnimatables() {
    return [];
  }
  dispose(forceDisposeEffect) {
    super.dispose(forceDisposeEffect);
  }
  clone(name) {
    return SerializationHelper.Clone(() => new MRDLFrontplateMaterial(name, this.getScene()), this);
  }
  serialize() {
    const serializationObject = SerializationHelper.Serialize(this);
    serializationObject.customType = "BABYLON.MRDLFrontplateMaterial";
    return serializationObject;
  }
  getClassName() {
    return "MRDLFrontplateMaterial";
  }
  // Statics
  static Parse(source, scene, rootUrl) {
    return SerializationHelper.Parse(() => new MRDLFrontplateMaterial(source.name, scene), source, scene, rootUrl);
  }
}
/**
 * URL pointing to the texture used to define the coloring for the BLOB.
 */
MRDLFrontplateMaterial.BLOB_TEXTURE_URL = "";
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "radius", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "lineWidth", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "relativeToHeight", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "edgeColor", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "blobEnable", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "blobPosition", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "blobIntensity", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "blobNearSize", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "blobFarSize", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "blobNearDistance", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "blobFarDistance", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "blobFadeLength", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "blobInnerFade", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "blobPulse", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "blobFade", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "blobPulseMaxSize", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "blobEnable2", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "blobPosition2", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "blobNearSize2", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "blobInnerFade2", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "blobPulse2", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "blobFade2", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "gazeIntensity", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "gazeFocus", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "selectionFuzz", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "selected", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "selectionFade", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "selectionFadeSize", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "selectedDistance", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "selectedFadeLength", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "proximityMaxIntensity", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "proximityFarDistance", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "proximityNearRadius", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "proximityAnisotropy", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "useGlobalLeftIndex", void 0);
__decorate([serialize()], MRDLFrontplateMaterial.prototype, "useGlobalRightIndex", void 0);
RegisterClass("BABYLON.GUI.MRDLFrontplateMaterial", MRDLFrontplateMaterial);
