import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { IndividualConfigExtended } from './toast-config-extension';

@Component({
  selector: 'oxr-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('flyInOut', [
      state(
        'inactive',
        style({
          opacity: 0,
        }),
      ),
      transition(
        'inactive => active',
        animate(
          '300ms ease-out',
          keyframes([
            style({
              opacity: 0,
              transform: 'translateX(110%)',
            }),
            style({
              transform: 'translateX(0)',
              opacity: 1,
            }),
          ]),
        ),
      ),
      transition(
        'active => removed',
        animate(
          '300ms ease-out',
          keyframes([
            style({
              opacity: 1,
            }),
            style({
              transform: 'translateX(150%)',
              opacity: 0,
            }),
          ]),
        ),
      ),
    ]),
  ],
  preserveWhitespaces: false,
})
export class ToastComponent extends Toast {
  @HostBinding('@flyInOut') get flyInOut() {
    return this.state;
  }

  extendedOptions: IndividualConfigExtended;
  toastType: string;

  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
    this.toastType = toastPackage.toastType;
    this.extendedOptions = this.options as IndividualConfigExtended;
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    this.toastrService.remove(this.toastPackage.toastId);
    return false;
  }
}
