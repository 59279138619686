import { Directive, ElementRef, HostListener, Inject, Input, NgZone, Renderer2 } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { interval, take } from 'rxjs';

export enum TooltipPosition {
  Above = 'above',
  Below = 'below',
  Right = 'right',
  Left = 'left',
  Pointer = 'pointer',
}

export enum TooltipAnimation {
  Undefined = 'undefined',
  VerticalBounce = 'verticalBounce',
}

@UntilDestroy()
@Directive({
  selector: '[uiTooltip]',
})
export class TooltipDirective {
  @Input() uiTooltip = ''; // The text for the tooltip to display
  @Input() tooltipPosition = TooltipPosition.Above;
  @Input() tooltipAnimation = TooltipAnimation.Undefined;
  @Input() tooltipDelay = 175;
  @Input() tooltipClasses = '';
  @Input() above = '10';
  @Input() left = '10';
  @Input() below = '10';
  @Input() right = '10';

  private _tooltip: HTMLElement | null;

  get _above() {
    return Number(this.above);
  }
  get _below() {
    return Number(this.below);
  }
  get _left() {
    return Number(this.left);
  }
  get _right() {
    return Number(this.right);
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private _ngZone: NgZone,
    @Inject(WINDOW) private readonly _window: Window,
  ) {}

  @HostListener('mouseenter', ['$event']) onMouseEnter() {
    if (!this._tooltip && this.uiTooltip) {
      this.show();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this._tooltip) {
      this.hide();
    }
  }
  @HostListener('window:mousedown') onWindowClick() {
    if (this._tooltip) {
      this.hide();
    }
  }
  @HostListener('window:keydown') onKeydown() {
    if (this._tooltip) {
      this.hide();
    }
  }

  show() {
    this.create();
    this.setPosition();
    this.renderer.addClass(this._tooltip, 'ng-tooltip-show');

    if (this.tooltipAnimation === TooltipAnimation.VerticalBounce) {
      this.renderer.addClass(this._tooltip, 'vertical-bounce-animation');
    }
  }

  hide() {
    this.renderer.removeClass(this._tooltip, 'ng-tooltip-show');

    if (this.tooltipAnimation === TooltipAnimation.VerticalBounce) {
      this.renderer.addClass(this._tooltip, 'vertical-bounce-animation');
    }

    this._ngZone.runOutsideAngular(() => {
      interval(this.tooltipDelay)
        .pipe(take(1), untilDestroyed(this))
        .subscribe(() => {
          if (this.renderer && this._tooltip) {
            this.renderer.removeChild(this._window.document.body, this._tooltip);
            this._tooltip = null;
            this._ngZone.run(() => {});
          }
        });
    });
  }

  create() {
    this._tooltip = this.renderer.createElement('span');

    if (this.tooltipClasses) {
      this.renderer.addClass(this._tooltip, this.tooltipClasses);
    }

    this.renderer.setProperty(this._tooltip, 'innerHTML', this.uiTooltip);

    this.renderer.appendChild(this._window.document.body, this._tooltip);

    this.renderer.addClass(this._tooltip, 'ng-tooltip');
    this.renderer.addClass(this._tooltip, `ng-tooltip-${this.tooltipPosition}`);

    this.renderer.setStyle(this._tooltip, '-webkit-transition', `opacity ${this.tooltipDelay}ms`);
    this.renderer.setStyle(this._tooltip, '-moz-transition', `opacity ${this.tooltipDelay}ms`);
    this.renderer.setStyle(this._tooltip, '-o-transition', `opacity ${this.tooltipDelay}ms`);
    this.renderer.setStyle(this._tooltip, 'transition', `opacity ${this.tooltipDelay}ms`);
  }

  setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();

    const tooltipPos = (<HTMLElement>this._tooltip).getBoundingClientRect();

    const scrollPos = this._window.scrollY || this._window.document.documentElement.scrollTop || this._window.document.body.scrollTop || 0;

    let top, left;

    if (this.tooltipPosition === TooltipPosition.Above) {
      top = hostPos.top - tooltipPos.height - this._above;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.tooltipPosition === TooltipPosition.Below) {
      top = hostPos.bottom + this._below;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.tooltipPosition === TooltipPosition.Left) {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this._left;
    }

    if (this.tooltipPosition === TooltipPosition.Right) {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this._right;
    }

    if (this.tooltipPosition === TooltipPosition.Pointer) {
      top = hostPos.bottom - 50;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2 + 50;
    }

    this.renderer.setStyle(this._tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this._tooltip, 'left', `${left}px`);
  }
}
