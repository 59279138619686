import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../../modal.service';

@Component({
  selector: 'ui-save-gallery',
  templateUrl: './save-gallery.component.html',
  styleUrls: ['./save-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveGalleryComponent implements OnInit {
  modalRef: ModalService;
  selectedSlider: 'auto' | 'manual' = 'auto';
  seconds: 3 | 5 | 10 = 3;

  constructor(private _translateService: TranslateService) {}

  ngOnInit(): void {}

  close() {
    this.modalRef.close(false);
  }

  save() {
    this.modalRef.close(this.selectedSlider === 'auto' ? this.seconds : 0);
  }
}
