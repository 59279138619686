<div class="confirmation">
  <div class="header">{{ 'shared.actions.save' | translate }}</div>
  <div class="body">
    <div class="slide">
      <ui-switch
        [onContent]="'oxr.creatingSpace.video.autoplay' | translate"
        [checked]="selectedSlider"
        [offContent]="'oxr.creatingSpace.video.manualPlay' | translate"
        [onShowContentMode]="true"
        size="lg"
        (changed)="selectedSlider = $event"
      ></ui-switch>
    </div>
    <div class="footer">
      <button ui-button sm (click)="save()">{{ 'shared.actions.save' | translate }}</button>
      <button ui-button sm secondary (click)="close()">{{ 'shared.actions.cancel' | translate }}</button>
    </div>
  </div>
</div>
