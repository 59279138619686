import { ChangeDetectorRef, Component, HostBinding, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Observable, skip, take, combineLatest, firstValueFrom, catchError, of, filter, interval } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { base64ToFile } from 'ngx-image-cropper';
import { WINDOW } from '@ng-web-apis/common';
import * as uuid from 'uuid';
import { Access, FileStorageType } from '@/data/src/lib/enums/access';
import { FileType, MediaType } from '@/data/src/lib/enums/file-type';
import { RouteParam } from '@/data/src/lib/enums/route-param';
import { FileReference } from '@/data/src/lib/models/data/file-reference';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { AssetService } from '@/data/src/lib/services/asset.service';
import { DataCacheService } from '@/data/src/lib/services/data-cache.service';
import { FileService } from '@/data/src/lib/services/file.service';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { UserSettingsService } from '@/data/src/lib/services/user-settings.service';
import { groupData } from '@/data/src/lib/utils/collection';
import { OxrSettingsService } from '@/ui/src/lib/layout/oxr-settings/oxr-settings.service';
import { ConfirmationComponent } from '@/ui/src/lib/modal/confirmation/confirmation.component';
import { GuideModalComponent } from '@/ui/src/lib/modal/guide/guide.component';
import { InformationComponent } from '@/ui/src/lib/modal/information/information.component';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { ApplicationService } from '@/view/src/app/app.service';
import { CanvasComponent } from '@/view/src/app/canvas/canvas.component';
import { ScenePlan, ScenePlanDetail } from '@/data/src/lib/enums/pricing-plan';
import { AccountService } from '@/data/src/lib/services/account.service';
import { EnterpriseRole } from '@/data/src/lib/models/data/enterprise';
import {
  ElementType,
  LightType,
  LIGHT_INTENSITES,
  MAX_LIGHTS,
  TEnvironmentElement,
  TLandscapeElement,
  TLightElement,
  TStageElement,
  ViewTool,
  ViewMode,
  OBJECT_POSITION,
  OBJECT_QUATERNION,
  COLLIDABLE_TYPES,
  INTERACTABLE_TYPES,
  TInteractableElement,
  TElement,
  LIGHT_ANGLE,
  LIGHT_DIFFUSE,
  LIGHT_DIRECTIONS,
  LIGHT_POSITION,
  STATIC_TYPES,
  TransformationMode,
  PopupType,
  EffectType,
  TPopup,
  TEffect,
  TEXT_FONT_WEIGHT,
  TEXT_FONT_SIZE,
  TEXT_FONT_FAMILY,
  TextAlignment,
  TEXT_LINE_HEIGHT,
  TEXT_LETTER_SPACING,
  TEXT_POSITION,
  TEXT_QUATERNION,
  TEXT_SCALING,
  POPUP_COLOR,
  InteractionTrigger,
  EFFECT_DEFAULTS,
  TInteractionParameter,
  LIGHT_GROUND,
  LIGHT_BIAS,
  LIGHT_NORMAL_BIAS,
  TEXT_PADDING,
  TScreenElement,
  SCREEN_SCALING,
  TMedia,
  TCustomizationMaterial,
} from '@/data/src/lib/view-manager';
import { ProgressComponent } from '@/ui/src/lib/modal/progress/progress.component';
import { EditorControlsInfoComponent } from '@/ui/src/lib/modal/scene/editor-controls-info/editor-controls-info.component';
import { TooltipPosition } from '@/ui/src/lib/directive/tooltip.directive';
import { LocalStorageKeys } from '@/data/src/lib/enums/storage-keys';
import { ExitWithoutSavingInterface } from '@/data/src/lib/guards/exit-without-saving.guard';
import { LocalStorageService } from '@/data/src/lib/services/local-storage.service';
import { PricingPlanService } from '@/data/src/lib/services/pricing-plan.service';
import { Permission } from '@/data/src/lib/enums/permission';
import { SceneFileService } from '@/data/src/lib/services/scene-file.service';
import { Account, AccountOption } from '@/data/src/lib/models/data/account';
import { AllocatedMedia } from '@/data/src/lib/models/data/scene-file';
import { AssetType, IAsset, TObjectParameters } from '@/data/src/lib/models/data/asset';
import { ViewManagerUtils } from '@/data/src/lib/utils/view-manager-utils';
import { deepCopy } from '@/data/src/lib/utils/data';

const DISABLED_EFFECTS = [EffectType.Bounce, EffectType.Move, EffectType.Roll];

export enum PanelMask {
  None = 0,
  Effect = 1,
  Environment = 2,
  Info = 4,
  Light = 8,
  Media = 16,
  Popup = 32,
  Screen = 64,
  Text = 128,
  Transformation = 256,
  Avatar = 512,
  SceneInteractions = 1024,
  Materials = 2048,
  Colors = 4096,
  AssetVersions = 8192,
}

export const THUMBNAIL_CANVAS_WIDTH = 1405;
export const THUMBNAIL_CANVAS_HEIGHT = 900;
export const THUMBNAIL_WIDTH = 813;
export const THUMBNAIL_HEIGHT = 524;

const INFO_RIGHT_OFFSET = 250;
const INFO_DOWN_OFFSET = 175;
const INFO_UP_OFFSET = 75;
const INFO_HEIGHT_OFFSET = 300;

enum ElementLibrary {
  None,
  Environment,
  Object,
  Image,
  Light,
  Tools,
  Layers,
}

enum AssetSource {
  Owned = 'Owned',
  Market = 'Market',
}

@UntilDestroy()
@Component({
  selector: 'app-oxr-space',
  templateUrl: './oxr-space.component.html',
  styleUrls: ['./oxr-space.component.scss'],
})
export class OxrSpaceComponent implements ExitWithoutSavingInterface, OnInit, OnDestroy {
  @ViewChild(CanvasComponent) canvas;
  @ViewChild('uncroppedImage', { static: false }) uncroppedImage: any;
  @ViewChild('captureZone', { static: false }) captureZone: any;

  public selectedElementLibrary = ElementLibrary.None;
  public selectedAssetSource: AssetSource = AssetSource.Owned;
  public selectedElementType: ElementType = ElementType.Object;
  public selectedFileType: FileType = FileType.Image;
  public activeScene: XRScene | undefined = undefined;
  public assetsLoaded = false;
  public assets!: Map<string, IAsset[]>;
  public images!: FileReference[];
  public videos!: FileReference[];
  public filesLoaded = false;

  public transformationMode = TransformationMode.Translate;

  public asset!: IAsset;

  assetTypes = AssetType;
  public assetMap = new Map<string, boolean>();

  public transformationInfoScreenCoords$: Observable<{ x: number; y: number } | undefined>;

  public hoverActive$: Observable<boolean>;

  public isSettingsPanelOpened$ = this._oxrSettingsService.isPanelOpened$;

  public selectedElement?: TElement;

  public materials: TCustomizationMaterial[] = [];
  public selectedMaterial?: TCustomizationMaterial;

  private panelMask: PanelMask;
  public isAllowedPanelToggling = false;

  public currentEffectData: any = null;
  public isPublishPageOpened = false;
  favIcon: HTMLLinkElement | null = this._document.querySelector('#appIcon');
  checkedMedia: FileReference[] = [];

  allocatedMedia: AllocatedMedia | null = null;

  public lights: { Name: string; Icon: string; Count: number }[] = [];

  loading = true;
  selectedAccountOption: AccountOption | null = null;
  publishAfterThumbnail = false;
  saveAfterThumbnail = false;
  imageLoading = false;
  customizeEnabled = false;
  focusEnabled = false;
  deleteEnabled = false;
  isDeletingMedia = false;
  showCaptureZone = false;
  showCroppedImage = false;
  captureImage: any;
  croppedImage: any;
  coords?: { x: number; y: number };

  tools = ViewTool;
  transformationModes = TransformationMode;
  popupTypes = Object.keys(PopupType);
  effectTypes = Object.keys(EffectType);
  currentTransformationMode$ = this._appService.transformationMode$;
  isInteractable = false;
  toolTipPositions = TooltipPosition;
  dropdownMenu = {
    popupContent: false,
    effect: false,
  };
  progressMap: { [key: string]: any } = {};

  showManual: boolean;
  showManualButton: boolean;
  supportsXR: boolean;

  private isEnterpriseOwner: boolean;
  isEnterpriseCollaborator: boolean;

  private hookedPopup?: TPopup;
  private hookedEffect?: TEffect;

  isLoading: boolean = false;
  scenePlan = ScenePlan;
  assetSource = AssetSource;
  assetsMenu = ElementLibrary;
  objectType = ElementType;
  activeAccount: Account;

  ownedAssets: IAsset[] = [];

  limitAssetTypes = [ElementType.Environment, ElementType.Landscape, ElementType.Stage, ElementType.Object];
  uploadAssetUsageLimit: number | undefined;
  countOfUsedUploadedAssets = 0;

  @HostBinding('class.player-mode')
  get onPlayerMode() {
    return this._appService.getViewMode() === ViewMode.Player;
  }

  get onSelectTool() {
    return this._appService.getViewManager()?.tool?.name === ViewTool.Select;
  }

  get onCustomizeTool() {
    return this._appService.getViewManager()?.tool?.name === ViewTool.Customize;
  }

  get activeToolName() {
    return this._appService.getViewManager()?.tool?.name;
  }

  get isEnterpriseOrAdmin() {
    return this._accountService.isEnterpriseOrAdmin;
  }

  get popup() {
    return (this.selectedElement as TInteractableElement)?.parameters?.popups?.[0];
  }

  get effect() {
    return (this.selectedElement as TInteractableElement)?.parameters?.effects?.[0];
  }

  get showAvatarPanel() {
    return this.panelMask & PanelMask.Avatar;
  }

  get showEffectPanel() {
    return this.panelMask & PanelMask.Effect;
  }

  get showEnvironmentPanel() {
    return this.panelMask & PanelMask.Environment;
  }

  get showLightPanel() {
    return this.panelMask & PanelMask.Light;
  }

  get showMediaPanel() {
    return this.panelMask & PanelMask.Media;
  }

  get showPopupContentPanel() {
    return this.panelMask & PanelMask.Popup;
  }

  get showSceneInteractionsPanel() {
    return this.panelMask & PanelMask.SceneInteractions;
  }

  get showTextPanel() {
    return this.panelMask & PanelMask.Text;
  }

  get showTransformationPanel() {
    return this.panelMask & PanelMask.Transformation;
  }

  get showMaterialsPanel() {
    return this.panelMask & PanelMask.Materials;
  }

  get showColorPanel() {
    return this.panelMask & PanelMask.Colors;
  }

  constructor(
    private _oxrSettingsService: OxrSettingsService,
    private _settingsService: UserSettingsService,
    private _sceneService: SceneService,
    private _assetService: AssetService,
    public _appService: ApplicationService,
    private _modalService: ModalService,
    private _fileService: FileService,
    private _translateService: TranslateService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _title: Title,
    public dataCache: DataCacheService,
    private _accountService: AccountService,
    private _cd: ChangeDetectorRef,
    private _localStorageService: LocalStorageService,
    private _ngZone: NgZone,
    private _sceneFileService: SceneFileService,
    private _pricingPlanService: PricingPlanService,
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(WINDOW) private readonly _window: Window,
  ) {
    this.resetCoords = this.resetCoords.bind(this);
    this.hoverActive$ = this._appService.hoverActive$;
    this.showManual = this._localStorageService.getItem(LocalStorageKeys.NEVER_SHOW_AGAIN_EDIT_MANUAL) !== 'true';
    this.showManualButton = this._localStorageService.getItem(LocalStorageKeys.NEVER_SHOW_AGAIN_EDIT_MANUAL) !== 'true';
  }

  ngOnDestroy(): void {
    this.favIcon!.href = './assets/oxr-favicon.png';
    this._title.setTitle('OWNXR');
    this.assetMap.clear();
  }

  ngOnInit(): void {
    this.isEnterpriseOwner = this._accountService.currentAccountOption.getValue()?.Role === EnterpriseRole.Owner;
    this.isEnterpriseCollaborator = this._accountService.currentAccountOption.getValue()?.Role === EnterpriseRole.Collaborator;
    this.selectedAccountOption = this._accountService.currentAccountOption.getValue();

    const getAssetVersionsQuery =
      'includeOwners=true' +
      (this.selectedAccountOption?.EnterpriseContract?.EnterpriseId
        ? `&enterpriseId=${this.selectedAccountOption?.EnterpriseContract?.EnterpriseId}`
        : '');

    this._accountService.activeAccount$.pipe().subscribe((account) => (account ? (this.activeAccount = account) : undefined));

    this._appService.assetMap$.pipe(untilDestroyed(this)).subscribe((assetMap) => {
      this.assetMap = assetMap;
    });

    this._sceneFileService.allocatedMedia$.pipe(untilDestroyed(this)).subscribe((allocatedMedia) => {
      this.allocatedMedia = allocatedMedia;
    });

    this._route.paramMap.pipe(untilDestroyed(this)).subscribe(async (params) => {
      const sceneId = params.get(RouteParam.Scene);
      if (sceneId) {
        this.activeScene = await this._sceneService.getById(sceneId);
        if (this.activeScene) {
          const [fileReferences, allocatedMedia, assets] = await Promise.all([
            this._fileService.get('', `sceneId=${this.activeScene.Id}`, undefined, true, true),
            this._sceneFileService.getAllocatedMediaFiles(this.activeScene.Id),
            // this._assetService.get('versions', getAssetVersionsQuery, undefined, true, true),
            this._appService.apiv2.getLibraryAssets(),
          ]);

          this.extractMediaFiles(fileReferences);
          this.extractAssets(assets);

          this._appService.assetMapSubject.next(this.assetMap);
          this.favIcon!.href = this.activeScene.Favicon ? this.activeScene.Favicon : './assets/oxr-favicon.png';
          this._title.setTitle(this.activeScene.Plan === ScenePlan.Free ? 'OWNXR | ' + this.activeScene.Name : this.activeScene.Name);

          this.loading = false;
          this._cd.detectChanges();

          if (this.isEnterpriseOwner) {
            this._oxrSettingsService.openPanel(this.activeScene);

            this._ngZone.runOutsideAngular(() => {
              interval(200)
                .pipe(take(1), untilDestroyed(this))
                .subscribe(() => {
                  this._oxrSettingsService.openDetails('collaborator');
                  this._ngZone.run(() => {});
                });
            });
          }

          this.uploadAssetUsageLimit =
            this.activeScene!.Plan === ScenePlan.Enterprise
              ? undefined
              : this._pricingPlanService.scenePlans![this.activeScene!.Plan][ScenePlanDetail.UploadAssetUsageLimit];

          if (this.uploadAssetUsageLimit) {
            const elements = this._appService.getViewElements();
            this.countOfUsedUploadedAssets = this.calculateCountOfUsedUploadedAssets(elements, this.ownedAssets);
          }
        } else {
          this._router.navigate(['error']);
        }
      } else {
        this._router.navigate(['error']);
      }
    });

    this._fileService.data$
      .pipe(
        untilDestroyed(this),
        filter((res) => !this.loading),
      )
      .subscribe((res) => {
        this.extractMediaFiles(res, true);
      });

    combineLatest([this._appService.apiv2.getLibraryAssets()])
      .pipe(
        untilDestroyed(this),
        filter((res) => !this.loading),
        catchError(() => {
          this.assetsLoaded = true;
          return of([]);
        }),
      )
      .subscribe(([assets]) => {
        this.extractAssets(assets);
      });

    this._appService.xrSupportSubject.pipe(untilDestroyed(this)).subscribe((supportsXR) => {
      this.supportsXR = supportsXR;
    });

    this._appService.selectedMeshScreenCoordinates$.pipe(untilDestroyed(this)).subscribe((coords) => {
      if (this.selectedElement?.type === ElementType.Checkpoint && coords) {
        const canvasWidth = this.canvas.viewCanvasRef.nativeElement.width;
        const canvasHeight = this.canvas.viewCanvasRef.nativeElement.height;

        const x =
          coords.x > canvasWidth - INFO_RIGHT_OFFSET
            ? canvasWidth - INFO_RIGHT_OFFSET
            : coords.x < INFO_RIGHT_OFFSET
              ? INFO_RIGHT_OFFSET
              : coords.x;
        const y = coords.y + INFO_DOWN_OFFSET > canvasHeight ? canvasHeight - INFO_HEIGHT_OFFSET : coords.y + INFO_UP_OFFSET;
        this.coords = { x, y };
        this._window.addEventListener('keydown', this.resetCoords);
        this._window.addEventListener('pointerdown', this.resetCoords);
      } else {
        this.coords = undefined;
      }
      this._cd.detectChanges();
    });

    this._appService.viewElementsSubject.pipe(untilDestroyed(this)).subscribe((elements) => {
      if (this.uploadAssetUsageLimit) {
        this.countOfUsedUploadedAssets = this.calculateCountOfUsedUploadedAssets(elements, this.ownedAssets);
        if (this.countOfUsedUploadedAssets > this.uploadAssetUsageLimit) {
          this.showLimitExceededModal();
          this._appService.removeViewElements([elements[elements.length - 1]]);
          return;
        }
      }

      this.selectedElement = elements.find(({ id }) => id === this.selectedElement?.id);
      if (!this.selectedElement) {
        switch (this.selectedElementType) {
          case ElementType.Landscape:
            this.selectedElement = elements.find(({ type }) => type === ElementType.Landscape);
            break;
          case ElementType.Stage:
            this.selectedElement = elements.find(({ type }) => type === ElementType.Stage);
            break;
          default:
            break;
        }
      }

      this.selectedElement &&
        this.selectedElement.type !== ElementType.Screen &&
        this.hookedPopup &&
        this._appService.showPopup(this.hookedPopup, this.selectedElement as TInteractableElement, true);
      this.hookedPopup = undefined;
      this.updatePanels();
      elements.forEach((element) => {
        switch (element.type) {
          case ElementType.Environment:
            const environmentId = (element as TEnvironmentElement).parameters.assetVersionId!;
            this.assetMap.set(environmentId, true);
            break;
          case ElementType.Landscape:
            const landscapeId = (element as TLandscapeElement).parameters.assetVersionId!;
            this.assetMap.set(landscapeId, true);
            break;
          case ElementType.Stage:
            const stageId = (element as TStageElement).parameters.assetVersionId!;
            this.assetMap.set(stageId, true);
            break;
          default:
            break;
        }
      });
      this._appService.assetMapSubject.next(this.assetMap);
      this._cd.detectChanges();
    });

    this._appService.selectedElementsSubject.pipe(untilDestroyed(this)).subscribe((elements) => {
      this.selectedElement = elements.length === 1 ? elements[0] : undefined;
      this.isInteractable = !!this.selectedElement && INTERACTABLE_TYPES.includes(this.selectedElement.type);
      this.customizeEnabled = this.selectedElement?.type === ElementType.Object;
      this.focusEnabled = !!elements.length && !elements.some(({ type }) => STATIC_TYPES.includes(type));
      this.deleteEnabled = !!elements.length && !elements.some(({ type }) => type === ElementType.Checkpoint);
      this.updatePanels();
      if (
        this.selectedElement &&
        this.selectedElement.type !== ElementType.Object &&
        this.selectedElement.type !== ElementType.Environment &&
        !COLLIDABLE_TYPES.includes(this.selectedElement.type) &&
        !(this.selectedElement.type === ElementType.Screen && this.selectedElementLibrary === ElementLibrary.Image)
      ) {
        this.selectedElementLibrary = ElementLibrary.None;
      }
      this._cd.detectChanges();
    });

    this._appService.transformationMode$.pipe(untilDestroyed(this)).subscribe((_mode) => {
      this._cd.detectChanges();
    });

    this._appService.progressMapSubject.pipe(untilDestroyed(this)).subscribe((map) => {
      this.progressMap = Object.fromEntries(map);
      this._cd.detectChanges();
    });

    this._appService.customizationMaterialsSubject.pipe(untilDestroyed(this)).subscribe((materials) => {
      this.materials = materials;
      this.updatePanels();
      this._cd.detectChanges();
    });

    this._appService.selectedMaterialsSubject.pipe(untilDestroyed(this)).subscribe((materials) => {
      this.selectedMaterial = materials[0];
      this.updatePanels();
      this._cd.detectChanges();
    });
  }

  get assetType() {
    return AssetType;
  }

  get fileType() {
    return FileType;
  }

  extractMediaFiles(fileReferences: FileReference[], isDetected = false) {
    const filteredFileReferences = fileReferences.filter((file) => file.MediaType === MediaType.MediaTab);

    const files = groupData(filteredFileReferences, 'FileType');
    this.filesLoaded = true;

    this.images = files.get(FileType.Image) ?? [];
    this.videos = files.get(FileType.Video) ?? [];

    if (isDetected) {
      this._cd.detectChanges();
    }
  }

  extractAssets(assets: IAsset[] | never[]) {
    let tagString = '';
    assets.map((asset) => {
      // const isOwner = asset.Owner?.Id === this.activeAccount?.Id;
      // if (isOwner) {
      //   asset.Permission = Permission.Owner;
      // }
      tagString = asset.Tags?.toLowerCase() ?? '';
      if (tagString.includes('stage')) {
        asset.Type = AssetType.Stage;
      } else if (tagString.includes('landscape')) {
        asset.Type = AssetType.Landscape;
      }
      asset.Thumbnail = this._fileService.getCompressedImageUrl(asset.Thumbnail);
      return asset;
    });
    // this.ownedAssets = assets.filter((a) => a.Permission === Permission.Owner);
    this.assets = groupData(assets, 'Type');
    this.assetsLoaded = true;
  }

  getAssetsByTag(assetType: AssetType) {
    return this.assets.get(AssetType.Object)?.filter((asset) => asset.Tags?.toLowerCase().includes(assetType.toLowerCase()));
  }

  setPanelActive(bool: boolean) {
    this.isAllowedPanelToggling = bool;
    this._appService.openPanelsMenu.next(bool);
  }

  resetCoords() {
    this.coords = undefined;
    this._window.removeEventListener('keydown', this.resetCoords);
    this._window.removeEventListener('pointerdown', this.resetCoords);
  }

  onAddObject(assetType: AssetType) {
    this._router.navigate(['market', 'list'], { queryParams: { selectedTag: assetType } });
  }

  openSettings(): void {
    if (this.activeScene) {
      this._oxrSettingsService.openPanel(this.activeScene);
      this._oxrSettingsService.scene$.pipe(skip(1), take(1)).subscribe((updatedScene) => {
        if (updatedScene) {
          this.activeScene = updatedScene;
          if (this.favIcon) {
            this.favIcon.href = updatedScene.Favicon ?? './assets/oxr-favicon.png';
          }
          this._title.setTitle(updatedScene.Plan === ScenePlan.Free ? 'OWNXR | ' + updatedScene.Name : updatedScene.Name);
        }
      });
    }
  }

  changeTransformationMode(mode: TransformationMode): void {
    this.transformationMode = mode;
    this._appService.setTransformationMode(mode);
  }

  panelSelected(panel: ElementLibrary) {
    if (panel === this.selectedElementLibrary) {
      this.selectedElementLibrary = ElementLibrary.None;
      this.setPanelActive(false);
      return;
    }
    this.selectedElementLibrary = panel;
    this.checkShowingGuide(panel);

    if (panel === ElementLibrary.None) {
      if (!this.selectedElement) {
        this.setPanelActive(false);
        return;
      }
      if (this.selectedElement.type === ElementType.Environment) {
        this._appService.setSelectedElements([]);
        this.setPanelActive(false);
        return;
      }
    } else if (panel === ElementLibrary.Environment) {
      const environment = this._appService.getViewElements(ElementType.Environment)[0];
      this._appService.setSelectedElements(environment ? [environment] : []);
      this.setPanelActive(!!environment);
    }
  }

  async checkShowingGuide(panel: ElementLibrary) {
    const guideSetting = await this._settingsService.getSettingByName('guide');
    const parsedValue = guideSetting.Value ? JSON.parse(guideSetting.Value) : null;
    let modal;

    switch (panel) {
      case ElementLibrary.Tools:
        if (!!parsedValue && !parsedValue.space.toolsFunctionDisabled) {
          modal = this._modalService.open(GuideModalComponent);
          modal.instance.message = this._translateService.instant('guideModal.messages.toolsFunction');
          modal.result.then((dontShow) => {
            if (dontShow) {
              parsedValue.space.toolsFunctionDisabled = true;
              guideSetting.Value = JSON.stringify(parsedValue);
              this._settingsService.modify(guideSetting);
              this._settingsService.commit();
            }
          });
        }
        break;
      default:
        break;
    }
  }

  updatePanels() {
    let isPanelActive = false;
    if (this.selectedElement) {
      this.panelMask =
        this.selectedElement.type === ElementType.Environment
          ? this.panelMask | PanelMask.Environment
          : this.panelMask & ~PanelMask.Environment;
      this.panelMask = STATIC_TYPES.includes(this.selectedElement.type)
        ? this.panelMask & ~PanelMask.Transformation
        : this.panelMask | PanelMask.Transformation;
      this.panelMask =
        this.selectedElement.type === ElementType.Checkpoint ? this.panelMask | PanelMask.Avatar : this.panelMask & ~PanelMask.Avatar;
      this.panelMask =
        this.selectedElement.type === ElementType.Light ? this.panelMask | PanelMask.Light : this.panelMask & ~PanelMask.Light;
      this.panelMask = this.selectedElement.type === ElementType.Text ? this.panelMask | PanelMask.Text : this.panelMask & ~PanelMask.Text;
      this.panelMask =
        this.isInteractable && !!(this.selectedElement as TInteractableElement).parameters.effects?.length
          ? this.panelMask | PanelMask.Effect
          : this.panelMask & ~PanelMask.Effect;
      this.panelMask =
        this.isInteractable && !!(this.selectedElement as TInteractableElement).parameters.popups?.length
          ? this.panelMask | PanelMask.Popup
          : this.panelMask & ~PanelMask.Popup;
      this.panelMask =
        this.selectedElement.type === ElementType.Screen && !!(this.selectedElement as TScreenElement).parameters.media
          ? this.panelMask | PanelMask.Media
          : this.panelMask & ~PanelMask.Media;
      this.panelMask = this.isInteractable ? this.panelMask | PanelMask.SceneInteractions : this.panelMask & ~PanelMask.SceneInteractions;
    } else {
      this.panelMask = PanelMask.None;
    }
    this.panelMask = this.materials.length ? this.panelMask | PanelMask.Materials : this.panelMask & ~PanelMask.Materials;
    this.panelMask = this.selectedMaterial ? this.panelMask | PanelMask.Colors : this.panelMask & ~PanelMask.Colors;
    if (this.panelMask) {
      isPanelActive = true;
    }
    this.updateLightPanel();
    this.setPanelActive(isPanelActive);
  }

  updateLightPanel() {
    let directionalLightCount = 0;
    let pointLightCount = 0;
    let ambientLightCount = 0;
    let spotLightCount = 0;

    this._appService.getViewElements(ElementType.Light).forEach((light) => {
      switch ((light as TLightElement).parameters.type) {
        case LightType.Directional:
          directionalLightCount += 1;
          break;
        case LightType.Point:
          pointLightCount += 1;
          break;
        case LightType.Ambient:
          ambientLightCount += 1;
          break;
        case LightType.Spot:
          spotLightCount += 1;
          break;
        default:
          break;
      }
    });

    this.lights = [
      { Name: 'lights.ambientLight', Icon: 'icon-ambient-light', Count: ambientLightCount },
      { Name: 'lights.directionalLight', Icon: 'icon-directional-light', Count: directionalLightCount },
      { Name: 'lights.pointLight', Icon: 'icon-point-light', Count: pointLightCount },
      { Name: 'lights.spotLight', Icon: 'icon-spot-light', Count: spotLightCount },
      { Name: 'lights.removeAll', Icon: '', Count: directionalLightCount + pointLightCount + ambientLightCount + spotLightCount },
    ];
  }

  onLightOptionChanged() {
    this.updateLightPanel();
    this._appService.dataCache.modify(this.activeScene!);
  }

  lightSelected(lightName: string) {
    if (this.activeScene) {
      if (lightName == 'lights.removeAll') {
        this.panelMask &= ~PanelMask.Light;
        const viewManager = this._appService.getViewManager();
        if (viewManager) {
          this._appService.setViewElements(
            viewManager.elements.filter(({ type }) => type !== ElementType.Light),
            true,
          );
          this.dataCache.modify(this.activeScene);
          viewManager.setTool(ViewTool.Select);
        }
      } else {
        if (this._appService.getViewElements(ElementType.Light).length < MAX_LIGHTS) {
          const elementId = uuid.v4();
          let type: LightType;
          switch (lightName) {
            case 'lights.pointLight':
              type = LightType.Point;
              break;
            case 'lights.directionalLight':
              type = LightType.Directional;
              break;
            case 'lights.ambientLight':
              type = LightType.Ambient;
              break;
            case 'lights.spotLight':
              type = LightType.Spot;
              break;
            default:
              // TODO: Notify with unknown data type
              return;
          }
          this._appService.setTool(ViewTool.Insert, [
            {
              id: elementId,
              type: ElementType.Light,
              name: `${type}Light-${elementId}`,
              parameters: {
                type,
                intensity: LIGHT_INTENSITES[type],
                position: LIGHT_POSITION,
                direction: LIGHT_DIRECTIONS[type],
                color: LIGHT_DIFFUSE,
                ...(type === LightType.Spot ? { angle: LIGHT_ANGLE } : {}),
                ...(type === LightType.Ambient ? { groundColor: LIGHT_GROUND } : { bias: LIGHT_BIAS, normalBias: LIGHT_NORMAL_BIAS }),
              },
            },
          ]);
          this.dataCache.modify(this.activeScene);
        } else {
          this._appService.showNotification('oxr.creatingSpace.lightsOption.noMoreLights', { number: MAX_LIGHTS }, 2000);
        }
      }
      this.updateLightPanel();
    }
  }

  hideLightsOption() {
    this._appService.focusOnCanvas();
    this._appService.setSelectedElements([]);
    this.panelMask &= ~PanelMask.Light;
  }

  async assetSelected(assetId: string) {
    // const asset = this._assetService.getCachedById(assetId);
    const asset = await this._appService.apiv2.getAssetById(assetId);
    if (!!asset?.Versions?.length) {
      const key = asset.Id || asset.Name || '';
      const isAssetImporting = this.progressMap[key] !== undefined;
      const defaultVersion = asset?.versions?.find((v) => v.Id === asset.ActiveVersionIds.split(',')[0]) ?? asset.versions[0];
      if (!isAssetImporting && this.activeScene && asset && defaultVersion) {
        switch (asset.Type) {
          case AssetType.Environment:
            this.panelMask |= PanelMask.Environment;
            this._appService.setEnvironmentElement(defaultVersion);
            break;
          case AssetType.Object:
            this._appService.handleObjectAsset(defaultVersion, this.selectedElementType as unknown as ElementType);
            break;
          default:
            break;
        }
        this.assetMap.set(assetId, true);
        this._appService.assetMapSubject.next(this.assetMap);
      }
    }
  }

  async fileSelected(assetId: string) {
    const file = this._fileService.getCachedById(assetId);
    if (!file) {
      return;
    }
    if (this.selectedElement?.type === ElementType.Screen) {
      const media = {
        id: uuid.v4(),
        fileId: file.Id,
        type: file.FileType,
        url: file.Url,
      };
      const element = deepCopy(this.selectedElement) as TScreenElement;
      ViewManagerUtils.GetMediaDimensions(media).then((dimensions) => {
        if (!(!dimensions || !dimensions.width || !dimensions.height)) {
          if (dimensions.width > dimensions.height) {
            element.parameters.scaling![0] = element.parameters.scaling![1] * (dimensions.width / dimensions.height);
          } else {
            element.parameters.scaling![1] = element.parameters.scaling![0] * (dimensions.height / dimensions.width);
          }
        }
        this._appService.updateViewElement({
          ...element,
          parameters: {
            ...element.parameters,
            media,
          },
        } as TScreenElement);
      });
    } else {
      this.onScreen({
        id: uuid.v4(),
        fileId: file.Id,
        type: file.FileType,
        url: file.Url,
      });
    }
  }

  noneSelected(assetType: AssetType | string) {
    if (!this.activeScene) {
      return;
    }
    switch (assetType) {
      case AssetType.Environment:
        this._appService.setViewElements(
          this._appService.getViewElements().filter(({ type }) => type !== ElementType.Environment),
          true,
        );
        const environment = this.assets.get(this.assetType.Environment)?.find((asset) => this.assetMap.has(asset.Id));
        environment && this.assetMap.set(environment.Id, false);
        break;
      case AssetType.Landscape:
        this._appService.setViewElements(
          this._appService.getViewElements().filter(({ type }) => type !== ElementType.Landscape),
          true,
        );
        break;
      case AssetType.Stage:
        this._appService.setViewElements(
          this._appService.getViewElements().filter(({ type }) => type !== ElementType.Stage),
          true,
        );
        break;
      case AssetType.Object:
        // eslint-disable-next-line
        if (this.selectedElementType === ElementType.Object) {
          const modal = this._modalService.open(ConfirmationComponent);
          modal.instance.title = this._translateService.instant('shared.confirmation.warning');
          modal.instance.body = this._translateService.instant('shared.confirmation.removeAllCurrentlyPlacedObjects');
          modal.instance.confirmAction = this._translateService.instant('shared.confirmation.yes');
          modal.result.then((confirmed) => {
            confirmed &&
              this._appService.setViewElements(
                this._appService.getViewElements().filter(({ type }) => type !== ElementType[assetType]),
                true,
              );
          });
        } else if (this.selectedElementType === ElementType.Stage) {
          this._appService.setViewElements(
            this._appService.getViewElements().filter(({ type }) => type !== ElementType.Stage),
            true,
          );
        } else if (this.selectedElementType === ElementType.Landscape) {
          this._appService.setViewElements(
            this._appService.getViewElements().filter(({ type }) => type !== ElementType.Landscape),
            true,
          );
        }
        break;
      case 'image':
      case 'video':
        this._appService.updateViewElement({
          ...this.selectedElement,
          parameters: {
            ...this.selectedElement?.parameters,
            media: undefined,
          },
        } as TScreenElement);
        break;
    }
    this._cd.detectChanges();
  }

  onDelete() {
    this._appService.deleteSelection();
  }

  onDeleteMedia(asset: FileReference) {
    const modal = this._modalService.open(ConfirmationComponent);
    if (modal) {
      modal.instance.cancelAction = this._translateService.instant('shared.confirmation.cancel');
      modal.instance.confirmAction = this._translateService.instant('shared.confirmation.delete');
      modal.instance.body = this._translateService.instant('shared.confirmation.deleteMediaFileConfirmationMessage');

      modal.result.then((confirmed) => {
        if (confirmed) {
          this._fileService.delete(asset, true).then(() => {
            this._sceneFileService.getAllocatedMediaFiles(this.activeScene!.Id);
            const modal = this._modalService.open(InformationComponent);
            if (modal) {
              modal.instance.message = this._translateService.instant('shared.information.mediaHasBeenDeleted', { num: 1 });
            }
          });
        }
      });
    }
  }

  async onBulkDeleteMedia() {
    const modal = this._modalService.open(ConfirmationComponent);
    if (modal) {
      modal.instance.cancelAction = this._translateService.instant('shared.confirmation.cancel');
      modal.instance.confirmAction = this._translateService.instant('shared.confirmation.delete');
      modal.instance.body = this._translateService.instant('shared.confirmation.deleteMediaFilesConfirmationMessage', {
        num: this.checkedMedia.length,
      });

      await modal.result.then(async (confirmed) => {
        if (confirmed) {
          this.isDeletingMedia = true;

          await this._fileService.delete(this.checkedMedia, true).then(() => {
            this._sceneFileService.getAllocatedMediaFiles(this.activeScene!.Id);

            const modal = this._modalService.open(InformationComponent);
            if (modal) {
              modal.instance.message = this._translateService.instant('shared.information.mediaHasBeenDeleted', {
                num: this.checkedMedia.length,
              });
              this.checkedMedia = [];
            }
          });

          this.isDeletingMedia = false;
        }
      });
    }
  }

  uploadFile(fileType: FileType) {
    this._document.getElementById(fileType)?.click();
  }

  showErrorPopup(message) {
    const modal = this._modalService.open(InformationComponent);
    if (modal) {
      modal.instance.classList = 'warning-message';
      modal.instance.message = message;
      return;
    }
  }

  async onFileInputChange(event: any, fileType: FileType) {
    const files = Array.from(event.target.files as FileList);
    this.isLoading = true;
    this._cd.detectChanges();
    this._sceneFileService.uploadFiles(this.activeScene, files, fileType);
    const target = event.target as HTMLInputElement;
    target.value = '';
    this.isLoading = false;
    this._cd.detectChanges();
  }

  onCaptureThumbnail() {
    if (!this.showCaptureZone) {
      this.showManual = false;
      this.showCaptureZone = true;
    }
  }

  exitCaptureMode() {
    this.showCaptureZone = false;
  }

  onThumbnailRetake() {
    this.captureImage = null;
    this.showCroppedImage = false;
    this.showCaptureZone = true;
  }

  async onThumbnailSave() {
    if (this.activeScene) {
      this.imageLoading = true;

      const { url } = await this._fileService.uploadFile(
        new File([base64ToFile(this.croppedImage)], ''),
        FileStorageType.Public,
        undefined,
        `${this.activeScene.SceneId}'s thumbnail`,
        true,
        FileType.Image,
        undefined,
        Access.Public,
        MediaType.Thumbnail,
      );
      this.activeScene!.Thumbnail = url;
      await this._sceneService.put(this.activeScene!);
      this.dataCache.modify(this.activeScene);
      this.showCroppedImage = false;
      this.imageLoading = false;

      if (this.saveAfterThumbnail) {
        this.saveAfterThumbnail = false;
        this.save();
      }

      if (this.publishAfterThumbnail) {
        this.publishAfterThumbnail = false;
        this.publish();
      }
    }
  }

  captureScreen() {
    this.uncroppedImage.nativeElement.onload = () => {
      const canvas = this._document.createElement('canvas');
      canvas.width = THUMBNAIL_CANVAS_WIDTH;
      canvas.height = THUMBNAIL_CANVAS_HEIGHT;
      const ctx = canvas.getContext('2d');
      const srcImg = this.uncroppedImage.nativeElement;
      ctx?.drawImage(
        srcImg,
        (srcImg.width - THUMBNAIL_WIDTH) / 2,
        (srcImg.height - THUMBNAIL_HEIGHT) / 2,
        THUMBNAIL_WIDTH,
        THUMBNAIL_HEIGHT,
        0,
        0,
        THUMBNAIL_CANVAS_WIDTH,
        THUMBNAIL_CANVAS_HEIGHT,
      );

      this._ngZone.runOutsideAngular(() => {
        interval(100)
          .pipe(take(1), untilDestroyed(this))
          .subscribe(() => {
            this.uncroppedImage.nativeElement.onload = null;
            this.croppedImage = canvas.toDataURL();
            this.showCaptureZone = false;
            this.showCroppedImage = true;
            this._ngZone.run(() => {});
          });
      });
    };

    this.captureImage = this.canvas.viewCanvasRef.nativeElement.toDataURL();
  }

  setPlayerMode() {
    this.showManual = false;
    this._appService.setViewMode(ViewMode.Player);
  }

  setVRMode() {
    this._appService.setViewMode(ViewMode.VR);
  }

  noShowAgainManual() {
    this.showManual = false;
    this.showManualButton = false;
  }

  showEditorControls() {
    this.showManual = false;

    if (this.onPlayerMode) {
      this._appService.setViewMode(ViewMode.Creator);
    } else {
      this._modalService.open(EditorControlsInfoComponent, {
        hasBackdrop: true,
        closeOnBackdropClick: false,
        allowHeaderClick: false,
        zIndex: 4980,
      });
    }
  }

  async save() {
    const modal = this._modalService.open(ProgressComponent);
    if (modal) {
      modal.instance.message = this._translateService.instant('shared.information.savingModel');
      await firstValueFrom(this._sceneService.postSceneElements(this.activeScene!.Id, this._appService.getViewElements()));
      await firstValueFrom(this._sceneService.createNewVersion(this.activeScene!.Id));
      this._modalService.close(1);
    }
  }

  onSave() {
    if (!this.activeScene?.Thumbnail) {
      this.showCaptureZone = true;
      this.saveAfterThumbnail = true;
    } else {
      this.save();
    }
  }

  /**
   * Saves the scene and shows publish screen
   */
  async publish() {
    if (this._pricingPlanService.hasScenePlanPropertyRestriction(this.activeScene!.Plan, ScenePlanDetail.PublishSpace, true)) {
      return;
    }

    if (this.isEnterpriseCollaborator) {
      return;
    }

    if (!this.activeScene?.Thumbnail) {
      this.showCaptureZone = true;
      this.publishAfterThumbnail = true;
      return;
    }

    this.dataCache.isDirty && (await this.save());
    this.activeScene.IsAccessible
      ? await this._sceneService.publishScene(this.activeScene.Id)
      : await this._sceneService.unPublishScene(this.activeScene.Id);
    this.isPublishPageOpened = true;
    this.showManual = false;
  }

  exitWithoutSaving(): Promise<boolean> {
    const modal = this._modalService.open(ConfirmationComponent, { closeOnBackdropClick: false });
    modal.instance.title = this._translateService.instant('shared.confirmation.warning');
    modal.instance.body = this._translateService.instant('shared.confirmation.exitWithoutSavingMessage');
    modal.instance.confirmAction = this._translateService.instant('shared.confirmation.exit');
    return modal.result;
  }

  async onSceneDelete({ action, sceneId }) {
    if (action === 'delete') {
      this._router.navigate(['oxr', 'owned']);
      return;
    }
    if (sceneId) {
      this.activeScene = await this._sceneService.getById(sceneId);
      this._appService.setActiveModel(this.activeScene);
      this.assetMap.clear();
      this._appService.assetMapSubject.next(this.assetMap);
    }
  }

  onSnap() {
    // TODO: Unify onSnap functions with controls
    this._appService.setTool(ViewTool.Snap, this._appService.getSelectedElements());
  }

  onCustomize() {
    this._appService.setTool(ViewTool.Customize, this._appService.getSelectedElements());
  }

  onFocus() {
    this._appService.getViewManager()?.focus();
  }

  onTextbox() {
    const id = uuid.v4();
    this._appService.setTool(ViewTool.Insert, [
      {
        id,
        type: ElementType.Text,
        name: `Text-${id}`,
        parameters: {
          text: 'Text',
          font: [TEXT_FONT_WEIGHT, TEXT_FONT_SIZE, TEXT_FONT_FAMILY].join(' '),
          alignment: TextAlignment.Left,
          doubleSided: true,
          color: [1, 1, 1, 1],
          background: [0, 0, 0, 0],
          lineHeight: TEXT_LINE_HEIGHT,
          letterSpacing: TEXT_LETTER_SPACING,
          position: TEXT_POSITION,
          quaternion: TEXT_QUATERNION,
          scaling: TEXT_SCALING,
          padding: TEXT_PADDING,
          popups: [],
          effects: [],
          collisions: false,
          shadows: true,
        },
      },
    ]);
  }

  onScreen(media?: TMedia) {
    const id = uuid.v4();
    const element: TScreenElement = {
      id,
      name: `Screen-${id}`,
      type: ElementType.Screen,
      parameters: {
        position: [...OBJECT_POSITION],
        quaternion: [...OBJECT_QUATERNION],
        scaling: [...SCREEN_SCALING],
        media,
      },
    };
    if (media) {
      ViewManagerUtils.GetMediaDimensions(media).then((dimensions) => {
        if (!(!dimensions || !dimensions.width || !dimensions.height)) {
          if (dimensions.width > dimensions.height) {
            element.parameters.scaling![0] = element.parameters.scaling![1] * (dimensions.width / dimensions.height);
          } else {
            element.parameters.scaling![1] = element.parameters.scaling![0] * (dimensions.height / dimensions.width);
          }
        }
        this._appService.setTool(ViewTool.Insert, [element]);
      });
    } else {
      this._appService.setTool(ViewTool.Insert, [element]);
    }
  }

  selectPopup(type?: string) {
    this.hideDropdown('popupContent');
    if (!this.selectedElement) {
      return;
    }
    if (!type) {
      this._appService.updateViewElement(
        {
          ...this.selectedElement,
          parameters: {
            ...this.selectedElement.parameters,
            popups: [],
          },
        } as TElement,
        true,
      );
    } else if (!this.popup && this.selectedElement) {
      this._appService.showPopup(
        {
          id: uuid.v4(),
          type: type as PopupType,
          color: POPUP_COLOR,
          parameters: [],
        },
        this.selectedElement as TInteractableElement,
        true,
      );
    } else if (this.popup?.type === type) {
      this._appService.showPopup(this.popup, this.selectedElement as TInteractableElement, true);
    } else {
      this._appService.showReplacementWarning(() => {
        this.hookedPopup = {
          id: uuid.v4(),
          type: type as PopupType,
          thumbnail: this.popup?.thumbnail ?? 'icon-help-line.svg',
          color: POPUP_COLOR,
          parameters: [],
        };
        this.selectedElement &&
          this._appService.updateViewElement(
            {
              ...this.selectedElement,
              parameters: {
                ...this.selectedElement.parameters,
                popups: [this.hookedPopup],
              },
            } as TElement,
            true,
          );
      });
    }
  }

  selectEffect(type?: string) {
    this.hideDropdown('effect');
    if (!this.selectedElement) {
      return;
    }
    if (!type) {
      this._appService.updateViewElement(
        {
          ...this.selectedElement,
          parameters: {
            ...this.selectedElement.parameters,
            effects: [],
          },
        } as TElement,
        true,
      );
    } else if (this.effect?.type === type) {
      return;
    } else if (!this.effect && this.selectedElement) {
      this.hookedEffect = {
        id: uuid.v4(),
        type: type as EffectType,
        trigger: InteractionTrigger.Load,
        parameters: Object.entries(EFFECT_DEFAULTS[type]).map(([key, value]) => ({ key, value }) as TInteractionParameter),
      };
      this._appService.updateViewElement(
        {
          ...this.selectedElement,
          parameters: {
            ...this.selectedElement.parameters,
            effects: [this.hookedEffect],
          },
        } as TElement,
        true,
      );
    } else {
      this._appService.showReplacementWarning(() => {
        this.hookedEffect = {
          id: uuid.v4(),
          type: type as EffectType,
          trigger: InteractionTrigger.Load,
          parameters: Object.entries(EFFECT_DEFAULTS[type]).map(([key, value]) => ({ key, value }) as TInteractionParameter),
        };
        this.selectedElement &&
          this._appService.updateViewElement(
            {
              ...this.selectedElement,
              parameters: {
                ...this.selectedElement.parameters,
                effects: [this.hookedEffect],
              },
            } as TElement,
            true,
          );
      });
    }
  }

  toggleDropdownMenu(key: string) {
    if (this.dropdownMenu[key] !== undefined) {
      this.dropdownMenu[key] = !this.dropdownMenu[key];
    }
  }

  hideDropdown(key) {
    if (this.dropdownMenu[key] !== undefined) {
      this.dropdownMenu[key] = false;
    }
  }

  getPopupIcon(type: string) {
    switch (type) {
      case PopupType.GoogleForm:
        return 'icon-popup-google-form';
      case PopupType.GuestBook:
        return 'icon-popup-guest-book';
      default:
        return `icon-popup-${type.toLowerCase()}`;
    }
  }

  getEffectIcon(type: string) {
    return `icon-effect-${type.toLowerCase()}`;
  }

  isEffectDisabled(type) {
    return DISABLED_EFFECTS.includes(type);
  }

  trackByFn(index: number, type?: string) {
    return type ?? index;
  }

  setCheckedMedia(items: FileReference[]) {
    this.checkedMedia = items;
  }

  onObjectTabChange(objectType: ElementType) {
    this.selectedElementType = objectType;
    switch (objectType) {
      case ElementType.Landscape:
      case ElementType.Stage:
        const element = this._appService.getViewElements(objectType)[0];
        this._appService.setSelectedElements(element ? [element] : []);
        this.setPanelActive(!!element);
        return;
      default:
        this.selectedElement &&
          [ElementType.Stage, ElementType.Landscape].includes(this.selectedElement.type) &&
          this._appService.setSelectedElements([]);
    }
  }

  onMediaTabChange(fileType: FileType) {
    this.selectedFileType = fileType;
    this.checkedMedia = [];
  }

  calculateCountOfUsedUploadedAssets(viewElements, ownedAssets: IAsset[] = []) {
    const ownedAssetsMap = ownedAssets.reduce(
      (acc, asset) => {
        acc[asset.Id] = true;
        return acc;
      },
      {} as { [key: string]: boolean },
    );

    const usedAssetsCount = viewElements.reduce((count, element) => {
      const assetId = element.parameters?.assetId;
      if (assetId && ownedAssetsMap[element.parameters.assetId]) {
        return count + 1;
      }
      return count;
    }, 0);

    return usedAssetsCount;
  }

  showLimitExceededModal() {
    this._appService.showNotification('limitSpaceAsset.warning', { limit: this.uploadAssetUsageLimit }, 2000, 'collaborator-info-modal');
  }
}
