import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '@/app/src/environments/environment';
import { ScenePlan } from '@/data/src/lib/enums/pricing-plan';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { ApplicationService } from '@/view/src/app/app.service';
import { EnterpriseRole } from '@/data/src/lib/models/data/enterprise';
import { LanguageCode, AccountService } from '@/data/src/lib/services/account.service';
import { ConfirmationComponent } from '../../modal/confirmation/confirmation.component';
import { ModalService } from '../../modal/modal.service';
import { DropdownItem, MediaUploadComponent } from '../../modal/media-upload/media-upload.component';
import { OxrSettingsService } from './oxr-settings.service';
import { MAX_CHARACTERS } from '@/data/src/lib/enums/max-length';
import { convertBytesToGBOrMBOrKB } from '@/data/src/lib/utils/convert-utils';
import { SceneFileService } from '@/data/src/lib/services/scene-file.service';
import { IntegratedSceneStorageUsageInfo } from '@/data/src/lib/models/data/scene-file';
import { AssetType } from '@/data/src/lib/models/data/asset';

@UntilDestroy()
@Component({
  selector: 'ui-oxr-settings',
  templateUrl: './oxr-settings.component.html',
  styleUrls: ['./oxr-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OxrSettingsComponent implements OnInit, OnDestroy {
  scenePlan = ScenePlan;
  @HostBinding('class.opened') isOpened = false;
  @Output() onSceneDelete = new EventEmitter<{ action: string; sceneId: string }>();

  editDescription = false;
  editInfo = false;
  baseUrl = environment.redirectURL;
  scene: XRScene;
  canClosePanel = true;

  isDetailsPanelOpened$ = this._oxrSettingsService.isDetailsPanelOpened$;
  openedDetails$ = this._oxrSettingsService.openedDetails$;

  form = new UntypedFormGroup({
    Name: new UntypedFormControl(
      '',
      Validators.maxLength(
        this._translateService.currentLang === LanguageCode.English ? MAX_CHARACTERS.Default_EN : MAX_CHARACTERS.Default,
      ),
    ),
    Description: new UntypedFormControl(''),
    Category: new UntypedFormControl({ value: '', disabled: true }),
    Tags: new UntypedFormControl('', Validators.pattern('[a-zA-Z0-9 \\_\\-\\.]*')),
    Favicon: new UntypedFormControl(''),
  });

  image: string;
  tagsString: string;
  lengthLimit = MAX_CHARACTERS.Default;
  sceneNamelengthLimit = MAX_CHARACTERS.Default;
  polygons = 0;
  isEnterpriseOwner = false;
  sceneStorageUsageInfo: IntegratedSceneStorageUsageInfo;

  get canEditContent() {
    return this._oxrSettingsService.account?.CanEditContent;
  }

  get canDeleteScene() {
    return this.canEditContent || this.isEnterpriseOwner;
  }

  constructor(
    private _accountService: AccountService,
    private _oxrSettingsService: OxrSettingsService,
    private _modalService: ModalService,
    private _appService: ApplicationService,
    private _translateService: TranslateService,
    private _cd: ChangeDetectorRef,
    private _sceneFileService: SceneFileService,
  ) {
    this._oxrSettingsService.isPanelOpened$.pipe(untilDestroyed(this)).subscribe((isOpened) => {
      this.isOpened = isOpened;
    });

    this.lengthLimit = this._translateService.currentLang === LanguageCode.English ? MAX_CHARACTERS.Tag_EN : MAX_CHARACTERS.Tag_KO;
    this.sceneNamelengthLimit =
      this._translateService.currentLang === LanguageCode.English ? MAX_CHARACTERS.SceneName_EN : MAX_CHARACTERS.SceneName_KO;
  }

  ngOnInit(): void {
    this._appService.polygonCountSubject.pipe(untilDestroyed(this)).subscribe((count) => {
      this.polygons = count;
      this._cd.detectChanges();
    });

    this._accountService.currentAccountOption$.pipe().subscribe((data) => {
      if (data) {
        this.isEnterpriseOwner = data.Role === EnterpriseRole.Owner;
      }
    });

    this._oxrSettingsService.scene$.pipe(untilDestroyed(this)).subscribe(async (scene: XRScene | null) => {
      if (scene) {
        this.scene = scene;
        this.editDescription = false;
        this.editInfo = false;
        this.image = '';

        this.form.get('Name')?.setValue(scene.Name);
        this.form.get('Description')?.setValue(scene.Description);
        this.form.get('Category')?.setValue(undefined);
        this.form.get('Tags')?.setValue(scene.Tags);
        this.form.get('Favicon')?.setValue(scene.Favicon);
        this.image = scene.Favicon;

        const allocatedMedia = await this._sceneFileService.getAllocatedMediaFiles(this.scene.Id);
        this.sceneStorageUsageInfo = this._sceneFileService.getIntegratedSceneStorageUsageInfo(this.scene, allocatedMedia);
        this._cd.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    this._oxrSettingsService.closePanel();
  }

  openDetails(name: string): void {
    this._oxrSettingsService.openDetails(name);
  }

  closeDetails(): void {
    this._oxrSettingsService.closeDetails();
    this._cd.markForCheck();
  }

  closePanel(): void {
    if (this.canClosePanel) {
      this._oxrSettingsService.closePanel();
    }
  }

  onDelete() {
    const modalRef = this._modalService.open(ConfirmationComponent);

    if (modalRef) {
      modalRef.instance.title = this._translateService.instant('shared.confirmation.confirmAction');
      modalRef.instance.body = this._translateService.instant('oxr.settings.deleteConfirmation');
      modalRef.instance.confirmAction = this._translateService.instant('shared.actions.delete');

      modalRef.result.then(async (result) => {
        if (result) {
          const payload = { sceneId: this.scene.Id, action: 'delete' };
          await this._oxrSettingsService.deleteScene();
          this.onSceneDelete.emit(payload);
        } else {
          this._oxrSettingsService.openPanel(this.scene);
        }
      });
    }
  }

  onReset() {
    const modalRef = this._modalService.open(ConfirmationComponent);

    if (modalRef) {
      modalRef.instance.title = this._translateService.instant('shared.confirmation.confirmAction');
      modalRef.instance.body = this._translateService.instant('oxr.settings.resetConfirmation');
      modalRef.instance.confirmAction = this._translateService.instant('shared.actions.reset');

      modalRef.result.then(async (result) => {
        if (result) {
          const payload = { sceneId: this.scene.Id, action: 'reset' };
          await this._oxrSettingsService.resetScene();
          this.onSceneDelete.emit(payload);
        } else {
          this._oxrSettingsService.openPanel(this.scene);
        }
      });
    }
  }

  onPngUpload() {
    const modalRef = this._modalService.open(MediaUploadComponent, { closeOnBackdropClick: false, closeOnDestroy: false });
    if (modalRef) {
      const dropDownData: DropdownItem[] = [
        {
          name: 'Favicon',
          assetType: AssetType.Favicon,
          extensions: '.jpeg, .jpg, .png',
          customMessage: this._translateService.instant('shared.fields.recommendedSize') + ' : 56*56px',
          limitSize: 1024 * 1024,
        },
      ];

      this.canClosePanel = false;
      modalRef.instance.modalRef = this._modalService;
      modalRef.instance.scene = this.scene;
      modalRef.instance.title = this._translateService.instant('shared.fields.favicon');
      modalRef.instance.data = dropDownData;
      modalRef.instance.kind = dropDownData[0].assetType;
      modalRef.result.then((result) => {
        if (result) {
          this.image = result;
          this.form.get('Favicon')?.setValue(result);
        }
        this.canClosePanel = true;
      });
    }
  }

  getTagsString(event: string) {
    this.tagsString = event;
    this.form.get('Tags')?.setValue(this.tagsString);
  }

  convertBytesToGBOrMBOrKB(bytes: number) {
    return convertBytesToGBOrMBOrKB(bytes, 1);
  }
}
