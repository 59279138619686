import { ArcRotateCamera, ICameraInput, Nullable, Tools, Vector3 } from '@/data/src/lib/babylon';

/**
 * Defines the key board controls for a camera input
 */
export class CreatorCameraInput implements ICameraInput<ArcRotateCamera> {
  private _keys: string[] = [];
  private keysUp = ['KeyW'];
  private keysDown = ['KeyS'];
  private keysLeft = ['KeyA'];
  private keysRight = ['KeyD'];
  private _onKeyDown;
  private _onKeyUp;

  constructor(public camera: Nullable<ArcRotateCamera>) {}

  getClassName(): string {
    return 'Pan Input';
  }

  getSimpleName(): string {
    return 'Pan';
  }

  attachControl(noPreventDefault?: boolean): void {
    if (this.camera) {
      const engine = this.camera.getEngine();
      const element = engine.getInputElement();
      this.camera.zoomToMouseLocation = true;
      if (!this._onKeyDown && element) {
        element.tabIndex = 1;
        this._onKeyDown = (event: { code: string; preventDefault: () => void }) => {
          if (event.code === 'Escape') {
            this._keys.length = 0;
          } else if (
            this.keysUp.includes(event.code) ||
            this.keysDown.includes(event.code) ||
            this.keysLeft.includes(event.code) ||
            this.keysRight.includes(event.code)
          ) {
            const index = this._keys.indexOf(event.code);
            if (index === -1) {
              this._keys.push(event.code);
            }
            if (!noPreventDefault) {
              event.preventDefault();
            }
          }
        };
        this._onKeyUp = (event) => {
          if (
            this.keysUp.includes(event.code) ||
            this.keysDown.includes(event.code) ||
            this.keysLeft.includes(event.code) ||
            this.keysRight.includes(event.code)
          ) {
            const index = this._keys.indexOf(event.code);
            if (index >= 0) {
              this._keys.splice(index, 1);
            }
            if (!noPreventDefault) {
              event.preventDefault();
            }
          }
        };
        element.addEventListener('keydown', this._onKeyDown, false);
        element.addEventListener('keyup', this._onKeyUp, false);
      }
    }
  }

  _onLostFocus() {
    this._keys = [];
  }

  detachControl(): void {
    if (this.camera) {
      const engine = this.camera.getEngine();
      const element = engine.getInputElement();
      if (this._onKeyDown && element) {
        element.removeEventListener('keydown', this._onKeyDown);
        element.removeEventListener('keyup', this._onKeyUp);
        Tools.UnregisterTopRootEvents(window, [{ name: 'blur', handler: this._onLostFocus }]);
        this._keys = [];
        this._onKeyDown = null;
        this._onKeyUp = null;
      }
    }
  }

  /**
   * Checks the input keyboard code and performs the corresponding action
   */
  check(): void {
    //
    if (this._onKeyDown) {
      const camera = this.camera;
      if (camera) {
        for (let index = 0; index < this._keys.length; index++) {
          const keyCode = this._keys[index];
          if ([...this.keysLeft, ...this.keysUp, ...this.keysRight, ...this.keysDown].includes(keyCode)) {
            const speed = camera.speed;
            const direction = camera.target.subtract(camera.position);
            const panDirection = new Vector3();
            const scale = (speed * 50) / camera.panningSensibility;
            if (this.keysUp.includes(keyCode)) {
              panDirection.copyFrom(direction.normalize().scale(scale));
            } else if (this.keysDown.includes(keyCode)) {
              panDirection.copyFrom(direction.normalize().scale(-scale));
            } else if (this.keysLeft.includes(keyCode)) {
              panDirection.copyFrom(
                direction
                  .cross(new Vector3(0, 1, 0))
                  .normalize()
                  .scale(-scale),
              );
            } else if (this.keysRight.includes(keyCode)) {
              panDirection.copyFrom(
                direction
                  .cross(new Vector3(0, 1, 0))
                  .normalize()
                  .scale(scale),
              );
            }
            camera.position = camera.position.add(panDirection);
            camera.target = camera.target.add(panDirection);
          }
          if (camera.getScene().useRightHandedSystem) {
            camera.cameraDirection.z *= -1;
          }
        }
      }
    }
  }

  checkInputs?: (() => void) | undefined = this.check;
}
