import { Permission } from '../../enums/permission';

/**
 * Defines the different types of database models, used for data management,
 * data cache filtering and querying throughout the application
 */

export enum ModelType {
  Asset = 'Asset',
  Instance = 'Instance',
  Scene = 'Scene',
  Category = 'Category',
  Brand = 'Brand',
  Company = 'Company',
  Unknown = 'Unknown',
  SceneAsset = 'SceneAsset',
  Theme = 'Theme',
  Account = 'Account',
  UserScene = 'UserScene',
  FileReference = 'FileReference',
  Market = 'Market',
  UserSetting = 'UserSetting',
  Support = 'Support',
  AssetVersion = 'AssetVersion',
  Notification = 'Notification',
  Payment = 'Payment',
  Enterprise = 'Enterprise',
  Domain = 'Domain',
  SceneFile = 'SceneFile',
  EnterpriseContract = 'EnterpriseContract',
}

/**
 * Interface for all database models
 */
export interface IModel {
  /**
   * Unique identifier
   */
  Id: string;

  /**
   * The type of model (readonly)
   */
  ModelType: ModelType;

  /**
   * Converts the model to json which can be posted directly to the back end
   */
  toJson(): string;

  /**
   * Updates the id of this model as well as all dependencies
   * @param id
   */
  setId(id: string): void;
}
/**
 * Interface of a user permission for the parent resource
 */
export interface IUserPermission extends IModel {
  ParentId: string;
  UserId: string;
  Permission: Permission;
  Username: string;
}

/**
 * Interface for a model property
 */
export interface IProperty extends IModel {
  Name: string;
  Value: string;
}
