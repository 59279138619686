import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import { LocalStorageKeys } from '../enums/storage-keys';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(@Inject(LOCAL_STORAGE) private readonly _localStorage: Storage) {}

  get length(): number {
    return this._localStorage.length;
  }

  clear() {
    this._localStorage.clear();
  }

  getItem(key: LocalStorageKeys | string) {
    return this._localStorage.getItem(key);
  }

  key(index: number): string | null {
    return this._localStorage.key(index);
  }

  removeItem(key: LocalStorageKeys) {
    this._localStorage.removeItem(key);
  }

  setItem(key: LocalStorageKeys, value: string) {
    this._localStorage.setItem(key, value);
  }
}
