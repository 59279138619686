<div class="component-group">
  <div class="tag" [ngClass]="{ selected: !!checkedTags.includes(tag) }" *ngFor="let tag of tags" (click)="tagClicked(tag)">
    {{ 'shared.filters.' + tag | translate }}
  </div>
  <button *ngIf="tags.length && isResettable" class="reset-tags-btn" [disabled]="!checkedTags.length" (click)="reset()">
    <div class="reset-tags-btn__icon icon-circle-movement"></div>
    <span>{{ 'shared.filters.reset' | translate }}</span>
  </button>
</div>
<div class="component-group align-right">
  <ng-content></ng-content>
</div>
