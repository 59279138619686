import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@/app/src/environments/environment';
import { AccountService } from '@/data/src/lib/services/account.service';
import { DomainMap } from '@/data/src/lib/models/data/domain';
import { DomainStatus, VerificationStatus } from '@/data/src/lib/enums/domain';
import { ModelType } from '@/data/src/lib/models/data/base';
import { newModel } from '@/data/src/lib/factories/model-factory';

const MS_TO_DAY = 1000 * 3600 * 24;
/**
 * Manages the domain services
 */
@Injectable({
  providedIn: 'root',
})
export class DomainService {
  private dataSubject = new BehaviorSubject<DomainMap[]>([]);
  data$ = this.dataSubject.asObservable();

  private _changeSubject = new BehaviorSubject<DomainMap | undefined>(undefined);
  changeSubject$ = this._changeSubject.asObservable();

  protected endPoint!: string;

  constructor(
    public http: HttpClient,
    private accountService: AccountService,
  ) {
    this.endPoint = `${environment[this.accountService.region].crudApiUrl}/Domain`;
  }

  public async setChangeSubject(domain: DomainMap | undefined) {
    this._changeSubject.next(domain);
  }

  /**
   * Validates domain valid and domain available
   * @param path test.ownxr.com
   */
  public async validate(path: string) {
    return await lastValueFrom(this.http.get<{ IsValid: boolean; IsAvailable: boolean }>(`${this.endPoint}/Validate/${path}`));
  }

  /**
   * Posts a new domain to the backend
   */
  public async post(domain: DomainMap) {
    return await lastValueFrom(
      this.http.post(`${this.endPoint}`, JSON.stringify(domain), {
        headers: { 'Content-Type': 'application/json' },
      }),
    );
  }

  /**
   * Domains that the logged in user has used
   */
  public async get(enterpriseId = '') {
    return await lastValueFrom(
      this.http
        .get<DomainMap[]>(`${this.endPoint}/${enterpriseId}`, {
          headers: { 'Content-Type': 'application/json' },
        })
        .pipe(
          map((data) => {
            if (data && data.length) {
              data = data.map((e: DomainMap) => {
                const domain = newModel(e, ModelType.Domain) as DomainMap;
                domain.SceneModel && (domain.SceneModel = newModel(domain.SceneModel, ModelType.Scene));

                if (domain.SceneModel) {
                  const now = new Date();
                  const days = (now.getTime() - new Date(domain.SceneModel.Expires!).getTime()) / MS_TO_DAY;
                  domain.DaysLeft = days < 0 ? Math.floor(days * -1) : 0;
                  if (domain.SceneModel.isExpired) {
                    domain.Status = DomainStatus.Expired;
                  } else {
                    if (
                      domain.VerificationStatus === VerificationStatus.Succeded &&
                      domain.MobileVerificationStatus === VerificationStatus.Succeded
                    ) {
                      domain.Status = domain.SslEnabled && domain.MobileSslEnabled ? DomainStatus.Connected : DomainStatus.Connecting;
                    } else if (
                      domain.VerificationStatus === VerificationStatus.Pending ||
                      domain.MobileVerificationStatus === VerificationStatus.Pending
                    ) {
                      domain.Status = DomainStatus.Connecting;
                    } else if (
                      domain.VerificationStatus === VerificationStatus.Failed ||
                      domain.MobileVerificationStatus === VerificationStatus.Failed
                    ) {
                      domain.Status = DomainStatus.Disabled;
                    }
                  }
                } else {
                  domain.SceneModel = undefined;
                  domain.DaysLeft = 0;
                  domain.Status = DomainStatus.Error;
                }

                return domain;
              });
            }
            this.dataSubject.next(data);
            return data;
          }),
        ),
    );
  }

  /**
   *  Delete the domain mapping on our database
   *  @param path test.ownxr.com
   */
  public async delete(path: string) {
    return await lastValueFrom(
      this.http.delete(`${this.endPoint}?pcDomainPath=${path}`, {
        headers: { 'Content-Type': 'application/json' },
      }),
    );
  }

  /**
   * Updates a domain map on the backend
   */
  public async put(domain: DomainMap) {
    return await lastValueFrom(
      this.http.put(`${this.endPoint}`, JSON.stringify(domain), {
        headers: { 'Content-Type': 'application/json' },
      }),
    );
  }

  getCached(): DomainMap[] {
    return this.dataSubject.value;
  }
}
