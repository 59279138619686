import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-assets-menu-tab',
  styles: [
    `
      :host {
        flex: 1 1 auto;
      }
    `,
  ],
  templateUrl: './assets-menu-tab.component.html',
  styleUrls: ['./assets-menu-tab.component.scss'],
})
export class AssetsMenuTabComponent implements OnInit {
  @Input() selected!: boolean;
  @Input() disabled = false;
  @Input() header = '';
  @Output() onTabClick: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  tabClick() {
    this.onTabClick.emit(true);
  }
}
