import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { AccountService } from './account.service';
import { ModelType } from '../models/data/base';
import { lastValueFrom, map, BehaviorSubject, Observable } from 'rxjs';
import {
  CollaboratorInvitationPayload,
  EnterpriseInvitation,
  EnterpriseScene,
  EnterpriseUpdatePayload,
  EnterpriseUser,
  InvitationStatus,
  EnterpriseSceneUser,
  EnterpriseAccount,
  EnterpriseRoleNumeric,
} from '../models/data/enterprise';
import { environment } from '@/app/src/environments/environment';
import { XRScene } from '../models/data/scene';
import { newModel } from '../factories/model-factory';

@Injectable({
  providedIn: 'root',
})
export class EnterpriseService extends BaseApiService<EnterpriseAccount> {
  private noAuthHttp: HttpClient;

  private enterpriseScenesSubject: BehaviorSubject<XRScene[]> = new BehaviorSubject<XRScene[]>([]);
  public enterpriseScenes$ = this.enterpriseScenesSubject.asObservable();

  constructor(
    public http: HttpClient,
    private handler: HttpBackend,
    accountService: AccountService,
  ) {
    super(http, accountService, ModelType.Enterprise);
    this.noAuthHttp = new HttpClient(this.handler);
    this.endPoint = `${environment[this.accountService.region].crudApiUrl}`;
  }

  getOwnedEnterprises(): Observable<EnterpriseAccount[]> {
    return this.http.get<EnterpriseAccount[]>(`${this.endPoint}/Enterprise/ByUser`).pipe(
      map((res) => {
        if (res.length) {
          const mappedData: EnterpriseAccount[] = [];
          res.forEach((item) => {
            const newItem = newModel(item, this.modelType);
            mappedData.push(newItem);
            this.attach(newItem);
          });
          this.dataSubject.next(mappedData);
          return mappedData;
        } else {
          this.dataSubject.next([]);
          return [];
        }
      }),
    );
  }

  async updateEnterprise(payload: EnterpriseUpdatePayload) {
    try {
      const selections = this.dataSubject.getValue();
      const model: EnterpriseAccount | undefined = selections.find((v) => v.EnterpriseId === payload.id);
      if (model) {
        await lastValueFrom(this.http.put(`${this.endPoint}/Enterprise`, { ...payload }, { ...this.postOptions }));
        if (payload.name) {
          model.EnterpriseContract.Enterprise.Name = payload.name;
        }
        this.modify(model);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  getEnterpriseUsers(enterpriseId) {
    return this.http.get<EnterpriseUser[]>(`${this.endPoint}/Enterprise/${enterpriseId}/Users`);
  }

  getEnterpriseSceneUsers(enterpriseId: string, sceneId: string, enterpriseRole?: EnterpriseRoleNumeric) {
    let params = new HttpParams();
    if (enterpriseRole !== undefined) {
      params = params.set('role', enterpriseRole);
    }
    return this.http.get<EnterpriseSceneUser[]>(`${this.endPoint}/Enterprise/${enterpriseId}/Scene/${sceneId}/Users`, { params });
  }

  addUserToEnterpriseScene(sceneId: string, userIds: string[]) {
    return this.http.post<string[]>(`${this.endPoint}/Enterprise/EnterpriseScene/${sceneId}/User`, userIds);
  }

  deleteUserFromEnterpriseScene(sceneId: string, userIds: string[]) {
    return this.http.delete(`${this.endPoint}/Enterprise/EnterpriseScene/${sceneId}/User`, { body: userIds });
  }

  async inviteCollaborator(invitation: CollaboratorInvitationPayload) {
    try {
      await lastValueFrom(
        this.http.post(`${this.endPoint}/EnterpriseInvitation`, JSON.stringify(invitation), { ...this.postOptions, responseType: 'text' }),
      );
      return true;
    } catch (error) {
      throw error;
    }
  }

  getPendingCollaboratorInvitations(enterpriseId) {
    return this.http.get<EnterpriseInvitation[]>(`${this.endPoint}/EnterpriseInvitation/Pending/${enterpriseId}`);
  }

  async getMyCollaboratorInvitations() {
    return await lastValueFrom(
      this.http
        .get<EnterpriseInvitation[]>(`${this.endPoint}/EnterpriseInvitation/ByReceiver`)
        .pipe(map((data) => data.filter((item) => item.InvitationStatus === InvitationStatus.Send))),
    );
  }

  async answerToInvitation(payload: { enterpriseInvitationId: string; invitationStatus: InvitationStatus }) {
    try {
      await lastValueFrom(
        this.http.put(`${this.endPoint}/EnterpriseInvitation/AnswerToInvitation`, {}, { ...this.postOptions, params: { ...payload } }),
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  async getInvitedScenesByUserIds(enterpriseId, userIds: string[]) {
    return await lastValueFrom(this.http.post<EnterpriseScene[]>(`${this.endPoint}/Enterprise/${enterpriseId}/UsersScenes`, userIds));
  }

  async getEnterpriseScenes(enterpriseId) {
    return await lastValueFrom(
      this.http.get<XRScene[]>(`${this.endPoint}/Enterprise/${enterpriseId}/Scenes`).pipe(
        map((data: XRScene[]) => {
          this.enterpriseScenesSubject.next(data);
        }),
      ),
    );
  }

  async removeCollaborators(enterpriseId, userIds: string[]) {
    try {
      await lastValueFrom(this.http.post(`${this.endPoint}/Enterprise/${enterpriseId}/RemoveMultipleCollaborators`, userIds));
      return true;
    } catch (error) {
      return false;
    }
  }

  async leaveEnterprise(enterpriseId) {
    const model = this.dataSubject.value.find((m) => m.EnterpriseId === enterpriseId);
    if (model) {
      try {
        await lastValueFrom(this.http.post(`${this.endPoint}/Enterprise/Leave/${enterpriseId}`, {}));
        this.remove(model);
        return true;
      } catch (error) {
        return false;
      }
    }
    return false;
  }

  async deleteEnterpriseScene(sceneId: string) {
    try {
      await lastValueFrom(this.http.delete(`${this.endPoint}/Enterprise/Scene/${sceneId}`));

      const updatedEnterpriseSceneData = this.enterpriseScenesSubject.value.filter((scene) => scene.Id !== sceneId);
      this.enterpriseScenesSubject.next(updatedEnterpriseSceneData);
      return true;
    } catch (error) {
      return false;
    }
  }

  get cachedEnterpriseScenes() {
    return this.enterpriseScenesSubject.getValue();
  }
}
