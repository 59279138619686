import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OxrSettingsService } from '../../oxr-settings.service';

@Component({
  selector: 'oxr-settings-details-wrapper',
  templateUrl: './oxr-settings-details-wrapper.component.html',
  styleUrls: ['./oxr-settings-details-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OxrSettingsDetailsWrapperComponent {
  constructor(private _oxrSettingsService: OxrSettingsService) {}

  onBack(): void {
    this._oxrSettingsService.closeDetails();
  }

  onClose(): void {
    this._oxrSettingsService.closePanel();
  }
}
