import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApplicationService } from '@/view/src/app/app.service';
import { FilterPipe } from '@/data/src/lib/pipes/filter.pipe';
import { ListOrderOption } from '@/data/src/lib/models/interfaces/list-order-option';
import { AccountOption } from '@/data/src/lib/models/data/account';
import { AccountService } from '@/data/src/lib/services/account.service';
import { SOUND_ONLY_PNG } from '@/data/src/lib/view-manager';
import { FileType } from '@/data/src/lib/enums/file-type';
import { FileReference } from '@/data/src/lib/models/data/file-reference';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { SceneFileService } from '@/data/src/lib/services/scene-file.service';
import { AllocatedMedia } from '@/data/src/lib/models/data/scene-file';

export interface MediaFile {
  name?: string;
  url?: string;
}
@UntilDestroy()
@Component({
  selector: 'ui-media-upload-library',
  templateUrl: './media-upload-library.component.html',
  styleUrls: ['./media-upload-library.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaUploadLibraryComponent implements AfterViewInit, OnDestroy, OnInit {
  public observer: IntersectionObserver | undefined;
  public uploadedMedia: MediaFile;
  public filesLoaded = false;
  public searchText = '';
  public isUploading = false;

  public orderingOptions: ListOrderOption[] = [
    { value: 'Name', name: 'alphabeticallyAscShort' },
    { value: '-Name', name: 'alphabeticallyDescShort' },
    { value: '-DateCreated', name: 'lastAdded' },
    { value: 'DateCreated', name: 'oldest' },
  ];
  public orderBy: ListOrderOption = this.orderingOptions[2];
  public activeScene: XRScene | undefined = undefined;
  public allocatedMedia: AllocatedMedia | null = null;

  @Input() filterByFields: string[] = ['Name', 'Tags'];
  @Input() images: FileReference[] | any[];
  @Input() videos: FileReference[] | any[];

  @Input() uploadType: FileType = FileType.Image;
  @Output() onSelect = new EventEmitter<FileReference>();
  @ViewChild('libraryContainer') libraryContainer;

  selectedAccountOption: AccountOption | null = null;
  SOUND_ONLY_PNG = SOUND_ONLY_PNG;
  fileConditions = this._sceneFileService.fileConditions;

  constructor(
    private _appService: ApplicationService,
    private _accountService: AccountService,
    private _cd: ChangeDetectorRef,
    private _sceneFileService: SceneFileService,
  ) {
    this.activeScene = this._appService.getActiveModel() as XRScene;
  }

  ngOnInit(): void {
    this.selectedAccountOption = this._accountService.currentAccountOption.getValue();

    this._sceneFileService.allocatedMedia$.pipe(untilDestroyed(this)).subscribe((allocatedMedia) => {
      this.allocatedMedia = allocatedMedia;
    });
  }

  get numberOfItems() {
    const term = this.searchText ? this.searchText.toLowerCase().replace(/,/g, '').replace(/#/g, '') : '';
    const result = term
      ? this.media.filter((i) => {
          if (this.filterByFields?.length) {
            let contains = false;
            this.filterByFields.forEach((key) => {
              if (FilterPipe.contains(i[key], term)) {
                contains = true;
              }
            });
            return contains;
          } else {
            return FilterPipe.contains(i, term);
          }
        })
      : this.media;
    return result?.length || 0;
  }

  get media() {
    return this.uploadType === FileType.Image ? this.images : this.videos;
  }

  ngAfterViewInit(): void {
    this.createObserver();
  }

  async onFileInputChange(event: any) {
    const files = Array.from(event.target.files as FileList);
    await this.uploadFiles(files);
    if (event.target) {
      const target = event.target as HTMLInputElement;
      target.value = '';
    }
  }

  async onFileDrop(fileList: FileList) {
    const files = Array.from(fileList);
    await this.uploadFiles(files);
  }

  async uploadFiles(files: File[]) {
    this.isUploading = true;
    this._cd.detectChanges();

    await this._sceneFileService.uploadFiles(this.activeScene, files, this.uploadType);

    this.isUploading = false;
    this._cd.detectChanges();
  }

  changeOrderBy(orderBy: ListOrderOption): void {
    this.orderBy = orderBy;
  }

  createObserver() {
    const options = {
      root: this.libraryContainer.nativeElement,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const isIntersecting = (entry: IntersectionObserverEntry) => entry.isIntersecting || entry.intersectionRatio > 0;

    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (isIntersecting(entry)) {
          const imgElement = entry.target as HTMLImageElement;
          if (!imgElement.src) imgElement.src = entry.target.getAttribute('data-src') || '';
        }
      });
    }, options);
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = undefined;
    }
  }

  trackByFn(item, index) {
    return item.Id;
  }

  mediaSelect(asset: FileReference) {
    this.onSelect.emit(asset);
  }

  close() {}
}
