import { take } from 'rxjs';
import { ViewManager } from '..';
import { HemisphericLight, Mesh, Nullable, Observer, PickingInfo, PointerEventTypes, PointerInfo, Scene, Vector3 } from '../../babylon';
import { LightRenderable, ObjectRenderable, ScreenRenderable, TextRenderable } from '../renderables';
import { ElementType, TElement, TInteractableElement, TLightElement, TTextElement, ViewTool } from '../types';

type TInsertable = LightRenderable | ObjectRenderable | ScreenRenderable | TextRenderable;

export class InsertTool {
  name = ViewTool.Insert;
  type: ElementType;
  hookedRenderables: TInsertable[] = [];
  elements: TElement[] = [];
  isLocked: boolean;

  private selectionMeshes: Mesh[] = [];
  private occlusionMeshes: Mesh[] = [];

  private pointerObserver: Nullable<Observer<PointerInfo>>;
  private pickInfo: PickingInfo;
  private anchor = Vector3.Zero();
  private moveCount: number = 0;

  private observers: {
    follow?: Nullable<Observer<Scene>>;
  } = {};

  constructor(private viewManager: ViewManager) {
    this.onPointer = this.onPointer.bind(this);
  }

  get isHooked() {
    return !!this.elements.length;
  }

  get scene() {
    return this.viewManager.scene;
  }

  setup(elements: TElement[]) {
    this.clear();
    this.hookedRenderables = elements.map((element) => this.viewManager.createRenderable(element, false)) as TInsertable[];
    this.pointerObserver = this.viewManager.scene.onPointerObservable.add(this.onPointer);
    this.elements.push(...elements);
    this.isLocked = true;
    this.update();
  }

  clear() {
    this.elements.length = 0;
    this.hookedRenderables.forEach((renderable) => {
      renderable.dispose();
    });
    this.anchor.set(0, 0, 0);
    this.hookedRenderables.length = 0;
    this.pointerObserver && this.viewManager.scene.onPointerObservable.remove(this.pointerObserver);
    this.pointerObserver = null;
    this.isLocked = false;
    this.moveCount = 0;
    this.update();
  }

  interact() {
    return;
  }

  update() {
    this.selectionMeshes.forEach((mesh) => {
      mesh.renderingGroupId = 2;
    });
    this.occlusionMeshes.forEach((mesh) => {
      mesh.dispose();
    });
    this.selectionMeshes.length = 0;
    this.occlusionMeshes.length = 0;

    this.hookedRenderables.forEach((renderable) => {
      (renderable.helper?.getChildMeshes(false).concat(renderable.helper) ?? []).forEach((mesh) => {
        this.selectionMeshes.push(mesh as Mesh);
        this.occlusionMeshes.push((mesh as Mesh).clone(undefined, undefined, true));
      });

      this.selectionMeshes.forEach((mesh, i) => {
        mesh.renderingGroupId = 3;
        this.occlusionMeshes[i].material = this.viewManager.occlusionMaterial;
        this.occlusionMeshes[i].renderingGroupId = 2;
      });
    });

    this.observers.follow && this.scene.onBeforeRenderObservable.remove(this.observers.follow);

    if (this.selectionMeshes.length) {
      this.observers.follow = this.scene.onBeforeRenderObservable.add(() => {
        this.selectionMeshes.forEach((mesh, i) => {
          this.occlusionMeshes[i].position.copyFrom(mesh.position);
          mesh.rotationQuaternion
            ? this.occlusionMeshes[i].rotationQuaternion?.copyFrom(mesh.rotationQuaternion)
            : this.occlusionMeshes[i].rotation?.copyFrom(mesh.rotation);
          this.occlusionMeshes[i].scaling.copyFrom(mesh.scaling);
        });
      });
    }
  }

  onPointer(pointerInfo: PointerInfo) {
    this.pickInfo = this.viewManager.scene.pick(
      this.viewManager.scene.pointerX,
      this.viewManager.scene.pointerY,
      (mesh) => mesh === this.viewManager.drawingPlane,
      true,
    );
    if (pointerInfo.type === PointerEventTypes.POINTERMOVE) {
      this.moveCount += Math.hypot(pointerInfo.event.movementX ?? 0, pointerInfo.event.movementY ?? 0);
      if (this.pickInfo.pickedPoint) {
        this.hookedRenderables.forEach((renderable, i) => {
          this.anchor
            .copyFrom(this.pickInfo.pickedPoint as Vector3)
            .addInPlaceFromFloats(
              ...(this.hookedRenderables[i].element.parameters.position?.map(
                (x, j) => x - this.hookedRenderables[0].element.parameters.position![j],
              ) as [number, number, number]),
            );
          if (renderable.element.type === ElementType.Light && renderable instanceof LightRenderable) {
            renderable.gizmo.attachedMesh?.position.copyFrom(this.anchor).addInPlaceFromFloats(0, 3, 0);
          } else if (
            ((renderable.element.type === ElementType.Text && renderable instanceof TextRenderable) ||
              (renderable.element.type === ElementType.Screen && renderable instanceof ScreenRenderable) ||
              (renderable.element.type === ElementType.Object && renderable instanceof ObjectRenderable)) &&
            renderable.helper
          ) {
            renderable.helper.position.copyFrom(this.anchor);
          }
        });
      }
      if (this.moveCount > 10) {
        this.isLocked = true;
      }
    } else if (pointerInfo.type === PointerEventTypes.POINTERDOWN) {
      this.isLocked = false;
      this.moveCount = 0;
    } else if (pointerInfo.type === PointerEventTypes.POINTERUP && !this.isLocked) {
      this.viewManager.appService.setViewElements(
        [
          ...this.viewManager.appService.getViewElements(),
          ...(this.hookedRenderables
            .map((renderable) => {
              if (renderable.element.type === ElementType.Light && renderable instanceof LightRenderable) {
                const light = renderable.light;
                const color = light.diffuse.asArray();
                return {
                  ...renderable.element,
                  parameters: {
                    ...renderable.element.parameters,
                    ...(light instanceof HemisphericLight
                      ? {
                          position: [
                            ...renderable.helper.computeWorldMatrix().asArray().slice(12, 15),
                          ] as TLightElement['parameters']['position'],
                          direction: light.direction.asArray() as TLightElement['parameters']['direction'],
                          color,
                        }
                      : {
                          position: light.position.asArray() as TLightElement['parameters']['position'],
                          direction: (light.direction?.asArray() as TLightElement['parameters']['direction']) || [0, 0, 0],
                          color,
                        }),
                  },
                };
              } else if (
                (renderable.element.type === ElementType.Screen && renderable instanceof ScreenRenderable) ||
                (renderable.element.type === ElementType.Object && renderable instanceof ObjectRenderable)
              ) {
                return {
                  ...renderable.element,
                  parameters: {
                    ...renderable.element.parameters,
                    position: [
                      ...renderable.helper.computeWorldMatrix().asArray().slice(12, 15),
                    ] as TInteractableElement['parameters']['position'],
                    quaternion: (
                      renderable.helper.rotationQuaternion ?? renderable.helper.rotation.toQuaternion()
                    ).asArray() as TInteractableElement['parameters']['quaternion'],
                    scaling: [...renderable.helper.scaling.asArray()] as TInteractableElement['parameters']['scaling'],
                  },
                };
              } else if (renderable.element.type === ElementType.Text && renderable instanceof TextRenderable) {
                return {
                  ...renderable.element,
                  parameters: {
                    ...renderable.element.parameters,
                    position: [...renderable.helper.computeWorldMatrix().asArray().slice(12, 15)] as TTextElement['parameters']['position'],
                    quaternion: (
                      renderable.helper.rotationQuaternion ?? renderable.helper.rotation.toQuaternion()
                    ).asArray() as TTextElement['parameters']['quaternion'],
                    scaling: [...renderable.helper.scaling.asArray().slice(0, 2)] as TTextElement['parameters']['scaling'],
                  },
                };
              }
              return;
            })
            .filter((e) => e) as TElement[]),
        ],
        !!this.hookedRenderables.length,
      );
      this.pointerObserver && this.viewManager.scene.onPointerObservable.remove(this.pointerObserver);
      this.pointerObserver = null;
      this.viewManager.appService.viewElementsSubject.pipe(take(1)).subscribe((elements) => {
        this.viewManager.scene.onAfterRenderObservable.addOnce(() => {
          const timeout = setTimeout(() => {
            this.viewManager.setTool(
              ViewTool.Select,
              this.hookedRenderables.map((renderable) => elements.find(({ id }) => id === renderable.elementId)) as TElement[],
            );
            clearTimeout(timeout);
          }, 0);
        });
      });
    }
  }

  onDelete() {
    this.onEscape();
  }

  onEscape() {
    this.viewManager.setTool(ViewTool.Select);
  }
}
