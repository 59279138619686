import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { OutputFormat } from 'ngx-color-picker/lib/helpers';
import { round } from 'lodash';
import { ViewManagerUtils } from '@/data/src/lib/utils/view-manager-utils';
import { WINDOW } from '@ng-web-apis/common';

const CHAR_KEY_REGEXP = /(\w|\s)/;
const HEX_CHAR_REGEXP = /[0-9a-f]$/;

@Component({
  selector: 'ui-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit {
  @Input() label: string;
  @Input() outputFormat: OutputFormat = 'rgba';
  @Input() showValue = true;
  @Input() showTransparency = false;

  @Input() set disabled(value: boolean) {
    if (value) {
      this._defaultColor = 'rgba(102,111,120,1)';
    }
    this._disabled = value;
  }

  get disabled() {
    return this._disabled;
  }

  @Input() set defaultColor(value: string) {
    let color = value;
    const isHex = ViewManagerUtils.IsHexValid(value);
    if (isHex) {
      color = ViewManagerUtils.HexToRgba(value);
    }
    const colorArray = ViewManagerUtils.GetColorArray(color);
    this._opacity = round(colorArray[3] * 100, 5).toString();
    this._defaultColor = ViewManagerUtils.GetColorString(colorArray);
    this._hex = this.rgbToHex(this._defaultColor);
  }

  get defaultColor() {
    return this._defaultColor;
  }

  @Input() set opacity(value: string) {
    if (isNaN(Number(value))) {
      return;
    }
    this._opacity = value;
    const colorArray = ViewManagerUtils.GetColorArray(this.defaultColor);
    colorArray[3] = Number(this._opacity) / 100;
    this._defaultColor = ViewManagerUtils.GetColorString(colorArray);
    this._hex = this.rgbToHex(this._defaultColor);
  }

  get opacity() {
    return this._opacity;
  }

  @Output() changed: EventEmitter<string> = new EventEmitter();
  @Output() changeEnd: EventEmitter<string> = new EventEmitter();
  @Output() opacityChange: EventEmitter<string> = new EventEmitter();

  private _disabled = false;
  public _defaultColor = 'rgba(102,111,120,1)';
  private _opacity = '100';
  public _hex: string;
  colorPickerPosition = 'bottom-left';
  showPicker = false;

  constructor(
    private element: ElementRef,
    @Inject(WINDOW) private readonly _window: Window,
  ) {}

  ngOnInit(): void {}

  onOpacityChange(event) {
    this.opacity = Math.min(Math.max(Number(event.target.value), 0), 100).toString();
    event.target.value = this.opacity;
    this.opacityChange.emit(this._defaultColor);
  }

  onChanged() {
    this.changed.emit(this._defaultColor);
  }

  onChangeEnd() {
    if (!ViewManagerUtils.IsHexValid(this._hex)) {
      this._hex = this.rgbToHex(this._defaultColor);
    }
    this.changeEnd.emit(this._defaultColor);
  }

  onMouseDown() {
    this.colorPickerPosition =
      this._window.innerHeight - 300 - this.element.nativeElement.getBoundingClientRect().bottom < 0 ? 'top-left' : 'bottom-left';
  }

  ngOnDestroy() {
    this.showPicker = false;
  }

  rgbToHex(rgb: string) {
    return ViewManagerUtils.RgbToHex(rgb);
  }

  onColorInput(input: string) {
    if (!!ViewManagerUtils.IsHexValid(input)) {
      const rgba = ViewManagerUtils.HexToRgba(input);
      const colorArray = ViewManagerUtils.GetColorArray(rgba);
      this._opacity = (colorArray[3] * 100).toString();
      this.changed.emit(ViewManagerUtils.GetColorString(colorArray));
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (CHAR_KEY_REGEXP.test(String.fromCharCode(event.keyCode)) && !HEX_CHAR_REGEXP.test(event.key)) {
      event.preventDefault();
    }
  }
}
