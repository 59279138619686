import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TooltipPosition } from '../../../directive/tooltip.directive';

@Component({
  selector: 'ui-side-bar-item',
  templateUrl: './side-bar-item.component.html',
  styleUrls: ['./side-bar-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideBarItemComponent implements OnInit {
  toolTipPositions = TooltipPosition;

  @Input() icon!: string;
  @Input() text: string;
  @Input() selected = false;
  @Input() badge: number | string | null;
  @Input() disabled = false;
  @Input() tooltip: string;

  @Output() itemClick = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onClick() {
    if (!this.disabled) {
      this.itemClick.emit();
    }
  }
}
