import { AccountService, LanguageCode } from '@/data/src/lib/services/account.service';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FileService } from 'projects/data/src/lib/services/file.service';
import { map } from 'rxjs';
import { ApplicationService } from '@/view/src/app/app.service';
import { ModalService } from '../../modal.service';
import { MAX_CHARACTERS } from '@/data/src/lib/enums/max-length';
import { INTERACTABLE_TYPES, PopupType, TInteractableElement, TPopup } from '@/data/src/lib/view-manager';
import { FileType } from '@/data/src/lib/enums/file-type';
import { QuillEditorMediaService } from '../../../components/quill-editor-media/quill-editor-media.service';
import { SpaceToolColor } from '../../../utils/dragDropController';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { ScenePlan } from '@/data/src/lib/enums/pricing-plan';

@UntilDestroy()
@Component({
  selector: 'ui-text-tool',
  templateUrl: './text-tool.component.html',
  styleUrls: ['./text-tool.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextToolComponent implements OnInit, AfterViewInit {
  message = '';
  data: { [key: string]: string };
  isPreviewMode = false;
  isReadonly = false;
  titleLimit = MAX_CHARACTERS.Title_KO; //ko 30 en 50
  selectedElement?: TInteractableElement;
  resizeObserver: ResizeObserver;
  quillEditorInstance: any;
  contentHeight = 514;
  plan = ScenePlan;

  TITLE_MIN_HEIGHT = '150px';
  FULL_HEIGHT = 664;

  @ViewChild('textarea') textarea: ElementRef<HTMLTextAreaElement>;
  @ViewChild('editorWrapper') editorWrapper: ElementRef<HTMLTextAreaElement>;

  colors: SpaceToolColor[] = [
    { background: '#BABABA', text: 'white', isSelected: false },
    { background: '#D4910D', text: 'white', isSelected: false },
    { background: '#0DD4BC', text: 'white', isSelected: false },
    { background: '#0D74D4', text: 'white', isSelected: true },
    { background: '#935769', text: 'white', isSelected: false },
    { background: '#212121', text: 'white', isSelected: false },
  ];

  get isTitleHidden() {
    return this.data?.['title-hidden'] === 'true';
  }

  get scenePlan() {
    const currentScene = this._appService.getActiveModel() as XRScene;
    return currentScene?.Plan;
  }

  constructor(
    private _appService: ApplicationService,
    private _modalService: ModalService,
    public fileService: FileService,
    private _accountService: AccountService,
    private _cd: ChangeDetectorRef,
    private _quillEditorMediaService: QuillEditorMediaService,
  ) {
    this._accountService.language$
      .pipe(
        map((lang) => (lang === LanguageCode.English ? MAX_CHARACTERS.Title_EN : MAX_CHARACTERS.Title_KO)),
        untilDestroyed(this),
      )
      .subscribe((titleLimit) => {
        this.titleLimit = titleLimit;
        this.updateView();
      });
  }

  ngOnInit(): void {
    this._appService.viewElementsSubject.pipe(untilDestroyed(this)).subscribe((elements) => {
      this.selectedElement = elements.find(({ id }) => id === this.selectedElement?.id) as TInteractableElement;
      this._cd.detectChanges();
    });

    this._appService.selectedElementsSubject.pipe(untilDestroyed(this)).subscribe((elements) => {
      this.selectedElement =
        elements.length === 1 && INTERACTABLE_TYPES.includes(elements[0].type) ? (elements[0] as TInteractableElement) : undefined;
      this._cd.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    if (this.textarea?.nativeElement) {
      this.resizeObserver = new ResizeObserver(() => {
        const height = this.textarea.nativeElement.offsetHeight;
        this.data['title-height'] = `${height}px`;
        this.contentHeight = this.FULL_HEIGHT - height;
      });
      this.resizeObserver.observe(this.textarea.nativeElement);
    }
  }

  getEditorInstance(instance) {
    this.quillEditorInstance = instance;
    const toolbar = this.quillEditorInstance.getModule('toolbar');
    toolbar.addHandler('image', () => this.mediaHandler(this.quillEditorInstance, FileType.Image));
    toolbar.addHandler('video', () => this.mediaHandler(this.quillEditorInstance, FileType.Video));
  }

  onTitleInput(ev) {
    let text = this.textarea.nativeElement.value;
    if (text?.length > this.titleLimit) {
      text = text.substring(0, this.titleLimit);
      this.data['title'] = text;
      this.updateView();
      this.textarea.nativeElement.blur();
    }
    this.data['title'] = text;
    this._cd.detectChanges();
  }

  setTextareaPadding(textarea) {
    return textarea.clientHeight / 2 - 23 + 'px';
  }

  updateView() {
    this._cd.markForCheck();
  }

  close() {
    this._modalService.close(false, this['modalContainerRef']);
  }

  save() {
    let popups: TPopup[] = [],
      oldData = {};
    if (this.selectedElement?.parameters?.popups?.length) {
      popups = this.selectedElement.parameters.popups;
      oldData = popups[0];
    }
    this.selectedElement &&
      this._appService.updateViewElement(
        {
          ...this.selectedElement,
          parameters: {
            ...this.selectedElement.parameters,
            popups: [
              {
                ...oldData,
                type: PopupType.Text,
                parameters: [...Object.entries(this.data).map(([key, value]) => ({ key, value }))],
              },
              ...popups.slice(1),
            ],
          },
        } as TInteractableElement,
        true,
      );
    this._modalService.close(true, this['modalContainerRef']);
  }

  setTextColor(colorCode: string): void {
    this.data['text-colour'] = colorCode;
    this._cd.detectChanges();
  }

  setBackgroundColor(colorCode: string): void {
    this.data['background-colour'] = colorCode;
    this._cd.detectChanges();
  }

  enablePreviewMode(bool) {
    this.isPreviewMode = bool;
    if (bool && this.resizeObserver) {
      this.resizeObserver.disconnect();
    } else {
      this.resizeObserver.observe(this.textarea.nativeElement);
    }
  }

  mediaHandler(quillEditorInstance, mediaType: FileType) {
    const range = quillEditorInstance.getSelection();
    if (range) {
      const modal = this._quillEditorMediaService.open();
      if (modal) {
        modal.instance.mediaType = mediaType;
        modal.result.then((url) => {
          if (url) {
            quillEditorInstance.insertEmbed(range.index, mediaType === FileType.Image ? 'image' : 'video', url, 'user');
          }
        });
      }
    }
  }

  onModelChange(event: any) {
    this.data['body'] = event;
    this._cd.detectChanges();
  }

  setTitleHidden() {
    this.data['title-hidden'] = this.isTitleHidden ? 'false' : 'true';
    this._cd.detectChanges();
  }
}
