import { Injectable, NgZone, Type } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, interval, of, take } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { Color4, ISceneLoaderAsyncResult, Mesh, Node, PBRMaterial, SceneLoader, Texture } from '@/data/src/lib/babylon';

import { TEnvironmentParameters, TObjectParameters, XRAsset, XRAssetVersion } from '@/data/src/lib/models/data/asset';
import { ResultCallback } from '@/data/src/lib/models/callbacks/callbacks';
import { ModelType } from '@/data/src/lib/models/data/base';
import { AssetType, IAsset, IAssetVersion } from '@/data/src/lib/models/data/asset';
import { IScene } from '@/data/src/lib/models/data/scene';
import { XRScene, XRSceneInteraction } from '@/data/src/lib/models/data/scene';
import { FileService } from '@/data/src/lib/services/file.service';
import { DataCacheService } from '@/data/src/lib/services/data-cache.service';
import { AssetService } from '@/data/src/lib/services/asset.service';
import { AccountService } from '@/data/src/lib/services/account.service';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { TransactionService } from '@/data/src/lib/services/transaction.service';
import { UrlService } from '@/data/src/lib/services/url.service';
import { PlayerService } from './services/player.service';
import { environment } from '@/app/src/environments/environment';
import { IFileItem } from '@/data/src/lib/models/interfaces/ifile-item';
import { groupData } from '@/data/src/lib/utils/collection';
import { ScenePlan } from '@/data/src/lib/enums/pricing-plan';
import { Access, FileStorageType } from '@/data/src/lib/enums/access';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { InformationComponent } from '@/ui/src/lib/modal/information/information.component';
import { ConfirmationComponent } from '@/ui/src/lib/modal/confirmation/confirmation.component';
import { ProgressComponent } from '@/ui/src/lib/modal/progress/progress.component';
import { ContactformToolComponent } from '@/ui/src/lib/modal/scene/contactform-tool/contactform-tool.component';
import { GalleryToolComponent } from '@/ui/src/lib/modal/scene/gallery-tool/gallery-tool.component';
import { GoogleFormToolComponent } from '@/ui/src/lib/modal/scene/google-form-tool/google-form-tool.component';
import { GuestbookMode, GuestbookToolComponent } from '@/ui/src/lib/modal/scene/guestbook-tool/guestbook-tool.component';
import { LinkToolComponent } from '@/ui/src/lib/modal/scene/link-tool/link-tool.component';
import { TextToolComponent } from '@/ui/src/lib/modal/scene/text-tool/text-tool.component';
import { VideoToolComponent } from '@/ui/src/lib/modal/scene/video-tool/video-tool.component';
import { MobileConfirmationComponent } from '@/ui/src/lib/modal/mobile/m-confirmation/m-confirmation.component';
import { MobileContactFormToolComponent } from '@/mobile/src/app/components/tools/m-contact-form-tool/m-contact-form-tool.component';
import { MobileGalleryToolComponent } from '@/mobile/src/app/components/tools/m-gallery-tool/m-gallery-tool.component';
import { MobileGoogleFormToolComponent } from '@/mobile/src/app/components/tools/m-google-form-tool/m-google-form-tool.component';
import { MobileGuestbookToolComponent } from '@/mobile/src/app/components/tools/m-guestbook-tool/m-guestbook-tool.component';
import { MobileTextToolComponent } from '@/mobile/src/app/components/tools/m-text-tool/m-text-tool.component';
import { MobileVideoToolComponent } from '@/mobile/src/app/components/tools/m-video-tool/m-video-tool.component';
import { PanelMask } from '@/app/src/app/oxr/oxr-space/oxr-space.component';
import {
  ViewManager,
  CheckpointRenderable,
  CheckpointType,
  ElementType,
  EnvironmentType,
  TElement,
  TEnvironmentElement,
  TInteractableElement,
  TLandscapeElement,
  TStageElement,
  TCustomizationMaterial,
  TPopup,
  TRenderable,
  PopupType,
  ViewMode,
  ViewTool,
  CustomizeTool,
  SelectTool,
  TransformationMode,
  TransformationInfo,
  ENVIRONMENT_INTENSITY,
  ENVIRONMENT_LEVEL,
  ENVIRONMENT_ROTATION,
  ENVIRONMENT_TINT,
  INTERACTABLE_TYPES,
  LANDSCAPE_POSITION,
  LANDSCAPE_ROTATION,
  LANDSCAPE_SCALING,
  MAX_LIGHTS,
  OBJECT_POSITION,
  OBJECT_QUATERNION,
  OBJECT_SCALING,
  STAGE_POSITION,
  STAGE_ROTATION,
  STAGE_SCALING,
  TRANSPARENT_PNG,
  TObjectElement,
  ObjectRenderable,
} from '@/data/src/lib/view-manager';
import { deepCopy } from '@/data/src/lib/utils/data';
import { ElementsTransaction } from './transactions/elements-transaction';
import { ViewManagerUtils } from '@/data/src/lib/utils/view-manager-utils';
import { JoystickEvent } from '@/ui/src/lib/components/joystick/joystick.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SceneInteractionType } from '@/data/src/lib/enums/scene-interaction';
import { Action } from '@/data/src/lib/enums/transaction-type';
import { APIv2, NEW_ASSET_ID, NEW_ASSET_VERSION_ID } from '@/data/src/lib/apiv2';

const POPUP_TIMEOUT_DURATION = 50;

export enum ViewType {
  Asset,
  Scene,
}

export enum AssetStatus {
  Undefined = 'Undefined',
  Pending = 'Pending',
  Ready = 'Ready',
}

export class Clipboard {
  public elements: TElement[] = [];

  public isInUse = false;

  clear() {
    this.elements.length = 0;
  }
}

export enum SceneMode {
  Default,
  Premuim,
}

/**
 * Tracks and manages the application state
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  //Behaviours Subjects
  private sceneModeSubject: BehaviorSubject<SceneMode> = new BehaviorSubject<SceneMode>(SceneMode.Default);
  private hoverActiveSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private activeModelSubject: BehaviorSubject<IAsset | IScene | undefined> = new BehaviorSubject<IAsset | IScene | undefined>(undefined);

  private selectedMeshScreenCoordsSubject: BehaviorSubject<{ x: number; y: number; width: number; height: number } | undefined> =
    new BehaviorSubject<{ x: number; y: number; width: number; height: number } | undefined>(undefined);
  private transformationModeSubject = new BehaviorSubject<TransformationMode>(TransformationMode.Translate);
  private transformationInfoSubject = new BehaviorSubject<TransformationInfo>({ x: undefined, y: undefined, z: undefined });
  private _assetMap = new Map<string, boolean>();
  private _assetMeshMap = new Map<string, ISceneLoaderAsyncResult | undefined>();
  private _assetStatusMap = new Map<string, AssetStatus>();
  private _progressMap = new Map<string, number>();
  private _thumbnailMap = new Map<string, string>();

  private _panelOverrides = new Map<PanelMask, boolean>();
  public panelOverridesSubject: BehaviorSubject<Map<PanelMask, boolean>> = new BehaviorSubject<Map<PanelMask, boolean>>(
    this._panelOverrides,
  );

  public viewManagerSubject: BehaviorSubject<ViewManager | undefined> = new BehaviorSubject<ViewManager | undefined>(undefined);
  public viewModeSubject: BehaviorSubject<ViewMode | undefined> = new BehaviorSubject<ViewMode | undefined>(undefined);
  public viewElementsSubject: BehaviorSubject<TElement[]> = new BehaviorSubject<TElement[]>([]);
  public selectedElementsSubject: BehaviorSubject<TElement[]> = new BehaviorSubject<TElement[]>([]);
  public customizationMaterialsSubject: BehaviorSubject<TCustomizationMaterial[]> = new BehaviorSubject<TCustomizationMaterial[]>([]);
  public selectedMaterialsSubject: BehaviorSubject<TCustomizationMaterial[]> = new BehaviorSubject<TCustomizationMaterial[]>([]);
  public xrSupportSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public selectedAssetVersionSubject: BehaviorSubject<XRAssetVersion | undefined> = new BehaviorSubject<XRAssetVersion | undefined>(
    undefined,
  );

  public imagePreview: IFileItem | undefined = undefined;

  public assetMapSubject: BehaviorSubject<Map<string, boolean>> = new BehaviorSubject<Map<string, boolean>>(this._assetMap);
  public assetStatusSubject: BehaviorSubject<Map<string, AssetStatus>> = new BehaviorSubject<Map<string, AssetStatus>>(
    this._assetStatusMap,
  );
  public progressMapSubject: BehaviorSubject<Map<string, number>> = new BehaviorSubject<Map<string, number>>(this._progressMap);
  public thumbnailMapSubject: BehaviorSubject<Map<string, string>> = new BehaviorSubject<Map<string, string>>(this._thumbnailMap);
  public polygonCountSubject = new BehaviorSubject<number>(0);
  public assetSizeSubject = new BehaviorSubject<number[]>([NaN, NaN, NaN]);
  public openPanelsMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  //Observables
  public sceneMode$ = this.sceneModeSubject.asObservable();
  public hoverActive$ = this.hoverActiveSubject.asObservable();
  public activeModel$ = this.activeModelSubject.asObservable();

  public selectedMeshScreenCoordinates$ = this.selectedMeshScreenCoordsSubject.asObservable();

  public transformationMode$ = this.transformationModeSubject.asObservable();
  public transformationInfo$ = this.transformationInfoSubject.asObservable();

  public tutorialMode = false;
  public assetMap$ = this.assetMapSubject.asObservable();
  public assetStatusMap$ = this.assetStatusSubject.asObservable();
  public progressMap$ = this.progressMapSubject.asObservable();
  public thumbnailMap$ = this.thumbnailMapSubject.asObservable();
  public openPanelsMenu$ = this.openPanelsMenu.asObservable();
  public panelOverrides$ = this.panelOverridesSubject.asObservable();

  //Fields
  public clipboard: Clipboard = new Clipboard();

  constructor(
    private accountService: AccountService,
    public dataCache: DataCacheService,
    public _sceneService: SceneService,
    private assetService: AssetService,
    public transactionService: TransactionService,
    public playerService: PlayerService,
    private fileService: FileService,
    public modalRef: ModalService,
    private _translateService: TranslateService,
    private _urlService: UrlService,
    private _ngZone: NgZone,
    public apiv2: APIv2,
  ) {
    this.setSceneMode();
    this.importMesh = this.importMesh.bind(this);
    this.launchViewManager = this.launchViewManager.bind(this);
  }

  public get isAuthenticated() {
    return !!this.accountService.account;
  }

  public get activeAccount() {
    return this.accountService.account;
  }

  public get isEnterpriseOrAdmin() {
    return this.accountService.isEnterpriseOrAdmin;
  }

  public setBackgroundColor(color: string) {
    const viewManager = this.getViewManager();
    if (!viewManager) {
      return;
    }
    viewManager.scene.clearColor = Color4.FromHexString(`#${ViewManagerUtils.RgbToHex(color)}`);
    return;
  }

  /** Gets the scene mode for the active view, scene mode is either Default for unpaid users or Premium for paid users */
  getSceneMode() {
    return this.sceneModeSubject.getValue();
  }

  private setSceneMode() {
    this.activeModel$.subscribe((view) => {
      if (view instanceof XRScene && view.Plan !== ScenePlan.Free) {
        if (this.sceneModeSubject.getValue() !== SceneMode.Premuim) {
          this.sceneModeSubject.next(SceneMode.Premuim);
        }
      } else if (this.sceneModeSubject.getValue() !== SceneMode.Default) {
        this.sceneModeSubject.next(SceneMode.Default);
      }
    });
  }

  /** Get the username of the active user */
  getActiveUsername() {
    return this.accountService.account?.Username;
  }

  focusOnCanvas() {
    this.getViewManager()?.scene?.getEngine().getRenderingCanvas()?.focus();
  }

  async launchViewManager(canvas: HTMLCanvasElement, activeModel: IAsset | IScene, isHandHeldDevice: boolean) {
    let viewManager = this.getViewManager();
    if (!viewManager) {
      viewManager = new ViewManager(canvas, activeModel, this, isHandHeldDevice);
      viewManager.initialize();
      this.setViewManager(viewManager);
    }
    if (activeModel?.ModelType === ModelType.Asset) {
      const asset = activeModel as XRAsset;
      const version = asset.versions?.[0] as IAssetVersion;
      if (!version) {
        return;
      }
      switch (asset.Type) {
        case AssetType.Object:
          this.setViewElements([
            ...(activeModel.Id === NEW_ASSET_ID
              ? []
              : [
                  {
                    id: uuid(),
                    name: asset.Name,
                    type: ElementType.Object,
                    parameters: {
                      assetVersionId: version.Id,
                      position: version.Parameters?.position ?? OBJECT_POSITION,
                      quaternion: version.Parameters?.quaternion ?? OBJECT_QUATERNION,
                      scaling: version.Parameters?.scaling ?? OBJECT_SCALING,
                    },
                  },
                ]),
            {
              id: uuid(),
              name: 'EditorEnvironment',
              type: ElementType.Environment,
              parameters: {
                assetVersionId: TRANSPARENT_PNG, // TODO: Integrate default assetVersionId
                type: EnvironmentType.Combined,
                intensity: version.Parameters?.environment?.intensity ?? ENVIRONMENT_INTENSITY,
                level: version.Parameters?.environment?.level ?? ENVIRONMENT_LEVEL,
                rotation: version.Parameters?.environment?.rotation ?? ENVIRONMENT_ROTATION,
                tint: version.Parameters?.environment?.tint ?? ENVIRONMENT_TINT,
              },
            },
          ]);
          break;
        case AssetType.Environment:
          this.setViewElements([
            {
              id: uuid(),
              name: asset.Name,
              type: ElementType.Environment,
              parameters: {
                assetVersionId: version.Id,
                type: EnvironmentType.Combined,
                intensity: version.Parameters?.intensity ?? ENVIRONMENT_INTENSITY,
                level: version.Parameters?.level ?? ENVIRONMENT_LEVEL,
                rotation: version.Parameters?.rotation ?? ENVIRONMENT_ROTATION,
                tint: version.Parameters?.tint ?? ENVIRONMENT_TINT,
              },
            },
          ]);
          break;
      }
    } else if (activeModel.ModelType === ModelType.Scene) {
      await this._sceneService.getByVersionId(activeModel.Id);
      const elements = (activeModel as IScene).SceneElements.map(ViewManagerUtils.FormatElementToManager);
      await this.apiv2.getBatchAssetVersions(
        elements
          .filter(({ type }) => [ElementType.Environment, ElementType.Landscape, ElementType.Object, ElementType.Stage].includes(type))
          .map(({ parameters: { assetVersionId, assetId } }) => assetVersionId ?? assetId),
      );
      this.setViewElements((activeModel as IScene).SceneElements.map(ViewManagerUtils.FormatElementToManager));
    }
  }

  destroyViewManager() {
    const viewManager = this.getViewManager();
    if (viewManager) {
      viewManager.engine.stopRenderLoop();
      viewManager.dispose();
    }
    this.viewManagerSubject.next(undefined);
  }

  /**
   * Sets the view manager for the application
   * @param viewManager Manager to be set
   */
  setViewManager(viewManager: ViewManager) {
    this.viewManagerSubject.next(viewManager);
  }

  /**
   * Gets the view manager of the application
   * @returns View manager
   */
  getViewManager() {
    return this.viewManagerSubject.getValue();
  }

  /**
   * Sets the view elements for the application
   * @param elements elements to be set
   */
  setViewElements(elements: TElement[], dirty = false) {
    const viewManager = this.viewManagerSubject.getValue();
    if (!viewManager) {
      return;
    }
    const transaction = new ElementsTransaction('ViewElements', Action.Modified);
    dirty && transaction.commit(viewManager);
    elements.forEach((element) => {
      this.updateViewRenderable(element);
    });
    if (viewManager.tool instanceof SelectTool) {
      this.getSelectedElements().some(({ id }) => !elements.find(({ id: elementId }) => id === elementId)) && viewManager.tool.interact();
    } else if (viewManager.tool instanceof CustomizeTool) {
      viewManager.tool.setup(this.getSelectedElements());
    }
    this.viewElementsSubject.next(elements);
    if (this.getViewMode() === ViewMode.Editor) {
      const assetElement = elements.find(({ type }) => type !== ElementType.Screen);
      if (!assetElement) {
        return;
      }
      const bounds = this.getRenderableBounds(assetElement);
      bounds && this.assetSizeSubject.next(bounds);
    }
  }

  /**
   * Gets the view elements of the application
   * @param targetType Optional parameter to get only the given type
   * @returns Array of view elements
   */
  getViewElements(targetType?: ElementType) {
    let result = this.viewElementsSubject.getValue().map(deepCopy);
    if (targetType) {
      result = result.filter(({ type }) => targetType === type);
    }
    return result;
  }

  /**
   * Pushes new element into the view elements array
   * @param element New element to be pushed
   */
  addViewElement(element: TElement, dirty = true) {
    const elements = this.viewElementsSubject.getValue();
    if (elements) {
      this.setViewElements([...elements, element], dirty);
    }
  }

  /**
   * Removes element from the view elements array
   * @param element Element to be removed
   */
  removeViewElements(elements: TElement[]) {
    const actualElements = this.viewElementsSubject.getValue();
    if (actualElements) {
      this.setViewElements(
        actualElements.filter(({ id: actualId }) => !elements.some(({ id }) => id === actualId)),
        true,
      );
    }
  }

  /**
   * Updates an element inside the view elements array
   * @param element to be updated as given
   */
  updateViewElement(element: TElement, silent = false) {
    this.updateViewElements([element], silent);
  }

  /**
   * Updates the given elements inside the view elements array
   * @param elements Set of elements to be updated
   * @param silent Flag indicates if data cache should be modified
   */
  updateViewElements(elements: TElement[], silent = false) {
    if (!elements?.length) {
      return;
    }
    const viewElements = this.viewElementsSubject.getValue();
    const viewManager = this.getViewManager();
    const newElements = deepCopy(viewElements);
    viewElements &&
      elements.forEach((element) => {
        const index = viewElements.findIndex(({ id }) => id === element.id) ?? -1;
        if (elements && ~index) {
          newElements[index] = deepCopy(element);
        }
      });
    if (silent) {
      if (viewManager?.tool instanceof SelectTool) {
        this.getSelectedElements().some(({ id }) => newElements.find(({ id: elementId }) => id === elementId)) &&
          viewManager.tool.setup(elements);
      } else if (viewManager?.tool instanceof CustomizeTool) {
        viewManager.tool.setup(this.getSelectedElements());
      }
      viewManager?.tool instanceof SelectTool && this.viewElementsSubject.next(newElements);
      this.dataCache.modify(this.getActiveModel()!);
      return;
    }
    this.setViewElements(newElements, true);
  }

  /**
   * Gets the renderable of the given element
   * @param element Element to get renderable from
   * @returns Renderable of the element
   */
  getViewRenderable(element: TElement): TRenderable | undefined {
    const viewManager = this.getViewManager();
    const predicate = (renderable) => element.id === renderable.elementId;
    switch (element.type) {
      case ElementType.Checkpoint:
        return viewManager?.checkpoints.find(predicate);
      case ElementType.Environment:
        return viewManager?.environments.find(predicate);
      case ElementType.Landscape:
        return viewManager?.landscapes.find(predicate);
      case ElementType.Light:
        return viewManager?.lights.find(predicate);
      case ElementType.Object:
        return viewManager?.objects.find(predicate);
      case ElementType.Screen:
        return viewManager?.screens.find(predicate);
      case ElementType.Stage:
        return viewManager?.stages.find(predicate);
      case ElementType.Text:
        return viewManager?.texts.find(predicate);
      default:
        return;
    }
  }

  /**
   * Sets the renderable of the given element
   * @param element Element to get renderable from
   */
  updateViewRenderable(element: TElement) {
    this.getViewRenderable(element)?.update(element);
  }

  /**
   * Gets the view mode of the view manager
   * @returns Active mode
   */
  getViewMode() {
    return this.getViewManager()?.activeMode;
  }

  /**
   * Sets the view mode of the view manager
   * @param mode Mode to be selected
   */
  setViewMode(mode: ViewMode) {
    this.getViewManager()?.setMode(mode);
    this.viewModeSubject.next(mode);
  }

  /**
   * Gets the selected elements of the view manager
   * @returns Array of selected elements
   */
  getSelectedElements() {
    return deepCopy(this.selectedElementsSubject.getValue());
  }

  /**
   * Sets the selected elements for the application
   * @param elements elements to be set
   */
  setSelectedElements(elements: TElement[]) {
    this.selectedElementsSubject.next(elements);
  }

  /**
   * Gets material descriptions available for customization
   * @returns Array of materials
   */
  getCustomizationMaterials() {
    return this.customizationMaterialsSubject.getValue();
  }

  /**
   * Sets material descriptions available for customization
   * @param materials materials to be set
   */
  setCustomizationMaterials(materials: TCustomizationMaterial[]) {
    this.customizationMaterialsSubject.next(materials);
  }

  /**
   * Gets the selected material descriptions for customization
   * @returns Array of selected materials
   */
  getSelectedMaterials() {
    return this.selectedMaterialsSubject.getValue();
  }

  /**
   * Sets the selected material descriptions for customization
   * @param materials materials to be set
   */
  setSelectedMaterials(materials: TCustomizationMaterial[]) {
    this.selectedMaterialsSubject.next(materials);
    this.getViewManager()?.tool?.update(materials);
  }

  /**
   * Enables or disables utility layer
   */
  setUtility(flag: boolean) {
    const viewManager = this.getViewManager();
    if (!viewManager) {
      return;
    }
    viewManager.utilityEnabled = flag;
    viewManager.tool?.update();
  }

  setOutlines(flag: boolean) {
    const viewManager = this.getViewManager();
    if (!viewManager) {
      return;
    }
    if (viewManager.tool instanceof CustomizeTool) {
      viewManager.tool.setOutline(flag);
    }
    viewManager.tool?.update();
  }

  /**
   * Shows a popup or popup tool for the given interaction
   * @param popup Interaction to be applied on a popup
   * @param edit Flag indicates edition, to open the tool
   */
  showPopup(popup: TPopup, element: TInteractableElement, edit = false, onClose: (() => void) | undefined = undefined) {
    const viewManager = this.getViewManager();
    if (!viewManager) {
      return;
    }
    const isHandHeldDevice = viewManager.isHandHeldDevice;
    switch (popup.type) {
      case PopupType.Contact:
        const contactData: { [key: string]: any } = {};
        contactData['introduction'] = popup.parameters.find(({ key }) => key === 'introduction')?.value ?? '';
        contactData['access'] = popup.parameters.find(({ key }) => key === 'access')?.value ?? '';

        const keyValue = {
          TITLE: 'title',

          PLACEHOLDERFIRST: 'placeholderFirst',
          PLACEHOLDERSECOND: 'placeholderSecond',
          PLACEHOLDERTHIRD: 'placeholderThird',
          PLACEHOLDERFOURTH: 'placeholderFourth',
          SUCCESSMESSAGE: 'successMessage',

          CHECKEDPLACEHOLDERFIRST: 'checkedPlaceholderFirst',
          CHECKEDPLACEHOLDERSECOND: 'checkedPlaceholderSecond',
          CHECKEDPLACEHOLDERTHIRD: 'checkedPlaceholderThird',
          CHECKEDPLACEHOLDERFOURTH: 'checkedPlaceholderFourth',

          ALRAMOPTIONALLCHECK: 'alramOptionAllcheck',
          ALRAMOPTIONOWNXR: 'alramOptionOwnXR',
          ALRAMOPTIONEMAIL: 'alramOptionEmail',
        };

        contactData[keyValue['TITLE']] = popup.parameters.find(({ key }) => key === keyValue['TITLE'])?.value ?? '';
        contactData[keyValue['PLACEHOLDERFIRST']] = popup.parameters.find(({ key }) => key === keyValue['PLACEHOLDERFIRST'])?.value || '';
        contactData[keyValue['PLACEHOLDERSECOND']] = popup.parameters.find(({ key }) => key === keyValue['PLACEHOLDERSECOND'])?.value || '';
        contactData[keyValue['PLACEHOLDERTHIRD']] = popup.parameters.find(({ key }) => key === keyValue['PLACEHOLDERTHIRD'])?.value || '';
        contactData[keyValue['PLACEHOLDERFOURTH']] = popup.parameters.find(({ key }) => key === keyValue['PLACEHOLDERFOURTH'])?.value || '';
        contactData[keyValue['SUCCESSMESSAGE']] = popup.parameters.find(({ key }) => key === keyValue['SUCCESSMESSAGE'])?.value ?? '';

        contactData[keyValue['CHECKEDPLACEHOLDERFIRST']] =
          popup.parameters.find(({ key }) => key === keyValue['CHECKEDPLACEHOLDERFIRST'])?.value ?? '';
        contactData[keyValue['CHECKEDPLACEHOLDERSECOND']] =
          popup.parameters.find(({ key }) => key === keyValue['CHECKEDPLACEHOLDERSECOND'])?.value ?? '';
        contactData[keyValue['CHECKEDPLACEHOLDERTHIRD']] =
          popup.parameters.find(({ key }) => key === keyValue['CHECKEDPLACEHOLDERTHIRD'])?.value ?? '';
        contactData[keyValue['CHECKEDPLACEHOLDERFOURTH']] =
          popup.parameters.find(({ key }) => key === keyValue['CHECKEDPLACEHOLDERFOURTH'])?.value ?? '1';

        contactData[keyValue['ALRAMOPTIONALLCHECK']] =
          popup.parameters.find(({ key }) => key === keyValue['ALRAMOPTIONALLCHECK'])?.value ?? '';
        contactData[keyValue['ALRAMOPTIONOWNXR']] = popup.parameters.find(({ key }) => key === keyValue['ALRAMOPTIONOWNXR'])?.value ?? '';
        contactData[keyValue['ALRAMOPTIONEMAIL']] = popup.parameters.find(({ key }) => key === keyValue['ALRAMOPTIONEMAIL'])?.value ?? '';

        this._ngZone.runOutsideAngular(() => {
          interval(POPUP_TIMEOUT_DURATION)
            .pipe(take(1), untilDestroyed(this))
            .subscribe(() => {
              if (edit) {
                const modal = this.modalRef.open(ContactformToolComponent, { closeOnBackdropClick: false }, { onClose });
                if (modal) {
                  modal.instance.modalRef = this.modalRef;
                  modal.instance.data = contactData;
                  modal.instance.keyValue = keyValue;
                  modal.instance.selectedElement = element;
                }
              } else {
                this.displayUIComponent(
                  isHandHeldDevice ? MobileContactFormToolComponent : ContactformToolComponent,
                  [
                    { name: 'data', value: contactData },
                    { name: 'keyValue', value: keyValue },
                    { name: 'modalRef', value: true },
                    { name: 'isPlayerMode', value: true },
                    { name: 'isEditMode', value: false },
                    { name: 'selectedElement', value: element },
                    { name: 'selectedSceneId', value: this.getActiveModel()!.Id },
                  ],
                  { onClose },
                );
              }
              this._ngZone.run(() => {});
            });
        });

        return;
      case PopupType.Gallery:
        const galleryData: { [key: string]: any } = {};
        galleryData['title'] = popup.parameters.find(({ key }) => key === 'title')?.value ?? '';
        galleryData['auto-slide'] = popup.parameters.find(({ key }) => key === 'auto-slide')?.value ?? '';
        const galleryCount = 10;
        for (let i = 0; i < galleryCount; i++) {
          galleryData['background-colour' + i] = popup.parameters.find(({ key }) => key === 'background-colour' + i)?.value ?? '';
          galleryData['text-colour' + i] = popup.parameters.find(({ key }) => key === 'text-colour' + i)?.value ?? '';
          galleryData['image' + i] = popup.parameters.find(({ key }) => key === 'image' + i)?.value ?? '';
          galleryData['description' + i] = popup.parameters.find(({ key }) => key === 'description' + i)?.value ?? '';
          galleryData['alt-text' + i] = popup.parameters.find(({ key }) => key === 'alt-text' + i)?.value ?? '';
          galleryData['layout' + i] = popup.parameters.find(({ key }) => key === 'layout' + i)?.value ?? '';
          galleryData['hyperlink' + i] = popup.parameters.find(({ key }) => key === 'hyperlink' + i)?.value ?? '';
          galleryData['positions' + i] = popup.parameters.find(({ key }) => key === 'positions' + i)?.value ?? {
            position1: 'title',
            position2: 'content',
            position3: 'description',
            position4: '',
          };
        }

        this._ngZone.runOutsideAngular(() => {
          interval(POPUP_TIMEOUT_DURATION)
            .pipe(take(1), untilDestroyed(this))
            .subscribe(() => {
              if (edit) {
                const modal = this.modalRef.open(GalleryToolComponent, { closeOnBackdropClick: false }, { onClose });
                if (modal) {
                  modal.instance.modalRef = this.modalRef;
                  modal.instance.data = galleryData;
                  modal.instance.imageCount = galleryCount;
                  modal.instance.selectedElement = element;
                }
              } else {
                this.displayUIComponent(
                  isHandHeldDevice ? MobileGalleryToolComponent : GalleryToolComponent,
                  [
                    { name: 'data', value: galleryData },
                    { name: 'isReadonly', value: true },
                    { name: 'imageCount', value: galleryCount },
                    { name: 'modalRef', value: true },
                    { name: 'selectedElement', value: element },
                  ],
                  { onClose },
                );
              }
              this._ngZone.run(() => {});
            });
        });

        return;
      case PopupType.GoogleForm:
        this._ngZone.runOutsideAngular(() => {
          interval(POPUP_TIMEOUT_DURATION)
            .pipe(take(1), untilDestroyed(this))
            .subscribe(() => {
              if (edit) {
                const modal = this.modalRef.open(GoogleFormToolComponent, { closeOnBackdropClick: false }, { onClose });
                if (modal) {
                  modal.instance.link = popup.parameters.find(({ key }) => key === 'link')?.value ?? '' ?? '';
                  modal.instance.title = popup.parameters.find(({ key }) => key === 'title')?.value ?? '' ?? '';
                }
              } else {
                this.displayUIComponent(
                  isHandHeldDevice ? MobileGoogleFormToolComponent : GoogleFormToolComponent,
                  [
                    { name: 'link', value: popup.parameters.find(({ key }) => key === 'link')?.value } ?? '',
                    { name: 'title', value: popup.parameters.find(({ key }) => key === 'title')?.value } ?? '',
                    { name: 'isReadonly', value: true },
                  ],
                  { onClose },
                );
              }
              this._ngZone.run(() => {});
            });
        });

        return;
      case PopupType.GuestBook:
        const guestbookData: { [key: string]: any } = {};
        guestbookData['introduction'] = popup.parameters.find(({ key }) => key === 'introduction')?.value ?? '';
        guestbookData['access'] = popup.parameters.find(({ key }) => key === 'access')?.value ?? 'public';

        this._ngZone.runOutsideAngular(() => {
          interval(POPUP_TIMEOUT_DURATION)
            .pipe(take(1), untilDestroyed(this))
            .subscribe(() => {
              if (edit) {
                const modal = this.modalRef.open(GuestbookToolComponent, { closeOnBackdropClick: false }, { onClose });
                if (modal) {
                  modal.instance.viewMode =
                    guestbookData['introduction'] && guestbookData['access'] ? GuestbookMode.Preview : GuestbookMode.Edit;
                  modal.instance.modalRef = this.modalRef;
                  modal.instance.data = guestbookData;
                  modal.instance.selectedSceneId = this.getActiveModel()!.Id;
                  modal.instance.selectedElement = element;
                  modal.instance.isEditMode = true;
                }
              } else {
                this.displayUIComponent(
                  isHandHeldDevice ? MobileGuestbookToolComponent : GuestbookToolComponent,
                  [
                    { name: 'data', value: guestbookData },
                    { name: 'isEditMode', value: false },
                    { name: 'modalRef', value: true },
                    { name: 'selectedSceneId', value: viewManager.model.Id },
                    { name: 'selectedElement', value: element },
                  ],
                  { onClose },
                );
              }
              this._ngZone.run(() => {});
            });
        });

        return;
      case PopupType.Link:
        this._ngZone.runOutsideAngular(() => {
          interval(POPUP_TIMEOUT_DURATION)
            .pipe(take(1), untilDestroyed(this))
            .subscribe(() => {
              if (edit) {
                const modal = this.modalRef.open(LinkToolComponent, { closeOnBackdropClick: false }, { onClose });
                if (modal) {
                  modal.instance.data = popup.parameters;
                  modal.instance.selectedElement = element;
                  modal.instance.scenePlan = this._sceneService.getSelected()?.Plan;
                }
              } else {
                const link = popup.parameters.find(({ key }) => key === 'link')?.value ?? '';
                const openWithoutPrompt = popup.parameters.find(({ key }) => key === 'openWithoutPrompt')?.value ?? false;
                const openOnCurrentTab = popup.parameters.find(({ key }) => key === 'openOnCurrentTab')?.value ?? false;
                this.displayOpenLink(
                  environment.production ? link : link.replace('https://app.ownxr.com', 'http://localhost:4200'),
                  isHandHeldDevice,
                  onClose,
                  openWithoutPrompt,
                  openOnCurrentTab,
                );
              }
              this._ngZone.run(() => {});
            });
        });

        return;
      case PopupType.Text:
        const textData: { [key: string]: any } = {};
        textData['title'] = '';
        textData['background-colour'] = '#0D74D4';
        textData['text-colour'] = '#454c53';
        popup.parameters.forEach(({ key, value }) => {
          textData[key] = value ?? '';
        });

        this._ngZone.runOutsideAngular(() => {
          interval(POPUP_TIMEOUT_DURATION)
            .pipe(take(1), untilDestroyed(this))
            .subscribe(() => {
              if (edit) {
                const modal = this.modalRef.open(TextToolComponent, { closeOnBackdropClick: false }, { onClose });
                if (modal) {
                  modal.instance.data = textData;
                }
              } else {
                this.displayUIComponent(
                  isHandHeldDevice ? MobileTextToolComponent : TextToolComponent,
                  [
                    { name: 'data', value: textData },
                    { name: 'isReadonly', value: true },
                  ],
                  { onClose },
                );
              }
              this._ngZone.run(() => {});
            });
        });

        return;
      case PopupType.Video:
        const videoData: { [key: string]: any } = {};
        videoData['title'] = popup.parameters.find(({ key }) => key === 'title')?.value ?? '';
        videoData['auto-slide'] = popup.parameters.find(({ key }) => key === 'auto-slide')?.value ?? '';
        const videoCount = 5;
        for (let i = 0; i < videoCount; i++) {
          videoData['background-colour' + i] = popup.parameters.find(({ key }) => key === 'background-colour' + i)?.value ?? '';
          videoData['text-colour' + i] = popup.parameters.find(({ key }) => key === 'text-colour' + i)?.value ?? '';
          videoData['video' + i] = popup.parameters.find(({ key }) => key === 'video' + i)?.value ?? '';
          videoData['description' + i] = popup.parameters.find(({ key }) => key === 'description' + i)?.value ?? '';
          videoData['alt-text' + i] = popup.parameters.find(({ key }) => key === 'alt-text' + i)?.value ?? '';
          videoData['layout' + i] = popup.parameters.find(({ key }) => key === 'layout' + i)?.value ?? '';
          videoData['thumbnail' + i] = popup.parameters.find(({ key }) => key === 'thumbnail' + i)?.value ?? '';
          videoData['positions' + i] = popup.parameters.find(({ key }) => key === 'positions' + i)?.value ?? {
            position1: 'title',
            position2: 'content',
            position3: 'description',
            position4: '',
          };
        }

        this._ngZone.runOutsideAngular(() => {
          interval(POPUP_TIMEOUT_DURATION)
            .pipe(take(1), untilDestroyed(this))
            .subscribe(() => {
              if (edit) {
                const modal = this.modalRef.open(VideoToolComponent, { closeOnBackdropClick: false }, { onClose });
                if (modal) {
                  modal.instance.modalRef = this.modalRef;
                  modal.instance.data = videoData;
                  modal.instance.videoCount = videoCount;
                  modal.instance.selectedElement = element;
                }
              } else {
                this.displayUIComponent(
                  isHandHeldDevice ? MobileVideoToolComponent : VideoToolComponent,
                  [
                    { name: 'data', value: videoData },
                    { name: 'isReadonly', value: true },
                    { name: 'videoCount', value: videoCount },
                    { name: 'modalRef', value: true },
                    { name: 'selectedElement', value: element },
                  ],
                  { onClose },
                );
              }
              this._ngZone.run(() => {});
            });
        });

        return;
      default:
        return;
    }
  }

  /**
   * Pops up a warning modal to confirm changes with overrides
   * @param callback to be executed after accepting the warning
   */
  async showReplacementWarning(callback?: () => void) {
    const message = this.modalRef.open(ConfirmationComponent);
    message.instance.title = this._translateService.instant('shared.confirmation.notice');
    message.instance.body = this._translateService.instant('shared.confirmation.messages.popupContents');
    message.instance.confirmAction = 'shared.confirmation.confirm';
    message.instance.cancelAction = 'shared.confirmation.cancel';
    const result = await message.result;
    result && callback?.();
  }

  async addContactFormEntry(selectedElementId: string, data: any) {
    const selectedSceneId = this.getActiveModel()?.Id;
    if (!selectedSceneId) {
      return;
    }
    const interaction = new XRSceneInteraction(selectedSceneId, selectedElementId, SceneInteractionType.Contact, {
      PlaceholderFirst: data['placeholderFirst'],
      PlaceholderSecond: data['placeholderSecond'],
      PlaceholderThird: data['placeholderThird'],
      PlaceholderFourth: data['placeholderFourth'],
      IsRead: 'false',
      IsDelete: 'false',
      Email: data['placeholderThird'],
    });
    await this._sceneService.postInteraction(interaction);
  }

  async addGuestbookEntry(selectedElementId: string, data: any) {
    const selectedSceneId = this.getActiveModel()?.Id;
    if (!selectedSceneId) {
      return;
    }
    const interaction = new XRSceneInteraction(selectedSceneId, selectedElementId, SceneInteractionType.Comment, {
      Comment: data.Comment,
      Emoji: data.Emoji,
    });
    await this._sceneService.postInteraction(interaction);
  }

  /**
   * Set the coordinates of the mesh in the canvas space
   * @param screenCoords The x, y coordinates of a mesh in the 2D canvas space
   */
  setMeshScreenCoordinates(screenCoords: { x: number; y: number; width: number; height: number } | undefined) {
    this.selectedMeshScreenCoordsSubject.next(screenCoords);
  }

  /**
  Sets the Active model for the application and sets the scene settings based on the model properties
  @param { IAsset | IScene } model Model to set as active
  */
  setActiveModel(model: IAsset | IScene | undefined): void {
    this.activeModelSubject.next(model);
  }

  /**
   * Gets the active model which represents the underlying definitions for a view
   * @returns the active model
   */
  getActiveModel() {
    return this.activeModelSubject.value;
  }

  setTool(tool: ViewTool, payload?: TElement[]) {
    this.getViewManager()?.setTool(tool, payload);
  }

  /**
   * Gets the active transformation mdoe
   * @returns Transformation mode
   */
  getTransformationMode(): TransformationMode {
    return this.transformationModeSubject.value;
  }

  /**
   * Set the transformation mode for the active view, the transformation mode defines the controls that
   * become visible when a mesh is selected
   * @param mode Transformation Mode
   */
  setTransformationMode(targetMode: TransformationMode) {
    if (targetMode !== this.transformationModeSubject.value) {
      const mode =
        targetMode & TransformationMode.Scale &&
        this.getSelectedElements().some(({ type }) => [ElementType.Checkpoint, ElementType.Light].includes(type))
          ? TransformationMode.Translate
          : targetMode;
      this.transformationModeSubject.next(mode);
      const tool = this.getViewManager()?.tool;
      if (tool instanceof SelectTool) {
        tool.setGizmos();
      }
    }
  }

  refreshView() {
    this.getViewManager()?.refresh();
  }

  /**
   * Imports geometry from a file and assigns the mesh to the input asset or node
   * @param file File or relative file path or data string of geometry file supports .obj, .glb or .gltf
   * @param asset Asset or node to assign geometry to
   * @param view View to load geometry in to
   * @param path Root file path
   * @returns Root mesh of import
   */
  public async importGeometryFromFile(file: string | File) {
    const viewManager = this.viewManagerSubject.getValue();
    if (viewManager && viewManager.scene && !viewManager.scene.isDisposed) {
      typeof file === 'string' && (await this.fileService.getFilePath(file));
      const element = {
        id: uuid(),
        name: typeof file === 'string' ? 'New Asset' : file.name.replace(/\.[^/.]+$/, ''),
        type: ElementType.Object,
        parameters: {
          position: OBJECT_POSITION,
          quaternion: OBJECT_QUATERNION,
          scaling: OBJECT_SCALING,
        },
      };
      viewManager.forceElement(element, file);
    }
    return undefined;
  }

  /**
   * Loads view meshes into the active canvas and tracks loading progress
   * @param view
   */
  public clearPanelOverrides() {
    this._panelOverrides.clear();
    this.panelOverridesSubject.next(this._panelOverrides);
  }

  /**
   * Import a mesh into the view and sets the loading progress based of the input id
   * @param file File, file path or data string of geometry to load
   * @param asset used to track the progress
   * @param path Root path for the mesh file dependencies
   * @returns the imported mesh
   */
  private async importMesh(file: string | File, asset: XRAsset, path = 'file://') {
    const viewManager = this.getViewManager();
    if (!viewManager || viewManager.scene.isDisposed) {
      return undefined;
    }
    const meshes: any = '';
    let progress = 1;
    this.updateProgressMap(asset, 1);
    const result = await SceneLoader.ImportMeshAsync(meshes, path, file, viewManager.scene, (event) => {
      if (!viewManager.scene.isDisposed) {
        progress = (event.loaded / event.total) * 100;
        this.updateProgressMap(asset, progress);
      }
    }).catch((error) => {
      console.error(error);
    });
    this.updateProgressMap(asset, 100);
    this._progressMap.delete(asset.Id ?? asset.Name);

    if (viewManager.scene.isDisposed) {
      return undefined;
    }
    return result ?? undefined;
  }

  /**
   * Loads asset textures, (To increase the loading time of an asset the image files are stripped from the original geometry textures
   * on the backend and assigned to the asset model. This function loads the stripped textures and assoicates them with their host material)
   * @param asset Asset containing the textures to load
   */
  private async loadAssetTextures(asset: XRAsset) {
    const viewManager = this.getViewManager();
    if (viewManager && asset.Textures) {
      const promises: Promise<void>[] = [];
      const texturesByMat = groupData(asset.Textures, 'MaterialName');
      texturesByMat.forEach((textures, key) => {
        const mat = viewManager.scene.getMaterialByName(key) as PBRMaterial;
        if (mat) {
          promises.push(
            ...textures.map(
              (t) =>
                new Promise<void>((resolve) => {
                  const texture = new Texture(
                    this.fileService.getCompressedImageUrl(`${environment[this.accountService.region].fileStorageUrl}/${t.Url}`),
                    viewManager.scene,
                    undefined,
                    false,
                    undefined,
                    () => {
                      if (t.Scale) texture.scale(t.Scale);
                      if (t.TexCoord) texture.coordinatesIndex = t.TexCoord;
                      switch (t.TextureType) {
                        case 'Occlusion':
                          mat.ambientTexture = texture;
                          break;
                        case 'Normal':
                          mat.bumpTexture = texture;
                          break;
                        case 'Emissive':
                          mat.emissiveTexture = texture;
                          break;
                        case 'Metallic':
                          mat.metallicTexture = texture;
                          break;
                      }
                      resolve();
                    },
                  );
                }),
            ),
          );
        }
      });
      await Promise.all(promises);
    }
  }

  /**
   * Loads asset for environment element
   * @param asset Asset to be loaded
   * @param image Path or file to be loaded
   * @returns Obtained file, name and URL
   */
  public async loadEnvironmentAsset(asset: XRAsset, image: string | File) {
    let file = '';
    let name = '';
    let url = '';
    if (image) {
      let blob: Blob | undefined;
      if (typeof image === 'string') {
        url = `${environment[this.accountService.region].fileStorageUrl}/${image}`;
        const container = image.split('/').shift();
        if (container) {
          this.updateProgressMap(asset, 1);
          blob = await this.fileService.downloadFile(url, (_error, progress) => {
            this.updateProgressMap(asset, progress);
          });
          if (blob) {
            file = (await this.fileService.blobToBase64(blob)).Value as string;
          }
          this.updateProgressMap(asset, 100);
          name = image;
        }
      } else {
        url = (await this.fileService.blobToBase64(image)).Value as string;
        name = image.name;
      }
    }
    return { name, url };
  }

  /**
   * Sets the 360 image, creates an environment element with the image URL
   * @param asset Id to track loading progress
   */
  public async setEnvironmentElement(assetVersion: XRAssetVersion) {
    const [element, ...rest] = this.getViewElements(ElementType.Environment) as TEnvironmentElement[];
    if (element) {
      this.updateViewElement({
        ...element,
        parameters: { ...element.parameters, ...assetVersion.Parameters, assetVersionId: assetVersion.Id },
      });
    } else {
      assetVersion &&
        this.addViewElement({
          id: uuid(),
          type: ElementType.Environment,
          name: assetVersion.Name,
          parameters: {
            type: EnvironmentType.Combined,
            assetVersionId: assetVersion.Id,
            intensity: (assetVersion.Parameters as TEnvironmentParameters)?.intensity ?? ENVIRONMENT_INTENSITY,
            level: (assetVersion.Parameters as TEnvironmentParameters)?.level ?? ENVIRONMENT_LEVEL,
            tint: (assetVersion.Parameters as TEnvironmentParameters)?.tint ?? ENVIRONMENT_TINT,
            rotation: (assetVersion.Parameters as TEnvironmentParameters)?.rotation ?? ENVIRONMENT_ROTATION,
          },
        });
    }
    rest.length && this.removeViewElements(rest);
  }

  public showNotification(message: string, parameters = {}, duration = 3000, classList = '') {
    const modal = this.modalRef.open(InformationComponent);
    if (classList) {
      modal.instance.classList = classList;
    }
    modal.instance.message = this._translateService.instant(message, parameters);

    this._ngZone.runOutsideAngular(() => {
      interval(duration)
        .pipe(take(1), untilDestroyed(this))
        .subscribe(() => {
          if (modal?.instance) {
            modal.instance.close();
            this._ngZone.run(() => {});
          }
        });
    });
  }

  /**
   * Loads mesh of an element with given asset resources
   * @param asset Id to track loading progress
   * @returns Landscape or stage mesh
   */
  public async loadObjectAsset(asset: XRAsset) {
    try {
      if (!(asset && asset.Id)) {
        return;
      }
      const viewManager = this.getViewManager();
      if (!viewManager) {
        return;
      }
      switch (this._assetStatusMap.get(asset.Id)) {
        case AssetStatus.Pending:
          return await new Promise<ISceneLoaderAsyncResult | undefined>((resolve) => {
            this.assetStatusMap$.subscribe((map) => {
              map.get(asset.Id!) === AssetStatus.Ready && resolve(this.loadObjectAsset(asset));
            });
          });
        case AssetStatus.Ready:
          return this.getLoadedAssetMesh(asset.Id);
        default:
          this.updateAssetStatusMap(asset, AssetStatus.Pending);
          let meshSubject = this.getLoadedAssetMesh(asset.Id);
          if (!meshSubject) {
            let url = asset.uri || asset.Url;
            if (url && url.endsWith('oxr')) {
              const fullPath = await this.fileService.getFilePath(url, '');
              this.updateProgressMap(asset, 1);
              await this.fileService.downloadFile(fullPath, (_error, progress) => {
                this.updateProgressMap(asset, progress);
              });
              this.updateProgressMap(asset, 100);
              // await this.assetService.loadAssetGeometry(asset.Id);
              url = asset.uri;
            }
            if (url && asset) {
              meshSubject = await this.importMesh(url, asset, environment[this.accountService.region].fileStorageUrl + '/');

              this.setLoadedAssetMesh(asset.Id, meshSubject);
              await this.loadAssetTextures(asset);
              this.updateAssetStatusMap(asset, AssetStatus.Ready);
            } else {
              meshSubject = {} as ISceneLoaderAsyncResult;
              this.setLoadedAssetMesh(asset.Id, meshSubject);
            }
          }
          return meshSubject;
      }
    } catch (error) {
      console.error(error);
      return;
    }
  }

  public handleObjectAsset(version: XRAssetVersion, elementType: ElementType) {
    const id = uuid();
    switch (elementType) {
      case ElementType.Object:
        this.setTool(ViewTool.Insert, [
          {
            id,
            name: version.Name,
            type: ElementType.Object,
            parameters: {
              assetVersionId: version.Id,
              position: (version.Parameters as TObjectParameters)?.position ?? OBJECT_POSITION,
              quaternion: (version.Parameters as TObjectParameters)?.quaternion ?? OBJECT_QUATERNION,
              scaling: (version.Parameters as TObjectParameters)?.scaling ?? OBJECT_SCALING,
              popups: [],
              effects: [],
              collisions: false,
              shadows: true,
            },
          },
        ]);
        break;
      case ElementType.Landscape:
      case ElementType.Stage:
        const [element, ...rest] = this.getViewElements(elementType) as (TLandscapeElement | TStageElement)[];
        if (element && element?.parameters.assetVersionId !== version.Id) {
          this.removeViewElements([element]);
        }
        this.addViewElement({
          id,
          type: elementType,
          name: version.Name,
          parameters: {
            assetVersionId: version.Id,
            ...(elementType === ElementType.Stage
              ? {
                  position: STAGE_POSITION,
                  rotation: STAGE_ROTATION,
                  scaling: STAGE_SCALING,
                }
              : {
                  position: LANDSCAPE_POSITION,
                  rotation: LANDSCAPE_ROTATION,
                  scaling: LANDSCAPE_SCALING,
                }),
          },
        });
        rest.length && this.removeViewElements(rest);
        break;
      default:
        break;
    }
  }

  public getEditorIntensity() {
    return this.getViewManager()?.scene?.environmentIntensity;
  }

  public setEditorIntensity(intensity: number) {
    const viewManager = this.getViewManager();
    if (isNaN(intensity) || !viewManager?.scene || !viewManager.scene?.environmentTexture) {
      return;
    }
    viewManager.scene.environmentIntensity = intensity;
  }

  /**
   * Returns the active checkpoint renderable
   * @returns Active checkpoint renderable
   */
  public getActiveCheckpoint() {
    return this.getViewManager()?.checkpoints.find(
      (checkpoint) => checkpoint.element.parameters.type === CheckpointType.Start,
    ) as CheckpointRenderable;
  }

  jump() {
    this.getViewManager()?.playerModeControls.jump();
  }

  walk(x: number, y: number) {
    this.getViewManager()?.playerModeControls.walk(x, y);
  }

  lookStart(event: JoystickEvent) {
    this.getViewManager()?.playerModeControls.lookStart(event);
  }

  look(event: JoystickEvent) {
    this.getViewManager()?.playerModeControls.look(event);
  }

  lookEnd() {
    this.getViewManager()?.playerModeControls.lookEnd();
  }

  getRenderableBounds(element: TElement) {
    const viewManager = this.viewManagerSubject.getValue();
    if (!viewManager) {
      return;
    }
    const mesh = this.getViewRenderable(element)?.helper;
    if (!mesh) {
      return;
    }
    return viewManager.getMeshBounds(mesh).asArray();
  }

  /**
   * Opens a component and displays it in a modal container
   * @param component The component to render
   * @param inputs Custom input overrides for the component
   * @returns true if the result of the component is successful or yes, false otherwise
   */
  async displayUIComponent(component: Type<any>, inputs: { name: string; value: any }[], options: { [key: string]: any } = {}) {
    const form = this.modalRef.open(component, undefined, options);
    if (form) {
      const instance: any = form.instance;
      for (const input of inputs) {
        instance[input.name] = input.value;
        if (input.name === 'modalRef') {
          instance[input.name] = this.modalRef;
        }
      }
      return new Promise<boolean>((resolve) => {
        form?.result
          .then((result) => {
            if (component.name === 'ConfirmationComponent') {
              resolve(result);
            } else {
              resolve(true);
            }
          })
          .catch(() => {
            resolve(false);
          });
      });
    }
    return false;
  }

  /**
   * Opens a open link modal
   * @param link Link to display
   */
  async displayOpenLink(
    link: string,
    isHandHeldDevice = false,
    onClose: (() => void) | undefined = undefined,
    openWithoutPrompt = false,
    openOnCurrentTab = false,
  ) {
    // Open directly
    if (openWithoutPrompt) {
      if (!link.startsWith('http://') && !link.startsWith('https://')) {
        link = 'https://' + link;
      }
      this._urlService.windowOpenWithPlatform(link, !openOnCurrentTab);
      return Promise.resolve(true);
    }
    let modal;

    if (isHandHeldDevice) {
      modal = this.modalRef.open(MobileConfirmationComponent);
      modal.instance.body = this._translateService.instant('shared.confirmation.goToURL', {
        url: link,
      });
    } else {
      modal = this.modalRef.open(ConfirmationComponent, undefined, { onClose });
      modal.instance.title = this._translateService.instant('guideModal.title');
      modal.instance.body = this._translateService.instant('shared.confirmation.goToURL', {
        url: link.length > 50 ? link.slice(0, 49) + '...' : link,
      });
    }
    modal.instance.confirmAction = this._translateService.instant('shared.actions.open');
    modal.instance.cancelAction = this._translateService.instant('shared.actions.cancel');

    return new Promise<boolean>((resolve) => {
      modal?.result
        .then((openLink) => {
          if (openLink) {
            if (!link.startsWith('http://') && !link.startsWith('https://')) {
              link = 'https://' + link;
            }
            this.windowOpenWithPlatform(link, !openOnCurrentTab);
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  windowOpenWithPlatform(url: string, newTab = true) {
    this._urlService.windowOpenWithPlatform(url, newTab);
  }

  translate(key: string, params?: any) {
    return this._translateService.instant(key, params);
  }

  async publishNewAsset() {
    // const modal = this.modalRef.open(ProgressComponent);
    const viewManager = this.getViewManager();
    // if (modal && viewManager) {
    if (viewManager) {
      // modal.instance.message = this._translateService.instant(savingMessage);
      const asset = viewManager.model as XRAsset;
      if (viewManager.model instanceof XRAsset) {
        if (asset.Type !== AssetType.Environment) {
          ViewManagerUtils.ExportPipeline(viewManager);
          const { url: fileURL } = await this.fileService.uploadScene(
            viewManager,
            viewManager.model.Id + '.glb',
            (_error, progress) => {
              // modal.instance.progress = +progress;
            },
            {
              shouldExportNode: (node: Node) => node.isEnabled() && node.metadata?.helperOf instanceof ObjectRenderable,
              metadataSelector: (data: any) => {
                if (!data?.helperOf) {
                  return data;
                }
                const metadata = Object.fromEntries(Object.entries(data).filter(([k, _v]) => k !== 'helperOf'));
                return !!Object.keys(metadata).length ? metadata : undefined;
              },
            },
          );
          viewManager.model.Url = fileURL;
        } else if (asset.Type === AssetType.Environment && asset.file) {
          const { url } = await this.fileService.uploadFile(
            asset.file,
            FileStorageType.Assets,
            (_error, progress) => {
              // modal.instance.progress = +progress;
            },
            undefined,
            false,
            undefined,
            undefined,
            Access.Private,
          );
          viewManager.model.Url = url;
        }
        if (viewManager.model.Id === NEW_ASSET_ID) {
          viewManager.model.Id = uuid();
        }
        await this.apiv2.postNewAsset(viewManager.model as XRAsset, this.getViewElements() as (TObjectElement | TEnvironmentElement)[]);
      }
      // this.modalRef.close(1);
    }
  }

  async saveAsset(asset: XRAsset) {
    const v = this.selectedAssetVersionSubject.getValue();
    if (!v) {
      return;
    }
    const parameters = {} as IAssetVersion['Parameters'];
    const obj = this.getViewElements(ElementType.Object)[0] as TObjectElement;
    const env = this.getViewElements(ElementType.Environment)[0] as TEnvironmentElement;
    switch (asset.Type) {
      case AssetType.Object:
        if (!obj || !env) {
          return;
        }
        parameters.position = obj.parameters.position;
        parameters.quaternion = obj.parameters.quaternion;
        parameters.scaling = obj.parameters.scaling;
        if (obj.parameters.customization) {
          parameters.customization = obj.parameters.customization;
        }
        parameters.environment = {
          ...(env.parameters.intensity !== undefined ? { intensity: env.parameters.intensity } : {}),
          ...(env.parameters.level !== undefined ? { level: env.parameters.level } : {}),
          ...(env.parameters.tint !== undefined ? { tint: env.parameters.tint } : {}),
          ...(env.parameters.rotation !== undefined ? { rotation: env.parameters.rotation } : {}),
        };
        break;
      case AssetType.Environment:
        if (!env) {
          return;
        }
        if (env.parameters.intensity !== undefined) {
          parameters.intensity = env.parameters.intensity;
        }
        if (env.parameters.level !== undefined) {
          parameters.level = env.parameters.level;
        }
        if (env.parameters.tint !== undefined) {
          parameters.tint = env.parameters.tint;
        }
        if (env.parameters.rotation !== undefined) {
          parameters.rotation = env.parameters.rotation;
        }
        break;
      default:
        return;
    }
    await this.apiv2.putAsset(asset);
  }

  setSelectedAssetVersion(assetVersion: XRAssetVersion) {
    if (assetVersion.Type === AssetType.Object) {
      const environment = this.getViewElements(ElementType.Environment)[0];
      const obj = this.getViewElements(ElementType.Object)[0];
      environment &&
        this.updateViewElements([
          ...(environment
            ? [
                {
                  ...environment,
                  parameters: {
                    ...environment.parameters,
                    ...((assetVersion.Parameters as TObjectParameters).environment as TEnvironmentElement['parameters']),
                  },
                },
              ]
            : []),
          ...(obj
            ? [
                {
                  ...obj,
                  parameters: { ...obj.parameters, ...(assetVersion.Parameters as TObjectParameters) },
                },
              ]
            : []),
        ]);
    } else if (assetVersion.Type === AssetType.Environment) {
      const environment = this.getViewElements(ElementType.Environment)[0];
      environment &&
        this.updateViewElement({
          ...environment,
          parameters: {
            ...environment.parameters,
            ...(assetVersion.Parameters as TEnvironmentElement['parameters']),
          },
        });
    }
    this.selectedAssetVersionSubject.next(assetVersion);
  }

  updateAssetVersion(assetVersion: IAssetVersion) {
    const v = this.apiv2.assetVersionMap.get(assetVersion.Id);
    if (!v) {
      return;
    }
    v.Name = assetVersion.Name;
    v.Thumbnail = assetVersion.Thumbnail;
    v.Parameters = deepCopy(assetVersion.Parameters);
    if (assetVersion.Id !== NEW_ASSET_VERSION_ID) {
      const originalIndex = v.asset.Versions.findIndex(({ Id }) => Id === v.Id);
      const original = v.asset.Versions[originalIndex];
      const { environment: originalEnvironment, ...rest } = JSON.parse(original.Parameters);
      if (original) {
        let parameters: IAssetVersion['Parameters'] = {};
        switch (v.asset.Type) {
          case AssetType.Environment:
            const { intensity, rotation, level, tint } = v.Parameters as TEnvironmentElement['parameters'];
            parameters = { level, tint };
            break;
          case AssetType.Object:
            const { environment, position, quaternion, scaling, customization } = v.Parameters as TObjectParameters;
            parameters = { position, quaternion, scaling, ...(customization ? { customization } : {}) };
            break;
          default:
            return;
        }
        if (!!ViewManagerUtils.PropsDiff(parameters, rest).length) {
          v.isDirty = true;
          v.V = original.V.split('.')
            .map((x, i) => (i ? Number(x) + 1 : x))
            .join('.');
        } else {
          v.isDirty = false;
          v.V = original.V;
        }
        this.apiv2.assetVersionMap.set(v.Id, v);
        this.selectedAssetVersionSubject.next(v);
      }
    }
    // Update maps?
  }

  /**
   * Commits all data in the cache to the backend. Tracks progress for asset uploads
   * @returns true on successful save, false otherwise
   */
  async saveScene(savingMessage = 'shared.information.savingModel') {
    const modal = this.modalRef.open(ProgressComponent);
    const viewManager = this.getViewManager();
    if (modal && viewManager) {
      modal.instance.message = this._translateService.instant(savingMessage);
      const asset = viewManager.model as XRAsset;
      if (viewManager.model instanceof XRScene) {
        this._sceneService.postSceneElements(viewManager.model!.Id, this.getViewElements()).subscribe();
        this._sceneService.createNewVersion(viewManager.model!.Id).subscribe();
      }
      this.modalRef.close(1);
    }
  }

  /**
   * Copies the current selection to the clipboard
   */
  async copySelection() {
    this.clipboard.clear();
    const elements = this.getSelectedElements().filter(({ type }) => type !== ElementType.Checkpoint);

    if (elements?.length) {
      this.clipboard.elements.length = 0;
      this.clipboard.elements.push(...elements);
    }
  }

  /**
   * Pastes the clipboard in the view
   */
  async pasteSelection() {
    if (!this.clipboard.isInUse && this.clipboard.elements.length) {
      if (
        this.clipboard.elements.some(({ type }) => type === ElementType.Light) &&
        this.getViewElements(ElementType.Light).length + this.clipboard.elements.filter(({ type }) => type === ElementType.Light).length >
          MAX_LIGHTS
      ) {
        this.showNotification('oxr.creatingSpace.lightsOption.noMoreLights', { number: MAX_LIGHTS });
        return;
      }
      this.getViewManager()?.setTool(
        ViewTool.Insert,
        this.clipboard.elements.map(
          (element) =>
            ({
              ...element,
              id: uuid(),
              parameters: {
                ...element.parameters,
                ...(INTERACTABLE_TYPES.includes(element.type)
                  ? {
                      popups: (element as TInteractableElement).parameters.popups?.map((popup) => ({
                        ...popup,
                        id: uuid(),
                      })),
                      effects: (element as TInteractableElement).parameters.effects?.map((effect) => ({
                        ...effect,
                        id: uuid(),
                      })),
                    }
                  : element.parameters),
              },
            }) as TElement,
        ),
      );
    }
  }

  /**
   * Un does the last action
   */
  undo() {
    const viewManager = this.getViewManager();
    if (!viewManager) {
      return;
    }
    const undoTransaction = viewManager.undoStack.pop();
    if (undoTransaction) {
      const undo = undoTransaction.rollback(viewManager);
      if (undo) {
        viewManager.onRollback?.notifyObservers(undo as ElementsTransaction);
        viewManager.redoStack.push(undo);
      }
    }
    viewManager.tool?.update();
  }

  /**
   * reversed the last undo action
   */
  redo() {
    const viewManager = this.getViewManager();
    if (!viewManager) {
      return;
    }
    const redoTransaction = viewManager.redoStack.pop();
    if (redoTransaction) {
      const redo = redoTransaction.rollback(viewManager);
      if (redo) {
        viewManager.onRollback?.notifyObservers(redo as ElementsTransaction);
        viewManager.undoStack.push(redo);
      }
    }
  }

  /**
   * removes the selected item from the scene
   */
  async deleteSelection() {
    this.getViewManager()?.tool?.onDelete();
  }

  /**
   * Set the state of the hover to inform the user of a clickable object
   * @param active hover state
   */
  setHoverActive(active: boolean) {
    this.hoverActiveSubject.next(active);
  }

  /**
   * Gets an behaviour subject of a loaded asset mesh
   * @param name Name of asset/asset id
   * @returns A subscribable behaviour subject of the loaded/loading asset mesh result
   */
  getLoadedAssetMesh(name: string) {
    return this._assetMeshMap.get(name);
  }

  /**
   * Maps a loading mesh behaviour subject to the input name/asset id
   * @param name Name of asset/asset id
   * @param mesh Loading mesh Behaviour subject
   */
  setLoadedAssetMesh(name: string, mesh: ISceneLoaderAsyncResult | undefined) {
    return this._assetMeshMap.set(name, mesh);
  }

  clearAssetMeshMap() {
    this._assetMeshMap.clear();
  }

  getAssetStatus(asset: XRAsset | string) {
    return this._assetStatusMap.get(typeof asset === 'string' ? asset : asset.Id ?? asset.Name);
  }

  updateAssetStatusMap(asset: XRAsset | string, status: AssetStatus) {
    this._assetStatusMap.set(typeof asset === 'string' ? asset : asset.Id ?? asset.Name, status);
    this.assetStatusSubject.next(this._assetStatusMap);
  }

  clearAssetStatusMap() {
    this._assetStatusMap.clear();
    this.assetStatusSubject.next(this._assetStatusMap);
  }

  updateProgressMap(asset: XRAsset | string, progress: number) {
    const key = (typeof asset === 'string' ? asset : asset.Id || asset.Name) || '';
    if (key) {
      if (progress === 100) {
        this._progressMap.has(key) && this._progressMap.delete(key);
      } else {
        this._progressMap.set(key, progress);
      }
      this.progressMapSubject.next(this._progressMap);
    }
  }

  updateThumbnailMap(fileId: string, thumbnail: string) {
    this._thumbnailMap.set(fileId, thumbnail);
    this.thumbnailMapSubject.next(this._thumbnailMap);
  }

  async getFileThumbnail(fileId: string): Promise<string> {
    const thumbnail = this._thumbnailMap.get(fileId);
    if (thumbnail) {
      return thumbnail;
    }
    return (await this.fileService.getByIds([fileId]))?.[0]?.Thumbnail;
  }

  setPanelOverride(key: PanelMask, value: boolean | undefined) {
    if (value === undefined) {
      return;
    }
    this._panelOverrides.set(key, value);
    this.panelOverridesSubject.next(this._panelOverrides);
  }
}
