export function getQueryParameters(url: string, querySeparator = '?') {
  const params = url.split(querySeparator);

  if (params.length > 1) {
    const queryParams = params[1];
    const result = new Map<string, string[] | string>();
    for (const p of queryParams.split('&')) {
      const keyValue = p.split('=');
      const value = result.get(keyValue[0]);
      if (value) {
        if (Array.isArray(value)) {
          value.push(keyValue[1]);
        } else {
          const arry = [keyValue[1]];
          result.set(keyValue[0], arry);
        }
      } else {
        result.set(keyValue[0], keyValue[1]);
      }
    }
    return result;
  }

  return undefined;
}

export function appendQueryParam(url: string, key: string, value: string) {
  if (url && url.includes('?')) {
    return url + '&' + key + '=' + value;
  } else {
    return url + '?' + key + '=' + value;
  }
}

export function prependHTTP(url: string) {
  let newURL = decodeURIComponent(url);
  if (!/^(?:f|ht)tps?\:\/\//.test(newURL)) {
    newURL = 'https://' + newURL;
  }
  return newURL;
}
