import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ApplicationService } from '@/view/src/app/app.service';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { DataCacheService } from '@/data/src/lib/services/data-cache.service';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { ConfirmationComponent } from '@/ui/src/lib/modal/confirmation/confirmation.component';
import { XRScene, XRSceneInteraction, InteractionEmotion } from '@/data/src/lib/models/data/scene';
import { SceneInteractionType } from '@/data/src/lib/enums/scene-interaction';
import { Account } from '@/data/src/lib/models/data/account';
import { PopupType, TInteractableElement } from '@/data/src/lib/view-manager';
import { Access } from '@/data/src/lib/enums/access';

export enum GuestbookMode {
  Comment = 'comment',
  Edit = 'edit',
  Preview = 'preview',
}

@Component({
  selector: 'ui-guestbook-tool',
  templateUrl: './guestbook-tool.component.html',
  styleUrls: ['./guestbook-tool.component.scss'],
  providers: [ModalService],
})
export class GuestbookToolComponent implements OnInit {
  modalRef: ModalService;
  isEditMode = false;
  innerModal = false;
  isCommunity = false;

  viewMode: GuestbookMode = GuestbookMode.Preview;
  guestbookMode = GuestbookMode;
  access = Access;

  selectedSceneId = '';
  selectedScene: XRScene | undefined;
  activeAccount: Account | undefined = this._appService.activeAccount;
  interactions: XRSceneInteraction[] = [];
  selectedElement: TInteractableElement;

  interactionEmotion = InteractionEmotion;
  emojiCounts: { [key: string]: number } = Object.fromEntries(Object.values(InteractionEmotion).map((emoji) => [emoji, 0]));

  newComment: string = '';

  selectedEmoji: InteractionEmotion = InteractionEmotion.None;

  editForm = new UntypedFormGroup({
    introduction: new UntypedFormControl(''),
  });

  data: { [key: string]: string } = {};

  get isEnterpriseOrAdmin() {
    return this._appService.isEnterpriseOrAdmin;
  }

  get emojis() {
    return Object.values(InteractionEmotion).filter((emoji) => emoji !== InteractionEmotion.None);
  }

  get accessChecked() {
    return this.data.access === (Access.Public as unknown as string);
  }

  constructor(
    private _modalService: ModalService,
    private _appService: ApplicationService,
    private _sceneService: SceneService,
    private _translateService: TranslateService,
    private _router: Router,
    public dataCache: DataCacheService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (!!this.data) {
      this.editForm.setValue({ introduction: this.data['introduction'] || '' });
    }

    if (this.selectedElement && this.viewMode === GuestbookMode.Preview) {
      await this.updateInteractions();
    }

    if (this.selectedSceneId && !this.innerModal) {
      this.selectedScene = await this._sceneService.getById(this.selectedSceneId);
    }

    if (this._router.url.includes('view')) {
      this.isCommunity = true;
    }
  }

  closeModal() {
    if (this.innerModal) {
      this.modalRef.close(false, this['modalContainerRef']);
    } else {
      this._modalService.close(false, this['modalContainerRef']);
    }
  }

  onSwitchChanged(checked: boolean) {
    this.data.access = (checked ? Access.Public : Access.Private) as unknown as string;
  }

  openSettingModal() {
    const settingModal = this._modalService.open(
      GuestbookToolComponent,
      { closeOnBackdropClick: false },
      {
        data: this.data,
        selectedElement: this.selectedElement,
        selectedScene: this.selectedScene,
        innerModal: true,
        viewMode: 'edit',
        selectedSceneId: this.selectedSceneId,
        modalRef: this._modalService,
      },
    );
  }

  async saveSettings() {
    this.data['introduction'] = this.editForm.value.introduction;
    const oldData = this.selectedElement?.parameters?.popups?.length ? this.selectedElement.parameters.popups[0] : {};
    this.selectedElement &&
      this._appService.updateViewElement(
        {
          ...this.selectedElement,
          parameters: {
            ...this.selectedElement.parameters,
            popups: [
              {
                ...oldData,
                type: PopupType.GuestBook,
                parameters: [...Object.entries(this.data).map(([key, value]) => ({ key, value }))],
              },
            ],
          },
        } as TInteractableElement,
        true,
      );
    if (this.innerModal) {
      this.modalRef.close(true, this['modalContainerRef']);
    } else {
      this.viewMode = GuestbookMode.Preview;
      await this.updateInteractions();
    }
  }

  openCommentModal() {
    const commentModal = this._modalService.open(GuestbookToolComponent, { closeOnBackdropClick: false });
    if (commentModal) {
      commentModal.instance.data = this.data;
      commentModal.instance.selectedElement = this.selectedElement;
      commentModal.instance.selectedScene = this.selectedScene;
      commentModal.instance.selectedSceneId = this.selectedSceneId;
      commentModal.instance.innerModal = true;
      commentModal.instance.viewMode = GuestbookMode.Comment;
      commentModal.instance.modalRef = this._modalService;
      commentModal.result.then((result) => {
        if (result) {
          this.updateInteractions();
        }
      });
    }
  }

  async updateInteractions() {
    const res = await this._sceneService.getInteractions(this.selectedSceneId, SceneInteractionType.Comment, this.selectedElement.id);
    this.interactions = res.filter((e) => !e.Username.includes('Removed@')).sort((a, b) => (a.Date < b.Date ? -1 : 1));
    if (this.data.access === (Access.Private as unknown as string)) {
      if (this.activeAccount) {
        if (!this.isEnterpriseOrAdmin) {
          this.countEmoji();
          return;
        }
        if (this.activeAccount.Id !== this.selectedScene?.CreatorUser?.Id) {
          this.interactions.length = 0;
          this.interactions.push(...this.interactions.filter((x) => x.Username === this.activeAccount?.Username));
        }
      } else {
        this.interactions.length = 0;
      }
    }
    this.countEmoji();
  }

  countEmoji() {
    this.interactions.length &&
      this.interactions.forEach((interaction) => {
        this.emojiCounts[interaction.Interaction.Emoji] += 1;
      });
  }

  async addComment() {
    if (this.newComment) {
      this._appService.addGuestbookEntry(this.selectedElement.id, {
        Comment: await this._sceneService.CheckCommentOfInteraction(this.newComment),
        Emoji: this.selectedEmoji,
      });
    }
  }

  async leaveComment() {
    await this.addComment();
    this.modalRef.close(true, this['modalContainerRef']);
    this.newComment = '';
    this.selectedEmoji = InteractionEmotion.None;
  }

  onSelectionChanged(emoji: InteractionEmotion) {
    this.selectedEmoji = emoji;
  }

  async deleteComment(interactionId: string) {
    const message = this._modalService.open(ConfirmationComponent);
    message.instance.title = this._translateService.instant('shared.confirmation.notice');
    message.instance.body = this._translateService.instant('shared.confirmation.deletionConfirmationMessage');
    message.instance.confirmAction = 'shared.confirmation.confirm';
    message.instance.cancelAction = 'shared.confirmation.cancel';
    if (await message.result) {
      await this._sceneService.deleteInteraction([interactionId]);
      await this.updateInteractions();
    }
  }
}
