import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { XRNotification } from 'projects/data/src/lib/models/data/notification';
import { map, Subject } from 'rxjs';
import { AccountPanelService } from '../../account-panel.service';

@Component({
  selector: 'ui-account-panel-notifications',
  templateUrl: './account-panel-notifications.component.html',
  styleUrls: ['./account-panel-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountPanelNotificationsComponent implements OnDestroy {
  notifications$ = this._accountPanelService.notifications$.pipe(
    map((notifications: XRNotification[]) => {
      return notifications.sort((a, b) => {
        if (!a.HasBeenViewed && b.HasBeenViewed) {
          return -1;
        } else if (a.HasBeenViewed && !b.HasBeenViewed) {
          return 1;
        } else {
          return 0;
        }
      });
    }),
  );

  private _destroy$ = new Subject<void>();

  constructor(private _accountPanelService: AccountPanelService, private _cd: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  goToDetails(notification: XRNotification): void {
    this._accountPanelService.selectNotification(notification);
    this._accountPanelService.openDetails('notification-details');
  }

  onDelete(notification: XRNotification): void {
    this._accountPanelService.deleteNotification(notification);
  }

  async markViewed(notification: XRNotification) {
    if (!notification.HasBeenViewed) {
      await this._accountPanelService.markNotificationViewed(notification);
      this._cd.detectChanges();
    }
  }
}
