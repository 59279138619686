import { v4 as uuid } from 'uuid';
import { ActionManager, Mesh, MeshBuilder, Nullable, Quaternion, Vector3 } from '../../babylon';
import { InteractableRenderable, ViewManager } from '..';
import { TElement, TMedia, TScreenElement } from '../types';
import { OBJECT_POSITION, OBJECT_QUATERNION, SCREEN_SCALING, Z_OFFSET } from '../constants';
import { Interaction } from '../auxiliaries/interaction';
import { Media } from '../auxiliaries/media';
import { VideoController } from '../auxiliaries/video-controller';
import { ViewManagerUtils } from '../../utils/view-manager-utils';
import { deepCopy } from '../../utils/data';

const LOAD_BUTTON_SIZE = 0.6;

export class ScreenRenderable extends InteractableRenderable {
  public elementId: string;
  public helper: Mesh;

  private overInteraction: Interaction;
  private outInteraction: Interaction;

  public media: Nullable<Media>;
  private _mediaDescr?: TMedia;

  public _loadButtonFront: Nullable<Mesh>;
  public _loadButtonBack: Nullable<Mesh>;
  public _controllerFront: Nullable<VideoController>;
  public _controllerBack: Nullable<VideoController>;

  constructor(
    element: TScreenElement,
    public viewManager: ViewManager,
  ) {
    super(element, viewManager);
    this.update = this.update.bind(this);
    this.createHelper = this.createHelper.bind(this);
    this.updateElement = this.updateElement.bind(this);
    this.createHelper();
    this.update();
  }

  get element() {
    return this._element as TScreenElement;
  }

  set element(payload) {
    this._element = deepCopy(payload) as TScreenElement;
  }

  createHelper() {
    this.helper = MeshBuilder.CreatePlane(`${this.element.id}`, { size: 1, sideOrientation: Mesh.BACKSIDE }, this.viewManager.scene);
    this.helper.material = this.viewManager.screenMaterial;
    this.helper.receiveShadows = false;
    ViewManagerUtils.AttachMetadata(this.helper, { helperOf: this });

    this.overInteraction = new Interaction(this.viewManager.scene, this.helper, ActionManager.OnPointerOverTrigger, undefined, true);
    this.outInteraction = new Interaction(this.viewManager.scene, this.helper, ActionManager.OnPointerOutTrigger, undefined, true);
  }

  update(element?: TElement) {
    if (element) {
      this.element = deepCopy(element as TScreenElement);
    }

    if (!this.helper) {
      return;
    }

    this._mediaDescr = deepCopy(this.element.parameters.media);
    if (this._mediaDescr) {
      this.helper.visibility = 0.001;
      if (this.media) {
        this.media.update(this._mediaDescr, true);
      } else {
        this.media = new Media(this, this._mediaDescr);
      }
    } else {
      this.helper.visibility = 1;
      this._controllerFront?.dispose();
      this._controllerFront = null;
      this._controllerBack?.dispose();
      this._controllerBack = null;
      this.media?.dispose();
      this.media = null;
    }
    this.viewManager.lights.forEach((light) => {
      this.helper && light.shadowGenerator?.removeShadowCaster(this.helper);
    });

    this.helper.position.copyFromFloats(...(this.element.parameters.position ?? OBJECT_POSITION));
    if (this.helper.rotationQuaternion) {
      this.helper.rotationQuaternion.copyFromFloats(...(this.element.parameters.quaternion ?? OBJECT_QUATERNION));
    } else {
      this.helper.rotationQuaternion = Quaternion.FromArray(this.element.parameters.quaternion ?? OBJECT_POSITION);
    }
    this.helper.scaling.copyFromFloats(...(this.element.parameters.scaling ?? SCREEN_SCALING), 1);

    super.update();
  }

  updateElement() {
    this.viewManager.scene.onBeforeRenderObservable.addOnce(() => {
      this.viewManager.appService.updateViewElement({
        ...this.element,
        parameters: {
          ...this.element.parameters,
          position: [...this.helper.computeWorldMatrix(true).asArray().slice(12, 15)] as TScreenElement['parameters']['position'],
          quaternion: [...this.helper.rotationQuaternion!.asArray()] as TScreenElement['parameters']['quaternion'],
          scaling: this.helper.scaling!.asArray().slice(0, 2) as TScreenElement['parameters']['scaling'],
        },
      });
    });
  }

  resizeControllers() {
    if (!this.media) {
      return;
    }
    this.media.hostFront && this._controllerFront?.resize(this.media.hostFront);
    this.media.hostBack && this._controllerBack?.resize(this.media.hostBack);
  }

  showControls() {
    this.hideLoadButton();
    this.controllerFront?.setEnabled(true);
    this._controllerBack?.setEnabled(true);
  }

  hideControls() {
    this.controllerFront?.setEnabled(false);
    this._controllerBack?.setEnabled(false);
  }

  assignOverInteraction(action: Interaction['callback'], register = true) {
    this.overInteraction.assign(action, register);
  }

  assignOutInteraction(action: Interaction['callback'], register = true) {
    this.outInteraction.assign(action, register);
  }

  get controllerFront() {
    if (!this._controllerFront && this.media?.hostFront) {
      this._controllerFront = new VideoController(this, this.media.hostFront);
    }
    return this._controllerFront;
  }

  get controllerBack() {
    if (!this._controllerBack && this.media?.hostBack) {
      this._controllerBack = new VideoController(this, this.media.hostBack);
    }
    return this._controllerBack;
  }

  showLoadButton() {
    this.loadButtonFront.parent = this.helper;
    if (this.element.parameters.doubleSided) {
      this.loadButtonBack.parent = this.helper;
    }
  }

  hideLoadButton() {
    this._loadButtonFront?.dispose();
    this._loadButtonFront = null;
    this._loadButtonBack?.dispose();
    this._loadButtonBack = null;
  }

  get loadButtonFront() {
    if (!this._loadButtonFront) {
      this._loadButtonFront = MeshBuilder.CreatePlane(
        `LoadFront-${uuid()}`,
        { size: LOAD_BUTTON_SIZE, sideOrientation: Mesh.BACKSIDE },
        this.viewManager.scene,
      );
      ViewManagerUtils.AttachMetadata(this._loadButtonFront, {
        helperOf: this,
        isControl: true,
        click: () => {
          if (!this.media || this.media.isLoaded) {
            return;
          }
          this.media.loadVideo(true);
        },
      });
      this._loadButtonFront.isPickable = true;
      this.helper && this._loadButtonFront.scaling.copyFrom(Vector3.One().divideInPlace(this.helper.absoluteScaling));
      this._loadButtonFront.position.set(0, 0, Z_OFFSET * 11);
      this._loadButtonFront.material = this.viewManager.loadButtonMaterial;
    }
    return this._loadButtonFront as Mesh;
  }

  get loadButtonBack() {
    if (!this._loadButtonBack) {
      this._loadButtonBack = this.loadButtonFront.clone(`LoadBack-${uuid()}`);
      this._loadButtonBack.position.z = -this.loadButtonFront.position.z;
      this._loadButtonBack.rotation.y = Math.PI;
    }
    return this._loadButtonBack as Mesh;
  }

  setVolume(volume: number) {
    this._controllerFront?.setVolume(volume);
    this._controllerBack?.setVolume(volume);
  }

  dispose() {
    this.overInteraction?.unregister();
    this.outInteraction?.unregister();
    this.hideLoadButton();
    this._controllerFront?.dispose();
    this._controllerBack?.dispose();
    this._controllerFront = null;
    this._controllerBack = null;
    this.media?.dispose();
    this.media = null;
    this.helper?.dispose();
  }
}
