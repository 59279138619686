import {
  AbstractMesh,
  Nullable,
  Observer,
  PickingInfo,
  PointerEventTypes,
  PointerInfo,
  Quaternion,
  Ray,
  RayHelper,
  Scene,
  Vector3,
  WebXRAbstractMotionController,
  WebXRControllerComponent,
  WebXRInputSource,
  WebXRState,
} from '@/data/src/lib/babylon';
import { ViewManager } from '../view-manager';
import { CheckpointRenderable } from '../renderables';
import { INTERACTABLE_TYPES, InteractionTrigger, PopupType, ViewMode } from '../types';
import { FORWARD_QUATERNION, INFINITE_PICK, MAX_SIZE, MoveDirection } from '../constants';
import { Effect } from '../auxiliaries/effect';
import { Media } from '../auxiliaries/media';
import { GuiManager } from '../../utils/gui-manager';

export class VrModeControls {
  public isAttached = false;
  public isLocked = false;

  private inputSources: { [key: string]: WebXRInputSource } = {};
  private motionControllers: { [key: string]: WebXRAbstractMotionController } = {};

  private observers: {
    beforeRender?: Nullable<Observer<Scene>>;
    pointer?: Nullable<Observer<PointerInfo>>;
    stateChanged?: Nullable<Observer<WebXRState>>;
    controllerAdded?: Nullable<Observer<WebXRInputSource>>;
    motionControllerInit: { [key: string]: Nullable<Observer<WebXRAbstractMotionController>> };
    trigger: { [key: string]: Nullable<Observer<WebXRControllerComponent>> };
    aButton: { [key: string]: Nullable<Observer<WebXRControllerComponent>> };
    bButton: { [key: string]: Nullable<Observer<WebXRControllerComponent>> };
    xButton: { [key: string]: Nullable<Observer<WebXRControllerComponent>> };
    yButton: { [key: string]: Nullable<Observer<WebXRControllerComponent>> };
    thumbstickAxis: { [key: string]: Nullable<Observer<{ x: number; y: number }>> };
  } = {
    motionControllerInit: {},
    trigger: {},
    aButton: {},
    bButton: {},
    xButton: {},
    yButton: {},
    thumbstickAxis: {},
  };

  private multipliers: {
    forwardBack: number;
    leftRight: number;
  } = { forwardBack: 1, leftRight: 1 };

  private direction?: Quaternion;
  private now: number;
  private then: number;
  private distance: number;

  private orientation?: Vector3;
  private dx = 0;
  private dy = 0;

  private moveDirection = MoveDirection.None;
  private gravity = -0.0002;
  private isHoverActive = false;
  private doubleController = false;
  private _activeCheckpoint: CheckpointRenderable;
  private _excludedMeshes: AbstractMesh[];

  private pickingInfo: { [key: string]: Nullable<PickingInfo | undefined> } = {};
  private nearestPickingInfo: { [key: string]: Nullable<PickingInfo | undefined> } = {};
  private pickedMesh: { [key: string]: Nullable<AbstractMesh | undefined> } = {};
  private isDragging: { [key: string]: boolean } = {};
  private isInteracting: { [key: string]: boolean } = {};
  private rays: { [key: string]: Ray } = {};
  private rayHelpers: { [key: string]: Nullable<RayHelper> } = {};
  private _hoveredEffect: { [key: string]: Effect | undefined } = {};
  private _hoveredMedia: { [key: string]: Media | undefined } = {};

  private _forwardBack?: number;
  private _leftRight?: number;

  private _guiManager: GuiManager;
  private _guiPosition = Vector3.Zero();

  private _resizeTimeout: NodeJS.Timeout;

  get guiManager(): GuiManager {
    if (!this._guiManager) {
      this._guiManager = new GuiManager(this.viewManager);
    }
    return this._guiManager;
  }

  get vrCamera() {
    return this.viewManager.vrCamera;
  }

  get activeCheckpoint(): CheckpointRenderable {
    if (this._activeCheckpoint) {
      return this._activeCheckpoint;
    }
    return this.viewManager.appService.getActiveCheckpoint();
  }

  get movementVector(): Vector3 {
    return this.activeCheckpoint.movementVector;
  }

  get fallVelocity(): number {
    return this.activeCheckpoint.fallVelocity;
  }

  set fallVelocity(value: number) {
    if (!this.activeCheckpoint) {
      return;
    }
    this.activeCheckpoint.fallVelocity = value;
  }

  constructor(private viewManager: ViewManager) {
    this.onKeyboardDown = this.onKeyboardDown.bind(this);
    this.beforeRenderCallback = this.beforeRenderCallback.bind(this);
    this.pointerCallback = this.pointerCallback.bind(this);
    this.interactCallbackFactory = this.interactCallbackFactory.bind(this);
    this.jumpCallback = this.jumpCallback.bind(this);
  }

  private onKeyboardDown(event: KeyboardEvent) {
    switch (event.code) {
      case 'Escape':
        this.viewManager.setMode(ViewMode.Player);
        break;
      default:
        break;
    }
  }

  public attach() {
    if (!this.viewManager.xrExperience && this.viewManager.supportsXR) {
      this.viewManager.scene
        .createDefaultXRExperienceAsync({
          uiOptions: {
            sessionMode: 'immersive-vr',
            referenceSpaceType: 'local-floor',
          },
          disableTeleportation: true,
          optionalFeatures: true,
          disableDefaultUI: true,
          ignoreNativeCameraTransformation: true,
          floorMeshes: [this.viewManager.boundary],
        })
        .then((xrExperience) => {
          this.viewManager.xrExperience = xrExperience;
          this.viewManager.vrCamera = xrExperience.baseExperience.camera;
          if (this.viewManager._badgeLayer) {
            this.viewManager.badgeScene.autoClearDepthAndStencil = false;
          }
          if (this.viewManager._gizmoLayer) {
            this.viewManager.gizmoScene.autoClearDepthAndStencil = false;
          }
          this.isLocked = false;
          this._excludedMeshes = [this.viewManager.drawingPlane, this.viewManager.boundary];
          this.movementVector.setAll(0);
          this.fallVelocity = 0;
          this.activeCheckpoint.avatar?.setEnabled(false);
          this.activeCheckpoint.updateCamera();

          this.pickingInfo = {};
          this.nearestPickingInfo = {};
          this.pickedMesh = {};
          this.isDragging = {};
          this.isInteracting = {};
          this.rays = {};
          this._hoveredEffect = {};
          this._hoveredMedia = {};

          this.viewManager.xrExperience.baseExperience.enterXRAsync('immersive-vr', 'local-floor').then(() => {
            this.vrCamera.position.copyFrom(this.activeCheckpoint.helper.position.add(this.viewManager.playerCamera.position));
            this.viewManager.scene.activeCamera = this.vrCamera;
            this.viewManager.scene.cameraToUseForPointers = this.vrCamera;
          });
          this.observers.stateChanged = this.viewManager.xrExperience.baseExperience.onStateChangedObservable.add((state) => {
            if (this.viewManager.targetMode !== ViewMode.Player && state === WebXRState.NOT_IN_XR) {
              this.viewManager.setMode(ViewMode.Player);
            }
          });
          this.observers.controllerAdded = this.viewManager.xrExperience.input.onControllerAddedObservable.add((inputSource) => {
            this.rays[inputSource.uniqueId] = Ray.Zero();
            this.rayHelpers[inputSource.uniqueId] = new RayHelper(this.rays[inputSource.uniqueId]);
            this.inputSources[inputSource.uniqueId] = inputSource;
            this.observers.motionControllerInit[inputSource.uniqueId] = this.inputSources[
              inputSource.uniqueId
            ].onMotionControllerInitObservable.add((motionController) => {
              this.motionControllers[motionController.handedness] = motionController;
              this.doubleController = Object.keys(this.motionControllers).length === 2;
              this.observers.trigger[inputSource.uniqueId] = this.motionControllers[motionController.handedness].components[
                'xr-standard-trigger'
              ]?.onButtonStateChangedObservable.add(this.interactCallbackFactory(inputSource));
              this.observers.aButton[inputSource.uniqueId] = this.motionControllers[motionController.handedness].components[
                'a-button'
              ]?.onButtonStateChangedObservable.add(this.jumpCallback);
              this.observers.xButton[inputSource.uniqueId] = this.motionControllers[motionController.handedness].components[
                'x-button'
              ]?.onButtonStateChangedObservable.add(this.jumpCallback);
              this.observers.bButton[inputSource.uniqueId] = this.motionControllers[motionController.handedness].components[
                'b-button'
              ]?.onButtonStateChangedObservable.add(this.interactCallbackFactory(inputSource));
              this.observers.yButton[inputSource.uniqueId] = this.motionControllers[motionController.handedness].components[
                'y-button'
              ]?.onButtonStateChangedObservable.add(this.interactCallbackFactory(inputSource));
              if (motionController.handedness === 'right') {
                this.observers.thumbstickAxis[inputSource.uniqueId] = this.motionControllers[motionController.handedness].components[
                  'xr-standard-thumbstick'
                ]?.onAxisValueChangedObservable.add(({ x, y }) => {
                  if (this.doubleController) {
                    // this.dx = -y * 0.05;
                    this.dy = -x * 0.05;
                    return;
                  }
                  this.walk(x, -y);
                });
              } else {
                this.observers.thumbstickAxis[inputSource.uniqueId] = this.motionControllers[motionController.handedness].components[
                  'xr-standard-thumbstick'
                ]?.onAxisValueChangedObservable.add(({ x, y }) => {
                  this.walk(x, -y);
                });
              }
            });
          });
          this.observers.beforeRender = this.viewManager.scene.onBeforeRenderObservable.add(this.beforeRenderCallback);
          this.observers.pointer = this.viewManager.scene.onPointerObservable.add(this.pointerCallback);

          [...this.viewManager.landscapes, ...this.viewManager.stages].forEach((renderable) => {
            renderable.setPicking(true);
          });
          this.viewManager.badgeScene.useRightHandedSystem = false;
          this.viewManager.revertBadges();

          window.addEventListener('keydown', this.onKeyboardDown);
          this.isAttached = true;
        }, console.error);
    }
  }

  public detach() {
    this._guiManager?.clear();
    this.activeCheckpoint.avatar?.setEnabled(true);
    [...this.viewManager.landscapes, ...this.viewManager.stages].forEach((renderable) => {
      renderable.setPicking(false);
    });
    this.observers.beforeRender && this.viewManager.scene.onBeforeRenderObservable.remove(this.observers.beforeRender);
    this.observers.pointer && this.viewManager.scene.onPointerObservable.remove(this.observers.pointer);
    this.observers.stateChanged &&
      this.viewManager.xrExperience?.baseExperience.onStateChangedObservable.remove(this.observers.stateChanged);
    this.observers.controllerAdded &&
      this.viewManager.xrExperience?.input.onControllerAddedObservable.remove(this.observers.controllerAdded);
    Object.values(this.inputSources).forEach((inputSource) => {
      this.observers.motionControllerInit[inputSource.uniqueId] &&
        this.inputSources[inputSource.uniqueId]?.onMotionControllerInitObservable.remove(
          this.observers.motionControllerInit[inputSource.uniqueId],
        );
      this.observers.trigger[inputSource.uniqueId] &&
        this.motionControllers[inputSource.uniqueId]?.components['xr-standard-trigger']?.onButtonStateChangedObservable.remove(
          this.observers.trigger[inputSource.uniqueId],
        );
      this.observers.aButton[inputSource.uniqueId] &&
        this.motionControllers[inputSource.uniqueId]?.components['a-button']?.onButtonStateChangedObservable.remove(
          this.observers.aButton[inputSource.uniqueId],
        );
      this.observers.bButton[inputSource.uniqueId] &&
        this.motionControllers[inputSource.uniqueId]?.components['b-button']?.onButtonStateChangedObservable.remove(
          this.observers.bButton[inputSource.uniqueId],
        );
      this.observers.xButton[inputSource.uniqueId] &&
        this.motionControllers[inputSource.uniqueId]?.components['x-button']?.onButtonStateChangedObservable.remove(
          this.observers.xButton[inputSource.uniqueId],
        );
      this.observers.yButton[inputSource.uniqueId] &&
        this.motionControllers[inputSource.uniqueId]?.components['y-button']?.onButtonStateChangedObservable.remove(
          this.observers.yButton[inputSource.uniqueId],
        );
      this.observers.thumbstickAxis[inputSource.uniqueId] &&
        this.motionControllers[inputSource.uniqueId]?.components['xr-standard-thumbstick']?.onAxisValueChangedObservable.remove(
          this.observers.thumbstickAxis[inputSource.uniqueId],
        );
      this.activeCheckpoint.updateCamera();
    });
    Object.entries(this.rayHelpers).forEach(([key, rayHelper]) => {
      rayHelper?.dispose();
      delete this.rays[key];
    });
    Object.entries(this._hoveredEffect).forEach(([key, effect]) => {
      effect?.reset();
      delete this._hoveredEffect[key];
    });
    Object.entries(this.isDragging).forEach(([key]) => {
      delete this.isDragging[key];
    });
    Object.entries(this.isInteracting).forEach(([key]) => {
      delete this.isInteracting[key];
    });
    this.viewManager.badgeScene.useRightHandedSystem = true;
    this.viewManager.revertBadges();

    window.removeEventListener('keydown', this.onKeyboardDown, false);
    this.isAttached = false;
    this.viewManager.xrExperience?.baseExperience.exitXRAsync().then(() => {
      this._resizeTimeout = setTimeout(() => {
        this.viewManager.engine.resize();
        clearTimeout(this._resizeTimeout);
      }, 500);
    });
  }

  public walk(x: number, y: number) {
    this.moveDirection = MoveDirection.None;

    if (x > 0) {
      this.multipliers.leftRight = x;
      this.moveDirection = MoveDirection.Right;
    } else if (x < 0) {
      this.multipliers.leftRight = -x;
      this.moveDirection = MoveDirection.Left;
    }
    if (y > 0) {
      this.multipliers.forwardBack = y;
      this.moveDirection |= MoveDirection.Forward;
    } else if (y < 0) {
      this.multipliers.forwardBack = -y;
      this.moveDirection |= MoveDirection.Backward;
    }
  }

  private beforeRenderCallback() {
    if (!Object.keys(this.inputSources).length) {
      return;
    }

    this.now = Date.now();
    this.distance = (this.now - this.then) * this.activeCheckpoint.speed;
    this.isHoverActive = false;

    if (!this.viewManager.isHandHeldDevice) {
      this.checkInteractions();
      this.viewManager.appService.setHoverActive(this.isHoverActive);
    }

    this.orientation = this.vrCamera.rotationQuaternion.toEulerAngles();
    this.orientation.x = Math.min(Math.max(this.orientation.x + this.dx, -Math.PI / 2 + 0.001), Math.PI / 2 - 0.001);
    this.orientation.y += this.dy;
    this.vrCamera.rotationQuaternion = this.orientation.toQuaternion();
    this.movementVector.copyFrom(
      this.vrCamera.position
        .subtract(this.activeCheckpoint.helper.position.add(this.viewManager.playerCamera.position))
        .multiplyByFloats(1, 0, 1),
    );
    this.direction = this.vrCamera.absoluteRotation.conjugate().multiply(FORWARD_QUATERNION.multiply(this.vrCamera.absoluteRotation));
    this._forwardBack = this.distance * this.multipliers.forwardBack;
    this._leftRight = this.distance * this.multipliers.leftRight;
    if (/*this.keys.up || */ this.moveDirection & MoveDirection.Forward) {
      this.movementVector.addInPlaceFromFloats(-this.direction.x * this._forwardBack, 0, this.direction.z * this._forwardBack);
    }
    if (/*this.keys.left || */ this.moveDirection & MoveDirection.Left) {
      this.movementVector.addInPlaceFromFloats(this.direction.z * this._leftRight, 0, this.direction.x * this._leftRight);
    }
    if (/*this.keys.down || */ this.moveDirection & MoveDirection.Backward) {
      this.movementVector.addInPlaceFromFloats(this.direction.x * this._forwardBack, 0, -this.direction.z * this._forwardBack);
    }
    if (/*this.keys.right || */ this.moveDirection & MoveDirection.Right) {
      this.movementVector.addInPlaceFromFloats(-this.direction.z * this._leftRight, 0, -this.direction.x * this._leftRight);
    }
    this.movementVector.length() > 0.002 && this.activeCheckpoint.helper.moveWithCollisions(this.movementVector);
    this.activeCheckpoint.helper.moveWithCollisions(new Vector3(0, this.fallVelocity, 0));
    this.fallVelocity += this.gravity * Math.min(this.now - (this.then ?? this.now), 60);
    this.vrCamera.position.copyFrom(this.activeCheckpoint.helper.position.add(this.viewManager.playerCamera.position));
    this.then = this.now;
  }

  private pointerCallback(event: PointerInfo) {
    switch (event.type) {
      case PointerEventTypes.POINTERMOVE:
        Object.values(this.inputSources).forEach((inputSource) => {
          if (!this.isDragging[inputSource.uniqueId]) {
            return;
          }
          this.nearestPickingInfo[inputSource.uniqueId] = this.pickAhead(inputSource);
          this.nearestPickingInfo[inputSource.uniqueId]?.pickedMesh?.metadata?.drag?.(this.nearestPickingInfo[inputSource.uniqueId]);
        });
        break;
      case PointerEventTypes.POINTERUP:
        Object.values(this.inputSources).forEach((inputSource) => {
          this.isInteracting[inputSource.uniqueId] = false;
        });
        break;
      default:
        break;
    }
  }

  private interact(inputSource: WebXRInputSource) {
    this.nearestPickingInfo[inputSource.uniqueId] = this.pickAhead(inputSource);
    this.pickedMesh[inputSource.uniqueId] = this.nearestPickingInfo[inputSource.uniqueId]?.pickedMesh;
    this.isDragging[inputSource.uniqueId] = !!this.pickedMesh[inputSource.uniqueId];
    if (this.pickedMesh[inputSource.uniqueId] && !this.isInteracting[inputSource.uniqueId]) {
      this.isInteracting[inputSource.uniqueId] = true;
      if (this.isDragging[inputSource.uniqueId] && !!this.pickedMesh[inputSource.uniqueId]?.metadata?.drag) {
        this.pickedMesh[inputSource.uniqueId]?.metadata?.drag?.(this.nearestPickingInfo[inputSource.uniqueId]);
        return;
      }
      if (this.pickedMesh[inputSource.uniqueId]?.metadata?.click) {
        this.pickedMesh[inputSource.uniqueId]?.metadata?.click(this.nearestPickingInfo[inputSource.uniqueId]);
        return;
      }
      this.pickedMesh[inputSource.uniqueId]?.metadata?.helperOf?.element?.parameters?.popups?.slice(0, 1).forEach((popup) => {
        this._guiPosition.copyFrom(
          this.activeCheckpoint.helper.position
            .add(
              this.nearestPickingInfo[inputSource.uniqueId]!.pickedPoint!.subtract(this.viewManager.scene.activeCamera!.globalPosition)
                .normalize()
                .scale(2),
            )
            .add(Vector3.UpReadOnly),
        );
        switch (popup.type) {
          case PopupType.Link:
            let url = popup.parameters.find(({ key }) => key === 'link')?.value ?? '';
            if (popup.parameters.find(({ key }) => key === 'openWithoutPrompt')?.value) {
              if (!url.startsWith('http://') && !url.startsWith('https://')) {
                url = 'https://' + url;
              }
              this.viewManager.appService.windowOpenWithPlatform(
                url,
                !popup.parameters.find(({ key }) => key === 'openOnCurrentTab')?.value,
              );
            } else {
              this.guiManager.showPopup(this.pickedMesh[inputSource.uniqueId]!.metadata.helperOf.element, this._guiPosition);
            }
            break;
          default:
            this.guiManager.showPopup(this.pickedMesh[inputSource.uniqueId]!.metadata.helperOf.element, this._guiPosition);
            break;
        }
      });
      this.pickedMesh[inputSource.uniqueId]?.metadata?.helperOf?.effect?.trigger === InteractionTrigger.Click &&
        this.pickedMesh[inputSource.uniqueId]?.metadata.helperOf.effect.play();
    }
    this.pickingInfo[inputSource.uniqueId] && this.viewManager.scene.simulatePointerDown(this.pickingInfo[inputSource.uniqueId]!);
  }

  private interactCallbackFactory(inputSource: WebXRInputSource) {
    return (event: WebXRControllerComponent) => {
      if (event.pressed) {
        this.interact(inputSource);
        return;
      }
      this.pickingInfo[inputSource.uniqueId] && this.viewManager.scene.simulatePointerUp(this.pickingInfo[inputSource.uniqueId]!);
      this.isDragging[inputSource.uniqueId] = false;
      this.isInteracting[inputSource.uniqueId] = false;
    };
  }

  private jumpCallback(event: WebXRControllerComponent) {
    event.pressed && this.activeCheckpoint.jump();
  }

  private pickAhead(inputSource: WebXRInputSource) {
    if (!this.rays[inputSource.uniqueId]) {
      this.rays[inputSource.uniqueId] = Ray.Zero();
    }
    this.rays[inputSource.uniqueId].origin = inputSource.pointer.position;
    inputSource.pointer.getDirectionToRef(Vector3.RightHandedForwardReadOnly, this.rays[inputSource.uniqueId].direction);
    this.rays[inputSource.uniqueId].length = MAX_SIZE;
    if (!this.rayHelpers[inputSource.uniqueId]) {
      this.rayHelpers[inputSource.uniqueId] = new RayHelper(this.rays[inputSource.uniqueId]);
    }
    this.rayHelpers[inputSource.uniqueId]!.show(this.viewManager.scene);
    if (this.vrCamera) {
      this.pickingInfo[inputSource.uniqueId] = this.viewManager.scene
        .multiPickWithRay(
          this.rays[inputSource.uniqueId],
          (mesh) =>
            mesh.isEnabled() &&
            !mesh.metadata?.box &&
            !this._excludedMeshes.includes(mesh) &&
            (INTERACTABLE_TYPES.includes(mesh?.metadata?.helperOf?.element?.type) || mesh?.metadata?.gui),
        )
        ?.reduce((c, p) => (c.distance < p.distance ? c : p), INFINITE_PICK);
      if (this.pickingInfo[inputSource.uniqueId]?.hit && this.pickingInfo[inputSource.uniqueId]?.pickedMesh) {
        return this.pickingInfo[inputSource.uniqueId];
      }
    }
    return undefined;
  }

  private checkInteractions() {
    Object.values(this.inputSources).forEach((inputSource) => {
      this.nearestPickingInfo[inputSource.uniqueId] = this.pickAhead(inputSource);
      this.pickedMesh[inputSource.uniqueId] = this.nearestPickingInfo[inputSource.uniqueId]?.pickedMesh;

      if (this.pickedMesh[inputSource.uniqueId]) {
        this._hoveredMedia[inputSource.uniqueId] = this.pickedMesh[inputSource.uniqueId]!.metadata?.helperOf?.media;
        this._hoveredMedia[inputSource.uniqueId]?.overInteractionCallback();
        if (this.pickedMesh[inputSource.uniqueId]!.metadata?.helperOf?.effect) {
          if (
            this.pickedMesh[inputSource.uniqueId]!.metadata.helperOf.effect !== this._hoveredEffect[inputSource.uniqueId] &&
            this.pickedMesh[inputSource.uniqueId]!.metadata.helperOf.effect.trigger === InteractionTrigger.Hover
          ) {
            this._hoveredEffect[inputSource.uniqueId]?.reset();
            this._hoveredEffect[inputSource.uniqueId] = this.pickedMesh[inputSource.uniqueId]!.metadata.helperOf.effect;
            this._hoveredEffect[inputSource.uniqueId]?.animationGroup.onAnimationGroupLoopObservable.clear();
            !this._hoveredEffect[inputSource.uniqueId]?.isPlaying && this._hoveredEffect[inputSource.uniqueId]?.play();
          }
        } else {
          this._hoveredEffect[inputSource.uniqueId]?.reset();
          this._hoveredEffect[inputSource.uniqueId] = undefined;
        }
        this.isHoverActive =
          this.pickedMesh[inputSource.uniqueId]?.metadata?.helperOf?.effect?.trigger === InteractionTrigger.Click ||
          this.pickedMesh[inputSource.uniqueId]?.metadata?.click ||
          this.pickedMesh[inputSource.uniqueId]?.metadata?.drag;

        if (this.pickedMesh[inputSource.uniqueId]?.metadata?.helperOf?.element?.parameters?.popups?.length) {
          this.isHoverActive = true;
        }
      } else {
        if (this._hoveredMedia) {
          this._hoveredMedia[inputSource.uniqueId]?.outInteractionCallback();
          this._hoveredMedia[inputSource.uniqueId] = undefined;
        }
        this._hoveredEffect[inputSource.uniqueId]?.reset &&
          this._hoveredEffect[inputSource.uniqueId]?.animationGroup.onAnimationGroupLoopObservable.addOnce(
            this._hoveredEffect[inputSource.uniqueId]!.reset,
          );
        this._hoveredEffect[inputSource.uniqueId] = undefined;
        this.isHoverActive = false;
      }
    });
  }

  public dispose() {
    this.detach();
    this._guiManager?.dispose();
  }
}
