import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { Options } from 'ngx-slider-v2';
import {
  TLightElement,
  TElement,
  LightType,
  LIGHT_DIFFUSE,
  LIGHT_INTENSITES,
  LIGHT_ANGLE,
  LIGHT_GROUND,
  LIGHT_BIAS,
  LIGHT_NORMAL_BIAS,
} from '@/data/src/lib/view-manager';
import { ApplicationService } from '@/view/src/app/app.service';
import { ViewManagerUtils } from '@/data/src/lib/utils/view-manager-utils';
import { deepCopy } from '@/data/src/lib/utils/data';
import { PanelComponent } from '@/ui/src/lib/components/panel/panel.component';

const LIGHT_OPTIONS: { [key: string]: Options } = {
  [LightType.Ambient]: { floor: 0, ceil: 10, step: 0.01, showSelectionBar: true },
  [LightType.Directional]: { floor: 0, ceil: 10, step: 0.01, showSelectionBar: true },
  [LightType.Spot]: { floor: 0, ceil: 100, step: 0.1, showSelectionBar: true },
  [LightType.Point]: { floor: 0, ceil: 100, step: 0.1, showSelectionBar: true },
};

const BIAS_FACTOR = 1000000;
const NORMAL_BIAS_FACTOR = 1000;
const ANGLE_FACTOR = 180 / Math.PI;

@Component({
  selector: 'ui-light-panel',
  templateUrl: './light-panel.component.html',
  styleUrls: ['./light-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightPanelComponent extends PanelComponent {
  _element: TLightElement;
  color: string = ViewManagerUtils.GetColorString(LIGHT_DIFFUSE);
  groundColor: string = ViewManagerUtils.GetColorString(LIGHT_GROUND);
  intensity: number | undefined;
  angle: number | undefined;
  bias: number | undefined;
  normalBias: number | undefined;

  _color = this.color;
  _groundColor = this.groundColor;

  angleOptions = { floor: 1, ceil: 179, step: 0.01, showSelectionBar: true };
  biasOptions = { floor: 0.1, ceil: 100, step: 0.1, showSelectionBar: true };

  @Input() set element(input: TElement | undefined) {
    if (!input) {
      return;
    }
    this._element = deepCopy(input) as TLightElement;
    this.color = ViewManagerUtils.GetColorString(this._element.parameters.color ?? LIGHT_DIFFUSE);
    this.groundColor = ViewManagerUtils.GetColorString(this._element.parameters.groundColor ?? LIGHT_GROUND);
    this.intensity = this._element.parameters.intensity ?? LIGHT_INTENSITES[this._element.parameters.type];
    this.bias = (this._element.parameters.bias ?? LIGHT_BIAS) * BIAS_FACTOR;
    this.normalBias = (this._element.parameters.normalBias ?? LIGHT_NORMAL_BIAS) * NORMAL_BIAS_FACTOR;
    this.angle = (this._element.parameters.angle ?? LIGHT_ANGLE) * ANGLE_FACTOR;

    this._color = this.color;
    this._groundColor = this.groundColor;

    this._cd.detectChanges();
  }

  get intensityOptions() {
    return LIGHT_OPTIONS[(this.element as TLightElement)?.parameters.type];
  }

  get element() {
    return this._element;
  }

  get type() {
    return (this.element as TLightElement)?.parameters?.type;
  }

  get lightType() {
    return LightType;
  }

  constructor(
    protected _appService: ApplicationService,
    private _cd: ChangeDetectorRef,
  ) {
    super(_appService);
    this.onChangeEnd = this.onChangeEnd.bind(this);
  }

  getColorArray(color: string) {
    return ViewManagerUtils.GetColorArray(color) as TLightElement['parameters']['color'];
  }

  onColorUpdate(color: string, key: string) {
    this[`_${key}`] = color;
    this._element &&
      this._appService.updateViewRenderable({
        ...this._element,
        parameters: {
          ...this._element.parameters,
          [key]: ViewManagerUtils.GetColorArray(color) as TLightElement['parameters']['color'],
        },
      });
  }

  onIntensityChange(intensity: number) {
    this.intensity = intensity;
    this._element &&
      this._appService.updateViewRenderable({
        ...this._element,
        parameters: { ...this._element.parameters, intensity },
      });
  }

  onBiasChange(bias: number) {
    this.bias = bias;
    this._element &&
      this._appService.updateViewRenderable({
        ...this._element,
        parameters: { ...this._element.parameters, bias: bias / BIAS_FACTOR },
      });
  }

  onNormalBiasChange(normalBias: number) {
    this.normalBias = normalBias;
    this._element &&
      this._appService.updateViewRenderable({
        ...this._element,
        parameters: { ...this._element.parameters, normalBias: normalBias / NORMAL_BIAS_FACTOR },
      });
  }

  onAngleChange(angle: number) {
    this.angle = angle;
    this._element &&
      this._appService.updateViewRenderable({
        ...this._element,
        parameters: { ...this._element.parameters, angle: this.angle / ANGLE_FACTOR },
      });
  }

  onChangeEnd() {
    if (!this._element) {
      return;
    }
    const actualElement = this._element && this._appService.getViewRenderable(this._element)?.element;
    if (!actualElement) {
      return;
    }

    if (
      this._element.parameters.intensity !== this.intensity ||
      this._element.parameters.angle !== this.angle ||
      ViewManagerUtils.PropsDiff(this._element.parameters.color ?? [], ViewManagerUtils.GetColorArray(this._color) ?? []).length ||
      ViewManagerUtils.PropsDiff(this._element.parameters.groundColor ?? [], ViewManagerUtils.GetColorArray(this._groundColor) ?? []).length
    ) {
      this._appService.updateViewElement(actualElement);
    }
  }
}
