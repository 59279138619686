import { ChangeDetectionStrategy, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { memoize } from 'lodash';
import { PanelMask } from '@/app/src/app/oxr/oxr-space/oxr-space.component';
import { ApplicationService } from '@/view/src/app/app.service';

@UntilDestroy()
@Component({
  selector: 'ui-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('contentPanel', [
      state('expanded', style({ height: '*', opacity: 1, visibility: 'visible' })),
      state('collapsed', style({ height: '0px', opacity: 0, padding: '0px', visibility: 'hidden' })),
      transition('expanded <=> collapsed', animate('200ms cubic-bezier(.37,1.04,.68,.98)')),
    ]),
  ],
})
export class PanelComponent implements OnInit, OnDestroy {
  /**
   * Decides if the single item will be open at once or not.
   * In collapsing mode, toggling one would collapse others
   */
  expanded: boolean;

  @Input() title = '';
  @Input() collapsing = true;
  @Input() disabled = false;

  @Input() panelId: PanelMask;
  @Input() set isExpanded(value: boolean) {
    this.expanded = value;
  }

  get panelMask() {
    return PanelMask;
  }

  constructor(protected _appService: ApplicationService) {}

  ngOnInit(): void {
    this._appService.panelOverrides$.pipe(untilDestroyed(this)).subscribe((overrides) => {
      this.isExpanded = overrides.get(this.panelId) ?? true;
    });
  }

  ngOnDestroy(): void {}

  /**
   * Make the toggle function available to be called from
   * outside.
   * Memoize to prevent extra calls
   * @param index - index of the accordion item
   */
  getToggleState = memoize((index: number) => {
    return this.toggleState.bind(this);
  });

  toggleState = () => {
    this.expanded = !this.expanded;
    this._appService.setPanelOverride(this.panelId, this.expanded);
  };
}
