<ui-panel [title]="'sidenav.media' | translate" [collapsing]="true" [panelId]="panelMask.Media">
  @if (!!element.parameters.media) {
    <div class="tool-header">
      <div class="tool-header-title">
        <span class="icon icon-arrow-down"></span>
        {{ name }}
      </div>
      <div class="tool-header-action">
        <div class="icon icon-delete" (click)="onDelete()"></div>
      </div>
    </div>
  }
  <div class="tool-body">
    <div class="rate-setting">
      <span>{{ 'oxr.creatingSpace.panels.media.fitToImage' | translate }}</span>
      <button ui-outlined-button xxs secondary (click)="resize()">{{ 'oxr.creatingSpace.panels.common.apply' | translate }}</button>
    </div>
    <div class="separator"></div>
    <ui-switch
      class="icon-style-switch"
      [checked]="doubleSided"
      [label]="'oxr.creatingSpace.panels.media.doubleSided' | translate"
      [onShowContentMode]="true"
      (changed)="onDoubleSideToggle($event)"
    ></ui-switch>
    <div class="separator"></div>
    <ui-slider
      [label]="'oxr.creatingSpace.panels.media.brightness' | translate"
      [value]="brightness"
      [options]="brightnessOptions"
      (onChange)="onBrightnessChange($event)"
      (onChangeEnd)="onChangeEnd()"
    ></ui-slider>
    @if (isVideo) {
      <div class="separator"></div>
      <ui-slider
        [label]="'oxr.creatingSpace.panels.media.defaultVolume' | translate"
        [value]="volume"
        [options]="volumeOptions"
        (onChange)="onVolumeChange($event)"
        (onChangeEnd)="onChangeEnd()"
      ></ui-slider>
      <div class="separator"></div>
      <ui-switch
        class="icon-style-switch"
        [checked]="autoplay"
        [label]="'oxr.creatingSpace.panels.media.autoplay' | translate"
        [onShowContentMode]="true"
        (changed)="toggleAutoplay($event)"
      ></ui-switch>
      <div class="separator"></div>
      <ui-switch
        class="icon-style-switch"
        [checked]="repeat"
        [label]="'oxr.creatingSpace.panels.media.repeat' | translate"
        [onShowContentMode]="true"
        (changed)="toggleRepeat($event)"
      ></ui-switch>
    }
  </div>
</ui-panel>
