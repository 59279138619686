import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { TScreenElement, TElement, SCREEN_SCALING } from '@/data/src/lib/view-manager';
import { ApplicationService } from '@/view/src/app/app.service';
import { deepCopy } from '@/data/src/lib/utils/data';
import { PanelComponent } from '@/ui/src/lib/components/panel/panel.component';

@Component({
  selector: 'ui-screen-panel',
  templateUrl: './screen-panel.component.html',
  styleUrls: ['./screen-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenPanelComponent extends PanelComponent {
  _element: TScreenElement;
  rate: string;
  width: number;
  height: number;

  isCustom: boolean;
  rateOptions = [
    {
      label: '1:1',
      value: '1:1',
    },
    {
      label: '4:3',
      value: '4:3',
    },
    {
      label: '16:9',
      value: '16:9',
    },
    {
      label: 'Custom',
      value: 'Custom',
    },
  ];

  @Input() set element(input: TElement | undefined) {
    if (!input) {
      return;
    }
    this._element = deepCopy(input) as TScreenElement;

    this.width = (this.element.parameters.scaling?.[0] ?? 1) * 1000;
    this.height = (this.element.parameters.scaling?.[1] ?? 1) * 1000;
    const ratio = this.width / (this.height || 1);

    if (Math.abs(ratio - 1) < 0.0001) {
      this.rate = this.rateOptions[0].value;
    } else if (Math.abs(ratio - 4 / 3) < 0.0001) {
      this.rate = this.rateOptions[1].value;
    } else if (Math.abs(ratio - 16 / 9) < 0.0001) {
      this.rate = this.rateOptions[2].value;
    } else {
      this.isCustom = true;
      this.rate = this.rateOptions[3].value;
    }
    this._cd.detectChanges();
  }

  get element(): TScreenElement {
    return this._element;
  }

  constructor(
    protected _appService: ApplicationService,
    private _cd: ChangeDetectorRef,
  ) {
    super(_appService);
  }

  onRateChange(rate: string) {
    let scaling = this.element.parameters.scaling ?? SCREEN_SCALING;
    switch (rate) {
      case '1:1':
        this.isCustom = false;
        scaling[1] = scaling[0];
        break;
      case '4:3':
        this.isCustom = false;
        scaling[1] = (scaling[0] * 3) / 4;
        break;
      case '16:9':
        this.isCustom = false;
        scaling[1] = (scaling[0] * 9) / 16;
        break;
      case 'Custom':
        this.isCustom = true;
        scaling[0] = this.width / 1000;
        scaling[1] = this.height / 1000;
        break;
      default:
        return;
    }
    this._appService.updateViewElement({
      ...this.element,
      parameters: { ...this.element.parameters, scaling },
    });
  }

  onWidthChange(input: string) {
    const width = Number(input);
    if (!this.element || isNaN(width) || width === 0) {
      return;
    }
    this.width = width;
    this.element &&
      this._appService.updateViewRenderable({
        ...this.element,
        parameters: {
          ...this.element.parameters,
          scaling: (this.element.parameters.scaling
            ? [this.width / 1000, ...this.element.parameters.scaling.slice(1)]
            : [this.width / 1000, this.height / 1000, 1]) as TScreenElement['parameters']['scaling'],
        },
      });
  }

  onHeightChange(input: string) {
    const height = Number(input);
    if (!this.element || isNaN(height) || height === 0) {
      return;
    }
    this.height = height;
    this.element &&
      this._appService.updateViewRenderable({
        ...this.element,
        parameters: {
          ...this.element.parameters,
          scaling: (this.element.parameters.scaling
            ? [this.element.parameters.scaling[0], this.height / 1000]
            : [this.width / 1000, this.height / 1000]) as TScreenElement['parameters']['scaling'],
        },
      });
  }

  onChangeEnd() {
    if (!this.element) {
      return;
    }
    const actualElement = this.element && (this._appService.getViewRenderable(this.element)?.element as TScreenElement);
    if (!actualElement) {
      return;
    }
    if (
      Math.abs(actualElement.parameters.scaling?.[0] ?? NaN - this.width / 1000) > 1e-15 ||
      Math.abs(actualElement.parameters.scaling?.[1] ?? NaN - this.height / 1000) > 1e-15
    ) {
      this._appService.updateViewElement(actualElement);
    }
  }

  ngOnDestroy(): void {
    this.onChangeEnd();
    super.ngOnDestroy();
  }
}
