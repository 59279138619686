import { Action, TransactionType } from '@/data/src/lib/enums/transaction-type';
import { INTERACTABLE_TYPES, TElement, TInteractableElement, ViewManager } from '@/data/src/lib/view-manager';
import { deepCopy } from '@/data/src/lib/utils/data';
import { privateFieldReplacer } from '@/data/src/lib/utils/json-utils';

export class ElementsTransaction {
  public get transactionType(): TransactionType {
    return TransactionType.Elements;
  }

  protected _lastElements: TElement[] = [];
  _committed = false;

  constructor(
    public name: string,
    public action: Action,
  ) {}

  /**
   * Commits the changes that occured within the transaction and pushes it onto the undo stack
   * @param viewManager view in which the changes occured
   * @param captureEvent set to true if you want to notify observers (create an undo event and share this event with other users)
   */
  commit(viewManager: ViewManager, captureEvent = true) {
    if (!this._committed) {
      const newElements = viewManager.appService.getViewElements();
      this._lastElements.length = 0;
      this._lastElements.push(...newElements);
      if (captureEvent) {
        viewManager.onTransaction?.notifyObservers(this);
      }
      viewManager.appService.dataCache.modify(viewManager.model);
      this._committed = true;
    }
  }

  /**
   * Reverses the transaction and sets the view back to the state before it occured
   * @param viewManager view in which the changes occured
   * @returns the inverse transaction
   */
  rollback(viewManager: ViewManager): ElementsTransaction | undefined {
    if (this._committed) {
      const redo = new ElementsTransaction('ViewElements', Action.Modified);
      redo.commit(viewManager, false);
      const actualElements = viewManager.appService.getViewElements();
      viewManager.appService.setViewElements(
        this._lastElements.map((element) => {
          if (!INTERACTABLE_TYPES.includes(element.type)) {
            return element;
          }
          const actual = actualElements.find(({ id }) => id === element.id) as TInteractableElement;
          if (!actual) {
            return element;
          }
          return {
            ...element,
            parameters: {
              ...element.parameters,
              popups: deepCopy(actual.parameters.popups),
              effects: deepCopy(actual.parameters.effects),
            },
          } as TInteractableElement;
        }),
      );
      this._committed = false;
      return redo;
    }
    return undefined;
  }

  /**
   * Converts the transaction to json
   * @returns a json string
   */
  toJson() {
    return JSON.stringify(this, privateFieldReplacer);
  }
}
