import { v4 as uuid } from 'uuid';
import { AssetStatus } from '@/view/src/app/app.service';
import {
  AbstractMesh,
  ActionManager,
  AnimationGroup,
  Mesh,
  MeshBuilder,
  Nullable,
  Observer,
  PickingInfo,
  Quaternion,
  Ray,
  Scene,
  SceneLoader,
  TransformNode,
  Vector3,
} from '../../babylon';
import { CEILING_RAY, FACING_RAY, FLOOR_RAY, HEAD_RAY, INFINITE_PICK, KNEE_RAY, MINUS_INFINITE_PICK, ViewManager } from '..';
import { COLLIDABLE_TYPES, ElementType, TCheckpointElement, TElement, ViewMode } from '../types';
import { Interaction } from '../auxiliaries/interaction';
import { deepCopy } from '../../utils/data';
import { ViewManagerUtils } from '../../utils/view-manager-utils';

const DEFAULT_POSITION: [number, number, number] = [0, 0.3, -0.027];
const DEFAULT_ROTATION: [number, number, number] = [Math.PI / 2, 0, 0];
const INITIAL_PIVOT: [number, number, number] = [0.25, 0, 0.25];

const READY_PLAYER_ME_REGEXP = /\/(?!.*\/)/;

enum AvatarAnimations {
  Idle = 'Idle',
  Walk = 'Walk',
  WalkBack = 'WalkBack',
  LeftWalk = 'LeftWalk',
  RightWalk = 'RightWalk',
  Run = 'Run',
  Jump = 'Jump',
}

export class CheckpointRenderable {
  public elementId: string;
  public helper: Mesh;
  public avatar: Nullable<Mesh>;
  public inJump: boolean;
  public movementVector = Vector3.Zero();
  public fallVelocity = 0;
  public speed = 0.004;

  private overInteraction: Interaction;
  private outInteraction: Interaction;

  private _avatarUrl?: string;
  private _validAvatarUrl?: string;
  private _animationGroups: { [key: string]: Nullable<AnimationGroup> };
  private _weights: { [key: string]: number };
  private activeAnimationName: string;
  private rays: { [key: string]: Ray };
  private picks: { [key: string]: PickingInfo | undefined };

  private _observers: {
    worldMatrix?: Nullable<Observer<TransformNode>>;
    beforeAnimations?: Nullable<Observer<Scene>>;
  } = {};

  constructor(
    public element: TCheckpointElement,
    public viewManager: ViewManager,
  ) {
    this.elementId = element.id;

    this.update = this.update.bind(this);
    this.createHelper = this.createHelper.bind(this);
    this.showHelper = this.showHelper.bind(this);
    this.hideHelper = this.hideHelper.bind(this);
    this.updateElement = this.updateElement.bind(this);
    this.syncAvatar = this.syncAvatar.bind(this);
    this.disposeAvatar = this.disposeAvatar.bind(this);
    this.addAnimationGroup = this.addAnimationGroup.bind(this);
    this.animationCallback = this.animationCallback.bind(this);
    this.collisionPredicate = this.collisionPredicate.bind(this);
    this.createHelper();
    this.update();
  }

  async load(_this?: CheckpointRenderable) {
    const self = _this ?? this;
    self.viewManager.tool?.interact();

    self._avatarUrl = self.element.parameters.avatar;
    if (self._avatarUrl) {
      let [path, file] = self._avatarUrl.split(READY_PLAYER_ME_REGEXP);
      if (path && file) {
        if (!path.endsWith('/')) {
          path += '/';
        }
        let mesh: AbstractMesh | undefined;
        switch (self.viewManager.appService.getAssetStatus(file)) {
          case AssetStatus.Pending:
            await new Promise<void>((resolve) => {
              self.viewManager.appService.assetStatusMap$.subscribe((map) => {
                map.get(file) === AssetStatus.Ready && resolve(self.load(self));
              });
            });
            return;
          case AssetStatus.Ready:
            mesh = self.viewManager.appService.getLoadedAssetMesh(file)?.meshes[0];
            break;
          default:
            self.viewManager.appService.updateAssetStatusMap(file, AssetStatus.Pending);
            let meshSubject = self.viewManager.appService.getLoadedAssetMesh(file);
            if (!meshSubject) {
              const meshes: any = '';
              let progress = 0;
              self.viewManager.appService.updateProgressMap(file, 1);
              try {
                meshSubject = await SceneLoader.ImportMeshAsync(meshes, path, file, self.viewManager.scene, (event) => {
                  progress = (event.loaded / event.total) * 100;
                  self.viewManager.appService.updateProgressMap(file, progress);
                });
                self.viewManager.appService.setLoadedAssetMesh(file, meshSubject);
                if (meshSubject) {
                  meshSubject.meshes[0].setEnabled(false);
                  self.viewManager.appService.updateAssetStatusMap(file, AssetStatus.Ready);
                  mesh = meshSubject.meshes[0];
                  self._validAvatarUrl = self._avatarUrl;
                } else {
                  throw new Error('No avatar subject.');
                }
              } catch {
                self.viewManager.appService.showNotification('oxr.creatingSpace.panels.avatar.enterValidLink');
                self._avatarUrl = self._validAvatarUrl;
                self.updateElement();
              } finally {
                self.viewManager.appService.updateProgressMap(file, 100);
              }
            }
            break;
        }

        if (mesh) {
          self.avatar?.dispose();
          self._observers.worldMatrix && self.helper.onAfterWorldMatrixUpdateObservable.remove(self._observers.worldMatrix);
          await self.viewManager.setAvatarAnimations(self, mesh);
          self.avatar = mesh.clone(uuid(), null) as Mesh;
          self.viewManager.scene.onAfterRenderObservable.addOnce(() => {
            self.avatar?.setEnabled(self.viewManager.targetMode !== ViewMode.VR);
          });
          self._observers.worldMatrix = self.helper.onAfterWorldMatrixUpdateObservable.add(self.syncAvatar);
          self.runAnimation(AvatarAnimations.Idle);

          ViewManagerUtils.ProcessRenderableHierarchy(self, {
            checkCollisions: false,
            isPickable: false,
            meshToProcess: self.avatar,
          });

          self.viewManager.lights.forEach((light) => {
            light.update();
          });

          self.overInteraction?.unregister();
          self.outInteraction?.unregister();
          self.overInteraction = new Interaction(self.viewManager.scene, self.avatar, ActionManager.OnPointerOverTrigger, undefined, true);
          self.outInteraction = new Interaction(self.viewManager.scene, self.avatar, ActionManager.OnPointerOutTrigger, undefined, true);
        }
      }
      self.helper.visibility = 0.001;
    } else {
      self.disposeAvatar();
      self.helper.visibility = 1;
    }
  }

  update(element?: TElement) {
    if (element) {
      this.element = deepCopy(element as TCheckpointElement);
    }

    this.viewManager.activeMode === ViewMode.Player ? this.hideHelper() : this.showHelper();

    const dirty = this._avatarUrl !== this.element.parameters.avatar;
    if (dirty) {
      this.load().then(() => {
        this.update();
      });
      return;
    }

    if (this.helper) {
      this.updateCamera();
      this.helper.position.copyFromFloats(...this.element.parameters.position!);
      if (this.helper.rotationQuaternion) {
        this.helper.rotationQuaternion.copyFromFloats(...this.element.parameters.quaternion!);
      } else {
        this.helper.rotationQuaternion = Quaternion.FromArray(this.element.parameters.quaternion!);
      }
    }
  }

  updateCamera() {
    this.viewManager.playerCamera.parent = this.helper;
    this.viewManager.targetMode === ViewMode.VR && this.viewManager.playerCamera.position.set(0, 0.75, 0);
    if (this.avatar) {
      switch (this.viewManager.targetMode) {
        case ViewMode.Creator:
          this.runAnimation(AvatarAnimations.Idle);
          this.avatar
            ? this.viewManager.playerCamera.position.set(-0.45, 0.75, -2)
            : this.viewManager.playerCamera.position.set(0, 0.75, 0);
          break;
        case ViewMode.Player:
          this.avatar
            ? this.viewManager.playerCamera.position.set(-0.45, 0.75, -2)
            : this.viewManager.playerCamera.position.set(0, 0.75, 0);
          break;
        case ViewMode.VR:
          this.viewManager.playerCamera.position.set(0, 0.75, 0);
          break;
      }
    } else {
      this.viewManager.playerCamera.position.set(0, 0.75, 0);
    }
  }

  createHelper() {
    this.helper = MeshBuilder.CreateCylinder(
      `Helper-${this.element.id}`,
      { diameterTop: 0, diameterBottom: 0.5, height: 0.75, tessellation: 32 },
      this.viewManager.scene,
    );
    this.helper.setPivotPoint(new Vector3(...INITIAL_PIVOT));
    this.helper.rotation.set(...DEFAULT_ROTATION);
    this.helper.bakeCurrentTransformIntoVertices();
    this.helper.position.set(...DEFAULT_POSITION);
    this.helper.setPivotPoint(Vector3.ZeroReadOnly);
    this.helper.ellipsoid.set(0.5, 1, 0.5);
    this.helper.receiveShadows = false;
    this.helper.material = this.viewManager.checkpointMaterial;
    this.helper.metadata = this.helper.metadata ?? { helperOf: this };
    this.helper.checkCollisions = true;
    this.helper.collisionMask = -1;
    this.helper.collisionGroup = -1;

    this.rays = {
      animation: HEAD_RAY.clone(),
      floor: FLOOR_RAY.clone(),
      facing: FACING_RAY.clone(),
      heading: HEAD_RAY.clone(),
      ceiling: CEILING_RAY.clone(),
      knee: KNEE_RAY.clone(),
    };
    this.picks = {};

    this.helper.onCollide = (mesh) => {
      if (!(mesh && this.helper?.collider?.collisionFound && this.helper.collider.intersectionPoint)) {
        return;
      }
      // // ORDER IMPORTANT
      // Floor Check
      this.rays.floor.origin.copyFrom(this.helper.getAbsolutePosition());
      this.rays.floor.direction.copyFrom(Vector3.DownReadOnly.scale(2));
      this.picks.floor = this.viewManager.scene
        .multiPickWithRay(this.rays.floor, this.collisionPredicate)
        ?.reduce((c, p) => (c.distance < p.distance ? c : p), INFINITE_PICK);

      if (!this.picks.floor) {
      } else if (this.picks.floor.distance === Infinity) {
        this.inJump = false;
      } else if (this.picks.floor.distance > 1) {
        this.inJump = false;
      } else {
        this.helper.position.y += Math.abs(1 - this.picks.floor.distance) + 0.02;
      }

      // Ceiling Check
      this.rays.ceiling.origin.copyFrom(this.helper.getAbsolutePosition());
      this.rays.ceiling.direction.copyFrom(Vector3.UpReadOnly.scale(2));
      this.picks.ceiling = this.viewManager.scene
        .multiPickWithRay(this.rays.ceiling, this.collisionPredicate)
        ?.reduce((c, p) => (c.distance < p.distance ? c : p), INFINITE_PICK);

      if (this.picks.ceiling?.distance && this.picks.ceiling.distance !== Infinity) {
        this.helper.position.y -= Math.min(Math.abs(1 - this.picks.ceiling.distance), 0.2) + 0.02;
        this.fallVelocity = 0;
        this.inJump = true;
      }

      // Facing check
      this.rays.facing.origin.copyFrom(this.helper.getAbsolutePosition());
      this.rays.facing.direction.copyFrom(this.movementVector.normalize().multiplyByFloats(1, 0, 1).normalize().scale(2));
      this.picks.facing = this.viewManager.scene
        .multiPickWithRay(this.rays.facing, this.collisionPredicate)
        ?.reduce((c, p) => (c.distance > p.distance ? c : p), MINUS_INFINITE_PICK);

      if (!this.picks.facing || this.picks.facing.distance === -Infinity) {
        // Heading Check
        this.rays.heading.origin.copyFrom(this.helper.getAbsolutePosition());
        this.rays.heading.direction.copyFrom(this.movementVector.multiplyByFloats(1, 0, 1).addInPlaceFromFloats(0, 1.4, 0));

        this.picks.heading = this.viewManager.scene
          .multiPickWithRay(this.rays.heading, this.collisionPredicate)
          ?.reduce((c, p) => (c.distance < p.distance ? c : p), INFINITE_PICK);

        if (this.picks.heading?.distance && this.picks.heading.distance !== Infinity) {
          this.inJump = true;
          this.helper.position.addInPlace(
            this.rays.heading.direction
              .multiplyByFloats(-1, 0, -1)
              .scale(this.rays.heading.direction.length() - this.picks.heading.distance),
          );
        }

        // Ladder check
        this.rays.knee.origin.copyFrom(this.helper.getAbsolutePosition());
        this.rays.knee.direction.copyFrom(this.movementVector.multiplyByFloats(1, 0, 1).normalize().addInPlaceFromFloats(0, -0.9, 0));
        this.picks.knee = this.viewManager.scene
          .multiPickWithRay(this.rays.knee, this.collisionPredicate)
          ?.reduce((c, p) => (c.distance < p.distance ? c : p), INFINITE_PICK);

        if (
          this.picks.knee?.distance &&
          this.picks.knee.distance !== Infinity &&
          (this.picks.floor?.distance ?? Infinity) > this.rays.knee.length - this.picks.knee?.distance
        ) {
          this.fallVelocity = 0.05;
          this.inJump = true;
        }
      } else if (this.picks.facing.distance > 0.2 && this.picks.facing.distance < 0.5) {
        this.helper.position.addInPlace(this.rays.facing.direction.multiplyByFloats(-0.025, 0, -0.025));
      }

      if (
        !(this.picks.ceiling && this.picks.floor) ||
        (this.picks.ceiling.distance === Infinity && this.picks.floor.distance === Infinity)
      ) {
        this.inJump = true;
      }

      if (mesh.animations?.length) {
        // Animation Check
        this.rays.animation.origin.copyFrom(this.helper.getAbsolutePosition());
        this.rays.animation.direction.copyFrom(this.helper.collider.intersectionPoint.normalize());
        this.picks.animation = this.viewManager.scene
          .multiPickWithRay(this.rays.animation, this.collisionPredicate)
          ?.reduce((c, p) => (c.distance < p.distance ? c : p), INFINITE_PICK);

        this.picks.animation?.distance &&
          this.picks.animation.distance < 1 &&
          this.helper.position.addInPlace(this.rays.animation.direction.scale((this.picks.animation.distance - 1) * 0.09));
      }
    };
    this.overInteraction = new Interaction(this.viewManager.scene, this.helper, ActionManager.OnPointerOverTrigger, undefined, true);
    this.outInteraction = new Interaction(this.viewManager.scene, this.helper, ActionManager.OnPointerOutTrigger, undefined, true);
  }

  collisionPredicate(mesh: AbstractMesh) {
    return (
      mesh?.isEnabled() &&
      !mesh.metadata?.box &&
      (mesh === this.viewManager.boundary ||
        ((COLLIDABLE_TYPES.includes(mesh.metadata?.helperOf?.element?.type) ||
          mesh.metadata?.helperOf?.element?.type === ElementType.Object) &&
          mesh.checkCollisions))
    );
  }

  jump() {
    if (!this.inJump) {
      this.runAnimation(AvatarAnimations.Jump);
      this.inJump = true;
      this.fallVelocity = this.fallVelocity > 0 ? this.fallVelocity + 0.1 : 0.1;
    }
  }

  showHelper() {
    this.helper.visibility = !!this.avatar ? 0.001 : 1;
    this.helper.isPickable = true;
  }

  hideHelper() {
    this.helper.visibility = 0;
    this.helper.isPickable = false;
  }

  updateElement() {
    this.viewManager.scene.onBeforeRenderObservable.addOnce(() => {
      this.viewManager.appService.updateViewElement({
        ...this.element,
        parameters: {
          ...this.element.parameters,
          position: [...this.helper.computeWorldMatrix(true).asArray().slice(12, 15)] as TCheckpointElement['parameters']['position'],
          quaternion: [...this.helper.rotationQuaternion!.asArray()] as TCheckpointElement['parameters']['quaternion'],
          avatar: this._validAvatarUrl,
        },
      });
    });
  }

  addAnimationGroup(name: string, animationGroup: AnimationGroup) {
    if (!this._animationGroups) {
      this._animationGroups = {};
    }
    if (!this._weights) {
      this._weights = {};
    }
    this._animationGroups[name]?.dispose();
    this._animationGroups[name] = animationGroup;
    this._weights[name] = 0;
    animationGroup.syncAllAnimationsWith(null);
    animationGroup.setWeightForAllAnimatables(this._weights[name]);
    animationGroup.start(true, 1, animationGroup.from, animationGroup.to);
  }

  syncAvatar() {
    if (!this.avatar) {
      return;
    }
    this.avatar.position.copyFrom(this.helper.position.subtractFromFloats(0, 1.02, 0));
    this.avatar.rotation.y = (this.helper.rotationQuaternion?.toEulerAngles() ?? this.helper.rotation).y;
  }

  walk(up: number, down: number, left: number, right: number) {
    if (this.inJump) {
      return;
    }
    if (left && !right) {
      this.runAnimation(AvatarAnimations.LeftWalk);
    } else if (right && !left) {
      this.runAnimation(AvatarAnimations.RightWalk);
    } else if (up && !down) {
      this.runAnimation(up > 0.05 ? AvatarAnimations.Run : AvatarAnimations.Walk);
    } else if (down && !up) {
      this.runAnimation(AvatarAnimations.WalkBack);
    } else {
      this.runAnimation(AvatarAnimations.Idle);
    }
  }

  animationCallback() {
    Object.entries(this._animationGroups).forEach(([key, group]) => {
      this._weights[key] = Math.min(Math.max(this._weights[key] + (key === this.activeAnimationName ? 0.16 : -0.16), 0), 1);
      group?.setWeightForAllAnimatables(this._weights[key]);
    });
    if (Object.entries(this._weights).every(([key, weight]) => weight === (key === this.activeAnimationName ? 1 : 0))) {
      this._observers.beforeAnimations && this.viewManager.scene.onBeforeAnimationsObservable.remove(this._observers.beforeAnimations);
    }
  }

  runAnimation(name: string) {
    if (!this._animationGroups?.[name] || this.activeAnimationName === name) {
      return;
    }
    this._animationGroups[name] &&
      name === AvatarAnimations.Jump &&
      this._animationGroups[name]!.goToFrame(this._animationGroups[name]!.from);
    Object.entries(this._animationGroups).forEach(([key, group]) => {
      group?.syncAllAnimationsWith(key === name ? null : group.animatables[0]);
    });
    this._observers.beforeAnimations && this.viewManager.scene.onBeforeAnimationsObservable.remove(this._observers.beforeAnimations);
    this.activeAnimationName = name;
    this._observers.beforeAnimations = this.viewManager.scene.onBeforeAnimationsObservable.add(this.animationCallback);
  }

  disposeAvatar() {
    if (!this.avatar) {
      return;
    }
    this._observers.worldMatrix && this.helper.onAfterWorldMatrixUpdateObservable.remove(this._observers.worldMatrix);
    this._observers.beforeAnimations && this.viewManager.scene.onBeforeAnimationsObservable.remove(this._observers.beforeAnimations);
    this._animationGroups &&
      Object.entries(this._animationGroups).forEach(([key, group]) => {
        group?.stop();
        group?.dispose();
        this._animationGroups[key] = null;
      });
    this.viewManager.lights.forEach((light) => {
      light.shadowGenerator?.removeShadowCaster(this.avatar as AbstractMesh);
    });
    this.avatar
      .getChildren(undefined, false)
      .concat(this.avatar)
      .forEach((child) => {
        child.setEnabled(false);
      });
    this.activeAnimationName = '';
    this.avatar = null;
  }

  dispose() {
    this.disposeAvatar();
    this.hideHelper();
    this.overInteraction?.unregister();
    this.outInteraction?.unregister();
    this.helper?.dispose();
  }
}
