import { inflate } from 'pako';

//This function converts a byte size into format (KB, MB, or GB) with one decimal point, based on predefined byte size constants.
export function convertBytesToGBOrMBOrKB(bytes: number, fractionDigits: number | undefined = undefined): string {
  const bytesInGB = 1073741824; // 1 GB = 1,073,741,824 bytes
  const bytesInMB = 1048576; // 1 MB = 1,048,576 bytes
  const bytesInKB = 1024; // 1 KB = 1,024 bytes

  if (bytes >= bytesInGB) {
    const gigabytes = bytes / bytesInGB;
    return gigabytes.toFixed(fractionDigits) + 'GB';
  } else if (bytes >= bytesInMB) {
    const megabytes = bytes / bytesInMB;
    return megabytes.toFixed(fractionDigits) + 'MB';
  } else {
    const kilobytes = bytes / bytesInKB;
    return kilobytes.toFixed(fractionDigits) + 'KB';
  }
}

export function convertBooleanToLowerString(boolean: boolean) {
  const stringValue = boolean ? 'true' : 'false';
  return stringValue.toLowerCase();
}

/**
 * Converts a hexidecimal string to a byte array
 * @param hex the hexidecimal string
 * @returns a byte array
 */
function hexToBytes(hex: string) {
  let bytes: number[] = [];
  for (let c = 0; c < hex.length; c += 2) {
    bytes.push(parseInt(hex.substring(c, c + 2), 16));
  }
  return bytes;
}
