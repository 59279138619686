import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ModalService } from '@/ui/src/lib/modal/modal.service';

@Component({
  selector: 'ui-m-confirmation',
  templateUrl: './m-confirmation.component.html',
  styleUrls: ['./m-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileConfirmationComponent implements OnInit {
  public body = '';
  public confirmAction = 'shared.confirmation.yes';
  public cancelAction = 'shared.confirmation.no';
  public showCancelAction = true;

  constructor(private _modalService: ModalService) {}

  ngOnInit(): void {}

  close(result: boolean) {
    this._modalService.close(result, this['modalContainerRef']);
  }
}
