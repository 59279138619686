@if (currentPage) {
  <div class="icons" [ngClass]="{ disabled: isFirstPageGroup }">
    <div class="icon-container" (click)="clickArrow('First')">
      <span class="icon icon-arrows-left-white"></span>
    </div>
    <div class="icon-container" (click)="clickArrow('BeforePageGroup')">
      <span class="icon icon-arrow-left-white"></span>
    </div>
  </div>
}
<div class="number-set">
  @for (pageNum of displayedPages; track pageNum) {
    <div class="number" [ngClass]="{ now: pageNum === currentPage }" (click)="clickNumber(pageNum)">
      {{ pageNum }}
    </div>
  }
</div>
@if (currentPage) {
  <div class="icons" [ngClass]="{ disabled: isLastPageGroup }">
    <div class="icon-container" (click)="clickArrow('NextPageGroup')">
      <div class="icon icon-arrow-right-white"></div>
    </div>
    <div class="icon-container" (click)="clickArrow('Last')">
      <div class="icon icon-arrows-right-white"></div>
    </div>
  </div>
}
