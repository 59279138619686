import { Injectable } from '@angular/core';
import { IModel, ModelType } from '../models/data/base';
import { AssetService } from './asset.service';
import { IApiService } from './base-api.service';
import { FileService } from './file.service';
import { SceneService } from './scene.service';
import { UserSceneService } from './user-scene.service';
import { UserSettingsService } from './user-settings.service';

/**
 * Tracks and manages the state of view related data and commits all changes to the backend in an order the won't impact SQL foreign keys dependencies
 */
@Injectable({
  providedIn: 'root',
})
export class DataCacheService {
  private serviceMap: Map<ModelType, IApiService> = new Map<ModelType, IApiService>();
  public isDirty = false;

  constructor(
    assetService: AssetService,
    sceneService: SceneService,
    fileReference: FileService,
    userSceneService: UserSceneService,
    userSettingService: UserSettingsService,
  ) {
    this.serviceMap.set(ModelType.Scene, sceneService);
    this.serviceMap.set(ModelType.Asset, assetService);
    this.serviceMap.set(ModelType.FileReference, fileReference);
    this.serviceMap.set(ModelType.UserScene, userSceneService);
    this.serviceMap.set(ModelType.UserSetting, userSettingService);
  }

  /**
   * Gets a model by its type and id
   * @param type model type
   * @param id model id
   * @param cached true if you only want to check the local cache
   * @returns the model
   */
  async get<T extends IModel>(type: ModelType, id: string, cached = false): Promise<T | undefined> {
    return (await this.serviceMap.get(type)?.getById(id, false, cached)) as T;
  }

  /**
   * Gets an array of models from an array of ids
   * @param type model type
   * @param ids ids to get
   * @returns array of models
   */
  async getMany<T extends IModel>(type: ModelType, ids: string[]): Promise<T[]> {
    const result = await Promise.all(ids.map((id) => this.get<T>(type, id)));
    return result.filter((r) => r !== undefined) as T[];
  }

  /**
   * Gets all models available to the user
   * @param type model type
   * @param cached true if you want to only get from the local cache
   * @returns an array of models
   */
  async getAll<T extends IModel>(type: ModelType, cached = true): Promise<T[] | undefined> {
    return (await this.serviceMap.get(type)?.getAll(cached)) as T[];
  }

  /**
   * Get all cached models of type
   * @param type
   * @returns
   */
  getCached<T extends IModel>(type: ModelType) {
    return this.serviceMap.get(type)?.getCached() as T[];
  }

  /**
   * Alternative to Get back can be called syncronously
   * @param type model type
   * @param id model id
   * @returns the model
   */
  getCachedById<T extends IModel>(type: ModelType, id: string) {
    return this.serviceMap.get(type)?.getCachedById(id) as T;
  }

  /**
   * Pushes the model onto the local cache
   * @param model
   */
  set(model: IModel | IModel[]) {
    if (!Array.isArray(model)) {
      model = [model];
    }
    if (model.length) {
      this.serviceMap.get(model[0].ModelType)?.set(model);
    }
  }

  /**
   * Attaches the model/s to the local data cache
   * @param model model or array of models
   */
  attach(model: IModel | IModel[]) {
    if (Array.isArray(model)) {
      model.forEach((m) => this.attach(m));
    } else {
      this.serviceMap.get(model.ModelType)?.attach(model);
    }
  }

  /**
   * Detaches the model/s from the local data cache
   * @param model model or array of models
   */
  detach(model: IModel | IModel[]) {
    if (Array.isArray(model)) {
      model.forEach((m) => this.detach(m));
    } else {
      this.serviceMap.get(model.ModelType)?.detach(model);
    }
  }

  /**
   * Adds the model to the local data cache
   * @param model
   */
  add(model: IModel) {
    this.isDirty = true;
    if (Array.isArray(model)) {
      model.forEach((m) => this.add(m));
    } else {
      this.serviceMap.get(model.ModelType)?.add(model);
    }
  }

  /**
   * removes the data from the local data cache
   * @param model
   */
  remove(model: IModel) {
    this.isDirty = true;
    if (Array.isArray(model)) {
      model.forEach((m) => this.remove(m));
    } else {
      this.serviceMap.get(model.ModelType)?.remove(model);
    }
  }

  /**
   * Flags a model as modified in the local data cache
   * @param model
   */
  modify(model: IModel) {
    this.isDirty = true;
    if (Array.isArray(model)) {
      model.forEach((m) => this.modify(m));
    } else {
      this.serviceMap.get(model.ModelType)?.modify(model);
    }
  }

  /**
   * sets the model as selected
   * @param model the model type
   * @param id id of model to select
   */
  select(model: ModelType, id: string | undefined) {
    if (id) {
      this.serviceMap.get(model)?.selectById(id);
    } else {
      this.serviceMap.get(model)?.select(undefined);
    }
  }

  /**
   * Commits all added, removed and updated data to the backend
   */
  async commitAll() {
    if (this.isDirty) {
      for (const service of this.serviceMap) {
        await service[1].commit();
      }
    }
    this.isDirty = false;
  }
}
