<input type="radio" [name]="group" [id]="id" [checked]="checked" (change)="checkValue($event, checked)" />

<label [for]="id" [class.disabled]="disabled">
  <div class="toggle">
    <div class="radio">
      <div class="selected"></div>
    </div>
  </div>
  <span>
    <ng-content></ng-content>
  </span>
</label>
