import { AccountService } from 'projects/data/src/lib/services/account.service';
import { Component, OnInit } from '@angular/core';
import { XRScene, XRSceneInteraction } from 'projects/data/src/lib/models/data/scene';
import { Account } from 'projects/data/src/lib/models/data/account';
import { DataCacheService } from 'projects/data/src/lib/services/data-cache.service';
import { ModalService } from '@/ui/src/lib/modal/modal.service';

@Component({
  selector: 'ui-m-guestbook-comment',
  templateUrl: './m-guestbook-comment.component.html',
  styleUrls: ['./m-guestbook-comment.component.scss'],
  providers: [ModalService],
})
export class MobileGuestbookCommentComponent implements OnInit {
  modalRef: ModalService;
  isExist = false;

  selectedSceneId = '';
  selectedScene: XRScene | undefined;
  activeAccount: Account | undefined = this._accountService.account;
  interactions!: XRSceneInteraction[];

  newComment: string = '';

  data: { [key: string]: string };

  constructor(
    private _accountService: AccountService,
    public dataCache: DataCacheService,
  ) {}

  async ngOnInit(): Promise<void> {}

  onClose(result: boolean) {
    this.modalRef.close(result);
  }
}
