import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Input() search!: string;
  @Input() placeholder = 'Search';
  @Input() canClear = false;
  @Output() searchChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  updateText(text: string) {
    this.searchChange.emit(text);
  }

  clearSearch() {
    this.search = '';
    this.updateText('');
  }
}
