<oxr-toolbar
  *ngIf="!onPlayerMode"
  [isPublishDisabled]="isPublishPageOpened || !activeScene || showCaptureZone || showCroppedImage || isEnterpriseCollaborator"
  [isEnterpriseCollaborator]="isEnterpriseCollaborator"
  [isSaveDisabled]="!dataCache.isDirty || !activeScene || showCaptureZone || showCroppedImage"
  (previewClicked)="setPlayerMode()"
  (thumbnailClicked)="onCaptureThumbnail()"
  (saveClicked)="onSave()"
  (publishClicked)="publish()"
>
  <div class="divider"></div>
  <div uiTooltip="{{ 'toolbarTooltips.snap' | translate }}" [tooltipPosition]="toolTipPositions.Below" tooltipClasses="tooltip">
    <div class="item" [class.active]="activeToolName === tools.Snap" [class.disabled]="!isInteractable" (click)="onSnap()">
      <div class="icon icon-snap"></div>
    </div>
  </div>
  <div class="divider"></div>
  <div uiTooltip="{{ 'toolbarTooltips.textbox' | translate }}" [tooltipPosition]="toolTipPositions.Below" tooltipClasses="tooltip">
    <div class="item" [class.active]="false" [class.disabled]="false" (click)="onTextbox()">
      <div class="icon icon-text"></div>
    </div>
  </div>
  <div uiTooltip="{{ 'toolbarTooltips.screen' | translate }}" [tooltipPosition]="toolTipPositions.Below" tooltipClasses="tooltip">
    <div class="item" [class.active]="false" [class.disabled]="false" (click)="onScreen()">
      <div class="icon icon-screen"></div>
    </div>
  </div>
  <div class="divider"></div>
  <!-- Popup-Contents -->
  <div
    class="dropdown-menu"
    [ngClass]="{ 'dropdown-menu-opened': dropdownMenu.popupContent }"
    (uiClickOutside)="hideDropdown('popupContent')"
  >
    <div class="dropdown-backdrop" (click)="hideDropdown('popupContent')"></div>
    <div uiTooltip="{{ 'toolbarTooltips.popupContent' | translate }}" [tooltipPosition]="toolTipPositions.Below" tooltipClasses="tooltip">
      <div class="item dropdown-menu-activator" [class.disabled]="!isInteractable" (click)="toggleDropdownMenu('popupContent')">
        <div class="icon icon-popup"></div>
      </div>
    </div>
    <div class="dropdown-menu-list">
      <div class="dropdown-menu-item" (click)="selectPopup()">
        <span class="text">{{ 'oxr.creatingSpace.clear' | translate }}</span>
      </div>
      <ng-container *ngFor="let type of popupTypes; trackBy: trackByFn">
        <div
          *ngIf="!!type; else emptyOption"
          class="dropdown-menu-item"
          [ngClass]="{
            selected: popup?.type === type
          }"
          (click)="selectPopup(type)"
        >
          <div [ngClass]="['icon', getPopupIcon(type)]"></div>
          <span class="tooltip ng-tooltip ng-tooltip-below"> {{ 'oxr.creatingSpace.' + type | translate }}</span>
        </div>
      </ng-container>
      <ng-template #emptyOption>
        <div class="dropdown-menu-item"></div>
      </ng-template>
    </div>
  </div>
  <!-- Effects -->
  <div
    class="dropdown-menu effect-dropdown-menu"
    [ngClass]="{ 'dropdown-menu-opened': dropdownMenu.effect }"
    (uiClickOutside)="hideDropdown('effect')"
  >
    <div class="dropdown-backdrop" (click)="hideDropdown('effect')"></div>

    <div uiTooltip="{{ 'toolbarTooltips.effects' | translate }}" [tooltipPosition]="toolTipPositions.Below" tooltipClasses="tooltip">
      <div class="item dropdown-menu-activator" [class.disabled]="!isInteractable" (click)="toggleDropdownMenu('effect')">
        <div class="icon icon-effects"></div>
      </div>
    </div>
    <div class="dropdown-menu-list">
      <div class="dropdown-menu-item" (click)="selectEffect()">
        <span class="text">{{ 'oxr.creatingSpace.clear' | translate }}</span>
      </div>
      <ng-container *ngFor="let type of effectTypes; trackBy: trackByFn">
        <div
          *ngIf="!!type; else emptyOption"
          class="dropdown-menu-item"
          [ngClass]="{
            disabled: isEffectDisabled(type),
            selected: effect?.type === type
          }"
          (click)="selectEffect(type)"
        >
          <div *ngIf="isEffectDisabled(type)" class="coming-soon">
            <div class="icon icon-union sm"></div>
            <div>{{ 'shared.fields.comingSoon' | translate }}</div>
          </div>
          <div [ngClass]="['icon', getEffectIcon(type)]"></div>
          <span class="tooltip ng-tooltip ng-tooltip-below"> {{ 'oxr.creatingSpace.panels.effect.options.' + type | translate }}</span>
        </div>
      </ng-container>
      <ng-template #emptyOption>
        <div class="dropdown-menu-item"></div>
      </ng-template>
    </div>
  </div>
  <div uiTooltip="{{ 'toolbarTooltips.customize' | translate }}" [tooltipPosition]="toolTipPositions.Below" tooltipClasses="tooltip">
    <div class="item" [class.disabled]="!customizeEnabled" (click)="onCustomize()">
      <div class="icon icon-edit"></div>
    </div>
  </div>
  <div class="divider"></div>
  <div uiTooltip="{{ 'toolbarTooltips.focus' | translate }}" [tooltipPosition]="toolTipPositions.Below" tooltipClasses="tooltip">
    <div class="item" [class.disabled]="!focusEnabled" (click)="onFocus()">
      <div class="icon icon-focus"></div>
    </div>
  </div>
  <div uiTooltip="{{ 'toolbarTooltips.delete' | translate }}" [tooltipPosition]="toolTipPositions.Below" tooltipClasses="tooltip">
    <div class="item" [class.disabled]="!deleteEnabled" (click)="onDelete()">
      <div class="icon icon-delete"></div>
    </div>
  </div>
</oxr-toolbar>

<ui-side-bar *ngIf="!onPlayerMode">
  <ui-side-bar-item
    icon="icon-360"
    text="sidenav.environment"
    [selected]="assetsMenu.Environment == selectedElementLibrary"
    (itemClick)="panelSelected(assetsMenu.Environment)"
  >
    <ui-assets-menu (close)="panelSelected(assetsMenu.None)">
      <ui-assets-menu-tab [selected]="true" header="sidenav.environment" *ngIf="assetsLoaded">
        <ui-assets-menu-card-list
          *ngIf="assetsMenu.Environment == selectedElementLibrary"
          [items]="assets.get(assetType.Environment) ?? []"
          [selectedMap]="assetMap"
          [progressMap]="progressMap"
          (selectionChange)="assetSelected($event)"
          (newSelection)="onAddObject(assetType.Environment)"
          (noneSelection)="noneSelected(assetType.Environment)"
        >
          <ui-assets-placement-info
            *ngIf="uploadAssetUsageLimit"
            [countOfUsedUploadedAssets]="countOfUsedUploadedAssets"
            [uploadAssetUsageLimit]="uploadAssetUsageLimit"
          ></ui-assets-placement-info>
        </ui-assets-menu-card-list>
      </ui-assets-menu-tab>
    </ui-assets-menu>
  </ui-side-bar-item>
  <ui-side-bar-item
    icon="icon-object"
    text="sidenav.3dModels"
    [selected]="assetsMenu.Object == selectedElementLibrary"
    (itemClick)="panelSelected(assetsMenu.Object)"
  >
    <ui-assets-menu (close)="panelSelected(assetsMenu.None)">
      <ui-assets-menu-tab
        [selected]="selectedElementType == objectType.Object"
        header="sidenav.object"
        *ngIf="assetsLoaded"
        (click)="selectedElementType = objectType.Object"
        (onTabClick)="onObjectTabChange(objectType.Object)"
      >
        <ui-assets-menu-card-list
          *ngIf="assetsMenu.Object == selectedElementLibrary && assetSource.Owned == selectedAssetSource"
          [items]="assets.get(assetType.Object) ?? []"
          [respectSelectedMap]="false"
          [showInitialize]="true"
          [progressMap]="progressMap"
          (newSelection)="onAddObject(assetType.Object)"
          (noneSelection)="noneSelected(assetType.Object)"
          (selectionChange)="assetSelected($event)"
          [disableSelection]="!!asset && !!progressMap[asset.Id]"
        >
          <ui-assets-placement-info
            *ngIf="uploadAssetUsageLimit"
            [countOfUsedUploadedAssets]="countOfUsedUploadedAssets"
            [uploadAssetUsageLimit]="uploadAssetUsageLimit"
          ></ui-assets-placement-info
        ></ui-assets-menu-card-list>
      </ui-assets-menu-tab>
      <ui-assets-menu-tab
        [selected]="selectedElementType == objectType.Stage"
        header="sidenav.stage"
        *ngIf="assetsLoaded"
        (onTabClick)="onObjectTabChange(objectType.Stage)"
      >
        <ui-assets-menu-card-list
          *ngIf="assetsMenu.Object == selectedElementLibrary"
          [items]="assets.get(assetType.Stage) ?? []"
          [respectSelectedMap]="false"
          [progressMap]="progressMap"
          (selectionChange)="assetSelected($event)"
          (newSelection)="onAddObject(assetType.Stage)"
          (noneSelection)="noneSelected(assetType.Stage)"
        >
          <ui-assets-placement-info
            *ngIf="uploadAssetUsageLimit"
            [countOfUsedUploadedAssets]="countOfUsedUploadedAssets"
            [uploadAssetUsageLimit]="uploadAssetUsageLimit"
          ></ui-assets-placement-info>
        </ui-assets-menu-card-list>
      </ui-assets-menu-tab>
      <ui-assets-menu-tab
        [selected]="selectedElementType == objectType.Landscape"
        header="sidenav.landscape"
        *ngIf="assetsLoaded"
        (onTabClick)="onObjectTabChange(objectType.Landscape)"
      >
        <ui-assets-menu-card-list
          *ngIf="assetsMenu.Object == selectedElementLibrary"
          [items]="assets.get(assetType.Landscape) ?? []"
          [respectSelectedMap]="false"
          [progressMap]="progressMap"
          (selectionChange)="assetSelected($event)"
          (newSelection)="onAddObject(assetType.Landscape)"
          (noneSelection)="noneSelected(assetType.Landscape)"
        >
          <ui-assets-placement-info
            *ngIf="uploadAssetUsageLimit"
            [countOfUsedUploadedAssets]="countOfUsedUploadedAssets"
            [uploadAssetUsageLimit]="uploadAssetUsageLimit"
          ></ui-assets-placement-info>
        </ui-assets-menu-card-list>
      </ui-assets-menu-tab>
    </ui-assets-menu>
  </ui-side-bar-item>
  <ui-side-bar-item
    icon="icon-media"
    text="sidenav.media"
    [selected]="assetsMenu.Image == selectedElementLibrary"
    (itemClick)="panelSelected(assetsMenu.Image)"
  >
    <ui-assets-menu (close)="panelSelected(assetsMenu.None)">
      <ui-assets-menu-tab
        [selected]="fileType.Image == selectedFileType"
        header="sidenav.photo"
        *ngIf="filesLoaded"
        (onTabClick)="onMediaTabChange(fileType.Image)"
      >
        <ui-assets-menu-card-list
          *ngIf="assetsMenu.Image == selectedElementLibrary && fileType.Image == selectedFileType"
          [items]="images"
          [isImages]="true"
          [respectSelectedMap]="false"
          [canDelete]="true"
          [progressMap]="progressMap"
          [canCheckMultiple]="true"
          (deleteItem)="onDeleteMedia($event)"
          (selectionChange)="fileSelected($event)"
          (newSelection)="uploadFile(fileType.Image)"
          (noneSelection)="noneSelected('image')"
          [allowAdd]="true"
          (onMultipleItemSelect)="setCheckedMedia($event)"
          [checkedItems]="checkedMedia"
        >
          <ui-media-storage-info
            *ngIf="activeScene && allocatedMedia"
            [scene]="activeScene"
            [checkedMedia]="checkedMedia"
            [allocatedMedia]="allocatedMedia"
            (delete)="onBulkDeleteMedia()"
            [isDeletingMedia]="isDeletingMedia"
          ></ui-media-storage-info>
        </ui-assets-menu-card-list>
      </ui-assets-menu-tab>
      <ui-assets-menu-tab
        [selected]="fileType.Video == selectedFileType"
        header="sidenav.video"
        *ngIf="filesLoaded"
        (onTabClick)="onMediaTabChange(fileType.Video)"
      >
        <ui-assets-menu-card-list
          *ngIf="assetsMenu.Image == selectedElementLibrary && fileType.Video == selectedFileType"
          [items]="videos"
          [isVideos]="true"
          [respectSelectedMap]="false"
          [canDelete]="true"
          [progressMap]="progressMap"
          [canCheckMultiple]="true"
          (deleteItem)="onDeleteMedia($event)"
          (selectionChange)="fileSelected($event)"
          (newSelection)="uploadFile(fileType.Video)"
          (noneSelection)="noneSelected('video')"
          (onMultipleItemSelect)="setCheckedMedia($event)"
          [checkedItems]="checkedMedia"
        >
          <ui-media-storage-info
            *ngIf="activeScene && allocatedMedia"
            [scene]="activeScene"
            [checkedMedia]="checkedMedia"
            [allocatedMedia]="allocatedMedia"
            (delete)="onBulkDeleteMedia()"
            [isDeletingMedia]="isDeletingMedia"
          ></ui-media-storage-info>
        </ui-assets-menu-card-list>
      </ui-assets-menu-tab>
    </ui-assets-menu>
  </ui-side-bar-item>

  <ui-side-bar-item
    icon="icon-ambient-light"
    text="sidenav.lights"
    [selected]="assetsMenu.Light == selectedElementLibrary"
    (itemClick)="panelSelected(assetsMenu.Light)"
  >
    <ui-assets-menu (close)="panelSelected(assetsMenu.None)">
      <ui-assets-menu-tab [selected]="true" header="sidenav.lights">
        <ui-assets-menu-card-list
          *ngIf="assetsMenu.Light == selectedElementLibrary"
          [items]="lights"
          [showSearch]="false"
          [allowAdd]="false"
          [showAddNew]="false"
          [allowReselect]="true"
          [showSelectNone]="false"
          [isLight]="true"
          [sort]="false"
          (selectionChange)="lightSelected($event)"
        ></ui-assets-menu-card-list>
      </ui-assets-menu-tab>
    </ui-assets-menu>
  </ui-side-bar-item>

  <ui-side-bar-item
    bottom
    icon="icon-settings"
    text="oxr.settings.title"
    [selected]="(isSettingsPanelOpened$ | async) ?? false"
    (itemClick)="openSettings()"
  >
  </ui-side-bar-item>
</ui-side-bar>

<ui-body class="p-0">
  <ng-container *ngIf="activeScene">
    <input
      [id]="fileType.Image"
      type="file"
      style="display: none"
      multiple
      accept="image/jpg, image/jpeg, image/png, image/gif"
      (change)="onFileInputChange($event, fileType.Image)"
    />
    <input
      [id]="fileType.Video"
      type="file"
      style="display: none"
      accept="video/mp4, application/x-mpegURL, video/MP2T, video/x-flv, video/3gpp, video/quicktime, video/x-msvideo, video/x-ms-wmv"
      (change)="onFileInputChange($event, fileType.Video)"
    />

    <view-canvas #viewCanvas [activeModel]="activeScene" [readonly]="false"></view-canvas>

    <ng-container *ngIf="onPlayerMode">
      <div class="crosshair" [ngClass]="{ 'active-target': hoverActive$ | async }"></div>
      <ng-container *ngIf="supportsXR">
        <div class="floating left" [style.bottom.px]="30" (click)="setVRMode()">
          <div class="icon icon-vr"></div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="showManual">
      <app-oxr-manual (closeManual)="showManual = false" (noShowAgain)="noShowAgainManual()"></app-oxr-manual>
    </ng-container>

    <ng-container *ngIf="showManualButton">
      <div class="floating right" [style.bottom.px]="90" (click)="showManual = true">
        <span>{{ 'oxr.information.openManual' | translate }}</span>
        <div class="icon icon-eye"></div>
      </div>
    </ng-container>

    <div class="floating right" [style.bottom.px]="30" (click)="showEditorControls()">
      <p>{{ (onPlayerMode ? 'oxr.information.escToExit' : 'oxr.information.escToShowControls') | translate }}</p>
      <div class="key">ESC</div>
      <p>{{ (onPlayerMode ? 'oxr.information.escToExit2' : 'oxr.information.escToShowControls2') | translate }}</p>
    </div>

    <ng-container *ngIf="coords">
      <div class="checkpoint-info" [style.left.px]="coords.x" [style.top.px]="coords.y">
        <p>{{ 'oxr.information.startingPoint' | translate }}</p>
        <p>{{ 'oxr.information.startingPointDescription' | translate }}</p>
      </div>
    </ng-container>
  </ng-container>

  <section *ngIf="showCaptureZone" #captureZone class="capture-zone icon-capture">
    <span class="help-text">{{ 'editor.creatingAsset.pleaseFocus' | translate }}</span>
  </section>
  <section *ngIf="showCaptureZone" class="capture-button-group">
    <button ui-outlined-button secondary sm (click)="exitCaptureMode()">
      {{ 'shared.actions.cancel' | translate }}
    </button>
    <button ui-button sm (click)="captureScreen()">
      {{ 'editor.creatingAsset.shoot' | translate }}
    </button>
  </section>

  <img #uncroppedImage class="uncropped-image" [src]="captureImage" />
  <ng-container *ngIf="showCroppedImage">
    <div class="preview-image-container">
      <img class="preview-image" [src]="croppedImage" />
    </div>
    <div class="capture-button-group">
      <button ui-outlined-button secondary sm [disabled]="imageLoading" (click)="onThumbnailRetake()">
        {{ 'editor.creatingAsset.retake' | translate }}
      </button>
      <button ui-button sm [disabled]="imageLoading" (click)="onThumbnailSave()">{{ 'shared.actions.save' | translate }}</button>
    </div>
  </ng-container>
</ui-body>

<ui-panels-menu *ngIf="!onPlayerMode" [canOpen]="isAllowedPanelToggling">
  <ng-container *ngIf="onSelectTool">
    <ui-environment-panel *ngIf="showEnvironmentPanel"></ui-environment-panel>
    <ui-avatar-panel *ngIf="showAvatarPanel"></ui-avatar-panel>
    <ui-text-panel *ngIf="showTextPanel"></ui-text-panel>
    <ui-transformation-panel *ngIf="showTransformationPanel" [element]="selectedElement"></ui-transformation-panel>
    <ui-scene-interactions-panel *ngIf="showSceneInteractionsPanel" [element]="selectedElement"></ui-scene-interactions-panel>
    <ui-media-panel *ngIf="showMediaPanel" [element]="selectedElement"></ui-media-panel>
    <ui-screen-panel *ngIf="showMediaPanel" [element]="selectedElement"></ui-screen-panel>
    <ui-popup-content-panel *ngIf="showPopupContentPanel" [element]="selectedElement"></ui-popup-content-panel>
    <ui-effect-panel
      *ngIf="showEffectPanel"
      [element]="selectedElement"
      [isScenePlanPropertyRestriction]="activeScene!.Plan"
    ></ui-effect-panel>
    <ui-light-panel *ngIf="showLightPanel" [element]="selectedElement"></ui-light-panel>
    <!-- <ui-stage-panel *ngIf="false"></ui-stage-panel>
    <ui-landscape-panel *ngIf="false"></ui-landscape-panel>-->
  </ng-container>
  <ng-container *ngIf="onCustomizeTool">
    <ui-materials-panel *ngIf="showMaterialsPanel" [material]="selectedMaterial"></ui-materials-panel>
    <ui-color-panel *ngIf="showColorPanel" [element]="selectedElement" [material]="selectedMaterial"></ui-color-panel>
  </ng-container>
</ui-panels-menu>

<oxr-publish
  *ngIf="activeScene"
  [class.opened]="isPublishPageOpened"
  [scene]="activeScene"
  (onClose)="isPublishPageOpened = false"
></oxr-publish>

<ui-oxr-settings (onSceneDelete)="onSceneDelete($event)"></ui-oxr-settings>

<div class="loading-overlay" *ngIf="isLoading">
  <ui-loader></ui-loader>
</div>
