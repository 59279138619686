<div class="container">
  <div class="header">
    <div class="domain">
      {{ 'domain.list.domain' | translate }}
    </div>
    <div class="connected-space">
      {{ 'domain.list.connectedSpace' | translate }}
    </div>
    <div class="expired-date">
      {{ 'domain.list.expiredDate' | translate }}
    </div>
    <div class="state">
      {{ 'domain.list.state.state' | translate }}
    </div>
    <div class="setting">
      {{ 'domain.list.setting' | translate }}
    </div>
  </div>
  @for (domain of domains; track domain) {
    <div class="line">
      <div class="domain">{{ domain.Path }}</div>
      @if (domain.SceneModel) {
        <div class="connected-space">{{ domain.SceneModel.Name }}</div>
      }
      @if (!domain.SceneModel) {
        <div class="none-connected-space">{{ 'domain.list.noneConnectedSpace' | translate }}</div>
      }
      @if (domain.SceneModel!.Expires) {
        <div class="expired-date">
          {{ domain.SceneModel?.Expires | date: 'yyyy.MM.dd' }}
          <div
            class="badge expiration-date"
            [ngClass]="{
              black: domain.DaysLeft! == 0,
              red: domain.DaysLeft! > 0 && domain.DaysLeft! <= 30,
              yellow: domain.DaysLeft! > 30 && domain.DaysLeft! <= 90,
              green: domain.DaysLeft! > 90
            }"
          >
            D-{{ domain.DaysLeft }}
          </div>
        </div>
      }
      @if (!domain.SceneModel!.Expires) {
        <div class="expired-date">-</div>
      }
      <div class="state">
        <div class="badge state" [ngClass]="domain.Status">
          {{ 'domain.list.state.' + domain.Status | translate }}
        </div>
      </div>
      <div class="setting">
        @if (loadingArray.indexOf(domain.Id) === -1) {
          <div class="icon icon-view-more sm" (click)="onClickSetting(domain)"></div>
          @if (selectedDomain?.Id === domain.Id) {
            <div class="options" (uiClickOutside)="selectedDomain = undefined" [draggable]="false">
              @if (domain.Status === 'connected') {
                <div class="option" (click)="onClickOption('view')">{{ 'domain.list.options.view' | translate }}</div>
                <div class="option" (click)="onClickOption('changeSpace')">{{ 'domain.list.options.changeSpace' | translate }}</div>
                <div class="option disable">{{ 'domain.list.options.extension' | translate }}</div>
              }
              @if (domain.Status === 'connecting' || domain.Status === 'error') {
                <div class="option disable">{{ 'domain.list.options.view' | translate }}</div>
                <div class="option disable">{{ 'domain.list.options.changeSpace' | translate }}</div>
                <div class="option disable">{{ 'domain.list.options.extension' | translate }}</div>
              }
              @if (domain.Status === 'expired' || domain.Status === 'disabled') {
                <div class="option disable">{{ 'domain.list.options.view' | translate }}</div>
                <div class="option disable">{{ 'domain.list.options.changeSpace' | translate }}</div>
                <div class="option" (click)="onClickOption('extension')">{{ 'domain.list.options.extension' | translate }}</div>
              }
              <div class="option" (click)="onClickOption('dnsSettings')" [ngClass]="{ disable: domain.DomainType === 'Subdomain' }">
                {{ 'domain.list.options.dnsSettings' | translate }}
              </div>
              <div class="option" (click)="onClickOption('delete')">{{ 'domain.list.options.delete' | translate }}</div>
            </div>
          }
        } @else {
          <ui-loader></ui-loader>
        }
      </div>
    </div>
  }
</div>
