<ui-panel
  [title]="'oxr.creatingSpace.panels.assetVersions.title' | translate: { count: asset?.Versions?.length || 0, max: MAX_VERSIONS }"
  [collapsing]="true"
  [panelId]="panelMask.AssetVersions"
>
  <div class="fields">
    <label for="asset-version-name">{{ 'shared.fields.name' | translate }} </label>
    <input uiInput id="asset-version-name" type="text" [(ngModel)]="versionName" (focusout)="onChangeEnd()" />
    <p>{{ 'shared.fields.dateCreated' | translate }}: {{ selectedVersion?.DateCreated?.substr(0, 10) }}</p>
    <p>{{ 'shared.fields.dateModified' | translate }}: {{ selectedVersion?.DateModified?.substr(0, 10) }}</p>
  </div>
  <div class="separator"></div>
  <ui-radio-group [options]="versionOptions" [value]="selectedVersion?.Id" (onChecked)="onVersionSelected($event)"></ui-radio-group>
</ui-panel>
