@if (!isNewCard) {
  <div class="display-card">
    <!-- <input class="selection-checkbox big" type="checkbox" *ngIf="canSelect" (change)="toggleSelection($event)" /> -->
    <section>
      @if (canSelect) {
        <ui-check-box class="selection-checkbox primary sm" [checked]="isSelected" (checkedChange)="toggleSelection($event)"></ui-check-box>
      }
      @if (scenePlan) {
        <div
          class="pricing-plan"
          [ngClass]="{
            enterprise: scenePlan === plans.Enterprise,
            free: scenePlan === plans.Free,
            pro: scenePlan === plans.Pro
          }"
        >
          <div class="icon"></div>
          <div class="text">{{ scenePlan }}</div>
        </div>
      }
      @if (chatOn) {
        <div class="chat-on">Live chat</div>
      }
      <div class="image" (mousedown)="onImageClick($event)">
        @if (imageSrc) {
          <img [src]="imageSrc" />
        }
      </div>
      <ng-content></ng-content>
    </section>
    @if (isExpired && !isEnterpriseOrAdmin) {
      <div class="locked-section">
        <div class="icon icon-lock"></div>
        <div class="text" [innerHtml]="'oxr.dashboard.resubscribeBackDrop' | translate"></div>
        <div class="function" (click)="onResubscriptionClick()">{{ 'oxr.dashboard.resubscribe' | translate }}</div>
      </div>
    }
  </div>
} @else {
  <div class="new-card" [class.disabled]="!canCreate" (click)="onImageClick($event)">
    <div class="icon icon-plus xl"></div>
    @if (!canCreate) {
      <div class="locked-section">
        <div class="icon icon-lock"></div>
        <div class="text" [innerHtml]="'oxr.dashboard.addSpaceInFullVersion' | translate"></div>
        <div class="preview" (click)="onPreviewClick()">{{ 'oxr.dashboard.planPreview' | translate }}</div>
      </div>
    }
  </div>
}
