import { v4 as uuid } from 'uuid';
import { XRAsset, XRAssetVersion } from '../../models/data/asset';
import { deepCopy } from '../../utils/data';
import {
  ActionManager,
  AnimationGroup,
  ISceneLoaderAsyncResult,
  Mesh,
  MeshBuilder,
  Nullable,
  Quaternion,
  SceneLoader,
  Skeleton,
  TransformNode,
  Vector3,
} from '../../babylon';
import { ViewManager } from '..';
import { TCustomization, TElement, TObjectElement, ViewMode } from '../types';
import { OBJECT_POSITION, OBJECT_QUATERNION } from '../constants';
import { Interaction } from '../auxiliaries/interaction';
import { Customization } from '../auxiliaries/customization';
import { InteractableRenderable } from './interactable-renderable';
import { ViewManagerUtils } from '../../utils/view-manager-utils';
import { AssetStatus } from '@/view/src/app/app.service';

export class ObjectRenderable extends InteractableRenderable {
  public elementId: string;
  public helper: Mesh;
  public assetVersion: XRAssetVersion | undefined;
  public customization?: Customization;
  public assetError?: boolean;
  public polygonCount = 0;

  protected overInteraction: Interaction;
  protected outInteraction: Interaction;

  private _customization?: TCustomization;
  private _tempCustomization?: TCustomization;

  private _initialScaling = Vector3.One();
  private _animationGroups: { [key: string]: Nullable<AnimationGroup> };
  private _sourceIdToClone = {};
  private _cloneIdToSkeleton: { [key: number]: Skeleton } = {};
  private _targetIdToTarget = {};

  constructor(
    element: TObjectElement,
    public viewManager: ViewManager,
    file: File | string | Mesh | undefined = undefined,
  ) {
    super(element, viewManager);
    this.load = this.load.bind(this);
    this.updateElement = this.updateElement.bind(this);
    this.load(this, file).then(() => {
      this.update();
    });
  }

  get asset() {
    return this.assetVersion?.asset;
  }

  get element() {
    return this._element as TObjectElement;
  }

  set element(payload) {
    this._element = deepCopy(payload) as TObjectElement;
  }

  async load(_this?: ObjectRenderable, source: File | string | Mesh | undefined = undefined) {
    const self = _this ?? this;

    let meshSubject: ISceneLoaderAsyncResult | undefined;
    const isEditor = self.viewManager.activeMode === ViewMode.Editor;
    if (source && !(source instanceof Mesh)) {
      const meshes: any = '';
      let progress = 0;
      self.viewManager.appService.updateProgressMap(self.viewManager.model as XRAsset, 0);
      meshSubject = await SceneLoader.ImportMeshAsync(meshes, 'file://', source, self.viewManager.scene, (event) => {
        progress = (event.loaded / event.total) * 100;
        self.viewManager.appService.updateProgressMap(self.viewManager.model as XRAsset, progress);
      }).catch(() => {
        this.viewManager.appService.showNotification('errorLoadingAsset');
        return null as any as ISceneLoaderAsyncResult;
      });
      self.viewManager.appService.updateProgressMap(self.viewManager.model as XRAsset, 100);
      self.viewManager.appService.updateAssetStatusMap(self.viewManager.model as XRAsset, AssetStatus.Ready);
    } else {
      if (self.element.parameters.assetVersionId) {
        self.assetVersion = await self.viewManager.appService.apiv2.getAssetVersionById(self.element.parameters.assetVersionId);
      }
      if (self.asset) {
        meshSubject = await self.viewManager.appService.loadObjectAsset(self.asset);
      } else {
        this.assetError = true;
      }
    }

    if (!meshSubject?.meshes?.length) {
      this.assetError = true;
    } else {
      if (isEditor) {
        self.helper = meshSubject.meshes[0] as Mesh;
      } else {
        meshSubject.meshes[0].setEnabled(false);
        self.helper = meshSubject.meshes[0].instantiateHierarchy(null, { doNotInstantiate: true }, (source, clone) => {
          clone.id = `${uuid()}.${source.name}`;
          clone.name = clone.id;
          self._sourceIdToClone[source.uniqueId] = clone;
          if (source instanceof Mesh && clone instanceof Mesh && source.morphTargetManager) {
            clone.morphTargetManager = source.morphTargetManager.clone();
            while (clone.morphTargetManager.numTargets > 0) {
              clone.morphTargetManager.removeTarget(clone.morphTargetManager.getTarget(0));
            }
            for (let i = 0; i < source.morphTargetManager.numTargets; i++) {
              const target = source.morphTargetManager.getTarget(i);
              self._targetIdToTarget[target.uniqueId] = target.clone();
              clone.morphTargetManager.addTarget(self._targetIdToTarget[target.uniqueId]);
            }
          }
        }) as Mesh;
        self.helper.setEnabled(true);

        self.helper
          .getChildMeshes(false)
          .concat(self.helper)
          .forEach((mesh) => {
            self.polygonCount += (mesh?.getTotalIndices() ?? 0) / 3;
            if (mesh.skeleton) {
              if (!self._cloneIdToSkeleton[mesh.skeleton.uniqueId]) {
                self._cloneIdToSkeleton[mesh.skeleton.uniqueId] = mesh.skeleton.clone(uuid());
                self._cloneIdToSkeleton[mesh.skeleton.uniqueId].bones.forEach((bone) => {
                  if (bone._linkedTransformNode) {
                    bone._linkedTransformNode = self._sourceIdToClone[bone._linkedTransformNode.uniqueId];
                  }
                });
              }
              mesh.skeleton = self._cloneIdToSkeleton[mesh.skeleton.uniqueId];
            }
          });
        if (self.polygonCount > 100000) {
          self.boundingDimensions.copyFrom(self.viewManager.getMeshBounds(self.helper));
          self.box = MeshBuilder.CreateBox(
            `Box-${uuid()}`,
            { width: self.boundingDimensions.x, height: self.boundingDimensions.y, depth: self.boundingDimensions.z },
            self.viewManager.scene,
          );
          self.box.position.copyFrom(self.viewManager.boundingHelper.boundingPosition);
          self.box.setParent(self.helper);
          self.box.visibility = 0.001;
        }

        meshSubject.animationGroups.forEach((group) => {
          group.stop();
          if (!self._animationGroups) {
            self._animationGroups = {};
          }
          self._animationGroups[group.name]?.dispose();
          self._animationGroups[group.name] = group.clone(`${group.name}-${uuid()}`, (oldTarget) =>
            oldTarget instanceof TransformNode ? self._sourceIdToClone[oldTarget.uniqueId] : self._targetIdToTarget[oldTarget.uniqueId],
          );
        });
        self._animationGroups && Object.values(self._animationGroups)[0]?.play(true);
      }
      ViewManagerUtils.ProcessRenderableHierarchy(self, {
        checkCollisions: !isEditor && !!self.element.parameters.collisions,
        isPickable: false,
        isEditor,
        box: self.box,
      });
      self._initialScaling.copyFrom(self.helper.scaling);

      self.viewManager.lights.forEach((light) => {
        light.update();
      });

      self.overInteraction = new Interaction(self.viewManager.scene, self.helper, ActionManager.OnPointerOverTrigger, undefined, true);
      self.outInteraction = new Interaction(self.viewManager.scene, self.helper, ActionManager.OnPointerOutTrigger, undefined, true);

      self.viewManager?.tool?.update();
      isEditor &&
        self.viewManager.scene.onAfterRenderObservable.addOnce(() => {
          self.viewManager.refresh();
        });
    }
  }

  update(element?: TElement) {
    if (element) {
      this.element = deepCopy(element as TObjectElement);
    }

    if (this.assetError) {
      this.viewManager.appService.removeViewElements([this.element]);
      console.warn(`MISSING ASSET! Name: "${this.element.name}" Version ID: ${this.element.parameters.assetVersionId}`);
      return;
    }

    const dirty = this.assetVersion?.Id !== this.element.parameters.assetVersionId;
    if (dirty) {
      this.load().then(() => {
        // TODO: Make asset transformations persistent
        this.viewManager.activeMode === ViewMode.Editor && this.updateElement();

        this.update();
      });
      return;
    }

    if (this.helper) {
      this.helper.setEnabled(true);
      this.helper.position.set(...(this.element.parameters?.position ?? OBJECT_POSITION));
      if (this.helper.rotationQuaternion) {
        this.helper.rotationQuaternion.copyFromFloats(...(this.element.parameters.quaternion ?? OBJECT_QUATERNION));
      } else {
        this.helper.rotationQuaternion = Quaternion.FromArray(this.element.parameters.quaternion ?? OBJECT_QUATERNION);
      }
      this.helper.scaling.fromArray(this.element.parameters?.scaling ?? this._initialScaling.asArray());

      this._tempCustomization = deepCopy(this.element.parameters.customization);
      if (this._tempCustomization?.materials && !!Object.keys(this._tempCustomization?.materials).length) {
        if (this.customization) {
          ViewManagerUtils.PropsDiff(this._customization ?? {}, this._tempCustomization).length &&
            this.customization.update(this, this._tempCustomization);
        } else {
          this.customization = new Customization(this, this._tempCustomization);
        }
      } else {
        this.disposeCustomization();
      }
      this._customization = this._tempCustomization;

      super.update();
    }
  }

  updateElement() {
    this.viewManager.scene.onBeforeRenderObservable.addOnce(() => {
      ObjectRenderable.UpdateViewElement(this);
    });
  }

  disposeCustomization() {
    this._customization = undefined;
    this._tempCustomization = undefined;
    this.customization?.dispose();
    this.customization = undefined;
  }

  dispose(hard = false) {
    this.disposeCustomization();
    super.dispose();
    this.overInteraction?.unregister();
    this.outInteraction?.unregister();
    this._animationGroups &&
      Object.entries(this._animationGroups).forEach(([key, group]) => {
        group?.stop();
        group?.dispose();
        this._animationGroups[key] = null;
      });
    Object.keys(this._sourceIdToClone).forEach((key) => {
      this._sourceIdToClone[key]?.dispose();
      this._sourceIdToClone[key] = null;
    });
    Object.keys(this._cloneIdToSkeleton).forEach((key) => {
      this._cloneIdToSkeleton[key]?.dispose();
      this._cloneIdToSkeleton[key] = null;
    });
    this.helper &&
      this.viewManager.lights.forEach((light) => {
        light.shadowGenerator?.removeShadowCaster(this.helper);
      });
    hard
      ? this.helper?.dispose()
      : this.helper
          ?.getChildren(undefined, false)
          .concat(this.helper)
          .forEach((child) => {
            child.setEnabled(false);
          });
  }

  // TODO: Make asset transformations persistent
  static UpdateViewElement(self: ObjectRenderable) {
    if (!self.helper) {
      return;
    }
    self.viewManager.appService.updateViewElement({
      ...self.element,
      parameters: {
        ...self.element.parameters,
        customization: self.customization?.descriptor,
        position: [...self.helper.computeWorldMatrix(true).asArray().slice(12, 15)] as TObjectElement['parameters']['position'],
        quaternion: [
          ...(self.helper.rotationQuaternion ?? self.helper.rotation.toQuaternion()).asArray(),
        ] as TObjectElement['parameters']['quaternion'],
        scaling: self.helper.scaling.asArray() as TObjectElement['parameters']['scaling'],
        // scaling: this.helper.scaling.divide(this._initialScaling).asArray() as TObjectElement['parameters']['scaling'],
      },
    });
  }
}
