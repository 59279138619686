import { Color3 } from "@babylonjs/core/Maths/math.color.js";
import { PBRMaterial } from "@babylonjs/core/Materials/PBR/pbrMaterial.js";
import { GLTFLoader } from "../glTFLoader.js";
const NAME = "KHR_materials_pbrSpecularGlossiness";
/**
 * [Specification](https://github.com/KhronosGroup/glTF/blob/main/extensions/2.0/Archived/KHR_materials_pbrSpecularGlossiness/README.md)
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class KHR_materials_pbrSpecularGlossiness {
  /**
   * @internal
   */
  constructor(loader) {
    /**
     * The name of this extension.
     */
    this.name = NAME;
    /**
     * Defines a number that determines the order the extensions are applied.
     */
    this.order = 200;
    this._loader = loader;
    this.enabled = this._loader.isExtensionUsed(NAME);
  }
  /** @internal */
  dispose() {
    this._loader = null;
  }
  /**
   * @internal
   */
  loadMaterialPropertiesAsync(context, material, babylonMaterial) {
    return GLTFLoader.LoadExtensionAsync(context, material, this.name, (extensionContext, extension) => {
      const promises = new Array();
      promises.push(this._loader.loadMaterialBasePropertiesAsync(context, material, babylonMaterial));
      promises.push(this._loadSpecularGlossinessPropertiesAsync(extensionContext, material, extension, babylonMaterial));
      this._loader.loadMaterialAlphaProperties(context, material, babylonMaterial);
      return Promise.all(promises).then(() => {});
    });
  }
  _loadSpecularGlossinessPropertiesAsync(context, material, properties, babylonMaterial) {
    if (!(babylonMaterial instanceof PBRMaterial)) {
      throw new Error(`${context}: Material type not supported`);
    }
    const promises = new Array();
    babylonMaterial.metallic = null;
    babylonMaterial.roughness = null;
    if (properties.diffuseFactor) {
      babylonMaterial.albedoColor = Color3.FromArray(properties.diffuseFactor);
      babylonMaterial.alpha = properties.diffuseFactor[3];
    } else {
      babylonMaterial.albedoColor = Color3.White();
    }
    babylonMaterial.reflectivityColor = properties.specularFactor ? Color3.FromArray(properties.specularFactor) : Color3.White();
    babylonMaterial.microSurface = properties.glossinessFactor == undefined ? 1 : properties.glossinessFactor;
    if (properties.diffuseTexture) {
      promises.push(this._loader.loadTextureInfoAsync(`${context}/diffuseTexture`, properties.diffuseTexture, texture => {
        texture.name = `${babylonMaterial.name} (Diffuse)`;
        babylonMaterial.albedoTexture = texture;
      }));
    }
    if (properties.specularGlossinessTexture) {
      promises.push(this._loader.loadTextureInfoAsync(`${context}/specularGlossinessTexture`, properties.specularGlossinessTexture, texture => {
        texture.name = `${babylonMaterial.name} (Specular Glossiness)`;
        babylonMaterial.reflectivityTexture = texture;
        babylonMaterial.reflectivityTexture.hasAlpha = true;
      }));
      babylonMaterial.useMicroSurfaceFromReflectivityMapAlpha = true;
    }
    return Promise.all(promises).then(() => {});
  }
}
GLTFLoader.RegisterExtension(NAME, loader => new KHR_materials_pbrSpecularGlossiness(loader));
