import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationType } from 'projects/data/src/lib/enums/notification-type';
import { XRNotification } from 'projects/data/src/lib/models/data/notification';

@Component({
  selector: 'ui-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnInit {
  notificationTypes = NotificationType;

  @Input() notification!: XRNotification;

  @Output() clicked = new EventEmitter<void>();
  @Output() deleted = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onClick(): void {
    this.clicked.emit();
  }

  onDelete(): void {
    this.deleted.emit();
  }
}
