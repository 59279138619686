import { AbstractMesh, BoundingBoxGizmo, BoundingInfo, Matrix, Plane, Quaternion, Vector3 } from '@/data/src/lib/babylon';

export class BoundingHelper extends BoundingBoxGizmo {
  constructor() {
    super();
    this.setEnabledScaling(false);
    this.setEnabledRotationAxis('');
    this._lineBoundingBox.setEnabled(false);
  }

  get boundingPosition() {
    return this._lineBoundingBox.position;
  }

  get boundingDimensions() {
    return this._boundingDimensions;
  }
}

/**
 * Projects a point onto a plane and saves the result back into he input point
 * @param point point to project
 * @param plane projection plane
 */
export function projectOnPlane(point: Vector3, plane: Plane) {
  const multiplier = -(Vector3.Dot(point, plane.normal) + plane.d);
  const pv = new Vector3(plane.normal.x * multiplier, plane.normal.y * multiplier, plane.normal.z * multiplier);
  const result = point.add(pv);
  point.x = result.x;
  point.y = result.y;
  point.z = result.z;
}

/**
 * Snaps a point to the nearest cubic grid
 * @param point reference point to snap
 * @param gridLength size of edge in the grid cube
 */
export function snapPoint(point: Vector3, gridLength: number) {
  point.x = Math.round(point.x / gridLength) * gridLength;
  point.y = Math.round(point.y / gridLength) * gridLength;
  point.z = Math.round(point.z / gridLength) * gridLength;
}

/**
 * Gets the bounding box of a mesh
 * @param mesh mesh to calculate the bounding box
 * @param setBoundingInfo if true, sets the bounding info of the mesh
 * @returns the bounding box
 */
export function computeBoundingInfo(mesh: AbstractMesh, setBoundingInfo = true) {
  let min: Vector3 | undefined;
  let max: Vector3 | undefined;

  mesh.getChildMeshes(false).forEach((geometry) => {
    geometry.refreshBoundingInfo();
    const boundingBox = geometry.getBoundingInfo().boundingBox;

    if (!min) {
      min = Vector3.FromArray(boundingBox.minimumWorld.asArray());
    }
    if (!max) {
      max = Vector3.FromArray(boundingBox.maximumWorld.asArray());
    }

    min = Vector3.Minimize(min, boundingBox.minimumWorld);
    max = Vector3.Maximize(max, boundingBox.maximumWorld);
  });

  if (min && max) {
    const boundingInfo = new BoundingInfo(min, max);
    setBoundingInfo && mesh.setBoundingInfo(boundingInfo);
    return boundingInfo;
  }
  return mesh.getBoundingInfo();
}

/**
 * Transforms an array of points by a transformation matrix
 * @param matrix matrix to muliply point with
 * @param points reference array of points to transform
 */
export function transformPoints(matrix: Matrix, points: Vector3[]) {
  for (let i = 0; i < points.length; i++) {
    points[i] = Vector3.TransformCoordinates(points[i], matrix);
  }
}

export function interpolatePosition(from: number[], to: number[], step: number) {
  if (from.length == to.length) {
    const result: number[][] = [];

    for (let j = 0; j < step; j++) {
      result[j] = [];
      for (let i = 0; i < from.length; i++) {
        result[j][i] = from[i] + (j * (to[i] - from[i])) / (step - 1);
      }
    }
    return result;
  } else {
    return undefined;
  }
}

export function interpolateQuaternion(from: number[], to: number[], step: number) {
  if (from.length == to.length && from.length == 4) {
    const qa = new Quaternion(from[0], from[1], from[2], from[3]);
    const qb = new Quaternion(to[0], to[1], to[2], to[3]);
    let cosHalfTheta = qa.w * qb.w + qa.x * qb.x + qa.y * qb.y + qa.z * qb.z;
    const sinHalfTheta = Math.sqrt(1.0 - cosHalfTheta * cosHalfTheta);

    const result: number[][] = [];
    if (Math.abs(cosHalfTheta) >= 1.0 || Math.abs(sinHalfTheta) < 0.001) {
      const gm = qa;
      for (let j = 0; j < step; j++) {
        result[j] = [gm.x, gm.y, gm.z, gm.w];
      }
      return result;
    }
    if (cosHalfTheta < 0) {
      qb.w = -qb.w;
      qb.x = -qb.x;
      qb.y = -qb.y;
      qb.z = -qb.z;
      cosHalfTheta = -cosHalfTheta;
    }
    const halfTheta = Math.acos(cosHalfTheta);

    for (let j = 0; j < step; j++) {
      const t = j / (step - 1);
      const ratioA = Math.sin((1 - t) * halfTheta) / sinHalfTheta;
      const ratioB = Math.sin(t * halfTheta) / sinHalfTheta;
      result[j] = [
        qa.x * ratioA + qb.x * ratioB,
        qa.y * ratioA + qb.y * ratioB,
        qa.z * ratioA + qb.z * ratioB,
        qa.w * ratioA + qb.w * ratioB,
      ];
    }
    return result;
  } else {
    return undefined;
  }
}
