import { Matrix, Vector3 } from "../../Maths/math.vector.js";
import { RenderTargetTexture } from "../../Materials/Textures/renderTargetTexture.js";
import "../../Shaders/shadowMap.fragment.js";
import "../../Shaders/shadowMap.vertex.js";
import "../../Shaders/depthBoxBlur.fragment.js";
import { _WarnImport } from "../../Misc/devTools.js";
import { ShadowGenerator } from "./shadowGenerator.js";
import { BoundingInfo } from "../../Culling/boundingInfo.js";
import { DepthReducer } from "../../Misc/depthReducer.js";
import { Logger } from "../../Misc/logger.js";
import { EngineStore } from "../../Engines/engineStore.js";
// eslint-disable-next-line @typescript-eslint/naming-convention
const UpDir = Vector3.Up();
// eslint-disable-next-line @typescript-eslint/naming-convention
const ZeroVec = Vector3.Zero();
const tmpv1 = new Vector3(),
  tmpv2 = new Vector3(),
  tmpMatrix = new Matrix();
/**
 * A CSM implementation allowing casting shadows on large scenes.
 * Documentation : https://doc.babylonjs.com/babylon101/cascadedShadows
 * Based on: https://github.com/TheRealMJP/Shadows and https://johanmedestrom.wordpress.com/2016/03/18/opengl-cascaded-shadow-maps/
 */
export class CascadedShadowGenerator extends ShadowGenerator {
  _validateFilter(filter) {
    if (filter === ShadowGenerator.FILTER_NONE || filter === ShadowGenerator.FILTER_PCF || filter === ShadowGenerator.FILTER_PCSS) {
      return filter;
    }
    console.error('Unsupported filter "' + filter + '"!');
    return ShadowGenerator.FILTER_NONE;
  }
  /**
   * Gets or set the number of cascades used by the CSM.
   */
  get numCascades() {
    return this._numCascades;
  }
  set numCascades(value) {
    value = Math.min(Math.max(value, CascadedShadowGenerator.MIN_CASCADES_COUNT), CascadedShadowGenerator.MAX_CASCADES_COUNT);
    if (value === this._numCascades) {
      return;
    }
    this._numCascades = value;
    this.recreateShadowMap();
    this._recreateSceneUBOs();
  }
  /**
   * Enables or disables the shadow casters bounding info computation.
   * If your shadow casters don't move, you can disable this feature.
   * If it is enabled, the bounding box computation is done every frame.
   */
  get freezeShadowCastersBoundingInfo() {
    return this._freezeShadowCastersBoundingInfo;
  }
  set freezeShadowCastersBoundingInfo(freeze) {
    if (this._freezeShadowCastersBoundingInfoObservable && freeze) {
      this._scene.onBeforeRenderObservable.remove(this._freezeShadowCastersBoundingInfoObservable);
      this._freezeShadowCastersBoundingInfoObservable = null;
    }
    if (!this._freezeShadowCastersBoundingInfoObservable && !freeze) {
      this._freezeShadowCastersBoundingInfoObservable = this._scene.onBeforeRenderObservable.add(() => this._computeShadowCastersBoundingInfo());
    }
    this._freezeShadowCastersBoundingInfo = freeze;
    if (freeze) {
      this._computeShadowCastersBoundingInfo();
    }
  }
  _computeShadowCastersBoundingInfo() {
    this._scbiMin.copyFromFloats(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE);
    this._scbiMax.copyFromFloats(-Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE);
    if (this._shadowMap && this._shadowMap.renderList) {
      const renderList = this._shadowMap.renderList;
      for (let meshIndex = 0; meshIndex < renderList.length; meshIndex++) {
        const mesh = renderList[meshIndex];
        if (!mesh) {
          continue;
        }
        const boundingInfo = mesh.getBoundingInfo(),
          boundingBox = boundingInfo.boundingBox;
        this._scbiMin.minimizeInPlace(boundingBox.minimumWorld);
        this._scbiMax.maximizeInPlace(boundingBox.maximumWorld);
      }
      const meshes = this._scene.meshes;
      for (let meshIndex = 0; meshIndex < meshes.length; meshIndex++) {
        const mesh = meshes[meshIndex];
        if (!mesh || !mesh.isVisible || !mesh.isEnabled || !mesh.receiveShadows) {
          continue;
        }
        const boundingInfo = mesh.getBoundingInfo(),
          boundingBox = boundingInfo.boundingBox;
        this._scbiMin.minimizeInPlace(boundingBox.minimumWorld);
        this._scbiMax.maximizeInPlace(boundingBox.maximumWorld);
      }
    }
    this._shadowCastersBoundingInfo.reConstruct(this._scbiMin, this._scbiMax);
  }
  /**
   * Gets or sets the shadow casters bounding info.
   * If you provide your own shadow casters bounding info, first enable freezeShadowCastersBoundingInfo
   * so that the system won't overwrite the bounds you provide
   */
  get shadowCastersBoundingInfo() {
    return this._shadowCastersBoundingInfo;
  }
  set shadowCastersBoundingInfo(boundingInfo) {
    this._shadowCastersBoundingInfo = boundingInfo;
  }
  /**
   * Sets the minimal and maximal distances to use when computing the cascade breaks.
   *
   * The values of min / max are typically the depth zmin and zmax values of your scene, for a given frame.
   * If you don't know these values, simply leave them to their defaults and don't call this function.
   * @param min minimal distance for the breaks (default to 0.)
   * @param max maximal distance for the breaks (default to 1.)
   */
  setMinMaxDistance(min, max) {
    if (this._minDistance === min && this._maxDistance === max) {
      return;
    }
    if (min > max) {
      min = 0;
      max = 1;
    }
    if (min < 0) {
      min = 0;
    }
    if (max > 1) {
      max = 1;
    }
    this._minDistance = min;
    this._maxDistance = max;
    this._breaksAreDirty = true;
  }
  /** Gets the minimal distance used in the cascade break computation */
  get minDistance() {
    return this._minDistance;
  }
  /** Gets the maximal distance used in the cascade break computation */
  get maxDistance() {
    return this._maxDistance;
  }
  /**
   * Gets the class name of that object
   * @returns "CascadedShadowGenerator"
   */
  getClassName() {
    return CascadedShadowGenerator.CLASSNAME;
  }
  /**
   * Gets a cascade minimum extents
   * @param cascadeIndex index of the cascade
   * @returns the minimum cascade extents
   */
  getCascadeMinExtents(cascadeIndex) {
    return cascadeIndex >= 0 && cascadeIndex < this._numCascades ? this._cascadeMinExtents[cascadeIndex] : null;
  }
  /**
   * Gets a cascade maximum extents
   * @param cascadeIndex index of the cascade
   * @returns the maximum cascade extents
   */
  getCascadeMaxExtents(cascadeIndex) {
    return cascadeIndex >= 0 && cascadeIndex < this._numCascades ? this._cascadeMaxExtents[cascadeIndex] : null;
  }
  /**
   * Gets the shadow max z distance. It's the limit beyond which shadows are not displayed.
   * It defaults to camera.maxZ
   */
  get shadowMaxZ() {
    if (!this._getCamera()) {
      return 0;
    }
    return this._shadowMaxZ;
  }
  /**
   * Sets the shadow max z distance.
   */
  set shadowMaxZ(value) {
    const camera = this._getCamera();
    if (!camera) {
      this._shadowMaxZ = value;
      return;
    }
    if (this._shadowMaxZ === value || value < camera.minZ || value > camera.maxZ && camera.maxZ !== 0) {
      return;
    }
    this._shadowMaxZ = value;
    this._light._markMeshesAsLightDirty();
    this._breaksAreDirty = true;
  }
  /**
   * Gets or sets the debug flag.
   * When enabled, the cascades are materialized by different colors on the screen.
   */
  get debug() {
    return this._debug;
  }
  set debug(dbg) {
    this._debug = dbg;
    this._light._markMeshesAsLightDirty();
  }
  /**
   * Gets or sets the depth clamping value.
   *
   * When enabled, it improves the shadow quality because the near z plane of the light frustum don't need to be adjusted
   * to account for the shadow casters far away.
   *
   * Note that this property is incompatible with PCSS filtering, so it won't be used in that case.
   */
  get depthClamp() {
    return this._depthClamp;
  }
  set depthClamp(value) {
    this._depthClamp = value;
  }
  /**
   * Gets or sets the percentage of blending between two cascades (value between 0. and 1.).
   * It defaults to 0.1 (10% blending).
   */
  get cascadeBlendPercentage() {
    return this._cascadeBlendPercentage;
  }
  set cascadeBlendPercentage(value) {
    this._cascadeBlendPercentage = value;
    this._light._markMeshesAsLightDirty();
  }
  /**
   * Gets or set the lambda parameter.
   * This parameter is used to split the camera frustum and create the cascades.
   * It's a value between 0. and 1.: If 0, the split is a uniform split of the frustum, if 1 it is a logarithmic split.
   * For all values in-between, it's a linear combination of the uniform and logarithm split algorithm.
   */
  get lambda() {
    return this._lambda;
  }
  set lambda(value) {
    const lambda = Math.min(Math.max(value, 0), 1);
    if (this._lambda == lambda) {
      return;
    }
    this._lambda = lambda;
    this._breaksAreDirty = true;
  }
  /**
   * Gets the view matrix corresponding to a given cascade
   * @param cascadeNum cascade to retrieve the view matrix from
   * @returns the cascade view matrix
   */
  getCascadeViewMatrix(cascadeNum) {
    return cascadeNum >= 0 && cascadeNum < this._numCascades ? this._viewMatrices[cascadeNum] : null;
  }
  /**
   * Gets the projection matrix corresponding to a given cascade
   * @param cascadeNum cascade to retrieve the projection matrix from
   * @returns the cascade projection matrix
   */
  getCascadeProjectionMatrix(cascadeNum) {
    return cascadeNum >= 0 && cascadeNum < this._numCascades ? this._projectionMatrices[cascadeNum] : null;
  }
  /**
   * Gets the transformation matrix corresponding to a given cascade
   * @param cascadeNum cascade to retrieve the transformation matrix from
   * @returns the cascade transformation matrix
   */
  getCascadeTransformMatrix(cascadeNum) {
    return cascadeNum >= 0 && cascadeNum < this._numCascades ? this._transformMatrices[cascadeNum] : null;
  }
  /**
   * Sets the depth renderer to use when autoCalcDepthBounds is enabled.
   *
   * Note that if no depth renderer is set, a new one will be automatically created internally when necessary.
   *
   * You should call this function if you already have a depth renderer enabled in your scene, to avoid
   * doing multiple depth rendering each frame. If you provide your own depth renderer, make sure it stores linear depth!
   * @param depthRenderer The depth renderer to use when autoCalcDepthBounds is enabled. If you pass null or don't call this function at all, a depth renderer will be automatically created
   */
  setDepthRenderer(depthRenderer) {
    this._depthRenderer = depthRenderer;
    if (this._depthReducer) {
      this._depthReducer.setDepthRenderer(this._depthRenderer);
    }
  }
  /**
   * Gets or sets the autoCalcDepthBounds property.
   *
   * When enabled, a depth rendering pass is first performed (with an internally created depth renderer or with the one
   * you provide by calling setDepthRenderer). Then, a min/max reducing is applied on the depth map to compute the
   * minimal and maximal depth of the map and those values are used as inputs for the setMinMaxDistance() function.
   * It can greatly enhance the shadow quality, at the expense of more GPU works.
   * When using this option, you should increase the value of the lambda parameter, and even set it to 1 for best results.
   */
  get autoCalcDepthBounds() {
    return this._autoCalcDepthBounds;
  }
  set autoCalcDepthBounds(value) {
    const camera = this._getCamera();
    if (!camera) {
      return;
    }
    this._autoCalcDepthBounds = value;
    if (!value) {
      if (this._depthReducer) {
        this._depthReducer.deactivate();
      }
      this.setMinMaxDistance(0, 1);
      return;
    }
    if (!this._depthReducer) {
      this._depthReducer = new DepthReducer(camera);
      this._depthReducer.onAfterReductionPerformed.add(minmax => {
        let min = minmax.min,
          max = minmax.max;
        if (min >= max) {
          min = 0;
          max = 1;
        }
        if (min != this._minDistance || max != this._maxDistance) {
          this.setMinMaxDistance(min, max);
        }
      });
      this._depthReducer.setDepthRenderer(this._depthRenderer);
    }
    this._depthReducer.activate();
  }
  /**
   * Defines the refresh rate of the min/max computation used when autoCalcDepthBounds is set to true
   * Use 0 to compute just once, 1 to compute on every frame, 2 to compute every two frames and so on...
   * Note that if you provided your own depth renderer through a call to setDepthRenderer, you are responsible
   * for setting the refresh rate on the renderer yourself!
   */
  get autoCalcDepthBoundsRefreshRate() {
    var _a, _b, _c;
    return (_c = (_b = (_a = this._depthReducer) === null || _a === void 0 ? void 0 : _a.depthRenderer) === null || _b === void 0 ? void 0 : _b.getDepthMap().refreshRate) !== null && _c !== void 0 ? _c : -1;
  }
  set autoCalcDepthBoundsRefreshRate(value) {
    var _a;
    if ((_a = this._depthReducer) === null || _a === void 0 ? void 0 : _a.depthRenderer) {
      this._depthReducer.depthRenderer.getDepthMap().refreshRate = value;
    }
  }
  /**
   * Create the cascade breaks according to the lambda, shadowMaxZ and min/max distance properties, as well as the camera near and far planes.
   * This function is automatically called when updating lambda, shadowMaxZ and min/max distances, however you should call it yourself if
   * you change the camera near/far planes!
   */
  splitFrustum() {
    this._breaksAreDirty = true;
  }
  _splitFrustum() {
    const camera = this._getCamera();
    if (!camera) {
      return;
    }
    const near = camera.minZ,
      far = camera.maxZ || this._shadowMaxZ,
      // account for infinite far plane (ie. maxZ = 0)
      cameraRange = far - near,
      minDistance = this._minDistance,
      maxDistance = this._shadowMaxZ < far && this._shadowMaxZ >= near ? Math.min((this._shadowMaxZ - near) / (far - near), this._maxDistance) : this._maxDistance;
    const minZ = near + minDistance * cameraRange,
      maxZ = near + maxDistance * cameraRange;
    const range = maxZ - minZ,
      ratio = maxZ / minZ;
    for (let cascadeIndex = 0; cascadeIndex < this._cascades.length; ++cascadeIndex) {
      const p = (cascadeIndex + 1) / this._numCascades,
        log = minZ * ratio ** p,
        uniform = minZ + range * p;
      const d = this._lambda * (log - uniform) + uniform;
      this._cascades[cascadeIndex].prevBreakDistance = cascadeIndex === 0 ? minDistance : this._cascades[cascadeIndex - 1].breakDistance;
      this._cascades[cascadeIndex].breakDistance = (d - near) / cameraRange;
      this._viewSpaceFrustumsZ[cascadeIndex] = d;
      this._frustumLengths[cascadeIndex] = (this._cascades[cascadeIndex].breakDistance - this._cascades[cascadeIndex].prevBreakDistance) * cameraRange;
    }
    this._breaksAreDirty = false;
  }
  _computeMatrices() {
    const scene = this._scene;
    const camera = this._getCamera();
    if (!camera) {
      return;
    }
    Vector3.NormalizeToRef(this._light.getShadowDirection(0), this._lightDirection);
    if (Math.abs(Vector3.Dot(this._lightDirection, Vector3.Up())) === 1.0) {
      this._lightDirection.z = 0.0000000000001; // Required to avoid perfectly perpendicular light
    }
    this._cachedDirection.copyFrom(this._lightDirection);
    const useReverseDepthBuffer = scene.getEngine().useReverseDepthBuffer;
    for (let cascadeIndex = 0; cascadeIndex < this._numCascades; ++cascadeIndex) {
      this._computeFrustumInWorldSpace(cascadeIndex);
      this._computeCascadeFrustum(cascadeIndex);
      this._cascadeMaxExtents[cascadeIndex].subtractToRef(this._cascadeMinExtents[cascadeIndex], tmpv1); // tmpv1 = cascadeExtents
      // Get position of the shadow camera
      this._frustumCenter[cascadeIndex].addToRef(this._lightDirection.scale(this._cascadeMinExtents[cascadeIndex].z), this._shadowCameraPos[cascadeIndex]);
      // Come up with a new orthographic camera for the shadow caster
      Matrix.LookAtLHToRef(this._shadowCameraPos[cascadeIndex], this._frustumCenter[cascadeIndex], UpDir, this._viewMatrices[cascadeIndex]);
      let minZ = 0,
        maxZ = tmpv1.z;
      // Try to tighten minZ and maxZ based on the bounding box of the shadow casters
      const boundingInfo = this._shadowCastersBoundingInfo;
      boundingInfo.update(this._viewMatrices[cascadeIndex]);
      maxZ = Math.min(maxZ, boundingInfo.boundingBox.maximumWorld.z);
      if (!this._depthClamp || this.filter === ShadowGenerator.FILTER_PCSS) {
        // If we don't use depth clamping, we must set minZ so that all shadow casters are in the light frustum
        minZ = Math.min(minZ, boundingInfo.boundingBox.minimumWorld.z);
      } else {
        // If using depth clamping, we can adjust minZ to reduce the [minZ, maxZ] range (and get some additional precision in the shadow map)
        minZ = Math.max(minZ, boundingInfo.boundingBox.minimumWorld.z);
      }
      Matrix.OrthoOffCenterLHToRef(this._cascadeMinExtents[cascadeIndex].x, this._cascadeMaxExtents[cascadeIndex].x, this._cascadeMinExtents[cascadeIndex].y, this._cascadeMaxExtents[cascadeIndex].y, useReverseDepthBuffer ? maxZ : minZ, useReverseDepthBuffer ? minZ : maxZ, this._projectionMatrices[cascadeIndex], scene.getEngine().isNDCHalfZRange);
      this._cascadeMinExtents[cascadeIndex].z = minZ;
      this._cascadeMaxExtents[cascadeIndex].z = maxZ;
      this._viewMatrices[cascadeIndex].multiplyToRef(this._projectionMatrices[cascadeIndex], this._transformMatrices[cascadeIndex]);
      // Create the rounding matrix, by projecting the world-space origin and determining
      // the fractional offset in texel space
      Vector3.TransformCoordinatesToRef(ZeroVec, this._transformMatrices[cascadeIndex], tmpv1); // tmpv1 = shadowOrigin
      tmpv1.scaleInPlace(this._mapSize / 2);
      tmpv2.copyFromFloats(Math.round(tmpv1.x), Math.round(tmpv1.y), Math.round(tmpv1.z)); // tmpv2 = roundedOrigin
      tmpv2.subtractInPlace(tmpv1).scaleInPlace(2 / this._mapSize); // tmpv2 = roundOffset
      Matrix.TranslationToRef(tmpv2.x, tmpv2.y, 0.0, tmpMatrix);
      this._projectionMatrices[cascadeIndex].multiplyToRef(tmpMatrix, this._projectionMatrices[cascadeIndex]);
      this._viewMatrices[cascadeIndex].multiplyToRef(this._projectionMatrices[cascadeIndex], this._transformMatrices[cascadeIndex]);
      this._transformMatrices[cascadeIndex].copyToArray(this._transformMatricesAsArray, cascadeIndex * 16);
    }
  }
  // Get the 8 points of the view frustum in world space
  _computeFrustumInWorldSpace(cascadeIndex) {
    const camera = this._getCamera();
    if (!camera) {
      return;
    }
    const prevSplitDist = this._cascades[cascadeIndex].prevBreakDistance,
      splitDist = this._cascades[cascadeIndex].breakDistance;
    const isNDCHalfZRange = this._scene.getEngine().isNDCHalfZRange;
    camera.getViewMatrix(); // make sure the transformation matrix we get when calling 'getTransformationMatrix()' is calculated with an up to date view matrix
    const cameraInfiniteFarPlane = camera.maxZ === 0;
    const saveCameraMaxZ = camera.maxZ;
    if (cameraInfiniteFarPlane) {
      camera.maxZ = this._shadowMaxZ;
      camera.getProjectionMatrix(true);
    }
    const invViewProj = Matrix.Invert(camera.getTransformationMatrix());
    if (cameraInfiniteFarPlane) {
      camera.maxZ = saveCameraMaxZ;
      camera.getProjectionMatrix(true);
    }
    const cornerIndexOffset = this._scene.getEngine().useReverseDepthBuffer ? 4 : 0;
    for (let cornerIndex = 0; cornerIndex < CascadedShadowGenerator._FrustumCornersNDCSpace.length; ++cornerIndex) {
      tmpv1.copyFrom(CascadedShadowGenerator._FrustumCornersNDCSpace[(cornerIndex + cornerIndexOffset) % CascadedShadowGenerator._FrustumCornersNDCSpace.length]);
      if (isNDCHalfZRange && tmpv1.z === -1) {
        tmpv1.z = 0;
      }
      Vector3.TransformCoordinatesToRef(tmpv1, invViewProj, this._frustumCornersWorldSpace[cascadeIndex][cornerIndex]);
    }
    // Get the corners of the current cascade slice of the view frustum
    for (let cornerIndex = 0; cornerIndex < CascadedShadowGenerator._FrustumCornersNDCSpace.length / 2; ++cornerIndex) {
      tmpv1.copyFrom(this._frustumCornersWorldSpace[cascadeIndex][cornerIndex + 4]).subtractInPlace(this._frustumCornersWorldSpace[cascadeIndex][cornerIndex]);
      tmpv2.copyFrom(tmpv1).scaleInPlace(prevSplitDist); // near corner ray
      tmpv1.scaleInPlace(splitDist); // far corner ray
      tmpv1.addInPlace(this._frustumCornersWorldSpace[cascadeIndex][cornerIndex]);
      this._frustumCornersWorldSpace[cascadeIndex][cornerIndex + 4].copyFrom(tmpv1);
      this._frustumCornersWorldSpace[cascadeIndex][cornerIndex].addInPlace(tmpv2);
    }
  }
  _computeCascadeFrustum(cascadeIndex) {
    this._cascadeMinExtents[cascadeIndex].copyFromFloats(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE);
    this._cascadeMaxExtents[cascadeIndex].copyFromFloats(-Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE);
    this._frustumCenter[cascadeIndex].copyFromFloats(0, 0, 0);
    const camera = this._getCamera();
    if (!camera) {
      return;
    }
    // Calculate the centroid of the view frustum slice
    for (let cornerIndex = 0; cornerIndex < this._frustumCornersWorldSpace[cascadeIndex].length; ++cornerIndex) {
      this._frustumCenter[cascadeIndex].addInPlace(this._frustumCornersWorldSpace[cascadeIndex][cornerIndex]);
    }
    this._frustumCenter[cascadeIndex].scaleInPlace(1 / this._frustumCornersWorldSpace[cascadeIndex].length);
    if (this.stabilizeCascades) {
      // Calculate the radius of a bounding sphere surrounding the frustum corners
      let sphereRadius = 0;
      for (let cornerIndex = 0; cornerIndex < this._frustumCornersWorldSpace[cascadeIndex].length; ++cornerIndex) {
        const dist = this._frustumCornersWorldSpace[cascadeIndex][cornerIndex].subtractToRef(this._frustumCenter[cascadeIndex], tmpv1).length();
        sphereRadius = Math.max(sphereRadius, dist);
      }
      sphereRadius = Math.ceil(sphereRadius * 16) / 16;
      this._cascadeMaxExtents[cascadeIndex].copyFromFloats(sphereRadius, sphereRadius, sphereRadius);
      this._cascadeMinExtents[cascadeIndex].copyFromFloats(-sphereRadius, -sphereRadius, -sphereRadius);
    } else {
      // Create a temporary view matrix for the light
      const lightCameraPos = this._frustumCenter[cascadeIndex];
      this._frustumCenter[cascadeIndex].addToRef(this._lightDirection, tmpv1); // tmpv1 = look at
      Matrix.LookAtLHToRef(lightCameraPos, tmpv1, UpDir, tmpMatrix); // matrix = lightView
      // Calculate an AABB around the frustum corners
      for (let cornerIndex = 0; cornerIndex < this._frustumCornersWorldSpace[cascadeIndex].length; ++cornerIndex) {
        Vector3.TransformCoordinatesToRef(this._frustumCornersWorldSpace[cascadeIndex][cornerIndex], tmpMatrix, tmpv1);
        this._cascadeMinExtents[cascadeIndex].minimizeInPlace(tmpv1);
        this._cascadeMaxExtents[cascadeIndex].maximizeInPlace(tmpv1);
      }
    }
  }
  _recreateSceneUBOs() {
    this._disposeSceneUBOs();
    if (this._sceneUBOs) {
      for (let i = 0; i < this._numCascades; ++i) {
        this._sceneUBOs.push(this._scene.createSceneUniformBuffer(`Scene for CSM Shadow Generator (light "${this._light.name}" cascade #${i})`));
      }
    }
  }
  /**
   *  Support test.
   */
  static get IsSupported() {
    const engine = EngineStore.LastCreatedEngine;
    if (!engine) {
      return false;
    }
    return engine._features.supportCSM;
  }
  /**
   * Creates a Cascaded Shadow Generator object.
   * A ShadowGenerator is the required tool to use the shadows.
   * Each directional light casting shadows needs to use its own ShadowGenerator.
   * Documentation : https://doc.babylonjs.com/babylon101/cascadedShadows
   * @param mapSize The size of the texture what stores the shadows. Example : 1024.
   * @param light The directional light object generating the shadows.
   * @param usefulFloatFirst By default the generator will try to use half float textures but if you need precision (for self shadowing for instance), you can use this option to enforce full float texture.
   * @param camera Camera associated with this shadow generator (default: null). If null, takes the scene active camera at the time we need to access it
   * @param useRedTextureType Forces the generator to use a Red instead of a RGBA type for the shadow map texture format (default: true)
   */
  constructor(mapSize, light, usefulFloatFirst, camera, useRedTextureType = true) {
    if (!CascadedShadowGenerator.IsSupported) {
      Logger.Error("CascadedShadowMap is not supported by the current engine.");
      return;
    }
    super(mapSize, light, usefulFloatFirst, camera, useRedTextureType);
    this.usePercentageCloserFiltering = true;
  }
  _initializeGenerator() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    this.penumbraDarkness = (_a = this.penumbraDarkness) !== null && _a !== void 0 ? _a : 1.0;
    this._numCascades = (_b = this._numCascades) !== null && _b !== void 0 ? _b : CascadedShadowGenerator.DEFAULT_CASCADES_COUNT;
    this.stabilizeCascades = (_c = this.stabilizeCascades) !== null && _c !== void 0 ? _c : false;
    this._freezeShadowCastersBoundingInfoObservable = (_d = this._freezeShadowCastersBoundingInfoObservable) !== null && _d !== void 0 ? _d : null;
    this.freezeShadowCastersBoundingInfo = (_e = this.freezeShadowCastersBoundingInfo) !== null && _e !== void 0 ? _e : false;
    this._scbiMin = (_f = this._scbiMin) !== null && _f !== void 0 ? _f : new Vector3(0, 0, 0);
    this._scbiMax = (_g = this._scbiMax) !== null && _g !== void 0 ? _g : new Vector3(0, 0, 0);
    this._shadowCastersBoundingInfo = (_h = this._shadowCastersBoundingInfo) !== null && _h !== void 0 ? _h : new BoundingInfo(new Vector3(0, 0, 0), new Vector3(0, 0, 0));
    this._breaksAreDirty = (_j = this._breaksAreDirty) !== null && _j !== void 0 ? _j : true;
    this._minDistance = (_k = this._minDistance) !== null && _k !== void 0 ? _k : 0;
    this._maxDistance = (_l = this._maxDistance) !== null && _l !== void 0 ? _l : 1;
    this._currentLayer = (_m = this._currentLayer) !== null && _m !== void 0 ? _m : 0;
    this._shadowMaxZ = (_q = (_o = this._shadowMaxZ) !== null && _o !== void 0 ? _o : (_p = this._getCamera()) === null || _p === void 0 ? void 0 : _p.maxZ) !== null && _q !== void 0 ? _q : 10000;
    this._debug = (_r = this._debug) !== null && _r !== void 0 ? _r : false;
    this._depthClamp = (_s = this._depthClamp) !== null && _s !== void 0 ? _s : true;
    this._cascadeBlendPercentage = (_t = this._cascadeBlendPercentage) !== null && _t !== void 0 ? _t : 0.1;
    this._lambda = (_u = this._lambda) !== null && _u !== void 0 ? _u : 0.5;
    this._autoCalcDepthBounds = (_v = this._autoCalcDepthBounds) !== null && _v !== void 0 ? _v : false;
    this._recreateSceneUBOs();
    super._initializeGenerator();
  }
  _createTargetRenderTexture() {
    const engine = this._scene.getEngine();
    const size = {
      width: this._mapSize,
      height: this._mapSize,
      layers: this.numCascades
    };
    this._shadowMap = new RenderTargetTexture(this._light.name + "_CSMShadowMap", size, this._scene, false, true, this._textureType, false, undefined, false, false, undefined, this._useRedTextureType ? 6 : 5);
    this._shadowMap.createDepthStencilTexture(engine.useReverseDepthBuffer ? 516 : 513, true);
    this._shadowMap.noPrePassRenderer = true;
  }
  _initializeShadowMap() {
    super._initializeShadowMap();
    if (this._shadowMap === null) {
      return;
    }
    this._transformMatricesAsArray = new Float32Array(this._numCascades * 16);
    this._viewSpaceFrustumsZ = new Array(this._numCascades);
    this._frustumLengths = new Array(this._numCascades);
    this._lightSizeUVCorrection = new Array(this._numCascades * 2);
    this._depthCorrection = new Array(this._numCascades);
    this._cascades = [];
    this._viewMatrices = [];
    this._projectionMatrices = [];
    this._transformMatrices = [];
    this._cascadeMinExtents = [];
    this._cascadeMaxExtents = [];
    this._frustumCenter = [];
    this._shadowCameraPos = [];
    this._frustumCornersWorldSpace = [];
    for (let cascadeIndex = 0; cascadeIndex < this._numCascades; ++cascadeIndex) {
      this._cascades[cascadeIndex] = {
        prevBreakDistance: 0,
        breakDistance: 0
      };
      this._viewMatrices[cascadeIndex] = Matrix.Zero();
      this._projectionMatrices[cascadeIndex] = Matrix.Zero();
      this._transformMatrices[cascadeIndex] = Matrix.Zero();
      this._cascadeMinExtents[cascadeIndex] = new Vector3();
      this._cascadeMaxExtents[cascadeIndex] = new Vector3();
      this._frustumCenter[cascadeIndex] = new Vector3();
      this._shadowCameraPos[cascadeIndex] = new Vector3();
      this._frustumCornersWorldSpace[cascadeIndex] = new Array(CascadedShadowGenerator._FrustumCornersNDCSpace.length);
      for (let i = 0; i < CascadedShadowGenerator._FrustumCornersNDCSpace.length; ++i) {
        this._frustumCornersWorldSpace[cascadeIndex][i] = new Vector3();
      }
    }
    const engine = this._scene.getEngine();
    this._shadowMap.onBeforeBindObservable.clear();
    this._shadowMap.onBeforeRenderObservable.clear();
    this._shadowMap.onBeforeRenderObservable.add(layer => {
      if (this._sceneUBOs) {
        this._scene.setSceneUniformBuffer(this._sceneUBOs[layer]);
      }
      this._currentLayer = layer;
      if (this._filter === ShadowGenerator.FILTER_PCF) {
        engine.setColorWrite(false);
      }
      this._scene.setTransformMatrix(this.getCascadeViewMatrix(layer), this.getCascadeProjectionMatrix(layer));
      if (this._useUBO) {
        this._scene.getSceneUniformBuffer().unbindEffect();
        this._scene.finalizeSceneUbo();
      }
    });
    this._shadowMap.onBeforeBindObservable.add(() => {
      var _a;
      this._currentSceneUBO = this._scene.getSceneUniformBuffer();
      (_a = engine._debugPushGroup) === null || _a === void 0 ? void 0 : _a.call(engine, `cascaded shadow map generation for pass id ${engine.currentRenderPassId}`, 1);
      if (this._breaksAreDirty) {
        this._splitFrustum();
      }
      this._computeMatrices();
    });
    this._splitFrustum();
  }
  _bindCustomEffectForRenderSubMeshForShadowMap(subMesh, effect) {
    effect.setMatrix("viewProjection", this.getCascadeTransformMatrix(this._currentLayer));
  }
  _isReadyCustomDefines(defines) {
    defines.push("#define SM_DEPTHCLAMP " + (this._depthClamp && this._filter !== ShadowGenerator.FILTER_PCSS ? "1" : "0"));
  }
  /**
   * Prepare all the defines in a material relying on a shadow map at the specified light index.
   * @param defines Defines of the material we want to update
   * @param lightIndex Index of the light in the enabled light list of the material
   */
  prepareDefines(defines, lightIndex) {
    super.prepareDefines(defines, lightIndex);
    const scene = this._scene;
    const light = this._light;
    if (!scene.shadowsEnabled || !light.shadowEnabled) {
      return;
    }
    defines["SHADOWCSM" + lightIndex] = true;
    defines["SHADOWCSMDEBUG" + lightIndex] = this.debug;
    defines["SHADOWCSMNUM_CASCADES" + lightIndex] = this.numCascades;
    defines["SHADOWCSM_RIGHTHANDED" + lightIndex] = scene.useRightHandedSystem;
    const camera = this._getCamera();
    if (camera && this._shadowMaxZ <= (camera.maxZ || this._shadowMaxZ)) {
      defines["SHADOWCSMUSESHADOWMAXZ" + lightIndex] = true;
    }
    if (this.cascadeBlendPercentage === 0) {
      defines["SHADOWCSMNOBLEND" + lightIndex] = true;
    }
  }
  /**
   * Binds the shadow related information inside of an effect (information like near, far, darkness...
   * defined in the generator but impacting the effect).
   * @param lightIndex Index of the light in the enabled light list of the material owning the effect
   * @param effect The effect we are binfing the information for
   */
  bindShadowLight(lightIndex, effect) {
    const light = this._light;
    const scene = this._scene;
    if (!scene.shadowsEnabled || !light.shadowEnabled) {
      return;
    }
    const camera = this._getCamera();
    if (!camera) {
      return;
    }
    const shadowMap = this.getShadowMap();
    if (!shadowMap) {
      return;
    }
    const width = shadowMap.getSize().width;
    effect.setMatrices("lightMatrix" + lightIndex, this._transformMatricesAsArray);
    effect.setArray("viewFrustumZ" + lightIndex, this._viewSpaceFrustumsZ);
    effect.setFloat("cascadeBlendFactor" + lightIndex, this.cascadeBlendPercentage === 0 ? 10000 : 1 / this.cascadeBlendPercentage);
    effect.setArray("frustumLengths" + lightIndex, this._frustumLengths);
    // Only PCF uses depth stencil texture.
    if (this._filter === ShadowGenerator.FILTER_PCF) {
      effect.setDepthStencilTexture("shadowSampler" + lightIndex, shadowMap);
      light._uniformBuffer.updateFloat4("shadowsInfo", this.getDarkness(), width, 1 / width, this.frustumEdgeFalloff, lightIndex);
    } else if (this._filter === ShadowGenerator.FILTER_PCSS) {
      for (let cascadeIndex = 0; cascadeIndex < this._numCascades; ++cascadeIndex) {
        this._lightSizeUVCorrection[cascadeIndex * 2 + 0] = cascadeIndex === 0 ? 1 : (this._cascadeMaxExtents[0].x - this._cascadeMinExtents[0].x) / (this._cascadeMaxExtents[cascadeIndex].x - this._cascadeMinExtents[cascadeIndex].x); // x correction
        this._lightSizeUVCorrection[cascadeIndex * 2 + 1] = cascadeIndex === 0 ? 1 : (this._cascadeMaxExtents[0].y - this._cascadeMinExtents[0].y) / (this._cascadeMaxExtents[cascadeIndex].y - this._cascadeMinExtents[cascadeIndex].y); // y correction
        this._depthCorrection[cascadeIndex] = cascadeIndex === 0 ? 1 : (this._cascadeMaxExtents[cascadeIndex].z - this._cascadeMinExtents[cascadeIndex].z) / (this._cascadeMaxExtents[0].z - this._cascadeMinExtents[0].z);
      }
      effect.setDepthStencilTexture("shadowSampler" + lightIndex, shadowMap);
      effect.setTexture("depthSampler" + lightIndex, shadowMap);
      effect.setArray2("lightSizeUVCorrection" + lightIndex, this._lightSizeUVCorrection);
      effect.setArray("depthCorrection" + lightIndex, this._depthCorrection);
      effect.setFloat("penumbraDarkness" + lightIndex, this.penumbraDarkness);
      light._uniformBuffer.updateFloat4("shadowsInfo", this.getDarkness(), 1 / width, this._contactHardeningLightSizeUVRatio * width, this.frustumEdgeFalloff, lightIndex);
    } else {
      effect.setTexture("shadowSampler" + lightIndex, shadowMap);
      light._uniformBuffer.updateFloat4("shadowsInfo", this.getDarkness(), width, 1 / width, this.frustumEdgeFalloff, lightIndex);
    }
    light._uniformBuffer.updateFloat2("depthValues", this.getLight().getDepthMinZ(camera), this.getLight().getDepthMinZ(camera) + this.getLight().getDepthMaxZ(camera), lightIndex);
  }
  /**
   * Gets the transformation matrix of the first cascade used to project the meshes into the map from the light point of view.
   * (eq to view projection * shadow projection matrices)
   * @returns The transform matrix used to create the shadow map
   */
  getTransformMatrix() {
    return this.getCascadeTransformMatrix(0);
  }
  /**
   * Disposes the ShadowGenerator.
   * Returns nothing.
   */
  dispose() {
    super.dispose();
    if (this._freezeShadowCastersBoundingInfoObservable) {
      this._scene.onBeforeRenderObservable.remove(this._freezeShadowCastersBoundingInfoObservable);
      this._freezeShadowCastersBoundingInfoObservable = null;
    }
    if (this._depthReducer) {
      this._depthReducer.dispose();
      this._depthReducer = null;
    }
  }
  /**
   * Serializes the shadow generator setup to a json object.
   * @returns The serialized JSON object
   */
  serialize() {
    const serializationObject = super.serialize();
    const shadowMap = this.getShadowMap();
    if (!shadowMap) {
      return serializationObject;
    }
    serializationObject.numCascades = this._numCascades;
    serializationObject.debug = this._debug;
    serializationObject.stabilizeCascades = this.stabilizeCascades;
    serializationObject.lambda = this._lambda;
    serializationObject.cascadeBlendPercentage = this.cascadeBlendPercentage;
    serializationObject.depthClamp = this._depthClamp;
    serializationObject.autoCalcDepthBounds = this.autoCalcDepthBounds;
    serializationObject.shadowMaxZ = this._shadowMaxZ;
    serializationObject.penumbraDarkness = this.penumbraDarkness;
    serializationObject.freezeShadowCastersBoundingInfo = this._freezeShadowCastersBoundingInfo;
    serializationObject.minDistance = this.minDistance;
    serializationObject.maxDistance = this.maxDistance;
    serializationObject.renderList = [];
    if (shadowMap.renderList) {
      for (let meshIndex = 0; meshIndex < shadowMap.renderList.length; meshIndex++) {
        const mesh = shadowMap.renderList[meshIndex];
        serializationObject.renderList.push(mesh.id);
      }
    }
    return serializationObject;
  }
  /**
   * Parses a serialized ShadowGenerator and returns a new ShadowGenerator.
   * @param parsedShadowGenerator The JSON object to parse
   * @param scene The scene to create the shadow map for
   * @returns The parsed shadow generator
   */
  static Parse(parsedShadowGenerator, scene) {
    const shadowGenerator = ShadowGenerator.Parse(parsedShadowGenerator, scene, (mapSize, light, camera) => new CascadedShadowGenerator(mapSize, light, undefined, camera));
    if (parsedShadowGenerator.numCascades !== undefined) {
      shadowGenerator.numCascades = parsedShadowGenerator.numCascades;
    }
    if (parsedShadowGenerator.debug !== undefined) {
      shadowGenerator.debug = parsedShadowGenerator.debug;
    }
    if (parsedShadowGenerator.stabilizeCascades !== undefined) {
      shadowGenerator.stabilizeCascades = parsedShadowGenerator.stabilizeCascades;
    }
    if (parsedShadowGenerator.lambda !== undefined) {
      shadowGenerator.lambda = parsedShadowGenerator.lambda;
    }
    if (parsedShadowGenerator.cascadeBlendPercentage !== undefined) {
      shadowGenerator.cascadeBlendPercentage = parsedShadowGenerator.cascadeBlendPercentage;
    }
    if (parsedShadowGenerator.depthClamp !== undefined) {
      shadowGenerator.depthClamp = parsedShadowGenerator.depthClamp;
    }
    if (parsedShadowGenerator.autoCalcDepthBounds !== undefined) {
      shadowGenerator.autoCalcDepthBounds = parsedShadowGenerator.autoCalcDepthBounds;
    }
    if (parsedShadowGenerator.shadowMaxZ !== undefined) {
      shadowGenerator.shadowMaxZ = parsedShadowGenerator.shadowMaxZ;
    }
    if (parsedShadowGenerator.penumbraDarkness !== undefined) {
      shadowGenerator.penumbraDarkness = parsedShadowGenerator.penumbraDarkness;
    }
    if (parsedShadowGenerator.freezeShadowCastersBoundingInfo !== undefined) {
      shadowGenerator.freezeShadowCastersBoundingInfo = parsedShadowGenerator.freezeShadowCastersBoundingInfo;
    }
    if (parsedShadowGenerator.minDistance !== undefined && parsedShadowGenerator.maxDistance !== undefined) {
      shadowGenerator.setMinMaxDistance(parsedShadowGenerator.minDistance, parsedShadowGenerator.maxDistance);
    }
    return shadowGenerator;
  }
}
CascadedShadowGenerator._FrustumCornersNDCSpace = [new Vector3(-1.0, +1.0, -1.0), new Vector3(+1.0, +1.0, -1.0), new Vector3(+1.0, -1.0, -1.0), new Vector3(-1.0, -1.0, -1.0), new Vector3(-1.0, +1.0, +1.0), new Vector3(+1.0, +1.0, +1.0), new Vector3(+1.0, -1.0, +1.0), new Vector3(-1.0, -1.0, +1.0)];
/**
 * Name of the CSM class
 */
CascadedShadowGenerator.CLASSNAME = "CascadedShadowGenerator";
/**
 * Defines the default number of cascades used by the CSM.
 */
CascadedShadowGenerator.DEFAULT_CASCADES_COUNT = 4;
/**
 * Defines the minimum number of cascades used by the CSM.
 */
CascadedShadowGenerator.MIN_CASCADES_COUNT = 2;
/**
 * Defines the maximum number of cascades used by the CSM.
 */
CascadedShadowGenerator.MAX_CASCADES_COUNT = 4;
/**
 * @internal
 */
CascadedShadowGenerator._SceneComponentInitialization = _ => {
  throw _WarnImport("ShadowGeneratorSceneComponent");
};
