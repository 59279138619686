<div
  uiTrapFocus
  class="modal-container"
  [class.backdrop]="hasBackdrop"
  [class.allow-header]="allowHeaderClick"
  [class.allow-background]="allowBackgroundClick"
  [style.zIndex]="zIndex"
  (click)="onContainerClick($event)"
>
  <ng-template modalContainer></ng-template>
</div>
