<ui-panel [title]="'oxr.creatingSpace.panels.customization.materials' | translate" [collapsing]="true" [panelId]="panelMask.Materials">
  <div>
    <ui-radio-group [options]="materialOptions" [value]="material?.materialId" (onChecked)="onMaterialSelect($event)"></ui-radio-group>
  </div>
  <div class="separator"></div>
  <ui-switch
    class="icon-style-switch"
    [checked]="outlinesEnabled"
    [label]="'oxr.creatingSpace.panels.customization.showOutlines' | translate"
    [onShowContentMode]="false"
    (changed)="toggleShowSelection($event)"
  ></ui-switch>
  <!-- Material metadata -->
</ui-panel>
