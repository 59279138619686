<!-- prettier-ignore -->
<div class="container">
  <div class="icon icon-close sm" (click)="onClose()"></div>
OwnXR 이용약관

제1조 (목적)

①이 약관은 주식회사 유룸(이하 “회사”라 합니다)이 OwnXR 웹사이트 (정보 : www.app.ownxr.com / www.uroom.io )를 통해 제공하는 플랫폼 서비스(이하 “서비스”라 합니다)를 이용함에 있어 회사와 이용자 사이의 권리ㆍ의무 및 책임 사항을 규정함을 목적으로 합니다.

② 이 약관은 회사가 제공하는 서비스의 이용에 한하여 적용되며, 제휴사에서 제공하는 서비스에 대해서는 제휴사의 약관이 적용됩니다.

제2조 (정의)

① “이용자”란 회사의 웹사이트에 접속하여 이 약관에 따라 회사가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.

② “회원”이라 함은 이 약관에 동의함으로써 회사와 이용계약을 체결하고 회사가 제공하는 정보와 서비스를 이용하는 자를 말합니다.

③ “비회원”이라 함은 이용계약을 하지 않고 회사가 제공하는 서비스를 이용하는 자를 말합니다.

④ "아이디(ID)"라 함은 회원의 식별과 서비스 이용을 위하여 회원이 정하고 회사가 승인하는 이메일 주소 및 기타 SNS(소셜네트워크서비스) 계정의 식별자를 의미합니다.

⑤ "비밀번호(PASSWORD)"라 함은 회원이 부여받은 아이디와 일치되는 회원임을 확인하고 비밀번호를 위해 회원 자신이 정한 문자 또는 숫자의 조합을 의미합니다.

⑥ “OwnXR”이라 함은 이용자가 회사의 서비스를 통해 생성한 메타버스 공간의 이름을 의미합니다.

⑦ “구독”이라 함은 정기 월단위 유료 상품을 의미합니다.

⑦ 전항 각호에 해당하는 정의 외에 기타 용어의 정의에 대하여는 거래 관행 및 관계 법령에 따릅니다.

제3조 (약관의 명시 및 개정)

① 회사는 회사의 상호, 대표자 성명, 주소, 전화번호(소비자의 불만을 처리할 수 있는 곳의 연락처 포함), 전화번호, 전자우편주소, 사업자등록번호, 통신 판매업 신고번호, 개인정보 관리 책임자 등을 이용자가 쉽게 알 수 있도록 웹사이트 초기 화면에 게시합니다. 다만, 약관의 내용은 이용자가 별도의 연결화면을 통하여 볼 수 있도록 게시합니다.

② 회사는 약관의 규제 등에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망 이용촉진 등에 관한 법률 등 관계 법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.

③이 약관은 이용자가 약관의 내용에 동의 후 구독 결제를 신청하고, 회사가 그 신청을 승낙함으로써 효력이 발생합니다.

④ 이 약관에 명시되지 않은 사항에 대해서는 약관의 규제 등에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망 이용촉진 등에 관한 법률 등 관계 법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.

⑤ 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여, 개정 전 약관과 함께 적용일자 7일 전부터 웹사이트에서 확인할 수 있도록 공지합니다. 다만, 회원의 권리 또는 의무에 관한 중요한 규정의 변경은 최소한 30일 전에 공지하고 개정약관을 회원이 가입시 등록한 ID인 이메일 계정으로 개별적으로 통지합니다. 회사는 회원 전체에 대한 통지를 14일 이상 회사 웹사이트 게시판에 게시함으로써 전항의 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래 및 사이트의 이용과 관련하여 중대한 영향을 미치는 사항은 개별통지를 합니다.

⑥ 회사가 전항에 따라 공지 또는 통지를 하면서 공지 또는 통지일로부터 개정약관 시행일까지 거부의사를 표시하지 아니하면 승인한 것으로 본다는 뜻을 명확하게 알렸음에도 이용자의 의사표시가 없는 경우에는 변경된 약관을 승인한 것으로 봅니다. 개정 약관에 동의하지 않는 경우, 회사는 서비스 이용 계약을 해지할 수 있습니다.

⑦ 이 약관에서 정하지 아니한 사항에 대해서는 「약관의 규제에 관한 법률」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「전자상거래 등에서의 소비자보호에 관한 법률」, 「이러닝(전자학습)산업 발전 및 이러닝 활용 촉진에 관한 법률」, 「콘텐츠산업 진흥법」 및 기타 관련법령 또는 상관례에 따릅니다.

제4조 (서비스 이용)

회사는 다음과 같은 업무를 수행합니다.

① OwnXR 서비스 제공

② OwnXR 서비스 정보 제공

③ 구독 서비스 상품 정보 제공

회사는 전 항 각호의 사항을 회사 웹사이트, 본 이용약관, 개인정보처리방침 등에서 이미 고지하고 있는 경우 별도로 표시하지 않을 수 있습니다.

구독 상품 명칭, 종류, 내용, 가격, 이용방법, 이용기간

환불기준 등 서비스 이용계약의 해지 안내

소비자피해보상의 처리, 서비스에 대한 불만 처리 및 소비자와 회사 간 분쟁 처리에 관한 사항

거래에 관한 약관

④ 구독 상품 계약의 체결

⑤ 체결된 구독 계약 서비스 제공

⑥ 가상 자산 상품 정보 제공

⑦ 가상 자산 상품 등록 계약의 체결

⑧ 체결된 가상 자산 상품 판매 및 정산 서비스 제공

회사는 다음 각호에 해당하는 사항을 해당 구독 서비스명과 함께 표시합니다.

제5조 (서비스 계약 체결의 성립)

① 회원은 회사가 제공하는 다음 각호 또는 이와 유사한 절차에 의하여 서비스를 신청합니다. 회사는 계약 체결 전, 다음 각호의 사항에 관하여 회원이 정확하게 이해하고 실수 또는 착오 없이 거래할 수 있도록 정보를 제공합니다.

비회원/회원

OwnXR 플랫폼 메타버스 가상 공간 제작 서비스 무료 제작 및 이용

회원

구독 서비스 상품 목록의 열람 및 선택

구독 서비스 상품 상세항목 및 청약철회, 환불 조건 등 확인

이용약관 확인, 회원 가입, OwnXR 생성 정보 및 결제 정보 입력

결제하기 클릭

구독 서비스 상품 및 결제금액 확인

결제수단 선택

결제금액 재확인

결제

구독 서비스 개설

가상 자산 등록 및 게시(선택)

가상 자산 판매 수익금 정산(선택)

② 회사는 회원의 구독 서비스 신청이 다음 각호에 해당하는 경우에는 승낙하지 않거나, 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.

구독 서비스 요금을 납입하지 않는 경우

구독 서비스 신청 금액 총액과 입금 총액이 일치하지 않는 경우

이용신청 내용에 허위, 기재누락, 오기가 있는 경우

기타 이용신청을 승낙하는 데 기술상 지장이 있는 등 합리적인 이유가 인정되는 경우

제6조 (서비스의 중단 및 변경)

① 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 콘텐츠서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 회사는 제 10조 (회원에 대한 통지)에 정한 방법으로 회원에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.

② 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.

③ 회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 서비스를 변경할 수 있습니다.

④ 회사는 서비스의 내용, 이용방법, 이용시간을 변경할 경우에 변경사유, 변경될 서비스의 내용 및 제공일자 등을 그 변경 전 7일 이상 해당 서비스 초기화면에 게시합니다.

⑤ 제4항의 경우에 변경된 내용이 중대하거나 회원에게 불리한 경우에는 회사가 해당 서비스를 제공받는 회원에게 제 3조(약관의 명시 및 개정)에 정한 방법으로 통지하고 동의를 받습니다. 이때, 회사는 동의를 거절한 회원에 대하여는 변경전 서비스를 제공합니다. 다만, 그러한 서비스 제공이 불가능할 경우 계약을 해지할 수 있습니다.

⑥ 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다

제7조 (회원가입)

① 이용자는 회사가 정한 가입 양식에 따라 회원 정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로써 회원가입을 신청합니다.

② 회사는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각호에 해당하지 않는 한 회원으로 등록합니다.

가입 신청자가 이 약관 제7조 제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제8조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 한다.

등록 내용에 허위, 기재누락, 오기가 있는 경우

기타 회원으로 등록하는 것이 회사의 기술상 현저히 지장이 있다고 판단되는 경우

③ 회원가입 계약의 성립 시기는 회사의 승낙이 회원에게 도달한 시점으로 합니다.

④ 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 회사에 대하여 회원 정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.

제8조 (회원 탈퇴 및 자격 상실 등)

① 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 영업일 2일 이내 회원탈퇴를 처리합니다.

② 회원이 다음 각호의 사유에 해당하는 경우, 회사는 회원자격을 제한 및 정지시킬 수 있습니다.

가입 신청 시에 허위 내용을 등록한 경우

구독 서비스 대금, 기타 서비스 이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우

다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우

서비스를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우

③ 회사가 회원 자격을 제한, 정지시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회사는 회원자격을 상실시킬 수 있습니다.

④ 회사가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.

제9조 (이용자가 등록한 게시물의 이용 및 삭제)

① 회사는 이용자가 등록한 게시물이 다음의 어느 하나에 해당하는 표현을 포함하는 경우 회원에게 삭제를 요청하거나 회사가 직접 삭제할 수 있습니다

대한민국 또는 외국의 법령에 위반되는 표현

대한민국 또는 외국에서의 범죄와 관련되는 표현

음란성ㆍ포악성ㆍ잔인성ㆍ사행성 등이 포함된 표현

사회질서를 파괴하는 표현

회사 또는 제3자의 명예를 훼손하는 표현

회사 및 제3자의 지식재산권을 침해하는 표현

개인의 인권 또는 평등권을 침해하는 차별적 표현

② 이용자가 제작한 생성된 OwnXR로 인하여 법률상 이익이 침해된 자는 회사에게 당해 정보의 삭제 또는 반박내용의 게재를 요청할 수 있습니다. 이 경우 회사는 지체없이 필요한 조치를 취하고 결과를 신청인에게 즉시 통지합니다.

③ 회사는 회사가 제공하는 웹사이트에 이용자가 생성한 OwnXR를 이용자의 사전 동의를 받아 이용ㆍ수정하거나 마케팅 및 출판 등에 활용할 수 있습니다.

④ 이용자는 전항의 동의를 철회할 수 있으며 회사는 회원의 철회 의사표시를 받은 이후부터 해당 게시물을 사용하지 않습니다.

제10조 (결제방법)

회사에서 구매한 서비스 대한 대금 지급방법은 다음 각호의 방법 중 가용한 방법으로 할 수 있습니다. 단, 회사는 이용자의 지급 방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수하지 않습니다.

① 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체

② 선불카드, 직불카드, 신용카드 등의 각종 카드 결제

③ 온라인무통장입금

④ 전자화폐에 의한 결제

⑤ 회사와 계약을 맺었거나 회사가 인정한 상품권에 의한 결제

⑥ 기타 전자적 방법에 의한 대금 지급 등

제11조 (환급)

회사는 회원의 구독 상품이 서비스 종료 등의 사유로 제공할 수 없을 때에는 지체없이 그 사유를 회원에게 통지하고 결제 대금을 환급하거나 환급에 필요한 조치를 취합니다.

제12조 (청약철회 및 환불)

① 회사는 다음과 같은 환불 규정을 적용합니다.

회원이 환불하고자 하는 경우 회사 웹사이트나 전화 등의 방법으로 그 의사를 표시하여야 하며, 회사는 환불 요청을 접수하고 회원의 요청과 환불 규정을 확인한 후, 3영업일 이내 환불합니다. 단, 회사의 책임 영역 외의 금융사의 전산 마비 등 외부적 사정에 의하여 지연되는 경우 회사는 책임을 지지 않습니다.

이용계약 체결 일을 기준으로 결제 시 신청한 구독 신청 기간 동안 매월 단위로 결제되며, 이미 결제가 이루어진 경우 다음 월부터 서비스가 해제됩니다.

회원이 구독을 해제∙해지했을 경우, 해당 월 남은 기간의 서비스는 정상적으로 이용하실 수 있으며, 다음 월 결제일 부터 자동 결제가 이루어 지지 않습니다.

이벤트 및 프로모션 적용 대상 결제의 경우, 환불 규정과 상의하며 청약철회 의사를 밝힌 이후 회사와 협의 하여 진행할 수 있습니다.

② 회사는 회원으로부터 2영업일 이내 구독 서비스 개시 전 철회를 요청받은 경우 지급받은 재화 등의 대금을 환급합니다. 이 경우 회사가 회원에게 환급을 지연할 때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」 제21조의 3에서 정하는 지연 이자율을 곱하여 산정한 지연 이자를 지급합니다.

② 회사는 위 대금 환급에 있어 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자가 재화 등의 대금 청구를 정지 또는 취소하도록 요청합니다.

③ 청약 철회 등의 경우 반환에 필요한 비용은 이용자가 부담합니다. 회사는 이용자에게 청약 철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 서비스 내용이 표시·광고 내용과 다르거나 계약 내용과 다르게 이행되어 청약 철회 등을 하는 경우 반환에 필요한 비용은 회사가 부담합니다.

제13조 (개인정보보호)

① 회사는 이용자의 개인정보 수집 시 서비스 제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.

② 회사는 회원가입 시 결제이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.

③ 회사는 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.

④ 회사는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.

⑤ 회사는 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보 제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조 제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.

⑥ 이용자는 언제든지 회사는 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 회사는 이에 대해 지체 없이 필요한 조치를 할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 회사는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.

⑦ 회사는 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며 신용카드, 은행 계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.

⑧ 회사 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.

⑨ 회사는 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절 시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.

제14조 (회사의 의무)

① 회사는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화․용역을 제공하는 데 최선을 다하여야 합니다.

② 회사는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.

③ 회사는 구독 서비스 이용과 관련여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 지체 없이 처리합니다. 회원이 제기한 의견이나 불만 사항에 대해서는 이메일을 통해 그 처리 과정 및 결과를 전달합니다.

④ 회사는 회사의 관계 법령 또는 이 약관상의 의무 위반으로 인하여 회원이 입은 손해를 배상합니다.

⑤ 회사는 이용자가 원하지 않는 영리 목적의 광고성 전자우편을 발송하지 않습니다.

제15조 (이용자의 의무)

이용자는 다음 행위를 하여서는 안 됩니다.

① 서비스의 소스 코드에 대한 액세스를 수정, 분해, 디컴파일, 리버스 엔지니어링 또는 기타 방법으로 획득하려는 시도

② 과실 또는 고의로 회사 또는 타 이용자의 정보 도용, 이미지 훼손을 유발하는 OwnXR를 생성 및 이용하는 행위

③ 불법, 사기, 허위 또는 오해의 소지가 있거나, 외설 또는 폭력, 기타 공서양속에 반하는 OwnXR를 생성 및 이용하는 행위

④ 서비스를 통해 제3자의 지적재산권 또는 기타 권리를 침해할 수 있는 자료를 사용하는 행위

⑤ 경쟁 제품 또는 서비스를 구축을 목적으로 회사 또는 타 이용자의 OwnXR의 특징, 기능 또는 그래픽을 도용하는 행위

⑥ 회사의 서비스를 사용하여 성희롱, 명예훼손, 위협, 외설, 추행, 타인의 지적 재산권 침해 또는 기타 불법 행위로 인해 민사 책임을 발생시키거나 형사 범죄 일으키는 행위

⑦ 다른 하드웨어, 소프트웨어, 네트워크 등을 통해회사에서 제공하는 서비스의 성능을 손상, 비활성화, 파괴하는 등의 악영향을 미치는 행위

⑧ 보안 시스템에 연결된 모든 서버나 네트워의 를 손상, 비활성화, 과도한 부담등 회사의 서비스를 방해할 수 있는 활동

제16조 (정보의 제공 및 광고의 게재 등)

①회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 또는 유ㆍ무선 등의 방법으로 회원에게 제공할 수 있습니다. 이 경우 회사는 수신을 거절할 수 있는 방법을 함께 제공하며 회원은 언제든지 수신을 거절할 수 있습니다.

②회사는 서비스 제공에 관한 광고를 회사 웹사이트, 회원가입 시 회원이 제공한 연락처에 발송할 수 있습니다. 이 경우 회사는 수신을 거절할 수 있는 방법을 함께 제공하며 회원은 언제든지 수신을 거절할 수 있습니다.

제17조 (저작권의 귀속 및 이용 제한)

① 회사는 특허, 상표, 서비스 마크, 저작권, 도덕적 권리, 노하우 또는 기타 지적 재산권 또는 산업적 재산권 등의 소유권을 갖습니다.

② 생성된 모든 OwnXR의 다른 이용자가 만든 콘텐츠 및 정보를 사용하는 것은 해당 이용자의 책임입니다. 회사는 이용자가 만든 OwnXR를 검토하지 않습니다.

제18조 (면책조항)

① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.

② 회사는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 등에 따라 회원정보를 포함한 회사의 데이터 일체에 관하여 보안에 필요한 기술적 조치를 취하고 있습니다. 다만, 회사는 위와 같은 기술적 조치에도 불구하고 제3자가 해킹, 컴퓨터바이러스 등의 방법으로 회사의 정보통신망 또는 이와 관련된 정보시스템을 공격하는 행위를 하여 발생한 사태로 인하여 회원에게 발생한 손해는 책임을 부담하지 아니합니다.

③ 회사는 회원의 귀책사유로 인하여 발생한 서비스 이용의 장애는 책임을 지지 않습니다.

④ 회사는 회원이 서비스와 관련하여 생성한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용은 책임을 지지 않습니다.

⑤ 회사는 이용자 상호간 또는 이용자와 제3자 간에 콘텐츠를 매개로 하여 발생한 분쟁 등에 대하여 책임을 지지 않습니다

제19조 (분쟁 해결)

① 회사는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상 처리하기 위하여 피해 보상처리기구를 설치․운영합니다.

② 회사는 이용자로부터 제출되는 불만 사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리 일정을 즉시 통보해 드립니다.

③ 회사와 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁 조정기관의 조정에 따를 수 있습니다.

제20조 (재판권 및 준거법)

① 이 약관의 해석과 적용은 대한민국 법령에 의합니다.

② 회사와 이용자 간 발생한 소송의 관할은 서울서부지방법원 또는 이용자의 소재지 법원으로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.

③ 해외에 주소나 거소가 있는 회원의 경우 회사와 회원 간 발생한 분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을 관할 법원으로 합니다.

이 약관은 2022년 11월 07일부터 시행합니다.



개인정보처리방침

 주식회사 유룸(이하 '회사'라 함)는 개인정보보호법 제30조에 따라 이용자(또는 회원)의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립ㆍ공개 합니다

 Shape

 제1조 (개인정보의 처리 목적, 항목 및 수집 방법)

 ① 개인정보 처리 목적

 회사는 아래의 목적으로 개인정보를 처리하고 있습니다. 회사는 처리 목적 이외의 용도로는 각 항목을 처리하지 않으며, 처리 목적이 변경되는 경우에는 개인정보보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행하겠습니다.

 목적

 상세 내용

 회원관리

 회원 개인 식별

 서비스 제공

 플랫폼 서비스 제공

 서비스 결제

 서비스 구독 대금 결제

 서비스 구매 정보 관리



 고객 문의 응대

 기술지원

 서비스 개선

 서비스 이용 로그 기록

 서비스 이용에 대한 통계



 마케팅 및 광고

 서비스, 이벤트 등 광고성 정보 전달

 SMS, E-mail 등을 통한 마케팅 및 이벤트 안내



 ② 개인정보 처리 항목

 회사가 처리하는 이용자의 개인정보는 아래와 같으며, 회사는 회원이 입력한 정보를 기본으로 개인정보의 처리 목적에 필요한 최소한의 정보를 수집하고 있습니다.

 회사는 개별 서비스 이용, 이벤트 응모 및 경품 신청 과정에서 해당 서비스의 이용자에 한해 추가 개인정보 수집을 할 수 있습니다. 추가로 개인정보를 수집할 경우 해당 개인정보 수집 시점에 이용자에게 수집하는 개인정보의 수집ㆍ이용목적 개인정보의 보관 기간에 안내를 드리고 동의를 받겠습니다

 구분

 수집하는 개인정보

 회원아이디 (필수)

 이메일, 비밀번호

 소셜로그인 이메일



 서비스 결제

 주문자이름, 주문자휴대전화

 회원아이디



 결제방법에 따른 정보



 신용카드 : 카드사명, 카드번호, 거래번호, 결제승인번호

 무통장입금 : 은행 명, 가상계좌번호, 입금자명, 거래번호

 결제 서비스 과정명, 결제연월일 결제정보 | | 서비스 결제 | 서비스 구독 대금 결제 서비스 구매 정보 관리 | | 고객 문의 응대 | 회원아이디 (이메일) OwnXR 생성 명 | | 기존 서비스 개선 | 서비스 이용 로그 기록, 쿠키 | | 마케팅 및 광고 (선택) | 회원아이디 (이메일) |

 ③ 개인정보 수집 방법

 서비스 가입이나 사용 중 이용자의 동의를 한 수집

 회원의 선택에 따라 SNS 로그인하는 회원의 경우 해당 회사에서 회원에게 동의를 받은 후 제공받아 수집

 문의게시판, 이벤트 응모 등을 통한 수집

 서비스 이용 기록 등의 서비스 이용과정에서 자동으로 수집

 제2조 (개인정보의 처리 및 보유기간)

 회사는 법령에 따른 개인정보 보유·이용기간 또는 회원으로 부터 개인정보 수집 시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다. 회원의 개인정보는 원칙적으로 개인정보의 수집 및 이용 목적이 달성된 후 지체 없이 파기되며 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존하며, 그 보존 목적으로만 이용합니다.

 가. 소비자의 불만 또는 분쟁처리에 관한 기록

 보관이유: 전자상거래 등에서의 소비자보호에 관한 법률

 보관기간: 3년

 나. 계약 또는 청약철회 등에 관한 기록

 보관이유: 전자상거래 등에서의 소비자보호에 관한 법률

 보관기간: 5년

 다. 대금결제 및 재화 등의 공급에 관한 기록

 보관이유: 전자상거래 등에서의 소비자보호에 관한 법률

 보관기간: 5년

 라. 건당 거래금액이 1만원을 초과하는 전자금융거래에 관한 기록

 보관이유: 전자금융거래법

 보관기간: 5년

 마. 건당 거래금액이 1만원 이하인 전자금융거래에 관한 기록

 보관이유: 전자금융거래법

 보관기간: 1년

 바. 서비스 이용에 관한 로그기록

 보관이유: 통신비밀보호법

 보관기간: 3개월

 제3조 (개인정보의 제3자 제공)

 회사는 이용자의 사전 동의 없이 개인정보를 외부에 제공하지 않습니다. 또한, 이용자가 개인정보 제공에 직접 동의를 한 경우, 그리고 법률의 특별한 규정 등 개인정보보호법 제17조 및 제18조에 해당하는 경우가 아닌 한, 명시한 범위를 초과하여 처리하거나 개인정보를 제3자에게 제공하지 않습니다.

 제4조 (개인정보의 파기절차 및 방법)

 회사는 개인정보 보유기간 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는, 지체 없이 해당 개인정보를 파기합니다. 개인정보의 파기절차 및 파기방법은 다음과 같습니다.

 ① 파기절차 및 기한

 회사는 파기 사유가 발생한 개인정보를 선정하고, 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다. 개인정보 보유기간이 경과한 경우에는 그 경과한 날로부터, 개인정보의 처리목적 달성, 회사 사업의 종료 등 그 개인정보가 불필요하게 된 경우에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 지체 없이 해당 개인정보를 파기합니다.

 ② 파기방법

 전자적 파일 형태로 기록ㆍ저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록ㆍ저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.

 제5조 (개인정보처리의 위탁)

 구분

 수집하는 개인정보

 이니시스

 서비스 요금 결제

 제6조 (개인정보 자동 수집 장치의 설치·운영 및 그 거부에 관한 사항)

 회사는 이용자에게 원활한 서비스 제공을 위하여 이용자들의 정보를 저장하고, 수시로 불러내는 찾아내는 ‘쿠키(cookie)’등을 운용합니다. 쿠키란 웹사이트를 운영하는 데 이용되는 서버가 회원의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장되기도 합니다(쿠키의 의미).

 회사는 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 회원의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 횟수 파악 등을 통한 타깃 마케팅 및 개인 맞춤 서비스 제공 등의 목적으로 쿠키를 사용합니다(쿠키의 사용 목적).

 회원은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 회원은 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다(쿠키의 설치, 운영 및 거부). 다만, 이용자가 쿠키 설치를 거부하는 경우 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.

 제3항에 따라 쿠키 설정을 거부하는 방법으로, 회원은 사용하는 웹 브라우저에서 설정을 선택할 수 있습니다.

 Google 애널리틱스 차단 브러우저 부가 기능 설정

 제7조 (회원과 법정대리인의 권리.의무 및 행사방법)

 이용자는 언제든지 자신의 개인정보를 열람, 공개 및 비공개 처리하거나 수정할 수 있으며, 삭제 및 파기를 요청할 수 있습니다.

 이용자의 권리는 회사에 대해 서면, 전자우편 등을 통하여 행사할 수 있으며, 회사는 이에 대해 지체 없이 필요한 조치를 하겠습니다. 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제4항, 제37조 제2항에 의하여 이용자의 권리가 제한될 수 있습니다. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에 그 삭제를 요구할 수 없습니다.

 이용자의 권리는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 행사할 수 있습니다. 이 경우 「개인정보보호법 시행규칙」 [별지 제11호 서식]에 따른 위임장을 제출하셔야 합니다. 회사는 이용자 권리에 따른 열람의 요구, 정정ㆍ삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 정당한 대리인인지 확인합니다.



 제8조 (개인정보보호를 위한 회사의 노력)

 회사는 개인정보의 안전한 관리를 위하여 다음과 같은 조치를 취하고 있습니다.

 내부관리계획 수립,시행, 정기적 직원 교육, 정기적 법적 준거성 검토 등

 제9조 (개인정보관리책임자 연락처)







 제10조 (개정 및 고지 의무)

 개인정보 처리방침의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소 7일 전에 ‘공지사항’을 통해 사전 공지를 할 것입니다. 다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의 중대한 변경이 발생할 때에는 최소 30일 전에 공지합니다.

 시행일자: 2022년 11월 07일
</div>
