@if (extendedOptions.imageSrc) {
  <img class="img" [src]="extendedOptions.imageSrc" />
}
@if (!extendedOptions.imageSrc) {
  <div class="icon-container">
    @if (extendedOptions.toastIcon) {
      <div class="icon icon-{{ extendedOptions.toastIcon }}"></div>
    }
    @if (!extendedOptions.toastIcon && toastType === 'toast-success') {
      <div class="icon icon-yes"></div>
    }
    @if (!extendedOptions.toastIcon && toastType === 'toast-error') {
      <div class="icon icon-close-circle"></div>
    }
    @if (!extendedOptions.toastIcon && (toastType === 'toast-info' || !toastType)) {
      <div class="icon icon-info"></div>
    }
  </div>
}
<div class="message-container">
  @if (title) {
    <div [class]="extendedOptions.titleClass" [attr.aria-label]="title" class="custom-title">
      {{ title }}
    </div>
  }
  @if (message && extendedOptions.enableHtml) {
    <div role="alert" aria-live="polite" class="custom-message">
      <div [innerHTML]="message"></div>
    </div>
  }
  @if (message && !extendedOptions.enableHtml) {
    <div role="alert" aria-live="polite" class="custom-message" [attr.aria-label]="message">
      {{ message }}
    </div>
  }
  <div class="action-container">
    @if (extendedOptions.showExtraAction) {
      <a ui-button xs class="extra-action-button" (click)="action($event)">
        {{ extendedOptions.extraActionLabel | translate }}
      </a>
    }
  </div>
</div>
<div class="icon-wrapper close" (click)="remove()">
  <div class="icon icon-close xs"></div>
</div>
<!-- <div *ngIf="extendedOptions.progressBar">
<div class="toast-progress" [style.width]="width + '%'"></div>
</div> -->
