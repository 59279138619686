import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountService } from './account.service';
import { lastValueFrom } from 'rxjs';
import { environment } from 'projects/app/src/environments/environment';

export interface Email {
  toEmail: string;
  subject: string;
  plainTextContent: string;
  htmlContent: string;
  fromEmail: string;
}

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private noAuthHttp: HttpClient;

  constructor(public http: HttpClient, private _accountService: AccountService, handler: HttpBackend) {
    this.noAuthHttp = new HttpClient(handler);
  }

  async sendEmail(email: Email) {
    return await lastValueFrom(
      this.noAuthHttp.post(`${environment[this._accountService.region].crudApiUrl}/Email`, JSON.stringify(email), {
        headers: { 'Content-Type': 'application/json' },
      }),
    );
  }
}
