import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { AccountService } from '@/data/src/lib/services/account.service';
import { ApplicationService } from '@/view/src/app/app.service';
import { PopupType, TInteractableElement } from '@/data/src/lib/view-manager';
import { Account } from '@/data/src/lib/models/data/account';
import { TooltipPosition } from '@/ui/src/lib/directive/tooltip.directive';
import { ScenePlan, ScenePlanDetail } from '@/data/src/lib/enums/pricing-plan';
import { PricingPlanService } from '@/data/src/lib/services/pricing-plan.service';

@UntilDestroy()
@Component({
  selector: 'ui-link-tool',
  templateUrl: './link-tool.component.html',
  styleUrls: ['./link-tool.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkToolComponent implements OnInit {
  data: { [key: string]: any };
  selectedElement?: TInteractableElement;
  scenePlan: ScenePlan | undefined;

  link = '';
  openWithoutPrompt: boolean;
  toolTipPositions = TooltipPosition;
  openOnCurrentTab: boolean;
  loggedAccount: Account | undefined;

  get isScenePlanPropertyRestriction() {
    return this._pricingPlanService.hasScenePlanPropertyRestriction(this.scenePlan!, ScenePlanDetail.RestrictedContentWithinSpaceUsage);
  }

  constructor(
    private _modalService: ModalService,
    private _appService: ApplicationService,
    private _accountService: AccountService,
    private _pricingPlanService: PricingPlanService,
  ) {
    this._accountService.activeAccount$.pipe(untilDestroyed(this)).subscribe((acc) => (this.loggedAccount = acc));
  }

  ngOnInit(): void {
    this.link = this.data.find(({ key }) => key === 'link')?.value ?? '';
    this.openWithoutPrompt = this.data.find(({ key }) => key === 'openWithoutPrompt')?.value ?? false;
    this.openOnCurrentTab = this.data.find(({ key }) => key === 'openOnCurrentTab')?.value ?? false;
  }

  close() {
    this._modalService.close(false, this['modalContainerRef']);
  }
  setDirectRedirect(value) {
    this.openWithoutPrompt = value;
  }

  setOpenOnCurrentTab(value) {
    this.openOnCurrentTab = value;
  }

  save() {
    const oldData = this.selectedElement?.parameters?.popups?.length ? this.selectedElement.parameters.popups[0] : {};
    this.selectedElement &&
      this._appService.updateViewElement(
        {
          ...this.selectedElement,
          parameters: {
            ...this.selectedElement.parameters,
            popups: [
              {
                ...oldData,
                type: PopupType.Link,
                parameters: [
                  { key: 'link', value: this.link },
                  { key: 'openWithoutPrompt', value: this.openWithoutPrompt },
                  { key: 'openOnCurrentTab', value: this.openOnCurrentTab },
                ],
              },
            ],
          },
        } as TInteractableElement,
        true,
      );
    this._modalService.close(this.link, this['modalContainerRef']);
  }
}
