<span class="ql-formats">
  <div [uiTooltip]="'quillTooltips.font' | translate" [tooltipClasses]="'quill-tooltips'">
    <select class="ql-font">
      <option value="arial"></option>
      <option value="baijamjuree"></option>
      <option value="notosans"></option>
      <option value="paybooc"></option>
      <option value="roboto"></option>
      <option value="seoulnamsan"></option>
      <option value="serif"></option>
      <option value="monospace"></option>
    </select>
  </div>
</span>
<span class="ql-formats">
  <div [uiTooltip]="'quillTooltips.style' | translate" [tooltipClasses]="'quill-tooltips'">
    <select class="ql-header">
      <option value="1"></option>
      <option value="2"></option>
      <option value="3"></option>
      <option selected="selected"></option>
    </select>
  </div>
</span>
<span class="ql-formats">
  <div [uiTooltip]="'quillTooltips.color' | translate" [tooltipClasses]="'quill-tooltips'">
    <select class="ql-color"></select>
  </div>
  <div [uiTooltip]="'quillTooltips.background' | translate" [tooltipClasses]="'quill-tooltips'">
    <select class="ql-background"></select>
  </div>
</span>
<span class="ql-formats">
  <button class="ql-bold" [uiTooltip]="'quillTooltips.bold' | translate" [tooltipClasses]="'quill-tooltips'"></button>
  <button class="ql-italic" [uiTooltip]="'quillTooltips.italic' | translate" [tooltipClasses]="'quill-tooltips'"></button>
  <button class="ql-strike" [uiTooltip]="'quillTooltips.strike' | translate" [tooltipClasses]="'quill-tooltips'"></button>
  <button class="ql-underline" [uiTooltip]="'quillTooltips.underline' | translate" [tooltipClasses]="'quill-tooltips'"></button>
</span>
<span class="ql-formats">
  <div [uiTooltip]="'quillTooltips.align' | translate" [tooltipClasses]="'quill-tooltips'">
    <select class="ql-align">
      <option selected></option>
      <option value="center"></option>
      <option value="right"></option>
      <option value="justify"></option>
    </select>
  </div>
  <button class="ql-list" value="bullet" [uiTooltip]="'quillTooltips.bullet' | translate" [tooltipClasses]="'quill-tooltips'"></button>
  <button class="ql-list" value="ordered" [uiTooltip]="'quillTooltips.ordered' | translate" [tooltipClasses]="'quill-tooltips'"></button>
</span>
<span class="ql-formats">
  <button class="ql-blockquote" [uiTooltip]="'quillTooltips.blockquote' | translate" [tooltipClasses]="'quill-tooltips'"></button>
</span>
@if (!isHidden['link']) {
  <span class="ql-formats">
    <button class="ql-link" [uiTooltip]="'quillTooltips.link' | translate" [tooltipClasses]="'quill-tooltips'"></button>
  </span>
}
@if (!isHidden['image']) {
  <span class="ql-formats">
    <button class="ql-image" [uiTooltip]="'quillTooltips.image' | translate" [tooltipClasses]="'quill-tooltips'"></button>
  </span>
}
@if (!isHidden['video']) {
  <span class="ql-formats">
    <button class="ql-video" [uiTooltip]="'quillTooltips.video' | translate" [tooltipClasses]="'quill-tooltips'"></button>
  </span>
}
