import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Options } from 'ngx-slider-v2';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from '@/view/src/app/app.service';
import { PanelComponent } from '@/ui/src/lib/components/panel/panel.component';
import { EFFECT_DEFAULTS, EffectType, InteractionTrigger, MoveMode, TElement, TInteractableElement } from '@/data/src/lib/view-manager';
import { ViewManagerUtils } from '@/data/src/lib/utils/view-manager-utils';
import { deepCopy } from '@/data/src/lib/utils/data';
import { ScenePlan, ScenePlanDetail } from '@/data/src/lib/enums/pricing-plan';
import { PricingPlanService } from '@/data/src/lib/services/pricing-plan.service';

@Component({
  selector: 'ui-effect-panel',
  templateUrl: './effect-panel.component.html',
  styleUrls: ['./effect-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EffectPanelComponent extends PanelComponent {
  _isScenePlanPropertyRestriction = true;
  _element: TInteractableElement;
  parameters: { [key: string]: any };
  _parameters: { [key: string]: any };

  @Input() set element(input: TElement | undefined) {
    if (!input) {
      return;
    }
    this._element = deepCopy(input) as TInteractableElement;
    this.parameters = {};
    this._parameters = {};
    this.effect?.parameters.forEach(({ key, value }) => {
      switch (key) {
        case 'speed':
        case 'height':
        case 'percent':
        case 'distance':
          this.parameters[key] = Number(value);
          break;
        case 'color':
          this.parameters[key] = value;
          this.shineColor = value;
          break;
        default:
          this.parameters[key] = value;
          break;
      }
      this._parameters[key] = this.parameters[key];
    });
    this._cd.detectChanges();
  }

  @Input() set isScenePlanPropertyRestriction(scenePlan: ScenePlan) {
    this._isScenePlanPropertyRestriction = this._pricingPlanService.hasScenePlanPropertyRestriction(
      scenePlan,
      ScenePlanDetail.RestrictedContentWithinSpaceUsage,
    );
  }

  get element(): TInteractableElement {
    return this._element as TInteractableElement;
  }

  get effect() {
    return this.element?.parameters?.effects?.[0];
  }

  effectOptions: any[] = [
    { name: EffectType.Spin, speed: 1, action: InteractionTrigger.Load, activeOnEdit: false },
    { name: EffectType.Bounce, disabled: true, speed: 2.5, height: 5, action: InteractionTrigger.Load, activeOnEdit: false },
    {
      name: EffectType.Move,
      disabled: true,
      speed: 2.5,
      distance: 5,
      mode: MoveMode.SideToSide,
      action: InteractionTrigger.Load,
      activeOnEdit: false,
    },
    { name: EffectType.Inflate, speed: 0.05, percent: 50, action: InteractionTrigger.Load, activeOnEdit: false },
    { name: EffectType.Roll, disabled: true, speed: 1, distance: 5, direction: 0, action: InteractionTrigger.Load, activeOnEdit: false },
    { name: EffectType.Shine, speed: 1, color: 5, action: InteractionTrigger.Load, activeOnEdit: false },
    { name: EffectType.Blink, speed: 1, action: InteractionTrigger.Load, activeOnEdit: false },
  ];

  moveDistanceOptions: Options = { floor: 0, ceil: 10, step: 0.1, showSelectionBar: true };
  moveSpeedOptions: Options = { floor: 0.1, ceil: 5, step: 0.1, showSelectionBar: true };

  spinSpeedOptions: Options = { floor: 0.1, ceil: 2, step: 0.1, showSelectionBar: true };

  bounceSpeedOptions: Options = { floor: 0.1, ceil: 5, step: 0.1, showSelectionBar: true };
  bounceHeightOptions: Options = { floor: 0, ceil: 10, step: 0.1, showSelectionBar: true };

  inflateSpeedOptions: Options = { floor: 0.1, ceil: 0.1, step: 0.01, showSelectionBar: true };
  inflatePercentOptions: Options = { floor: 0, ceil: 100, step: 1, showSelectionBar: true };

  rollDirectionOptions = { floor: 0, ceil: 360, step: 1, showSelectionBar: true };
  rollSpeedOptions: Options = { floor: 0.1, ceil: 5, step: 0.1, showSelectionBar: true };
  rollDistanceOptions: Options = { floor: 0, ceil: 10, step: 0.1, showSelectionBar: true };

  shineSpeedOptions: Options = { floor: 0.1, ceil: 5, step: 0.1, showSelectionBar: true };
  shineColor = EFFECT_DEFAULTS[EffectType.Shine].color;

  blinkSpeedOptions: Options = { floor: 0.1, ceil: 5, step: 0.1, showSelectionBar: true };

  get effectType() {
    return EffectType;
  }

  get moveMode() {
    return MoveMode;
  }

  get speedOptions(): Options {
    switch (this.effect?.type) {
      case EffectType.Spin:
        return { floor: 0.1, ceil: 2, step: 0.1, showSelectionBar: true };
      case EffectType.Inflate:
        return { floor: 0.01, ceil: 0.1, step: 0.01, showSelectionBar: true };
      default:
        return { floor: 0.1, ceil: 5, step: 0.1, showSelectionBar: true };
    }
  }

  get effectActionOptions() {
    return [
      {
        label: this._translateService.instant('oxr.creatingSpace.panels.effect.mouseOver'),
        value: InteractionTrigger.Hover,
      },
      {
        label: this._translateService.instant('oxr.creatingSpace.panels.effect.click'),
        value: InteractionTrigger.Click,
      },
      {
        label: this._translateService.instant('oxr.creatingSpace.panels.effect.repeat'),
        value: InteractionTrigger.Load,
      },
    ];
  }

  constructor(
    protected _appService: ApplicationService,
    private _translateService: TranslateService,
    private _pricingPlanService: PricingPlanService,
    private _cd: ChangeDetectorRef,
  ) {
    super(_appService);
  }

  onOptionChange(value, key) {
    if (!this.element || !this.effect) {
      return;
    }
    switch (key) {
      case 'color':
        this._parameters[key] = value;
        break;
      default:
        this._parameters[key] = String(value);
        break;
    }
    const index = this.effect.parameters.findIndex(({ key: k }) => k === key);
    const parameter = deepCopy(this.effect.parameters[index] ?? {});
    parameter.key = key;
    parameter.value = this._parameters[key];
    this._appService.updateViewElement({
      ...this.element,
      parameters: {
        ...this.element.parameters,
        effects: this.element.parameters.effects
          ? [
              {
                ...this.effect,
                parameters: [...this.effect.parameters.slice(0, index), parameter, ...this.effect.parameters.slice(index + 1)],
              },
              ...this.element.parameters.effects?.slice(1),
            ]
          : [{ ...this.effect, parameters: [...this.effect.parameters, { [key]: this._parameters[key] }] }],
      },
    } as TInteractableElement);
  }

  onTriggerChange(trigger: InteractionTrigger) {
    this._appService.updateViewElement(
      {
        ...this.element,
        parameters: {
          ...this.element.parameters,
          effects: this.element.parameters.effects
            ? [{ ...this.effect, trigger }, ...this.element.parameters.effects?.slice(1)]
            : [{ ...this.effect, trigger }],
        },
      } as TInteractableElement,
      true,
    );
  }

  onChangeEnd() {
    if (!this.element) {
      return;
    }
    const actualElement = this.element && this._appService.getViewRenderable(this.element)?.element;
    if (!actualElement) {
      return;
    }
    if (ViewManagerUtils.PropsDiff(this._parameters, this.element.parameters)) {
      this._appService.updateViewElement(actualElement);
    }
  }
}
