@if (label) {
  <div class="label">
    {{ label }}
  </div>
}
<label class="switch" [ngClass]="[size, type, onShowContentMode ? 'has-content' : '']" [class.disabled]="disabled">
  <input tabindex="-1" type="checkbox" [checked]="checked" (click)="onChanged()" />
  <span class="slider round" [id]="checked ? onContent : offContent">
    @if (onShowContentMode) {
      <div class="content">{{ checked ? offContent : onContent }}</div>
    }
  </span>
</label>
