import { Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { BehaviorSubject } from 'rxjs';
import { AccountService } from '../../../../data/src/lib/services/account.service';
import { Player } from '../models/player';
import { AssetService } from '../../../../data/src/lib/services/asset.service';
import { environment } from 'projects/app/src/environments/environment';

/**
 * Sends and receives transactions through signal r to allow for real time player movements and updates
 */
@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  private timestamp: number = Date.now();
  private started = false;
  private historyBehaviour = new BehaviorSubject<Player | undefined>(undefined);
  public history$ = this.historyBehaviour.asObservable();

  private playerExitBehaviour = new BehaviorSubject<Player>(<Player>{});
  public playerExit$ = this.playerExitBehaviour.asObservable();

  private playMovementSubject = new BehaviorSubject<{ x: number; y: number }>({ x: 0, y: 0 });
  public playerMovement$ = this.playMovementSubject.asObservable();

  private playerOrientationSubject = new BehaviorSubject<{ x: number; y: number } | undefined>(undefined);
  public playerOrientation$ = this.playerOrientationSubject.asObservable();
  public initialOrientation: { x: number; y: number };

  private playerJumpSubject = new BehaviorSubject<boolean>(false);
  public playerJump$ = this.playerJumpSubject.asObservable();

  constructor(
    private accountService: AccountService,
    private assetService: AssetService,
  ) {}

  private hubConnection!: signalR.HubConnection;

  /**
   * Checks if the connection has started
   * @returns true for started false otherwise
   */
  public connectionStarted(): boolean {
    return this.started;
  }

  /**
   * Starts the hub connection
   */
  public startConnection = async () => {
    if (!this.connectionStarted()) {
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(environment[this.accountService.region].messengerUrl + '/PlayerHub')
        .build();
      await this.hubConnection
        .start()
        .then(() => {
          console.log('Connection started');
          this.started = true;
        })
        .catch((err) => console.log('Error while starting connection: ' + err));
      this.addPlayerListener();
    }
  };

  /**
   * Adds a signal r listener to the hub connection
   */
  public addPlayerListener = () => {
    this.hubConnection.on('ReceiveTransaction', async (transaction: Player) => {
      if (
        (transaction.Username !== this.accountService.account?.Username ?? (await this.accountService.getSessionId())) &&
        transaction.Entered !== this.timestamp
      ) {
        this.historyBehaviour.next(transaction);
      }
    });

    this.hubConnection.on('PlayerLeft', async (transaction: Player) => {
      if (
        (transaction.Username !== this.accountService.account?.Username ?? (await this.accountService.getSessionId())) &&
        transaction.Entered !== this.timestamp
      ) {
        this.playerExitBehaviour.next(transaction);
      }
    });
  };

  /**
   * Reports when a player has left the scene
   * @param sceneId scene id of the scene the player is currently in
   * @param transaction the player that is exiting
   */
  public exitScene = async (sceneId: string, transaction: Player) => {
    this.hubConnection.invoke('ExitScene', sceneId, {
      Username: this.accountService.account?.Username ?? this.accountService.getSessionId(),
      Asset: transaction.Asset,
      Scene: transaction.Scene,
      Position: transaction.Position,
      Quaternion: transaction.Quaternion,
      Entered: this.timestamp,
    });
  };

  /**
   * Sends a player transaction to the hub connection
   * @param sceneId scene id of the scene the player is currently in
   * @param transaction the transaction made
   */
  public sendTransaction = async (sceneId: string, transaction: Player) => {
    if (this.hubConnection) {
      this.hubConnection.invoke('SendTransaction', sceneId, {
        Username: this.accountService.account?.Username ?? this.accountService.getSessionId(),
        Position: transaction.Position,
        Quaternion: transaction.Quaternion,
        Entered: this.timestamp,
      });
    }
  };

  /**
   * Begin listening to player changes within the scene
   * @param sceneId model to listen for changes
   */
  public listenToPlayerChanges = async (sceneId: string) => {
    await this.hubConnection.invoke('BeginPlayerTransactions', sceneId);
  };

  /**
   * Stop listening to player changes for the scene
   * @param sceneId model to listen for changes
   */
  public stopPlayerChanges = async (sceneId: string) => {
    await this.hubConnection.invoke('StopPlayerTransactions', sceneId);
  };

  /**
   * Gets the avatar asset associated with the input username
   * @param username
   * @returns the avatar of the user
   */
  public async getAvatar(username: string) {
    return await this.assetService.getUserAvatar(username);
  }

  /**
   * Set the Behaviour of the player to move in the input direction
   * @param x The magnitude of movement speed in the X direction
   * @param y The magnitude of movement speed in the Y direction
   */
  public setMovement(x: number, y: number) {
    this.playMovementSubject.next({ x, y });
  }

  /**
   * Gets the player orientation
   * @returns Player orientation
   */
  public getOrientation() {
    return this.playerOrientationSubject.getValue();
  }

  /**
   * Sets the Behaviour of the player to look in the input direction
   * @param x The magnitude of movement speed in the X direction
   * @param y The magnitude of movement speed in the Y direction
   */
  public setOrientation(x: number, y: number) {
    this.playerOrientationSubject.next({ x, y });
  }

  public setInitialOrientation(x: number, y: number) {
    this.initialOrientation = { x, y };
  }

  public setJump() {
    this.playerJumpSubject.next(true);
  }
}
