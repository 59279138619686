import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ElementType, TCheckpointElement } from '@/data/src/lib/view-manager';
import { ApplicationService } from '@/view/src/app/app.service';
import { PanelComponent } from '@/ui/src/lib/components/panel/panel.component';

const TRUSTED_SOURCES = ['https://api.readyplayer.me/', 'https://models.readyplayer.me/'];

@UntilDestroy()
@Component({
  selector: 'ui-avatar-panel',
  templateUrl: './avatar-panel.component.html',
  styleUrls: ['./avatar-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarPanelComponent extends PanelComponent {
  element: TCheckpointElement | undefined;
  avatar?: string;
  avatarEnabled = false;

  constructor(protected _appService: ApplicationService, private _cd: ChangeDetectorRef) {
    super(_appService);
  }

  ngOnInit() {
    this._appService.viewElementsSubject.pipe(untilDestroyed(this)).subscribe((elements) => {
      this.element = elements.find(({ type }) => type === ElementType.Checkpoint) as TCheckpointElement;
      if (this.element) {
        this.avatar = this.element.parameters.avatar;
        this.avatarEnabled = !!this.element.parameters.avatar;
      }
      this._cd.detectChanges();
    });
  }

  onAvatarToggle(enableAvatar: boolean) {
    this.avatarEnabled = enableAvatar;
    this.element &&
      !enableAvatar &&
      this._appService.updateViewElement({
        ...this.element,
        parameters: { ...this.element.parameters, avatar: undefined },
      });
  }

  applyAvatar() {
    if (!TRUSTED_SOURCES.some((source) => this.avatar?.startsWith(source))) {
      this._appService.showNotification('oxr.creatingSpace.panels.avatar.enterTrustedLink');
      this.avatar = this.element?.parameters.avatar;
      this._cd.detectChanges();
      return;
    }
    this.element &&
      this.element.parameters.avatar !== this.avatar &&
      this._appService.updateViewElement({
        ...this.element,
        parameters: { ...this.element.parameters, avatar: this.avatar },
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
