import { AccountService } from 'projects/data/src/lib/services/account.service';
import { Component, OnInit } from '@angular/core';
import { UrlService } from '@/data/src/lib/services/url.service';

@Component({
  selector: 'ui-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  currentLanguage$ = this._accountService.language$;

  constructor(private _accountService: AccountService, private _urlService: UrlService) {}

  ngOnInit(): void {}

  showTermsOfUse(): void {
    this._urlService.windowOpenWithPlatform('/termsOfUse');
  }

  showPrivacyPolicy(): void {
    this._urlService.windowOpenWithPlatform('privacyPolicy');
  }
}
