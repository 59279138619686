import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { GuideModalComponent } from '../guide/guide.component';
import { FileService } from '@/data/src/lib/services/file.service';
import { Access, FileStorageType } from '@/data/src/lib/enums/access';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { FileType, MediaType } from '@/data/src/lib/enums/file-type';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { AssetType } from '@/data/src/lib/models/data/asset';

export interface DropdownItem {
  name: string;
  assetType: AssetType;
  extensions: string;
  limitSize: number; //unit byte
  customMessage?: string;
  description?: string;
}
@Component({
  selector: 'ui-media-upload',
  templateUrl: './media-upload.component.html',
  styleUrls: ['./media-upload.component.scss'],
  providers: [ModalService],
})
export class MediaUploadComponent implements OnInit {
  @Input() title: string;
  @Input() data: DropdownItem[] = [];
  @Input() kind: string;
  @Input() showMyCom = true;
  @Input() layoutSize: string;
  @Input() buttonName = this._translateService.instant('shared.actions.upload');
  @Output() oxrModelChange = new EventEmitter<string>();

  selectedData: DropdownItem | undefined;
  uploadedData: any;
  modalRef: ModalService;
  uploading = false;
  previewImage: string | ArrayBuffer | null = '';
  scene: XRScene;
  error: string = '';

  constructor(
    private _fileService: FileService,
    private _modalService: ModalService,
    private _translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.setValue();
  }

  onClose() {
    const confirmationRef = this._modalService.open(ConfirmationComponent);
    if (confirmationRef) {
      confirmationRef.instance.title = this._translateService.instant('shared.confirmation.warning');
      confirmationRef.instance.body = this._translateService.instant('shared.confirmation.cancelUpload');
      confirmationRef.instance.confirmAction = this._translateService.instant('shared.confirmation.cancel');
      confirmationRef.result.then((confirmed) => {
        if (confirmed) {
          this.modalRef.close(null);
        }
      });
    }
  }

  async onUpload(uploadedData: File) {
    if (!this.selectedData) {
      return;
    }
    switch (this.selectedData.assetType) {
      case AssetType.Favicon:
        this.uploading = true;
        const f = await this._fileService.uploadFile(
          this.uploadedData,
          FileStorageType.Public,
          undefined,
          this.scene.Id,
          true,
          FileType.Image,
          undefined,
          Access.Public,
          MediaType.Favicon,
        );
        this.uploading = true;
        this.modalRef.close(f.thumbnailUrl);
        return;
      default:
        if (uploadedData.size > this.selectedData.limitSize) {
          this.error = 'shared.information.fileSizeError';
          return;
        }
        this.modalRef.close([uploadedData, this.selectedData]);
        return;
    }
  }

  uploadFileByDialog(event) {
    if (event?.target?.files?.length) {
      this.uploadFile(event.target.files);
      const element = event.target as HTMLInputElement;
      element.value = '';
    }
  }

  onReset() {}

  async uploadFile(file: FileList | File) {
    this.error = '';
    /**Checking Format*/
    if (
      this.selectedData?.extensions &&
      !this.selectedData?.extensions.includes((file instanceof FileList ? file[0].name : file.name).split('.').pop()!)
    ) {
      this.uploadedData = null;
      this.error = 'shared.information.invalidFormatError';
      return;
    }

    this.uploading = true;

    switch (this.selectedData?.assetType) {
      case AssetType.Favicon:
        this.uploadedData = file[0];
        var fr = new FileReader();
        fr.onload = () => {
          this.previewImage = fr.result;
          this.uploading = false;
        };
        fr.readAsDataURL(file[0]);
        break;

      case AssetType.Environment:
      case AssetType.Object:
        const f = file instanceof FileList ? file[0] : file;
        this.uploading = false;
        if (f.size > this.selectedData.limitSize) {
          this.error = 'shared.information.fileSizeError';
          break;
        }
        this.uploadedData = f;
        this.onUpload(f);
        break;
    }
  }

  async showingGuide(message: string) {
    const modal = this._modalService.open(GuideModalComponent);
    if (modal) {
      modal.instance.message = message;
      modal.instance.showCheckbox = false;
      modal.result.then(() => {});
    }
  }

  dropDownChanged() {
    this.setValue();
    this.error = '';
    this.uploadedData = null;
  }

  setValue() {
    if (this.kind) {
      this.selectedData = this.data.find((e) => e.assetType == this.kind);
    }
  }
}
