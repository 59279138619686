import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import { catchError, lastValueFrom, throwError } from 'rxjs';
import { ModelType } from '../models/data/base';
import { BaseApiService } from './base-api.service';
import {
  CardRequestPaymentCancel,
  CardRequestPayment,
  Payment,
  QueryParams,
  CardNumberPayment,
  AutomaticPaymentBillingBody,
  AutomaticPaymentBilling,
  AutomaticPaymentApprovalBody,
} from '../models/data/payment';
import { AccountService, LanguageCode } from './account.service';
import { PaymentEndPoint } from '../enums/payment';
import * as uuid from 'uuid';
import { LocalStorageKeys } from '../enums/storage-keys';
import { LocalStorageService } from './local-storage.service';
import { WINDOW } from '@ng-web-apis/common';

// The client key is the key used when linking the payment window in the browser.
const testClientKey = 'test_ck_D5GePWvyJnrK0W0k6q8gLzN97Eoq';
// The secret key is the key used when calling the TosPayments API. It should not be exposed to the browser.
const testSecretKey = 'test_sk_zXLkKEypNArWmo50nX3lmeaxYG5R';

@Injectable({
  providedIn: 'root',
})
export class PaymentService extends BaseApiService<Payment> {
  private noAuthHttp: HttpClient;
  private headers;

  constructor(
    private _router: Router,
    accountService: AccountService,
    public http: HttpClient,
    handler: HttpBackend,
    private _localStorageService: LocalStorageService,
    @Inject(WINDOW) private readonly _window: Window,
  ) {
    super(http, accountService, ModelType.Payment);
    this.noAuthHttp = new HttpClient(handler);
  }

  padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

  setCardRequestPayment(_parameter: CardRequestPayment | undefined) {
    this._localStorageService.removeItem(LocalStorageKeys.CARD_REQUEST_PAYMENT);
    this._localStorageService.setItem(LocalStorageKeys.CARD_REQUEST_PAYMENT, JSON.stringify(_parameter));
  }

  getCardRequestPayment(): CardRequestPayment {
    return JSON.parse(this._localStorageService.getItem(LocalStorageKeys.CARD_REQUEST_PAYMENT)!);
  }

  parsingParams(params: Params): QueryParams {
    const queryParams = {
      paymentKey: params['paymentKey'],
      orderId: params['orderId'],
      amount: params['amount'],
      code: params['code'],
      message: params['message'],
    };

    return queryParams;
  }

  setHeader() {
    const text = testSecretKey + ':';
    const encoded = btoa(text);
    if (this.accountService.language === LanguageCode.Korean) {
      this.headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'Basic ' + encoded);
    } else {
      this.headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Basic ' + encoded)
        .set('Accept-Language', 'en-US');
    }
  }

  /** Call payment window */
  async cardRequestPayment(_parameter: CardRequestPayment) {
    // Init object
    const tossPayments = await loadTossPayments(testClientKey);

    this.setCardRequestPayment(_parameter);

    tossPayments.requestPayment('카드', {
      amount: +_parameter.Amount,
      orderId: uuid.v1(),
      orderName: _parameter.OrderName,
      successUrl: this._window.document.location.href.replace(this._router.url, '') + '/paymentResult',
      failUrl: this._window.document.location.href.replace(this._router.url, '') + '/paymentResult',
      customerName: _parameter.CustomerName,
      customerEmail: _parameter.CustomerEmail,
      useInternationalCardOnly: !_parameter.KRWPayment,
    });
  }

  /** After successful payment request, request payment approval */
  async requestPaymentApproval(params: QueryParams): Promise<Payment> {
    this.setHeader();

    const result = await lastValueFrom(
      this.noAuthHttp.post(`${PaymentEndPoint.PaymentApproval}`, JSON.stringify(params), { headers: this.headers }).pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      ),
    );

    return <Payment>result;
  }

  /** Payment inquiry by paymentKey */
  async paymentInquiryByPaymentKey(paymentKey: string): Promise<Payment> {
    this.setHeader();

    const result = await lastValueFrom(
      this.noAuthHttp.get(`${PaymentEndPoint.PaymentInquiryByPaymentKey}` + paymentKey, { headers: this.headers }).pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      ),
    );

    return <Payment>result;
  }

  /** Payment inquiry by orderId */
  async paymentInquiryByOrderId(orderId: string): Promise<Payment> {
    this.setHeader();

    const result = await lastValueFrom(
      this.noAuthHttp.get(`${PaymentEndPoint.PaymentInquiryByOrderId}` + orderId, { headers: this.headers }).pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      ),
    );

    return <Payment>result;
  }

  /** Request payment cancel */
  async requestPaymentCancel(paymentKey: string, cancelAmount: number, cancelReason: string): Promise<Payment> {
    let result;
    let cardRequestPaymentCancel: CardRequestPaymentCancel;

    this.setHeader();

    //Get 'refundableAmount' for safety cancel
    await this.paymentInquiryByPaymentKey(paymentKey).then((res) => {
      if (res.cancels) {
        cardRequestPaymentCancel = {
          cancelReason: cancelReason,
          cancelAmount: cancelAmount !== 0 ? cancelAmount : res.totalAmount,
          refundableAmount: res.cancels[res.cancels.length - 1].refundableAmount,
        };
      } else {
        cardRequestPaymentCancel = {
          cancelReason: cancelReason,
          cancelAmount: cancelAmount !== 0 ? cancelAmount : res.totalAmount,
          refundableAmount: res.totalAmount,
        };
      }

      result = lastValueFrom(
        this.noAuthHttp
          .post(`${PaymentEndPoint.PaymentCancel}` + paymentKey + '/cancel', JSON.stringify(cardRequestPaymentCancel), {
            headers: this.headers,
          })
          .pipe(
            catchError((error: HttpErrorResponse) => {
              return throwError(error);
            }),
          ),
      );
    });

    return <Payment>result;
  }

  /** Request payment with the card number to be paid and other card information.
   *  Card number payment API can be used after additional contract.
   */
  async requestCardNumberPayment(cardNumberPayment: CardNumberPayment): Promise<Payment> {
    this.setHeader();

    const result = await lastValueFrom(
      this.noAuthHttp.post(`${PaymentEndPoint.CardNumberPayment}`, JSON.stringify(cardNumberPayment), { headers: this.headers }).pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      ),
    );

    return <Payment>result;
  }

  /** Requests issuance of a billing key to be associated with a customerKey that specifies a customer. */
  async requestCardAutomaticPaymentBillingByCustomerKey(
    automaticPaymentBillingBody: AutomaticPaymentBillingBody,
  ): Promise<AutomaticPaymentBilling> {
    this.setHeader();

    const result = await lastValueFrom(
      this.noAuthHttp
        .post(`${PaymentEndPoint.AutomaticPaymentBillingByCustomerKey}`, JSON.stringify(automaticPaymentBillingBody), {
          headers: this.headers,
        })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            return throwError(error);
          }),
        ),
    );

    return <AutomaticPaymentBilling>result;
  }

  /** Requests issuance of a billing key to be associated with a customerKey that specifies a customer. */
  async requestCardAutomaticPaymentApproval(
    billingKey: string,
    automaticPaymentApprovalBody: AutomaticPaymentApprovalBody,
  ): Promise<Payment> {
    this.setHeader();

    const result = await lastValueFrom(
      this.noAuthHttp
        .post(`${PaymentEndPoint.AutomaticPaymentApproval}` + billingKey, JSON.stringify(automaticPaymentApprovalBody), {
          headers: this.headers,
        })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            return throwError(error);
          }),
        ),
    );

    return <Payment>result;
  }
}
