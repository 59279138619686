<ui-panel [title]="'oxr.creatingSpace.lightsOption.light' | translate" [collapsing]="true" [panelId]="panelMask.Light">
  <div class="tool-header">
    <div class="tool-header-title">
      <span class="icon icon-arrow-down"></span>
      {{ 'oxr.creatingSpace.lights.' + (type | lowercase) + 'Light' | translate | uppercase }}
    </div>
  </div>
  <div class="tool-body">
    <div>
      <ui-color-picker
        [label]="'oxr.creatingSpace.lightsOption.lightColor' | translate"
        [defaultColor]="color"
        (changed)="onColorUpdate($event, 'color')"
        (changeEnd)="onChangeEnd()"
      ></ui-color-picker>
      @if (type === lightType.Ambient) {
        <div class="separator"></div>
        <ui-color-picker
          [label]="'oxr.creatingSpace.lightsOption.groundColor' | translate"
          [defaultColor]="groundColor"
          (changed)="onColorUpdate($event, 'groundColor')"
          (changeEnd)="onChangeEnd()"
        ></ui-color-picker>
      }
    </div>
    <div class="separator"></div>
    <div>
      <ui-slider
        [value]="intensity"
        [label]="'oxr.creatingSpace.lightsOption.intensity' | translate"
        [options]="intensityOptions"
        (onChange)="onIntensityChange($event)"
        (onChangeEnd)="onChangeEnd()"
      ></ui-slider>
      @if (type !== lightType.Ambient) {
        <div class="separator"></div>
        <ui-slider
          [value]="bias"
          [label]="'oxr.creatingSpace.lightsOption.bias' | translate"
          [options]="biasOptions"
          (onChange)="onBiasChange($event)"
          (onChangeEnd)="onChangeEnd()"
        ></ui-slider>
        <div class="separator"></div>
        <ui-slider
          [value]="normalBias"
          [label]="'oxr.creatingSpace.lightsOption.normalBias' | translate"
          [options]="biasOptions"
          (onChange)="onNormalBiasChange($event)"
          (onChangeEnd)="onChangeEnd()"
        ></ui-slider>
      }
      @if (type === lightType.Spot) {
        <div class="separator"></div>
        <ui-slider
          [value]="angle"
          [label]="'oxr.creatingSpace.lightsOption.angle' | translate"
          [options]="angleOptions"
          (onChange)="onAngleChange($event)"
          (onChangeEnd)="onChangeEnd()"
        ></ui-slider>
      }
    </div>
  </div>
</ui-panel>
