import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, filter, interval, take, tap } from 'rxjs';
import { Account } from '@/data/src/lib/models/data/account';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { AccountService } from '@/data/src/lib/services/account.service';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { ScenePlan } from '@/data/src/lib/enums/pricing-plan';
import { EnterpriseService } from './../../../../../data/src/lib/services/enterprise.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class OxrSettingsService {
  canClosePanel = false;

  // controls state of detail panels
  private _isDetailsPanelOpened$ = new BehaviorSubject<boolean>(false);
  isDetailsPanelOpened$ = this._isDetailsPanelOpened$.asObservable();

  // controls view inside details panel
  private _openedDetails$ = new BehaviorSubject<string>('');
  openedDetails$ = this._openedDetails$.asObservable();

  // controls state of the panel
  private _isPanelOpened$ = new BehaviorSubject<boolean>(false);
  isPanelOpened$ = this._isPanelOpened$.asObservable().pipe(
    tap((value) => {
      if (value) {
        this.canClosePanel = false;

        this._ngZone.runOutsideAngular(() => {
          interval(1000)
            .pipe(take(1), untilDestroyed(this))
            .subscribe(() => {
              this.canClosePanel = true;
              this._ngZone.run(() => {});
            });
        });
      }
    }),
  );

  private _scene$ = new BehaviorSubject<XRScene | null>(null);
  scene$ = this._scene$.asObservable();

  currentLang$ = this._accountService.language$;

  account: Account | undefined;
  account$ = this._accountService.activeAccount$.pipe(
    filter((data) => !!data),
    tap((account) => (this.account = account)),
    untilDestroyed(this),
  );

  constructor(
    private _sceneService: SceneService,
    private _translateService: TranslateService,
    private _accountService: AccountService,
    private _toastr: ToastrService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _enterpriseService: EnterpriseService,
    private _ngZone: NgZone,
  ) {
    this.account$.subscribe();
  }

  openPanel(scene: XRScene): void {
    this._scene$.next(scene);
    this._isPanelOpened$.next(true);
  }

  closePanel(): void {
    if (this.canClosePanel && this._isPanelOpened$.getValue()) {
      this._isPanelOpened$.next(false);
      this._isDetailsPanelOpened$.next(false);

      // wait for details panel to hide - then destroy inner components
      this._ngZone.runOutsideAngular(() => {
        interval(300)
          .pipe(take(1), untilDestroyed(this))
          .subscribe(() => {
            this._openedDetails$.next('');
            this._ngZone.run(() => {});
          });
      });
    }
  }

  openDetails(name: string): void {
    this._isDetailsPanelOpened$.next(true);

    if (!this._openedDetails$.value) {
      this._openedDetails$.next(name);
    } else {
      this._isDetailsPanelOpened$.next(false);

      this._ngZone.runOutsideAngular(() => {
        interval(300)
          .pipe(take(1), untilDestroyed(this))
          .subscribe(() => {
            this._isDetailsPanelOpened$.next(true);
            this._openedDetails$.next(name);
            this._ngZone.run(() => {});
          });
      });
    }
  }

  closeDetails(): void {
    this._isDetailsPanelOpened$.next(false);

    // wait for details panel to hide - then destroy inner components
    this._ngZone.runOutsideAngular(() => {
      interval(300)
        .pipe(take(1), untilDestroyed(this))
        .subscribe(() => {
          this._openedDetails$.next('');
          this._ngZone.run(() => {});
        });
    });
  }

  async isUsernameAvailable(username: string): Promise<boolean> {
    return await this._accountService.checkUsername(username);
  }

  // async uploadFile(event) {
  //   if (event?.target?.files?.length) {
  //     const resize = <Blob>await this._fileService.resizeImage(event.target.files[0], 150);
  //     const account = <Account>this._accountService.account;
  //     const { url: thumbnailUrl } = await this._fileService.uploadFile(resize, FileStorageType.Public, undefined, account.Id);
  //     account.Thumbnail = thumbnailUrl;
  //     // this.updateAccount(account);
  //     account.Thumbnail = '';
  //     this._accountService.setAccountSubject(account);
  //   }
  // }

  async deleteScene() {
    if (this._scene$.value?.Plan === ScenePlan.Enterprise) {
      await this._enterpriseService.deleteEnterpriseScene(this._scene$.value.Id);
      await this._sceneService.updateDataSubject([this._scene$.value], true);
    } else {
      await this._sceneService.delete(<XRScene>this._scene$.value);
    }

    const message = this._translateService.instant('shared.information.hasBeenDeleted', { name: this._scene$.value?.Name });
    this._toastr.info(message);
    if (!this._route.snapshot.url.find((segment) => segment.path === 'owned')) {
      this._router.navigateByUrl('oxr/owned');
    }
  }

  async resetScene() {
    const message = this._translateService.instant('shared.information.hasBeenReset', { name: this._scene$.value?.Name });
    await this._sceneService.delete(<XRScene>this._scene$.value, false);
    await this._sceneService.get('', '', undefined, true);
    this._toastr.info(message);
  }

  async saveScene(changes: Partial<XRScene>) {
    const scene = this._scene$.value;
    if (!scene) {
      return;
    }
    if (changes.Name !== undefined) {
      scene.Name = changes.Name;
    }
    if (changes.Description !== undefined) {
      scene.Description = changes.Description;
    }
    if (changes.Favicon !== undefined) {
      scene.Favicon = changes.Favicon;
    }
    if (changes.Tags !== undefined) {
      scene.Tags = changes.Tags;
    }
    if (changes.DisplayConcurrentUsers !== undefined) {
      scene.DisplayConcurrentUsers = changes.DisplayConcurrentUsers;
    }
    if (changes.ChatOn !== undefined) {
      scene.ChatOn = changes.ChatOn;
    }

    await this._sceneService.put(scene);
    this._scene$.next(scene);
  }

  logout(): void {
    this._accountService.logout();
  }
}
