import { privateFieldReplacer } from '../../utils/json-utils';
import { IModel, IUserPermission, ModelType } from './base';
import { Permission } from './../../enums/permission';
import * as uuid from 'uuid';

export class UserScenes {
  constructor(public Id: string, public Permission: string, public SceneId: string, public UserId: string) {}
}

/**
 * Defines the permission the active user has for the coresponding scene
 */
export class UserScene implements IModel, IUserPermission {
  private _displayName!: string;

  constructor(public Id: string, public SceneId: string, public UserId: string, public Permission: Permission) {}
  get ModelType(): ModelType {
    return ModelType.UserScene;
  }

  get Username() {
    return this._displayName;
  }

  set Username(value: string) {
    this._displayName = value;
  }

  get ParentId(): string {
    return this.SceneId;
  }

  toJson(): string {
    return JSON.stringify(this, privateFieldReplacer);
  }

  static create(sceneId: string, userId: string, username: string, permission: Permission = Permission.Read) {
    const us = new UserScene(uuid.v4(), sceneId, userId, permission);
    us.Username = username;
    return us;
  }
  setId(id: string): void {
    this.Id = id;
  }
}
