import { Component, OnInit } from '@angular/core';
import { AccountService } from '@/data/src/lib/services/account.service';

@Component({
  selector: 'ui-m-subscription-plan',
  templateUrl: './m-subscription-plan.component.html',
  styleUrls: ['./m-subscription-plan.component.scss'],
})
export class MobileSubscriptionPlanComponent implements OnInit {
  currentLanguage$ = this._accountService.language$;

  constructor(private _accountService: AccountService) {}

  ngOnInit(): void {}
}
