<div class="container" [formGroup]="form">
  <div class="header">
    <div class="title">{{ 'subscription.quote' | translate }}</div>
    <div class="close icon icon-close sm" (click)="onClose()"></div>
  </div>
  <div class="body">
    <ng-select
      class="inquiryType"
      [virtualScroll]="true"
      [clearable]="false"
      placeholder="{{ 'subscription.inquiryTypePlaceholder' | translate }}"
      formControlName="inquiryType"
      [(ngModel)]="selectedInquiryType"
      uiAutofocus
    >
      @for (type of inquiryType; track type) {
        <ng-option>
          {{ 'subscription.inquiryType.' + type | translate }}
        </ng-option>
      }
    </ng-select>
    <input uiInput class="company" type="text" placeholder="{{ 'subscription.company' | translate }}" formControlName="company" />
    <input uiInput class="name" type="text" placeholder="{{ 'subscription.name' | translate }}" formControlName="name" />
    <input uiInput class="phone" type="text" placeholder="{{ 'subscription.phone' | translate }}" formControlName="phone" />
    <input uiInput class="email" type="text" placeholder="{{ 'subscription.email' | translate }}" formControlName="email" />
    <textarea
      class="contents"
      uiTextarea
      nonResizable
      rows="4"
      placeholder="{{ 'subscription.content' | translate }}"
      formControlName="contents"
    ></textarea>
  </div>
  <div class="footer">
    <ui-check-box class="selection-checkbox primary xs" [(checked)]="checked">{{
      'subscription.consentPersonalInformation' | translate
    }}</ui-check-box>
    <button ui-button (click)="onClick()" [disabled]="!form.valid || !checked">{{ 'subscription.contactUs' | translate }}</button>
  </div>
</div>
