<main [formGroup]="form">
  <section class="thumbnail">
    <input #thumbnailInput type="file" id="user-thumbnail2" (change)="uploadFile($event)" />
    <div>
      @if (!account.Thumbnail && !fileUploading) {
        <div class="user-thumbnail">
          <div class="icon icon-account"></div>
        </div>
      }
      @if (account.Thumbnail && !fileUploading) {
        <img class="user-thumbnail" [src]="account.Thumbnail" />
      }
      @if (fileUploading) {
        <div class="user-thumbnail">
          <ui-loader></ui-loader>
        </div>
      }
    </div>
    <div class="actions">
      <button ui-outlined-button white xs (click)="thumbnailInput.click()">{{ 'shared.actions.change' | translate }}</button>
      <button ui-outlined-button white xs (click)="removeThumbnail()">{{ 'shared.actions.delete' | translate }}</button>
    </div>
  </section>
  <div class="item">
    <input uiInput disabled [value]="email$ | async" placeholder="{{ 'shared.fields.email' | translate | uppercase }}" />
  </div>
  <div class="item">
    <input
      uiInput
      placeholder="{{ 'USERNAME' | uppercase }}"
      formControlName="Username"
      (focus)="showUsernameHints = true"
      (blur)="showUsernameHints = false"
    />
    <div uiError [class.show]="form.get('Username')?.errors?.required">{{ 'shared.validation.required' | translate }}</div>
    <div uiError [class.show]="form.get('Username')?.errors?.maxlength">{{ 'shared.validation.maxlength' | translate: { max: 20 } }}</div>
    <div uiError [class.show]="form.get('Username')?.errors?.pattern">{{ 'shared.validation.pattern' | translate }}</div>
    <div uiError [class.show]="form.get('Username')?.errors?.usernameExists">{{ 'shared.validation.usernameExists' | translate }}</div>
    <div uiHint [class.show]="showUsernameHints">
      <ul>
        <li class="warning">{{ 'header.accountPanel.userInfo.hint1' | translate }}</li>
        <li class="warning">{{ 'header.accountPanel.userInfo.hint2' | translate }}</li>
        <li>{{ 'header.accountPanel.userInfo.hint3' | translate }}</li>
        <li innerHTML="{{ 'header.accountPanel.userInfo.hint4' | translate }}"></li>
      </ul>
    </div>
  </div>

  <div class="item switch">
    <span class="title">{{ 'shared.fields.marketingConsent' | translate }}</span>
    <ui-switch [checked]="marketingConsent" (changed)="onChangeB2CAttribute($event, userB2CAttribute.MarrketingConsent)"></ui-switch>
  </div>
  <div class="action-item" (click)="openDetails('security')">
    <div class="left">{{ 'header.accountPanel.actions.securitySettings' | translate }}</div>
    <div class="right">
      <div class="icon icon-arrow-right"></div>
    </div>
  </div>
</main>
<section class="footer">
  <button ui-button fixed [disabled]="!form.valid || loading" (click)="onSave()">
    @if (loading) {
      <ui-loader sm loaderColor="white"></ui-loader>
    } @else {
      {{ 'shared.actions.save' | translate }}
    }
  </button>
</section>
