import { v4 as uuid } from 'uuid';
import { Matrix, Mesh, MeshBuilder, Nullable, PickingInfo, Vector3 } from '../../babylon';
import { ScreenRenderable } from '../renderables';
import { ViewManagerUtils } from '../../utils/view-manager-utils';
import { Z_OFFSET } from '../constants';
import { MediaControl, TScreenElement } from '../types';

const HEIGHT_RATIO = 0.12;
const BUTTON_SIZE = 0.12;
const X_MARGIN = BUTTON_SIZE * 1.25;
const Y_SCALE = 0.5 / HEIGHT_RATIO;

export class VideoController {
  public container: Mesh;
  public durationController: Mesh;
  public durationContainer: Mesh;
  public durationBar: Mesh;
  public volumeController: Mesh;
  public volumeContainer: Mesh;
  public volumeBar: Mesh;

  public _buttons: {
    next?: Nullable<Mesh>;
    previous?: Nullable<Mesh>;
    play?: Nullable<Mesh>;
    pause?: Nullable<Mesh>;
    stop?: Nullable<Mesh>;
    sound?: Nullable<Mesh>;
  } = {};

  private xScale: number;
  private zMargin: number;

  get viewManager() {
    return this.renderable.viewManager;
  }

  get media() {
    return this.renderable.media;
  }

  constructor(
    private renderable: ScreenRenderable,
    host: Mesh,
  ) {
    this.durationControl = this.durationControl.bind(this);
    this.volumeControl = this.volumeControl.bind(this);

    if (!this.media?.dimensions) {
      return;
    }

    this.container = MeshBuilder.CreatePlane(`Controller-${uuid()}`, { size: 1, sideOrientation: Mesh.BACKSIDE }, this.viewManager.scene);
    ViewManagerUtils.AttachMetadata(this.container, { helperOf: renderable, isControl: true });
    this.container.material = this.viewManager.videoControllerMaterial;

    const hostBoundingBox = host.getBoundingInfo().boundingBox;
    this.container.parent = host;
    this.container.position.y = hostBoundingBox.minimum.y + this.container.scaling.y / 2;
    this.container.position.z = Z_OFFSET;
    this.container.alphaIndex = 1;

    this.zMargin = this.container.position.z + Z_OFFSET;

    this.getButton(MediaControl.Play);
    this.getButton(MediaControl.Pause);
    this.getButton(MediaControl.Stop);
    this.getButton(MediaControl.FastForward);
    this.getButton(MediaControl.SoundMax);
    this.getButton(MediaControl.Fullscreen);

    this.durationController = MeshBuilder.CreatePlane(
      `DurationController-${uuid()}`,
      { size: 1, sideOrientation: Mesh.BACKSIDE },
      this.viewManager.scene,
    );
    ViewManagerUtils.AttachMetadata(this.durationController, {
      helperOf: this.renderable,
      click: this.durationControl,
      drag: this.durationControl,
    });
    this.durationController.isPickable = true;

    const controllerBoundingBox = this.container.getBoundingInfo().boundingBox;
    this.durationController.setPivotPoint(new Vector3(controllerBoundingBox.minimum.x, controllerBoundingBox.center.y, 0));
    this.durationController.position.z = Z_OFFSET * 5;
    this.durationController.scaling.z = -1;
    this.durationController.visibility = 0.001;
    this.durationController.parent = this.container;

    this.durationContainer = MeshBuilder.CreatePlane(`DurationContainer-${uuid()}`, { size: 1 }, this.viewManager.scene);
    ViewManagerUtils.AttachMetadata(this.durationContainer, { helperOf: this.renderable });
    this.durationContainer.position.z = Z_OFFSET * 2;
    this.durationContainer.material = this.viewManager.volumeContainerMaterial;
    this.durationContainer.visibility = 1;
    this.durationContainer.parent = this.durationController;

    this.durationBar = MeshBuilder.CreatePlane(`DurationBar-${uuid()}`, { size: 1 }, this.viewManager.scene);
    ViewManagerUtils.AttachMetadata(this.durationBar, { helperOf: this.renderable });
    this.durationBar.position.z = Z_OFFSET;
    this.durationBar.material = this.viewManager.durationBarMaterial;
    this.durationBar.parent = this.durationController;
    this.durationBar.setPivotPoint(new Vector3(this.container.getBoundingInfo().boundingBox.minimum.x, 0, 0));

    this.volumeController = MeshBuilder.CreatePlane(
      `VolumeController-${uuid()}`,
      { size: 1, sideOrientation: Mesh.BACKSIDE },
      this.viewManager.scene,
    );
    ViewManagerUtils.AttachMetadata(this.volumeController, {
      helperOf: this.renderable,
      click: this.volumeControl,
      drag: this.volumeControl,
    });
    this.volumeController.isPickable = true;

    const buttonBoundingBox = this.getButton(MediaControl.SoundMax).getBoundingInfo().boundingBox;
    this.volumeController.setPivotPoint(new Vector3(buttonBoundingBox.maximum.x, buttonBoundingBox.center.y, 0));
    this.volumeController.position.z = Z_OFFSET;
    this.volumeController.visibility = 0;
    this.volumeController.parent = this.getButton(MediaControl.SoundMax);

    this.volumeContainer = MeshBuilder.CreatePlane(
      `VolumeContainer-${uuid()}`,
      { size: 1, sideOrientation: Mesh.BACKSIDE },
      this.viewManager.scene,
    );
    ViewManagerUtils.AttachMetadata(this.volumeContainer, { helperOf: this.renderable });
    this.volumeContainer.position.z = -Z_OFFSET * 2;
    this.volumeContainer.material = this.viewManager.volumeContainerMaterial;
    this.volumeContainer.parent = this.volumeController;

    this.volumeBar = MeshBuilder.CreatePlane(`VolumeBar-${uuid()}`, { size: 1, sideOrientation: Mesh.BACKSIDE }, this.viewManager.scene);
    ViewManagerUtils.AttachMetadata(this.volumeBar, {
      helperOf: this.renderable,
      click: this.volumeControl,
      drag: this.volumeControl,
    });
    this.volumeBar.position.z = Z_OFFSET * 2;
    this.volumeBar.setPivotPoint(new Vector3(this.volumeContainer.getBoundingInfo().boundingBox.minimum.x, 0, 0));
    this.volumeBar.material = this.viewManager.volumeBarMaterial;
    this.volumeBar.parent = this.volumeContainer;

    this.getButton(MediaControl.Play).setEnabled(!this.renderable.media?.isPlaying);
    this.getButton(MediaControl.Pause).setEnabled(this.renderable.media?.isPlaying);

    this.resize(host);
  }

  resize(host: Mesh) {
    let ratio = Math.abs(host.absoluteScaling.x / host.absoluteScaling.y);
    const hostBoundingBox = host.getBoundingInfo().boundingBox;
    this.container.scaling.y = ratio > 1 ? HEIGHT_RATIO : HEIGHT_RATIO * ratio;
    this.container.position.y = hostBoundingBox.minimum.y + this.container.scaling.y / 2;

    this.xScale = ratio > 1 ? 0.5 / ratio : 0.5;

    const buttonExtendX = this.getButton(MediaControl.Play).getBoundingInfo().boundingBox.extendSize.x;
    const xOffset = (buttonExtendX + X_MARGIN) * this.xScale;
    this.getButton(MediaControl.Play).position.x = hostBoundingBox.minimum.x + X_MARGIN * this.xScale;
    this.getButton(MediaControl.Play).scaling.x = this.xScale;
    this.getButton(MediaControl.Pause).position.set(...this.getButton(MediaControl.Play).position.asArray().slice(0, 2), Z_OFFSET);
    this.getButton(MediaControl.Pause).scaling.x = this.xScale;
    this.getButton(MediaControl.Stop).position.x = this.getButton(MediaControl.Play).position.x + xOffset;
    this.getButton(MediaControl.Stop).scaling.x = this.xScale;
    this.getButton(MediaControl.FastForward).position.x = this.getButton(MediaControl.Stop).position.x + xOffset;
    this.getButton(MediaControl.FastForward).scaling.x = this.xScale;
    this.getButton(MediaControl.SoundMax).position.x = this.getButton(MediaControl.FastForward).position.x + xOffset;
    this.getButton(MediaControl.SoundMax).scaling.x = this.xScale;
    this.getButton(MediaControl.Fullscreen).position.x = hostBoundingBox.maximum.x - X_MARGIN * this.xScale;
    this.getButton(MediaControl.Fullscreen).scaling.x = this.xScale;

    const controllerBoundingBox = this.container.getBoundingInfo().boundingBox;
    this.durationController.setPivotPoint(new Vector3(controllerBoundingBox.minimum.x, controllerBoundingBox.center.y, 0));
    this.durationController.position.y = 0.55;
    this.durationController.scaling.y = 0.25;

    this.durationContainer.scaling.y = 0.4;

    this.durationBar.scaling.y = 0.4;
    this.durationBar.scaling.x = 0;
    this.durationBar.setPivotPoint(new Vector3(this.container.getBoundingInfo().boundingBox.minimum.x, 0, 0));

    const buttonBoundingBox = this.getButton(MediaControl.SoundMax).getBoundingInfo().boundingBox;
    this.volumeController.setPivotPoint(new Vector3(buttonBoundingBox.maximum.x, buttonBoundingBox.center.y, 0));
    this.volumeController.position.x = 0.225;
    this.volumeController.scaling.x = 0.35;
    this.volumeController.scaling.y = 0.15;

    this.volumeContainer.scaling.x = 0.8;
    this.volumeContainer.scaling.y = 0.15;

    this.volumeBar.setPivotPoint(new Vector3(this.volumeContainer.getBoundingInfo().boundingBox.minimum.x, 0, 0));
    this.volumeBar.scaling.x = (this.renderable.element as TScreenElement).parameters.media?.volume ?? 1;
  }

  getButton(name: MediaControl) {
    if (!this._buttons[name]) {
      this._buttons[name] = MeshBuilder.CreatePlane(
        `${name}-${uuid()}`,
        { size: BUTTON_SIZE, sideOrientation: Mesh.BACKSIDE },
        this.viewManager.scene,
      );
      this._buttons[name].position.set(0, 0, this.zMargin);
      this._buttons[name].isPickable = true;
      this._buttons[name].scaling.x = this.xScale;
      this._buttons[name].scaling.y = Y_SCALE;
      this._buttons[name].parent = this.container;
      this._buttons[name].material = this.viewManager.getMediaButtonMaterial(name);

      let click = () => {};
      switch (name) {
        case MediaControl.Play:
          click = () => {
            this.media?.play();
          };
          break;
        case MediaControl.Pause:
          click = () => {
            this.media?.pause();
          };
          break;
        case MediaControl.Stop:
          click = () => {
            this.media?.stop();
          };
          break;
        case MediaControl.FastForward:
          click = () => {
            this.media?.fastForward();
          };
          break;
        case MediaControl.SoundMax:
        case MediaControl.SoundMin:
        case MediaControl.SoundZero:
        case MediaControl.Unmute:
          click = () => {
            this.media?.toggleSound();
          };
          break;
        case MediaControl.Fullscreen:
          click = () => {
            this.media?.fullscreen();
          };
          break;
        default:
          break;
      }
      ViewManagerUtils.AttachMetadata(this._buttons[name], { helperOf: this.renderable, click });
    }
    return this._buttons[name];
  }

  durationControl(pickingInfo: PickingInfo) {
    if (pickingInfo?.pickedPoint && this.media) {
      const projectedX = Vector3.TransformCoordinates(
        pickingInfo.pickedPoint,
        (this.durationContainer?.getWorldMatrix() as Matrix).clone().invert(),
      ).x;
      this.media?.setDurationPercentage(Math.min(Math.max(projectedX + 0.5, 0), 1));
    }
  }

  volumeControl(pickingInfo: PickingInfo) {
    if (pickingInfo?.pickedPoint && this.media) {
      const projectedX = Vector3.TransformCoordinates(
        pickingInfo.pickedPoint,
        (this.volumeContainer?.getWorldMatrix() as Matrix).clone().invert(),
      ).x;
      this.media?.setVolume(Math.min(Math.max(projectedX * 1.1 + 0.5, 0), 1));
    }
  }

  setVolume(volume: number) {
    if (!this.volumeBar) {
      return;
    }
    this.volumeBar.scaling.x = volume;
  }

  setEnabled(flag: boolean) {
    this.container?.setEnabled(flag);
  }

  dispose() {
    this.container.parent = null;
    Object.entries(this._buttons).forEach(([key, button]) => {
      button?.dispose();
      this._buttons[key] = null;
    });
    this.durationBar.dispose();
    this.durationContainer.dispose();
    this.durationController.dispose();
    this.volumeBar.dispose();
    this.volumeContainer.dispose();
    this.volumeController.dispose();
    this.container.dispose();
  }
}
