<hr class="horizontal-line" />
<div class="container">
  <div class="container-left">
    <section class="contact">
      <div class="title">CONTACT</div>
      <div class="sns-icon">
        <a href="https://www.instagram.com/ownxr_official/">
          <div class="icon icon-instagram-white"></div>
        </a>
        <a href="https://www.facebook.com/profile.php?id=100089217545469">
          <div class="icon icon-facebook-white"></div>
        </a>
        <a href="https://www.linkedin.com/company/uroom-inc">
          <div class="icon icon-linkedin-white"></div>
        </a>
        <a href="https://www.youtube.com/@ownxr">
          <div class="icon icon-youtube-white"></div>
        </a>
      </div>
      <div class="mail">support&#64;uroom.io</div>
      <div class="number">
        <span>TEL. {{ 'footer.tel' | translate }}</span>
        <span>FAX. {{ 'footer.fax' | translate }}</span>
      </div>
    </section>
    <section class="info">
      <div>{{ 'footer.companyName' | translate }}</div>
      <div>
        {{ 'footer.ceo' | translate }}
      </div>
      <div>{{ 'footer.address' | translate }}</div>
      @if ((currentLanguage$ | async) === 'ko') {
        <div>
          <span class="business-number">사업자 등록번호 | 461-88-01600</span>
          <span>통신 판매업 | 2023-서울송파-0283</span>
        </div>
      }
    </section>
  </div>
  <section class="terms">
    <div>
      <span class="privacy-policy" (click)="showPrivacyPolicy()">{{ 'footer.privacyPolicy' | translate | uppercase }}</span>
      <span class="terms-of-use" (click)="showTermsOfUse()">{{ 'footer.termsOfUse' | translate | uppercase }}</span>
    </div>
    <div>© 2023 URoom Inc. All right reserved</div>
  </section>
</div>
