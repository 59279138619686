import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, lastValueFrom, Observable, of, retry, tap } from 'rxjs';
import { AccountService } from './account.service';
import { environment } from 'projects/app/src/environments/environment';

export interface ReleaseLogData {
  Title: { en: string; ko: string };
  Description: { en: string; ko: string };
}

export interface ReleaseLog {
  Id: string;
  DateCreated: string;
  DisplayDate: string;
  VersionNumber: string;
  Notes: string;
  Data: ReleaseLogData[];
}

@Injectable({
  providedIn: 'root',
})
export class ReleaseLogService {
  private _releaseLogSubject: BehaviorSubject<ReleaseLog[]> = new BehaviorSubject<ReleaseLog[]>([]);
  public releaseLog$ = this._releaseLogSubject.asObservable().pipe(filter((data) => !!data.length));

  constructor(public http: HttpClient, private _accountService: AccountService) {}

  public get releaseLog() {
    return this._releaseLogSubject.value;
  }

  /**
   * Gets release log
   * @param forceHttp Set to true to force http call to the server
   * @returns The release log
   */
  get(forceHttp = false): Observable<ReleaseLog[]> {
    if (!forceHttp && this.releaseLog?.length) {
      return of(this.releaseLog);
    } else {
      return this.http.get<ReleaseLog[]>(`${environment[this._accountService.region].crudApiUrl}/ReleaseLog`).pipe(
        retry(2),
        tap((result: ReleaseLog[]) => {
          this._releaseLogSubject.next(
            result
              .map((item) => {
                if (item.DisplayDate[-1] !== 'Z') {
                  item.DisplayDate += 'Z';
                }
                return item;
              })
              .filter((item) => {
                return new Date(item.DisplayDate) <= new Date();
              }),
          );
        }),
      );
    }
  }

  /**
   * Post release log
   * @returns server response
   */
  post(data): Observable<any> {
    return this.http.post(`${environment[this._accountService.region].crudApiUrl}/ReleaseLog`, data);
  }
}
