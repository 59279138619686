import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalService } from '../../../../../../ui/src/lib/modal/modal.service';

@Component({
  selector: 'm-information',
  templateUrl: './m-information.component.html',
  styleUrls: ['./m-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileInformationComponent {
  message = '';
  modalContainerRef;

  constructor(private _modalRef: ModalService) {}

  close() {
    this._modalRef.close(1, this.modalContainerRef);
  }
}
