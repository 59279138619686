import { FileType, MediaType } from '@/data/src/lib/enums/file-type';
import { LocalStorageService } from '@/data/src/lib/services/local-storage.service';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventType } from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Access, FileStorageType } from 'projects/data/src/lib/enums/access';
import { Account, AccountSetting } from 'projects/data/src/lib/models/data/account';
import { XRNotification } from 'projects/data/src/lib/models/data/notification';
import { AccountService, LanguageCode } from 'projects/data/src/lib/services/account.service';
import { FileService } from 'projects/data/src/lib/services/file.service';
import { NotificationService } from 'projects/data/src/lib/services/notification.service';
import { BehaviorSubject, Subject, filter, interval, take, takeUntil, tap } from 'rxjs';
import { ConfirmationComponent } from '../../modal/confirmation/confirmation.component';
import { InformationComponent } from '../../modal/information/information.component';
import { ModalService } from '../../modal/modal.service';
import { MobileConfirmationComponent } from '../../modal/mobile/m-confirmation/m-confirmation.component';
import { MobileInformationComponent } from '../../modal/mobile/m-information/m-information.component';
import { SessionStorageService } from '@/data/src/lib/services/session-storage.service';
import { PlatformDetail, SessionStorageKeys } from '@/data/src/lib/enums/storage-keys';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AccountPanelService implements OnDestroy {
  // controls state of the panel
  private _isPanelOpened$ = new BehaviorSubject<boolean>(false);
  isPanelOpened$ = this._isPanelOpened$.asObservable();

  // controls state of detail panels
  private _isDetailsPanelOpened$ = new BehaviorSubject<boolean>(false);
  isDetailsPanelOpened$ = this._isDetailsPanelOpened$.asObservable();

  // controls view inside details panel
  private _openedDetails$ = new BehaviorSubject<string>('');
  openedDetails$ = this._openedDetails$.asObservable();

  // email subject
  private _emailSubject$ = new BehaviorSubject<string | undefined>('');
  email$ = this._emailSubject$.asObservable();

  account: Account | undefined;
  account$ = this._accountService.activeAccount$.pipe(
    tap(async (account) => {
      this.account = account;
      if (account) {
        this._emailSubject$.next(await this._accountService.getEmail());
      }
    }),
  );

  private _notifications$ = new BehaviorSubject<XRNotification[]>([]);
  notifications$ = this._notifications$.asObservable();

  private _selectedNotification$ = new BehaviorSubject<XRNotification | null>(null);
  selectedNotification$ = this._selectedNotification$.asObservable().pipe(filter((data) => !!data));

  currentLang$ = this._accountService.language$;

  languageSetting$ = new BehaviorSubject<AccountSetting | null>(null);

  isDesktop = this._sessionStorageService.getItem(SessionStorageKeys.PLATFORM) === PlatformDetail.DESKTOP ? true : false;

  private _destroy$ = new Subject<void>();

  constructor(
    private _msalService: MsalService,
    private _router: Router,
    private _translateService: TranslateService,
    private _accountService: AccountService,
    private _notificationService: NotificationService,
    private _fileService: FileService,
    private _broadcastService: MsalBroadcastService,
    private _toastrService: ToastrService,
    private _modalService: ModalService,
    private _localStorageService: LocalStorageService,
    private _sessionStorageService: SessionStorageService,
    private _ngZone: NgZone,
  ) {
    this._broadcastService.msalSubject$
      .pipe(
        filter((res) => res.eventType == EventType.ACQUIRE_TOKEN_SUCCESS || res.eventType == EventType.LOGIN_SUCCESS),
        take(1),
      )
      .subscribe(() => {
        this.getNotifications();
      });
  }

  getNotifications(cached = false): void {
    this._notificationService.getAll(cached).then((notifications: XRNotification[]) => {
      this._notifications$.next(notifications);
    });
  }

  openPanel(): void {
    this._isPanelOpened$.next(true);
  }

  closePanel(): void {
    if (this._isPanelOpened$.getValue()) {
      this._isPanelOpened$.next(false);
      this._isDetailsPanelOpened$.next(false);

      // wait for details panel to hide - then destroy inner components
      this._ngZone.runOutsideAngular(() => {
        interval(300)
          .pipe(take(1), untilDestroyed(this))
          .subscribe(() => {
            this._openedDetails$.next('');
            this._ngZone.run(() => {});
          });
      });
    }
  }

  openDetails(name: string): void {
    this._isDetailsPanelOpened$.next(true);

    if (!this._openedDetails$.value) {
      this._openedDetails$.next(name);
    } else {
      this._isDetailsPanelOpened$.next(false);

      this._ngZone.runOutsideAngular(() => {
        interval(300)
          .pipe(take(1), untilDestroyed(this))
          .subscribe(() => {
            this._isDetailsPanelOpened$.next(true);
            this._openedDetails$.next(name);
            this._ngZone.run(() => {});
          });
      });
    }
  }

  closeDetails(): void {
    this._isDetailsPanelOpened$.next(false);

    // wait for details panel to hide - then destroy inner components
    this._ngZone.runOutsideAngular(() => {
      interval(300)
        .pipe(take(1), untilDestroyed(this))
        .subscribe(() => {
          this._openedDetails$.next('');
          this._ngZone.run(() => {});
        });
    });
  }

  selectNotification(notification: XRNotification): void {
    this._selectedNotification$.next(notification);
  }

  async deleteNotification(notification: XRNotification): Promise<void> {
    this._notificationService.remove(notification);
    this.getNotifications(true);
    return await this._notificationService.commit();
  }

  async isUsernameAvailable(username: string): Promise<boolean> {
    return await this._accountService.checkUsername(username);
  }

  async uploadFile(event) {
    if (event?.target?.files?.length) {
      const resize = <Blob>await this._fileService.resizeImage(event.target.files[0], 150);
      if (this._fileService.checkFormat(resize, FileType.Image)) {
        const account = <Account>this._accountService.account;
        const { url: thumbnailUrl } = await this._fileService.uploadFile(
          resize,
          FileStorageType.Public,
          undefined,
          account.Id,
          true,
          FileType.Image,
          undefined,
          Access.Public,
          MediaType.Profile,
        );
        account.Thumbnail = thumbnailUrl;
        this.updateAccount(account);
        account.Thumbnail = '';
        this._accountService.setAccountSubject(account);
      }
    }
  }

  async removeThumbnail() {
    const account = <Account>this._accountService.account;
    account.Thumbnail = '';
    await this.updateAccount(account);
  }

  async saveLanguage(lang: LanguageCode) {
    await this._accountService.setLanguage(lang);
  }

  async updateAccount(updates: Partial<Account>) {
    const newAccount = Object.assign({}, <Account>this._accountService.account, updates);
    const b2CAttribute = this.returnPropertyInObject(updates, '_b2CAttribute');
    newAccount.B2CAttribute = b2CAttribute ?? [];

    await this._accountService.updateAccount(newAccount);

    if (b2CAttribute) {
      await this._accountService.updateAccountB2CAttribute(b2CAttribute);
    }
    this._accountService.setAccountSubject(newAccount);
  }

  returnPropertyInObject(obj: Object, propertyName: string) {
    if (obj.hasOwnProperty(propertyName)) {
      return obj[propertyName];
    }
    return undefined;
  }

  async sendFeedback(message: string) {
    await this._accountService.postFeedback(message);

    message = this._translateService.instant('shared.information.feedbackSaved');
    this._toastrService.info(message, '');
  }

  async deleteAccount(message: string) {
    let confimationModal;
    let informationModal;

    if (this.isDesktop) {
      confimationModal = this._modalService.open(ConfirmationComponent);
      if (confimationModal) {
        confimationModal.instance.title = this._translateService.instant('shared.confirmation.confirmAction');
        confimationModal.instance.confirmAction = this._translateService.instant('shared.confirmation.delete');
        confimationModal.instance.body = this._translateService.instant('shared.confirmation.deleteAccountConfirmationMessage');
      } else return;
    } else {
      confimationModal = this._modalService.open(MobileConfirmationComponent);
      if (confimationModal) {
        {
          confimationModal.instance.body = this._translateService.instant('shared.confirmation.deleteAccountConfirmationMessage');
          confimationModal.instance.confirmAction = 'shared.confirmation.delete';
        }
      } else return;
    }

    confimationModal.result.then(async (confirmed) => {
      if (confirmed) {
        await this.sendFeedback(message);
        const loginRequest = { scopes: ['openid', 'email', 'profile'] };
        const currentSub = this._msalService.instance.getAllAccounts()[0].idTokenClaims?.sub;

        await this._msalService
          .acquireTokenSilent(loginRequest)
          .pipe(takeUntil(this._destroy$))
          .subscribe(async (loginResponse) => {
            // compare decodedToken.sub with the subject from the response
            if (loginResponse.idTokenClaims['sub'] === currentSub) {
              await this._accountService.deleteAccount();

              if (this.isDesktop) {
                informationModal = this._modalService.open(InformationComponent);
                informationModal.instance.message = this._translateService.instant('shared.information.accountWasDeleted');
              } else {
                informationModal = this._modalService.open(MobileInformationComponent);
                informationModal.instance.message = this._translateService.instant('shared.information.accountWasDeleted');
              }

              this._accountService.setAccountSubject(undefined);
              this.logout();
              this._localStorageService.clear();
              this.closePanel();

              this._ngZone.runOutsideAngular(() => {
                interval(1000)
                  .pipe(take(1), untilDestroyed(this))
                  .subscribe(() => {
                    this._router.navigate(['/']);
                    this._ngZone.run(() => {});
                  });
              });
            }
          });
      }
    });
  }

  async markNotificationViewed(notification: XRNotification) {
    notification.HasBeenViewed = true;
    await this._notificationService.setAsViewed([notification]);
    this._notificationService.modify(notification);
    this.getNotifications(true);
  }

  logout(): void {
    this._accountService.logout();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
