import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { generateComponentId } from '../../utils/component-utils';

@Component({
  selector: 'ui-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent implements OnInit {
  @Input() checked = false;

  @Input() group = 'group1';

  @Input() disabled = false;

  @Output() checkedChange = new EventEmitter<boolean>();

  public id: string = generateComponentId();
  constructor() {}

  ngOnInit() {}

  checkValue(event: any, value: boolean) {
    this.checkedChange.emit(event.target.checked);
  }
}
