import { ChangeDetectionStrategy, Component, ElementRef, Input, Output, ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { IModel } from '@/data/src/lib/models/data/base';
import { IAsset } from '@/data/src/lib/models/data/asset';
import { FileReference } from '@/data/src/lib/models/data/file-reference';
import { MAX_LIGHTS, SOUND_ONLY_PNG } from '@/data/src/lib/view-manager';
import { AccountService } from '@/data/src/lib/services/account.service';
import { ListOrderOption } from '@/data/src/lib/models/interfaces/list-order-option';
import { FilterPipe } from '@/data/src/lib/pipes/filter.pipe';
import { Permission } from '@/data/src/lib/enums/permission';

/**
 * TODO: Rename as LibraryCardListComponent
 */

type ILightItem = { Name: string; Icon: string; Count: number };

export type ILibraryItem = (IAsset | FileReference | ILightItem) & {
  checked?: boolean;
  disabled?: boolean;
};

@Component({
  selector: 'ui-assets-menu-card-list',
  templateUrl: './assets-menu-card-list.component.html',
  styleUrls: ['./assets-menu-card-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetsMenuCardListComponent {
  @ViewChild('cardList') cardList!: ElementRef;

  public hoveredCard: any = null;
  public searchText = '';
  public endIndex = 12;
  public showMoreTags: string[] = []; // assetId's to show more
  permission = Permission;
  $account = this._accountService.activeAccount$;

  orderingOptions: ListOrderOption[] = [
    { value: '-DateCreated', name: 'lastAdded' },
    { value: 'DateCreated', name: 'oldest' },
    { value: 'Name', name: 'alphabeticallyAscShort' },
    { value: '-Name', name: 'alphabeticallyDescShort' },
  ];

  orderBy: ListOrderOption = this.orderingOptions[0];

  private _items: ILibraryItem[] | any[] = [];
  private _progressMap: { [key: string]: any } = {};

  // TODO: Improve
  @Input() set items(value: ILibraryItem[]) {
    if (this.disableItems?.length && value?.length) {
      value.forEach((item) => {
        if (this.disableItems.includes(item.Name)) {
          item.disabled = true;
        }
      });
    }

    this._items = value.map((item) => ({ ...item, DateCreated: (item as IAsset).DateCreated, checked: false }));
  }

  @Input() set progressMap(value: { [key: string]: any }) {
    this._progressMap = value;
  }

  @Input() selectedMap!: Map<string, IModel | boolean> | undefined;

  @Input() allowAdd = true;
  @Input() allowReselect = false;
  @Input() disableSelection = false;
  @Input() showSearch = true;
  @Input() showSelectNone = true;
  @Input() showAddNew = true;
  @Input() canDeselect = true;
  @Input() canDelete = false;
  @Input() canCheckMultiple = false;
  @Input() checkedItems: any[] = [];
  @Input() respectSelectedMap = true;
  @Input() showInitialize = false;
  @Input() filterByFields: string[] = ['Name', 'Tags'];
  @Input() disableItems: string[] = []; // Names of items to disable
  @Input() isLight = false;
  @Input() isImages = false;
  @Input() isVideos = false;
  @Input() sort = true;

  @Output() selectionChange = new EventEmitter<string>();
  @Output() selectionRemoved = new EventEmitter<string>();
  @Output() newSelection = new EventEmitter<void>();
  @Output() noneSelection = new EventEmitter<string>();
  @Output() deleteItem = new EventEmitter<FileReference>();
  @Output() onMultipleItemSelect = new EventEmitter<FileReference[]>();

  SOUND_ONLY_PNG = SOUND_ONLY_PNG;

  get items() {
    return this._items;
  }

  get isMedia(): boolean {
    return this.isImages || this.isVideos;
  }

  get maxLights() {
    return MAX_LIGHTS;
  }

  get isEnterpriseOrAdmin() {
    return this._accountService.isEnterpriseOrAdmin;
  }

  get numberOfItems() {
    const term = this.searchText ? this.searchText.toLowerCase().replace(/,/g, '').replace(/#/g, '') : '';
    const result = term
      ? this.items.filter((i) => {
          if (this.filterByFields?.length) {
            let contains = false;
            this.filterByFields.forEach((key) => {
              if (FilterPipe.contains(i[key], term)) {
                contains = true;
              }
            });
            return contains;
          } else {
            return FilterPipe.contains(i, term);
          }
        })
      : this.items;
    return result?.length || 0;
  }

  get isImporting() {
    return this._items.some((a) => {
      const key = a.AssetId || a.Name || '';
      return this.progressMap[key] !== undefined || !!this.progressMap[key];
    });
  }

  get progressMap() {
    return this._progressMap;
  }

  constructor(private _accountService: AccountService) {}

  toggleShowMore(itemId: string) {
    if (this.showMoreTags.includes(itemId)) {
      this.showMoreTags.splice(this.showMoreTags.indexOf(itemId), 1);
    } else {
      this.showMoreTags.push(itemId);
    }
  }

  isIncludeSearch(tag: string) {
    return tag.toLowerCase().includes(this.searchText.toLowerCase());
  }

  getTags(item: any): string[] {
    if (item?.Tags?.length) {
      const tags = item.Tags.split(',');
      return tags.filter((tag) => !!tag).map((tag) => (tag = `# ${tag}`));
    }
    return [];
  }

  toggleSelection(item: IAsset) {
    const searchBy = item.Id ?? item.Name;

    if (this.canDeselect && this.selectedMap?.get(searchBy)) {
      this.selectionRemoved.emit(item.Id);
    } else if (!this.selectedMap?.get(searchBy) || this.allowReselect) {
      this.selectionChange.emit(searchBy);
    }
  }

  isItemChecked(item: any) {
    return !!this.checkedItems.find((i) => i.Id === item.Id);
  }

  onItemCheck(isChecked: string | boolean, item: any) {
    const newCheckedList = [...this.checkedItems];
    if (isChecked) {
      newCheckedList.push(item);
      this.onMultipleItemSelect.emit(newCheckedList);
    } else {
      const index = newCheckedList.findIndex((i) => i.Id === item.Id);
      if (index > -1) {
        newCheckedList.splice(index, 1);
        this.onMultipleItemSelect.emit(newCheckedList);
      }
    }
  }

  onDelete($event: Event, item: any) {
    $event.stopPropagation();
    this.deleteItem.emit(item as FileReference);
  }

  setNone() {
    if (!this.respectSelectedMap || (this.respectSelectedMap && this.selectedMap)) {
      this.noneSelection.emit(this.selectedMap?.keys()?.next()?.value);
    }
  }

  addNew() {
    this.newSelection.emit();
  }

  updateListIndices() {
    const element = this.cardList.nativeElement;
    this.endIndex = Math.max(this.endIndex, Math.round((element.scrollTop + element.clientHeight) / 160) * 3);
  }

  changeOrderBy(orderBy: ListOrderOption): void {
    this.orderBy = orderBy;
  }

  checkboxClick($event) {
    $event.stopPropagation();
  }
}
