import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uiHighlight',
})
export class UiHighlightPipe implements PipeTransform {
  transform(value: any, args: any): unknown {
    if (!args) return value;
    const re = new RegExp(args, 'igm');
    value = value.replace(re, '<mark>$&</mark>');

    return value;
  }
}
