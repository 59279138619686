import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MAX_VERSIONS } from '@/data/src/lib/view-manager';
import { ApplicationService } from '@/view/src/app/app.service';
import { PanelComponent } from '@/ui/src/lib/components/panel/panel.component';
import { XRAsset, XRAssetVersion } from '@/data/src/lib/models/data/asset';

@UntilDestroy()
@Component({
  selector: 'ui-asset-versions-panel',
  templateUrl: './asset-versions-panel.component.html',
  styleUrls: ['./asset-versions-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetVersionsPanelComponent extends PanelComponent {
  _asset: XRAsset;
  _selectedVersion: XRAssetVersion | undefined;
  versionName: string;
  MAX_VERSIONS = MAX_VERSIONS;

  @Input() set asset(input: XRAsset) {
    if (!input) {
      return;
    }
    this._asset = input;
    if (!this.selectedVersion && input.Versions?.length) {
      this.selectedVersion = input.versions![0];
    }
    this._cd.detectChanges();
  }

  get asset() {
    return this._asset;
  }

  set selectedVersion(input: XRAssetVersion | undefined) {
    this._selectedVersion = input;
    if (this._selectedVersion) {
      this.versionName = this._selectedVersion.Name;
    }
    this._cd.detectChanges();
  }

  get selectedVersion(): XRAssetVersion | undefined {
    return this._selectedVersion;
  }

  get versionOptions() {
    return this.asset?.versions?.map((value) => ({ value: value.Id, label: value.Name })) ?? [];
  }

  constructor(
    protected _appService: ApplicationService,
    private _cd: ChangeDetectorRef,
  ) {
    super(_appService);
  }

  ngOnInit() {
    this._appService.selectedAssetVersionSubject.pipe(untilDestroyed(this)).subscribe((version) => {
      this.selectedVersion = version;
      this._cd.detectChanges();
    });
  }

  onVersionSelected(versionId: string) {
    const version = this.asset.versions?.find((v) => v.Id === versionId);
    version && this._appService.setSelectedAssetVersion(version);
  }

  onChangeEnd() {
    if (!this.asset || !this.selectedVersion) {
      return;
    }
    if (this.versionName !== this.selectedVersion.Name) {
      this.selectedVersion.Name = this.versionName;
      this._appService.updateAssetVersion(this.selectedVersion);
    }
  }

  ngOnDestroy() {
    this.onChangeEnd();
    super.ngOnDestroy();
  }
}
