import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { AccountSetting } from 'projects/data/src/lib/models/data/account';
import { LanguageCode } from 'projects/data/src/lib/services/account.service';
import { ReleaseLog, ReleaseLogData, ReleaseLogService } from 'projects/data/src/lib/services/release-log.service';
import { UserSettingsService } from 'projects/data/src/lib/services/user-settings.service';
import { interval, map, Subject, take, takeUntil } from 'rxjs';
import { AccountPanelService } from '../../layout/account-panel/account-panel.service';
import { ModalService } from '../modal.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'ui-release-log',
  templateUrl: './release-log.component.html',
  styleUrls: ['./release-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReleaseLogComponent implements OnInit, OnDestroy {
  public lastSeenReleaseLogSetting: AccountSetting;
  public lastSeenReleaseLogDate: string;
  public title = 'shared.information.releaseLogTitle';
  public releaseLogData$ = this._releaseLogService.releaseLog$.pipe(
    map((log: ReleaseLog[]) => {
      return log.filter((item) => {
        return new Date(item.DisplayDate) >= new Date(this.lastSeenReleaseLogSetting.Value);
      });
    }),
    map((log: ReleaseLog[]) => {
      const result: ReleaseLogData[] = [];
      if (log.length > 1) {
        log = [log[0]];
      }
      this.lastSeenReleaseLogDate = log[0]?.DisplayDate;
      log.forEach((log) => result.push(...log.Data));
      return result;
    }),
  );
  checkboxChecked = false;
  currentLanguage = LanguageCode.Korean;

  private _destroy = new Subject<void>();

  constructor(
    private _modalService: ModalService,
    private _settingsService: UserSettingsService,
    private _releaseLogService: ReleaseLogService,
    private _accountPanelService: AccountPanelService,
    private _ngZone: NgZone,
  ) {
    this._accountPanelService.currentLang$.pipe(takeUntil(this._destroy)).subscribe((lang) => (this.currentLanguage = lang));
    this._settingsService.getSettingByName('LastSeenReleaseLog', '2022-01-01T00:00:00Z', false).then((setting) => {
      this.lastSeenReleaseLogSetting = setting;
    });
    this._settingsService.getSettingByName('ShowReleaseLogOnEveryLogin', 'true', false).then((setting) => {
      this.checkboxChecked = setting.Value !== 'true';
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  goToLogs(event): void {
    event.stopPropagation();
    event.preventDefault();
    this._accountPanelService.openPanel();

    this._ngZone.runOutsideAngular(() => {
      interval(300)
        .pipe(take(1), untilDestroyed(this))
        .subscribe(() => {
          this._accountPanelService.openDetails('release-log');
          this._ngZone.run(() => {});
        });
    });

    this.close();
  }

  close(): void {
    this._settingsService.getSettingByName('ShowReleaseLogOnEveryLogin', 'true').then(async (setting) => {
      setting.Value = this.checkboxChecked ? 'false' : 'true';
      this._settingsService.modify(setting);
      if (this.lastSeenReleaseLogDate) {
        this.lastSeenReleaseLogSetting.Value = this.lastSeenReleaseLogDate;
        this._settingsService.modify(this.lastSeenReleaseLogSetting);
      }
      this._settingsService.commit().then(() => {});
      this._modalService.close('', this['modalContainerRef']);
    });
  }
}
