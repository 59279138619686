<div class="wrapper">
  <input
    autoSizeInput
    [placeholder]="placeholder"
    [(ngModel)]="value"
    [extraWidth]="10"
    [includeBorders]="true"
    [usePlaceHolderWhenEmpty]="true"
    [disabled]="disabled"
    (ngModelChange)="textChanged($event)"
  />
  @if (!placeholder || !!value) {
    <span>{{ inplaceStaticText }}</span>
  }
  @if (loading) {
    <ui-loader></ui-loader>
  }
</div>
