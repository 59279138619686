<div class="container">
  <div class="header">
    @if (showingRecommend) {
      <div class="recommend">{{ 'domain.menuCard.recommend' | translate }}</div>
    }
    @if (!showingRecommend) {
      <div class="empty-space"></div>
    }
    <div class="icon icon-info md" uiTooltip="{{ settingData.tooltip | translate }}"></div>
  </div>
  <div class="menu-description">{{ settingData.description | translate }}</div>
  <div class="menu-title">{{ settingData.mainTitle | translate }}</div>
  <div class="wrapper">
    @if (!showComingSoonBtn) {
      <button ui-button [disabled]="loading" (click)="onClick()" (mouseover)="onHover('over')" (mouseout)="onHover('out')">
        @if (!loading) {
          {{ buttonTitle | translate }}
        } @else {
          <ui-loader></ui-loader>
        }
      </button>
    }
    @if (showComingSoonBtn) {
      <button ui-button (click)="onClick()" [disabled]="true">Comming Soon</button>
    }
  </div>
</div>
