export function toDictionary<T>(data: T[], key: string, value?: string): Map<string, T> {
  const result: any = {};
  for (const item of data) {
    if (item) {
      const itemAny: any = item;
      const itemValue = itemAny[key];
      if (itemValue) {
        result[itemValue] = value ? itemAny[value] : item;
      }
    }
  }
  return new Map(Object.keys(result).map((k) => [k, result[k]]));
}

export function toDictionaryValue<T>(data: T[], key: string, value: string): Map<string, any> {
  return toDictionary(data, key, value) as Map<string, any>;
}

export function toMap<T>(data: T[]) {
  const result = new Map<T, boolean>();
  for (const item of data) {
    result.set(item, true);
  }
  return result;
}

export function toMapByValue<T>(data: T[], value: string) {
  const result = new Map<string, boolean>();
  for (const item of data) {
    result.set(item[value], true);
  }
  return result;
}

export function mergeDictionary<TKey, TValue>(dict1: Map<TKey, TValue>, dict2: Map<TKey, TValue>) {
  const result = new Map<TKey, TValue>();

  for (const keyVal of dict2) {
    result.set(keyVal[0], keyVal[1]);
  }
  // Keep the values in the first dictionary
  for (const keyVal of dict1) {
    result.set(keyVal[0], keyVal[1]);
  }

  return result;
}

export function unique<T>(data: T[], key?: string): T[] {
  const map = new Map<T, T>();
  for (const item of data) {
    if (key) {
      map.set((item as any)[key], item);
    } else {
      map.set(item, item);
    }
  }
  return Array.from(map.values());
}

export function except<T>(data: T[], except: T[]): T[] {
  const map = new Map<T, T>();
  for (const item of except) {
    map.set(item, item);
  }
  const result: T[] = [];
  for (const item of data) {
    if (!map.get(item)) {
      result.push(item);
    }
  }

  return result;
}

export function intersect<T>(data: T[], intersect: T[]): T[] {
  const map = new Map<T, T>();
  for (const item of intersect) {
    map.set(item, item);
  }
  const result: T[] = [];
  for (const item of data) {
    if (map.get(item)) {
      result.push(item);
    }
  }

  return result;
}

export function union<T>(data: T[], union: T[]): T[] {
  const map = new Map<T, T>();
  for (const item of data) {
    map.set(item, item);
  }
  const result: T[] = [];
  result.push(...data);
  for (const item of union) {
    if (!map.get(item)) {
      result.push(item);
    }
  }

  return result;
}

export function sum(data: number[]): number {
  let sum = 0;
  for (const n of data) {
    sum += n;
  }
  return sum;
}

export function removeNulls<T>(data: (T | undefined | null)[]): T[] {
  const result = data.filter((d) => d) as T[];
  return result;
}

export function groupData<T>(data: T[], key: string): Map<string, T[]> {
  const result: any = {};
  for (const item of data) {
    const itemAny: any = item;
    if (itemAny[key]) {
      if (!result[itemAny[key]]) {
        result[itemAny[key]] = [item];
      } else {
        result[itemAny[key]].push(item);
      }
    }
  }
  return new Map(Object.keys(result).map((k) => [k, result[k]]));
}

export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}
export function enumValues<O extends object, K extends keyof O = keyof O>(obj: O): O[K][] {
  return Object.keys(obj)
    .filter((k) => Number.isNaN(+k))
    .map((k) => obj[k as K]) as O[K][];
}

export function enumValue<O extends object, K extends keyof O = keyof O>(obj: O, key: K) {
  return obj[key];
}

export function removeItems<T>(array: T[], item: T[]) {
  const hash = new Map<T, T>();
  array.forEach((a) => {
    hash.set(a, a);
  });

  for (const i of item) {
    hash.delete(i);
  }
  return Array.from(hash.values());
}

export function removeItem<T>(array: T[], item: T) {
  const index = array.indexOf(item);
  if (index > -1) {
    array.splice(index, 1);
  }
}

export function createSeries(start: number, step: number, length: number) {
  const result: number[] = [];
  for (let i = 0; i < length; i++) {
    result.push(start + step * i);
  }
  return result;
}

export function flatten<T>(array: T[][]) {
  const result: T[] = [];
  for (const item of array) {
    if (Array.isArray(item)) {
      result.push(...item);
    } else {
      result.push(item);
    }
  }
  return result;
}

export function sortByKey<TValue, TKey>(list: TValue[], keys: TKey[]) {
  let result: { key: TKey; value: TValue }[] = [];

  for (let i = 0; i < list.length; i++) {
    result.push({ key: keys[i], value: list[i] });
  }

  result = result.sort((a, b) => {
    return a.key > b.key ? 1 : -1;
  });

  return result;
}

export function max(array: number[]) {
  let max = Number.MIN_VALUE;
  for (let i = 0; i < array.length; i++) {
    if (array[i] > max) {
      max = array[i];
    }
  }
  return max;
}

export function min(array: number[]) {
  let min = Number.MAX_VALUE;
  for (let i = 0; i < array.length; i++) {
    if (array[i] < min) {
      min = array[i];
    }
  }
  return min;
}
