<div class="header">
  <div class="count-box">
    <div class="group">
      <div class="simple-image happy"></div>
      {{ happyCount }}
    </div>
    <div class="group">
      <div class="simple-image surprise"></div>
      {{ surpriseCount }}
    </div>
    <div class="group">
      <div class="simple-image congrats"></div>
      {{ congratsCount }}
    </div>
    <div class="group">
      <div class="simple-image sad"></div>
      {{ sadCount }}
    </div>
    <div class="group">
      <div class="simple-image awesome"></div>
      {{ awesomeCount }}
    </div>
  </div>
  <div class="close icon icon-close sm" (click)="modalClose(false)"></div>
</div>
<div class="body">
  <div class="border">
    <div class="new-card wrapper">
      <div
        class="simple-image"
        [ngClass]="selectedEmoji.length ? selectedEmoji : 'add'"
        (click)="this.isEmojiSetOpen = !this.isEmojiSetOpen"
      ></div>
      @if (isEmojiSetOpen) {
        <div class="emoji" (uiClickOutside)="isEmojiSetOpen = false">
          <div class="img-container" (click)="onSelectionChanged('happy')">
            <div class="animated-image happy" [class.selected]="selectedEmoji === 'happy'"></div>
            <div class="simple-image happy" [class.selected]="selectedEmoji === 'happy'"></div>
          </div>
          <div class="img-container" (click)="onSelectionChanged('surprise')">
            <div class="animated-image surprise" [class.selected]="selectedEmoji === 'surprise'"></div>
            <div class="simple-image surprise" [class.selected]="selectedEmoji === 'surprise'"></div>
          </div>
          <div class="img-container" (click)="onSelectionChanged('congrats')">
            <div class="animated-image congrats" [class.selected]="selectedEmoji === 'congrats'"></div>
            <div class="simple-image congrats" [class.selected]="selectedEmoji === 'congrats'"></div>
          </div>
          <div class="img-container" (click)="onSelectionChanged('sad')">
            <div class="animated-image sad" [class.selected]="selectedEmoji === 'sad'"></div>
            <div class="simple-image sad" [class.selected]="selectedEmoji === 'sad'"></div>
          </div>
          <div class="img-container" (click)="onSelectionChanged('awesome')">
            <div class="animated-image awesome" [class.selected]="selectedEmoji === 'awesome'"></div>
            <div class="simple-image awesome" [class.selected]="selectedEmoji === 'awesome'"></div>
          </div>
        </div>
      }
      <span class="username">{{ 'community.view.guestbook.noLoginInfo' | translate }}</span>
      @if (newComment.length) {
        <span class="comment" (click)="openPopup()">{{ newComment }}</span>
      } @else {
        <span class="comment placeholder" (click)="openPopup()">{{ 'community.view.guestbook.note' | translate }}</span>
      }
      <button ui-button xxs [disabled]="!newComment.length || !selectedEmoji" (click)="addComment()">
        {{ 'community.view.guestbook.leave' | translate }}
      </button>
    </div>
    @if (interactions.length! > 0) {
      @for (item of interactions; track item; let i = $index) {
        <div class="wrapper">
          @if (
            (!!activeAccount && activeAccount.Id === selectedScene?.CreatorUser?.Id) ||
            item.Username === activeAccount?.Username ||
            isEnterpriseOrAdmin
          ) {
            <div class="close" (click)="deleteComment(item.Id)">
              <div class="icon icon-close xs"></div>
            </div>
          }
          <div class="simple-image" [ngClass]="item.Interaction.Emoji"></div>
          <div class="username">{{ item.Username | uppercase }}</div>
          <textarea class="comment" uiTextarea nonResizable rows="4" [(ngModel)]="item.Interaction.Comment" [disabled]="true"></textarea>
          <div class="date">{{ item.Date | date: 'yyyy.MM.dd' }}</div>
        </div>
      }
    }
  </div>
</div>
