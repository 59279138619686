<div class="popup-content-modal">
  <section class="modal-body">
    <div
      class="title-wrapper"
      [style.backgroundColor]="data['background-colour']"
      [style.display]="isTitleHidden ? 'none' : 'flex'"
      [style.color]="data['text-colour']"
    >
      <div #title class="title" innerText="{{ data['title'] }}" uiOverflowReadmore></div>
    </div>

    <div class="editor-container">
      <quill-view class="w-100" [content]="data['body']" [preserveWhitespace]="true"> </quill-view>
    </div>
  </section>
  <div class="close icon-wrapper" (click)="close()">
    <div class="icon icon-close sm"></div>
  </div>
</div>
