import { ActionEvent, ActionManager, ExecuteCodeAction, Mesh, Scene } from '../../babylon';

export class Interaction {
  action: ExecuteCodeAction | null;
  callback: (event: ActionEvent) => void;

  constructor(
    private scene: Scene,
    public mesh: Mesh,
    private triggerOptions: any,
    callback: (event: ActionEvent) => void = () => {},
    register = false,
    _pickInvisible = true,
  ) {
    this.mesh = mesh;
    this.scene = scene;
    this.callback = callback;
    this.triggerOptions = triggerOptions;
    this.action = null;
    this.register = this.register.bind(this);
    this.unregister = this.unregister.bind(this);
    if (register) this.register();
  }

  register() {
    if (!this.mesh?.actionManager) {
      this.mesh.actionManager = new ActionManager(this.scene);
    }
    this.mesh.actionManager.isRecursive = true;
    if (!this.action) this.action = new ExecuteCodeAction(this.triggerOptions, this.callback);
    this.mesh.enablePointerMoveEvents = true;
    this.mesh.actionManager.registerAction(this.action);
    this.mesh.actionManager.hoverCursor = 'pointer';
  }

  unregister() {
    if (this.mesh?.actionManager && this.action) {
      this.mesh.actionManager.unregisterAction(this.action);
    }
    if (this.mesh?.actionManager && this.mesh.actionManager.actions.length <= 0) {
      this.mesh.actionManager.hoverCursor = '';
      this.mesh.actionManager.dispose();
    }
  }

  assign(callback: (event: ActionEvent) => void, register = true) {
    this.unregister();
    this.callback = callback;
    this.action = new ExecuteCodeAction(this.triggerOptions, this.callback);
    if (register) this.register();
  }
}
