import { Location } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ui-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss'],
})
export class ComingSoonComponent implements OnInit {
  @Input() comment = 'shared.information.comingSoon';

  constructor(private _location: Location) {}

  ngOnInit(): void {}

  onClick() {
    this._location.back();
  }
}
