import { v4 as uuid } from 'uuid';
import { Animation, AnimationGroup, Color3, EasingFunction, AbstractMesh, PowerEase, Vector3 } from '@/data/src/lib/babylon';
import { InteractionTrigger, EffectType, MoveMode, TEffect, SHINE_COLOR, SELECTION_COLOR } from '@/data/src/lib/view-manager';
import { ViewManagerUtils } from '../../utils/view-manager-utils';
import { InteractableRenderable } from '../renderables/interactable-renderable';
import { deepCopy } from '../../utils/data';

const FRAME_RATE = 30;

const INFLATE_EASE = new PowerEase();
INFLATE_EASE.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
const BOUNCE_EASE = new PowerEase();
BOUNCE_EASE.setEasingMode(EasingFunction.EASINGMODE_EASEOUT);

export class Effect {
  private descriptor: TEffect;

  public animationGroup: AnimationGroup;
  private callback: () => void;
  private rollback: () => void;
  private data: { [key: string]: any };
  private parameters: { [key: string]: any };
  private defaultValues: any = {};
  private loop: boolean;
  private loopMode: number;
  private meshes: AbstractMesh[] = [];

  public isPlaying: boolean = false;

  get scene() {
    return this.renderable.viewManager.scene;
  }

  get trigger() {
    return this.descriptor?.trigger;
  }

  constructor(
    private renderable: InteractableRenderable,
    descriptor: TEffect,
  ) {
    this.play = this.play.bind(this);
    this.reset = this.reset.bind(this);
    this.setup = this.setup.bind(this);
    this.update = this.update.bind(this);
    this.setup(renderable, descriptor);
  }

  setup(renderable: InteractableRenderable, descriptor: TEffect) {
    this.descriptor = deepCopy(descriptor);
    this.data = {};
    this.parameters = {};
    this.animationGroup = new AnimationGroup(uuid(), this.scene);

    this.meshes.length = 0;
    this.renderable.helper
      .getChildMeshes(false)
      .concat(this.renderable.helper)
      .forEach((mesh) => {
        if (mesh.metadata.isOverlay || mesh.metadata.isControl || mesh === this.renderable.box) {
          return;
        }
        this.meshes.push(mesh);
      });

    this.descriptor.parameters.forEach(({ key, value }) => {
      switch (key) {
        case 'color':
          const color = ViewManagerUtils.IsHexValid(value)
            ? Color3.FromHexString(value)
            : Color3.FromArray(ViewManagerUtils.GetColorArray(value).slice(0, 3));
          this.parameters[key] = color;
          break;
        default:
          this.parameters[key] = value;
          break;
      }
    });

    if (descriptor.type && descriptor.trigger) {
      this.update();

      switch (descriptor.type) {
        case EffectType.Blink:
          this.defaultValues = { speed: { Min: 0, Max: 5 } };
          this.meshes.forEach((mesh) => {
            if (mesh.metadata.isControl) {
              return;
            }
            this.data[mesh.uniqueId] = mesh.visibility;
          });
          this.callback = () => {
            this.animationGroup.start(this.loop, this.animationGroup.speedRatio);
          };

          this.rollback = () => {
            this.animationGroup.stop();
            this.meshes.forEach((mesh) => {
              if (mesh.metadata.isControl) {
                return;
              }
              mesh.animations.length = 0;
              mesh.visibility = this.data[mesh.uniqueId];
            });
          };
          break;
        case EffectType.Shine:
          this.defaultValues = { speed: { Min: 0, Max: 5 } };
          this.defaultValues.color = SHINE_COLOR;
          this.callback = () => {
            this.meshes.forEach((mesh) => {
              if (mesh.metadata.isControl) {
                return;
              }
              mesh.renderOverlay = true;
              mesh.overlayAlpha = 0;
              mesh.overlayColor.copyFrom(this.parameters.color);
            });
            this.animationGroup.start(this.loop, this.animationGroup.speedRatio);
          };

          this.rollback = () => {
            this.animationGroup.stop();
            this.meshes.forEach((mesh) => {
              if (mesh.metadata.isControl) {
                return;
              }
              mesh.animations.length = 0;
              mesh.overlayColor.copyFrom(SELECTION_COLOR);
              mesh.renderOverlay = false;
            });
          };
          break;
        case EffectType.Inflate:
          this.defaultValues = {
            speed: { Min: 0, Max: 0.1 },
            percent: { Min: 0, Max: 100 },
          };
          this.callback = () => {
            this.animationGroup.start(this.loop, this.animationGroup.speedRatio);
          };

          this.rollback = () => {
            this.animationGroup.stop();
            renderable.helper.animations.length = 0;
            renderable.helper.scaling.copyFrom(this.data.initialScaling);
          };
          break;
        case EffectType.Spin:
          this.defaultValues = { speed: { Min: 0, Max: 2 } };
          this.data.rotationSynchronizer = () => {
            renderable.helper.rotationQuaternion?.copyFrom(
              this.data.initialRotationQuaternion.multiply(this.data.temporaryRotation.toQuaternion()),
            );
          };

          this.callback = () => {
            this.animationGroup.start(this.loop, this.animationGroup.speedRatio);
            if (renderable.helper.rotationQuaternion) {
              this.data.onBeforeRenderObserver = this.data.onBeforeRenderObservable.add(this.data.rotationSynchronizer);
            }
          };

          this.rollback = () => {
            this.data.onBeforeRenderObserver && this.data.onBeforeRenderObservable.remove(this.data.onBeforeRenderObserver);
            this.animationGroup.stop();
            renderable.helper.animations.length = 0;
            renderable.helper.rotation.copyFrom(this.data.initialRotation);
            renderable.helper.rotationQuaternion?.copyFrom(this.data.initialRotationQuaternion);
          };
          break;
        case EffectType.Move:
          this.defaultValues = {
            speed: { Min: 0, Max: 5 },
            distance: { Min: 0, Max: 10 },
          };
          this.data.positionSynchronizer = () => {
            this.data.displacement = Math.sin(this.data.positionAngle) * this.parameters.distance;
            if (this.parameters.mode === MoveMode.SideToSide) {
              renderable.helper.position.x = this.data.initialPosition.x + this.data.displacement;
            } else if (this.parameters.mode === MoveMode.ForwardBack) {
              renderable.helper.position.z = this.data.initialPosition.z + this.data.displacement;
            }
          };

          this.callback = () => {
            this.animationGroup.start(this.loop, this.animationGroup.speedRatio);
            this.data.onBeforeRenderObserver = this.data.onBeforeRenderObservable.add(this.data.positionSynchronizer);
          };

          this.rollback = () => {
            this.data.onBeforeRenderObserver && this.data.onBeforeRenderObservable.remove(this.data.onBeforeRenderObserver);
            this.animationGroup.stop();
            renderable.helper.animations.length = 0;
            renderable.helper.position.copyFrom(this.data.initialPosition);
          };
          break;
        case EffectType.Bounce:
          this.defaultValues = {
            speed: { Min: 0, Max: 5 },
            height: { Min: 0, Max: 10 },
          };
          this.data.positionSynchronizer = () => {
            this.data.displacement = Math.sin(this.data.positionAngle / 2) ** 3 * this.parameters.height;
            renderable.helper.position.y = this.data.initialPosition.y + this.data.displacement;
          };

          this.callback = () => {
            this.animationGroup.start(this.loop, this.animationGroup.speedRatio);
            this.data.onBeforeRenderObserver = this.data.onBeforeRenderObservable.add(this.data.positionSynchronizer);
          };

          this.rollback = () => {
            this.data.onBeforeRenderObserver && this.data.onBeforeRenderObservable.remove(this.data.onBeforeRenderObserver);
            this.animationGroup.stop();
            renderable.helper.animations.length = 0;
            renderable.helper.position.copyFrom(this.data.initialPosition);
          };
          break;
        default:
          break;
      }

      this.animationGroup.speedRatio = Math.max(this.parameters.speed - this.defaultValues.speed.Min, 0.01) / this.defaultValues.speed.Max;

      descriptor.trigger === InteractionTrigger.Click &&
        this.animationGroup.onAnimationGroupEndObservable.add(() => {
          this.reset();
        });

      this.reset();
    }
  }

  play() {
    this.descriptor.trigger === InteractionTrigger.Click && this.reset();
    !this.isPlaying && this.callback?.();
    this.isPlaying = true;
  }

  reset() {
    this.isPlaying && this.rollback?.();
    this.isPlaying = false;
  }

  update() {
    this.loop = this.descriptor.trigger !== InteractionTrigger.Click;
    this.loopMode = this.loop ? Animation.ANIMATIONLOOPMODE_CYCLE : Animation.ANIMATIONLOOPMODE_CONSTANT;

    this.animationGroup.targetedAnimations.length = 0;

    switch (this.descriptor.type) {
      case EffectType.Blink:
        this.meshes.forEach((mesh) => {
          if (mesh.metadata.isControl) {
            return;
          }
          const animation = new Animation(uuid(), 'visibility', FRAME_RATE, Animation.ANIMATIONTYPE_FLOAT, this.loopMode);
          animation.setKeys(getVisibilityKeys(mesh.visibility));
          this.data[mesh.uniqueId] = mesh.visibility;
          this.animationGroup.addTargetedAnimation(animation, mesh);
        });
        break;
      case EffectType.Shine:
        this.meshes.forEach((mesh) => {
          if (mesh.metadata.isControl) {
            return;
          }
          const overlayAlphaAnimation = new Animation(uuid(), 'overlayAlpha', FRAME_RATE, Animation.ANIMATIONTYPE_FLOAT, this.loopMode);
          overlayAlphaAnimation.setKeys([
            { frame: 0, value: 0 },
            { frame: FRAME_RATE / 2, value: 1 },
            { frame: FRAME_RATE, value: 0 },
          ]);
          this.animationGroup.addTargetedAnimation(overlayAlphaAnimation, mesh);
        });
        break;
      case EffectType.Inflate:
        {
          this.data.initialScaling = this.renderable.helper.scaling.clone();
          const animation = new Animation(uuid(), 'scaling', FRAME_RATE, Animation.ANIMATIONTYPE_VECTOR3, this.loopMode);
          animation.setEasingFunction(INFLATE_EASE);
          animation.setKeys(getScalingKeys(this.renderable.helper.scaling.clone(), 1 + this.parameters.percent / 100));
          this.animationGroup.addTargetedAnimation(animation, this.renderable.helper);
        }
        break;
      case EffectType.Spin:
        {
          this.data.onBeforeRenderObservable = this.scene.onBeforeRenderObservable;
          this.renderable.helper.reIntegrateRotationIntoRotationQuaternion = true;
          this.data.initialRotation = this.renderable.helper.rotation.clone();
          this.data.initialRotationQuaternion = this.renderable.helper.rotationQuaternion?.clone() ?? null;
          this.data.temporaryRotation = this.renderable.helper.rotationQuaternion?.toEulerAngles() ?? this.data.initialRotation.clone();

          let animation: Animation;
          if (this.renderable.helper.rotationQuaternion) {
            animation = new Animation(uuid(), 'temporaryRotation', FRAME_RATE, Animation.ANIMATIONTYPE_VECTOR3, this.loopMode);
            animation.setKeys(getSpinRotationKeys(Vector3.Zero()));
            this.animationGroup.addTargetedAnimation(animation, this.data);
          } else {
            animation = new Animation(uuid(), 'rotation.y', FRAME_RATE, Animation.ANIMATIONTYPE_FLOAT, this.loopMode);
            animation.setKeys(getSpinRotationKeys(this.renderable.helper.rotation.clone()));
            this.animationGroup.addTargetedAnimation(animation, this.renderable.helper);
          }
        }
        break;
      case EffectType.Move:
        {
          this.data.onBeforeRenderObservable = this.scene.onBeforeRenderObservable;
          this.data.initialPosition = this.renderable.helper.position.clone();
          this.data.positionAngle = 0;
          this.data.displacement = 0;

          const animation = new Animation(uuid(), 'positionAngle', FRAME_RATE, Animation.ANIMATIONTYPE_FLOAT, this.loopMode);
          animation.setKeys(getPositionAngleKeys());
          this.animationGroup.addTargetedAnimation(animation, this.data);
        }
        break;
      case EffectType.Bounce:
        {
          this.data.onBeforeRenderObservable = this.scene.onBeforeRenderObservable;
          this.data.initialPosition = this.renderable.helper.position.clone();
          this.data.positionAngle = 0;
          this.data.displacement = 0;

          const animation = new Animation(uuid(), 'positionAngle', FRAME_RATE, Animation.ANIMATIONTYPE_FLOAT, this.loopMode);
          animation.setKeys(getPositionAngleKeys());
          animation.setEasingFunction(BOUNCE_EASE);
          this.animationGroup.addTargetedAnimation(animation, this.data);
        }
        break;
      default:
        break;
    }
  }

  dispose() {
    this.reset();
    this.animationGroup.dispose();
    this.meshes.length = 0;
  }
}

const getVisibilityKeys = (initial: number) => [
  { frame: 0, value: initial },
  { frame: FRAME_RATE / 2, value: 0 },
  { frame: FRAME_RATE, value: initial },
];

const getScalingKeys = (initial: Vector3, target: number) => [
  { frame: 0, value: initial },
  { frame: FRAME_RATE / 2, value: initial.scale(target) },
  { frame: FRAME_RATE, value: initial },
];

const getSpinRotationKeys = (initial: Vector3) => [
  { frame: 0, value: initial },
  { frame: FRAME_RATE, value: initial.add(Vector3.UpReadOnly.scale(Math.PI * 1.999)) },
];

const getPositionAngleKeys = () => [
  { frame: 0, value: 0 },
  { frame: FRAME_RATE, value: Math.PI * 2 },
];
