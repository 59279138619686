import { HttpClient } from '@angular/common/http';
import { SvgHttpLoader, SvgLoader } from 'angular-svg-icon';
import { Observable } from 'rxjs';

export class SvgBrowserLoader implements SvgLoader {
  constructor(private http: HttpClient) {}

  getSvg(url: string): Observable<string> {
    return new SvgHttpLoader(this.http).getSvg(url);
  }
}
