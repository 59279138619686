import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalService } from '../../modal.service';

@Component({
  selector: 'ui-editor-controls-info',
  templateUrl: './editor-controls-info.component.html',
  styleUrls: ['./editor-controls-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorControlsInfoComponent {
  constructor(private _modalService: ModalService) {}

  close(): void {
    this._modalService.close(false, this['modalContainerRef']);
  }
}
