import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { environment } from 'projects/app/src/environments/environment';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ChatRoom, Message, ChatRoomUser, MessageSender } from '../models/data/chat';
import { AccountService } from './account.service';
import { XRScene } from '../models/data/scene';
import { LocalStorageService } from './local-storage.service';
import { LocalStorageKeys } from '../enums/storage-keys';

/**
 * Tracks and manages messages and chat sessions. Loads historic data from the backend and connects to signal r for real-time message and session updates
 */
@Injectable({
  providedIn: 'root',
})
export class ChatService implements OnInit, OnDestroy {
  private noAuthHttp: HttpClient;
  private connectionId: string;
  private ipAddress: string;
  private profileColorList = ['#6f63b9', '#ccc058', '#63b9a4', '#f66161', '#5dc05b', '#b78365', '#f9a4b3', '#d078e5'];

  private hubConnection!: signalR.HubConnection;

  private connectionSubject = new BehaviorSubject<boolean>(false);
  private chatRoomSubject = new BehaviorSubject<ChatRoom | undefined>(undefined);
  private messageSubject = new BehaviorSubject<Message | undefined>(undefined);
  private remainingTimeSubject = new BehaviorSubject<number>(0);

  public connection$ = this.connectionSubject.asObservable();
  public chatRoom$ = this.chatRoomSubject.asObservable();
  public message$ = this.messageSubject.asObservable();
  public remainingTime$ = this.remainingTimeSubject.asObservable();

  constructor(
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private _accountService: AccountService,
    handler: HttpBackend,
  ) {
    this.noAuthHttp = new HttpClient(handler);
  }

  ngOnDestroy() {
    this.hubConnection
      .stop()
      .then(function () {
        console.log('Disconnect success!');
      })
      .catch(function (err) {
        console.error(err.toString());
      });
  }

  async ngOnInit() {
    this.ipAddress = await this._accountService.getUserIP();
  }

  private randomThumbnail() {
    const randomIndex = Math.floor(Math.random() * this.profileColorList.length);
    return this.profileColorList[randomIndex];
  }

  public startConnection() {
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment[this._accountService.region].messengerUrl}`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .configureLogging(signalR.LogLevel.Information)
      .build();

    hubConnection.on('Connect', (id: string) => {
      if (id) {
        this.connectionId = id;
        this.connectionSubject.next(true);
      }
    });

    hubConnection.on(
      'ReceiveChatRoom',
      (
        returnFrom: 'Join' | 'Leave',
        chatRoomUser: ChatRoomUser,
        chatRoomUsers: ChatRoomUser[],
        numberOfUsersInChatRoom: string,
        dateCreated: Date,
      ) => {
        if (!this.chatRoomSubject.value?.messageSender) {
          //My Join

          const myInfo: MessageSender = !!chatRoomUser.user
            ? {
                id: chatRoomUser.user?.id,
                connectionId: chatRoomUser.signalRConnectionId,
                isLoggedInUser: !!chatRoomUser.user,
                alias: chatRoomUser.user?.alias,
                server: chatRoomUser.user?.server,
                thumbnail: chatRoomUser.user?.thumbnail,
              }
            : {
                id: chatRoomUser.ip,
                connectionId: chatRoomUser.signalRConnectionId,
                isLoggedInUser: !!chatRoomUser.user,
                nickName: chatRoomUser.nickName,
                thumbnail: this.randomThumbnail(),
              };

          chatRoomUsers.map((chatRoomUser: ChatRoomUser) => chatRoomUser.nickName && (chatRoomUser.thumbnail = this.randomThumbnail()));

          const newChatRoom: ChatRoom = {
            messageSender: myInfo,
            chatRoomUsers: chatRoomUsers,
            numberOfUsersInChatRoom: numberOfUsersInChatRoom,
            dateCreacted: dateCreated,
          };

          this.chatRoomSubject.next(newChatRoom);
        } else {
          //Other's Join : only Update numberOfUsers, chatRoomUsers of chatRoom
          const chatRoomInfo = this.chatRoomSubject.value;
          chatRoomUsers.map((user) => {
            if (user.nickName && user.nickName === chatRoomUser.nickName) {
              user.thumbnail = this.randomThumbnail();
            }
          });

          if (!!chatRoomInfo) {
            chatRoomInfo.numberOfUsersInChatRoom = numberOfUsersInChatRoom;
            chatRoomInfo.chatRoomUsers = chatRoomUsers;
            this.chatRoomSubject.next(chatRoomInfo);
          }
        }
      },
    );

    hubConnection.on('ReceiveToGroup', (chatRoomUser: ChatRoomUser, message: string, dateCreated: Date) => {
      const isLoggedInUser = !!chatRoomUser.user;

      let thumbnail;
      if (isLoggedInUser) {
        thumbnail = chatRoomUser.user?.thumbnail;
      } else {
        if (chatRoomUser.nickName === this.chatRoomSubject.value?.messageSender.nickName) {
          thumbnail = this.chatRoomSubject.value?.messageSender.thumbnail;
        } else {
          thumbnail = this.chatRoomSubject.value?.chatRoomUsers.find((user) => user.nickName === chatRoomUser.nickName)?.thumbnail;
        }
      }

      const newMessage: Message = {
        id: chatRoomUser.user ? chatRoomUser.user.id : chatRoomUser.ip,
        connectionId: chatRoomUser.signalRConnectionId,
        isLoggedInUser: isLoggedInUser,
        thumbnail: thumbnail,
        nickName: chatRoomUser.user ? chatRoomUser.user.alias : chatRoomUser.nickName,
        message: message,
        dateCreated: dateCreated,
      };

      this.messageSubject.next(newMessage);
    });

    hubConnection.on('ChatLimitInfo', (isLoggedInUser: boolean, remainingTime: number) => {
      this.remainingTimeSubject.next(remainingTime);
    });

    hubConnection.start().catch((err) => {
      console.error(err.toString());
    });

    this.hubConnection = hubConnection;
  }

  public stopConnection() {
    this.hubConnection
      .stop()
      .then(function () {
        console.log('Disconnect success!');
      })
      .catch(function (err) {
        console.error(err.toString());
      });
  }

  async joinChatRoom(scene: XRScene, isLoggedIn: boolean) {
    this.ipAddress = await this._accountService.getUserIP();

    const httpClient = isLoggedIn ? this.http : this.noAuthHttp;
    const server = this.localStorageService.getItem(LocalStorageKeys.REGION); // Not checking for logged-in-user
    const queryParams = `?forCommunity=${scene.IsPublished}&connectionId=${this.connectionId}&sceneId=${scene.Id}&server=${server}&ip=${this.ipAddress}`;

    return await lastValueFrom(
      httpClient.post(`${environment[this._accountService.region].crudApiUrl}/Chat/JoinChatRoom${queryParams}`, {
        headers: { 'Content-Type': 'application/json' },
      }),
    );
  }

  async sendToGroup(message: string) {
    const queryParams = `?connectionId=${this.connectionId}&message=${message}&ip=${this.ipAddress}`;

    return await lastValueFrom(
      this.noAuthHttp.post(`${environment[this._accountService.region].crudApiUrl}/Chat/SendToGroup${queryParams}`, {
        headers: { 'Content-Type': 'application/json' },
      }),
    );
  }
}
