import { Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { interval, take } from 'rxjs';

export interface SpaceToolColor {
  background: string;
  text: string;
  isSelected: boolean;
}

export enum SpaceToolLayout {
  EditorBelow,
  EditorRight,
  NoEditor,
}

export interface SpaceToolItem {
  name: string;
  description: string;
  altText: string;
  layout: SpaceToolLayout;
  color: SpaceToolColor;
  showContextMenu: boolean;
  showAltText: boolean;
}
@UntilDestroy()
@Component({
  template: '',
})
export class DragDropController {
  @ViewChild('position1') position1: ElementRef<HTMLDivElement>;
  @ViewChild('position2') position2: ElementRef<HTMLDivElement>;
  @ViewChild('position3') position3: ElementRef<HTMLDivElement>;
  @ViewChild('position4') position4: ElementRef<HTMLDivElement>;

  layouts = SpaceToolLayout;

  dropPositions = ['position1', 'position2', 'position3', 'position4'];

  defaultPosition: { [key: string]: any } = {
    position1: 'title',
    position2: 'content',
    position3: 'description',
    position4: '',
  };

  isDragging = '';
  isDragingOver = '';
  isDraggable = false;

  sectionDraggable = {
    content: false,
    description: false,
    title: false,
  };

  constructor(public _ngZone: NgZone) {}

  handleEditorRightDrag(container: Element, position) {
    switch (position) {
      case 'position1':
        container.querySelector('.position-4')?.classList.add('dropable');
        break;
      case 'position2':
        container.querySelector('.position-3')?.classList.add('dropable');
        break;
      case 'position3':
        container.querySelector('.position-2')?.classList.add('dropable');
        break;
      case 'position4':
        container.querySelector('.position-1')?.classList.add('dropable');
        break;
    }
  }

  triggerDropableArea(event: DragEvent, layout, position) {
    const draggedPosition = <HTMLElement>(<HTMLElement>event.target)?.parentNode;

    const container = draggedPosition.closest('.drag-drop-container');
    if (container) {
      switch (layout) {
        case SpaceToolLayout.EditorRight:
          this.handleEditorRightDrag(container, position);
          break;
        default:
          const draggableAreas = container.querySelectorAll('[class^="position"]');
          if (draggableAreas.length) {
            draggableAreas.forEach((el) => {
              if (el !== draggedPosition && !el.classList.contains('dropable')) {
                el.classList.add('dropable');
              }
            });
          }
          break;
      }
    }
  }

  clearDropAreas(event: DragEvent) {
    const elment = <HTMLElement>(<HTMLElement>event.target);

    const container = elment.closest('.drag-drop-container');
    if (container) {
      const draggableAreas = container.querySelectorAll('[class^="position"]');
      if (draggableAreas.length) {
        draggableAreas.forEach((el) => {
          if (el.classList.contains('dropable')) {
            el.classList.remove('dropable');
          }
          el.querySelector('.drop-section')?.classList.remove('hover');
        });
      }
    }
  }

  onDrag(event, layout, position) {
    event.dataTransfer.setData('fromPosition', position);
    event.dataTransfer.setData('layout', layout);
    if (!event.target.classList.contains('dragging')) {
      event.target.classList.add('dragging');
    }
    this.isDragging = position;

    this._ngZone.runOutsideAngular(() => {
      interval(0)
        .pipe(take(1), untilDestroyed(this))
        .subscribe(() => {
          this.triggerDropableArea(event, layout, position);
          this._ngZone.run(() => {});
        });
    });
  }

  allowDrop(event) {
    event.preventDefault();
  }

  onDrop(event, toPosition, entity) {
    event.preventDefault();
    const fromPosition = event.dataTransfer.getData('fromPosition');
    if (fromPosition !== toPosition) {
      const temp = entity.positions[toPosition];
      entity.positions[toPosition] = entity.positions[fromPosition];
      entity.positions[fromPosition] = temp;
    }
    this.isDragging = '';

    this._ngZone.runOutsideAngular(() => {
      interval(0)
        .pipe(take(1), untilDestroyed(this))
        .subscribe(() => {
          this.clearDropAreas(event);
          this._ngZone.run(() => {});
        });
    });
  }

  onDragEnter(event, slot) {
    if (this.isDragging !== slot) {
      this.isDragingOver = slot;
      if (!event.target.classList.contains('hover')) {
        event.target.classList.add('hover');
      }
    }
  }

  onDragLeave(event) {
    event.target.classList.remove('hover');
    this.isDragingOver = '';
  }

  onDragend(event) {
    if (event.target.classList.contains('dragging')) {
      event.target.classList.remove('dragging');
    }
    this.isDragging = '';

    this._ngZone.runOutsideAngular(() => {
      interval(0)
        .pipe(take(1), untilDestroyed(this))
        .subscribe(() => {
          this.clearDropAreas(event);
          this._ngZone.run(() => {});
        });
    });
  }
}
