<input type="checkbox" [id]="id" [disabled]="disabled" [ngModel]="isChecked" (ngModelChange)="checkValue($event)" />

<label [for]="id" [class.disabled]="disabled">
  <div class="box">
    <i class="icon icon-yes"></i>
  </div>
  <div #content [ngClass]="{'content': !!content.hasChildNodes()}">
    <ng-content></ng-content>
  </div>
</label>
