import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, interval, take, takeUntil } from 'rxjs';
import { ModalService } from '../../modal.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'ui-controls-info',
  templateUrl: './controls-info.component.html',
  styleUrls: ['./controls-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlsInfoComponent implements AfterViewInit {
  displayButton = false;

  private _destroy$ = new Subject();

  constructor(
    private _modalService: ModalService,
    private _cd: ChangeDetectorRef,
    private _router: Router,
    private _ngZone: NgZone,
  ) {}

  ngAfterViewInit(): void {
    this._ngZone.runOutsideAngular(() => {
      interval(1000)
        .pipe(take(1), untilDestroyed(this))
        .subscribe(() => {
          this.displayButton = true;
          this._cd.markForCheck();
          this._ngZone.run(() => {});
        });
    });

    this._router.events.pipe(takeUntil(this._destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.close();
      }
    });
  }

  close(): void {
    this._modalService.close(false, this['modalContainerRef']);
  }
}
