import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PanelComponent } from '@/ui/src/lib/components/panel/panel.component';
import { ApplicationService } from '@/view/src/app/app.service';
import { TCustomizationMaterial } from '@/data/src/lib/view-manager';
import { deepCopy } from '@/data/src/lib/utils/data';

@UntilDestroy()
@Component({
  selector: 'ui-materials-panel',
  templateUrl: './materials-panel.component.html',
  styleUrls: ['./materials-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialsPanelComponent extends PanelComponent {
  _material?: TCustomizationMaterial;
  materials: TCustomizationMaterial[] = [];
  materialOptions: { label: string; value: string }[] = [];
  outlinesEnabled = true;

  @Input() set material(input: TCustomizationMaterial | undefined) {
    this._material = deepCopy(input);
    this._cd.detectChanges();
  }

  get material(): TCustomizationMaterial | undefined {
    return this._material;
  }

  constructor(
    protected _appService: ApplicationService,
    private _cd: ChangeDetectorRef,
  ) {
    super(_appService);
  }

  ngOnInit(): void {
    this._appService.setOutlines(true);
    this._appService.customizationMaterialsSubject.subscribe((materials) => {
      this._appService.setOutlines(this.outlinesEnabled);
      this.materials = materials;
      this.materialOptions.length = 0;
      this.materials.forEach((material) => {
        this.materialOptions.push({
          label: material.name || 'Unnamed material',
          value: material.materialId,
        });
      });
      this.material = this.materials.find(({ materialId }) => materialId === this.material?.materialId);
      this._cd.detectChanges();
    });
  }

  toggleShowSelection(flag: boolean) {
    this.outlinesEnabled = flag;
    this._appService.setOutlines(flag);
  }

  onMaterialSelect(materialId: any) {
    const material = this.materials.find(({ materialId: id }) => id === materialId);
    this._appService.setSelectedMaterials(material ? [material] : []);
  }

  ngOnDestroy(): void {
    this._appService.setSelectedMaterials([]);
    super.ngOnDestroy();
  }
}
