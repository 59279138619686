<div class="popup-content-modal popup-gallery">
  <section class="modal-body">
    <div class="scroll-container" #container>
      <div class="carousel">
        @for (image of displayedImages; track image; let i = $index) {
          <div class="carousel-item readonly" [style.backgroundColor]="image.color.background">
            <div
              class="layout-container drag-drop-container"
              [ngClass]="{
                'horizontal-layout': image.layout === layouts.EditorBelow,
                'vertical-layout': image.layout === layouts.EditorRight,
                'horizontal-layout-no-edit': image.layout === layouts.NoEditor
              }"
            >
              <div class="position-1" #position1>
                <ng-container
                  [ngTemplateOutlet]="
                    image.positions['position1'] === 'title'
                      ? titleTemplate
                      : image.positions['position1'] === 'content'
                        ? content
                        : image.positions['position1'] === 'description'
                          ? description
                          : null
                  "
                  [ngTemplateOutletContext]="{ $implicit: image, slot: 'position1', index: i }"
                ></ng-container>
              </div>
              @if (image.layout === layouts.EditorRight) {
                <div class="editor-right-container">
                  <ng-container [ngTemplateOutlet]="midContent"></ng-container>
                </div>
              }
              @if (image.layout !== layouts.EditorRight) {
                <ng-container [ngTemplateOutlet]="midContent"></ng-container>
              }
              <ng-template #midContent>
                <div class="position-2" #position2>
                  <ng-container
                    [ngTemplateOutlet]="
                      image.positions['position2'] === 'title'
                        ? titleTemplate
                        : image.positions['position2'] === 'content'
                          ? content
                          : image.positions['position2'] === 'description'
                            ? description
                            : null
                    "
                    [ngTemplateOutletContext]="{ $implicit: image, slot: 'position2', index: i }"
                  ></ng-container>
                </div>
                <div class="position-3" #position3>
                  <ng-container
                    [ngTemplateOutlet]="
                      image.positions['position3'] === 'title'
                        ? titleTemplate
                        : image.positions['position3'] === 'content'
                          ? content
                          : image.positions['position3'] === 'description'
                            ? description
                            : null
                    "
                    [ngTemplateOutletContext]="{ $implicit: image, slot: 'position3', index: i }"
                  ></ng-container>
                </div>
              </ng-template>
              <div class="position-4" #position4>
                <ng-container
                  [ngTemplateOutlet]="
                    image.positions['position4'] === 'title'
                      ? titleTemplate
                      : image.positions['position4'] === 'content'
                        ? content
                        : image.positions['position4'] === 'description'
                          ? description
                          : null
                  "
                  [ngTemplateOutletContext]="{ $implicit: image, slot: 'position4', index: i }"
                ></ng-container>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </section>
  <div class="close icon-wrapper" (click)="close()">
    <div class="icon icon-close sm"></div>
  </div>
</div>
<div class="pagination">
  @for (page of displayedImages; track page; let i = $index) {
    <div class="page" [class.active]="currentPage === i + 1"></div>
  }
</div>
<ng-template #titleTemplate let-slot="slot" let-image>
  @if (!!title) {
    <div class="title-wrapper drag-item" [style.color]="image.color.text || 'transparent'">
      <div class="text" uiOverflowReadmore>
        {{ title }}
      </div>
    </div>
  }
</ng-template>
<ng-template #content let-image let-slot="slot" let-imageIndex="index">
  <div class="content drag-item">
    <div
      class="content-view-wrapper"
      (click)="goToUrl(image.hyperlink)"
      [draggable]="false"
      (dragstart)="preventDefault($event)"
      (dragend)="preventDefault($event)"
      (cut)="preventDefault($event)"
      (copy)="preventDefault($event)"
      (paste)="preventDefault($event)"
      (print)="preventDefault($event)"
    >
      <!-- <div class="blocker-overlay"></div> -->
      @if (image.imageUrl) {
        <img class="image" [alt]="image.altText" [src]="image.imageUrl" draggable="false" uiImageOrientation />
      }
      <div class="bg-image" [style.background-image]="'url(' + image.imageUrl + ')'"></div>
    </div>
  </div>
</ng-template>
<ng-template #description let-image let-slot="slot">
  @if (image.layout !== layouts.NoEditor && !!image.description) {
    <div class="editor-container drag-item description">
      <quill-view class="w-100 view-mode" [content]="image.description" [preserveWhitespace]="true"> </quill-view>
    </div>
  }
</ng-template>
