import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Inject, Injectable, Injector } from '@angular/core';
import { ModalOptions } from '../../modal/modal.service';
import { QuillEditorMediaComponent } from './quill-editor-media.component';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class QuillEditorMediaService {
  private modalContainerRef!: ComponentRef<QuillEditorMediaComponent> | undefined;
  private result: any;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {}

  public get isModalOpen() {
    return this._document.querySelector('body > ui-quill-editor-media > div.quill-editor-container');
  }

  open<T>(options?: ModalOptions) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(QuillEditorMediaComponent);
    const componentRef = componentFactory.create(this.injector);

    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    const app = this._document.body;
    app.appendChild(domElem);

    this.modalContainerRef = componentRef;

    const openModal = {
      instance: componentRef.instance,
      result: new Promise((resolve) => {
        if (this.modalContainerRef) {
          this.modalContainerRef.onDestroy(() => {
            resolve(this.result);
          });
        }
      }),
    };
    componentRef.instance.zIndex = options?.zIndex ?? 5000;
    componentRef.instance.allowHeaderClick = !!options?.allowHeaderClick;
    if (options?.hasBackdrop !== undefined) {
      componentRef.instance.hasBackdrop = options.hasBackdrop;
    }
    if (options?.closeOnBackdropClick !== undefined) {
      componentRef.instance.closeOnBackdropClick = options.closeOnBackdropClick;
    }
    return openModal;
  }

  close(result: any = '') {
    this.result = result;
    if (this.modalContainerRef) {
      this.appRef.detachView(this.modalContainerRef.hostView);
      this.modalContainerRef.destroy();
      this.modalContainerRef = undefined;
    }
  }
}
