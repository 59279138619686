export enum Action {
  Added,
  Modified,
  Removed,
  Drawn,
  Copied,
  Imported,
  Identity,
  Multiple,
}

export enum TransactionType {
  Mesh = 'Mesh',
  Media = 'Media',
  Model = 'Model',
  Group = 'Group',
  Elements = 'Elements',
}
