import { NgModule } from '@angular/core';
import { DataComponent } from './data.component';
import { FilterTagsPipe } from './pipes/filter-tags.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { RemoveCommaPipe } from './pipes/remove-comma.pipe';
import { OrderPipe } from './pipes/order.pipe';
import { FilterAmountPipe } from './pipes/filter-amount.pipe';
import { RemoveHashPipe } from './pipes/remove-hash.pipe';
import { LogPipe } from './pipes/log.pipe';
import { DateAsAgoPipe } from './pipes/dateAsAgo.pipe';
import { LookupPipe } from './pipes/lookup-pipe';

@NgModule({
  declarations: [
    DataComponent,
    FilterPipe,
    OrderPipe,
    FilterTagsPipe,
    RemoveCommaPipe,
    RemoveHashPipe,
    LogPipe,
    FilterAmountPipe,
    DateAsAgoPipe,
    LookupPipe,
  ],
  imports: [],
  exports: [
    DataComponent,
    FilterPipe,
    OrderPipe,
    FilterTagsPipe,
    RemoveCommaPipe,
    RemoveHashPipe,
    LogPipe,
    FilterAmountPipe,
    DateAsAgoPipe,
    LookupPipe,
  ],
})
export class DataModule {}
