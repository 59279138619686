<main>
  <section class="card participating-collaborators">
    <div class="title">
      <span class="left">{{ 'oxr.settings.collaborator.participating' | translate }}</span>
      <span class="right">
        <div class="icon icon-user"></div>
        {{ activeCollaborators.length }}
      </span>
    </div>
    <div class="content">
      @if (activeCollaborators.length) {
        <div class="content" [ngClass]="{ has: activeCollaborators.length }">
          @for (activeCollaborator of activeCollaborators; track activeCollaborator) {
            <div
              class="collaborator-profile"
              (mouseenter)="selectedActiveCollaboratoreId = activeCollaborator.Id"
              (mouseleave)="selectedActiveCollaboratoreId = ''"
            >
              @if (activeCollaborator.Thumbnail) {
                <img class="thumbnail" [src]="activeCollaborator.Thumbnail" alt="" />
              }
              @if (!activeCollaborator.Thumbnail) {
                <div class="user-thumbnail empty">
                  <div class="icon icon-account primary md"></div>
                </div>
              }
              @if (selectedActiveCollaboratoreId === activeCollaborator.Id) {
                <div class="thumbnail hover-dark"></div>
                @if (!loading) {
                  <div class="icon icon-delete-minus" (click)="removeCollaborator(activeCollaborator)"></div>
                }
                <span class="img-hover-text">{{ activeCollaborator.Alias }}</span>
              }
            </div>
          }
        </div>
      }
      @if (!activeCollaborators.length) {
        <span class="comment" [innerHTML]="'oxr.settings.collaborator.addDetail' | translate"> </span>
      }
    </div>
  </section>
  <section class="card add-collaborators">
    <div class="title">
      <span class="left">{{ 'oxr.settings.collaborator.add' | translate }}</span>
      <span class="right">
        <div class="icon icon-user"></div>
        {{ inActiveCollaborators.length }}
      </span>
    </div>
    <ui-search class="sm" [canClear]="true" [(search)]="searchText"></ui-search>
    <div class="user-list">
      @if (inActiveCollaborators) {
        @for (
          inActiveCollaborator of inActiveCollaborators | filterBy: ['Alias', 'DisplayName'] : searchText : false;
          track inActiveCollaborator
        ) {
          <div
            class="user"
            (mouseenter)="selectedInActiveCollaboratoreId = inActiveCollaborator.Id"
            (mouseleave)="selectedInActiveCollaboratoreId = ''"
          >
            @if (inActiveCollaborator.Thumbnail) {
              <img class="user-thumbnail" [src]="inActiveCollaborator.Thumbnail" alt="" />
            }
            @if (!inActiveCollaborator.Thumbnail) {
              <div class="user-thumbnail empty">
                <div class="icon icon-account primary sm"></div>
              </div>
            }
            <div class="user-info">
              <span class="name">{{ inActiveCollaborator.Alias }}</span>
              <span class="email">{{ inActiveCollaborator.DisplayName }} </span>
            </div>
            @if (selectedInActiveCollaboratoreId === inActiveCollaborator.Id) {
              @if (!loading) {
                <div class="icon icon-add-plus-blue-bg" (click)="addCollaborator(inActiveCollaborator)"></div>
              }
            }
          </div>
        }
      }
    </div>
  </section>
</main>
<section class="footer">
  <button ui-button fixed (click)="onSave()" [disabled]="loading">
    @if (!loading) {
      <span>{{ 'shared.actions.save' | translate }}</span>
    }
    @if (loading) {
      <ui-loader></ui-loader>
    }
  </button>
</section>
