<section class="media-upload image-container editing" uiFileDragDrop (fileDropped)="onFileDrop($event)">
  <input
    #fileInput
    id="media-upload-input"
    type="file"
    [accept]="fileConditions[uploadType].accept"
    (change)="onFileInputChange($event)"
    multiple
  />
  <label for="media-upload-input">
    <div class="icon-file-upload"></div>
    <div class="info">
      <span class="text-drop">{{ fileConditions[uploadType].info.textDrop | translate }}</span>
      <span class="text-requirements">{{ fileConditions[uploadType].info.textRequirements1 | translate }}</span>
      <span class="text-requirements">{{ fileConditions[uploadType].info.textRequirements2 | translate }}</span>
    </div>
  </label>
  @if (false) {
    <div class="loader">
      <ui-loader></ui-loader>
    </div>
  }
</section>
<section class="media-library">
  <div class="library-toolbar">
    <div class="total-items" [innerHTML]="'shared.list.inTotal' | translate: { total: numberOfItems }"></div>
    <div class="library-search">
      <ui-search sm [canClear]="true" [(search)]="searchText"></ui-search>
      <ui-search-filter>
        <ng-select
          class="small"
          [searchable]="false"
          [clearable]="false"
          placeholder="{{ 'shared.list.orderBy.placeholder' | translate }}"
          [ngModel]="orderBy"
          (ngModelChange)="changeOrderBy($event)"
        >
          @for (option of orderingOptions; track option) {
            <ng-option [value]="option">
              <div class="select-option-wrapper">
                {{ 'shared.list.orderBy.' + option.name | translate }}
              </div>
            </ng-option>
          }
        </ng-select>
      </ui-search-filter>
    </div>
  </div>
  <div class="library-container" #libraryContainer>
    @if (!!observer) {
      @for (
        item of media | filter: (searchText | removeComma | removeHash) : filterByFields | orderBy: orderBy.value;
        track item;
        let i = $index
      ) {
        <div class="media-item" (click)="mediaSelect(item)">
          @if (item.LoadingProgress) {
            <div class="progress">
              <div class="loading-bar" [style.width.%]="item.LoadingProgress"></div>
            </div>
          }
          @if (!!item.Name) {
            <span class="media-tooltip ng-tooltip ng-tooltip-show">{{ item.Name }}</span>
          }
          <img [attr.data-src]="item.Thumbnail || SOUND_ONLY_PNG" uiOnIntersectionObserver [observer]="observer" />
        </div>
      }
    }
  </div>
</section>
@if (isUploading) {
  <ui-loader></ui-loader>
}
