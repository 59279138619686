@if (allocatedMedia) {
  @if (allocatedStorage; as storage) {
    <section>
      <div class="info">
        <div>{{ 'shared.fields.storageSpace' | translate }}</div>
        <div>{{ storage.TotalUsedSpace + ' / ' + (storage.TotalSpace ?? 'oxr.enterpriseSpace.unlimited' | translate) }}</div>
      </div>
      <div class="progress">
        <div class="images" role="progressbar" [style.width]="storage.ImagePercentage + '%'" [ngStyle]="{ 'z-index': 1 }"></div>
        <div
          class="videos"
          role="progressbar"
          [style.width]="storage.VideoPercentage + storage.ImagePercentage + '%'"
          [ngStyle]="{ 'z-index': 0 }"
        ></div>
      </div>
      <div class="labels">
        <div class="image-icon"></div>
        <div>{{ 'shared.fields.images' | translate }}</div>
        <div class="video-icon"></div>
        <div>{{ 'shared.fields.videos' | translate }}</div>
      </div>
      <div class="button-container">
        @if (!checkedMedia.length) {
          <button ui-outlined-button xs [disabled]="true">
            {{ 'shared.actions.deleteMediaSelection' | translate }}
          </button>
        }
        @if (!!checkedMedia.length) {
          @if (isDeletingMedia) {
            <ui-loader></ui-loader>
          } @else {
            <button ui-outlined-button xs (click)="onDelete()">
              {{ 'shared.actions.deleteMedia' | translate: { num: checkedMedia.length } }}
            </button>
          }
        }
      </div>
    </section>
  }
} @else {
  <section>
    <div class="info">
      <div>{{ 'shared.fields.storageSpace' | translate }}</div>
      <div>{{ 'shared.information.unlimited' | translate }}</div>
    </div>
    <div class="progress unlimited"></div>
    <div class="labels">
      <div class="image-icon"></div>
      <div>{{ 'shared.fields.images' | translate }}</div>
      <div class="video-icon"></div>
      <div>{{ 'shared.fields.videos' | translate }}</div>
    </div>
    <div class="button-container">
      @if (!checkedMedia.length) {
        <button ui-outlined-button xs [disabled]="true">
          {{ 'shared.actions.deleteMediaSelection' | translate }}
        </button>
      }
      @if (!!checkedMedia.length) {
        @if (isDeletingMedia) {
          <ui-loader></ui-loader>
        } @else {
          <button ui-outlined-button xs (click)="onDelete()">
            {{ 'shared.actions.deleteMedia' | translate: { num: checkedMedia.length } }}
          </button>
        }
      }
    </div>
  </section>
}
<ng-template #unlimited>
  <section>
    <div class="info">
      <div>{{ 'shared.fields.storageSpace' | translate }}</div>
      <div>{{ 'shared.information.unlimited' | translate }}</div>
    </div>
    <div class="progress unlimited"></div>
    <div class="labels">
      <div class="image-icon"></div>
      <div>{{ 'shared.fields.images' | translate }}</div>
      <div class="video-icon"></div>
      <div>{{ 'shared.fields.videos' | translate }}</div>
    </div>
    <div class="button-container">
      @if (!checkedMedia.length) {
        <button ui-outlined-button xs [disabled]="true">
          {{ 'shared.actions.deleteMediaSelection' | translate }}
        </button>
      }
      @if (!!checkedMedia.length) {
        @if (isDeletingMedia) {
          <ui-loader></ui-loader>
        } @else {
          <button ui-outlined-button xs (click)="onDelete()">
            {{ 'shared.actions.deleteMedia' | translate: { num: checkedMedia.length } }}
          </button>
        }
      }
    </div>
  </section>
</ng-template>
