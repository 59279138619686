@if (scene) {
  <div (uiClickOutside)="closePanel()">
    <div class="container" [formGroup]="form">
      <section class="title">
        {{ 'oxr.settings.title' | translate }}
        <div class="icon-wrapper" (click)="closePanel()">
          <div class="icon sm icon-close"></div>
        </div>
      </section>
      <section
        class="tier card"
        [ngClass]="{
          enterprise: scene.Plan === scenePlan.Enterprise,
          free: scene.Plan === scenePlan.Free,
          pro: scene.Plan === scenePlan.Pro
        }"
      >
        <div class="tier-info">
          <div class="tier-icon"></div>
          <div class="tier-name">{{ scene.Plan }}</div>
        </div>
        <div class="right">
          @if (canDeleteScene) {
            <button ui-outlined-button xxs error (click)="onDelete()">
              {{ 'shared.actions.delete' | translate }}
            </button>
          }
          @if (!canDeleteScene) {
            <button ui-outlined-button xxs error (click)="onReset()">
              {{ 'shared.actions.resetSpace' | translate }}
            </button>
          }
        </div>
      </section>
      <section class="main-info card">
        <div class="storage">
          <div class="icon icon-storage"></div>
          <div class="info">
            <div>
              {{
                'oxr.mbInUse'
                  | translate
                    : {
                        total: sceneStorageUsageInfo.TotalSpace ?? ('oxr.enterpriseSpace.unlimited' | translate),
                        inUse: sceneStorageUsageInfo.TotalUsedSpace
                      }
              }}
            </div>
            <div class="storage-bar">
              <div class="storage-used" [style.width.%]="sceneStorageUsageInfo.TotalUsedSpacePercentage"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="icon icon-link">i</div>
          <div class="text">{{ baseUrl }}/view/{{ scene.Id }}</div>
        </div>
      </section>
      <section class="actions-menu">
        <div class="action-item" (click)="openDetails('information')">
          <div class="left">
            {{ 'oxr.settings.spaceInformation' | translate }}
          </div>
          <div class="right">
            <div class="icon icon-arrow-right"></div>
          </div>
        </div>
        @if (scene.IsPublished) {
          <div class="action-item" (click)="openDetails('description')">
            <div class="left">
              {{ 'oxr.settings.spaceOption' | translate }}
            </div>
            <div class="right">
              <div class="icon icon-arrow-right"></div>
            </div>
          </div>
        }

        @if (isEnterpriseOwner) {
          <div class="action-item" (click)="openDetails('collaborator')">
            <div class="left">
              {{ 'oxr.settings.collaborator.management' | translate }}
            </div>
            <div class="right">
              <div class="icon icon-arrow-right"></div>
            </div>
          </div>
        }
      </section>
    </div>
    <oxr-settings-details-wrapper [class.opened]="isDetailsPanelOpened$ | async">
      <div header>
        @if ((openedDetails$ | async) === 'information') {
          <span>{{ 'oxr.settings.spaceInformation' | translate | uppercase }}</span>
        }
        @if ((openedDetails$ | async) === 'description') {
          <span>{{ 'oxr.settings.spaceOption' | translate | uppercase }}</span>
        }
        @if ((openedDetails$ | async) === 'collaborator') {
          <span>{{ 'oxr.settings.collaborator.management' | translate | uppercase }}</span>
        }
      </div>
      @if ((openedDetails$ | async) === 'information') {
        <oxr-space-information [scene]="scene"></oxr-space-information>
      }
      @if ((openedDetails$ | async) === 'description') {
        <oxr-space-description [scene]="scene"></oxr-space-description>
      }
      @if ((openedDetails$ | async) === 'collaborator') {
        <oxr-collaborator-management [scene]="scene"></oxr-collaborator-management>
      }
    </oxr-settings-details-wrapper>
  </div>
}
