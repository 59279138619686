import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'order',
})
export class OrderPipe implements PipeTransform {
  transform(items: any[], term: any | undefined, reverse = false): any {
    if (term !== undefined && term !== null) {
      return reverse
        ? items.slice(0).sort((a, b) => (a[term] < b[term] ? -1 : 1))
        : items.slice(0).sort((a, b) => (a[term] < b[term] ? 1 : -1));
    } else {
      return items;
    }
  }

  transformInner(items: any[], property: any | undefined, term: any | undefined, reverse = false): any {
    if (term !== undefined && term !== null) {
      return reverse
        ? items.slice(0).sort((a, b) => (a[property][term] < b[property][term] ? -1 : 1))
        : items.slice(0).sort((a, b) => (a[property][term] < b[property][term] ? 1 : -1));
    } else {
      return items;
    }
  }
}
