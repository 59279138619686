<ui-panel [title]="'oxr.elements.transformation' | translate" [collapsing]="true" [panelId]="panelMask.Transformation">
  <ng-container *ngFor="let field of fields; trackBy: trackField">
    <p>{{ 'oxr.creatingSpace.panels.transformation.' + field | translate }}</p>
    <div class="setting-box">
      <ng-container *ngFor="let dimension of getDimensions(field); trackBy: trackDimension.bind(this, field)">
        <div>
          <label [for]="field + '-' + dimension">{{ dimension | uppercase }}</label>
          <input
            uiInput
            [id]="field + '-' + dimension"
            type="text"
            [ngModel]="getField(field, dimension)"
            (input)="onFieldChange($event, field, dimension)"
            (keydown)="onKeyDown($event, field, dimension)"
            (focusout)="onChangeEnd()"
          />
        </div>
      </ng-container>
      <ui-switch
        *ngIf="field === 'scaling'"
        class="icon-style-switch"
        [checked]="lockScaling"
        [label]="'oxr.creatingSpace.panels.transformation.lockScaling' | translate"
        [onShowContentMode]="false"
        (changed)="onScalingLockToggle($event)"
      ></ui-switch>
    </div>
  </ng-container>
  <div class="info">
    <div class="icon icon-box"></div>
    <p>{{ bounding }}</p>
  </div>
</ui-panel>
