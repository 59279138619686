import { ThinEngine } from "../../Engines/thinEngine.js";
import { InternalTexture, InternalTextureSource } from "../../Materials/Textures/internalTexture.js";
import { Logger } from "../../Misc/logger.js";
import { LoadImage } from "../../Misc/fileTools.js";
import { RandomGUID } from "../../Misc/guid.js";
ThinEngine.prototype._createDepthStencilCubeTexture = function (size, options, rtWrapper) {
  const internalTexture = new InternalTexture(this, InternalTextureSource.DepthStencil);
  internalTexture.isCube = true;
  if (this.webGLVersion === 1) {
    Logger.Error("Depth cube texture is not supported by WebGL 1.");
    return internalTexture;
  }
  const internalOptions = Object.assign({
    bilinearFiltering: false,
    comparisonFunction: 0,
    generateStencil: false
  }, options);
  const gl = this._gl;
  this._bindTextureDirectly(gl.TEXTURE_CUBE_MAP, internalTexture, true);
  this._setupDepthStencilTexture(internalTexture, size, internalOptions.generateStencil, internalOptions.bilinearFiltering, internalOptions.comparisonFunction);
  rtWrapper._depthStencilTexture = internalTexture;
  rtWrapper._depthStencilTextureWithStencil = internalOptions.generateStencil;
  // Create the depth/stencil buffer
  for (let face = 0; face < 6; face++) {
    if (internalOptions.generateStencil) {
      gl.texImage2D(gl.TEXTURE_CUBE_MAP_POSITIVE_X + face, 0, gl.DEPTH24_STENCIL8, size, size, 0, gl.DEPTH_STENCIL, gl.UNSIGNED_INT_24_8, null);
    } else {
      gl.texImage2D(gl.TEXTURE_CUBE_MAP_POSITIVE_X + face, 0, gl.DEPTH_COMPONENT24, size, size, 0, gl.DEPTH_COMPONENT, gl.UNSIGNED_INT, null);
    }
  }
  this._bindTextureDirectly(gl.TEXTURE_CUBE_MAP, null);
  this._internalTexturesCache.push(internalTexture);
  return internalTexture;
};
ThinEngine.prototype._partialLoadFile = function (url, index, loadedFiles, onfinish, onErrorCallBack = null) {
  const onload = data => {
    loadedFiles[index] = data;
    loadedFiles._internalCount++;
    if (loadedFiles._internalCount === 6) {
      onfinish(loadedFiles);
    }
  };
  const onerror = (request, exception) => {
    if (onErrorCallBack && request) {
      onErrorCallBack(request.status + " " + request.statusText, exception);
    }
  };
  this._loadFile(url, onload, undefined, undefined, true, onerror);
};
ThinEngine.prototype._cascadeLoadFiles = function (scene, onfinish, files, onError = null) {
  const loadedFiles = [];
  loadedFiles._internalCount = 0;
  for (let index = 0; index < 6; index++) {
    this._partialLoadFile(files[index], index, loadedFiles, onfinish, onError);
  }
};
ThinEngine.prototype._cascadeLoadImgs = function (scene, texture, onfinish, files, onError = null, mimeType) {
  const loadedImages = [];
  loadedImages._internalCount = 0;
  for (let index = 0; index < 6; index++) {
    this._partialLoadImg(files[index], index, loadedImages, scene, texture, onfinish, onError, mimeType);
  }
};
ThinEngine.prototype._partialLoadImg = function (url, index, loadedImages, scene, texture, onfinish, onErrorCallBack = null, mimeType) {
  const tokenPendingData = RandomGUID();
  const onload = img => {
    loadedImages[index] = img;
    loadedImages._internalCount++;
    if (scene) {
      scene.removePendingData(tokenPendingData);
    }
    if (loadedImages._internalCount === 6 && onfinish) {
      onfinish(texture, loadedImages);
    }
  };
  const onerror = (message, exception) => {
    if (scene) {
      scene.removePendingData(tokenPendingData);
    }
    if (onErrorCallBack) {
      onErrorCallBack(message, exception);
    }
  };
  LoadImage(url, onload, onerror, scene ? scene.offlineProvider : null, mimeType);
  if (scene) {
    scene.addPendingData(tokenPendingData);
  }
};
ThinEngine.prototype._setCubeMapTextureParams = function (texture, loadMipmap, maxLevel) {
  const gl = this._gl;
  gl.texParameteri(gl.TEXTURE_CUBE_MAP, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
  gl.texParameteri(gl.TEXTURE_CUBE_MAP, gl.TEXTURE_MIN_FILTER, loadMipmap ? gl.LINEAR_MIPMAP_LINEAR : gl.LINEAR);
  gl.texParameteri(gl.TEXTURE_CUBE_MAP, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_CUBE_MAP, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  texture.samplingMode = loadMipmap ? 3 : 2;
  if (loadMipmap && this.getCaps().textureMaxLevel && maxLevel !== undefined && maxLevel > 0) {
    gl.texParameteri(gl.TEXTURE_CUBE_MAP, gl.TEXTURE_MAX_LEVEL, maxLevel);
    texture._maxLodLevel = maxLevel;
  }
  this._bindTextureDirectly(gl.TEXTURE_CUBE_MAP, null);
};
ThinEngine.prototype.createCubeTextureBase = function (rootUrl, scene, files, noMipmap, onLoad = null, onError = null, format, forcedExtension = null, createPolynomials = false, lodScale = 0, lodOffset = 0, fallback = null, beforeLoadCubeDataCallback = null, imageHandler = null, useSRGBBuffer = false) {
  const texture = fallback ? fallback : new InternalTexture(this, InternalTextureSource.Cube);
  texture.isCube = true;
  texture.url = rootUrl;
  texture.generateMipMaps = !noMipmap;
  texture._lodGenerationScale = lodScale;
  texture._lodGenerationOffset = lodOffset;
  texture._useSRGBBuffer = !!useSRGBBuffer && this._caps.supportSRGBBuffers && (this.webGLVersion > 1 || this.isWebGPU || !!noMipmap);
  if (texture !== fallback) {
    texture.label = rootUrl.substring(0, 60); // default label, can be overriden by the caller
  }
  if (!this._doNotHandleContextLost) {
    texture._extension = forcedExtension;
    texture._files = files;
  }
  const originalRootUrl = rootUrl;
  if (this._transformTextureUrl && !fallback) {
    rootUrl = this._transformTextureUrl(rootUrl);
  }
  const rootUrlWithoutUriParams = rootUrl.split("?")[0];
  const lastDot = rootUrlWithoutUriParams.lastIndexOf(".");
  const extension = forcedExtension ? forcedExtension : lastDot > -1 ? rootUrlWithoutUriParams.substring(lastDot).toLowerCase() : "";
  let loader = null;
  for (const availableLoader of ThinEngine._TextureLoaders) {
    if (availableLoader.canLoad(extension)) {
      loader = availableLoader;
      break;
    }
  }
  const onInternalError = (request, exception) => {
    if (rootUrl === originalRootUrl) {
      if (onError && request) {
        onError(request.status + " " + request.statusText, exception);
      }
    } else {
      // fall back to the original url if the transformed url fails to load
      Logger.Warn(`Failed to load ${rootUrl}, falling back to the ${originalRootUrl}`);
      this.createCubeTextureBase(originalRootUrl, scene, files, !!noMipmap, onLoad, onError, format, forcedExtension, createPolynomials, lodScale, lodOffset, texture, beforeLoadCubeDataCallback, imageHandler, useSRGBBuffer);
    }
  };
  if (loader) {
    const onloaddata = data => {
      if (beforeLoadCubeDataCallback) {
        beforeLoadCubeDataCallback(texture, data);
      }
      loader.loadCubeData(data, texture, createPolynomials, onLoad, onError);
    };
    if (files && files.length === 6) {
      if (loader.supportCascades) {
        this._cascadeLoadFiles(scene, images => onloaddata(images.map(image => new Uint8Array(image))), files, onError);
      } else {
        if (onError) {
          onError("Textures type does not support cascades.");
        } else {
          Logger.Warn("Texture loader does not support cascades.");
        }
      }
    } else {
      this._loadFile(rootUrl, data => onloaddata(new Uint8Array(data)), undefined, undefined, true, onInternalError);
    }
  } else {
    if (!files) {
      throw new Error("Cannot load cubemap because files were not defined");
    }
    this._cascadeLoadImgs(scene, texture, (texture, imgs) => {
      if (imageHandler) {
        imageHandler(texture, imgs);
      }
    }, files, onError);
  }
  this._internalTexturesCache.push(texture);
  return texture;
};
ThinEngine.prototype.createCubeTexture = function (rootUrl, scene, files, noMipmap, onLoad = null, onError = null, format, forcedExtension = null, createPolynomials = false, lodScale = 0, lodOffset = 0, fallback = null, loaderOptions, useSRGBBuffer = false) {
  const gl = this._gl;
  return this.createCubeTextureBase(rootUrl, scene, files, !!noMipmap, onLoad, onError, format, forcedExtension, createPolynomials, lodScale, lodOffset, fallback, texture => this._bindTextureDirectly(gl.TEXTURE_CUBE_MAP, texture, true), (texture, imgs) => {
    const width = this.needPOTTextures ? ThinEngine.GetExponentOfTwo(imgs[0].width, this._caps.maxCubemapTextureSize) : imgs[0].width;
    const height = width;
    const faces = [gl.TEXTURE_CUBE_MAP_POSITIVE_X, gl.TEXTURE_CUBE_MAP_POSITIVE_Y, gl.TEXTURE_CUBE_MAP_POSITIVE_Z, gl.TEXTURE_CUBE_MAP_NEGATIVE_X, gl.TEXTURE_CUBE_MAP_NEGATIVE_Y, gl.TEXTURE_CUBE_MAP_NEGATIVE_Z];
    this._bindTextureDirectly(gl.TEXTURE_CUBE_MAP, texture, true);
    this._unpackFlipY(false);
    const internalFormat = format ? this._getInternalFormat(format, texture._useSRGBBuffer) : texture._useSRGBBuffer ? this._glSRGBExtensionValues.SRGB8_ALPHA8 : gl.RGBA;
    let texelFormat = format ? this._getInternalFormat(format) : gl.RGBA;
    if (texture._useSRGBBuffer && this.webGLVersion === 1) {
      texelFormat = internalFormat;
    }
    for (let index = 0; index < faces.length; index++) {
      if (imgs[index].width !== width || imgs[index].height !== height) {
        this._prepareWorkingCanvas();
        if (!this._workingCanvas || !this._workingContext) {
          Logger.Warn("Cannot create canvas to resize texture.");
          return;
        }
        this._workingCanvas.width = width;
        this._workingCanvas.height = height;
        this._workingContext.drawImage(imgs[index], 0, 0, imgs[index].width, imgs[index].height, 0, 0, width, height);
        gl.texImage2D(faces[index], 0, internalFormat, texelFormat, gl.UNSIGNED_BYTE, this._workingCanvas);
      } else {
        gl.texImage2D(faces[index], 0, internalFormat, texelFormat, gl.UNSIGNED_BYTE, imgs[index]);
      }
    }
    if (!noMipmap) {
      gl.generateMipmap(gl.TEXTURE_CUBE_MAP);
    }
    this._setCubeMapTextureParams(texture, !noMipmap);
    texture.width = width;
    texture.height = height;
    texture.isReady = true;
    if (format) {
      texture.format = format;
    }
    texture.onLoadedObservable.notifyObservers(texture);
    texture.onLoadedObservable.clear();
    if (onLoad) {
      onLoad();
    }
  }, !!useSRGBBuffer);
};
