<div
  uiTrapFocus
  class="quill-editor-container"
  [class.backdrop]="hasBackdrop"
  [class.allow-header]="allowHeaderClick"
  [style.zIndex]="zIndex"
  (click)="onContainerClick($event)"
>
  <div class="popup-content-modal">
    <section class="modal-header">
      <div class="header-title">{{ 'sidenav.media' | translate }}</div>
      <div class="close icon-wrapper" (click)="close()">
        <div class="icon icon-close sm"></div>
      </div>
    </section>
    <div class="modal-body">
      <ui-media-upload-library
        [images]="images"
        [videos]="videos"
        [uploadType]="mediaType"
        (onSelect)="selectImage($event)"
      ></ui-media-upload-library>
      <section class="footer">
        <div></div>
        <div class="buttons-container">
          <button ui-button sm secondary (click)="close()">
            {{ 'shared.actions.cancel' | translate }}
          </button>
        </div>
      </section>
    </div>
  </div>
</div>
