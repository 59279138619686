import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ModalService } from '../../../modal.service';

@Component({
  selector: 'ui-save-video',
  templateUrl: './save-video.component.html',
  styleUrls: ['./save-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveVideoComponent implements OnInit {
  modalRef: ModalService;
  selectedSlider: boolean = true;

  constructor() {}

  ngOnInit(): void {}

  close() {
    this.modalRef.close(false);
  }

  save() {
    this.modalRef.close(this.selectedSlider ? 'auto' : 'manual');
  }
}
