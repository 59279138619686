<div class="ui-color-palette" [ngClass]="[!!open ? 'opened' : '', position]" (uiClickOutside)="clickOutside($event)">
  <div class="color-palette-activator" (click)="toggleMenu()">
    <ng-container *ngTemplateOutlet="activatorTemplate ? activatorTemplate : defaultActivator"></ng-container>
    <ng-template #defaultActivator>
      @if (type === 'icon') {
        <div class="color-palette-btn">
          <div class="icon icon-color-palette" [style]="{ backgroundColor: _color || 'white' }"></div>
          <div class="icon icon-color-palette-outline"></div>
        </div>
      } @else {
        <div class="preview">
          <div class="preview-color" [ngStyle]="{ backgroundColor: _color || 'white' }" [ngClass]="{ empty: !_color }"></div>
          <div class="preview-code">
            <span>{{ _color || 'transparent' }}</span>
          </div>
        </div>
      }
    </ng-template>
  </div>
  <ng-template #contentRef>
    <div class="color-palette-menu" [ngClass]="[position]">
      <div class="color-list">
        <div class="color-item empty" (click)="changeColor({ value: '#00000000', isLimited: false })"></div>
        @for (color of colorOptions; track color) {
          <div
            class="color-item"
            [ngStyle]="{ backgroundColor: color.value }"
            [class.disabled]="_isScenePlanPropertyRestriction && color.isLimited"
            uiTooltip="{{
              _isScenePlanPropertyRestriction && color.isLimited ? ('oxr.creatingSpace.proPlanEnabledFeaturesTooltip' | translate) : ''
            }}"
            [tooltipPosition]="toolTipPositions.Below"
            tooltipClasses="tooltip"
            (click)="changeColor(color)"
          ></div>
        }
      </div>
      <div class="color-input-control">
        <div class="color-input-code-wrapper">
          @if (_isScenePlanPropertyRestriction) {
            <i class="icon icon-lock"></i>
          }
          @if (!_isScenePlanPropertyRestriction) {
            <div class="preview-color" [ngStyle]="{ backgroundColor: _color || 'white' }" [ngClass]="{ empty: !_color }"></div>
          }
          <input
            uiInput
            sm
            type="text"
            class="color-input-code"
            [value]="_color"
            (input)="onColorInput($event)"
            [disabled]="_isScenePlanPropertyRestriction"
          />
        </div>
        <div class="color-input-transparency-wrapper">
          <input
            uiInput
            sm
            type="text"
            class="color-input-transparency"
            type="number"
            [value]="transparency"
            [disabled]="_isScenePlanPropertyRestriction || !_color"
            (input)="onColorTransparency($event)"
          />
        </div>
      </div>
    </div>
  </ng-template>
</div>
