<div class="wrapper">
  <span class="name">{{ label | translate }}</span>
  @if (disabled) {
    <i
      class="icon icon-info xs"
      uiTooltip="{{ 'oxr.creatingSpace.proPlanEnabledFeaturesTooltip' | translate }}"
      [tooltipPosition]="toolTipPositions.Below"
      tooltipClasses="tooltip"
    ></i>
  }
</div>

<div class="slider-container">
  <input
    #slider
    class="ui-slider"
    type="range"
    [min]="options.floor"
    [max]="options.ceil"
    [ngModel]="_value"
    [step]="options.step"
    [disabled]="disabled"
    (change)="userChangeEnd($event)"
    (input)="change($event)"
  />
  @if (showInput) {
    <input
      uiInput
      sm
      type="number"
      step="0.01"
      name="slider"
      [ngModel]="_value"
      [disabled]="disabled"
      (input)="onInputChange($event)"
      (blur)="onInputBlur($event)"
    />
  }
</div>
