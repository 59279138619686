<ui-panel [title]="'sidenav.popupContent' | translate" [collapsing]="true" [panelId]="panelMask.Popup">
  <div class="tool-header">
    <div class="tool-header-title">
      <span class="icon icon-arrow-down"></span>
      {{ (popup && popup.type ? 'oxr.creatingSpace.' + popup.type : 'oxr.creatingSpace.panels.popupContent.popupContent') | translate }}
    </div>
    <div class="tool-header-action">
      <div class="icon icon-edit" (click)="onEdit()"></div>
      <div class="icon icon-delete" (click)="onDelete()"></div>
    </div>
  </div>
  <div class="tool-body">
    <div class="label">{{ 'oxr.creatingSpace.panels.popupContent.icon' | translate }}</div>
    <div class="container">
      <div class="option-item">
        <div class="text">{{ 'oxr.creatingSpace.panels.common.color' | translate }}</div>
        <ui-color-picker
          [defaultColor]="color"
          [showValue]="false"
          (changed)="onColorChanged($event)"
          (changeEnd)="onChangeEnd()"
        ></ui-color-picker>
      </div>
      <div class="option-item">
        <div class="uppercase">
          {{ 'oxr.creatingSpace.panels.popupContent.' + iconType | translate }}
        </div>
        <ui-switch
          class="icon-style-switch"
          [checked]="iconType === 'solid'"
          [onShowContentMode]="false"
          (changed)="onThumbnailTypeChanged($event)"
        ></ui-switch>
      </div>
      <div class="option-item">
        <div class="icons">
          <div class="container" [ngClass]="{ selected: !selectedIcon }">
            <div class="icon icon-slash" (click)="onThumbnailSelected()"></div>
          </div>
          <div class="container">
            <div
              class="add"
              [ngClass]="{
                disabled: !allowAdd
              }"
            >
              <div class="icon icon-plus md"></div>
              @if (!allowAdd) {
                <div class="icon icon-union"></div>
              }
            </div>
          </div>
          @for (icon of icons; track icon) {
            <div class="container" [ngClass]="{ selected: selectedIcon === icon }" (click)="onThumbnailSelected(icon)">
              <svg-icon
                [src]="'ui/assets/icons/' + 'icon-' + icon + '-' + iconType + '.svg'"
                [stretch]="true"
                [svgStyle]="{ fill: color }"
                [attr.data-name]="icon"
              >
              </svg-icon>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</ui-panel>
