<div class="container" (uiClickOutside)="onHide($event)">
  @for (item of data; track item) {
    <div class="item" [class.has-shortcut]="item.shortcut" (click)="setSelection(item)">
      <div class="left">{{ item.name | translate }}</div>
      @if (item.shortcut) {
        <div class="right">{{ item.shortcut }}</div>
      }
    </div>
  }
</div>
