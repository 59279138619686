import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'ui-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextBoxComponent implements OnInit {
  @Input() control: UntypedFormControl;
  @Input() model = '';
  @Input() placeholder = '';
  @Input() disabled = false;

  @Output() modelChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  onChange(value: string) {
    this.modelChange.emit(value);
  }
}
