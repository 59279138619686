<div class="search-bar" [ngClass]="{ error: error }">
  <input
    type="text"
    [ngModel]="searchText"
    (ngModelChange)="onSearchTextChange($event)"
    [placeholder]="placeholder"
    [disabled]="disabled"
    (keyup.enter)="onSearchTriggered()"
  />
  <div class="icon icon-search" (click)="onSearchTriggered()"></div>
</div>
