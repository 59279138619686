import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, OnDestroy, ViewChild, NgZone } from '@angular/core';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { Access, FileStorageType } from 'projects/data/src/lib/enums/access';
import { FileService } from 'projects/data/src/lib/services/file.service';
import { ContextMenuItem } from '../../../components/context-menu/context-menu.component';
import { ApplicationService } from '@/view/src/app/app.service';
import { ModalService } from '../../modal.service';
import { SaveGalleryComponent } from './save-gallery/save-gallery.component';
import { PopupType, TInteractableElement } from '@/data/src/lib/view-manager';
import { FileType, MediaType } from '@/data/src/lib/enums/file-type';
import { groupData } from '@/data/src/lib/utils/collection';
import { FileReference } from '@/data/src/lib/models/data/file-reference';
import { prependHTTP } from '@/data/src/lib/utils/url-utils';
import { DragDropController, SpaceToolColor, SpaceToolItem, SpaceToolLayout } from '../../../utils/dragDropController';
import { UrlService } from '@/data/src/lib/services/url.service';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { ScenePlan } from '@/data/src/lib/enums/pricing-plan';

export interface GalleryToolImage extends SpaceToolItem {
  imageUrl: string;
  loadingImage: boolean;
  hyperlink?: string;
  positions: { [key: string]: any };
}

@Component({
  selector: 'ui-gallery-tool',
  templateUrl: './gallery-tool.component.html',
  styleUrls: ['./gallery-tool.component.scss'],
  providers: [ModalService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryToolComponent extends DragDropController implements OnInit, OnDestroy {
  title = '';
  data: { [key: string]: any };
  imageCount: number;
  autoSlide = 3;
  allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
  containerWidth = 1200;
  plan = ScenePlan;

  public mediaImages!: FileReference[];

  modalRef: ModalService;
  selectedElement?: TInteractableElement;

  uploadFileType = FileType.Image;

  @ViewChild('container') container: ElementRef<HTMLDivElement>;
  @ViewChild('popupWrapper') popupWrapper: ElementRef<HTMLDivElement>;
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  defaultImageColor = { background: '#FFFFFF', text: '#000000', isSelected: true };

  spaceLayouts = [
    {
      icon: 'icon icon-vertical-align',
      value: SpaceToolLayout.EditorBelow,
    },
    {
      icon: 'icon icon-horizontal-align',
      value: SpaceToolLayout.EditorRight,
    },
    {
      icon: 'icon icon-full-align',
      value: SpaceToolLayout.NoEditor,
    },
  ];

  images: GalleryToolImage[] = [
    {
      imageUrl: '',
      name: '',
      description: '',
      altText: '',
      layout: SpaceToolLayout.EditorBelow,
      color: { ...this.defaultImageColor },
      showContextMenu: false,
      showAltText: false,
      loadingImage: false,
      hyperlink: '',
      positions: { ...this.defaultPosition },
    },
    {
      imageUrl: '',
      name: '',
      description: '',
      altText: '',
      layout: SpaceToolLayout.EditorBelow,
      color: { ...this.defaultImageColor },
      showContextMenu: false,
      showAltText: false,
      loadingImage: false,
      hyperlink: '',
      positions: { ...this.defaultPosition },
    },
    {
      imageUrl: '',
      name: '',
      description: '',
      altText: '',
      layout: SpaceToolLayout.EditorBelow,
      color: { ...this.defaultImageColor },
      showContextMenu: false,
      showAltText: false,
      loadingImage: false,
      hyperlink: '',
      positions: { ...this.defaultPosition },
    },
    {
      imageUrl: '',
      name: '',
      description: '',
      altText: '',
      layout: SpaceToolLayout.EditorBelow,
      color: { ...this.defaultImageColor },
      showContextMenu: false,
      showAltText: false,
      loadingImage: false,
      hyperlink: '',
      positions: { ...this.defaultPosition },
    },
    {
      imageUrl: '',
      name: '',
      description: '',
      altText: '',
      layout: SpaceToolLayout.EditorBelow,
      color: { ...this.defaultImageColor },
      showContextMenu: false,
      showAltText: false,
      loadingImage: false,
      hyperlink: '',
      positions: { ...this.defaultPosition },
    },
    {
      imageUrl: '',
      name: '',
      description: '',
      altText: '',
      layout: SpaceToolLayout.EditorBelow,
      color: { ...this.defaultImageColor },
      showContextMenu: false,
      showAltText: false,
      loadingImage: false,
      hyperlink: '',
      positions: { ...this.defaultPosition },
    },
    {
      imageUrl: '',
      name: '',
      description: '',
      altText: '',
      layout: SpaceToolLayout.EditorBelow,
      color: { ...this.defaultImageColor },
      showContextMenu: false,
      showAltText: false,
      loadingImage: false,
      hyperlink: '',
      positions: { ...this.defaultPosition },
    },
    {
      imageUrl: '',
      name: '',
      description: '',
      altText: '',
      layout: SpaceToolLayout.EditorBelow,
      color: { ...this.defaultImageColor },
      showContextMenu: false,
      showAltText: false,
      loadingImage: false,
      hyperlink: '',
      positions: { ...this.defaultPosition },
    },
    {
      imageUrl: '',
      name: '',
      description: '',
      altText: '',
      layout: SpaceToolLayout.EditorBelow,
      color: { ...this.defaultImageColor },
      showContextMenu: false,
      showAltText: false,
      loadingImage: false,
      hyperlink: '',
      positions: { ...this.defaultPosition },
    },
    {
      imageUrl: '',
      name: '',
      description: '',
      altText: '',
      layout: SpaceToolLayout.EditorBelow,
      color: { ...this.defaultImageColor },
      showContextMenu: false,
      showAltText: false,
      loadingImage: false,
      hyperlink: '',
      positions: { ...this.defaultPosition },
    },
  ];

  contextMenuOptions: ContextMenuItem[] = [
    { name: 'oxr.creatingSpace.altText', shortcut: '', value: 'altText' },
    { name: 'oxr.creatingSpace.hyperlink', shortcut: '', value: 'hyperlink' },
  ];
  contextMenuPosition: { x: number; y: number } = { x: 800, y: 800 };
  contextMenuValue: ContextMenuItem | null = null;

  isPreviewMode = false;
  isReadonly = false;

  currentPage = 1;
  autoSlideInterval: any;

  get imageRange() {
    return this.isPreviewMode || this.isReadonly ? new Array(this.imageCount) : new Array(10);
  }

  get currentImage() {
    return this.images[this.currentPage - 1];
  }

  get imagesWithContent() {
    return this.images.filter((img) => img.imageUrl);
  }

  get displayedImages() {
    return this.isPreviewMode || this.isReadonly ? this.imagesWithContent : this.images;
  }

  get canScrollLeft() {
    return this.currentPage > 1 && !this._isScrolling;
  }

  get canScrollRight() {
    return this.currentPage < this.displayedImages.length && !this._isScrolling;
  }

  get isImageUploaded() {
    return !!this.images.some((img) => !!img.imageUrl);
  }

  get scenePlan() {
    const currentScene = this._appService.getActiveModel() as XRScene;
    return currentScene?.Plan;
  }

  _isScrolling = false;

  constructor(
    private _modalService: ModalService,
    private _appService: ApplicationService,
    public _cd: ChangeDetectorRef,
    public fileService: FileService,
    private _urlService: UrlService,
    private _sceneService: SceneService,
    _ngZone: NgZone,
  ) {
    super(_ngZone);
  }

  ngOnInit(): void {
    if (this.data['title']) {
      this.title = this.data['title'];
    }

    this.triggerAutoSlide(this.data['auto-slide']);

    for (let i = 0; i < this.imageCount; i++) {
      if (this.images[i]) {
        this.images[i].imageUrl = this.data['image' + i];
        this.images[i].description = this.data['description' + i];
        this.images[i].altText = this.data['alt-text' + i];
        this.images[i].hyperlink = this.data['hyperlink' + i];
        this.images[i].layout = this.data['layout' + i] || SpaceToolLayout.EditorBelow;
        this.images[i].color.background = this.data['background-colour' + i] || this.defaultImageColor.background;
        this.images[i].color.text = this.data['text-colour' + i] || this.defaultImageColor.text;
        if (this.data['positions' + i]['position1'] !== undefined) {
          this.images[i].positions = this.data['positions' + i];
        }
      }
    }

    this.fileService.data$.subscribe((res) => {
      const files = groupData(res, 'FileType');
      // TODO:after work shope about datastorage managing of private data
      // this.images = files.get(FileType.Image)?.filter((e) => e.Access === 'Private') ?? [];
      this.mediaImages = files.get(FileType.Image) ?? [];
      this._cd.detectChanges();
      // TODO:after work shope about datastorage managing of private data
      // this.videos = files.get(FileType.Video)?.filter((e) => e.Access === 'Private') ?? [];
    });
  }

  triggerAutoSlide(autoSlide) {
    this.autoSlide = autoSlide ?? 0;
    clearInterval(this.autoSlideInterval);
    if (this.isReadonly && this.autoSlide) {
      this._ngZone.runOutsideAngular(() => {
        this.autoSlideInterval = setInterval(() => {
          if (this.canScrollRight) {
            this.nextItem();
          } else {
            this.currentPage = 1;
            this.scrollToPage(this.currentPage);
            this._cd.detectChanges();
          }
          this._ngZone.run(() => {});
        }, this.autoSlide * 1000);
      });
    }
  }

  ngOnDestroy(): void {
    if (this.autoSlideInterval) {
      clearInterval(this.autoSlideInterval);
    }
    this.autoSlideInterval = null;
  }

  close() {
    this.modalRef.close(false);
  }

  isColorSelected(color: SpaceToolColor) {
    return this.currentImage.color.background === color.background;
  }

  previousItem() {
    if (this.canScrollLeft) {
      const toPage = this.currentPage - 1;
      this.scrollToPage(toPage);
      this.currentPage -= 1;
      this.triggerAutoSlide(this.data['auto-slide']);
    }
  }

  nextItem() {
    if (this.canScrollRight) {
      const toPage = this.currentPage + 1;
      this.scrollToPage(toPage);
      this.currentPage += 1;
      this._cd.detectChanges();
      this.triggerAutoSlide(this.data['auto-slide']);
    }
  }

  enterPreviewMode() {
    this.isPreviewMode = true;
    this.currentPage = 1;
    this.scrollToPage(this.currentPage);
  }

  scrollToPage(pageNumber, behavior: ScrollBehavior = 'smooth') {
    this._isScrolling = true;
    const left = this.containerWidth * (pageNumber - 1);
    this.container.nativeElement.scrollTo({ left, top: 0, behavior });
    this._isScrolling = false;
  }

  onFileDrop(fileList: FileList) {
    if (this.allowedTypes.includes(fileList[0]?.type) && fileList[0]?.size <= 100000000) {
      this.currentImage.name = fileList[0].name;
      this.uploadFile(fileList[0]);
    }
  }

  onFileInputChange(event: any) {
    if (this.allowedTypes.includes(event.target.files[0]?.type) && event.target.files[0]?.size <= 100000000) {
      this.currentImage.name = event.target.files[0].name;
      this.uploadFile(event.target.files[0]);
    }
  }

  async uploadFile(file: File) {
    const currentImage = this.currentImage;
    currentImage.loadingImage = true;
    const { url } = await this.fileService.uploadFile(
      file,
      FileStorageType.Public,
      undefined,
      file.name,
      true,
      FileType.Image,
      this._sceneService.getSelected()?.Id,
      Access.Public,
      MediaType.MediaTab,
    );
    currentImage.imageUrl = url;
    currentImage.loadingImage = false;
    this._cd.markForCheck();
  }

  onContextMenu(event, image) {
    event.preventDefault();
    event.stopPropagation();
    const { left, top } = this.popupWrapper.nativeElement.getBoundingClientRect();
    this.contextMenuPosition = { x: event.x, y: event.y };
    this.currentImage.showContextMenu = true;
  }

  onContextMenuSelect(item: ContextMenuItem) {
    this.contextMenuValue = item;
    this.currentImage.showContextMenu = false;
  }

  save() {
    const modalRef = this._modalService.open(SaveGalleryComponent);
    modalRef.instance.modalRef = this._modalService;
    modalRef.result.then((result) => {
      if (result !== false) {
        this.data = {};
        this.data['title'] = this.title;
        this.data['auto-slide'] = result;
        this.imagesWithContent.forEach((image, i) => {
          this.data['image' + i] = image.imageUrl;
          this.data['description' + i] = image.description;
          this.data['alt-text' + i] = image.altText;
          this.data['hyperlink' + i] = image.hyperlink;
          this.data['layout' + i] = image.layout;
          this.data['background-colour' + i] = image.color.background;
          this.data['text-colour' + i] = image.color.text;
          this.data['positions' + i] = image.positions;
        });
        const oldData = this.selectedElement?.parameters?.popups?.length ? this.selectedElement.parameters.popups[0] : {};
        this.selectedElement &&
          this._appService.updateViewElement(
            {
              ...this.selectedElement,
              parameters: {
                ...this.selectedElement.parameters,
                popups: [
                  {
                    ...oldData,
                    type: PopupType.Gallery,
                    parameters: [...Object.entries(this.data).map(([key, value]) => ({ key, value }))],
                  },
                ],
              },
            } as TInteractableElement,
            true,
          );
        this.modalRef.close(this.data);
      }
    });
  }

  setCurrentImageBackgroundColor(color: string) {
    this.images[this.currentPage - 1].color.background = color;
  }

  setCurrentImageTextColor(color: string) {
    this.images[this.currentPage - 1].color.text = color;
  }

  setCurrentImageSpaceLayout(layout: SpaceToolLayout) {
    this.images[this.currentPage - 1].layout = layout;
    this.images[this.currentPage - 1].positions = { ...this.defaultPosition };
  }
  setCurrentImageAltValue(input) {
    if (this.contextMenuValue) {
      switch (this.contextMenuValue.value) {
        case 'altText':
          this.images[this.currentPage - 1].altText = input.value;
          break;
        case 'hyperlink':
          this.images[this.currentPage - 1].hyperlink = input.value;
          break;
      }
      this.contextMenuValue = null;
    }
  }

  cancelAltInput() {
    this.contextMenuValue = null;
  }
  goToUrl(hyperlink) {
    if (!hyperlink) return;
    const url = prependHTTP(hyperlink);
    if (url) {
      this._urlService.windowOpenWithPlatform(url);
    }
  }

  loadImage(asset: FileReference) {
    const currentImage = this.currentImage;
    currentImage.loadingImage = true;
    currentImage.imageUrl = asset.Url;
    currentImage.loadingImage = false;
    this._cd.markForCheck();
  }
}
