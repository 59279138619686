import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[uiOnIntersectionObserver]',
})
export class OnIntersectionObserverDirective implements AfterViewInit {
  @Input() observer: IntersectionObserver | undefined;

  constructor(private element: ElementRef) {}
  ngAfterViewInit() {
    this.startObservingElement();
  }

  private startObservingElement() {
    if (!this.observer) {
      return;
    }
    this.observer.observe(this.element.nativeElement);
  }
}
