import { Vector3, Quaternion, TmpVectors, Matrix } from "@babylonjs/core/Maths/math.vector.js";
import { Color3 } from "@babylonjs/core/Maths/math.color.js";
import { Light } from "@babylonjs/core/Lights/light.js";
import { ShadowLight } from "@babylonjs/core/Lights/shadowLight.js";
import { _Exporter } from "../glTFExporter.js";
import { Logger } from "@babylonjs/core/Misc/logger.js";
const NAME = "KHR_lights_punctual";
/**
 * [Specification](https://github.com/KhronosGroup/glTF/blob/master/extensions/2.0/Khronos/KHR_lights_punctual/README.md)
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class KHR_lights_punctual {
  /**
   * @internal
   */
  constructor(exporter) {
    /** The name of this extension. */
    this.name = NAME;
    /** Defines whether this extension is enabled. */
    this.enabled = true;
    /** Defines whether this extension is required */
    this.required = false;
    this._exporter = exporter;
  }
  /** @internal */
  dispose() {
    this._lights = null;
  }
  /** @internal */
  get wasUsed() {
    return !!this._lights;
  }
  /** @internal */
  onExporting() {
    this._exporter._glTF.extensions[NAME] = this._lights;
  }
  /**
   * Define this method to modify the default behavior when exporting a node
   * @param context The context when exporting the node
   * @param node glTF node
   * @param babylonNode BabylonJS node
   * @param nodeMap Node mapping of unique id to glTF node index
   * @returns nullable INode promise
   */
  postExportNodeAsync(context, node, babylonNode, nodeMap) {
    return new Promise(resolve => {
      if (node && babylonNode instanceof ShadowLight) {
        let light;
        const lightType = babylonNode.getTypeID() == Light.LIGHTTYPEID_POINTLIGHT ? "point" /* KHRLightsPunctual_LightType.POINT */ : babylonNode.getTypeID() == Light.LIGHTTYPEID_DIRECTIONALLIGHT ? "directional" /* KHRLightsPunctual_LightType.DIRECTIONAL */ : babylonNode.getTypeID() == Light.LIGHTTYPEID_SPOTLIGHT ? "spot" /* KHRLightsPunctual_LightType.SPOT */ : null;
        if (lightType == null) {
          Logger.Warn(`${context}: Light ${babylonNode.name} is not supported in ${NAME}`);
        } else {
          if (!babylonNode.position.equalsToFloats(0, 0, 0)) {
            node.translation = babylonNode.position.asArray();
          }
          if (lightType !== "point" /* KHRLightsPunctual_LightType.POINT */) {
            const localAxis = babylonNode.direction;
            const yaw = -Math.atan2(localAxis.z, localAxis.x) + Math.PI / 2;
            const len = Math.sqrt(localAxis.x * localAxis.x + localAxis.z * localAxis.z);
            const pitch = -Math.atan2(localAxis.y, len);
            const lightRotationQuaternion = Quaternion.RotationYawPitchRoll(yaw + Math.PI, pitch, 0);
            if (!Quaternion.IsIdentity(lightRotationQuaternion)) {
              node.rotation = lightRotationQuaternion.asArray();
            }
          }
          if (babylonNode.falloffType !== Light.FALLOFF_GLTF) {
            Logger.Warn(`${context}: Light falloff for ${babylonNode.name} does not match the ${NAME} specification!`);
          }
          light = {
            type: lightType
          };
          if (!babylonNode.diffuse.equals(Color3.White())) {
            light.color = babylonNode.diffuse.asArray();
          }
          if (babylonNode.intensity !== 1.0) {
            light.intensity = babylonNode.intensity;
          }
          if (babylonNode.range !== Number.MAX_VALUE) {
            light.range = babylonNode.range;
          }
          if (lightType === "spot" /* KHRLightsPunctual_LightType.SPOT */) {
            const babylonSpotLight = babylonNode;
            if (babylonSpotLight.angle !== Math.PI / 2.0) {
              if (light.spot == null) {
                light.spot = {};
              }
              light.spot.outerConeAngle = babylonSpotLight.angle / 2.0;
            }
            if (babylonSpotLight.innerAngle !== 0) {
              if (light.spot == null) {
                light.spot = {};
              }
              light.spot.innerConeAngle = babylonSpotLight.innerAngle / 2.0;
            }
          }
          this._lights || (this._lights = {
            lights: []
          });
          this._lights.lights.push(light);
          const lightReference = {
            light: this._lights.lights.length - 1
          };
          // Avoid duplicating the Light's parent node if possible.
          const parentBabylonNode = babylonNode.parent;
          if (parentBabylonNode && parentBabylonNode.getChildren().length == 1) {
            const parentNode = this._exporter._nodes[nodeMap[parentBabylonNode.uniqueId]];
            if (parentNode) {
              const parentTranslation = Vector3.FromArrayToRef(parentNode.translation || [0, 0, 0], 0, TmpVectors.Vector3[0]);
              const parentRotation = Quaternion.FromArrayToRef(parentNode.rotation || [0, 0, 0, 1], 0, TmpVectors.Quaternion[0]);
              const parentScale = Vector3.FromArrayToRef(parentNode.scale || [1, 1, 1], 0, TmpVectors.Vector3[1]);
              const parentMatrix = Matrix.ComposeToRef(parentScale, parentRotation, parentTranslation, TmpVectors.Matrix[0]);
              const translation = Vector3.FromArrayToRef(node.translation || [0, 0, 0], 0, TmpVectors.Vector3[2]);
              const rotation = Quaternion.FromArrayToRef(node.rotation || [0, 0, 0, 1], 0, TmpVectors.Quaternion[1]);
              const matrix = Matrix.ComposeToRef(Vector3.OneReadOnly, rotation, translation, TmpVectors.Matrix[1]);
              parentMatrix.multiplyToRef(matrix, matrix);
              matrix.decompose(parentScale, parentRotation, parentTranslation);
              if (parentTranslation.equalsToFloats(0, 0, 0)) {
                delete parentNode.translation;
              } else {
                parentNode.translation = parentTranslation.asArray();
              }
              if (Quaternion.IsIdentity(parentRotation)) {
                delete parentNode.rotation;
              } else {
                parentNode.rotation = parentRotation.asArray();
              }
              if (parentScale.equalsToFloats(1, 1, 1)) {
                delete parentNode.scale;
              } else {
                parentNode.scale = parentScale.asArray();
              }
              parentNode.extensions || (parentNode.extensions = {});
              parentNode.extensions[NAME] = lightReference;
              // Do not export the original node
              resolve(null);
              return;
            }
          }
          node.extensions || (node.extensions = {});
          node.extensions[NAME] = lightReference;
        }
      }
      resolve(node);
    });
  }
}
_Exporter.RegisterExtension(NAME, exporter => new KHR_lights_punctual(exporter));
