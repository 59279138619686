import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApplicationService } from '@/view/src/app/app.service';
import { SceneInteractionType } from '@/data/src/lib/enums/scene-interaction';
import { XRSceneInteraction } from '@/data/src/lib/models/data/scene';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { INTERACTABLE_TYPES, TInteractableElement } from '@/data/src/lib/view-manager';

@UntilDestroy()
@Component({
  selector: 'ui-guest-book',
  templateUrl: './guest-book.component.html',
  styleUrls: ['./guest-book.component.scss'],
})
export class GuestBookComponent implements OnInit {
  @Input() oxrModel!: string;
  @Input() editMode!: boolean;
  @Output() oxrModelChange = new EventEmitter<string>();

  guestBook!: { isPrivate: boolean };
  sceneId?: string;
  selectedElement?: TInteractableElement;
  interactions!: XRSceneInteraction[];
  newComment = '';

  constructor(
    private appService: ApplicationService,
    private sceneService: SceneService,
  ) {}

  ngOnDestroy(): void {
    this.oxrModelChange.emit(JSON.stringify(this.guestBook));
  }

  async ngOnInit(): Promise<void> {
    this.appService.viewManagerSubject.pipe(untilDestroyed(this)).subscribe((viewManager) => {
      this.sceneId = viewManager?.sceneModel.Id;
      this.updateInteractions();
    });

    this.appService.viewElementsSubject.pipe(untilDestroyed(this)).subscribe((elements) => {
      this.selectedElement = elements.find(({ id }) => id === this.selectedElement?.id) as TInteractableElement;
    });

    this.appService.selectedElementsSubject.pipe(untilDestroyed(this)).subscribe((elements) => {
      this.selectedElement =
        elements.length === 1 && INTERACTABLE_TYPES.includes(elements[0].type) ? (elements[0] as TInteractableElement) : undefined;
    });

    this.guestBook = this.oxrModel ? JSON.parse(this.oxrModel) : { isPrivate: false };
  }

  private async updateInteractions() {
    if (!(this.sceneId && this.selectedElement)) {
      return;
    }
    this.interactions = (await this.sceneService.getInteractions(this.sceneId, SceneInteractionType.Comment, this.selectedElement.id)).sort(
      (a, b) => (a.Date < b.Date ? -1 : 1),
    );
  }

  async addComment() {
    if (!(this.sceneId && this.selectedElement)) {
      return;
    }
    if (this.newComment) {
      const interaction = new XRSceneInteraction(this.sceneId, this.selectedElement.id, SceneInteractionType.Comment, {
        Comment: this.newComment,
      });
      this.newComment = '';
      await this.sceneService.postInteraction(interaction);
      await this.updateInteractions();
    }
  }

  async onKeyDown(event: any) {
    if (event.code === 'Enter') {
      await this.addComment();
    }
  }

  checkChanged(event: any) {}
}
