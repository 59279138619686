import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { ChangeDetectionStrategy, Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { FileService } from '@/data/src/lib/services/file.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'ui-m-text-tool',
  templateUrl: './m-text-tool.component.html',
  styleUrls: ['./m-text-tool.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileTextToolComponent implements OnInit {
  @HostBinding('class.tablet') isTablet = false;
  data: { [key: string]: string };

  get isTitleHidden() {
    return this.data?.['title-hidden'] === 'true';
  }

  constructor(private _deviceService: DeviceDetectorService, private _modalService: ModalService, public fileService: FileService) {
    this.isTablet = this._deviceService.isTablet(); // otherwise it's mobile
  }

  ngOnInit(): void {}

  close() {
    this._modalService.close(false, this['modalContainerRef']);
  }

  save() {
    this._modalService.close(true, this['modalContainerRef']);
  }
}
