export function createCopy<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj)) as T;
}

/* Copies an Object, retaining it's class properties and functions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
export function deepCopy<T>(source: T): T {
  return Array.isArray(source)
    ? source.map((item) => deepCopy(item))
    : source instanceof Date
    ? new Date(source.getTime())
    : source && typeof source === 'object'
    ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
        Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop)!);
        o[prop] = deepCopy((source as { [key: string]: any })[prop]);
        return o;
      }, Object.create(Object.getPrototypeOf(source)))
    : (source as T);
}
