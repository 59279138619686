import { v4 as uuid } from 'uuid';
import {
  Mesh,
  MeshBuilder,
  Nullable,
  Observer,
  Quaternion,
  Scene,
  StandardMaterial,
  Texture,
  TransformNode,
  Vector3,
  VideoTexture,
} from '@/data/src/lib/babylon';
import { TMedia, SelectTool, MediaControl, ViewMode, ScreenRenderable, TScreenElement, MediaDimensions } from '@/data/src/lib/view-manager';
import { SOUND_ONLY_PNG, Z_OFFSET } from '../constants';
import { deepCopy } from '../../utils/data';
import { FileType } from '../../enums/file-type';
import { AnimatedGifTexture } from './animated-gif-texture';
import { ViewManagerUtils } from '../../utils/view-manager-utils';

const Z_PI_QUATERNION = Quaternion.RotationAxis(new Vector3(0, 0, -1), Math.PI / 2);
const CONTROLLER_TIMEOUT = 1000;
const VOID_ACTION = () => {};

export class Media {
  public descriptor: TMedia;
  public dimensions: MediaDimensions;
  public hostFront: Nullable<Mesh>;
  public hostBack: Nullable<Mesh>;
  public isLoaded = false;

  private imageTexture: Texture | AnimatedGifTexture;
  private videoTexture: VideoTexture;

  private _absoluteScaling: Vector3;
  private _hostMaterial: StandardMaterial;
  private _scaledWidth: number;
  private _scaledHeight: number;

  private _hostScaling = Vector3.One();
  private _scalingFactor = Vector3.One();

  private afterMatrixObserver: Nullable<Observer<TransformNode>>;
  private beforeRenderObserver: Nullable<Observer<Scene>>;

  private controllerTimeout: NodeJS.Timeout;

  constructor(
    private renderable: ScreenRenderable,
    descriptor: TMedia,
  ) {
    this.setup = this.setup.bind(this);
    this.update = this.update.bind(this);
    this.hostControl = this.hostControl.bind(this);
    this.loadVideo = this.loadVideo.bind(this);
    this.offsetScreen = this.offsetScreen.bind(this);
    this.onTextureLoad = this.onTextureLoad.bind(this);
    this.onCanPlay = this.onCanPlay.bind(this);
    this.overInteractionCallback = this.overInteractionCallback.bind(this);
    this.outInteractionCallback = this.outInteractionCallback.bind(this);
    this.onPlay = this.onPlay.bind(this);
    this.onPause = this.onPause.bind(this);
    this.onVolumeChange = this.onVolumeChange.bind(this);
    this.onInteract = this.onInteract.bind(this);
    this.updateDurationBar = this.updateDurationBar.bind(this);
    this.setup(descriptor);
  }

  get scene() {
    return this.renderable.viewManager.scene;
  }

  get hostMaterial() {
    if (!this._hostMaterial) {
      this._hostMaterial = new StandardMaterial(`HostMaterial-${uuid()}`, this.scene);
      this._hostMaterial.emissiveColor.set(1, 1, 1);
      this._hostMaterial.diffuseColor.set(0, 0, 0);
      this._hostMaterial.specularColor.set(0, 0, 0);
      this._hostMaterial.backFaceCulling = true;
      this._hostMaterial.sideOrientation = Mesh.BACKSIDE;
    }
    return this._hostMaterial;
  }

  get imageRatio() {
    if (!this.hostFront) {
      return NaN;
    }
    const ratio = this.dimensions.width / this.dimensions.height;
    if (!isNaN(ratio) || !this.renderable.helper) {
      return ratio;
    }
    const boundingBox = this.renderable.helper.getBoundingInfo().boundingBox;
    const boundingHeight = boundingBox.maximumWorld.y ? boundingBox.extendSize.y : boundingBox.extendSize.z;
    const boundingWidth = boundingBox.extendSize.x;
    const rotation = this.renderable.helper.absoluteRotationQuaternion;

    return (
      this.hostFront.absoluteScaling.multiplyByFloats(boundingWidth, 0, 0).applyRotationQuaternion(rotation).length() /
      this.hostFront.absoluteScaling.multiplyByFloats(0, boundingHeight, 0).applyRotationQuaternion(rotation).length()
    );
  }

  get isPlaying() {
    return !this.videoTexture?.video?.paused && !this.videoTexture?.video?.ended;
  }

  setup(descriptor: TMedia) {
    if (!this.hostFront) {
      this.hostFront = MeshBuilder.CreatePlane(`HostFront-${uuid()}`, { size: 1, sideOrientation: Mesh.FRONTSIDE }, this.scene);
      ViewManagerUtils.AttachMetadata(this.hostFront, {
        helperOf: this.renderable,
        ...(descriptor.type === FileType.Video ? { control: this.hostControl } : {}),
      });
      this.hostFront.parent = this.renderable.helper;

      const boundingBox = this.renderable.helper!.getBoundingInfo().boundingBox;
      this.hostFront.position = boundingBox.center;
      this._absoluteScaling = this.renderable.helper.absoluteScaling.clone();

      if (boundingBox.extendSize.y == 0 && boundingBox.extendSizeWorld.y > 0) {
        if (this.renderable.helper.rotationQuaternion) {
          this.hostFront.rotationQuaternion = Quaternion.Inverse(this.renderable.helper.rotationQuaternion);
        }
        this.hostFront.position.y = -Z_OFFSET;
      } else {
        this.offsetScreen();
      }

      this.hostFront.isPickable = false;
      this.hostFront.material = this.hostMaterial;

      this.afterMatrixObserver = this.renderable.helper.onAfterWorldMatrixUpdateObservable.add(this.offsetScreen);
    }
    this.update(descriptor, true);
  }

  update(descriptor: TMedia, silent = false, resize = false) {
    let dirty = resize || descriptor.id !== this.descriptor?.id || descriptor.fileId !== this.descriptor?.fileId;
    this.descriptor = deepCopy(descriptor);

    if ((this.renderable.element as TScreenElement).parameters.doubleSided) {
      if (!this.hostBack && this.hostFront) {
        this.hostBack = this.hostFront.clone(`HostBack-${uuid()}`, this.renderable.helper, true);
        this.hostBack.rotation.y = Math.PI;
      }
      this.scene.onAfterRenderObservable.addOnce(() => {
        if (this.descriptor.type === FileType.Video) {
          this.isLoaded ? this.renderable.controllerBack?.setEnabled(true) : this.renderable.showLoadButton();
        }
      });
    } else {
      this.renderable._controllerBack?.dispose();
      this.renderable._controllerBack = null;
      this.hostBack?.dispose();
      this.hostBack = null;
    }

    if (dirty) {
      this.stop();
      ViewManagerUtils.GetMediaDimensions(this.descriptor).then((dimensions) => {
        if (!this.hostFront) {
          return;
        }

        this.dimensions = dimensions;
        this.hostFront.scaling.set(1, 1, 1);
        if (this.dimensions.rotate90) {
          this.hostFront.rotationQuaternion = Z_PI_QUATERNION;
        }
        this.imageTexture?.dispose();
        this.videoTexture?.dispose();

        switch (this.descriptor.type) {
          case FileType.Image:
            if (this.hostFront.metadata?.click) {
              this.hostFront.metadata.click = undefined;
            }
            if (this.hostBack?.metadata?.click) {
              this.hostBack.metadata.click = undefined;
            }
            this.renderable.hideLoadButton();
            this.renderable.hideControls();
            this.renderable.assignOverInteraction(VOID_ACTION);
            this.renderable.assignOutInteraction(VOID_ACTION);
            if (this.descriptor.url.toLowerCase().endsWith('.gif')) {
              this.imageTexture = new AnimatedGifTexture(this.descriptor.url, this.renderable.viewManager.scene.getEngine(), () => {
                this.onTextureLoad(this.imageTexture as AnimatedGifTexture, silent, resize);
              });
            } else {
              this.imageTexture = new Texture(
                this.descriptor.url,
                this.renderable.viewManager.scene,
                undefined,
                undefined,
                undefined,
                () => {
                  this.onTextureLoad(this.imageTexture as Texture, silent, resize);
                },
              );
            }
            break;
          case FileType.Video:
            this.hostFront.metadata.click = this.hostControl;
            if (this.hostBack) {
              this.hostBack.metadata.click = this.hostControl;
            }
            if (this.descriptor.autoplay || this.isLoaded) {
              this.loadVideo(silent, resize);
            } else {
              !this.isLoaded && this.renderable.showLoadButton();
              this.isLoaded = false;
              this.renderable.viewManager.appService.getFileThumbnail(descriptor.fileId).then((thumbnail) => {
                this.imageTexture = new Texture(
                  thumbnail || SOUND_ONLY_PNG,
                  this.renderable.viewManager.scene,
                  undefined,
                  undefined,
                  undefined,
                  () => {
                    this.onTextureLoad(this.imageTexture as Texture, silent, resize);
                    this.renderable.showLoadButton();
                  },
                );
              });
            }
            break;
          default:
            this.renderable.hideControls();
            break;
        }
      });
    }

    this.hostMaterial.emissiveColor.set(this.descriptor.brightness ?? 1, this.descriptor.brightness ?? 1, this.descriptor.brightness ?? 1);

    if (this.descriptor.autoplay && this.renderable.viewManager.targetMode === ViewMode.Player) {
      window.addEventListener('click', this.onInteract);
      window.addEventListener('touchstart', this.onInteract);
    }

    if (this.videoTexture?.video) {
      this.videoTexture.video.loop = !!this.descriptor.repeat;
      this.videoTexture.video.volume = this.descriptor.volume ?? 1;
      this.renderable.setVolume(this.descriptor.volume ?? 1);
      this.renderable.resizeControllers();
    }
  }

  hostControl() {
    if (this.isLoaded) {
      return;
    }
    this.loadVideo(true);
  }

  loadVideo(silent: boolean, resize = false) {
    if (!this.hostFront) {
      return;
    }

    this.videoTexture = new VideoTexture(uuid(), this.descriptor.url, this.renderable.viewManager.scene, undefined, undefined, undefined, {
      autoPlay: false,
    });
    this.imageTexture?.dispose();
    this.onTextureLoad(this.videoTexture as VideoTexture, silent, resize);
    this.isLoaded = true;
    this.hostFront.metadata.click = undefined;
    if (this.hostBack) {
      this.hostBack.metadata.click = undefined;
    }
    this.renderable.showControls();
  }

  offsetScreen() {
    if (!this.hostFront) {
      return;
    }
    this.hostFront.position.z =
      ((this.renderable.helper.getWorldMatrix().determinant() > 0 ? 1 : -1) * (Z_OFFSET * 5)) / Math.abs(this._absoluteScaling.z);

    if (!this.hostBack) {
      return;
    }
    this.hostBack.position.z = -this.hostFront.position.z;
  }

  play() {
    this.videoTexture?.video?.play();
    if (this.beforeRenderObserver) {
      return;
    }
    this.beforeRenderObserver = this.scene.onBeforeRenderObservable.add(this.updateDurationBar);
  }

  pause() {
    this.videoTexture?.video?.pause();
    // this.beforeRenderObserver && this.scene.onBeforeRenderObservable.remove(this.beforeRenderObserver);
  }

  stop() {
    if (!this.videoTexture?.video) {
      return;
    }
    this.pause();
    this.videoTexture.video.currentTime = 0;
    if (this.renderable._controllerFront) {
      this.renderable._controllerFront.durationBar.scaling.x = 0;
    }
    if (this.renderable._controllerBack) {
      this.renderable._controllerBack.durationBar.scaling.x = 0;
    }
  }

  fastForward() {
    if (!this.videoTexture?.video) {
      return;
    }
    this.videoTexture.video.currentTime += 5;
    if (this.renderable._controllerFront) {
      this.renderable._controllerFront.durationBar.scaling.x =
        Math.min(this.videoTexture.video.currentTime, this.videoTexture.video.duration) / this.videoTexture.video.duration;
      if (this.renderable._controllerBack) {
        this.renderable._controllerBack.durationBar.scaling.x = this.renderable._controllerFront.durationBar.scaling.x;
      }
    }
  }

  toggleSound() {
    if (!this.videoTexture?.video) {
      return;
    }
    if (this.videoTexture.video.volume === 0) {
      this.videoTexture.video.muted = false;
      this.videoTexture.video.volume = 1;
    } else {
      this.videoTexture.video.muted = !this.videoTexture.video.muted;
    }
  }

  setDurationPercentage(percentage: number) {
    if (!this.videoTexture?.video) {
      return;
    }
    this.videoTexture.video.currentTime = percentage * this.videoTexture.video.duration ?? Infinity;
    if (this.renderable._controllerFront) {
      this.renderable._controllerFront.durationBar.scaling.x =
        Math.min(this.videoTexture.video.currentTime, this.videoTexture.video.duration ?? Infinity) /
        (this.videoTexture.video.duration ?? Infinity);
      if (this.renderable._controllerBack) {
        this.renderable._controllerBack.durationBar.scaling.x = this.renderable._controllerFront.durationBar.scaling.x;
      }
    }
  }

  setVolume(volume: number) {
    if (!this.videoTexture?.video) {
      return;
    }
    this.videoTexture.video.muted = volume === 0;
    this.videoTexture.video.volume = volume;
  }

  fullscreen() {
    if (!this.videoTexture?.video) {
      return;
    }
    document.getElementById('activeFullscreenVideo')?.remove?.();
    this.videoTexture.video.id = 'activeFullscreenVideo';
    document.body.appendChild(this.videoTexture.video);
    this.videoTexture.video.requestFullscreen?.();
    this.videoTexture.video.focus();
    this.videoTexture.video.onfullscreenchange = () => {
      if (document.fullscreenElement) {
        document.exitPointerLock();
      }
      if (!(this.videoTexture instanceof VideoTexture && this.videoTexture.video)) {
        return;
      }
      !document.fullscreenElement && this.videoTexture.video.remove();
      if (!this.videoTexture.video.paused && !this.videoTexture.video.ended) {
        const timeout = setTimeout(() => {
          if (!(this.videoTexture instanceof VideoTexture && this.videoTexture.video)) {
            return;
          }
          this.videoTexture.video.play();
          clearTimeout(timeout);
        }, 10);
      }
    };
  }

  onTextureLoad(texture: Texture | AnimatedGifTexture | VideoTexture, silent: boolean, resize = false) {
    this.scene.onAfterRenderObservable.addOnce(() => {
      if (!this.renderable.helper || !this.dimensions) {
        return;
      }
      const boundingBox = this.renderable.helper.getBoundingInfo().boundingBox;
      const boundingHeight = boundingBox.maximumWorld.y ? boundingBox.extendSize.y : boundingBox.extendSize.z;
      const boundingWidth = boundingBox.extendSize.x;
      const rotation = this.renderable.helper.absoluteRotationQuaternion;
      this._scaledWidth = this.renderable.helper.absoluteScaling
        .multiplyByFloats(boundingWidth, 0, 0)
        .applyRotationQuaternion(rotation)
        .length();
      this._scaledHeight = this.renderable.helper.absoluteScaling
        .multiplyByFloats(0, boundingHeight, 0)
        .applyRotationQuaternion(rotation)
        .length();

      this.hostMaterial.diffuseTexture = texture;
      if ((texture instanceof Texture && texture.url?.endsWith('.png')) || texture instanceof AnimatedGifTexture) {
        this.hostMaterial.diffuseTexture.hasAlpha = true;
        this.hostMaterial.useAlphaFromDiffuseTexture = true;
      }

      (resize || !silent) && this.fitToMedia(silent);

      if (this.descriptor.type === FileType.Video && !this.isLoaded && this.renderable.helper) {
        this.renderable.loadButtonFront.scaling.copyFrom(Vector3.One().divideInPlace(this.renderable.helper.absoluteScaling));
        if ((this.renderable.element as TScreenElement).parameters.doubleSided) {
          this.renderable.loadButtonBack.scaling.copyFrom(Vector3.One().divideInPlace(this.renderable.helper.absoluteScaling));
        }
      }
      if (
        this.renderable.viewManager.tool instanceof SelectTool &&
        this.renderable.viewManager.appService.getSelectedElements().find(({ id }) => id === this.renderable.element.id)
      ) {
        this.renderable.viewManager.tool.update();
      }

      if (texture instanceof VideoTexture && texture.video) {
        texture.video.addEventListener('canplay', this.onCanPlay);
        texture.video.addEventListener('play', this.onPlay);
        texture.video.addEventListener('pause', this.onPause);
        texture.video.addEventListener('volumechange', this.onVolumeChange);
      }
    });
  }

  onCanPlay() {
    if (!this.videoTexture?.video) {
      return;
    }
    this.videoTexture.video.currentTime = 0;
    if ((this.descriptor.autoplay && this.renderable.viewManager.targetMode === ViewMode.Player) || !this.renderable._loadButtonFront) {
      const timeout = setTimeout(() => {
        this.play();
        clearTimeout(timeout);
      }, 0);
    } else {
      const timeout = setTimeout(() => {
        this.stop();
        clearTimeout(timeout);
      }, 0);
    }
    if (this.isLoaded && (!this.videoTexture.video.videoWidth || !this.videoTexture.video.videoHeight)) {
      this.imageTexture = new Texture(SOUND_ONLY_PNG, this.renderable.viewManager.scene, undefined, undefined, undefined, () => {
        this.onTextureLoad(this.imageTexture as Texture, true);
      });
    }
    this.videoTexture.video.loop = !!this.descriptor.repeat;
    this.videoTexture.video.removeEventListener('canplay', this.onCanPlay);
    this.update(this.descriptor);
  }

  onPlay() {
    this.renderable._controllerFront?.getButton(MediaControl.Play)?.setEnabled(false);
    this.renderable._controllerFront?.getButton(MediaControl.Pause)?.setEnabled(true);
    this.renderable._controllerBack?.getButton(MediaControl.Play)?.setEnabled(false);
    this.renderable._controllerBack?.getButton(MediaControl.Pause)?.setEnabled(true);
    this.renderable.assignOverInteraction(this.overInteractionCallback);
    this.renderable.assignOutInteraction(this.outInteractionCallback);
  }

  onPause() {
    this.renderable._controllerFront?.getButton(MediaControl.Play)?.setEnabled(true);
    this.renderable._controllerFront?.getButton(MediaControl.Pause)?.setEnabled(false);
    this.renderable._controllerBack?.getButton(MediaControl.Play)?.setEnabled(true);
    this.renderable._controllerBack?.getButton(MediaControl.Pause)?.setEnabled(false);
    this.renderable.showControls();
  }

  overInteractionCallback() {
    if (this.descriptor?.type !== FileType.Video || !this.isLoaded) {
      return;
    }
    clearTimeout(this.controllerTimeout);
    this.renderable.showControls();
  }

  outInteractionCallback() {
    if (this.descriptor?.type !== FileType.Video || !this.isLoaded) {
      return;
    }
    this.controllerTimeout = setTimeout(() => {
      !this.videoTexture?.video?.paused && !this.videoTexture?.video?.ended && this.renderable.hideControls();
      clearTimeout(this.controllerTimeout);
    }, CONTROLLER_TIMEOUT);
  }

  onInteract() {
    (this.videoTexture?.video.paused || this.videoTexture?.video.ended) && this.play();
    window.removeEventListener('click', this.onInteract);
    window.removeEventListener('touchstart', this.onInteract);
  }

  onVolumeChange() {
    [
      this.renderable._controllerFront?.getButton(MediaControl.SoundMax),
      this.renderable._controllerBack?.getButton(MediaControl.SoundMax),
    ].forEach((button) => {
      if (button) {
        if (this.videoTexture.video.muted || this.videoTexture.video.volume === 0) {
          button.material = this.renderable.viewManager.getMediaButtonMaterial(MediaControl.Unmute);
          return;
        } else if (this.videoTexture.video.volume < 0.1) {
          button.material = this.renderable.viewManager.getMediaButtonMaterial(MediaControl.SoundZero);
        } else if (this.videoTexture.video.volume < 0.5) {
          button.material = this.renderable.viewManager.getMediaButtonMaterial(MediaControl.SoundMin);
        } else {
          button.material = this.renderable.viewManager.getMediaButtonMaterial(MediaControl.SoundMax);
        }
      }
      this.renderable.setVolume(this.videoTexture.video.volume);
    });
  }

  fitToMedia(silent: boolean) {
    if (!this.renderable.helper) {
      return;
    }
    const newBoundingWidth = (this.dimensions.width * this._scaledHeight) / this.dimensions.height;
    this._scalingFactor.set(newBoundingWidth / this._scaledWidth, 1, 1);
    this._scalingFactor.fromArray(this._scalingFactor.asArray().map((t) => Number(t.toFixed(2))));

    this.renderable.viewManager.appService.updateViewElement(
      {
        ...this.renderable.element,
        parameters: {
          ...this.renderable.element.parameters,
          scaling: this.renderable.helper.scaling.multiply(this._scalingFactor).asArray() as TScreenElement['parameters']['scaling'],
        },
      },
      silent,
    );
  }

  updateDurationBar() {
    if (!this.videoTexture.video.paused && !this.videoTexture.video.ended && this.renderable._controllerFront) {
      this.renderable._controllerFront.durationBar.scaling.x = this.videoTexture.video.currentTime / this.videoTexture.video.duration;
      if (this.renderable._controllerBack) {
        this.renderable._controllerBack.durationBar.scaling.x = this.renderable._controllerFront.durationBar.scaling.x;
      }
    }
  }

  dispose() {
    if (this.videoTexture) {
      this.videoTexture.video.removeEventListener('canplay', this.onCanPlay);
      this.videoTexture.video.removeEventListener('play', this.onPlay);
      this.videoTexture.video.removeEventListener('pause', this.onPause);
      this.videoTexture.video.removeEventListener('volumechange', this.onVolumeChange);
      let securityCounter = 100;
      while (!!securityCounter && this.videoTexture.video?.lastChild) {
        this.videoTexture.video?.removeChild?.(this.videoTexture.video.lastChild);
        securityCounter--;
      }
      this.videoTexture.video.removeAttribute('src');
    }
    window.removeEventListener('click', this.onInteract);
    window.removeEventListener('touchstart', this.onInteract);
    this.beforeRenderObserver && this.scene.onBeforeRenderObservable.remove(this.beforeRenderObserver);
    this.afterMatrixObserver && this.renderable.helper.onAfterWorldMatrixUpdateObservable.remove(this.afterMatrixObserver);
    this.imageTexture?.dispose();
    this.videoTexture?.dispose();
    this.hostFront?.dispose();
    this.hostBack?.dispose();
    this.hostFront = null;
    this.hostBack = null;
  }
}
