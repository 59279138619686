<div class="header">
  {{ 'shared.information.updateLogTitle' | translate }}
  <div class="close icon icon-close sm" uiAutofocus (click)="close()"></div>
</div>
<div class="beta">
  <span class="bold text-white"> {{ 'shared.information.betaInformation1' | translate }}</span>
  <span class="highlight bold">{{ 'shared.information.betaInformation2' | translate }}</span>
  <span> {{ 'shared.information.betaInformation3' | translate }}</span>
</div>
<div class="data">
  @for (item of releaseLogData$ | async; track item; let i = $index) {
    <section class="item">
      <div class="title">{{ i + 1 }}. {{ item.Title[currentLanguage] | translate }}</div>
      <div class="description">{{ item.Description[currentLanguage] | translate }}</div>
    </section>
  }
</div>
<ul class="information">
  <li>
    <span>{{ 'shared.information.updateLogInformation2' | translate }} </span>
    <span class="link" (click)="goToLogs($event)">{{ 'shared.information.updateLogInformation3' | translate }}</span>
    <span>{{ 'shared.information.updateLogInformation4' | translate }}</span>
  </li>
</ul>
<div class="checkbox">
  <ui-check-box class="outlined-grey xs" [checked]="checkboxChecked" (checkedChange)="checkboxChecked = !checkboxChecked">{{
    'guideModal.dontShowAgain' | translate
  }}</ui-check-box>
</div>
