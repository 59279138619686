<ng-template #panel [ngTemplateOutlet]="panel" let-account="account" [ngTemplateOutletContext]="{ account: account$ | async }">
  <div class="container" (click)="$event.stopPropagation()">
    <div class="icon-wrapper" (click)="onClose()">
      <div class="icon icon-close xs"></div>
    </div>
    <section class="user-info">
      @if (!account) {
        <div class="user-thumbnail no-account">
          @if (!(account$ | async)?.Thumbnail) {
            <div class="icon icon-account primary"></div>
          }
        </div>
        <div class="login-section">
          <button ui-outlined-button xs (click)="onLogin()">{{ 'header.accountPanel.login' | translate }}</button>
          <button ui-outlined-button xs (click)="onSignUp()">{{ 'header.accountPanel.signUp' | translate }}</button>
        </div>
      } @else {
        <input type="file" id="user-thumbnail" (change)="uploadFile($event)" />
        <div class="thumbnail-wrapper">
          @if (!account?.Thumbnail && !fileUploading) {
            <div class="user-thumbnail">
              <div class="icon icon-account primary xl"></div>
            </div>
          }
          @if (account?.Thumbnail && !fileUploading) {
            <img class="user-thumbnail" [src]="account?.Thumbnail" />
          }
          @if (fileUploading) {
            <div class="user-thumbnail">
              <ui-loader></ui-loader>
            </div>
          }
          <div class="edit-btn" (click)="openDetails('account-info')">{{ 'shared.actions.edit' | translate }}</div>
        </div>
        <div class="username">{{ account.Username || '-' }}</div>
        @if (hasEnterprise) {
          <div class="account-options">
            <div class="current-account-label">
              @if (myAccountOption?.Type === 'individual') {
                <span>
                  @if (account?.IdentityProvider?.includes('google')) {
                    <span class="icon icon-google"></span>
                  }
                  @if (account?.IdentityProvider?.includes('facebook')) {
                    <span class="icon icon-facebook2"></span>
                  }
                  @if (account?.IdentityProvider?.includes('apple')) {
                    <span class="icon icon-apple"></span>
                  }
                </span>
              }
              @if (myAccountOption) {
                <span class="account-name">
                  {{ myAccountOption.Name }}
                </span>
              }
            </div>
            <ng-select
              class="small"
              [searchable]="false"
              [clearable]="false"
              [items]="accountOptions"
              [ngModel]="myAccountOption?.OptionId"
              (ngModelChange)="changeAccount($event)"
              [bindLabel]="'Name'"
              [bindValue]="'OptionId'"
              [trackByFn]="trackByFn"
              [disabled]="isAccountOptionDropdownDisabled"
              uiTooltip="{{
                (isAccountOptionDropdownDisabled ? 'header.accountPanel.tooltip.disabledAccountOptionChange' : '') | translate
              }}"
              [tooltipPosition]="toolTipPositions.Below"
              tooltipClasses="tooltip"
            >
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div class="select-option-wrapper">
                  <span class="username">
                    {{ item.Type === accountOptionType.Individual ? ('collaborator.individual' | translate) : item.Name }}
                  </span>
                  <span class="email">
                    {{ account.DisplayName }}
                  </span>
                </div>
              </ng-template>
            </ng-select>
          </div>
        } @else {
          <div class="email">
            @if (account?.IdentityProvider?.includes('google')) {
              <span class="icon icon-google"></span>
            }
            @if (account?.IdentityProvider?.includes('facebook')) {
              <span class="icon icon-facebook2"></span>
            }
            @if (account?.IdentityProvider?.includes('apple')) {
              <span class="icon icon-apple"></span>
            }
            {{ (email$ | async) || '-' }}
          </div>
        }
      }
    </section>
    <section class="system-message">{{ 'header.accountPanel.systemMessage' | translate }}</section>
    <section class="actions-menu">
      <div class="action-item" [class.disabled]="!account" (click)="openDetails('account-info')">
        <div class="left">
          @if (!account) {
            <div class="icon icon-lock"></div>
          }
          {{ 'header.accountPanel.actions.manage' | translate }}
        </div>
        <div class="right">
          <div class="icon icon-arrow-right"></div>
        </div>
      </div>
      <div class="action-item disabled" (click)="openDetails('notifications')">
        <div class="left">
          @if (!account) {
            <div class="icon icon-lock"></div>
          }
          {{ 'header.accountPanel.actions.notifications' | translate }}
          @if (unviewedNotificationsNumber$ | async) {
            <div class="notification-dot"></div>
          }
        </div>
        <div class="right">
          <div class="icon icon-arrow-right"></div>
        </div>
      </div>
      <div class="action-item disabled" [class.disabled]="!account" (click)="onRedirect('purchaseHistory')">
        <div class="left">
          @if (!account) {
            <div class="icon icon-lock"></div>
          }
          {{ 'header.accountPanel.actions.purchaseHistory' | translate }}
        </div>
        <div class="right">
          <div class="icon icon-arrow-right-body"></div>
        </div>
      </div>
      <div class="action-item disabled" [class.disabled]="!account" (click)="onRedirect('subscriptionManagement')">
        <div class="left">
          @if (!account) {
            <div class="icon icon-lock"></div>
          }
          {{ 'header.accountPanel.actions.subscriptionManagement' | translate }}
        </div>
        <div class="right">
          <div class="icon icon-arrow-right-body"></div>
        </div>
      </div>
      <div class="action-item disabled">
        <div class="left">
          <div class="icon icon-lock"></div>
          {{ 'header.accountPanel.actions.graphicsSettings' | translate }}
        </div>
        <div class="right">
          <div class="icon icon-arrow-right"></div>
        </div>
      </div>
      <div class="action-item" (click)="openDetails('language')">
        <div class="left">{{ 'header.accountPanel.actions.language' | translate }}</div>
        <div class="right">{{ ((currentLang$ | async) === languageCode.English ? 'shared.english' : 'shared.korean') | translate }}</div>
      </div>
    </section>
    <section class="footer">
      @if (account) {
        <div class="logout">
          <button ui-outlined-button tabindex="-1" xs (click)="onLogout()">{{ 'header.accountPanel.footer.logout' | translate }}</button>
        </div>
      }
      @if (account && latestReleaseVersion$ | async; as version) {
        <div class="release-log">
          <span tabindex="-1" (click)="openDetails('release-log')"
            >{{ 'header.accountPanel.footer.releaseLog' | translate }} <span class="version">{{ version }}</span></span
          >
        </div>
      }
      <div class="legal-info">
        <div class="terms" (click)="showTermsOfUse()">{{ 'header.accountPanel.footer.terms' | translate }}</div>
        <div class="privacy-policy" (click)="showPrivacyPolicy()">{{ 'header.accountPanel.footer.privacyPolicy' | translate }}</div>
      </div>
    </section>
  </div>
  <ui-account-panel-details-wrapper [class.opened]="isDetailsPanelOpened$ | async">
    <div header>
      @if ((openedDetails$ | async) === 'account-info') {
        <span>{{ 'header.accountPanel.actions.manage' | translate | uppercase }}</span>
      }
      @if ((openedDetails$ | async) === 'security') {
        <span>{{ 'header.accountPanel.actions.securitySettings' | translate | uppercase }}</span>
      }
      @if ((openedDetails$ | async) === 'withdrawal') {
        <span>{{ 'header.accountPanel.securitySettings.withdrawal.title' | translate | uppercase }}</span>
      }
      @if ((openedDetails$ | async) === 'notifications') {
        <span>{{ 'header.accountPanel.actions.notifications' | translate | uppercase }}</span>
      }
      @if ((openedDetails$ | async) === 'notification-details') {
        <span>{{ 'header.accountPanel.actions.notifications' | translate | uppercase }}</span>
      }
      @if ((openedDetails$ | async) === 'language') {
        <span>{{ 'header.accountPanel.actions.language' | translate | uppercase }}</span>
      }
      @if ((openedDetails$ | async) === 'release-log') {
        <span>{{ 'header.accountPanel.actions.releaseLog' | translate | uppercase }}</span>
      }
    </div>
    @if ((openedDetails$ | async) === 'account-info') {
      <ui-account-panel-user-info></ui-account-panel-user-info>
    }
    @if ((openedDetails$ | async) === 'security') {
      <ui-account-panel-security></ui-account-panel-security>
    }
    @if ((openedDetails$ | async) === 'withdrawal') {
      <ui-withdrawal></ui-withdrawal>
    }
    @if ((openedDetails$ | async) === 'notifications') {
      <ui-account-panel-notifications></ui-account-panel-notifications>
    }
    @if ((openedDetails$ | async) === 'notification-details') {
      <ui-notification-details></ui-notification-details>
    }
    @if ((openedDetails$ | async) === 'language') {
      <ui-account-panel-language></ui-account-panel-language>
    }
    @if ((openedDetails$ | async) === 'release-log') {
      <ui-account-panel-release-log></ui-account-panel-release-log>
    }
  </ui-account-panel-details-wrapper>
</ng-template>
