import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Access, FileStorageType } from '@/data/src/lib/enums/access';
import { FileService } from '@/data/src/lib/services/file.service';
import { ContextMenuItem } from '../../../components/context-menu/context-menu.component';
import { ApplicationService } from '@/view/src/app/app.service';
import { ModalService } from '../../modal.service';
import { SaveVideoComponent } from './save-video/save-video.component';
import { PopupType, TInteractableElement } from '@/data/src/lib/view-manager';
import { FileReference } from '@/data/src/lib/models/data/file-reference';
import { groupData } from '@/data/src/lib/utils/collection';
import { FileType, MediaType } from '@/data/src/lib/enums/file-type';
import { DragDropController, SpaceToolColor, SpaceToolItem, SpaceToolLayout } from '../../../utils/dragDropController';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { ScenePlan } from '@/data/src/lib/enums/pricing-plan';
import { XRScene } from '@/data/src/lib/models/data/scene';

export interface VideoToolItem extends SpaceToolItem {
  videoUrl: string;
  positions: { [key: string]: any };
  yLink?: '';
  thumbnail: string;
  isYouTube?: boolean;
  iframeSrc?: string;
}

@Component({
  selector: 'ui-video-tool',
  templateUrl: './video-tool.component.html',
  styleUrls: ['./video-tool.component.scss'],
  providers: [ModalService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoToolComponent extends DragDropController implements OnInit, OnDestroy {
  spaceLayouts = [
    {
      icon: 'icon icon-vertical-align',
      value: SpaceToolLayout.EditorBelow,
    },
    {
      icon: 'icon icon-horizontal-align',
      value: SpaceToolLayout.EditorRight,
    },
    {
      icon: 'icon icon-full-align',
      value: SpaceToolLayout.NoEditor,
    },
  ];
  title = '';
  data: { [key: string]: any };
  videoCount: number;
  videoLoading = false;

  modalRef: ModalService;
  selectedElement?: TInteractableElement;
  containerWidth = 1200;
  plan = ScenePlan;

  @ViewChild('container') container: ElementRef<HTMLDivElement>;
  @ViewChild('popupWrapper') popupWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('ytLink') ytLink: ElementRef<HTMLInputElement>;

  defaultColor = { background: '#FFFFFF', text: '#222c36', isSelected: true };

  videos: VideoToolItem[] = [
    {
      videoUrl: '',
      name: '',
      description: '',
      altText: '',
      layout: SpaceToolLayout.EditorBelow,
      color: { ...this.defaultColor },
      showContextMenu: false,
      showAltText: false,
      positions: { ...this.defaultPosition },
      yLink: '',
      thumbnail: '',
    },
    {
      videoUrl: '',
      name: '',
      description: '',
      altText: '',
      layout: SpaceToolLayout.EditorBelow,
      color: { ...this.defaultColor },
      showContextMenu: false,
      showAltText: false,
      positions: { ...this.defaultPosition },
      yLink: '',
      thumbnail: '',
    },
    {
      videoUrl: '',
      name: '',
      description: '',
      altText: '',
      layout: SpaceToolLayout.EditorBelow,
      color: { ...this.defaultColor },
      showContextMenu: false,
      showAltText: false,
      positions: { ...this.defaultPosition },
      yLink: '',
      thumbnail: '',
    },
    {
      videoUrl: '',
      name: '',
      description: '',
      altText: '',
      layout: SpaceToolLayout.EditorBelow,
      color: { ...this.defaultColor },
      showContextMenu: false,
      showAltText: false,
      positions: { ...this.defaultPosition },
      yLink: '',
      thumbnail: '',
    },
    {
      videoUrl: '',
      name: '',
      description: '',
      altText: '',
      layout: SpaceToolLayout.EditorBelow,
      color: { ...this.defaultColor },
      showContextMenu: false,
      showAltText: false,
      positions: { ...this.defaultPosition },
      yLink: '',
      thumbnail: '',
    },
  ];

  contextMenuOptions: ContextMenuItem[] = [{ name: 'oxr.creatingSpace.altText', shortcut: '', value: 'altText' }];
  contextMenuPosition: { x: number; y: number } = { x: 800, y: 800 };
  contextMenuValue: ContextMenuItem | null = null;

  isPreviewMode = false;
  isReadonly = false;

  currentPage = 1;
  autoplay = true;

  _isScrolling = false;
  mediaVideos!: FileReference[];
  uploadFileType = FileType.Video;

  get videoRange() {
    return this.isPreviewMode || this.isReadonly ? new Array(this.videoCount) : new Array(5);
  }

  get currentVideo() {
    return this.videos[this.currentPage - 1];
  }

  get videosWithContent() {
    return this.videos.filter((img) => img.videoUrl);
  }

  get displayedVideos() {
    return this.isPreviewMode || this.isReadonly ? this.videosWithContent : this.videos;
  }

  get canScrollLeft() {
    return this.currentPage > 1 && !this._isScrolling;
  }

  get canScrollRight() {
    return this.currentPage < this.displayedVideos.length && !this._isScrolling;
  }

  get isVideoUploaded() {
    return !!this.videos.some((img) => !!img.videoUrl);
  }

  get scenePlan() {
    const currentScene = this._appService.getActiveModel() as XRScene;
    return currentScene?.Plan;
  }

  constructor(
    private _modalService: ModalService,
    private _appService: ApplicationService,
    private _cd: ChangeDetectorRef,
    public fileService: FileService,
    private _sceneService: SceneService,
    _ngZone: NgZone,
  ) {
    super(_ngZone);
  }

  ngOnInit(): void {
    if (this.data['title']) {
      this.title = this.data['title'];
    }
    this.autoplay = this.data['auto-slide'];

    for (let i = 0; i < this.videoCount; i++) {
      if (this.videos[i]) {
        this.videos[i].videoUrl = this.data['video' + i];
        this.videos[i].description = this.data['description' + i];
        this.videos[i].altText = this.data['alt-text' + i];
        this.videos[i].layout = this.data['layout' + i] || SpaceToolLayout.EditorBelow;
        this.videos[i].color.background = this.data['background-colour' + i] || this.defaultColor.background;
        this.videos[i].color.text = this.data['text-colour' + i] || this.defaultColor.text;
        this.videos[i].thumbnail = this.data['thumbnail' + i] || null;
        this.videos[i].isYouTube = this.videos[i].videoUrl?.includes('youtube.com/');
        this.videos[i].iframeSrc = this.videos[i].videoUrl
          ? `${this.videos[i].videoUrl}${this.videos[i].videoUrl.includes('?') ? '&' : '?'}autoplay=${!!this.autoplay}`
          : undefined;
        if (this.data['positions' + i]['position1'] !== undefined) {
          this.videos[i].positions = this.data['positions' + i];
        }
      }
    }
    this.fileService.data$.subscribe((res) => {
      const files = groupData(res, 'FileType');
      this.mediaVideos = files.get(FileType.Video) ?? [];
      this._cd.detectChanges();
    });
  }

  next(): void {
    const pastedUrl = this.ytLink.nativeElement.value;
    let videoId: string | null = null;
    if (pastedUrl.includes('youtu.be')) {
      videoId = pastedUrl.split('/').pop() || '';
    } else {
      let params = new URL(pastedUrl).searchParams;
      videoId = params.get('v');
    }
    this.currentVideo.videoUrl = 'https://www.youtube.com/embed/' + videoId;
  }

  ngOnDestroy(): void {}

  close() {
    this.modalRef.close(false);
  }

  isColorSelected(color: SpaceToolColor) {
    return this.currentVideo.color.background === color.background;
  }

  selectColor(color: SpaceToolColor) {
    this.currentVideo.color = color;
  }

  previousItem() {
    if (this.canScrollLeft) {
      const toPage = this.currentPage - 1;
      this.scrollToPage(toPage);
      this.currentPage -= 1;
      this._cd.detectChanges();
    }
  }

  nextItem() {
    if (this.canScrollRight) {
      const toPage = this.currentPage + 1;
      this.scrollToPage(toPage);
      this.currentPage += 1;
      this._cd.detectChanges();
    }
  }

  enterPreviewMode() {
    this.isPreviewMode = true;
    this.currentPage = 1;
    this.scrollToPage(this.currentPage);
  }

  scrollToPage(pageNumber, behavior: ScrollBehavior = 'smooth') {
    this._isScrolling = true;
    const left = this.containerWidth * (pageNumber - 1);
    this.container.nativeElement.scrollTo({ left, top: 0, behavior });
    this._isScrolling = false;
  }

  onFileDrop(fileList: FileList) {
    if (fileList[0]?.type === 'video/mp4' && fileList[0]?.size <= 100000000) {
      this.currentVideo.name = fileList[0].name;
      this.uploadFile(fileList[0]);
    }
  }

  onFileInputChange(event: any) {
    if (event.target.files[0]?.type === 'video/mp4' && event.target.files[0]?.size <= 100000000) {
      this.currentVideo.name = event.target.files[0].name;
      this.uploadFile(event.target.files[0]);
    }
  }

  async uploadFile(file: File) {
    const currentVideo = this.currentVideo;
    this.videoLoading = true;
    const { url } = await this.fileService.uploadFile(
      file,
      FileStorageType.Public,
      undefined,
      file.name,
      true,
      FileType.Video,
      this._sceneService.getSelected()?.Id,
      Access.Public,
      MediaType.MediaTab,
    );
    currentVideo.videoUrl = url;
    this.videoLoading = false;
    this._cd.markForCheck();
  }

  onContextMenu(event, image) {
    event.preventDefault();
    event.stopPropagation();
    const { left, top } = this.popupWrapper.nativeElement.getBoundingClientRect();
    this.contextMenuPosition = { x: event.x - left, y: event.y - top };
    this.currentVideo.showContextMenu = true;
  }

  onContextMenuSelect(item: ContextMenuItem) {
    this.contextMenuValue = item;
    this.currentVideo.showContextMenu = false;
  }

  save() {
    const modalRef = this._modalService.open(SaveVideoComponent);
    modalRef.instance.modalRef = this._modalService;
    modalRef.instance.selectedSlider = this.data['auto-slide'];
    modalRef.result.then((result) => {
      if (result) {
        this.data = {};
        this.data['title'] = this.title;
        this.data['auto-slide'] = result === 'auto' ? true : false;
        this.videosWithContent.forEach((video, i) => {
          this.data['video' + i] = video.videoUrl;
          this.data['description' + i] = video.description;
          this.data['alt-text' + i] = video.altText;
          this.data['layout' + i] = video.layout;
          this.data['background-colour' + i] = video.color.background;
          this.data['text-colour' + i] = video.color.text;
          this.data['positions' + i] = video.positions;
          this.data['thumbnail' + i] = video.thumbnail;
        });
        const oldData = this.selectedElement?.parameters?.popups?.length ? this.selectedElement.parameters.popups[0] : {};
        this.selectedElement &&
          this._appService.updateViewElement(
            {
              ...this.selectedElement,
              parameters: {
                ...this.selectedElement.parameters,
                popups: [
                  {
                    ...oldData,
                    type: PopupType.Video,
                    parameters: [...Object.entries(this.data).map(([key, value]) => ({ key, value }))],
                  },
                ],
              },
            } as TInteractableElement,
            true,
          );
        this.modalRef.close(this.data);
      }
    });
  }

  setCurrentVideoBackgroundColor(color: string) {
    this.videos[this.currentPage - 1].color.background = color;
  }

  setCurrentVideoTextColor(color: string) {
    this.videos[this.currentPage - 1].color.text = color;
  }

  setCurrentVideoSpaceLayout(layout: SpaceToolLayout) {
    this.videos[this.currentPage - 1].layout = layout;
    this.videos[this.currentPage - 1].positions = { ...this.defaultPosition };
  }
  setCurrentVideoAltValue(input) {
    if (this.contextMenuValue) {
      switch (this.contextMenuValue.value) {
        case 'altText':
          this.videos[this.currentPage - 1].altText = input.value;
          break;
        default:
      }
      this.contextMenuValue = null;
    }
  }

  cancelAltInput() {
    this.contextMenuValue = null;
  }

  loadVideo(asset: FileReference) {
    const currentVideo = this.currentVideo;
    this.videoLoading = true;
    currentVideo.videoUrl = asset.Url;
    currentVideo.thumbnail = asset.Thumbnail;
    this.videoLoading = false;
    this._cd.markForCheck();
  }
}
