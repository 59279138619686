import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-assets-placement-info',
  templateUrl: './assets-placement-info.component.html',
  styleUrls: ['./assets-placement-info.component.scss'],
})
export class AssetsPlacementInfoComponent implements OnInit {
  @Input() countOfUsedUploadedAssets = 0;
  @Input() uploadAssetUsageLimit = 0;

  constructor() {}

  ngOnInit(): void {}
}
