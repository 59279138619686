import { IModel, ModelType } from './base';
import { Permission as PermissionType } from '../../enums/permission';
import { privateFieldReplacer } from '../../utils/json-utils';
import { TCustomization, TEnvironmentElement, TObjectElement } from '../../view-manager';

export enum AssetType {
  Object = 'Object',
  Environment = 'Environment', // 'Background',
  // Discuss following
  Favicon = 'Favicon',
  Undefined = 'Undefined',
  // May remove the rest
  Geometry = 'Geometry',
  Placeholder = 'Placeholder',
  Stage = 'Stage',
  Landscape = 'Landscape',
}

/**
 * API Interface of an asset
 */
export interface IAsset extends IModel {
  Type: AssetType;
  Name: string;
  Tags: string;
  Thumbnail: string;
  Versions?: IAssetVersion[];
  ActiveVersionIds: string;
  Url: string;
  DateCreated: string;
  DateModified: string;
  Owner?: any;
}

export interface IAssetVersion extends IModel {
  Id: string;
  V: string;
  AssetId: string;
  Type: AssetType;
  Name: string;
  Thumbnail: string;
  Parameters: any;
  DateCreated: string;
  DateModified: string;
}

export type TEnvironmentParameters = {
  intensity: TEnvironmentElement['parameters']['intensity'];
  level: TEnvironmentElement['parameters']['level'];
  tint: TEnvironmentElement['parameters']['tint'];
  rotation: TEnvironmentElement['parameters']['rotation'];
};

export type TObjectParameters = {
  environment: TEnvironmentParameters;
  customization: TCustomization;
  position: TObjectElement['parameters']['position'];
  quaternion: TObjectElement['parameters']['quaternion'];
  scaling: TObjectElement['parameters']['scaling'];
};

/**
 * Defines an asset texture, to increase mesh loading time the texture images are stripped from the gltf json string and appended to the asset as a texture.
 */
export class XRAssetTexture {
  constructor(
    /**
     * Material name which the texture image belongs to
     */
    public MaterialName: string,
    /**
     * Url of texture image
     */
    public Url: string,
    /**
     * Texture type which determines which material texture to apply the image to
     */
    public TextureType: string,
    public Scale?: number,
    public TexCoord?: number,
  ) {}

  public MetallicFactor!: number;
  public RoughnessFactor!: number;
}

/**
 * Parameter based pre-made transformations and customizations for an asset
 */
export class XRAssetVersion implements IAssetVersion {
  private _asset!: XRAsset;
  public isDirty = false; // Integrate UUID into update

  constructor(
    public Id: string, // Asset version ID
    public V: string, //  Version number (#.#)
    public AssetId: string,
    public Type: AssetType,
    public Name: string,
    public Thumbnail: string,
    public Environment: TEnvironmentElement['parameters'],
    public Parameters: TEnvironmentParameters | TObjectParameters,
    public DateCreated: string,
    public DateModified: string,
  ) {}

  get ModelId(): string {
    return this.Id;
  }

  get ModelType() {
    return ModelType.AssetVersion;
  }

  get Readonly(): boolean {
    return true;
  }

  setId(id: string): void {
    this.Id = id;
  }

  toJson(): string {
    return JSON.stringify(this, privateFieldReplacer);
  }

  get asset() {
    return this._asset;
  }

  set asset(value: XRAsset) {
    this._asset = value;
  }
}

/**
 * The asset model which implements IViewableModel allowing it to be loaded in a view. Contains the mesh data and base definitions for a user defined asset which can be loaded directly or instanced and loaded into a scene
 */
export class XRAsset implements IAsset {
  public file!: File;
  private _isPublic: boolean;
  private _versions: XRAssetVersion[];
  private _textures: XRAssetTexture[] | undefined;
  private _dateCreated: string;
  private _dateModified: string;
  private _uri: string;

  constructor(
    public Type: AssetType,
    public Name: string,
    public Thumbnail: string,
    public Url: string,
    DateModified: string,
    DateCreated: string,
    public Id: string,
    public Tags: string,
    public Versions: IAssetVersion[],
    public ActiveVersionIds: string,
    public Owner?: {
      Id?: string;
      DisplayName?: string;
      Thumbnail?: string;
    },
    // public Permission?: PermissionType,
    // public CategoryId: string,
    // public Views: number = 0,
    // public Likes: number = 0,
    // public EnterpriseId?: string,
    // isPublic = false,
  ) {
    // this._isPublic = isPublic;
    this._dateCreated = DateCreated;
    this._dateModified = DateModified;
  }

  get ModelId(): string {
    return this.Id;
  }

  init: () => void = () => {};

  get Readonly(): boolean {
    return false;
  }

  get Textures(): XRAssetTexture[] | undefined {
    return this._textures;
  }

  set Textures(value: XRAssetTexture[] | undefined) {
    this._textures = value;
  }

  public get Mode(): number {
    return 1;
  }

  get ModelType() {
    return ModelType.Asset;
  }

  get IsPublic() {
    return !!this._isPublic;
  }

  get DateCreated() {
    return this._dateCreated;
  }

  get DateModified() {
    return this._dateModified;
  }

  /**
   * Model file as data string
   * @returns string i.e. data:{\"asset\":{\"generator\":\"URoom\", ...}}
   */
  get uri() {
    return this._uri;
  }

  set uri(value: string) {
    this._uri = value;
  }

  set versions(value: XRAssetVersion[]) {
    this._versions = value;
  }

  get versions() {
    return this._versions;
  }

  addTag(tag: string) {
    this.Tags = this.Tags ? `${this.Tags}, ${tag}` : tag;
  }

  setId(id: string): void {
    this.Id = id;
  }

  toJson() {
    return JSON.stringify(this, privateFieldReplacer);
  }
}
