import { Component, EventEmitter, Input, OnInit, Output, ElementRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, fromEvent, Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'ui-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  @Input() searchText!: string;
  @Input() placeholder = 'Search';
  @Input() error = false;
  @Input() onDebounceTime: number = 0;
  @Input() disabled = false;

  @Output() searchTextChange = new EventEmitter<string>();
  @Output() searchTextChangeDebounce = new EventEmitter<string>();
  @Output() searchTriggered = new EventEmitter<string>();

  input$: Observable<Event>;

  constructor(private _elementRef: ElementRef) {}

  ngOnInit(): void {
    if (this.onDebounceTime) {
      const input = this._elementRef.nativeElement.querySelector('input');
      this.input$ = fromEvent(input, 'input');
      this.input$
        .pipe(untilDestroyed(this), debounceTime(this.onDebounceTime), distinctUntilChanged())
        .subscribe(() => this.searchTextChangeDebounce.emit(this.searchText));
    }
  }

  onSearchTextChange(searchText: string) {
    this.searchTextChange.emit(searchText);
  }

  onSearchTriggered() {
    this.searchTriggered.emit(this.searchText);
  }
}
