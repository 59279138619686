<main>
  <div class="field">
    <span class="title">{{ 'oxr.settings.communityVisibility' | translate }}</span>
    <ui-switch
      [checked]="isAccessible"
      (changed)="onAccessibilityChange($event)"
      [disabled]="isScenePlanPublishPropertyRestriction"
    ></ui-switch>
  </div>
  <div class="field">
    <span class="title">{{ 'oxr.settings.chatOnOff' | translate }}</span>
    <ui-switch [checked]="chatOn" (changed)="chatOn = $event" [disabled]="isScenePlanChatPropertyRestriction"></ui-switch>
  </div>
</main>
<section class="footer">
  <button ui-button fixed (click)="onSave()">
    @if (isLoading) {
      <ui-loader sm [loaderColor]="'white'"></ui-loader>
    }
    @if (!isLoading) {
      <span>{{ 'shared.actions.save' | translate }}</span>
    }
  </button>
</section>
