export enum UserRoles {
  Enterprise = 'Enterprise',
  Admin = 'Admin',
  Application = 'Application',
  Settings = 'Settings',
  System = 'System',
}

export enum UserB2CAttribute {
  MarrketingConsent = 'marketingConsent',
  PrivacyPolicy = 'privacyPolicy',
  TermsOfUse = 'termsOfUse',
}
