<div class="popup-content-modal popup-video">
  <section class="modal-body">
    <div class="scroll-container" #container>
      <div class="carousel">
        @for (item of displayedVideos; track item; let i = $index) {
          <div class="carousel-item readonly" [style.backgroundColor]="item.color.background">
            <div
              class="layout-container drag-drop-container"
              [ngClass]="{
                'horizontal-layout': item.layout === layouts.EditorBelow,
                'vertical-layout': item.layout === layouts.EditorRight,
                'horizontal-layout-no-edit': item.layout === layouts.NoEditor
              }"
            >
              <div class="position-1" #position1>
                <ng-container
                  [ngTemplateOutlet]="
                    item.positions['position1'] === 'title'
                      ? titleTemplate
                      : item.positions['position1'] === 'content'
                        ? content
                        : item.positions['position1'] === 'description'
                          ? description
                          : null
                  "
                  [ngTemplateOutletContext]="{ $implicit: item, slot: 'position1', index: i }"
                ></ng-container>
              </div>
              @if (item.layout === layouts.EditorRight) {
                <div class="editor-right-container">
                  <ng-container [ngTemplateOutlet]="midContent"></ng-container>
                </div>
              }
              @if (item.layout !== layouts.EditorRight) {
                <ng-container [ngTemplateOutlet]="midContent"></ng-container>
              }
              <ng-template #midContent>
                <div class="position-2" #position2>
                  <ng-container
                    [ngTemplateOutlet]="
                      item.positions['position2'] === 'title'
                        ? titleTemplate
                        : item.positions['position2'] === 'content'
                          ? content
                          : item.positions['position2'] === 'description'
                            ? description
                            : null
                    "
                    [ngTemplateOutletContext]="{ $implicit: item, slot: 'position2', index: i }"
                  ></ng-container>
                </div>
                <div class="position-3" #position3>
                  <ng-container
                    [ngTemplateOutlet]="
                      item.positions['position3'] === 'title'
                        ? titleTemplate
                        : item.positions['position3'] === 'content'
                          ? content
                          : item.positions['position3'] === 'description'
                            ? description
                            : null
                    "
                    [ngTemplateOutletContext]="{ $implicit: item, slot: 'position3', index: i }"
                  ></ng-container>
                </div>
              </ng-template>
              <div class="position-4" #position4>
                <ng-container
                  [ngTemplateOutlet]="
                    item.positions['position4'] === 'title'
                      ? titleTemplate
                      : item.positions['position4'] === 'content'
                        ? content
                        : item.positions['position4'] === 'description'
                          ? description
                          : null
                  "
                  [ngTemplateOutletContext]="{ $implicit: item, slot: 'position4', index: i }"
                ></ng-container>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </section>
  <div class="close icon-wrapper" (click)="close()">
    <div class="icon icon-close sm"></div>
  </div>
</div>
<div class="pagination">
  @for (page of displayedVideos; track page; let i = $index) {
    <div class="page" [class.active]="currentPage === i + 1"></div>
  }
</div>
<ng-template #titleTemplate let-slot="slot" let-item>
  @if (!!title) {
    <div class="title-wrapper" [style.color]="item.color.text || 'transparent'">
      <div class="text" uiOverflowReadmore>
        {{ title }}
      </div>
    </div>
  }
</ng-template>
<ng-template #content let-item let-slot="slot" let-videoIndex="videoIndex">
  <div class="content drag-item">
    <div class="content-view-wrapper">
      @if (item.videoUrl?.includes('youtube.com/')) {
        <iframe
          class="video"
          [src]="item.videoUrl | safe: 'url'"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      }
      @if (item.videoUrl && !item.videoUrl.includes('youtube.com/')) {
        <ui-video-player class="video" [videoData]="item" [autoplay]="false"></ui-video-player>
      }
    </div>
  </div>
</ng-template>
<ng-template #description let-item let-slot="slot">
  @if (item.layout !== layouts.NoEditor && item.description) {
    <div class="editor-container drag-item description">
      <quill-view class="w-100 view-mode" [content]="item.description" [preserveWhitespace]="true"> </quill-view>
    </div>
  }
</ng-template>
