/* eslint-disable no-case-declarations */
import * as uuid from 'uuid';
import { Account } from '@/data/src/lib/models/data/account';
import { DomainMap } from '@/data/src/lib/models/data/domain';
import { UserScene } from '@/data/src/lib/models/data/user-scene';
import { FileReference } from '@/data/src/lib/models/data/file-reference';
import { EnterpriseAccount, EnterpriseContract } from '@/data/src/lib/models/data/enterprise';
import { IModel, ModelType } from '@/data/src/lib/models/data/base';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { XRAsset, XRAssetVersion } from '@/data/src/lib/models/data/asset';
import { SceneFile } from '@/data/src/lib/models/data/scene-file';
import { IAsset } from '@/data/src/lib/models/data/asset';

/**
 * Creates a new model from the input obj, when a model is retrieved from the backend it contains all the properties but not the internal methods or functions.
 * This methods converts the object to a native model and assigns the model type which is required for downstream data pipelines and serialisation.
 * @param obj the obj to convert to a native model
 * @param modelType model type of object
 * @param isClone creates a clone if the input model is already a native object
 * @returns the model
 */
export function newModel<T extends IModel>(obj: T, modelType: ModelType): T {
  switch (modelType) {
    case ModelType.Scene:
      const scene: XRScene = <XRScene>(<unknown>obj);
      return <T>(
        (<unknown>(
          new XRScene(
            scene.SceneElements,
            scene.Name ?? '',
            scene.Description ?? '',
            scene.Thumbnail,
            scene.Company,
            scene.BrandId,
            scene.ThemeId,
            scene.Id,
            scene.Tags ?? '',
            scene.DefaultVersion,
            scene.Plan,
            scene.Permission,
            scene.DateModified,
            scene.DateCreated,
            scene.Username,
            scene.SceneId,
            scene.ReferencedByteLength,
            scene.Likes,
            scene.Views,
            scene.Favicon,
            scene.Users,
            scene.IsPublished,
            scene.IsAccessible,
            scene.CreatorUser ? scene.CreatorUser.Id : '',
            scene.CreatorUser,
            scene.Expires,
            scene.RecommendedSortOrder,
            scene.DisplayConcurrentUsers,
            scene.ChatOn,
            scene.Interactions,
          )
        ))
      );
    case ModelType.Asset:
      const asset: IAsset = <IAsset>(<unknown>obj);
      return <T>(<unknown>new XRAsset(
        asset.Type,
        asset.Name,
        asset.Thumbnail,
        asset.Url,
        asset.DateModified,
        asset.DateCreated,
        asset.Id,
        asset.Tags,
        asset.Versions ?? [],
        asset.ActiveVersionIds ?? '',
        asset.Owner,
        // asset.CategoryId,
        // asset.Length,
        // asset.Views,
        // asset.Likes,
        // asset.AssetId,
        // asset.EnterpriseId,
        // asset.IsPublic,
        // asset.Permission,
      ));
    case ModelType.AssetVersion:
      const assetVersion = <XRAssetVersion>(<unknown>obj);
      return <T>(
        (<unknown>(
          new XRAssetVersion(
            assetVersion.Id,
            assetVersion.V,
            assetVersion.AssetId,
            assetVersion.Type,
            assetVersion.Name,
            assetVersion.Thumbnail,
            assetVersion.Environment,
            assetVersion.Parameters,
            assetVersion.DateCreated,
            assetVersion.DateModified,
          )
        ))
      );
    case ModelType.UserScene:
      const userScene: UserScene = <UserScene>(<unknown>obj);
      const result = new UserScene(userScene.Id, userScene.SceneId, userScene.UserId, userScene.Permission);
      result.Username = userScene.Username;
      return <T>(<unknown>result);
    case ModelType.FileReference:
      const fileRef = <FileReference>(<unknown>obj);
      return <T>(
        (<unknown>(
          new FileReference(
            fileRef.Id,
            fileRef.Name,
            fileRef.Url,
            fileRef.Access,
            fileRef.FileType,
            fileRef.MimeType,
            fileRef.Length,
            fileRef.Thumbnail,
            fileRef.DateCreated,
            fileRef.MediaType,
          )
        ))
      );
    case ModelType.Account:
      const account = <Account>(<unknown>obj);
      return <T>(
        (<unknown>(
          new Account(
            account.Id,
            account.Username,
            account.Server,
            account.DisplayName,
            account.Thumbnail,
            account.IdentityProvider,
            account.UserRoles,
            account.CanCreate,
            account.CanEditContent,
            account.UserPlan,
            account.B2CAttribute ?? [],
          )
        ))
      );
    case ModelType.Enterprise:
      const payload = <EnterpriseAccount>(<unknown>obj);
      return <T>(<unknown>new EnterpriseAccount(payload));
    case ModelType.Domain:
      const domain = <DomainMap>(<unknown>obj);
      return <T>(
        (<unknown>(
          new DomainMap(
            domain.Path,
            domain.SceneId,
            domain.SslEnabled,
            domain.DomainType,
            domain.Id,
            domain.SceneModel,
            domain.DateCreated,
            domain.User,
            domain.UserId,
            domain.MobilePath,
            domain.Name,
            domain.MobileName,
            domain.VerificationStatus,
            domain.MobileVerificationStatus,
            domain.MobileSslEnabled,
            domain.Status,
            domain.MobileStatus,
            domain.DaysLeft,
          )
        ))
      );
    case ModelType.SceneFile:
      const sceneFile = <SceneFile>(<unknown>obj);
      return <T>(
        (<unknown>(
          new SceneFile(
            sceneFile.Id,
            sceneFile.userid,
            sceneFile.sceneId,
            sceneFile.fileReferenceId,
            sceneFile.scene,
            sceneFile.fileReference,
          )
        ))
      );
    case ModelType.EnterpriseContract:
      const enterpriseContract = <EnterpriseContract>(<unknown>obj);
      return <T>(
        (<unknown>(
          new EnterpriseContract(
            enterpriseContract.Id,
            enterpriseContract.DateCreated,
            enterpriseContract.EnterpriseId,
            enterpriseContract.Enterprise,
            enterpriseContract.NumberOfSpaces,
            enterpriseContract.ExpirationDateOfSpacesAfterDays,
            enterpriseContract.SpaceSavePointDiscardAfterDays,
            enterpriseContract.NumberOfCollaborators,
            enterpriseContract.MediaStorageCapacity,
            enterpriseContract.EditorCapacity,
          )
        ))
      );
    default:
      obj.ModelType = modelType;
      return obj;
  }
}
