<div class="container" [ngClass]="layoutSize">
  <div class="title">
    {{ title }} {{ 'shared.actions.upload' | translate }}
    <div class="close icon icon-close" (click)="onClose()"></div>
  </div>
  @if (showMyCom) {
    <div class="myCom">
      <input type="file" #input (change)="uploadFileByDialog($event)" />
      <button ui-button xs (click)="input.click()">
        <pre>{{ 'shared.actions.myComputer' | translate }}</pre>
      </button>
    </div>
  }
  <ng-select
    class="custom"
    [(ngModel)]="kind"
    [searchable]="false"
    [clearable]="false"
    [items]="data"
    bindLabel="name"
    bindValue="assetType"
    (ngModelChange)="dropDownChanged()"
    uiAutofocus
  >
  </ng-select>
  <div class="image">
    @if (uploading) {
      <div class="upload-files">
        <ui-loader></ui-loader>
      </div>
    } @else {
      <div class="upload-files" uiFileDragDrop (fileDropped)="uploadFile($event)">
        <input type="file" #input (change)="uploadFileByDialog($event)" [accept]="selectedData?.extensions + ',.jpg'" />
        <div class="icon icon-bg-add-plus xl" (click)="input.click()"></div>
        <div class="upload-info" [innerHtml]="'shared.fields.uploadInfo' | translate"></div>
        @if (selectedData?.description) {
          <div class="description" [innerHtml]="selectedData?.description"></div>
        }
        <div class="info">
          @if (selectedData?.extensions) {
            <span>{{ 'shared.fields.format' | translate }} : {{ selectedData?.extensions }}</span>
          }
        </div>
        @if (selectedData?.customMessage) {
          <div class="info">{{ selectedData?.customMessage }}</div>
        }
      </div>
    }
    @if (error) {
      <div class="error-msg"><i class="icon-warning"></i>{{ error | translate }}</div>
    }
  </div>
  <div class="upload">
    <button
      ui-button
      [disabled]="uploading || uploadedData == undefined"
      [ngClass]="uploadedData ? 'primary' : 'secondary'"
      sm
      (click)="onUpload(uploadedData)"
    >
      {{ buttonName }}
    </button>
  </div>
</div>
