<div class="close icon icon-close sm" (click)="close()"></div>
<div class="title">
  <div class="text">{{ title }}</div>
</div>
<main>
  <div class="iframe-area">
    @if (link) {
      <iframe [src]="link | safe: 'resourceUrl'" frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe>
    }
  </div>
</main>
<div class="footer"></div>
