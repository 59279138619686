import {
  ActionManager,
  DynamicTexture,
  Engine,
  FloatArray,
  ICanvasRenderingContext,
  Mesh,
  MeshBuilder,
  Quaternion,
  StandardMaterial,
  VertexBuffer,
} from '../../babylon';
import {
  TEXT_BACKGROUND,
  TEXT_COLOR,
  TEXT_FONT_FAMILY,
  TEXT_FONT_SIZE,
  TEXT_FONT_WEIGHT,
  TEXT_LETTER_SPACING,
  TEXT_LINE_HEIGHT,
  TEXT_PADDING,
  TEXT_POSITION,
  TEXT_QUATERNION,
  TEXT_SCALING,
  ViewManager,
} from '..';
import { TElement, TTextElement, TextAlignment } from '../types';
import { Interaction } from '../auxiliaries/interaction';
import { deepCopy } from '../../utils/data';
import { ViewManagerUtils } from '../../utils/view-manager-utils';
import { Effect } from '../auxiliaries/effect';
import { InteractableRenderable } from './interactable-renderable';

const MAX_DIMENSION = 16383; // 16k - 1
const VERTEX_FACTOR = 1 / 216;

const SINGLE_UVS = [1, 0, 0, 0, 0, 1, 1, 1];
const DOUBLE_UVS = [1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 1, 0, 1, 1, 0, 1];

export class TextRenderable extends InteractableRenderable {
  public elementId: string;
  public helper: Mesh;
  public texture: DynamicTexture;
  public effect?: Effect;

  private overInteraction: Interaction;
  private outInteraction: Interaction;

  private _text: string;
  private _lines: string[] = [];
  private _font: string;
  private _size: number;
  private _lineHeight: number;
  private _letterSpacing: number;
  private _alignment: TextAlignment;
  private _doubleSided: boolean;
  private _context: ICanvasRenderingContext;
  private _width: number;
  private _height: number;
  private _vertices: FloatArray;
  private _vertexScale: [number, number, number];

  private _color: string;
  private _background: string;
  private _tempColor: string;
  private _tempBackground: string;
  private _heightFactor: number;
  private _lineHeightFactor: number;

  private _paddingTop: number;
  private _paddingRight: number;
  private _paddingBottom: number;
  private _paddingLeft: number;

  constructor(
    element: TTextElement,
    public viewManager: ViewManager,
  ) {
    super(element, viewManager);
    this.createHelper = this.createHelper.bind(this);
    this.updateElement = this.updateElement.bind(this);
    this.createHelper();
    this.update();
  }

  get element() {
    return this._element as TTextElement;
  }

  set element(payload) {
    this._element = deepCopy(payload) as TTextElement;
  }

  createHelper() {
    this._vertexScale = [1, 1, 1];
    this.helper = MeshBuilder.CreatePlane(
      `Plane-${this.element.id}`,
      { size: 1, sideOrientation: this._doubleSided ? Mesh.DOUBLESIDE : Mesh.FRONTSIDE }, //, frontUVs, backUVs },
      this.viewManager.scene,
    );
    this.helper.setVerticesData(VertexBuffer.UVKind, this._doubleSided ? DOUBLE_UVS : SINGLE_UVS);
    this._vertices = this.helper.getVerticesData(VertexBuffer.PositionKind)!;
    ViewManagerUtils.AttachMetadata(this.helper, { helperOf: this });
    this.helper.material = new StandardMaterial(`PlaneMaterial-${this.element.id}`, this.viewManager.scene);
    (this.helper.material as StandardMaterial).transparencyMode = Engine.ALPHA_MULTIPLY;
    (this.helper.material as StandardMaterial).backFaceCulling = true;

    this.overInteraction = new Interaction(this.viewManager.scene, this.helper, ActionManager.OnPointerOverTrigger, undefined, true);
    this.outInteraction = new Interaction(this.viewManager.scene, this.helper, ActionManager.OnPointerOutTrigger, undefined, true);

    this.helper.receiveShadows = true;
    this.viewManager.lights.forEach((light) => {
      light.update();
    });
  }

  splitText(lines: string[]) {
    const result: string[] = [];
    lines.forEach((line) => {
      let left = 0;
      let right = line.length;
      while (left < right) {
        const mid = Math.floor((left + right + 1) / 2);
        if (
          this.viewManager.textMeasurementContext.measureText(line.slice(0, mid)).width + this._paddingLeft + this._paddingRight >
          MAX_DIMENSION
        ) {
          right = mid - 1;
        } else {
          left = mid;
        }
      }
      const part1 = line.slice(0, left);
      const part2 = line.slice(left);
      this._width = Math.max(
        this._width,
        this.viewManager.textMeasurementContext.measureText(part1).width + this._paddingLeft + this._paddingRight,
      );
      result.push(part1);
      if (part2) {
        result.push(...this.splitText([part2]));
      }
    });
    return result;
  }

  update(element?: TElement) {
    if (element) {
      this.element = deepCopy(element as TTextElement);
    }

    let dirty = false;

    if (this.element.parameters.doubleSided !== this._doubleSided) {
      this._doubleSided = this.element.parameters.doubleSided ?? true;
      this.dispose();
      this.createHelper();
      dirty = true;
    }

    if (
      dirty ||
      this.element.parameters.text !== this._text ||
      this.element.parameters.font !== this._font ||
      this.element.parameters.lineHeight !== this._lineHeight ||
      this.element.parameters.letterSpacing !== this._letterSpacing ||
      this.element.parameters.alignment !== this._alignment ||
      this.element.parameters?.padding?.[0] !== this._paddingTop ||
      this.element.parameters?.padding?.[1] !== this._paddingRight ||
      this.element.parameters?.padding?.[2] !== this._paddingBottom ||
      this.element.parameters?.padding?.[3] !== this._paddingLeft
    ) {
      dirty = true;

      this._font = this.element.parameters.font ?? [TEXT_FONT_WEIGHT, TEXT_FONT_SIZE, TEXT_FONT_FAMILY].join(' ');
      if (!document.fonts.check(this._font)) {
        document.fonts.load(this._font).then(() => {
          this.update();
        });
        return;
      }
      this._paddingTop = this.element.parameters?.padding?.[0] ?? TEXT_PADDING[0];
      this._paddingRight = this.element.parameters?.padding?.[1] ?? TEXT_PADDING[1];
      this._paddingBottom = this.element.parameters?.padding?.[2] ?? TEXT_PADDING[2];
      this._paddingLeft = this.element.parameters?.padding?.[3] ?? TEXT_PADDING[3];
      this._width = this._paddingLeft + this._paddingRight;

      this._size = Number(this._font.split(' ')[1].replace('px', ''));
      this._lineHeight = this.element.parameters.lineHeight ?? TEXT_LINE_HEIGHT;
      this._letterSpacing = this.element.parameters.letterSpacing ?? TEXT_LETTER_SPACING;
      this._alignment = this.element.parameters.alignment ?? TextAlignment.Left;

      // @ts-expect-error
      this.viewManager.textMeasurementContext.letterSpacing = `${this._letterSpacing}px`;
      this.viewManager.textMeasurementContext.font = this._font;
      this._text = this.element.parameters.text;
      this._lines.length = 0;
      this._lines.push(...this.splitText(this._text.split('\n')));

      this._heightFactor = this._size * 0.75;
      this._lineHeightFactor = (this._lineHeight * this._size) / 16;
      this._height = this._heightFactor + (this._lines.length - 1) * this._lineHeightFactor + this._paddingTop + this._paddingBottom;

      this._vertexScale[0] = this._width * VERTEX_FACTOR;
      this._vertexScale[1] = this._height * VERTEX_FACTOR;

      this.texture?.dispose();
      this.texture = new DynamicTexture(`Texture-${this.element.id}`, { width: this._width, height: this._height }, this.viewManager.scene);
      this.texture.hasAlpha = true;
      (this.helper.material as StandardMaterial).diffuseTexture = this.texture;
      (this.helper.material as StandardMaterial).emissiveTexture = this.texture;
      (this.helper.material as StandardMaterial).opacityTexture = this.texture;
      (this.helper.material as StandardMaterial).useAlphaFromDiffuseTexture = true;
      (this.helper.material as StandardMaterial).disableLighting = true;

      this.helper.setVerticesData(VertexBuffer.PositionKind, this._vertices);
      this.helper.setVerticesData(
        VertexBuffer.PositionKind,
        this.helper.getVerticesData(VertexBuffer.PositionKind)!.map((v: number, i: number) => v * this._vertexScale[i % 3]),
      );
    }

    this._tempColor = ViewManagerUtils.GetColorString(this.element.parameters.color);
    this._tempBackground = ViewManagerUtils.GetColorString(this.element.parameters.background);

    if (dirty || this._tempColor !== this._color || this._tempBackground !== this._background) {
      this._color = this._tempColor;
      this._background = this._tempBackground;
      this._context = this.texture.getContext();
      this._context.clearRect(0, 0, this._width, this._height);
      this._context.fillStyle = this._background ?? TEXT_BACKGROUND;
      // @ts-expect-error
      this._context.letterSpacing = `${this._letterSpacing}px`;
      this._context.fillRect(0, 0, this._width, this._height);

      this._lines.forEach((line, i) => {
        this.texture.drawText(
          line,
          this.getRightOffset(line),
          this._heightFactor + i * this._lineHeightFactor + this._paddingTop,
          this.element.parameters.font ?? [TEXT_FONT_WEIGHT, TEXT_FONT_SIZE, TEXT_FONT_FAMILY].join(' '),
          this._color ?? TEXT_COLOR,
          '#00000000',
          undefined,
        );
      });
    }

    this.helper.position.copyFromFloats(...(this.element.parameters.position ?? TEXT_POSITION));
    if (this.helper.rotationQuaternion) {
      this.helper.rotationQuaternion.copyFromFloats(...(this.element.parameters.quaternion ?? TEXT_QUATERNION));
    } else {
      this.helper.rotationQuaternion = Quaternion.FromArray(this.element.parameters.quaternion ?? TEXT_POSITION);
    }
    this.helper.scaling.copyFromFloats(...(this.element.parameters.scaling ?? TEXT_SCALING), 1);

    super.update();
  }

  getRightOffset(line: string): number | null {
    switch (this._alignment) {
      case TextAlignment.Center:
        return (
          (this._width - this.viewManager.textMeasurementContext.measureText(line).width + this._letterSpacing) / 2 +
          this._paddingLeft -
          this._paddingRight
        );
      case TextAlignment.Right:
        return this._width - this.viewManager.textMeasurementContext.measureText(line).width + this._letterSpacing - this._paddingRight;
      case TextAlignment.Left:
      default:
        return this._paddingLeft;
    }
  }

  updateElement() {
    this.viewManager.scene.onBeforeRenderObservable.addOnce(() => {
      this.viewManager.appService.updateViewElement({
        ...this.element,
        parameters: {
          ...this.element.parameters,
          font: this._font,
          alignment: this._alignment,
          lineHeight: this._lineHeight,
          letterSpacing: this._letterSpacing,
          padding: [this._paddingTop, this._paddingRight, this._paddingBottom, this._paddingLeft],
          position: [...this.helper.computeWorldMatrix(true).asArray().slice(12, 15)] as TTextElement['parameters']['position'],
          quaternion: [...this.helper.rotationQuaternion!.asArray()] as TTextElement['parameters']['quaternion'],
          scaling: this.helper.scaling!.asArray().slice(0, 2) as TTextElement['parameters']['scaling'],
        },
      });
    });
  }

  dispose() {
    super.dispose();
    this.viewManager.lights.forEach((light) => {
      light.shadowGenerator?.removeShadowCaster(this.helper);
    });
    this.overInteraction?.unregister();
    this.outInteraction?.unregister();
    this.texture?.dispose();
    this.helper?.material?.dispose();
    this.helper?.dispose();
  }
}
