import { Component, Input, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from '@ng-web-apis/common';
import { AccountService } from '@/data/src/lib/services/account.service';
import { PaymentStripeService, SessionResult } from '@/data/src/lib/services/payment-stripe.service';
import { ScenePlan } from '@/data/src/lib/enums/pricing-plan';

enum MoustEventType {
  Left,
  Middle,
  Right,
}

@Component({
  selector: 'ui-display-card',
  templateUrl: './display-card.component.html',
  styleUrls: ['./display-card.component.scss'],
})
export class DisplayCardComponent implements OnInit {
  plans = ScenePlan;
  @Input() imageSrc!: string;
  @Input() isNewCard!: boolean;
  @Input() canCreate!: boolean;
  @Input() canSelect!: boolean;
  @Input() isSelected = false;
  @Input() id = '';
  @Input() scenePlan: ScenePlan;
  @Input() isExpired: boolean;
  @Input() spaceName = '';
  @Input() chatOn!: boolean | undefined;

  @Output() imageClick = new EventEmitter<boolean>(); // True if middle click
  @Output() selected = new EventEmitter<boolean>();

  isEnterpriseOrAdmin = this._accountService.isEnterpriseOrAdmin;

  constructor(
    protected router: Router,
    protected _accountService: AccountService,
    protected _paymentStripeService: PaymentStripeService,
    @Inject(WINDOW) readonly _window: Window,
  ) {}

  ngOnInit(): void {}

  onImageClick(event: MouseEvent) {
    event.stopPropagation();

    if (!this.isExpired || this.isEnterpriseOrAdmin) {
      // TODO: Check if this is the correct place for this condition
      this.imageClick.emit(event.button === MoustEventType.Middle || (event.button === MoustEventType.Left && event.ctrlKey));
    }
  }

  toggleSelection(checked: boolean): void {
    this.selected.emit(checked);
  }

  onPreviewClick() {
    this.router.navigate(['subscription']);
  }

  async onResubscriptionClick() {
    await this._paymentStripeService
      .redirectStripeCheckout(this.id)
      .then((sessionResult: SessionResult) => (this._window.document.location.href = sessionResult.RedirectUrl));
  }

  onEditDomain() {
    this.router.navigate(['domain', 'owned']);
  }
}
