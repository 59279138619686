import {
  AbstractMesh,
  Camera,
  Color4,
  Light,
  Material,
  Mesh,
  Nullable,
  PBRMaterial,
  Quaternion,
  StandardMaterial,
  TransformNode,
  Vector3,
} from '../babylon';
import { FileType } from '../enums/file-type';
import {
  CheckpointRenderable,
  CheckpointType,
  ElementType,
  EnvironmentType,
  InteractableRenderable,
  LightType,
  LandscapeRenderable,
  ObjectRenderable,
  PopupType,
  StageRenderable,
  TCheckpointElement,
  TElement,
  TEnvironmentElement,
  TLandscapeElement,
  TLightElement,
  TMedia,
  TObjectElement,
  TScreenElement,
  TStageElement,
  TTextElement,
  TCustomization,
  TCustomizationMaterial,
  TextAlignment,
  ViewManager,
  ViewMode,
  XR_ROOT_PREFIX,
  LIGHT_BIAS,
  LIGHT_NORMAL_BIAS,
  TEXT_PADDING,
  MediaDimensions,
} from '../view-manager';
import { computeBoundingInfo } from './geometry-utils';
import { getQueryParameters } from './url-utils';

const HEX_REGEXP = /[0-9a-fA-F]{8}$|[0-9a-fA-F]{6}$/;

interface IRenderableProcessOptions {
  checkCollisions: boolean;
  isPickable: boolean;
  isEditor?: boolean;
  meshToProcess?: Mesh;
  box?: Mesh;
}

export class ViewManagerUtils {
  static IsEqual(target: any, current: any): boolean {
    if (!!target && target.constructor === Object && !!target && target.constructor === Object) {
      return !!ViewManagerUtils.PropsDiff(target, current).length;
    }
    if (Array.isArray(target) && Array.isArray(current)) {
      return target.length !== current.length || target.some((element, i) => element !== current[i]);
    }
    return target !== current;
  }

  // TODO: Unify PropsDiff and IsEqual

  static PropsDiff(target: { [key: string]: any }, current: { [key: string]: any }): string[] {
    const diff: string[] = [];
    Object.entries(target).forEach(([key, value]) => {
      if (!!value && value.constructor === Object) {
        if (!current[key]) {
          diff.push(key);
          return;
        }
        const subDiff = ViewManagerUtils.PropsDiff(value, current[key]);
        subDiff.length && diff.push(key);
        return;
      }
      if (Array.isArray(value)) {
        (value.length !== current[key]?.length || value.some((element, i) => ViewManagerUtils.IsEqual(element, current[key][i]))) &&
          diff.push(key);
        return;
      }
      value !== current?.[key] && diff.push(key);
    });
    return diff;
  }

  static FormatElementFromManager(sceneId: string, element: TElement) {
    const Parameters: any = {};

    switch (element.type) {
      case ElementType.Checkpoint:
        const checkpoint = element as TCheckpointElement;
        Parameters.Type = ViewManagerUtils.GetCheckpointEnum(checkpoint.parameters.type);
        Parameters.Position = checkpoint.parameters.position;
        Parameters.Quaternion = checkpoint.parameters.quaternion;
        Parameters.Avatar = checkpoint.parameters.avatar;
        break;
      case ElementType.Environment:
        const environment = element as TEnvironmentElement;
        Parameters.type = ViewManagerUtils.GetEnvironmentEnum(environment.parameters.type);
        Parameters.assetVersionId = environment.parameters.assetVersionId;
        Parameters.intensity = environment.parameters.intensity?.toString();
        Parameters.level = environment.parameters.level?.toString();
        Parameters.rotation = environment.parameters.rotation?.toString();
        Parameters.tint = JSON.stringify(environment.parameters.tint);
        break;
      case ElementType.Landscape:
        const landscape = element as TLandscapeElement;
        Parameters.AssetVersionId = landscape.parameters.assetVersionId;
        Parameters.Position = landscape.parameters.position;
        Parameters.Rotation = landscape.parameters.rotation;
        Parameters.Scaling = landscape.parameters.scaling;
        break;
      case ElementType.Light:
        const light = element as TLightElement;
        Parameters.Type = ViewManagerUtils.GetLightEnum(light.parameters.type);
        Parameters.Intensity = light.parameters.intensity?.toString();
        Parameters.Bias = light.parameters.bias?.toString();
        Parameters.NormalBias = light.parameters.normalBias?.toString();
        Parameters.Color = JSON.stringify(light.parameters.color);
        Parameters.GroundColor = JSON.stringify(light.parameters.groundColor);
        Parameters.Position = JSON.stringify(light.parameters.position);
        Parameters.Direction = JSON.stringify(light.parameters.direction);
        Parameters.Angle = light.parameters.angle?.toString();
        break;
      case ElementType.Object:
        const obj = element as TObjectElement;
        Parameters.AssetVersionId = obj.parameters.assetVersionId;
        Parameters.Popups = ViewManagerUtils.FormatInteractionsFromManager(obj.parameters.popups ?? []);
        Parameters.Effects = ViewManagerUtils.FormatInteractionsFromManager(obj.parameters.effects ?? []);
        Parameters.Customization = ViewManagerUtils.FormatCustomizationFromManager(obj.parameters.customization);
        Parameters.Position = obj.parameters.position;
        Parameters.Quaternion = obj.parameters.quaternion;
        Parameters.Scaling = obj.parameters.scaling;
        Parameters.Collisions = obj.parameters.collisions;
        Parameters.Shadows = obj.parameters.shadows;
        break;
      case ElementType.Screen:
        const screen = element as TScreenElement;
        Parameters.Position = screen.parameters.position;
        Parameters.Quaternion = screen.parameters.quaternion;
        Parameters.Scaling = screen.parameters.scaling;
        Parameters.Popups = ViewManagerUtils.FormatInteractionsFromManager(screen.parameters.popups ?? []);
        Parameters.Effects = ViewManagerUtils.FormatInteractionsFromManager(screen.parameters.effects ?? []);
        Parameters.Collisions = screen.parameters.collisions;
        Parameters.Shadows = screen.parameters.shadows;
        Parameters.Media = ViewManagerUtils.FormatMediaFromManager(screen.parameters.media);
        Parameters.DoubleSided = screen.parameters.doubleSided;
        break;
      case ElementType.Stage:
        const stage = element as TStageElement;
        Parameters.AssetVersionId = stage.parameters.assetVersionId;
        Parameters.Position = stage.parameters.position;
        Parameters.Rotation = stage.parameters.rotation;
        Parameters.Scaling = stage.parameters.scaling;
        break;
      case ElementType.Text:
        const text = element as TTextElement;
        Parameters.Text = text.parameters.text;
        Parameters.Font = text.parameters.font;
        Parameters.Color = JSON.stringify(text.parameters.color);
        Parameters.Background = JSON.stringify(text.parameters.background);
        Parameters.Alignment = ViewManagerUtils.GetAlignmentEnum(text.parameters.alignment);
        Parameters.LineHeight = text.parameters.lineHeight;
        Parameters.LetterSpacing = text.parameters.letterSpacing;
        Parameters.Padding = text.parameters.padding;
        Parameters.DoubleSided = text.parameters.doubleSided;
        Parameters.Popups = ViewManagerUtils.FormatInteractionsFromManager(text.parameters.popups ?? []);
        Parameters.Effects = ViewManagerUtils.FormatInteractionsFromManager(text.parameters.effects ?? []);
        Parameters.Position = text.parameters.position;
        Parameters.Quaternion = text.parameters.quaternion;
        Parameters.Scaling = text.parameters.scaling;
        Parameters.Collisions = text.parameters.collisions;
        Parameters.Shadows = text.parameters.shadows;
        break;
      default:
        break;
    }

    return {
      Id: element.id,
      SceneId: sceneId,
      Name: element.name,
      Type: ViewManagerUtils.GetElementEnum(element.type),
      Parameters: JSON.stringify(Parameters),
    };
  }

  static FormatElementToManager(element: any) {
    const parameters: any = {};
    const rawParameters = JSON.parse(element.Parameters);

    switch (element.Type) {
      case ElementType.Checkpoint:
        parameters.type = ViewManagerUtils.GetCheckpointString(rawParameters.Type);
        parameters.position = rawParameters.Position;
        parameters.quaternion = rawParameters.Quaternion;
        parameters.avatar = rawParameters.Avatar;
        break;
      case ElementType.Object:
        parameters.assetVersionId = rawParameters.AssetVersionId ?? rawParameters.AssetId;
        parameters.popups = ViewManagerUtils.FormatInteractionsToManager(rawParameters.Popups ?? []);
        parameters.effects = ViewManagerUtils.FormatInteractionsToManager(rawParameters.Effects ?? []);
        parameters.customization = ViewManagerUtils.FormatCustomizationToManager(rawParameters.Customization);
        parameters.doubleSided = rawParameters.DoubleSided;
        parameters.position = rawParameters.Position;
        parameters.quaternion = rawParameters.Quaternion;
        parameters.scaling = rawParameters.Scaling;
        parameters.collisions = rawParameters.Collisions ?? false;
        parameters.shadows = rawParameters.Shadows ?? true;
        break;
      case ElementType.Environment:
        parameters.type = ViewManagerUtils.GetEnvironmentString(rawParameters.type);
        parameters.assetVersionId = rawParameters.assetVersionId ?? rawParameters.assetId;
        parameters.intensity = JSON.parse(rawParameters.intensity);
        parameters.level = JSON.parse(rawParameters.level);
        parameters.rotation = JSON.parse(rawParameters.rotation);
        parameters.tint = JSON.parse(rawParameters.tint);
        break;
      case ElementType.Landscape:
        parameters.assetVersionId = rawParameters.AssetVersionId ?? rawParameters.AssetId;
        parameters.position = rawParameters.Position;
        parameters.rotation = rawParameters.Rotation;
        parameters.scaling = rawParameters.Scaling;
        break;
      case ElementType.Light:
        parameters.type = ViewManagerUtils.GetLightString(rawParameters.Type);
        parameters.intensity = JSON.parse(rawParameters.Intensity);
        parameters.bias = JSON.parse(rawParameters.Bias ?? LIGHT_BIAS);
        parameters.normalBias = JSON.parse(rawParameters.NormalBias ?? LIGHT_NORMAL_BIAS);
        parameters.color = JSON.parse(rawParameters.Color);
        parameters.groundColor = rawParameters.GroundColor && JSON.parse(rawParameters.GroundColor);
        parameters.position = JSON.parse(rawParameters.Position);
        parameters.direction = JSON.parse(rawParameters.Direction);
        parameters.angle = rawParameters.Angle && JSON.parse(rawParameters.Angle);
        break;
      case ElementType.Screen:
        parameters.position = rawParameters.Position;
        parameters.quaternion = rawParameters.Quaternion;
        parameters.scaling = rawParameters.Scaling;
        parameters.media = ViewManagerUtils.FormatMediaToManager(rawParameters.Media);
        parameters.doubleSided = rawParameters.DoubleSided;
        parameters.effects = ViewManagerUtils.FormatInteractionsToManager(rawParameters.Effects ?? []);
        parameters.popups = ViewManagerUtils.FormatInteractionsToManager(rawParameters.Popups ?? []);
        parameters.collisions = rawParameters.Collisions ?? false;
        parameters.shadows = rawParameters.Shadows ?? true;
        break;
      case ElementType.Stage:
        parameters.assetVersionId = rawParameters.AssetVersionId ?? rawParameters.AssetId;
        parameters.position = rawParameters.Position;
        parameters.rotation = rawParameters.Rotation;
        parameters.scaling = rawParameters.Scaling;
        break;
      case ElementType.Text:
        parameters.text = rawParameters.Text;
        parameters.font = rawParameters.Font;
        parameters.color = JSON.parse(rawParameters.Color);
        parameters.background = JSON.parse(rawParameters.Background);
        parameters.alignment = ViewManagerUtils.GetAlignmentString(rawParameters.Alignment);
        parameters.lineHeight = rawParameters.LineHeight;
        parameters.letterSpacing = rawParameters.LetterSpacing;
        parameters.padding = rawParameters.Padding ?? TEXT_PADDING;
        parameters.doubleSided = rawParameters.DoubleSided;
        parameters.popups = ViewManagerUtils.FormatInteractionsToManager(rawParameters.Popups ?? []);
        parameters.effects = ViewManagerUtils.FormatInteractionsToManager(rawParameters.Effects ?? []);
        parameters.position = rawParameters.Position;
        parameters.quaternion = rawParameters.Quaternion;
        parameters.scaling = rawParameters.Scaling;
        parameters.collisions = rawParameters.Collisions ?? false;
        parameters.shadows = rawParameters.Shadows ?? true;
        break;
      default:
        break;
    }

    return {
      id: element.Id,
      name: element.Name,
      type: element.Type,
      parameters,
    };
  }

  static FormatInteractionsFromManager(interactions: any[]) {
    return interactions.map((interaction) => ({ ...interaction, parameters: JSON.stringify(interaction.parameters) }));
  }

  static FormatInteractionsToManager(rawInteractions: any[]) {
    return rawInteractions.map((rawInteraction) => ({ ...rawInteraction, parameters: JSON.parse(rawInteraction.parameters) }));
  }

  static FormatMediaFromManager(media: TMedia | undefined) {
    return media ? { ...media, type: ViewManagerUtils.GetFileTypeEnum(media.type) } : undefined;
  }

  static FormatMediaToManager(rawMedia: any) {
    return rawMedia ? { ...rawMedia, type: ViewManagerUtils.GetFileTypeString(rawMedia.type) } : undefined;
  }

  static FormatCustomizationFromManager(customization: TCustomization | undefined) {
    return customization
      ? {
          materials: customization.materials ? JSON.stringify(customization.materials) : undefined,
          meshes: customization.meshes ? JSON.stringify(customization.meshes) : undefined,
        }
      : undefined;
  }

  static FormatCustomizationToManager(rawCustomization: any) {
    return rawCustomization
      ? ({
          ...rawCustomization,
          materials: rawCustomization.materials ? JSON.parse(rawCustomization.materials) : undefined,
          meshes: rawCustomization.meshes ? JSON.parse(rawCustomization.meshes) : undefined,
        } as TCustomization)
      : undefined;
  }

  static GetElementEnum(type: ElementType): number {
    switch (type) {
      case ElementType.Object:
        return 0;
      // case ElementType.Display:
      //   return 1;
      case ElementType.Stage:
        return 2;
      case ElementType.Landscape:
        return 3;
      case ElementType.Environment:
        return 4;
      case ElementType.Screen:
        return 5;
      case ElementType.Camera:
        return 6;
      case ElementType.Light:
        return 7;
      case ElementType.Checkpoint:
        return 8;
      case ElementType.Interaction:
        return 9;
      case ElementType.Text:
        return 10;
      default:
        return -1;
    }
  }

  static GetCheckpointEnum(type: CheckpointType) {
    return type === CheckpointType.Start ? 0 : -1;
  }

  static GetEnvironmentEnum(type: EnvironmentType): number {
    switch (type) {
      case EnvironmentType.Background:
        return 0;
      case EnvironmentType.Illumination:
        return 1;
      case EnvironmentType.Combined:
        return 2;
      default:
        return -1;
    }
  }

  static GetLightEnum(type: LightType): number {
    switch (type) {
      case LightType.Ambient:
        return 0;
      case LightType.Directional:
        return 1;
      case LightType.Point:
        return 2;
      case LightType.Spot:
        return 3;
      default:
        return -1;
    }
  }

  static GetAlignmentEnum(alignment?: TextAlignment): number {
    switch (alignment) {
      case TextAlignment.Left:
        return 0;
      case TextAlignment.Center:
        return 1;
      case TextAlignment.Right:
        return 2;
      default:
        return -1;
    }
  }

  static GetFileTypeEnum(type?: FileType): number {
    switch (type) {
      case FileType.Image:
        return 0;
      case FileType.Video:
        return 1;
      default:
        return -1;
    }
  }

  static GetCheckpointString(number: number): CheckpointType | undefined {
    return number === 0 ? CheckpointType.Start : undefined;
  }

  static GetEnvironmentString(number: number): EnvironmentType | undefined {
    switch (number) {
      case 0:
        return EnvironmentType.Background;
      case 1:
        return EnvironmentType.Illumination;
      case 2:
        return EnvironmentType.Combined;
      default:
        return;
    }
  }

  static GetLightString(number: number): LightType | undefined {
    switch (number) {
      case 0:
        return LightType.Ambient;
      case 1:
        return LightType.Directional;
      case 2:
        return LightType.Point;
      case 3:
        return LightType.Spot;
      default:
        return;
    }
  }

  static GetAlignmentString(number: number): TextAlignment | undefined {
    switch (number) {
      case 0:
        return TextAlignment.Left;
      case 1:
        return TextAlignment.Center;
      case 2:
        return TextAlignment.Right;
      default:
        return;
    }
  }

  static GetFileTypeString(number: number): FileType | undefined {
    switch (number) {
      case 0:
        return FileType.Image;
      case 1:
        return FileType.Video;
      default:
        return;
    }
  }

  static GetColorString(color: number[] | undefined) {
    if (color) {
      return `rgba(${color
        .slice(0, 3)
        .map((x) => x * 255)
        .join(',')},${color.length === 4 ? color[3] : 1})`;
    }
    return 'rgba(0,0,0,1)';
  }

  static GetColorArray(color: string) {
    const colorArray = color.replace('rgb(', '').replace('rgba(', '').replace(')', '').split(',');
    if (colorArray.length === 4) {
      return colorArray
        .slice(0, 3)
        .map((x) => Number(x) / 255)
        .concat(Number(colorArray[3]));
    }
    return colorArray.map((x) => Number(x) / 255);
  }

  static RgbToHex(rgb: string) {
    return (rgb.match(/\d+/g) ?? ['0', '0', '0'])
      .slice(0, 3)
      .map((x: string) => (+x).toString(16).padStart(2, '0'))
      .join('')
      .toUpperCase();
  }

  static HexToRgba(hex: string) {
    return Color4.FromHexString(hex).toString();
  }

  static IsHexValid(hex: string) {
    return hex ? HEX_REGEXP.test(hex) : false;
  }

  static ToEuler(quaternion: [number, number, number, number]) {
    return Quaternion.FromArray(quaternion).toEulerAngles().asArray();
  }

  static ToQuaternion(euler: [number, number, number]) {
    return Vector3.FromArray(euler).toQuaternion().asArray();
  }

  static GetPopupType(type: string) {
    const query = type.toLowerCase();
    switch (query) {
      case 'contact':
        return PopupType.Contact;
      case 'gallery':
        return PopupType.Gallery;
      case 'google form':
        return PopupType.GoogleForm;
      case 'guest book':
        return PopupType.GuestBook;
      case 'link':
        return PopupType.Link;
      case 'text':
        return PopupType.Text;
      case 'video':
        return PopupType.Video;
      default:
        return;
    }
  }

  static AttachMetadata(
    target: Mesh | AbstractMesh | Camera | Material | TransformNode,
    metadata: (Mesh | AbstractMesh | Camera | Material | TransformNode)['metadata'],
  ) {
    target.metadata = target.metadata ? { ...target.metadata, ...metadata } : metadata;
  }

  static ExportPipeline(self: ViewManager) {
    if (self.activeMode !== ViewMode.Editor) {
      return;
    }
    // TODO: Implement in asset renderables for Editor (Environment, Object)
    let root: Nullable<Mesh> = null;
    self.objects.forEach((obj) => {
      if (!obj.helper.metadata.isRoot) {
        if (!root) {
          root = new Mesh(XR_ROOT_PREFIX, self.scene);
          root.position.fromArray(obj.element.parameters.position!);
          root.rotationQuaternion = Quaternion.FromArray(obj.element.parameters.quaternion!);
          root.scaling.fromArray(obj.element.parameters.scaling!);
        }
        obj.helper.setParent(root);
        ViewManagerUtils.AttachMetadata(root, { helperOf: obj, isRoot: true });
        obj.helper = root;
        obj.updateElement();
      }
    });
  }

  static ProcessRenderableHierarchy(
    renderable: CheckpointRenderable | LandscapeRenderable | ObjectRenderable | StageRenderable,
    options: IRenderableProcessOptions = { checkCollisions: false, isPickable: true, isEditor: false },
    enable: boolean | undefined = true,
  ) {
    const isCheckpoint = renderable instanceof CheckpointRenderable;
    (options?.meshToProcess || renderable.helper)
      .getChildren(undefined, false)
      .concat(options?.meshToProcess || renderable.helper)
      .forEach((child) => {
        enable !== undefined && child.setEnabled(enable);
        if (!(child instanceof AbstractMesh)) {
          if (child instanceof Light || child instanceof Camera) {
            child.dispose();
            return;
          }
          options?.isEditor &&
            child instanceof TransformNode &&
            ViewManagerUtils.AttachMetadata(child, { helperOf: renderable, ...(child === options?.box ? {} : { box: options?.box }) });
          return;
        }
        if (child.material && (child.material as StandardMaterial).maxSimultaneousLights < 8) {
          (child.material as StandardMaterial).maxSimultaneousLights = 8;
        }
        child.alwaysSelectAsActiveMesh = !!child.skeleton;
        child.receiveShadows = true;
        child.checkCollisions = options?.box && child !== options.box ? false : !!options?.checkCollisions && !!child.getTotalIndices();
        if (child.checkCollisions) {
          child.collisionMask = isCheckpoint ? -1 : 2 ** child.uniqueId;
          child.collisionGroup = isCheckpoint ? -1 : child.uniqueId;
        }
        child.isPickable = options?.box && child !== options.box ? false : !!options?.isPickable;
        child.enablePointerMoveEvents = child.isPickable;
        ViewManagerUtils.AttachMetadata(child, { helperOf: renderable, ...(child === options?.box ? {} : { box: options?.box }) });
      });
  }

  static FocusOnRenderable(renderable: InteractableRenderable | LandscapeRenderable | StageRenderable) {
    const boundingSphere = computeBoundingInfo(renderable.helper).boundingSphere;
    renderable.viewManager.creatorCamera.target.copyFrom(boundingSphere.centerWorld);
    renderable.viewManager.creatorCamera.radius = boundingSphere.radiusWorld * 2;
  }

  static async GetMediaDimensions(media: TMedia) {
    if (media?.url && !media.url.startsWith('data')) {
      const queryParams = getQueryParameters(media.url);
      const width = queryParams?.get('width');
      const height = queryParams?.get('height');
      if (width && height) {
        return {
          width: Number(width) || 100,
          height: Number(height) || 100,
          rotate90: false,
        };
      }
    }
    switch (media.type) {
      case FileType.Image:
        const imageElement = new Image();
        imageElement.src = media.url;
        const imagePromise = new Promise<MediaDimensions>((resolve) => {
          imageElement.onload = () => {
            imageElement.remove();
            resolve({ width: imageElement.width, height: imageElement.height, rotate90: false });
          };
        });
        document.body.append(imageElement);
        return await imagePromise;
      case FileType.Video:
        const videoElement = document.createElement('video');
        videoElement.src = media.url;
        const videoPromise = new Promise<MediaDimensions>((resolve) => {
          videoElement.addEventListener(
            'loadedmetadata',
            function () {
              videoElement.remove();
              const videoWidth = this.videoWidth;
              const videoHeight = this.videoHeight;

              const width = this.clientHeight;
              const height = this.clientWidth;

              if ((videoWidth > videoHeight && width < height) || (videoHeight > videoWidth && height < width)) {
                resolve({
                  width: videoHeight,
                  height: videoWidth,
                  rotate90: true,
                });
              } else {
                resolve({
                  width: videoWidth,
                  height: videoHeight,
                  rotate90: false,
                });
              }
            },
            false,
          );
        });

        document.body.append(videoElement);
        return await videoPromise;
      default:
        return {
          width: 16,
          height: 9,
          rotate90: false,
        };
    }
  }

  static GetCustomizationParameters(material: Material) {
    return {
      materialId: material.metadata?.origin?.id || material.id,
      name: material.metadata?.origin?.name || material.name,
      parameters: {
        diffuseColor: (material instanceof PBRMaterial ? material.albedoColor : (material as StandardMaterial).diffuseColor).asArray(),
        emissiveColor: (material as PBRMaterial).emissiveColor.asArray(),
        ambientColor: (material as PBRMaterial).ambientColor.asArray(),
        unlit: material instanceof PBRMaterial && (material as PBRMaterial).unlit,
      },
    } as TCustomizationMaterial;
  }
}
