import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, ViewChild } from '@angular/core';
import { VideoToolItem } from '../../modal/scene/video-tool/video-tool.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'ui-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class VideoPlayerComponent {
  @ViewChild('container') container;
  @ViewChild('video') video;
  @ViewChild('controls') controls;
  @ViewChild('playPause') playPause;
  @ViewChild('stop') stop;
  @ViewChild('mute') mute;
  @ViewChild('fs') fs;
  @ViewChild('progress') progress;
  @ViewChild('progressBar') progressBar;
  @ViewChild('progressCircle') progressCircle;
  @ViewChild('volumeSlider') volumeSlider;

  @Input() videoData: VideoToolItem;
  @Input() autoplay: boolean = true;
  @Input() set isIntoView(val) {
    if (!val && this.video && this.video.nativeElement && !this.video.nativeElement.paused && !this.video.nativeElement.ended) {
      this.video.nativeElement.pause();
    }
  }
  showControls = false;
  showProgressCircle = false;
  isFullscreen = false;
  isStarted = false;
  isLoading = true;
  isLoop = false;
  currentTime = 0;
  videoDuration = 0;

  get currentVolume() {
    return Math.floor(this.video?.nativeElement?.volume * 10) / 10 ?? 0;
  }

  constructor(
    private _cd: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {}

  ngOnInit(): void {
    this._document.addEventListener('fullscreenchange', (e) => {
      this.container && this.container.nativeElement.setAttribute('data-fullscreen', !!this._document.fullscreenElement);
    });
  }

  onPlayPause(): void {
    if (this.video.nativeElement.paused || this.video.nativeElement.ended) {
      this.video.nativeElement.play();
      this.isStarted = true;
    } else {
      this.video.nativeElement.pause();
    }
  }

  onContext(event: MouseEvent) {
    event.preventDefault();
    return false;
  }

  onStop(): void {
    this.video.nativeElement.pause();
    this.video.nativeElement.currentTime = 0;
    this.progress.nativeElement.value = 0;
  }

  onSkip(): void {
    if (this.video.nativeElement.currentTime + 10 < this.video.nativeElement.duration) {
      this.video.nativeElement.currentTime += 10;
    }
  }

  onMute(): void {
    this.video.nativeElement.muted = !this.video.nativeElement.muted;
  }

  changeVolume(louder: boolean): void {
    if (louder && this.currentVolume < 1) {
      this.video.nativeElement.volume += 0.1;
    } else if (!louder && this.currentVolume > 0) {
      this.video.nativeElement.volume -= 0.1;
    }
  }

  onTimeUpdate(): void {
    this.currentTime = this.video.nativeElement.currentTime;
    if (!this.progress.nativeElement.getAttribute('max'))
      this.progress.nativeElement.setAttribute('max', this.video.nativeElement.duration);
    this.progress.nativeElement.value = this.video.nativeElement.currentTime;
    this.progressBar.nativeElement.style.width = `${Math.floor(
      (this.video.nativeElement.currentTime * 100) / this.video.nativeElement.duration,
    )}%`;
  }

  skip(e): void {
    const rect = this.progress.nativeElement.getBoundingClientRect();
    const pos = (e.pageX - rect.left) / this.progress.nativeElement.offsetWidth;
    this.video.nativeElement.currentTime = `${Math.floor(pos * this.video.nativeElement.duration)}`;
  }

  setVolume(e) {
    const rect = this.volumeSlider.nativeElement.getBoundingClientRect();
    this.video.nativeElement.volume = (e.pageX - rect.left) / 100;
  }

  onFS(): void {
    if (this._document.fullscreenElement !== null) {
      // The document is in fullscreen mode
      this._document.exitFullscreen();
      this.container.nativeElement.setAttribute('data-fullscreen', false);
      this.isFullscreen = false;
    } else {
      // The document is not in fullscreen mode
      this.container.nativeElement.requestFullscreen();
      this.container.nativeElement.setAttribute('data-fullscreen', true);
      this.isFullscreen = true;
    }
    this._cd.detectChanges();
  }
}
