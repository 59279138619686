import { v4 as uuid } from 'uuid';
import {
  AbstractMesh,
  BoundingInfo,
  Color3,
  Material,
  Mesh,
  MeshBuilder,
  Nullable,
  Observer,
  PickingInfo,
  PointerEventTypes,
  PointerInfo,
  Quaternion,
  Vector3,
} from '../../babylon';
import { ViewManagerUtils } from '../../utils/view-manager-utils';
import { CUSTOMIZATION_COLOR, INFINITE_PICK } from '../constants';
import { ObjectRenderable } from '../renderables';
import { ElementType, TCustomizationMaterial, TElement, TObjectElement, ViewTool } from '../types';
import { ViewManager } from '../view-manager';

export class CustomizeTool {
  name = ViewTool.Customize;
  elements: TObjectElement[] = [];

  materials: { [materialId: string]: Material } = {};
  private meshes: AbstractMesh[] = [];

  isLocked: boolean;

  private pointerInfo: Nullable<PickingInfo[]>;
  private nearestPick: Nullable<PickingInfo>;
  private moveCount = 0;

  private observers: {
    pointer?: Nullable<Observer<PointerInfo>>;
  } = {};

  private _origin: Nullable<Mesh>;

  private focusBoundingInfo: BoundingInfo;

  constructor(private viewManager: ViewManager) {
    this.interact = this.interact.bind(this);
    this.onPointer = this.onPointer.bind(this);
  }

  get descriptions() {
    return Object.values(this.materials).map(ViewManagerUtils.GetCustomizationParameters);
  }

  get origin() {
    if (!this._origin) {
      this._origin = MeshBuilder.CreateBox(`Origin-${uuid()}`, { size: 0.5 }, this.viewManager.scene);
      this._origin.rotationQuaternion = Quaternion.Identity();
      this._origin.isVisible = false;
      this._origin.isPickable = false;
      ViewManagerUtils.AttachMetadata(this._origin, { helperOf: this });
    }
    return this._origin;
  }

  get isHooked() {
    return !!this.elements.length;
  }

  get element() {
    return this.elements[0];
  }

  get renderable() {
    if (!this.element) {
      return null;
    }
    return this.viewManager.appService.getViewRenderable(this.element) as ObjectRenderable;
  }

  get scene() {
    return this.viewManager.scene;
  }

  setup(elements: TElement[]) {
    this.clear();
    if (
      !elements.every(
        (element) => (element as TObjectElement).parameters.assetVersionId === (elements[0] as TObjectElement).parameters.assetVersionId,
      )
    ) {
      console.warn('Customization is supported only for the same asset version through multiple elements.');
      return;
    }
    this.isLocked = true;
    if (!this.observers.pointer) {
      this.observers.pointer = this.scene.onPointerObservable.add(this.onPointer);
    }
    this.elements.push(...(elements as TObjectElement[]));
    this.viewManager.appService.setSelectedElements(this.elements);
    this.renderable?.helper
      ?.getChildMeshes(false)
      ?.concat(this.renderable.helper)
      .forEach((mesh) => {
        if (mesh.metadata?.isOverlay || !mesh.material) {
          return;
        }
        this.materials[mesh.material.id] = mesh.material;
        mesh.outlineColor.copyFrom(Color3.BlackReadOnly);
        this.meshes.push(mesh);
      });
    this.viewManager.appService.setCustomizationMaterials(Object.values(this.materials).map(ViewManagerUtils.GetCustomizationParameters));
    this.update(this.viewManager.appService.getSelectedMaterials());
  }

  clear() {
    this.setOutline(false);
    this.elements.length = 0;
    Object.keys(this.materials).forEach((materialId) => {
      delete this.materials[materialId];
    });
    this.observers.pointer && this.scene.onPointerObservable.remove(this.observers.pointer);
    this.observers.pointer = null;
    this.moveCount = 0;

    this.meshes.length = 0;

    this.update();
  }

  update(materials?: TCustomizationMaterial[]) {
    materials &&
      this.meshes.forEach((mesh) => {
        if (
          materials.some(
            ({ materialId }) =>
              materialId === (this.renderable?.customization?.meshToOriginalMaterial[mesh.uniqueId]?.name ?? mesh.material?.name),
          )
        ) {
          mesh.outlineColor.copyFrom(CUSTOMIZATION_COLOR);
          mesh.outlineWidth = 0.01;
        } else {
          mesh.outlineColor.copyFrom(Color3.BlackReadOnly);
          mesh.outlineWidth = 0.0081;
        }
      });
  }

  onPointer(pointerInfo: PointerInfo) {
    if (!this.isLocked) {
      this.pointerInfo = this.scene.multiPick(
        this.scene.pointerX,
        this.scene.pointerY,
        (mesh) =>
          !mesh?.metadata?.isOverlay &&
          mesh !== mesh?.metadata?.helperOf?.box &&
          mesh?.metadata?.helperOf?.element?.type === ElementType.Object,
      );
    }
    if (pointerInfo.type === PointerEventTypes.POINTERDOWN) {
      this.isLocked = false;
      this.moveCount = 0;
    } else if (pointerInfo.type === PointerEventTypes.POINTERMOVE) {
      this.moveCount += Math.hypot(pointerInfo.event.movementX ?? 0, pointerInfo.event.movementY ?? 0);
      if (this.moveCount > 10) {
        this.isLocked = true;
      }
    } else if (pointerInfo.type === PointerEventTypes.POINTERUP) {
      if (!this.isLocked) {
        this.nearestPick = this.pointerInfo?.reduce((p, c) => (c.distance < p.distance ? c : p), INFINITE_PICK) ?? null;
        this.interact(this.nearestPick?.pickedMesh);
        return;
      }
    }
  }

  interact(mesh: Nullable<AbstractMesh> = null) {
    if (!this.viewManager.toolsEnabled) {
      return;
    }
    if (!mesh) {
      this.viewManager.appService.setSelectedElements([]);
      this.viewManager.appService.setSelectedMaterials([]);
      this.viewManager.setTool(ViewTool.Select);
      return;
    }
    if (mesh.metadata?.helperOf !== this.renderable) {
      if (mesh.metadata?.helperOf?.element) {
        this.viewManager.appService.setSelectedMaterials(mesh.material ? [ViewManagerUtils.GetCustomizationParameters(mesh.material)] : []);
        this.setup([mesh.metadata.helperOf.element]);
      }
      return;
    }
    this.viewManager.appService.setSelectedElements(this.elements);
    this.viewManager.appService.setSelectedMaterials(mesh?.material ? [ViewManagerUtils.GetCustomizationParameters(mesh.material)] : []);
    this.update();
  }

  focus() {
    this.focusBoundingInfo = new BoundingInfo(Vector3.ZeroReadOnly, Vector3.ZeroReadOnly, this.origin.computeWorldMatrix(true));
    this.meshes.forEach((mesh) => {
      mesh.refreshBoundingInfo();
      this.focusBoundingInfo.encapsulateBoundingInfo(mesh.getBoundingInfo());
    });
    this.origin.setBoundingInfo(this.focusBoundingInfo);
    this.viewManager.creatorCamera.target.copyFrom(this.focusBoundingInfo.boundingSphere.centerWorld);
    this.viewManager.scene.onAfterRenderObservable.addOnce(() => {
      this.viewManager.creatorCamera.radius = this.focusBoundingInfo.boundingSphere.radiusWorld * 2;
    });
  }

  reset() {
    // TODO: Store initial materials and use here
  }

  setOutline(flag: boolean) {
    this.meshes.forEach((mesh) => {
      mesh.renderOutline = flag;
      mesh.outlineWidth = flag ? 0.01 : 0;
    });
  }

  onDelete() {
    this.onEscape();
  }

  onEscape() {
    this.reset();
    const elements = [...this.elements];
    this.clear();
    this.viewManager.setTool(ViewTool.Select, elements);
  }
}
