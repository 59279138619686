<div class="confirmation">
  <div class="header">{{ 'shared.actions.save' | translate }}</div>
  <div class="body">
    <div class="slide">
      <button ui-button sm [class.selected]="selectedSlider === 'auto'" (click)="selectedSlider = 'auto'">
        {{ 'oxr.creatingSpace.gallery.autoSlide' | translate }}
      </button>
      <button ui-button sm [class.selected]="selectedSlider === 'manual'" (click)="selectedSlider = 'manual'">
        {{ 'oxr.creatingSpace.gallery.manualSlide' | translate }}
      </button>
    </div>
    <div class="seconds">
      <ui-radio [checked]="seconds === 3" [disabled]="selectedSlider === 'manual'" (checkedChange)="seconds = 3"
        >3 {{ 'oxr.creatingSpace.gallery.seconds' | translate }}</ui-radio
      >
      <ui-radio [checked]="seconds === 5" [disabled]="selectedSlider === 'manual'" (checkedChange)="seconds = 5"
        >5 {{ 'oxr.creatingSpace.gallery.seconds' | translate }}</ui-radio
      >
      <ui-radio [checked]="seconds === 10" [disabled]="selectedSlider === 'manual'" (checkedChange)="seconds = 10"
        >10 {{ 'oxr.creatingSpace.gallery.seconds' | translate }}</ui-radio
      >
    </div>
    <div class="footer">
      <button ui-button sm (click)="save()">{{ 'shared.actions.save' | translate }}</button>
      <button ui-button sm secondary (click)="close()">{{ 'shared.actions.cancel' | translate }}</button>
    </div>
  </div>
</div>
