{
  "name": "ownxr-application",
  "version": "2.7.8",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "test": "ng test",
    "lint": "ng lint",
    "prepare": "husky install",
    "serve:ssr:app": "node dist/app/server/server.mjs",
    "serve:ssr:mobile": "node dist/mobile/server/server.mjs"
  },
  "private": true,
  "dependencies": {
    "@angular-devkit/architect": "^0.1703.2",
    "@angular-devkit/core": "^17.3.2",
    "@angular/animations": "^17.3.1",
    "@angular/cdk": "^17.3.1",
    "@angular/common": "^17.3.1",
    "@angular/compiler": "^17.3.1",
    "@angular/core": "^17.3.1",
    "@angular/forms": "^17.3.1",
    "@angular/platform-browser": "^17.3.1",
    "@angular/platform-browser-dynamic": "^17.3.1",
    "@angular/platform-server": "^17.3.1",
    "@angular/router": "^17.3.1",
    "@angular/ssr": "^17.3.2",
    "@aspnet/signalr": "^1.0.27",
    "@azure/msal-angular": "^3.0.9",
    "@azure/msal-browser": "^3.6.0",
    "@babylonjs/core": "^6.33.1",
    "@babylonjs/gui": "^6.33.1",
    "@babylonjs/loaders": "^6.33.1",
    "@babylonjs/materials": "^6.33.1",
    "@babylonjs/serializers": "^6.33.1",
    "@microsoft/signalr": "^6.0.5",
    "@ng-select/ng-select": "^12.0.4",
    "@ng-web-apis/common": "^3.0.6",
    "@ng-web-apis/storage": "^3.0.6",
    "@ng-web-apis/universal": "^3.0.7",
    "@ngneat/until-destroy": "^9.2.3",
    "@ngx-ssr/cache": "^1.2.1",
    "@ngx-ssr/platform": "^1.2.1",
    "@ngx-ssr/timeout": "^1.1.1",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@stripe/stripe-js": "^1.54.2",
    "@tosspayments/payment-sdk": "^1.2.0",
    "angular-svg-icon": "^13.0.0",
    "express": "^4.18.2",
    "gifuct-js": "^2.1.2",
    "i": "^0.3.7",
    "install": "^0.13.0",
    "is-valid-domain": "^0.1.6",
    "moment": "^2.29.4",
    "msal": "^1.4.16",
    "ngx-autosize-input": "^16.0.0",
    "ngx-clipboard": "^15.1.0",
    "ngx-color-picker": "^12.0.1",
    "ngx-device-detector": "^7.0.0",
    "ngx-drag-scroll": "^16.0.0",
    "ngx-image-cropper": "^6.3.4",
    "ngx-pipes": "^3.2.2",
    "ngx-quill": "^24.0.2",
    "ngx-quill-upload": "^2.0.0",
    "ngx-slider-v2": "^17.0.0",
    "ngx-toastr": "^18.0.0",
    "nipplejs": "^0.10.1",
    "npm": "^8.19.4",
    "or": "^0.2.0",
    "pako": "^2.0.4",
    "public-ip": "^4.0.4",
    "quill": "^1.3.7",
    "quill-image-resize-module": "^3.0.0",
    "rxjs": "^7.5.0",
    "tslib": "^2.6.2",
    "type-fest": "^4.5.0",
    "url": "^0.11.1",
    "uuid": "^8.3.2",
    "webxr-polyfill": "^2.0.3",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.2",
    "@angular/cli": "^17.3.2",
    "@angular/compiler-cli": "^17.3.1",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~3.6.0",
    "@types/node": "^18.18.0",
    "@types/pako": "^2.0.0",
    "@types/quill": "^1.3.10",
    "@types/uuid": "^8.3.4",
    "@typescript-eslint/eslint-plugin": "^5.30.0",
    "@typescript-eslint/parser": "^5.30.0",
    "codelyzer": "^6.0.0",
    "eslint": "^8.18.0",
    "eslint-config-prettier": "^8.5.0",
    "eslint-plugin-prettier": "^4.1.0",
    "husky": "^8.0.1",
    "jasmine-core": "~3.8.0",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "^6.3.20",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.0.3",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "^1.5.0",
    "lint-staged": "^13.0.3",
    "ng-packagr": "^17.0.2",
    "prettier": "3.1.1",
    "protractor": "~7.0.0",
    "ts-node": "~8.3.0",
    "typescript": "^5.2.2"
  },
  "lint-staged": {
    "*.{html,js,jsx,ts,tsx,scss,less,css,json}": "prettier --write --ignore-unknown"
  }
}
