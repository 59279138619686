import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OxrSettingsService } from '../../oxr-settings.service';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { DropdownItem, MediaUploadComponent } from '@/ui/src/lib/modal/media-upload/media-upload.component';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageCode } from '@/data/src/lib/services/account.service';
import { MAX_CHARACTERS } from '@/data/src/lib/enums/max-length';
import { ScenePlan } from '@/data/src/lib/enums/pricing-plan';
import { interval, take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AssetType } from '@/data/src/lib/models/data/asset';
@UntilDestroy()
@Component({
  selector: 'oxr-space-information',
  templateUrl: './oxr-space-information.component.html',
  styleUrls: ['./oxr-space-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OxrSpaceInformationComponent implements OnInit, OnDestroy {
  @Input() set scene(scene: XRScene) {
    this._scene = scene;
    this.populateForm();
  }
  get scene() {
    return this._scene;
  }

  form = new UntypedFormGroup({
    Name: new UntypedFormControl('', Validators.maxLength(50)),
    Description: new UntypedFormControl(''),
    Category: new UntypedFormControl({ value: '', disabled: true }),
    Tags: new UntypedFormControl('', Validators.pattern('[a-zA-Z0-9, \\_\\-\\.]*')),
    Favicon: new UntypedFormControl(''),
  });

  tagsString: string;
  image: string;
  lengthLimit = MAX_CHARACTERS.Tag_KO;
  sceneNamelengthLimit = MAX_CHARACTERS.SceneName_EN;

  editName = false;
  editCategory = false;
  editTags = false;
  editDescription = false;

  timeoutID: NodeJS.Timeout;

  plan = ScenePlan;

  private _scene: XRScene;

  constructor(
    private _oxrSettingsService: OxrSettingsService,
    private _translateService: TranslateService,
    private _modalService: ModalService,
    private _cd: ChangeDetectorRef,
    private _ngZone: NgZone,
  ) {}

  ngOnInit(): void {
    this.lengthLimit = this._translateService.currentLang === LanguageCode.English ? MAX_CHARACTERS.Tag_EN : MAX_CHARACTERS.Tag_KO;
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutID);
  }

  getTagsString(event: string) {
    this.tagsString = event;
    this.form.get('Tags')?.setValue(this.tagsString);
  }

  populateForm() {
    if (this.scene) {
      this.image = '';
      this.editName = false;
      this.editCategory = false;
      this.editTags = false;
      this.editDescription = false;

      this.form.get('Name')?.setValue(this.scene.Name);
      this.form.get('Description')?.setValue(this.scene.Description);
      this.form.get('Category')?.setValue(undefined);
      this.form.get('Tags')?.setValue(this.scene.Tags);
      this.form.get('Favicon')?.setValue(this.scene.Favicon);
      this.image = this.scene.Favicon;
      this._cd.detectChanges();
    }
  }

  onClickEdit(propToEdit: string) {
    this._oxrSettingsService.canClosePanel = false;

    if (propToEdit) {
      this[propToEdit] = true;
    }

    this._ngZone.runOutsideAngular(() => {
      interval(0)
        .pipe(take(1), untilDestroyed(this))
        .subscribe(() => {
          this._oxrSettingsService.canClosePanel = true;
          this._ngZone.run(() => {});
        });
    });
  }

  openDetails(name: string): void {
    this._oxrSettingsService.openDetails(name);
  }

  onPngUpload() {
    const modalRef = this._modalService.open(MediaUploadComponent, { closeOnBackdropClick: false, closeOnDestroy: false });
    if (modalRef) {
      const dropDownData: DropdownItem[] = [
        {
          name: 'Favicon',
          assetType: AssetType.Favicon,
          extensions: '.jpeg, .jpg, .png',
          customMessage: this._translateService.instant('shared.fields.recommendedSize') + ' : 56*56px',
          limitSize: 1024 * 1024,
        },
      ];

      this._oxrSettingsService.canClosePanel = false;
      modalRef.instance.modalRef = this._modalService;
      modalRef.instance.scene = this.scene;
      modalRef.instance.title = this._translateService.instant('shared.fields.favicon');
      modalRef.instance.data = dropDownData;
      modalRef.instance.kind = dropDownData[0].assetType;
      modalRef.result.then((result) => {
        if (result) {
          this.image = result;
          this.form.get('Favicon')?.setValue(result);
          this._cd.detectChanges();
        }
        this._oxrSettingsService.canClosePanel = true;
      });
    }
  }

  onDeleteFavicon() {
    this.image = '';
    this.form.get('Favicon')?.setValue('');
    this._cd.detectChanges();
  }

  async onSave() {
    await this._oxrSettingsService.saveScene(this.form.getRawValue());
    this._oxrSettingsService.closeDetails();
  }
}
