import { Nullable, Observer } from '@/data/src/lib/babylon';
import { ElementsTransaction } from '@/view/src/app/transactions/elements-transaction';

import { ViewManager } from '../view-manager';
import { TransformationMode, ViewMode, ViewTool } from '../types';
import { EditorControlsInfoComponent } from '@/ui/src/lib/modal/scene/editor-controls-info/editor-controls-info.component';
import { CustomizeTool, SelectTool } from '../tools';

const IGNORE_TAGS = ['INPUT', 'TEXTAREA'];

export class CreatorModeControls {
  public isAttached = false;

  private observers: {
    transaction?: Nullable<Observer<ElementsTransaction>>;
  } = {};

  get isCreatorMode() {
    return this.viewManager.activeMode === ViewMode.Creator;
  }

  constructor(private viewManager: ViewManager) {
    this.onKeyboardUp = this.onKeyboardUp.bind(this);
    this.onKeyboardDown = this.onKeyboardDown.bind(this);
    this.transactionCallback = this.transactionCallback.bind(this);
  }

  public attach() {
    if (this.viewManager._badgeLayer) {
      this.viewManager.badgeScene.autoClearDepthAndStencil = true;
    }
    if (this.viewManager._gizmoLayer) {
      this.viewManager.gizmoScene.autoClearDepthAndStencil = true;
    }
    this.viewManager.scene.activeCamera = this.viewManager.creatorCamera;
    this.viewManager.scene.cameraToUseForPointers = this.viewManager.creatorCamera;
    this.viewManager.creatorCamera.attachControl(this.viewManager.scene.getEngine().getRenderingCanvas(), false);
    this.observers.transaction = this.viewManager.onTransaction.add(this.transactionCallback);
    window.addEventListener('keyup', this.onKeyboardUp);
    window.addEventListener('keydown', this.onKeyboardDown);
    this.isAttached = true;
  }

  public detach() {
    this.viewManager.creatorCamera.detachControl();
    window.removeEventListener('keyup', this.onKeyboardUp);
    window.removeEventListener('keydown', this.onKeyboardDown);
    this.observers.transaction && this.viewManager.onTransaction.remove(this.observers.transaction);
    this.isAttached = false;
  }

  private onKeyboardDown(event: KeyboardEvent) {
    if (this.viewManager.appService.modalRef.isModalOpen || IGNORE_TAGS.includes(document.activeElement?.tagName as string)) {
      event.code === 'Escape' && this.viewManager.appService.modalRef.close();
      return;
    }
    if (event.code === 'ShiftLeft' && this.viewManager.tool instanceof SelectTool) {
      this.viewManager.tool.multiple = true;
    }
    event.metaKey && this.checkControlKeys(event);
    switch (event.code) {
      case 'Escape':
        if (this.viewManager.toolsEnabled) {
          // TODO: After migration, remove isHooked from tools if necessary
          // and use tool.elements.length instead
          this.viewManager.tool?.isHooked
            ? this.viewManager.tool?.onEscape()
            : this.viewManager.appService.modalRef.open(EditorControlsInfoComponent, {
                hasBackdrop: true,
                closeOnBackdropClick: false,
                allowHeaderClick: false,
                zIndex: 4980,
              });
        }
        break;
      case 'Delete':
      case 'Backspace':
        if (document.activeElement !== this.viewManager.scene.getEngine().getRenderingCanvas()) {
          break;
        }
        this.viewManager.tool?.onDelete();
        this.viewManager.appService.deleteSelection();
        break;
      case 'AltLeft':
      case 'Tab':
        event.preventDefault();
        break;
      case 'KeyS':
        (event.ctrlKey || event.metaKey) && event.preventDefault();
        break;
      default:
        break;
    }
  }

  private onKeyboardUp(event: KeyboardEvent) {
    if (this.viewManager.appService.modalRef.isModalOpen || IGNORE_TAGS.includes(document.activeElement?.tagName as string)) {
      return;
    }
    if (event.code === 'ShiftLeft' && this.viewManager.tool instanceof SelectTool) {
      this.viewManager.tool.multiple = false;
    }
    if (event.ctrlKey) {
      this.checkControlKeys(event);
    } else {
      switch (event.code) {
        case 'Digit1':
          this.viewManager.setTool(ViewTool.Select);
          break;
        case 'Digit2':
          this.viewManager.appService.setTransformationMode(TransformationMode.Translate);
          break;
        case 'Digit3':
          this.viewManager.appService.setTransformationMode(TransformationMode.Rotate);
          break;
        case 'Digit4':
          this.viewManager.appService.setTransformationMode(TransformationMode.Scale);
          break;
        case 'KeyF':
          this.viewManager.focus();
          break;
        case 'KeyR':
          this.viewManager.refresh();
          break;
        case 'KeyT':
          this.viewManager.setTool(ViewTool.Snap, this.viewManager.appService.getSelectedElements());
          break;
        default:
          break;
      }
    }
  }

  private transactionCallback(transaction: ElementsTransaction) {
    this.viewManager.undoStack.push(transaction);
  }

  public dispose() {
    this.detach();
  }

  public checkControlKeys(event: KeyboardEvent) {
    switch (event.code) {
      case 'KeyC':
        this.isCreatorMode && this.viewManager.appService.copySelection();
        break;
      case 'KeyV':
        this.isCreatorMode && this.viewManager.appService.pasteSelection();
        break;
      case 'KeyS':
        this.isCreatorMode && !event.shiftKey && this.viewManager.appService.saveScene();
        break;
      case 'KeyZ':
        event.shiftKey ? this.viewManager.appService.redo() : this.viewManager.appService.undo();
        break;
      case 'KeyY':
        this.viewManager.appService.redo();
        event.preventDefault();
        break;
      default:
        break;
    }
  }
}
