import {
  AbstractMesh,
  KeyboardInfo,
  Nullable,
  Observer,
  PickingInfo,
  PointerEventTypes,
  PointerInfo,
  Quaternion,
  Ray,
  Scene,
  Vector3,
} from '@/data/src/lib/babylon';
import { ControlsInfoComponent } from '@/ui/src/lib/modal/scene/controls-info/controls-info.component';
import { ViewManager } from '../view-manager';
import { CheckpointRenderable } from '../renderables';
import { INTERACTABLE_TYPES, InteractionTrigger, ViewMode } from '../types';
import { FORWARD_QUATERNION, INFINITE_PICK, MoveDirection } from '../constants';
import { Effect } from '../auxiliaries/effect';
import { Media } from '../auxiliaries/media';
import { JoystickEvent } from '@/ui/src/lib/components/joystick/joystick.component';

const X_LOOK_LIMIT = Math.PI / 2 - 0.00001;

export interface PlayerModeKeys {
  up?: boolean;
  left?: boolean;
  down?: boolean;
  right?: boolean;
}

export class PlayerModeControls {
  public isAttached = false;
  public isShowingInstructions = false;

  private observers: {
    beforeRender?: Nullable<Observer<Scene>>;
    keyboard?: Nullable<Observer<KeyboardInfo>>;
    pointer?: Nullable<Observer<PointerInfo>>;
  } = {};

  private keys: PlayerModeKeys = {};

  private multipliers: {
    forwardBack: number;
    leftRight: number;
  } = { forwardBack: 1, leftRight: 1 };

  private direction?: Quaternion;
  private orientation?: Vector3;
  private now: number;
  private then: number;
  private distance: number;
  private pickingInfo: Nullable<PickingInfo | undefined>;
  private nearestPickingInfo: Nullable<PickingInfo | undefined>;
  private pickedMesh?: Nullable<AbstractMesh | undefined>;
  private lookCallback: (() => void) | null;

  private initialOrientation: { x: number; y: number } = { x: 0, y: 0 };
  private moveDirection = MoveDirection.None;
  private gravity = -0.0002;
  private lookSensitivity = 1000;
  private isDragging = false;
  private isEventLoading = false;
  private isKeyDown = false;
  private isLookLocked = false;
  private isPointerLocked = false;
  private isHoverActive = false;
  private _activeCheckpoint: CheckpointRenderable;
  private _excludedMeshes: AbstractMesh[];

  private _hoveredEffect?: Effect;
  private _hoveredMedia?: Media;

  private _up?: number;
  private _down?: number;
  private _forwardBack?: number;
  private _leftRight?: number;

  private ray = Ray.Zero();

  get activeCheckpoint(): CheckpointRenderable {
    if (this._activeCheckpoint) {
      return this._activeCheckpoint;
    }
    return this.viewManager.appService.getActiveCheckpoint();
  }

  get canvas(): Nullable<HTMLCanvasElement> {
    return this.viewManager.scene.getEngine().getRenderingCanvas();
  }

  get inJump(): boolean {
    return this.activeCheckpoint.inJump;
  }

  get movementVector(): Vector3 {
    return this.activeCheckpoint?.movementVector;
  }

  get fallVelocity(): number {
    return this.activeCheckpoint.fallVelocity;
  }

  set fallVelocity(value: number) {
    if (!this.activeCheckpoint) {
      return;
    }
    this.activeCheckpoint.fallVelocity = value;
  }

  constructor(private viewManager: ViewManager) {
    this.onCanvasClick = this.onCanvasClick.bind(this);
    this.onKeyboardDown = this.onKeyboardDown.bind(this);
    this.onPointerLock = this.onPointerLock.bind(this);
    this.beforeRenderCallback = this.beforeRenderCallback.bind(this);
    this.keyboardCallback = this.keyboardCallback.bind(this);
    this.pointerCallback = this.pointerCallback.bind(this);
  }

  private onKeyboardDown(event) {
    switch (event.code) {
      case 'Escape':
        // TODO: Check for viewManager.toolsEnabled instead of location.href
        if (this.isShowingInstructions) {
          this.viewManager.appService.modalRef.close();
          location.href.includes('/oxr/space/') && this.viewManager.appService.setViewMode(ViewMode.Creator);
        } else if (this.viewManager.appService.modalRef.isModalOpen) {
          this.viewManager.appService.modalRef.close();
        } else {
          this.showInstructions();
        }
        break;
      case 'KeyS':
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
          location.href.includes('/oxr/space/') && this.viewManager.appService.saveScene();
        }
        break;
      default:
        break;
    }
  }

  public attach() {
    if (this.viewManager._badgeLayer) {
      this.viewManager.badgeScene.autoClearDepthAndStencil = false;
    }
    if (this.viewManager._gizmoLayer) {
      this.viewManager.gizmoScene.autoClearDepthAndStencil = false;
    }
    this._excludedMeshes = [this.viewManager.drawingPlane, this.viewManager.boundary];
    this.fallVelocity = 0;
    this.viewManager.scene.activeCamera = this.viewManager.playerCamera;
    this.viewManager.scene.cameraToUseForPointers = this.viewManager.playerCamera;
    this.observers.beforeRender = this.viewManager.scene.onBeforeRenderObservable.add(this.beforeRenderCallback);
    if (!this.viewManager.isHandHeldDevice) {
      this.observers.keyboard = this.viewManager.scene.onKeyboardObservable.add(this.keyboardCallback);
      this.observers.pointer = this.viewManager.scene.onPointerObservable.add(this.pointerCallback);
    }
    this.isPointerLocked = false;
    // TODO: Remove after selection is unified after migration
    [...this.viewManager.landscapes, ...this.viewManager.stages].forEach((renderable) => {
      renderable.setPicking(true);
    });
    this.movementVector?.setAll(0);
    this.initializePointerLock();

    window.addEventListener('keydown', this.onKeyboardDown);
    this.isAttached = true;
  }

  public detach() {
    Object.entries(this.keys).forEach(([key, _value]) => (this.keys[key] = false));
    // TODO: Remove after selection is unified after migration
    [...this.viewManager.landscapes, ...this.viewManager.stages].forEach((renderable) => {
      renderable.setPicking(false);
    });
    this.isPointerLocked = false;
    this.observers.beforeRender && this.viewManager.scene.onBeforeRenderObservable.remove(this.observers.beforeRender);
    this.observers.keyboard && this.viewManager.scene.onKeyboardObservable.remove(this.observers.keyboard);
    this.observers.pointer && this.viewManager.scene.onPointerObservable.remove(this.observers.pointer);
    this._hoveredMedia?.outInteractionCallback?.();
    this.canvas?.removeEventListener('click', this.onCanvasClick, false);
    window.removeEventListener('keydown', this.onKeyboardDown, false);
    document.removeEventListener('pointerlockchange', this.onPointerLock, false);
    document.removeEventListener('mspointerlockchange', this.onPointerLock, false);
    document.removeEventListener('mozpointerlockchange', this.onPointerLock, false);
    document.removeEventListener('webkitpointerlockchange', this.onPointerLock, false);
    this.isAttached = false;
  }

  public showInstructions() {
    this.viewManager.appService.modalRef
      .open(ControlsInfoComponent, {
        hasBackdrop: true,
        closeOnBackdropClick: false,
        allowHeaderClick: false,
        zIndex: 4980,
      })
      .result.then(() => {
        const timeout = setTimeout(() => {
          this.isShowingInstructions = false;
          this.canvas?.click();
          clearTimeout(timeout);
        });
      });
    this.isShowingInstructions = true;
  }

  public walk(x: number, y: number) {
    this.moveDirection = MoveDirection.None;

    if (x > 0) {
      this.multipliers.leftRight = x;
      this.moveDirection = MoveDirection.Right;
    } else if (x < 0) {
      this.multipliers.leftRight = -x;
      this.moveDirection = MoveDirection.Left;
    }
    if (y > 0) {
      this.multipliers.forwardBack = y;
      this.moveDirection |= MoveDirection.Forward;
    } else if (y < 0) {
      this.multipliers.forwardBack = -y;
      this.moveDirection |= MoveDirection.Backward;
    }
  }

  public jump() {
    this.activeCheckpoint?.jump();
  }

  public lookStart(event: JoystickEvent) {
    this.isLookLocked = false;
    this.calibrateOrientation();
    this.pickingInfo = this.viewManager.scene
      .multiPick(
        event.data.position.x,
        event.data.position.y,
        (mesh) =>
          mesh.isEnabled() &&
          !mesh.metadata?.box &&
          !this._excludedMeshes.includes(mesh) &&
          INTERACTABLE_TYPES.includes(mesh?.metadata?.helperOf?.element?.type),
      )
      ?.reduce((c, p) => (c.distance < p.distance ? c : p), INFINITE_PICK);
    if (this.pickingInfo?.hit && this.pickingInfo?.pickedMesh) {
      this.pickedMesh = this.pickingInfo.pickedMesh;
      if (this.pickedMesh.metadata?.drag) {
        this.isDragging = true;
        this.pickedMesh.metadata.drag();
      } else if (this.pickedMesh.metadata?.click) {
        this.lookCallback = () => {
          this.pickedMesh?.metadata?.click?.();
        };
      } else {
        this.lookCallback = this.pickedMesh.metadata?.helperOf?.element?.parameters?.popups?.length
          ? () => {
              this.pickedMesh &&
                this.viewManager.appService.showPopup(
                  this.pickedMesh.metadata.helperOf.element.parameters.popups[0],
                  this.pickedMesh.metadata.helperOf.element,
                );
            }
          : null;
        this.pickedMesh?.metadata?.helperOf?.effect?.trigger === InteractionTrigger.Click &&
          this.pickedMesh.metadata.helperOf.effect.play();
      }
    } else {
      this.pickedMesh = null;
    }
  }

  public look(event: JoystickEvent) {
    if (!this.activeCheckpoint?.helper.rotationQuaternion) {
      return;
    }
    if (this.isDragging && this.viewManager.playerCamera) {
      this.viewManager.scene.createPickingRayToRef(
        event.data.position.x,
        event.data.position.y,
        null,
        this.ray,
        this.viewManager.playerCamera,
      );
      this.pickingInfo = this.viewManager.scene
        .multiPickWithRay(
          this.ray,
          (mesh) =>
            mesh.isEnabled() &&
            !mesh.metadata?.box &&
            !this._excludedMeshes.includes(mesh) &&
            INTERACTABLE_TYPES.includes(mesh?.metadata?.helperOf?.element?.type),
        )
        ?.reduce((c, p) => (c.distance < p.distance ? c : p), INFINITE_PICK);
      this.pickingInfo?.pickedMesh?.metadata?.drag?.(this.pickingInfo);
      return;
    }
    this.isLookLocked = this.isLookLocked || event.data.distance > 3;
    this.orientation = this.activeCheckpoint.helper.rotationQuaternion.toEulerAngles();
    this.orientation.x = Math.min(
      Math.max(this.initialOrientation.x - event.data.vector.y * event.data.force * 0.1, -Math.PI / 2 + 0.001),
      Math.PI / 2 - 0.001,
    );
    this.orientation.y = this.initialOrientation.y - event.data.vector.x * event.data.force * 0.1;
    this.activeCheckpoint.helper.rotationQuaternion = this.orientation.toQuaternion();
  }

  public lookEnd() {
    this.isDragging = false;
    this.calibrateOrientation();
    !this.isLookLocked && this.lookCallback?.();
  }

  private calibrateOrientation() {
    this.orientation = this.activeCheckpoint?.helper.rotationQuaternion?.toEulerAngles();
    if (this.orientation) {
      this.initialOrientation.x = this.orientation.x;
      this.initialOrientation.y = this.orientation.y;
    }
  }

  private beforeRenderCallback() {
    if (!this.activeCheckpoint?.helper.rotationQuaternion) {
      return;
    }

    this.now = Date.now();
    this.distance = (this.now - this.then) * this.activeCheckpoint.speed;
    this.isHoverActive = false;

    if (!this.viewManager.isHandHeldDevice) {
      this.checkInteractions();
      this.viewManager.appService.setHoverActive(this.isHoverActive);
    }

    if (this.isPointerLocked || this.inJump) {
      this.direction = this.activeCheckpoint.helper.rotationQuaternion
        .conjugate()
        .multiply(FORWARD_QUATERNION.multiply(this.activeCheckpoint.helper.rotationQuaternion));
      this.movementVector.setAll(0);
      this._forwardBack = this.distance * this.multipliers.forwardBack;
      this._leftRight = this.distance * this.multipliers.leftRight;
      if (this.keys.up || this.moveDirection & MoveDirection.Forward) {
        this.movementVector.addInPlaceFromFloats(-this.direction.x * this._forwardBack, 0, this.direction.z * this._forwardBack);
      }
      if (this.keys.left || this.moveDirection & MoveDirection.Left) {
        this.movementVector.addInPlaceFromFloats(this.direction.z * this._leftRight, 0, this.direction.x * this._leftRight);
      }
      if (this.keys.down || this.moveDirection & MoveDirection.Backward) {
        this.movementVector.addInPlaceFromFloats(this.direction.x * this._forwardBack, 0, -this.direction.z * this._forwardBack);
      }
      if (this.keys.right || this.moveDirection & MoveDirection.Right) {
        this.movementVector.addInPlaceFromFloats(-this.direction.z * this._leftRight, 0, -this.direction.x * this._leftRight);
      }
      this.movementVector.length() > 0.002 && this.activeCheckpoint.helper.moveWithCollisions(this.movementVector);
      if (this.viewManager.appService.getViewMode() === ViewMode.Player && this.activeCheckpoint.helper) {
        this.activeCheckpoint.helper.moveWithCollisions(new Vector3(0, this.fallVelocity, 0));
        this.fallVelocity += this.gravity * Math.min(this.now - (this.then ?? this.now), 60);
      }
      this._up = this.moveDirection & MoveDirection.Forward ? this._forwardBack : 0;
      this._down = this.moveDirection & MoveDirection.Backward ? this._forwardBack : 0;
      this.activeCheckpoint.avatar &&
        (this.viewManager.isHandHeldDevice
          ? this.activeCheckpoint.walk(
              this._up,
              this._down,
              Math.max(this._up, this._down) < (this.moveDirection & MoveDirection.Left ? this._leftRight : 0)
                ? this.multipliers.leftRight
                : 0,
              Math.max(this._up, this._down) < (this.moveDirection & MoveDirection.Right ? this._leftRight : 0)
                ? this.multipliers.leftRight
                : 0,
            )
          : this.activeCheckpoint.walk(
              Number(this.keys.up) * this.activeCheckpoint.speed * 10,
              Number(this.keys.down),
              Number(this.keys.left),
              Number(this.keys.right),
            ));
    }
    this.then = this.now;
  }

  private keyboardCallback(event: KeyboardInfo) {
    this.isKeyDown = event.event.type === 'keydown';
    switch (event.event.code) {
      case 'KeyW':
      case 'ArrowUp':
        this.keys.up = this.isKeyDown;
        break;
      case 'KeyA':
      case 'ArrowLeft':
        this.keys.left = this.isKeyDown;
        break;
      case 'KeyR':
        !this.isKeyDown && !this.isShowingInstructions && this.viewManager.refresh();
        break;
      case 'KeyS':
      case 'ArrowDown':
        this.keys.down = this.isKeyDown;
        break;
      case 'KeyD':
      case 'ArrowRight':
        this.keys.right = this.isKeyDown;
        break;
      case 'Tab':
        this.isKeyDown && event.event.preventDefault();
        break;
      case 'Space':
        this.isKeyDown && this.jump();
        break;
      case 'ShiftLeft':
      case 'ShiftRight':
        this.activeCheckpoint.speed = this.isKeyDown ? 0.008 : 0.004;
        break;
      default:
        break;
    }
  }

  private pointerCallback(event: PointerInfo) {
    this.moveDirection = MoveDirection.None;
    if (this.isEventLoading || !this.isPointerLocked || !this.activeCheckpoint?.helper.rotationQuaternion) {
      return;
    }
    if (this.isPointerLocked && event.type === PointerEventTypes.POINTERMOVE) {
      this.orientation = this.activeCheckpoint.helper.rotationQuaternion.toEulerAngles();
      this.orientation.y += -event.event.movementX / this.lookSensitivity;
      this.orientation.x += event.event.movementY / this.lookSensitivity;

      if (this.orientation.x > X_LOOK_LIMIT) {
        this.orientation.x = X_LOOK_LIMIT;
      } else if (this.orientation.x < -X_LOOK_LIMIT) {
        this.orientation.x = -X_LOOK_LIMIT;
      }

      Quaternion.FromEulerVectorToRef(this.orientation, this.activeCheckpoint.helper.rotationQuaternion);

      if (this.isDragging) {
        this.nearestPickingInfo = this.pickAhead();
        this.nearestPickingInfo?.pickedMesh?.metadata?.drag?.(this.nearestPickingInfo);
      }
    } else if (event.type === PointerEventTypes.POINTERDOWN) {
      this.nearestPickingInfo = this.pickAhead();
      this.pickedMesh = this.nearestPickingInfo?.pickedMesh;
      this.isDragging = !!this.pickedMesh;
      if (this.pickedMesh) {
        if (this.isDragging && !!this.pickedMesh?.metadata?.drag) {
          this.pickedMesh?.metadata?.drag?.(this.nearestPickingInfo);
          return;
        }
        if (this.pickedMesh?.metadata?.click) {
          this.pickedMesh.metadata?.click(this.nearestPickingInfo);
          return;
        }
        this.pickedMesh.metadata?.helperOf?.element?.parameters?.popups?.slice(0, 1).forEach((popup) => {
          this.viewManager.appService.showPopup(popup, this.pickedMesh!.metadata.helperOf.element, undefined, () => {
            const timeout = setTimeout(() => {
              this.canvas?.click();
              clearTimeout(timeout);
            });
          });
          document.exitPointerLock();
        });
        this.pickedMesh.metadata?.helperOf?.effect?.trigger === InteractionTrigger.Click && this.pickedMesh.metadata.helperOf.effect.play();
      }
    } else if (event.type === PointerEventTypes.POINTERUP) {
      this.isDragging = false;
    }
  }

  private pickAhead() {
    if (this.activeCheckpoint?.helper.rotationQuaternion) {
      this.direction = this.activeCheckpoint.helper.rotationQuaternion
        .conjugate()
        .multiply(FORWARD_QUATERNION.multiply(this.activeCheckpoint.helper.rotationQuaternion));
      if (this.viewManager.playerCamera) {
        this.pickingInfo = this.viewManager.scene
          .multiPickWithRay(
            this.viewManager.playerCamera.getForwardRay(undefined, undefined, this.viewManager.playerCamera.globalPosition),
            (mesh) =>
              mesh.isEnabled() &&
              !mesh.metadata?.box &&
              !this._excludedMeshes.includes(mesh) &&
              INTERACTABLE_TYPES.includes(mesh?.metadata?.helperOf?.element?.type),
          )
          ?.reduce((c, p) => (c.distance < p.distance ? c : p), INFINITE_PICK);
        if (this.pickingInfo?.hit && this.pickingInfo?.pickedMesh) {
          return this.pickingInfo;
        }
      }
    }
    return undefined;
  }

  private checkInteractions() {
    this.nearestPickingInfo = this.pickAhead();
    this.pickedMesh = this.nearestPickingInfo?.pickedMesh;

    if (this.pickedMesh) {
      this._hoveredMedia = this.pickedMesh.metadata?.helperOf?.media;
      this._hoveredMedia?.overInteractionCallback();
      if (this.pickedMesh.metadata?.helperOf?.effect) {
        if (
          this.pickedMesh.metadata.helperOf.effect !== this._hoveredEffect &&
          this.pickedMesh.metadata.helperOf.effect.trigger === InteractionTrigger.Hover
        ) {
          this._hoveredEffect?.reset();
          this._hoveredEffect = this.pickedMesh.metadata.helperOf.effect;
          this._hoveredEffect?.animationGroup.onAnimationGroupLoopObservable.clear();
          !this._hoveredEffect?.isPlaying && this._hoveredEffect?.play();
        }
      } else {
        this._hoveredEffect?.reset();
        this._hoveredEffect = undefined;
      }
      this.isHoverActive =
        this.pickedMesh?.metadata?.helperOf?.effect?.trigger === InteractionTrigger.Click ||
        this.pickedMesh?.metadata?.click ||
        this.pickedMesh?.metadata?.drag;

      if (this.pickedMesh.metadata?.helperOf?.element?.parameters?.popups?.length) {
        this.isHoverActive = true;
      }
    } else {
      if (this._hoveredMedia) {
        this._hoveredMedia.outInteractionCallback();
        this._hoveredMedia = undefined;
      }
      this._hoveredEffect?.animationGroup.onAnimationGroupLoopObservable.addOnce(this._hoveredEffect.reset);
      this._hoveredEffect = undefined;
      this.isHoverActive = false;
    }
  }

  private onPointerLock() {
    this.isPointerLocked = document.pointerLockElement === this.canvas;
    if (this.isPointerLocked) {
      Object.keys(this.keys).forEach((key) => {
        this.keys[key] = false;
      });
    } else {
      const timeout = setTimeout(() => {
        if (!this.viewManager.appService.modalRef.isModalOpen) {
          this.showInstructions();
        }
        clearTimeout(timeout);
      }, 75);
    }
  }

  private onCanvasClick() {
    if (this.canvas && this.viewManager.appService.getViewMode() === ViewMode.Player && !this.viewManager.appService.modalRef.isModalOpen) {
      this.canvas.requestPointerLock =
        this.canvas.requestPointerLock ||
        this.canvas.msRequestPointerLock ||
        this.canvas.mozRequestPointerLock ||
        this.canvas.webkitRequestPointerLock;
      this.canvas.requestPointerLock?.();
    }
  }

  /**
   * Locks the pointer in the view which allows mouse movements to control the view window of the camera
   */
  private initializePointerLock() {
    if (this.viewManager.isHandHeldDevice) {
      //If hand held device set the pointer locked to true
      this.isPointerLocked = true;
    } else {
      // On click event, request pointer lock
      if (this.canvas) {
        this.canvas.addEventListener('click', this.onCanvasClick, false);
        // Event listener when the pointerlock is updated (or removed by pressing ESC for example).
        // Attach events to the document
        document.addEventListener('pointerlockchange', this.onPointerLock, false);
        document.addEventListener('mspointerlockchange', this.onPointerLock, false);
        document.addEventListener('mozpointerlockchange', this.onPointerLock, false);
        document.addEventListener('webkitpointerlockchange', this.onPointerLock, false);
      }
    }
  }

  public dispose() {
    this.detach();
  }
}
