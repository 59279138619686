<ui-panel [title]="'oxr.creatingSpace.panels.info.title' | translate" [collapsing]="true" [panelId]="panelMask.Info">
  <div class="fields">
    @if (asset?.file) {
      <span>{{ 'oxr.creatingSpace.panels.info.changeFile' | translate }}</span>
      <label for="asset-file">
        <p>{{ asset.file.name }}</p>
        <div class="icon icon-attach"></div>
      </label>
      <input uiInput id="asset-file" type="file" (change)="onFileChange($event)" />
      <br />
    }
    <label for="asset-name">{{ 'shared.fields.name' | translate }} </label>
    <input uiInput id="asset-name" type="text" [(ngModel)]="assetName" (focusout)="onChangeEnd()" />
    <br />
    <label for="asset-tags">{{ 'oxr.creatingSpace.panels.info.tags' | translate }} </label>
    <input
      uiInput
      id="asset-tags"
      type="text"
      [disabled]="tags.length >= MAX_TAGS"
      placeholder="{{ 'oxr.creatingSpace.panels.info.tagsPlaceholder' | translate: { max: MAX_TAGS } }}"
      (keyup)="onTagInput($event)"
    />
    @if (tags.length) {
      <div class="tags">
        @for (tag of tags; track tag) {
          <div>
            <span>{{ tag }}</span>
            <div class="icon icon-close" (click)="removeTag(tag)"></div>
          </div>
        }
      </div>
    }
  </div>
  <div class="data">
    <p>{{ 'oxr.creatingSpace.panels.info.assetType' | translate }}: {{ 'sidenav.' + assetType | translate }}</p>
    @if (!isEnvironmentAsset) {
      <p>{{ bounding }}</p>
      <p>{{ 'oxr.creatingSpace.panels.info.polygonCount' | translate }}: {{ polygonCount }}</p>
    }
  </div>
</ui-panel>
