import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SceneService } from './scene.service';
import { environment } from '@/app/src/environments/environment';
import { SessionStorageService } from './session-storage.service';
import { PlatformDetail, SessionStorageKeys } from '../enums/storage-keys';
import { WINDOW } from '@ng-web-apis/common';

export interface UrlSplit {
  protocol: string;
  path: string;
  host: string;
}

export interface UrlTransformResult {
  transformedUrl: string;
  sceneId: string;
  isMappedDomain: boolean;
  shouldRedirect: boolean;
  isInitialValue: boolean;
}

export interface ExceptionCase {
  [key: string]: {
    desktopUrl: string;
    mobileUrl: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  private urlTransformResultSubject = new BehaviorSubject<UrlTransformResult>({
    transformedUrl: environment.redirectURL,
    sceneId: '',
    isMappedDomain: false,
    shouldRedirect: false,
    isInitialValue: true,
  });
  urlTransformResult$ = this.urlTransformResultSubject.asObservable();

  private exceptionCase: ExceptionCase = {
    'localhost:4200': {
      desktopUrl: 'http://localhost:4200',
      mobileUrl: 'http://localhost:4200',
    },
    'm.feature.ownxr.com': {
      desktopUrl: 'https://feature.ownxr.com',
      mobileUrl: 'https://m.feature.ownxr.com',
    },
    'feature.ownxr.com': {
      desktopUrl: 'https://feature.ownxr.com',
      mobileUrl: 'https://m.feature.ownxr.com',
    },
    'm.staging.ownxr.com': {
      desktopUrl: 'https://staging.ownxr.com',
      mobileUrl: 'https://m.staging.ownxr.com',
    },
    'staging.ownxr.com': {
      desktopUrl: 'https://staging.ownxr.com',
      mobileUrl: 'https://m.staging.ownxr.com',
    },
    'm.ownxr.com': {
      desktopUrl: 'https://www.ownxr.com',
      mobileUrl: 'https://m.ownxr.com',
    },
    'www.ownxr.com': {
      desktopUrl: 'https://www.ownxr.com',
      mobileUrl: 'https://m.ownxr.com',
    },
    'app.ownxr.com': {
      desktopUrl: 'https://www.ownxr.com',
      mobileUrl: 'https://m.ownxr.com',
    },
    // Add other exception cases here
  };

  constructor(
    private _sceneService: SceneService,
    private _sessionStorageService: SessionStorageService,
    @Inject(WINDOW) private readonly _window: Window,
  ) {}

  public get urlTransformResult() {
    return this.urlTransformResultSubject.value;
  }

  public async transformUrlBasedOnConditions(url: string, isDesktop: boolean) {
    const urlWithProtocol = this.addProtocolToUrl(url);
    const splitedUrl = this.splitUrl(urlWithProtocol);
    const isExceptionCase = this.handleExceptionCase(splitedUrl, isDesktop);

    if (isExceptionCase) {
      this.urlTransformResultSubject.next({
        transformedUrl: isExceptionCase,
        sceneId: '',
        isMappedDomain: false,
        shouldRedirect: urlWithProtocol === isExceptionCase ? false : true,
        isInitialValue: false,
      });
      return;
    }

    if (isDesktop) {
      await this.transformUrlToAccessDesktop(splitedUrl).then((urlTransformResult) => {
        urlTransformResult.shouldRedirect = urlWithProtocol === urlTransformResult.transformedUrl ? false : true;
        this.urlTransformResultSubject.next(urlTransformResult);
        return;
      });
    } else {
      await this.transformUrlToAccessMobile(splitedUrl).then((urlTransformResult) => {
        urlTransformResult.shouldRedirect = urlWithProtocol === urlTransformResult.transformedUrl ? false : true;
        this.urlTransformResultSubject.next(urlTransformResult);
        return;
      });
    }
  }

  private async transformUrlToAccessDesktop(splitedUrl: UrlSplit): Promise<UrlTransformResult> {
    let _transformedUrl = splitedUrl.host;
    let _isMappedDomain = false;
    let _sceneId = '';

    if (this.isMobileDomain(splitedUrl.host)) {
      _transformedUrl = splitedUrl.host.replace('.m.', '.');
    }

    const mappedScene = await this._sceneService.getByDomain(_transformedUrl);

    if (mappedScene) {
      _isMappedDomain = true;
      _transformedUrl = splitedUrl.protocol + _transformedUrl;
      _sceneId = mappedScene.Id;
    } else {
      _transformedUrl = environment.redirectURL;
    }

    return {
      transformedUrl: _transformedUrl,
      sceneId: _sceneId,
      isMappedDomain: _isMappedDomain,
      shouldRedirect: false,
      isInitialValue: false,
    };
  }

  private async transformUrlToAccessMobile(splitedUrl: UrlSplit): Promise<UrlTransformResult> {
    const isMobileDomain = this.isMobileDomain(splitedUrl.host);
    let _transformedUrl = splitedUrl.host;
    let _isMappedDomain = false;
    let _sceneId = '';

    if (isMobileDomain) {
      _transformedUrl = splitedUrl.host.replace('.m.', '.');
    }

    const mappedScene = await this._sceneService.getByDomain(_transformedUrl);

    if (mappedScene) {
      _isMappedDomain = true;
      _transformedUrl = splitedUrl.protocol + (isMobileDomain ? splitedUrl.host : this.convertToMobileUrl(splitedUrl.host));
      _sceneId = mappedScene.Id;
    } else {
      _transformedUrl = environment.redirectURL;
    }

    return {
      transformedUrl: _transformedUrl,
      sceneId: _sceneId,
      isMappedDomain: _isMappedDomain,
      shouldRedirect: false,
      isInitialValue: false,
    };
  }

  private isMobileDomain(url: string): boolean {
    return url.includes('.m.');
  }

  private splitUrl(url: string): UrlSplit {
    const splitedUrl: UrlSplit = { path: '', host: url, protocol: '' };

    const protocolSeparator = '://';
    const protocolIndex = url.indexOf(protocolSeparator);

    if (protocolIndex !== -1) {
      splitedUrl.protocol = url.substring(0, protocolIndex + protocolSeparator.length);
      splitedUrl.host = url.substring(protocolIndex + protocolSeparator.length);
    }

    const splitIndex = splitedUrl.host.indexOf('/');
    if (splitIndex !== -1) {
      splitedUrl.path = splitedUrl.host.substring(splitIndex);
      splitedUrl.host = splitedUrl.host.substring(0, splitIndex);
    }

    return splitedUrl;
  }

  private handleExceptionCase(splitedUrl: UrlSplit, isDesktop: boolean): string {
    if (this.exceptionCase.hasOwnProperty(splitedUrl.host)) {
      const newSplitedUrl: UrlSplit = this.splitUrl(
        isDesktop ? this.exceptionCase[splitedUrl.host].desktopUrl : this.exceptionCase[splitedUrl.host].mobileUrl,
      );

      return newSplitedUrl.protocol + newSplitedUrl.host + splitedUrl.path;
    }

    return '';
  }

  convertToMobileUrl(url: string): string {
    const subdomain = '.m';
    const domainParts = url.split('.');

    if (domainParts.length >= 3) {
      domainParts[0] += subdomain;
      return domainParts.join('.');
    }

    return url;
  }

  private addProtocolToUrl(url: string): string {
    if (!url.startsWith('http') && !url.includes('://')) {
      if (url.includes('localhost')) {
        return 'http://' + url;
      } else {
        return 'https://' + url;
      }
    }

    return url.endsWith('/') ? url.slice(0, -1) : url;
  }

  /**
   *
   * @param url
   * @param isNewTab
   * @param platformDetail The parameter that determines which platform to run the URL with.
   */
  windowOpenWithPlatform(url: string, isNewTab = true, platformDetail?: PlatformDetail) {
    const platform = platformDetail ? platformDetail : this._sessionStorageService.getItem(SessionStorageKeys.PLATFORM);
    const editedUrl = platform ? `${url}?Platform=${platform}` : url;
    this._window.open(editedUrl, isNewTab ? '_blank' : '_self');
  }
}
