import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';

@Component({
  selector: 'ui-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  @Output() clickPageEmitter = new EventEmitter<number>();
  @Input() totalPages!: number;
  @Input() currentPage!: number;

  pages: number[] = [];
  displayedPages: number[] = [];

  isFirstPageGroup = false;
  isLastPageGroup = false;

  constructor() {}

  ngOnChanges(): void {
    this.pages = [...Array(this.totalPages).keys()].map((key) => key + 1);

    const n = Math.floor((this.currentPage - 1) / 10) * 10;
    this.displayedPages = this.pages.slice(n, n + 10);

    this.isFirstPageGroup = this.displayedPages[0] === 1;
    this.isLastPageGroup = this.displayedPages.at(-1) === this.pages.at(-1);
  }

  clickNumber(page: number) {
    this.currentPage = page;
    this.clickPageEmitter.emit(page);
  }

  clickArrow(action: string) {
    switch (action) {
      case 'First':
        this.clickPageEmitter.emit(1);
        break;
      case 'BeforePageGroup':
        this.clickPageEmitter.emit(this.displayedPages[0] - 10);
        break;
      case 'NextPageGroup':
        this.clickPageEmitter.emit(this.displayedPages[0] + 10);
        break;
      case 'Last':
        this.clickPageEmitter.emit(this.totalPages);
        break;
    }
  }
}
