import { _Exporter } from "../glTFExporter.js";
import { PBRMaterial } from "@babylonjs/core/Materials/PBR/pbrMaterial.js";
const NAME = "KHR_materials_transmission";
/**
 * [Specification](https://github.com/KhronosGroup/glTF/blob/main/extensions/2.0/Khronos/KHR_materials_transmission/README.md)
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class KHR_materials_transmission {
  constructor(exporter) {
    /** Name of this extension */
    this.name = NAME;
    /** Defines whether this extension is enabled */
    this.enabled = true;
    /** Defines whether this extension is required */
    this.required = false;
    this._wasUsed = false;
    this._exporter = exporter;
  }
  dispose() {}
  /** @internal */
  get wasUsed() {
    return this._wasUsed;
  }
  postExportMaterialAdditionalTextures(context, node, babylonMaterial) {
    const additionalTextures = [];
    if (babylonMaterial instanceof PBRMaterial) {
      if (this._isExtensionEnabled(babylonMaterial)) {
        if (babylonMaterial.subSurface.thicknessTexture) {
          additionalTextures.push(babylonMaterial.subSurface.thicknessTexture);
        }
        return additionalTextures;
      }
    }
    return additionalTextures;
  }
  _isExtensionEnabled(mat) {
    // This extension must not be used on a material that also uses KHR_materials_unlit
    if (mat.unlit) {
      return false;
    }
    const subs = mat.subSurface;
    return subs.isRefractionEnabled && subs.refractionIntensity != undefined && subs.refractionIntensity != 0 || this._hasTexturesExtension(mat);
  }
  _hasTexturesExtension(mat) {
    return mat.subSurface.refractionIntensityTexture != null;
  }
  postExportMaterialAsync(context, node, babylonMaterial) {
    return new Promise(resolve => {
      var _a;
      if (babylonMaterial instanceof PBRMaterial && this._isExtensionEnabled(babylonMaterial)) {
        this._wasUsed = true;
        const subs = babylonMaterial.subSurface;
        const transmissionFactor = subs.refractionIntensity === 0 ? undefined : subs.refractionIntensity;
        const transmissionTexture = (_a = this._exporter._glTFMaterialExporter._getTextureInfo(subs.refractionIntensityTexture)) !== null && _a !== void 0 ? _a : undefined;
        const volumeInfo = {
          transmissionFactor: transmissionFactor,
          transmissionTexture: transmissionTexture,
          hasTextures: () => {
            return this._hasTexturesExtension(babylonMaterial);
          }
        };
        node.extensions = node.extensions || {};
        node.extensions[NAME] = volumeInfo;
      }
      resolve(node);
    });
  }
}
_Exporter.RegisterExtension(NAME, exporter => new KHR_materials_transmission(exporter));
