import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, retry } from 'rxjs/operators';
import { newModel } from '../factories/model-factory';
import { XRAsset } from '../models/data/asset';
import { ModelType } from '../models/data/base';
import { BaseApiService, ModelState, RETRY_COUNT } from './base-api.service';
import { AccountService } from './account.service';
import { Observable, lastValueFrom } from 'rxjs';
import { environment } from '@/app/src/environments/environment';

/**
 * Tracks and manages the state of asset models and commits changes to the backend
 */
@Injectable({
  providedIn: 'root',
})
export class AssetService extends BaseApiService<XRAsset> {
  private noAuthHttp: HttpClient;

  constructor(
    public http: HttpClient,
    accountService: AccountService,
    handler: HttpBackend,
  ) {
    super(http, accountService, ModelType.Asset);
    this.noAuthHttp = new HttpClient(handler);
  }

  async getById(id: string, forceFromServer = false, cachedOnly = false): Promise<XRAsset | undefined> {
    if (id) {
      const result = this.modelState.get(id);
      if (!forceFromServer && result) {
        this.selectedSubject.next(result.data);
        return result.data;
      } else if (!cachedOnly && !this.accountService.trialOnly) {
        return lastValueFrom(
          this.noAuthHttp.get<XRAsset>(`${environment[this.accountService.region].crudApiUrl}/${this.modelType}/${id}`).pipe(
            retry(RETRY_COUNT),
            map((data) => {
              if (data) {
                data = newModel(data, this.modelType) as XRAsset;
                this.attach(data);

                this.updateDataSubject(data);
                this.selectedSubject.next(data);
              }
              return data;
            }),
          ),
        ).catch(() => {
          return undefined;
        });
      }
    }
    return undefined;
  }

  async getUserAvatar(username: string) {
    return await lastValueFrom(this.http.get<XRAsset>(`${this.endPoint}/UserAvatar/${username}`))
      .then((a) => {
        const model = newModel(a, ModelType.Asset);
        this.attach(model);
        return model;
      })
      .catch(() => {
        return undefined;
      });
  }

  async getAssetForEdit(assetId: string, enterpriseId?: string) {
    const param = enterpriseId ? `?enterpriseId=${enterpriseId}` : '';
    const getOriginalAssetEndpoint = `${this.endPoint}/Original/${assetId}${param}`;

    let asset1 = this.getCachedById(assetId);
    let asset2 = await lastValueFrom(this.http.get<XRAsset>(getOriginalAssetEndpoint))
      .then((a) => {
        const model = newModel(a, ModelType.Asset);
        this.attach(model);
        return model;
      })
      .catch(() => {
        return undefined;
      });

    return asset2 ?? asset1;
  }

  /**
   * Get demo asset data
   */
  async getDemoAssets() {
    return lastValueFrom(
      this.http.get<XRAsset[]>(`${this.endPoint}/Demo/`).pipe(
        map((data) => {
          data.forEach((asset, index) => {
            data[index].Url = (asset as any).Data;
            asset = newModel(asset, ModelType.Asset);
            this.attach(asset);
          });
          this.updateDataSubject(data);
          return data;
        }),
      ),
    );
  }

  /**
   * Publishes an asset
   * @param assetId string id of the asset to publish
   */
  async publish(assetId: string) {
    return lastValueFrom(this.http.post<any>(`${this.endPoint}/Publish/${assetId}`, null).pipe(retry(1)));
  }

  /**
   * Checks if the asset exists in the database
   * @param assetId string id of the asset to check
   */
  async checkIfExistsOnBackend(assetId: string): Promise<boolean> {
    return lastValueFrom(this.http.get<any>(`${this.endPoint}/CheckIfExist/${assetId}`));
  }

  /**
   * Individual : Get the IDs of sold Assets among the Assets uploaded by login user.
   * Enterprise : Get the IDs of the sold asset among Assets uploaded by every enterprise user.
   * @param enterpriseId
   * @returns The ids of the sold assets.
   */
  getSoldAssetIds(enterpriseId: string | undefined): Observable<string[]> {
    const apiUrl = enterpriseId ? `${this.endPoint}/SoldAssets?enterpriseId=${enterpriseId}` : `${this.endPoint}/SoldAssets`;
    return this.http.get<string[]>(apiUrl);
  }
}
