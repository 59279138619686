import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Inject, Injectable, Injector, Type } from '@angular/core';
import { ModalComponent } from './modal.component';
import { DOCUMENT } from '@angular/common';

export class ModalRef<T> {
  instance!: T;
  modalContainerRef!: ComponentRef<ModalComponent> | undefined;
  result!: Promise<any>;
}

export interface ModalOptions {
  hasBackdrop?: boolean;
  closeOnBackdropClick?: boolean;
  closeOnDestroy?: boolean;
  allowHeaderClick?: boolean;
  allowBackgroundClick?: boolean;
  zIndex?: number;
  onClose?: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalContainerRef!: ComponentRef<ModalComponent> | undefined;
  private result: any;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {}

  public get isModalOpen() {
    return this._document.querySelector('body > ui-modal > div.modal-container');
  }

  open<T>(component: Type<T>, options?: ModalOptions, componentOptions: { [key: string]: any } = {}): ModalRef<T> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ModalComponent);
    const componentRef = componentFactory.create(this.injector);

    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    const app = this._document.getElementsByTagName('body')[0];
    app.appendChild(domElem);

    // app.classList.add("modal-show");
    this.modalContainerRef = componentRef;

    const openModal: ModalRef<T> = {
      instance: componentRef.instance.loadModal(component, componentOptions).instance,
      modalContainerRef: componentRef,
      result: new Promise((resolve) => {
        if (this.modalContainerRef) {
          this.modalContainerRef.onDestroy(() => {
            this.modalContainerRef?.instance?.closeOnDestroy && this.modalContainerRef.instance.componentRef.instance?.onClose?.();
            resolve(this.result);
          });
        }
      }),
    };
    openModal.instance['modalContainerRef'] = openModal.modalContainerRef;
    componentRef.instance.zIndex = options?.zIndex ?? 5000;
    componentRef.instance.allowHeaderClick = !!options?.allowHeaderClick;
    if (options?.hasBackdrop !== undefined) {
      componentRef.instance.hasBackdrop = options.hasBackdrop;
    }
    if (options?.closeOnBackdropClick !== undefined) {
      componentRef.instance.closeOnBackdropClick = options.closeOnBackdropClick;
    }
    if (options?.allowBackgroundClick !== undefined) {
      componentRef.instance.allowBackgroundClick = options.allowBackgroundClick;
    }
    if (options?.closeOnDestroy !== undefined) {
      componentRef.instance.closeOnDestroy = options.closeOnDestroy;
    }
    return openModal;
  }

  close(result: any = '', modalContainerRef?: ComponentRef<ModalComponent> | undefined) {
    this.result = result;
    let containerRef = modalContainerRef ?? this.modalContainerRef;

    if (containerRef) {
      this.appRef.detachView(containerRef.hostView);
      this._document.body.classList.remove('modal-show');
      containerRef.destroy();
      containerRef.changeDetectorRef.detectChanges();
      containerRef = undefined;
    }
  }
}
