import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { debounce as _debounce } from 'lodash';
import { ScenePlan, ScenePlanDetail } from '@/data/src/lib/enums/pricing-plan';
import { PricingPlanService } from '@/data/src/lib/services/pricing-plan.service';
import { ViewManagerUtils } from '@/data/src/lib/utils/view-manager-utils';
import { TooltipPosition } from '../../directive/tooltip.directive';

@Component({
  selector: 'ui-color-palette',
  templateUrl: './color-palette.component.html',
  styleUrls: ['./color-palette.component.scss'],
})
export class ColorPaletteComponent implements OnInit, AfterViewInit {
  private _disabled = false;
  transparency: number = 100;
  _color: string;
  _isScenePlanPropertyRestriction = true;
  open: boolean = false;
  node: HTMLElement | null = null;
  toolTipPositions = TooltipPosition;

  @ViewChild('contentRef') contentRef!: TemplateRef<any>;

  private viewContainerRef: ViewContainerRef;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeMenu();
  }

  @Input() activatorTemplate?: TemplateRef<any>;
  @Input() position: string = 'top';
  @Input() type: string = 'default';
  @Input() colorOptions: { value: string; isLimited: boolean }[] = [
    { value: '#000000', isLimited: false },
    { value: '#333333', isLimited: false },
    { value: '#666666', isLimited: false },
    { value: '#9D9D9D', isLimited: false },
    { value: '#DDDDDD', isLimited: false },
    { value: '#FFFFFF', isLimited: false },
    { value: '#ED2029', isLimited: false },
    { value: '#F78E28', isLimited: false },
    { value: '#F3BE30', isLimited: false },
    { value: '#109A88', isLimited: false },
    { value: '#0071D3', isLimited: false },
    { value: '#8942B3', isLimited: false },
    { value: '#7E99B0', isLimited: false },
    // limited color
    { value: '#FEC1C8', isLimited: true },
    { value: '#FFC9B1', isLimited: true },
    { value: '#F6E09E', isLimited: true },
    { value: '#A1EEC5', isLimited: true },
    { value: '#9ACFF8', isLimited: true },
    { value: '#C1C0F7', isLimited: true },
    { value: '#C0D2E6', isLimited: true },
    { value: '#EE536A', isLimited: true },
    { value: '#EE6E57', isLimited: true },
    { value: '#A7BB2C', isLimited: true },
    { value: '#449C22', isLimited: true },
    { value: '#0D95D0', isLimited: true },
    { value: '#6067C3', isLimited: true },
    { value: '#8DB4BD', isLimited: true },
    { value: '#771B33', isLimited: true },
    { value: '#943B36', isLimited: true },
    { value: '#606C31', isLimited: true },
    { value: '#1F7026', isLimited: true },
    { value: '#1A568E', isLimited: true },
    { value: '#5639AD', isLimited: true },
    { value: '#466871', isLimited: true },
  ];

  @Input() onOpen?: Function;
  @Input() onClose?: Function;
  @Input() disabled?: boolean = false;

  @Input() set color(value: string) {
    let color = value;
    if (value.length > 7) {
      const parsed = parseInt(value.substring(7), 16);
      if (!isNaN(parsed)) {
        this.transparency = Math.round((parsed / 255) * 100);
      }
    } else {
      this.transparency = 100;
    }
    this._color = color;
  }

  @Input() set isScenePlanPropertyRestriction(scenePlan: ScenePlan) {
    this._isScenePlanPropertyRestriction = this._pricingPlanService.hasScenePlanPropertyRestriction(
      scenePlan,
      ScenePlanDetail.RestrictedContentWithinSpaceUsage,
    );
  }

  @Output() onChange = new EventEmitter<string>();

  constructor(
    viewContainerRef: ViewContainerRef,
    private el: ElementRef,
    private _pricingPlanService: PricingPlanService,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {
    this.viewContainerRef = viewContainerRef;
  }

  public ngAfterViewInit(): void {}

  ngOnInit(): void {}

  openMenu() {
    const hostPositions = this.el.nativeElement.getBoundingClientRect();
    var embeddedViewRef = this.viewContainerRef.createEmbeddedView(this.contentRef);
    embeddedViewRef.detectChanges();

    const node = embeddedViewRef.rootNodes[0];

    if (this.position === 'bottom') {
      node.style.top = hostPositions.top + 32 + 10 + 'px';
      node.style.left = hostPositions.left + (hostPositions.width - 233) / 2 + 'px';
    }

    if (this.position === 'top') {
      node.style.top = hostPositions.top - 216 - 10 + 'px';
      node.style.left = hostPositions.left + (hostPositions.width - 233) / 2 + 'px';
    }

    this.node = node;
    this._document.body.appendChild(node);
    this.open = true;
    this.onOpen && this.onOpen();
  }

  closeMenu() {
    this.open = false;
    if (this.node) {
      this.node.remove();
    }
    this.onClose && this.onClose();
  }

  clickOutside($event) {
    if (this.node && this.node.contains($event.target)) return;
    this.open = false;
    if (this.node) {
      this.node.remove();
    }
  }

  toggleMenu() {
    this.open ? this.closeMenu() : this.openMenu();
  }

  changeColor(colorOption: { value: string; isLimited: boolean }) {
    if (this._isScenePlanPropertyRestriction && colorOption.isLimited) {
      return;
    }

    this.onChange.emit(colorOption.value);
  }

  debounceColorCodeInput = _debounce((event) => {
    let colorCode = event.target.value;
    colorCode = colorCode.startsWith('#') ? colorCode : '#' + colorCode;
    if (ViewManagerUtils.IsHexValid(colorCode)) {
      let color = colorCode.substring(0, 7);
      if (colorCode.length > 7) {
        this.transparency = Math.round((parseInt(colorCode.substring(7), 16) / 255) * 100);
        color = `${colorCode.substring(0, 7)}${Math.floor((255 * this.transparency) / 100)
          .toString(16)
          .padStart(2, '0')
          .toUpperCase()}`;
      }
      this.onChange.emit(color);
    }
  }, 600);

  onColorInput(event) {
    this.debounceColorCodeInput(event);
  }

  debounceColorTransparencyInput = _debounce((event) => {
    const transparency = event.target.value;
    this.transparency = Number(transparency);
    let newColor = this._color.substring(0, 7);
    if (this.transparency !== 100) {
      newColor = `${this._color.substring(0, 7)}${Math.floor((255 * transparency) / 100)
        .toString(16)
        .padStart(2, '0')
        .toUpperCase()}`;
    }
    this.onChange.emit(newColor);
  }, 500);

  onColorTransparency(event) {
    this.debounceColorTransparencyInput(event);
  }
}
