import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { v4 as uuid } from 'uuid';

import { environment } from '@/app/src/environments/environment';
import { AccountService } from '../services/account.service';
import { catchError, lastValueFrom, map, retry } from 'rxjs';
import { XRAsset, XRAssetVersion } from '../models/data/asset';
import { newModel } from '../factories/model-factory';
import { ModelType } from '../models/data/base';
import { AssetType, IAsset, IAssetVersion } from '../models/data/asset';
import {
  ENVIRONMENT_INTENSITY,
  ENVIRONMENT_LEVEL,
  ENVIRONMENT_ROTATION,
  ENVIRONMENT_TINT,
  ElementType,
  OBJECT_POSITION,
  OBJECT_QUATERNION,
  TEnvironmentElement,
  TObjectElement,
} from '../view-manager';

export const NEW_ASSET_ID = 'create';
export const NEW_ASSET_VERSION_ID = 'initial-asset-version';

const TEMPORARY_CATEGORY_ID = 'e25af303-17cf-6908-8f49-1c0054732969';

@Injectable({
  providedIn: 'root',
})
export class APIv2 {
  private root: string;
  private httpHeaders: HttpHeaders;
  private noAuthHttp: HttpClient;

  // TODO: Split data
  public assetMap = new Map<string, XRAsset>();
  public assetVersionMap = new Map<string, XRAssetVersion>();

  constructor(
    private http: HttpClient,
    private accountService: AccountService,
    handler: HttpBackend,
  ) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.noAuthHttp = new HttpClient(handler);
    this.root = `${environment[this.accountService.region].crudApiUrl}/v2`;
  }

  async getLibraryAssets(take = 1335, skip = 0) {
    const route = 'Asset/List';
    // const route = 'Asset/OXR/List';
    const query = `?take=${take}&skip=${skip}&includeDemo=${true}`;
    return lastValueFrom(this.http.get<IAsset[]>(`${this.root}/${route}${query}`).pipe(retry(3)));
  }

  async getEditorAssets(take = 100, skip = 0) {
    const route = 'Asset/List';
    // const route = 'Asset/Editor/List';
    const query = `?take=${take}&skip=${skip}`;
    return lastValueFrom(this.http.get<IAsset[]>(`${this.root}/${route}${query}`).pipe(retry(3)));
  }

  async getAssetById(id: string, key?: string, editor = false, force = false) {
    if (this.assetMap.has(id)) {
      if (force) {
        const asset = this.assetMap.get(id)!;
        asset.versions.forEach((version) => {
          this.assetVersionMap.delete(version.Id);
        });
        this.assetMap.delete(id);
      } else if (editor) {
        const asset = this.assetMap.get(id)!;
        asset.versions.forEach((version, i) => {
          version.Parameters = JSON.parse(asset.Versions[i].Parameters);
        });
        this.assetMap.set(id, asset);
      } else {
        return this.assetMap.get(id);
      }
    }
    const route = `Asset/${editor ? 'Editor' : 'OXR'}`;
    return lastValueFrom(
      (key ? this.noAuthHttp.get<IAsset>(`${this.root}/Asset/${id}?key=${key}`) : this.http.get<IAsset>(`${this.root}/Asset/${id}`))
        // (key
        //   ? this.noAuthHttp.get<IAsset>(`${this.root}/${route}/${id}?key=${key}`)
        //   : this.http.get<IAsset>(`${this.root}/${route}/${id}`)
        // )
        .pipe(
          retry(3),
          map((data) => {
            if (!data) {
              return undefined;
            }
            const asset = newModel(data, ModelType.Asset) as XRAsset;
            asset.versions = asset.Versions.map((version) => {
              const v = newModel(version, ModelType.AssetVersion) as XRAssetVersion;
              v.Type = asset.Type;
              v.Parameters = JSON.parse(version.Parameters);
              v.asset = asset;
              this.assetVersionMap.set(version.Id, v);
              return v;
            });
            this.assetMap.set(id, asset);
            return this.assetMap.get(id) as XRAsset;
          }),
          catchError(async () => undefined),
        ),
    );
  }

  postNewAsset(asset: XRAsset, elements: (TObjectElement | TEnvironmentElement)[]) {
    let Parameters: IAssetVersion['Parameters'] = {};
    switch (asset.Type) {
      case AssetType.Environment:
        const element = elements[0] as TEnvironmentElement;
        Parameters = {
          level: element.parameters.level,
          tint: element.parameters.tint,
          // intensity: element.parameters.intensity,
          // rotation: element.parameters.rotation,
        };
        break;
      case AssetType.Object:
        // Only environment element is needed for default version parameters
        const environment = elements.find(({ type }) => type === ElementType.Environment) as TEnvironmentElement;
        Parameters.environment = {
          intensity: environment.parameters?.intensity ?? ENVIRONMENT_INTENSITY,
          tint: environment.parameters?.tint ?? ENVIRONMENT_TINT,
          level: environment.parameters?.level ?? ENVIRONMENT_LEVEL,
          rotation: environment.parameters?.rotation ?? ENVIRONMENT_ROTATION,
        };
        /**
         * Other parameters need to be current defaults.
         * Transformations and customization will be applied on the file.
         */
        // Parameters.customization
        Parameters.position = OBJECT_POSITION;
        Parameters.quaternion = OBJECT_QUATERNION;
        Parameters.scaling = [1, 1, 1];
        break;
      default:
        break;
    }
    return lastValueFrom(
      this.http
        .post<IAsset>(
          `${this.root}/Asset`, // Only for Asset (AssetSet)
          {
            Id: asset.Id,
            Name: asset.Name,
            Type: asset.Type,
            Url: asset.Url,
            Thumbnail: asset.Thumbnail,
            Tags: asset.Tags,
            // Categories: asset.Categories,
            // Owner: { Id, DisplayName, Thumbnail },
            Versions: [
              {
                Id: uuid(),
                V: '1.0',
                Type: asset.Type,
                Name: 'Default Version',
                Parameters: JSON.stringify(Parameters),
              },
            ],
          },
          { headers: this.httpHeaders },
        )
        .pipe(retry(3)),
    );
  }

  async putAsset(asset: XRAsset) {
    return lastValueFrom(
      this.http
        .put<IAsset>(
          `${this.root}/Asset/${asset.Id}`,
          {
            Id: asset.Id,
            Name: asset.Name,
            Type: asset.Type,
            Url: asset.Url, // Disable modifying URL
            Thumbnail: asset.Thumbnail,
            Tags: asset.Tags,
            // Categories: asset.Categories,
            // Owner: { Id, DisplayName, Thumbnail },
            Versions: asset.versions.map((version) => ({
              AssetId: asset.Id,
              Id: version.isDirty ? uuid() : version.Id,
              V: version.V,
              Type: version.Type,
              Name: version.Name,
              Thumbnail: version.Thumbnail,
              Parameters: JSON.stringify(version.Parameters),
            })),
          },
          { headers: this.httpHeaders },
        )
        .pipe(retry(3)),
    );
  }

  async resetAsset(asset: XRAsset) {
    return lastValueFrom(
      this.http.get<IAsset>(`${this.root}/Asset/Reset/${asset.Id}`, { headers: this.httpHeaders }),
      // .pipe(retry(3)),
    );
  }

  createNewAsset(file: File, description: IAsset) {
    this.clearNewAsset();

    const assetVersion = newModel({ Id: NEW_ASSET_VERSION_ID, Parameters: '{}' } as any, ModelType.AssetVersion) as XRAssetVersion;
    const asset = newModel(
      { ...description, Id: NEW_ASSET_ID, Versions: [assetVersion], ModelType: ModelType.Asset } as IAsset,
      ModelType.Asset,
    ) as XRAsset;
    asset.file = file;
    assetVersion.asset = asset;
    asset.versions = [assetVersion];

    this.assetVersionMap.set(NEW_ASSET_VERSION_ID, assetVersion);
    this.assetMap.set(NEW_ASSET_ID, asset);

    return asset;
  }

  clearNewAsset() {
    this.assetMap.delete(NEW_ASSET_ID);
    this.assetVersionMap.delete(NEW_ASSET_VERSION_ID);
  }

  /**
   * ASSET VERSIONS
   */

  /**
   * Gets asset version data from asset version ID
   * @param id asset version ID
   * @param key to fetch asset version without authentication
   */
  async getAssetVersionById(id: string, key?: string, force = false) {
    if (!force && this.assetVersionMap.has(id)) {
      return this.assetVersionMap.get(id);
    }
    const route = 'Asset/ByVersionId';
    return lastValueFrom(
      (key
        ? this.noAuthHttp.get<unknown>(`${this.root}/${route}/${id}?key=${key}`)
        : this.http.get<unknown>(`${this.root}/${route}/${id}`)
      ).pipe(
        retry(3),
        map(async (data) => {
          if (!data) {
            return undefined;
          }
          const asset = data as IAsset;
          const assetVersion = asset.Versions?.find(({ Id }) => Id === id) as IAssetVersion;
          if (!assetVersion) {
            return;
          }
          let a = this.assetMap.get(asset.Id);
          if (!a) {
            a = newModel(asset, ModelType.Asset) as XRAsset;
            this.assetMap.set(asset.Id, a);
          }
          const v = newModel(assetVersion, ModelType.AssetVersion) as XRAssetVersion;
          v.asset = a;
          this.assetVersionMap.set(assetVersion.Id, v);

          return v;
        }),
        catchError(async () => undefined),
      ),
    );
  }

  async getBatchAssetVersions(ids: string[]) {
    if (!ids.length) {
      return;
    }
    return lastValueFrom(
      this.noAuthHttp.post<IAsset[]>(`${this.root}/Asset/ByVersionIds`, ids).pipe(
        retry(3),
        map((data) => {
          if (!data) {
            return;
          }
          (data as IAsset[]).forEach((a) => {
            const asset = newModel(a, ModelType.Asset) as XRAsset;
            asset.versions = asset.Versions.map((version) => {
              const v = newModel(version, ModelType.AssetVersion) as XRAssetVersion;
              v.Type = asset.Type;
              v.Parameters = JSON.parse(version.Parameters ?? '{}');
              v.asset = asset;
              this.assetVersionMap.set(version.Id, v);
              return v;
            });
            this.assetMap.set(asset.Id, asset);
          });
        }),
        catchError(async () => [
          new Promise((resolve) => {
            console.error('Failed to fetch asset versions');
            resolve(true);
          }),
        ]),
      ),
    );
  }

  async publishNewAssetVersion(assetVersion: XRAssetVersion, elements: (TObjectElement | TEnvironmentElement)[]) {
    let Parameters = {};
    switch (assetVersion.asset.Type) {
      case AssetType.Environment:
        const element = elements[0] as TEnvironmentElement;
        Parameters = {
          intensity: element.parameters.intensity,
          level: element.parameters.level,
          tint: element.parameters.tint,
          rotation: element.parameters.rotation,
        };
        break;
      case AssetType.Object:
        const environment = elements.find(({ type }) => type === ElementType.Environment) as TEnvironmentElement;
        const obj = elements.find(({ type }) => type === ElementType.Object) as TObjectElement;
        Parameters = {
          environment: {
            intensity: environment.parameters.intensity,
            tint: environment.parameters.tint,
            level: environment.parameters.level,
            rotation: environment.parameters.rotation,
          },
          customization: obj.parameters.customization,
          position: obj.parameters.position,
          quaternion: obj.parameters.quaternion,
          scaling: obj.parameters.scaling,
        };
        break;
      default:
        break;
    }
    return lastValueFrom(
      this.http
        .put<IAsset>(
          `${this.root}/Asset/${assetVersion.asset.Id}`,
          {
            Id: assetVersion.asset.Id,
            Name: assetVersion.asset.Name,
            Type: assetVersion.asset.Type,
            Url: assetVersion.asset.Url, // Disable modifying URL
            Thumbnail: assetVersion.asset.Thumbnail,
            Tags: assetVersion.asset.Tags,
            // Categories: assetVersion.asset.Categories,
            // Owner: { Id, DisplayName, Thumbnail },
            Versions: assetVersion.asset.Versions.map((version) => ({
              // Original versions
              AssetId: version.AssetId,
              Id: version.Id,
              V: version.V,
              Type: version.Type,
              Name: version.Name,
              Thumbnail: version.Thumbnail,
              Parameters: version.Parameters,
            })).concat({
              // New version
              AssetId: assetVersion.asset.Id,
              Id: uuid(),
              V: Number(assetVersion.V.split('.')[0]) + 1 + '.0',
              Type: assetVersion.asset.Type,
              Name: assetVersion.Name,
              Thumbnail: assetVersion.Thumbnail,
              Parameters: JSON.stringify(Parameters),
            }),
          },
          { headers: this.httpHeaders },
        )
        .pipe(retry(3)),
    );
  }
}
