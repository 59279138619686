export enum DomainStatus {
  Expired = 'expired',
  Connecting = 'connecting', //When VerificationStatus is Pending
  Connected = 'connected', //When VerificationStatus is Succeded
  Error = 'error', //When VerificationStatus is Failed
  Disabled = 'disabled',
}

// Backend Side Status
export enum VerificationStatus {
  Pending = 'Pending',
  Succeded = 'Succeded',
  Failed = 'Failed',
}

export enum DomainType {
  Subdomain = 'Subdomain',
  Pointer = 'Pointer',
  Owned = 'Owned',
}
