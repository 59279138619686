<ui-panel [title]="'oxr.elements.avatar' | translate" [collapsing]="true" [panelId]="panelMask.Avatar">
  <ui-switch
    class="icon-style-switch"
    [checked]="avatarEnabled"
    [label]="'oxr.creatingSpace.panels.avatar.readyPlayerMe' | translate"
    [onShowContentMode]="true"
    (changed)="onAvatarToggle($event)"
  ></ui-switch>
  <div class="avatar-link" [class.disabled]="!avatarEnabled">
    <input uiInput type="text" [placeholder]="'oxr.creatingSpace.panels.avatar.enterLink' | translate" [(ngModel)]="avatar" />
    <button ui-outlined-button xxs secondary (click)="applyAvatar()">
      {{ 'oxr.creatingSpace.panels.common.apply' | translate }}
    </button>
  </div>
</ui-panel>
