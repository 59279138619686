import { _Exporter } from "../glTFExporter.js";
import { PBRBaseMaterial } from "@babylonjs/core/Materials/PBR/pbrBaseMaterial.js";
import { Tools } from "@babylonjs/core/Misc/tools.js";
const NAME = "KHR_materials_clearcoat";
/**
 * @internal
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class KHR_materials_clearcoat {
  constructor(exporter) {
    /** Name of this extension */
    this.name = NAME;
    /** Defines whether this extension is enabled */
    this.enabled = true;
    /** Defines whether this extension is required */
    this.required = false;
    this._wasUsed = false;
    this._exporter = exporter;
  }
  dispose() {}
  /** @internal */
  get wasUsed() {
    return this._wasUsed;
  }
  postExportMaterialAdditionalTextures(context, node, babylonMaterial) {
    const additionalTextures = [];
    if (babylonMaterial instanceof PBRBaseMaterial) {
      if (babylonMaterial.clearCoat.isEnabled) {
        if (babylonMaterial.clearCoat.texture) {
          additionalTextures.push(babylonMaterial.clearCoat.texture);
        }
        if (!babylonMaterial.clearCoat.useRoughnessFromMainTexture && babylonMaterial.clearCoat.textureRoughness) {
          additionalTextures.push(babylonMaterial.clearCoat.textureRoughness);
        }
        if (babylonMaterial.clearCoat.bumpTexture) {
          additionalTextures.push(babylonMaterial.clearCoat.bumpTexture);
        }
        return additionalTextures;
      }
    }
    return [];
  }
  postExportMaterialAsync(context, node, babylonMaterial) {
    return new Promise(resolve => {
      if (babylonMaterial instanceof PBRBaseMaterial) {
        if (!babylonMaterial.clearCoat.isEnabled) {
          resolve(node);
          return;
        }
        this._wasUsed = true;
        node.extensions = node.extensions || {};
        const clearCoatTextureInfo = this._exporter._glTFMaterialExporter._getTextureInfo(babylonMaterial.clearCoat.texture);
        let clearCoatTextureRoughnessInfo;
        if (babylonMaterial.clearCoat.useRoughnessFromMainTexture) {
          clearCoatTextureRoughnessInfo = this._exporter._glTFMaterialExporter._getTextureInfo(babylonMaterial.clearCoat.texture);
        } else {
          clearCoatTextureRoughnessInfo = this._exporter._glTFMaterialExporter._getTextureInfo(babylonMaterial.clearCoat.textureRoughness);
        }
        if (babylonMaterial.clearCoat.isTintEnabled) {
          Tools.Warn(`Clear Color tint is not supported for glTF export. Ignoring for: ${babylonMaterial.name}`);
        }
        if (babylonMaterial.clearCoat.remapF0OnInterfaceChange) {
          Tools.Warn(`Clear Color F0 remapping is not supported for glTF export. Ignoring for: ${babylonMaterial.name}`);
        }
        const clearCoatNormalTextureInfo = this._exporter._glTFMaterialExporter._getTextureInfo(babylonMaterial.clearCoat.bumpTexture);
        const clearCoatInfo = {
          clearcoatFactor: babylonMaterial.clearCoat.intensity,
          clearcoatTexture: clearCoatTextureInfo !== null && clearCoatTextureInfo !== void 0 ? clearCoatTextureInfo : undefined,
          clearcoatRoughnessFactor: babylonMaterial.clearCoat.roughness,
          clearcoatRoughnessTexture: clearCoatTextureRoughnessInfo !== null && clearCoatTextureRoughnessInfo !== void 0 ? clearCoatTextureRoughnessInfo : undefined,
          clearcoatNormalTexture: clearCoatNormalTextureInfo !== null && clearCoatNormalTextureInfo !== void 0 ? clearCoatNormalTextureInfo : undefined,
          hasTextures: () => {
            return clearCoatInfo.clearcoatTexture !== null || clearCoatInfo.clearcoatRoughnessTexture !== null || clearCoatInfo.clearcoatRoughnessTexture !== null;
          }
        };
        node.extensions[NAME] = clearCoatInfo;
      }
      resolve(node);
    });
  }
}
_Exporter.RegisterExtension(NAME, exporter => new KHR_materials_clearcoat(exporter));
