import { ViewManager } from '..';
import { AbstractMesh, Nullable, Observer, PickingInfo, PointerEventTypes, PointerInfo, Quaternion, Vector3 } from '../../babylon';
import { ElementType, SNAPPABLE_TYPES, TElement, ViewTool } from '../types';

const SNAP_OFFSET = 0.01;

export class SnapTool {
  name = ViewTool.Snap;
  type: ElementType;
  // TODO: hookedRenderable: Renderable[] = [];
  hookedMesh: AbstractMesh | null;
  elements: TElement[] = [];
  isLocked: boolean;

  private _pointerObserver: Nullable<Observer<PointerInfo>>;
  private _pickInfo: PickingInfo;

  private _normal: Vector3;
  private _boundingDimensions = Vector3.Zero();
  private _originalPosition = Vector3.Zero();
  private _originalQuaternion = Quaternion.Identity();
  private _rotation = Vector3.Zero();

  private _boundingVectors: { min: Vector3; max: Vector3 };

  constructor(private viewManager: ViewManager) {
    this.onPointer = this.onPointer.bind(this);
  }

  get isHooked() {
    return !!this.hookedMesh;
  }

  setup(elements: TElement[]) {
    this.clear();
    if (this.viewManager.gizmoLayer) {
      this.viewManager.gizmoLayer.pickingEnabled = false;
    }

    this.hookedMesh = elements.length === 1 ? this.viewManager.appService.getViewRenderable(elements[0])?.helper ?? null : null;

    if (this.hookedMesh) {
      this._originalPosition = this.hookedMesh.position.clone();
      this._originalQuaternion = this.hookedMesh.rotationQuaternion?.clone() ?? Quaternion.Identity();
      this._boundingDimensions.copyFrom(this.viewManager.getMeshBounds(this.hookedMesh));
    } else {
      this.viewManager.setTool(ViewTool.Select);
      return;
    }

    this._pointerObserver = this.viewManager.scene.onPointerObservable.add(this.onPointer);
    this.elements.push(...elements);
    this.isLocked = true;
  }

  clear() {
    this.elements.length = 0;
    this.hookedMesh = null;
    this._pointerObserver && this.viewManager.scene.onPointerObservable.remove(this._pointerObserver);
    this._pointerObserver = null;
    this.isLocked = false;
  }

  interact() {
    return;
  }

  update() {
    return;
  }

  reset() {
    if (this.hookedMesh && this._originalPosition) {
      this.hookedMesh.position.copyFrom(this._originalPosition ?? this.hookedMesh.position);
      this.hookedMesh.rotationQuaternion = this._originalQuaternion ?? this.hookedMesh.rotationQuaternion;
    }
  }

  onPointer(pointerInfo: PointerInfo) {
    if (!this.hookedMesh) {
      return;
    }

    this._pickInfo = this.viewManager.scene.pick(
      this.viewManager.scene.pointerX,
      this.viewManager.scene.pointerY,
      (mesh) =>
        mesh.isEnabled() &&
        mesh !== this.hookedMesh &&
        !mesh.isDescendantOf(this.hookedMesh!) &&
        SNAPPABLE_TYPES.includes(mesh.metadata?.helperOf?.element?.type),
      false,
      undefined,
      (p0, p1, p2, ray) => Vector3.Dot(ray.direction, Vector3.Cross(p0.subtract(p1), p2.subtract(p1))) > 0,
    );

    if (pointerInfo.type === PointerEventTypes.POINTERMOVE) {
      if (this._pickInfo.pickedPoint) {
        this._normal = (this._pickInfo.getNormal(true, true) as Vector3).normalize();
        this._rotation.set(0, Math.atan2(this._normal.x, this._normal.z), 0);
        this.hookedMesh.rotationQuaternion?.copyFrom(this._rotation.toQuaternion());
        this.hookedMesh.computeWorldMatrix(true);
        this._boundingVectors = this.hookedMesh.getHierarchyBoundingVectors();

        this.hookedMesh.setAbsolutePosition(
          this._pickInfo.pickedPoint.add(
            this._normal.multiplyByFloats(
              this._boundingDimensions.z + SNAP_OFFSET,
              this.hookedMesh.position.y - this._boundingVectors.min.y + SNAP_OFFSET,
              this._boundingDimensions.z + SNAP_OFFSET,
            ),
          ),
        );
      }
      this.isLocked = true;
    } else if (pointerInfo.type === PointerEventTypes.POINTERDOWN) {
      this.isLocked = false;
    } else if (pointerInfo.type === PointerEventTypes.POINTERUP && !this.isLocked) {
      this.viewManager.scene.onAfterRenderObservable.addOnce(() => {
        this.hookedMesh?.metadata?.helperOf?.updateElement();
        this.viewManager.setTool(ViewTool.Select, [...this.elements]);
      });
    }
  }

  onDelete() {
    this.onEscape();
  }

  onEscape() {
    this.reset();
    this.clear();
    this.viewManager.setTool(ViewTool.Select);
  }
}
