import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { LanguageCode } from 'projects/data/src/lib/services/account.service';
import { Subject, takeUntil } from 'rxjs';
import { AccountPanelService } from '../../account-panel.service';

@Component({
  selector: 'ui-account-panel-language',
  templateUrl: './account-panel-language.component.html',
  styleUrls: ['./account-panel-language.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountPanelLanguageComponent implements OnDestroy {
  languageCode = LanguageCode;
  selectedLang: LanguageCode;
  loading = false;

  private _destroy$ = new Subject<void>();

  constructor(private _accountPanelService: AccountPanelService) {
    this._accountPanelService.currentLang$.pipe(takeUntil(this._destroy$)).subscribe((lang: LanguageCode) => {
      this.selectedLang = lang;
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  async onChangeLanguage() {
    this.loading = true;
    await this._accountPanelService.saveLanguage(this.selectedLang);
    this.loading = false;
    this._accountPanelService.closeDetails();
  }
}
