import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, fromEvent, Observable, switchMap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'ui-auto-size-input',
  templateUrl: './auto-size-input.component.html',
  styleUrls: ['./auto-size-input.component.scss'],
})
export class AutoSizeInputComponent implements OnInit {
  @Input() placeholder: string;
  @Input() inplaceStaticText: string;
  @Input() value = '';
  @Input() loading = false;
  @Input() disabled = false;
  @Input() onDebounceTime: number = 0;

  @Output() searchTextChange = new EventEmitter<string>();
  @Output() searchTextChangeDebounce = new EventEmitter<string>();
  input$: Observable<Event>;

  constructor(private _elementRef: ElementRef) {}

  ngOnInit(): void {
    if (this.onDebounceTime) {
      const input = this._elementRef.nativeElement.querySelector('input');
      this.input$ = fromEvent(input, 'input');

      this.input$
        .pipe(untilDestroyed(this), debounceTime(this.onDebounceTime), distinctUntilChanged())
        .subscribe(() => this.searchTextChangeDebounce.emit(this.value));
    }
  }

  textChanged(text: string) {
    this.searchTextChange.emit(text);
  }
}
