import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { NotificationType } from 'projects/data/src/lib/enums/notification-type';
import { Subject } from 'rxjs';
import { AccountPanelService } from '../../../account-panel.service';

@Component({
  selector: 'ui-notification-details',
  templateUrl: './notification-details.component.html',
  styleUrls: ['./notification-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationDetailsComponent implements OnDestroy {
  notificationTypes = NotificationType;
  notification$ = this._accountPanelService.selectedNotification$;

  private _destroy$ = new Subject<void>();

  constructor(private _accountPanelService: AccountPanelService) {}

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
