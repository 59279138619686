import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: `ui-loader`,
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements OnInit {
  @Input() set fullScreen(value: boolean) {
    if (value) {
      this._host.nativeElement.classList.add('full-screen');
    } else {
      this._host.nativeElement.classList.remove('full-screen');
    }
  }

  @Input() set loaderColor(color: string) {
    this._host.nativeElement.classList.add(color);
  }

  constructor(private _host: ElementRef) {}

  ngOnInit(): void {}
}
