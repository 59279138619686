<div class="popup-content-modal popup-gallery" #popupWrapper>
  <section class="modal-header">
    <div class="header-title">{{ title || 'shared.fields.titlePlaceholder' | translate }}</div>
    <div class="close icon-wrapper" (click)="close()">
      <div class="icon icon-close sm"></div>
    </div>
  </section>
  <section class="modal-body">
    <div class="scroll-container" #container>
      <div class="carousel">
        @for (image of displayedImages; track image; let i = $index) {
          <div class="carousel-item" [class.readonly]="isPreviewMode || isReadonly" [style.backgroundColor]="image.color.background">
            <div
              class="layout-container drag-drop-container"
              [ngClass]="{
                editing: !isPreviewMode && !isReadonly,
                'horizontal-layout': image.layout === layouts.EditorBelow,
                'vertical-layout': image.layout === layouts.EditorRight,
                'horizontal-layout-no-edit': image.layout === layouts.NoEditor
              }"
            >
              <div class="position-1" #position1>
                <div
                  class="drop-section"
                  (drop)="onDrop($event, 'position1', currentImage)"
                  (dragover)="allowDrop($event)"
                  (dragenter)="onDragEnter($event, 'position1')"
                  (dragleave)="onDragLeave($event)"
                ></div>
                <ng-container
                  [ngTemplateOutlet]="
                    image.positions['position1'] === 'title'
                      ? titleTemplate
                      : image.positions['position1'] === 'content'
                        ? content
                        : image.positions['position1'] === 'description'
                          ? description
                          : null
                  "
                  [ngTemplateOutletContext]="{ $implicit: image, slot: 'position1', index: i }"
                ></ng-container>
              </div>
              @if (image.layout === layouts.EditorRight) {
                <div class="editor-right-container">
                  <ng-container [ngTemplateOutlet]="midContent"></ng-container>
                </div>
              }
              @if (image.layout !== layouts.EditorRight) {
                <ng-container [ngTemplateOutlet]="midContent"></ng-container>
              }
              <ng-template #midContent>
                <div class="position-2" #position2>
                  <div
                    class="drop-section"
                    (drop)="onDrop($event, 'position2', currentImage)"
                    (dragover)="allowDrop($event)"
                    (dragenter)="onDragEnter($event, 'position2')"
                    (dragleave)="onDragLeave($event)"
                  ></div>
                  <ng-container
                    [ngTemplateOutlet]="
                      image.positions['position2'] === 'title'
                        ? titleTemplate
                        : image.positions['position2'] === 'content'
                          ? content
                          : image.positions['position2'] === 'description'
                            ? description
                            : null
                    "
                    [ngTemplateOutletContext]="{ $implicit: image, slot: 'position2', index: i }"
                  ></ng-container>
                </div>
                <div class="position-3" #position3>
                  <ng-container
                    [ngTemplateOutlet]="
                      image.positions['position3'] === 'title'
                        ? titleTemplate
                        : image.positions['position3'] === 'content'
                          ? content
                          : image.positions['position3'] === 'description'
                            ? description
                            : null
                    "
                    [ngTemplateOutletContext]="{ $implicit: image, slot: 'position3', index: i }"
                  ></ng-container>
                  <div
                    class="drop-section"
                    (drop)="onDrop($event, 'position3', currentImage)"
                    (dragover)="allowDrop($event)"
                    (dragenter)="onDragEnter($event, 'position3')"
                    (dragleave)="onDragLeave($event)"
                  ></div>
                </div>
              </ng-template>
              <div class="position-4" #position4>
                <ng-container
                  [ngTemplateOutlet]="
                    image.positions['position4'] === 'title'
                      ? titleTemplate
                      : image.positions['position4'] === 'content'
                        ? content
                        : image.positions['position4'] === 'description'
                          ? description
                          : null
                  "
                  [ngTemplateOutletContext]="{ $implicit: image, slot: 'position4', index: i }"
                ></ng-container>
                <div
                  class="drop-section"
                  (drop)="onDrop($event, 'position4', currentImage)"
                  (dragover)="allowDrop($event)"
                  (dragenter)="onDragEnter($event, 'position4')"
                  (dragleave)="onDragLeave($event)"
                ></div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
    <section class="footer">
      @if (!!contextMenuValue) {
        <div class="buttons-container"></div>
        <div class="buttons-container alt-input">
          <input
            uiInput
            fixed
            type="text"
            placeholder="{{ contextMenuValue.name | translate }}"
            [value]="currentImage[contextMenuValue.value || '']"
            #altTextInput
          />
          <button ui-button sm secondary (click)="cancelAltInput()">
            {{ 'shared.actions.cancel' | translate }}
          </button>
          <button ui-button sm (click)="setCurrentImageAltValue(altTextInput)">
            {{ 'shared.actions.save' | translate }}
          </button>
        </div>
      } @else {
        <div class="buttons-container">
          @if (!isPreviewMode && !isReadonly) {
            <div class="alignment-btn-group">
              @for (layout of spaceLayouts; track layout) {
                <div
                  [ngClass]="layout.icon"
                  (click)="setCurrentImageSpaceLayout(layout.value)"
                  [class.selected]="currentImage.layout === layout.value"
                ></div>
              }
            </div>
            <ui-color-palette
              [type]="'icon'"
              (onChange)="setCurrentImageBackgroundColor($event)"
              [color]="currentImage.color.background"
              [isScenePlanPropertyRestriction]="scenePlan"
            >
            </ui-color-palette>
          }
        </div>
        <div class="pagination">
          <div class="icon icon-arrow-left-black left-arrow" (click)="previousItem()" [class.disabled]="!canScrollLeft"></div>
          <div class="counter">{{ currentPage + '/' + displayedImages.length }}</div>
          <div class="icon icon-arrow-right-black right-arrow" [class.disabled]="!canScrollRight" (click)="nextItem()"></div>
        </div>
        <div class="buttons-container">
          @if (!isPreviewMode && !isReadonly) {
            <button [disabled]="!isImageUploaded" ui-button sm secondary (click)="enterPreviewMode()">
              {{ 'shared.actions.preview' | translate }}
            </button>
          }
          @if (isPreviewMode && !isReadonly) {
            <button ui-button sm (click)="isPreviewMode = false">
              {{ 'shared.actions.edit' | translate }}
            </button>
          }
          @if (!isPreviewMode && !isReadonly) {
            <button [disabled]="!isImageUploaded" ui-button sm (click)="save()">
              {{ 'shared.actions.save' | translate }}
            </button>
          }
        </div>
      }
      <ng-template #footer>
        <div class="buttons-container">
          @if (!isPreviewMode && !isReadonly) {
            <div class="alignment-btn-group">
              @for (layout of spaceLayouts; track layout) {
                <div
                  [ngClass]="layout.icon"
                  (click)="setCurrentImageSpaceLayout(layout.value)"
                  [class.selected]="currentImage.layout === layout.value"
                ></div>
              }
            </div>
            <ui-color-palette
              [type]="'icon'"
              (onChange)="setCurrentImageBackgroundColor($event)"
              [color]="currentImage.color.background"
              [isScenePlanPropertyRestriction]="scenePlan"
            >
            </ui-color-palette>
          }
        </div>
        <div class="pagination">
          <div class="icon icon-arrow-left-black left-arrow" (click)="previousItem()" [class.disabled]="!canScrollLeft"></div>
          <div class="counter">{{ currentPage + '/' + displayedImages.length }}</div>
          <div class="icon icon-arrow-right-black right-arrow" [class.disabled]="!canScrollRight" (click)="nextItem()"></div>
        </div>
        <div class="buttons-container">
          @if (!isPreviewMode && !isReadonly) {
            <button [disabled]="!isImageUploaded" ui-button sm secondary (click)="enterPreviewMode()">
              {{ 'shared.actions.preview' | translate }}
            </button>
          }
          @if (isPreviewMode && !isReadonly) {
            <button ui-button sm (click)="isPreviewMode = false">
              {{ 'shared.actions.edit' | translate }}
            </button>
          }
          @if (!isPreviewMode && !isReadonly) {
            <button [disabled]="!isImageUploaded" ui-button sm (click)="save()">
              {{ 'shared.actions.save' | translate }}
            </button>
          }
        </div>
      </ng-template>
    </section>
  </section>
</div>
<ng-template #titleTemplate let-slot="slot" let-image>
  @if ((isPreviewMode || isReadonly) && title) {
    <div class="title-wrapper drag-item" [style.color]="image.color.text || 'transparent'">
      <div class="text none-text-transform" uiOverflowReadmore>{{ title }}</div>
    </div>
  }
  @if (!isPreviewMode && !isReadonly) {
    <div
      class="title-wrapper drag-item"
      [class.edit]="!isPreviewMode && !isReadonly"
      [draggable]="!isPreviewMode && !isReadonly && !!sectionDraggable['title']"
      (dragstart)="onDrag($event, image.layout, slot)"
      (dragend)="onDragend($event)"
    >
      <div class="drag-button" (mousedown)="sectionDraggable['title'] = true" (mouseup)="sectionDraggable['title'] = false">
        <i class="icon-drag"></i>
      </div>
      <ui-color-palette
        [type]="'icon'"
        [color]="image.color.text"
        (onChange)="setCurrentImageTextColor($event)"
        [color]="image.color.text"
        [position]="slot === 'position4' || slot === 'position3' ? 'top' : 'bottom'"
        [isScenePlanPropertyRestriction]="scenePlan"
      >
      </ui-color-palette>
      <input
        class="none-text-transform"
        uiInput
        fixed
        type="text"
        placeholder="{{ 'shared.fields.titlePlaceholder' | translate }}"
        [(ngModel)]="title"
        [maxlength]="80"
        [style.color]="image.color.text"
      />
    </div>
  }
</ng-template>
<ng-template #content let-image let-slot="slot" let-imageIndex="index">
  <div
    class="content drag-item"
    [draggable]="!isPreviewMode && !isReadonly && !!sectionDraggable['content']"
    (dragstart)="onDrag($event, image.layout, slot)"
    (dragend)="onDragend($event)"
  >
    @if (isPreviewMode || isReadonly) {
      <div class="content-view-wrapper" (click)="goToUrl(image.hyperlink)">
        @if (image.imageUrl) {
          <img class="image" [alt]="image.altText" [src]="image.imageUrl" draggable="false" uiImageOrientation />
        }
        <div class="bg-image" [style.background-image]="'url(' + image.imageUrl + ')'"></div>
      </div>
    }
    <!-- Content Edit mode -->
    @if (!isPreviewMode && !isReadonly) {
      <div class="drag-button" (mousedown)="sectionDraggable['content'] = true" (mouseup)="sectionDraggable['content'] = false">
        <i class="icon-drag"></i>
      </div>
      @if (!!image.imageUrl) {
        <div class="content-remove-button" (click)="image.imageUrl = ''">
          <div class="icon icon-close xs"></div>
        </div>
      }
      <div class="content-editor-wrapper">
        @if (!image.imageUrl && currentPage - 1 === imageIndex) {
          <ui-media-upload-library
            (onSelect)="loadImage($event)"
            [images]="mediaImages"
            [uploadType]="uploadFileType"
          ></ui-media-upload-library>
        }
        @if (image.loadingImage) {
          <div class="loader">
            <ui-loader></ui-loader>
          </div>
        }
        @if (image.imageUrl && !image.loadingImage) {
          <img
            uiImageOrientation
            class="image"
            [alt]="image.altText"
            [src]="image.imageUrl"
            (contextmenu)="onContextMenu($event, image)"
            draggable="false"
          />
          <div class="bg-image" [style.background-image]="'url(' + image.imageUrl + ')'"></div>
        }
        @if (image.showContextMenu) {
          <div class="context-menu" [style.top]="contextMenuPosition.y + 'px'" [style.left]="contextMenuPosition.x + 'px'">
            <ui-context-menu
              [data]="contextMenuOptions"
              (onSelect)="onContextMenuSelect($event)"
              (hide)="image.showContextMenu = false"
            ></ui-context-menu>
          </div>
        }
      </div>
    }
  </div>
</ng-template>
<ng-template #description let-image let-slot="slot">
  @if (image.layout !== layouts.NoEditor) {
    @if (!isPreviewMode && !isReadonly) {
      <div
        class="editor-container drag-item description"
        [draggable]="!isPreviewMode && !isReadonly && !!sectionDraggable['description']"
        (dragstart)="onDrag($event, image.layout, slot)"
        (dragend)="onDragend($event)"
      >
        <div class="drag-button" (mousedown)="sectionDraggable['description'] = true" (mouseup)="sectionDraggable['description'] = false">
          <i class="icon-drag"></i>
        </div>
        <quill-editor
          class="w-100"
          [(ngModel)]="image.description"
          [preserveWhitespace]="true"
          [placeholder]="'oxr.creatingSpace.descriptionPlaceholder' | translate"
        >
          <ui-quill-editor-custom-toolbar quill-editor-toolbar [hide]="['link', 'image', 'video']"></ui-quill-editor-custom-toolbar>
        </quill-editor>
      </div>
    }
    @if ((isPreviewMode || isReadonly) && image.description) {
      <div class="editor-container description drag-item">
        <quill-view [preserveWhitespace]="true" class="w-100 view-mode" [content]="image.description"> </quill-view>
      </div>
    }
  }
</ng-template>
