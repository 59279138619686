import { v4 as uuid } from 'uuid';
import {
  BLUE,
  CheckpointRenderable,
  FORWARD,
  FORWARD_QUATERNION,
  GIZMO_SCALE,
  GREEN,
  INFINITE_PICK,
  InteractableRenderable,
  LightRenderable,
  MAX_SIZE,
  OBJECT_QUATERNION,
  RED,
  RIGHT,
  SELECTION_COLOR,
  ScreenRenderable,
  TextRenderable,
  UP,
  ViewManager,
} from '..';
import {
  BoundingInfo,
  IPointerEventData,
  Matrix,
  Mesh,
  MeshBuilder,
  Nullable,
  Observer,
  PickingInfo,
  PlaneDragGizmo,
  PointerEventTypes,
  PointerInfo,
  PositionGizmo,
  Quaternion,
  RotationGizmo,
  ScaleGizmo,
  Scene,
  Vector3,
} from '../../babylon';
import { ViewManagerUtils } from '../../utils/view-manager-utils';
import { Media } from '../auxiliaries/media';
import { EffectType, ElementType, TElement, TransformationMode, ViewMode, ViewTool } from '../types';
import { computeBoundingInfo } from '../../utils/geometry-utils';

const IGNORE_TYPES = [ElementType.Environment, ElementType.Landscape, ElementType.Stage];
const SNAP_DISTANCE = Math.PI / 18;

type TSelectable = InteractableRenderable | CheckpointRenderable | ScreenRenderable;

enum Axes {
  X = 'x',
  Y = 'y',
  Z = 'z',
}

export class SelectTool {
  name = ViewTool.Select;
  elements: TElement[] = [];
  lastElements: TElement[] = [];
  isLocked: boolean;
  isDragging: boolean;

  private pointerInfo: Nullable<PickingInfo[]>;
  private utilityPointerInfo: PickingInfo;
  private nearestPick: Nullable<PickingInfo>;
  private forcedElement?: TElement;
  private moveCount: number = 0;
  private _multiple: boolean;
  private _hoveredMedia?: Media;

  private positionGizmo: Nullable<PositionGizmo>;
  private rotationGizmo: Nullable<RotationGizmo>;
  private scaleGizmo: Nullable<ScaleGizmo>;
  private xPlaneGizmo: Nullable<PlaneDragGizmo>;
  private yPlaneGizmo: Nullable<PlaneDragGizmo>;
  private zPlaneGizmo: Nullable<PlaneDragGizmo>;

  private observers: {
    follow?: Nullable<Observer<Scene>>;
    pointer?: Nullable<Observer<PointerInfo>>;
    xPosition?: Nullable<Observer<IPointerEventData>>;
    yPosition?: Nullable<Observer<IPointerEventData>>;
    zPosition?: Nullable<Observer<IPointerEventData>>;
    positionEnd?: Nullable<Observer<unknown>>;
    xPlane?: Nullable<Observer<IPointerEventData>>;
    yPlane?: Nullable<Observer<IPointerEventData>>;
    zPlane?: Nullable<Observer<IPointerEventData>>;
    xPlaneEnd?: Nullable<Observer<{ dragPlanePoint: Vector3; pointerId: number; pointerInfo: Nullable<PointerInfo> }>>;
    yPlaneEnd?: Nullable<Observer<{ dragPlanePoint: Vector3; pointerId: number; pointerInfo: Nullable<PointerInfo> }>>;
    zPlaneEnd?: Nullable<Observer<{ dragPlanePoint: Vector3; pointerId: number; pointerInfo: Nullable<PointerInfo> }>>;
    xRotationStart?: Nullable<Observer<{ dragPlanePoint: Vector3; pointerId: number; pointerInfo: Nullable<PointerInfo> }>>;
    xRotation?: Nullable<Observer<IPointerEventData>>;
    yRotationStart?: Nullable<Observer<{ dragPlanePoint: Vector3; pointerId: number; pointerInfo: Nullable<PointerInfo> }>>;
    yRotation?: Nullable<Observer<IPointerEventData>>;
    zRotationStart?: Nullable<Observer<{ dragPlanePoint: Vector3; pointerId: number; pointerInfo: Nullable<PointerInfo> }>>;
    zRotation?: Nullable<Observer<IPointerEventData>>;
    rotationEnd?: Nullable<Observer<unknown>>;
    scaleStart?: Nullable<Observer<unknown>>;
    scaleEnd?: Nullable<Observer<unknown>>;
    scaleBoxEnd?: Nullable<Observer<{}>>;
  } = {};

  private selectionMeshes: Mesh[] = [];
  private occlusionMeshes: Mesh[] = [];
  private originalScaling = Vector3.Zero();
  private target: Nullable<Mesh>;
  private _origin: Nullable<Mesh>;
  private originOffset = Vector3.Zero();

  private focusBoundingInfo: BoundingInfo;
  private focusDirection = Quaternion.Identity();
  private focusOffset = Vector3.Zero();

  constructor(private viewManager: ViewManager) {
    this.onPointer = this.onPointer.bind(this);
    this.checkBoundaryX = this.checkBoundaryX.bind(this);
    this.checkBoundaryY = this.checkBoundaryY.bind(this);
    this.checkBoundaryZ = this.checkBoundaryZ.bind(this);
    this.followCallback = this.followCallback.bind(this);
    this.updateElements = this.updateElements.bind(this);
    this.rotateStartCallbackFactory = this.rotateStartCallbackFactory.bind(this);
    this.rotateCallbackFactory = this.rotateCallbackFactory.bind(this);
    this.rotateEndCallback = this.rotateEndCallback.bind(this);
    this.parentMeshes = this.parentMeshes.bind(this);
    this.unparentMeshes = this.unparentMeshes.bind(this);
    this.interact = this.interact.bind(this);
  }

  get isHooked() {
    return !!this.elements.length;
  }

  get multiple() {
    return this._multiple;
  }

  set multiple(flag: boolean) {
    this._multiple = flag;
    if (this.rotationGizmo) {
      this.rotationGizmo.snapDistance = flag ? SNAP_DISTANCE : 0;
    }
  }

  get origin() {
    if (!this._origin) {
      this._origin = MeshBuilder.CreateBox(`SelectOrigin-${uuid()}`, { size: 0.5 }, this.viewManager.scene);
      this._origin.rotationQuaternion = Quaternion.Identity();
      this._origin.isVisible = false;
      this._origin.isPickable = false;
      ViewManagerUtils.AttachMetadata(this._origin, { helperOf: this });
      this.updateOrigin();
    }
    return this._origin;
  }

  get renderables() {
    return this.viewManager.renderables.filter((renderable) =>
      this.elements.some(({ id }) => renderable.elementId === id),
    ) as TSelectable[];
  }

  get scene() {
    return this.viewManager.scene;
  }

  setup(elements: TElement[]) {
    this.clear();
    if (this.viewManager.gizmoLayer) {
      this.viewManager.gizmoLayer.pickingEnabled = true;
    }
    this.isLocked = true;
    if (!this.observers.pointer) {
      this.observers.pointer = this.scene.onPointerObservable.add(this.onPointer);
    }
    this.multiple = elements.length > 1;
    elements.map((element) => {
      this.interact(element);
    });
    this.multiple = false;
  }

  clear() {
    this.clearGizmos();
    this.elements.length = 0;
    this.lastElements.length = 0;
    this.observers.pointer && this.scene.onPointerObservable.remove(this.observers.pointer);
    this.observers.follow && this.scene.onBeforeRenderObservable.remove(this.observers.follow);
    this.observers.pointer = null;
    this.moveCount = 0;

    const isTargetPlayerMode = this.viewManager.targetMode === ViewMode.Player;
    this.selectionMeshes.forEach((mesh) => {
      mesh.renderingGroupId = 0;
      mesh.renderOverlay = isTargetPlayerMode && mesh.metadata?.helperOf?.effect?.descriptor?.type === EffectType.Shine;
    });
    this.occlusionMeshes.forEach((mesh) => {
      mesh.dispose();
    });
    this.selectionMeshes.length = 0;
    this.occlusionMeshes.length = 0;

    this.viewManager.lights.forEach((light) => {
      light.hideHelper();
    });
    this.viewManager.appService.setSelectedElements([]);
    this.update();
  }

  update() {
    this.lastElements.length = 0;
    this.lastElements.push(...this.elements);

    this.selectionMeshes.forEach((mesh) => {
      mesh.renderingGroupId = 2;
    });
    this.occlusionMeshes.forEach((mesh) => {
      mesh.dispose();
    });
    this.selectionMeshes.length = 0;
    this.occlusionMeshes.length = 0;

    [...this.viewManager.checkpoints, ...this.viewManager.objects, ...this.viewManager.screens, ...this.viewManager.texts].forEach(
      (renderable) => {
        if (this.viewManager.utilityEnabled && this.elements.some(({ id }) => renderable.elementId === id)) {
          (
            renderable.helper
              ?.getChildMeshes(false)
              .concat(
                renderable instanceof CheckpointRenderable && !!renderable.avatar
                  ? renderable.avatar.getChildMeshes(false).concat(renderable.avatar)
                  : renderable.helper,
              ) ?? []
          ).forEach((mesh) => {
            if (mesh === renderable.helper.metadata?.box) {
              return;
            }
            mesh.renderOverlay = true;
            mesh.overlayColor.copyFrom(SELECTION_COLOR);
            mesh.overlayAlpha = 0.5;

            this.selectionMeshes.push(mesh as Mesh);
            this.occlusionMeshes.push((mesh as Mesh).clone(undefined, undefined, true));
          });

          this.selectionMeshes.forEach((mesh, i) => {
            mesh.renderingGroupId = 3;
            this.occlusionMeshes[i].material = this.viewManager.occlusionMaterial;
            this.occlusionMeshes[i].renderingGroupId = 2;
            this.occlusionMeshes[i].isPickable = false;
            ViewManagerUtils.AttachMetadata(this.occlusionMeshes[i], { isOverlay: true });
          });
        } else {
          !(renderable instanceof InteractableRenderable && renderable.effect?.isPlaying) &&
            (
              renderable.helper
                ?.getChildMeshes(false)
                .concat(
                  renderable instanceof CheckpointRenderable && !!renderable.avatar
                    ? renderable.avatar.getChildMeshes(false).concat(renderable.avatar)
                    : renderable.helper,
                ) ?? []
            ).forEach((mesh) => {
              mesh.renderingGroupId = 0;
              mesh.renderOverlay = false;
            });
        }
      },
    );

    this.observers.follow && this.scene.onBeforeRenderObservable.remove(this.observers.follow);

    if (this.selectionMeshes.length) {
      this.observers.follow = this.scene.onBeforeRenderObservable.add(this.followCallback);
    }

    this.updateOrigin();
  }

  resetOrigin() {
    if (!this._origin) {
      return;
    }
    this.unparentMeshes();
    this._origin.position.setAll(0);
    this._origin.rotationQuaternion = Quaternion.FromArray(OBJECT_QUATERNION);
    this._origin.scaling.setAll(1);
  }

  updateOrigin() {
    if (!this._origin) {
      return;
    }
    this.resetOrigin();
    this.renderables.forEach((renderable) => {
      this._origin!.position.addInPlace(renderable.helper.position);
    });
    this.renderables.length && this._origin.position.scaleInPlace(1 / this.renderables.length);
    this.originOffset.copyFrom(this._origin.position);
  }

  onPointer(pointerInfo: PointerInfo) {
    this.utilityPointerInfo = this.viewManager.gizmoScene?.pick(this.scene.pointerX, this.scene.pointerY);

    if (!this.isLocked) {
      this.pointerInfo = this.scene.multiPick(
        this.scene.pointerX,
        this.scene.pointerY,
        (mesh) =>
          !mesh?.metadata?.isOverlay &&
          !mesh?.metadata?.box &&
          mesh !== this.viewManager.drawingPlane &&
          mesh !== this.viewManager.boundary &&
          !IGNORE_TYPES.includes(mesh?.metadata?.helperOf?.element?.type),
      );
    }

    if (this.utilityPointerInfo?.hit) {
      if (pointerInfo.type === PointerEventTypes.POINTERUP) {
        this.forcedElement && this.interact(this.forcedElement);
        this.forcedElement = undefined;
      }
    } else {
      if (pointerInfo.type === PointerEventTypes.POINTERDOWN) {
        this.nearestPick =
          this.pointerInfo?.reduce(
            (p, c) =>
              c.pickedMesh?.isEnabled() &&
              p.pickedMesh?.metadata?.helperOf?.element?.type !== ElementType.Light &&
              c.pickedMesh?.metadata?.helperOf &&
              c.distance < p.distance
                ? c
                : p,
            INFINITE_PICK,
          ) ?? null;
        if (!!this.nearestPick?.pickedMesh?.metadata?.drag) {
          this.isDragging = !!this.nearestPick?.hit;
          this.isDragging && this.nearestPick!.pickedMesh!.metadata.drag(this.nearestPick);
        }
        this.isLocked = false;
        this.moveCount = 0;
      } else if (pointerInfo.type === PointerEventTypes.POINTERMOVE) {
        this.moveCount += Math.hypot(pointerInfo.event.movementX ?? 0, pointerInfo.event.movementY ?? 0);
        this.nearestPick =
          this.pointerInfo?.reduce(
            (p, c) =>
              c.pickedMesh?.isEnabled() &&
              p.pickedMesh?.metadata?.helperOf?.element?.type !== ElementType.Light &&
              c.pickedMesh?.metadata?.helperOf &&
              c.distance < p.distance
                ? c
                : p,
            INFINITE_PICK,
          ) ?? null;
        if (this.nearestPick?.pickedMesh) {
          this._hoveredMedia = this.nearestPick.pickedMesh.metadata?.helperOf?.media;
          this._hoveredMedia?.overInteractionCallback();
        } else {
          this._hoveredMedia?.outInteractionCallback();
          this._hoveredMedia = undefined;
        }
        if (!this.isDragging && this.moveCount > 10) {
          this.isLocked = true;
          this.forcedElement = undefined;
        } else if (!this.multiple && this.isDragging) {
          this.nearestPick?.pickedMesh?.metadata?.drag?.(this.nearestPick);
        }
      } else if (pointerInfo.type === PointerEventTypes.POINTERUP) {
        this.isDragging = false;
        if (!this.isLocked) {
          this.nearestPick =
            this.pointerInfo?.reduce(
              (p, c) =>
                c.pickedMesh?.isEnabled() &&
                p.pickedMesh?.metadata?.helperOf?.element?.type !== ElementType.Light &&
                c.pickedMesh?.metadata?.helperOf &&
                c.distance < p.distance
                  ? c
                  : p,
              INFINITE_PICK,
            ) ?? null;
          if (!this.multiple && this.nearestPick?.pickedMesh?.metadata?.click) {
            this.nearestPick.pickedMesh.metadata.click(this.nearestPick);
            return;
          }
          this.interact(this.nearestPick?.pickedMesh?.metadata?.helperOf?.element);
          return;
        }
      }

      if (!this.forcedElement && this.pointerInfo?.[0]?.hit) {
        if (pointerInfo.type === PointerEventTypes.POINTERDOWN) {
          this.isLocked = false;
          this.moveCount = 0;
        } else if (pointerInfo.type === PointerEventTypes.POINTERMOVE) {
          this.moveCount += Math.hypot(pointerInfo.event.movementX ?? 0, pointerInfo.event.movementY ?? 0);
          if (this.moveCount > 10) {
            this.isLocked = true;
            this.forcedElement = undefined;
          }
        } else if (pointerInfo.type === PointerEventTypes.POINTERUP) {
          if (!this.isLocked) {
            const priorityElement = this.pointerInfo
              .map((pickingInfo) => pickingInfo.pickedMesh?.metadata?.helperOf?.element)
              .find((element) => element?.type === ElementType.Light);
            this.interact(priorityElement ?? pointerInfo.pickInfo?.pickedMesh?.metadata?.helperOf?.element);
          }
        }
      }
    }

    if (this.viewManager.activeMode === ViewMode.Creator) {
      if (this.isDragging) {
        this.viewManager.creatorModeControls.detach();
      } else if (!this.viewManager.creatorModeControls.isAttached && !this.isDragging) {
        this.viewManager.creatorModeControls.attach();
      }
    }
  }

  followCallback() {
    this.selectionMeshes.forEach((mesh, i) => {
      this.occlusionMeshes[i].position.copyFrom(mesh.position);
      mesh.rotationQuaternion
        ? this.occlusionMeshes[i].rotationQuaternion?.copyFrom(mesh.rotationQuaternion)
        : this.occlusionMeshes[i].rotation?.copyFrom(mesh.rotation);
      this.occlusionMeshes[i].scaling.copyFrom(mesh.scaling);
    });
  }

  onDelete() {
    if (!this.elements.length || this.elements.some(({ type }) => type === ElementType.Checkpoint)) {
      return;
    }
    this.viewManager.appService.removeViewElements(this.elements);
    this.interact();
  }

  onEscape() {
    this.elements.length && this.interact();
  }

  forceElement(element: TElement) {
    this.forcedElement = element;
  }

  updateElements() {
    this.scene.onBeforeRenderObservable.addOnce(() => {
      this.viewManager.appService.updateViewElements(
        this.renderables.map(
          (renderable) =>
            ({
              ...renderable.element,
              parameters: {
                ...renderable.element.parameters,
                position: renderable.helper.position.asArray(),
                quaternion: renderable.helper.rotationQuaternion?.asArray() ?? OBJECT_QUATERNION,
                scaling: renderable.helper.scaling.asArray(),
              },
            }) as TElement,
        ),
      );
    });
    this.updateOrigin();
  }

  setGizmos() {
    this.clearGizmos();
    if (
      !this.renderables.every((renderable) => renderable instanceof CheckpointRenderable || renderable instanceof InteractableRenderable)
    ) {
      return;
    }

    // TODO: Simplify transformation modes
    const mode = this.viewManager.appService.getTransformationMode();
    this.target = this.renderables.length === 1 ? this.renderables[0].helper : this.origin;
    this.originOffset.copyFrom(this.target.position);
    if (mode & TransformationMode.Translate) {
      this.positionGizmo = new PositionGizmo(this.viewManager.gizmoLayer);
      this.xPlaneGizmo = new PlaneDragGizmo(RIGHT, RED, this.viewManager.gizmoLayer);
      this.yPlaneGizmo = new PlaneDragGizmo(UP, GREEN, this.viewManager.gizmoLayer);
      this.zPlaneGizmo = new PlaneDragGizmo(FORWARD, BLUE, this.viewManager.gizmoLayer);

      this.positionGizmo.attachedMesh = this.target;
      this.xPlaneGizmo.attachedMesh = this.target;
      this.yPlaneGizmo.attachedMesh = this.target;
      this.zPlaneGizmo.attachedMesh = this.target;

      const alignPosition = (renderable: TSelectable) => {
        if (!this.target || this.renderables.length <= 1) {
          return;
        }
        renderable.helper.position.copyFrom(
          Vector3.FromArray(renderable.element.parameters.position!).add(this.target.position).subtract(this.originOffset),
        );
      };

      const xDragCallback = () => {
        this.target && this.checkBoundaryX(this.target);
      };
      const yDragCallback = () => {
        this.target && this.checkBoundaryY(this.target, this.renderables[0] instanceof CheckpointRenderable);
      };
      const zDragCallback = () => {
        this.target && this.checkBoundaryZ(this.target);
      };

      this.observers.xPosition = this.positionGizmo.xGizmo.dragBehavior.onDragObservable.add(() => {
        xDragCallback();
        this.renderables.forEach(alignPosition);
      });
      this.observers.yPosition = this.positionGizmo.yGizmo.dragBehavior.onDragObservable.add(() => {
        yDragCallback();
        this.renderables.forEach(alignPosition);
      });
      this.observers.zPosition = this.positionGizmo.zGizmo.dragBehavior.onDragObservable.add(() => {
        zDragCallback();
        this.renderables.forEach(alignPosition);
      });
      this.observers.positionEnd = this.positionGizmo.onDragEndObservable.add(this.updateElements);

      this.observers.xPlane = this.xPlaneGizmo.dragBehavior.onDragObservable.add(() => {
        yDragCallback();
        zDragCallback();
        this.renderables.forEach(alignPosition);
      });
      this.observers.yPlane = this.yPlaneGizmo.dragBehavior.onDragObservable.add(() => {
        xDragCallback();
        zDragCallback();
        this.renderables.forEach(alignPosition);
      });
      this.observers.zPlane = this.zPlaneGizmo.dragBehavior.onDragObservable.add(() => {
        xDragCallback();
        yDragCallback();
        this.renderables.forEach(alignPosition);
      });
      this.observers.xPlaneEnd = this.xPlaneGizmo.dragBehavior.onDragEndObservable.add(this.updateElements);
      this.observers.yPlaneEnd = this.yPlaneGizmo.dragBehavior.onDragEndObservable.add(this.updateElements);
      this.observers.zPlaneEnd = this.zPlaneGizmo.dragBehavior.onDragEndObservable.add(this.updateElements);
    } else if (mode & TransformationMode.Rotate) {
      this.rotationGizmo = new RotationGizmo(this.viewManager.gizmoLayer);
      this.rotationGizmo.attachedMesh = this.target;
      this.rotationGizmo.updateGizmoRotationToMatchAttachedMesh = !this.target.scaling.isNonUniform;

      if (this.target.metadata.helperOf instanceof CheckpointRenderable) {
        this.rotationGizmo.xGizmo.dispose();
        this.rotationGizmo.zGizmo.dispose();
      } else {
        this.observers.xRotationStart = this.rotationGizmo.xGizmo.dragBehavior.onDragStartObservable.add(
          this.rotateStartCallbackFactory(Axes.X),
        );
        this.observers.xRotation = this.rotationGizmo.xGizmo.dragBehavior.onDragObservable.add(this.rotateCallbackFactory(Axes.X));

        this.observers.zRotationStart = this.rotationGizmo.zGizmo.dragBehavior.onDragStartObservable.add(
          this.rotateStartCallbackFactory(Axes.Z),
        );
        this.observers.zRotation = this.rotationGizmo.zGizmo.dragBehavior.onDragObservable.add(this.rotateCallbackFactory(Axes.Z));
      }

      this.observers.yRotationStart = this.rotationGizmo.yGizmo.dragBehavior.onDragStartObservable.add(
        this.rotateStartCallbackFactory(Axes.Y),
      );
      this.observers.yRotation = this.rotationGizmo.yGizmo.dragBehavior.onDragObservable.add(this.rotateCallbackFactory(Axes.Y));

      this.observers.rotationEnd = this.rotationGizmo.onDragEndObservable.add(this.rotateEndCallback);
    } else if (mode & TransformationMode.Scale) {
      if (this.target.metadata.helperOf instanceof CheckpointRenderable) {
        return;
      } else {
        this.scaleGizmo = new ScaleGizmo(this.viewManager.gizmoLayer);
        this.scaleGizmo.attachedMesh = this.target;

        if (this.target.metadata.helperOf instanceof TextRenderable || this.target.metadata.helperOf instanceof ScreenRenderable) {
          this.scaleGizmo.zGizmo.dispose();
        }

        this.scaleGizmo.uniformScaleGizmo.dragBehavior.useObjectOrientationForDragging = false;
        this.scaleGizmo.uniformScaleGizmo.uniformScaling = true;
        this.scaleGizmo.updateGizmoRotationToMatchAttachedMesh = true;
        this.scaleGizmo.updateGizmoPositionToMatchAttachedMesh = true;

        if (this.renderables.length === 1) {
          this.observers.scaleEnd = this.scaleGizmo.onDragEndObservable.add(this.target.metadata.helperOf.updateElement);
        } else {
          this.observers.scaleStart = this.scaleGizmo.onDragStartObservable.add(this.parentMeshes);
          this.observers.scaleEnd = this.scaleGizmo.onDragEndObservable.add(() => {
            this.unparentMeshes();
            this.updateElements();
          });
        }
      }
    }

    [
      this.positionGizmo?.xGizmo._rootMesh.getChildMeshes()[0],
      this.positionGizmo?.yGizmo._rootMesh.getChildMeshes()[0],
      this.positionGizmo?.zGizmo._rootMesh.getChildMeshes()[0],
      this.rotationGizmo?.xGizmo._rootMesh.getChildMeshes()[0],
      this.rotationGizmo?.yGizmo._rootMesh.getChildMeshes()[0],
      this.rotationGizmo?.zGizmo._rootMesh.getChildMeshes()[0],
      this.scaleGizmo?.xGizmo._rootMesh.getChildMeshes()[0],
      this.scaleGizmo?.yGizmo._rootMesh.getChildMeshes()[0],
      this.scaleGizmo?.zGizmo._rootMesh.getChildMeshes()[0],
      this.scaleGizmo?.uniformScaleGizmo._rootMesh.getChildMeshes()[0],
    ].forEach((mesh) => {
      mesh?.scaling.scaleInPlace(GIZMO_SCALE);
    });

    this.updateOrigin();
  }

  clearGizmos() {
    this.resetOrigin();

    this.observers.xPosition && this.positionGizmo?.xGizmo.dragBehavior.onDragObservable.remove(this.observers.xPosition);
    this.observers.yPosition && this.positionGizmo?.yGizmo.dragBehavior.onDragObservable.remove(this.observers.yPosition);
    this.observers.zPosition && this.positionGizmo?.zGizmo.dragBehavior.onDragObservable.remove(this.observers.zPosition);
    this.observers.positionEnd && this.positionGizmo?.onDragEndObservable.remove(this.observers.positionEnd);
    this.positionGizmo?.dispose();
    this.positionGizmo = null;

    this.observers.xPlane && this.xPlaneGizmo?.dragBehavior.onDragObservable.remove(this.observers.xPlane);
    this.observers.yPlane && this.yPlaneGizmo?.dragBehavior.onDragObservable.remove(this.observers.yPlane);
    this.observers.zPlane && this.zPlaneGizmo?.dragBehavior.onDragObservable.remove(this.observers.zPlane);
    this.observers.xPlaneEnd && this.xPlaneGizmo?.dragBehavior.onDragEndObservable.remove(this.observers.xPlaneEnd);
    this.observers.yPlaneEnd && this.yPlaneGizmo?.dragBehavior.onDragEndObservable.remove(this.observers.yPlaneEnd);
    this.observers.zPlaneEnd && this.zPlaneGizmo?.dragBehavior.onDragEndObservable.remove(this.observers.zPlaneEnd);
    this.xPlaneGizmo?.dispose();
    this.yPlaneGizmo?.dispose();
    this.zPlaneGizmo?.dispose();
    this.xPlaneGizmo = null;
    this.yPlaneGizmo = null;
    this.zPlaneGizmo = null;

    this.observers.xRotationStart && this.rotationGizmo?.yGizmo.dragBehavior.onDragStartObservable.remove(this.observers.xRotationStart);
    this.observers.xRotation && this.rotationGizmo?.yGizmo.dragBehavior.onDragObservable.remove(this.observers.xRotation);
    this.observers.yRotationStart && this.rotationGizmo?.yGizmo.dragBehavior.onDragStartObservable.remove(this.observers.yRotationStart);
    this.observers.yRotation && this.rotationGizmo?.yGizmo.dragBehavior.onDragObservable.remove(this.observers.yRotation);
    this.observers.zRotationStart && this.rotationGizmo?.yGizmo.dragBehavior.onDragStartObservable.remove(this.observers.zRotationStart);
    this.observers.zRotation && this.rotationGizmo?.yGizmo.dragBehavior.onDragObservable.remove(this.observers.zRotation);
    this.observers.rotationEnd && this.rotationGizmo?.onDragEndObservable.remove(this.observers.rotationEnd);
    this.rotationGizmo?.dispose();
    this.rotationGizmo = null;

    this.observers.scaleStart && this.scaleGizmo?.onDragObservable.remove(this.observers.scaleStart);
    this.observers.scaleEnd && this.scaleGizmo?.onDragEndObservable.remove(this.observers.scaleEnd);
    this.scaleGizmo?.dispose();
    this.scaleGizmo = null;
  }

  rotateStartCallbackFactory(axis: Axes) {
    const gizmo = this.rotationGizmo?.[`${axis}Gizmo`];
    return () => {
      if (!this.target) {
        return;
      }
      this.originalScaling.copyFrom(this.rotationGizmo!.attachedMesh?.scaling ?? Vector3.One());
      this.renderables.length > 1 && this.parentMeshes();
      if (this.target.scaling.isNonUniform || !gizmo) {
        return;
      }
      gizmo.customRotationQuaternion = this.target.rotationQuaternion?.clone() ?? Quaternion.Identity();
    };
  }

  rotateCallbackFactory(axis: Axes) {
    const gizmo = this.rotationGizmo?.[`${axis}Gizmo`];
    return () => {
      if (!this.target) {
        return;
      }
      this.rotationGizmo?.attachedMesh?.scaling!.copyFrom(this.originalScaling);
      if (this.target.scaling.isNonUniform || !gizmo) {
        return;
      }
      gizmo.customRotationQuaternion = this.target.rotationQuaternion?.clone() ?? Quaternion.Identity();
    };
  }

  rotateEndCallback() {
    this.resetOrigin();
    if (!this.target) {
      return;
    }
    if (!this.target.scaling.isNonUniform) {
      if (this.rotationGizmo?.xGizmo) {
        this.rotationGizmo.xGizmo.updateGizmoRotationToMatchAttachedMesh = true;
        this.rotationGizmo.xGizmo.customRotationQuaternion = null;
      }
      if (this.rotationGizmo?.zGizmo) {
        this.rotationGizmo.zGizmo.updateGizmoRotationToMatchAttachedMesh = true;
        this.rotationGizmo.zGizmo.customRotationQuaternion = null;
      }
      this.rotationGizmo!.yGizmo.updateGizmoRotationToMatchAttachedMesh = true;
      this.rotationGizmo!.yGizmo.customRotationQuaternion = null;
    }
    this.updateElements();
  }

  parentMeshes() {
    this.renderables.forEach((renderable) => {
      renderable.helper.setParent(this.target);
      this.occlusionMeshes[this.selectionMeshes.indexOf(renderable.helper)].setParent(this.target);
    });
  }

  unparentMeshes() {
    this._origin?.getChildMeshes(true).forEach((child) => {
      child.setParent(null);
    });
  }

  checkBoundaryX(mesh: Mesh) {
    if (mesh.position.x > MAX_SIZE) {
      mesh.position.x = MAX_SIZE;
    } else if (mesh.position.x < -MAX_SIZE) {
      mesh.position.x = -MAX_SIZE;
    }
  }

  checkBoundaryY(mesh: Mesh, limitToGround = false) {
    if (mesh.position.y > MAX_SIZE) {
      mesh.position.y = MAX_SIZE;
    } else if (mesh.position.y < (limitToGround ? 0 : -MAX_SIZE)) {
      mesh.position.y = limitToGround ? 0 : -MAX_SIZE;
    }
  }

  checkBoundaryZ(mesh: Mesh) {
    if (mesh.position.z > MAX_SIZE) {
      mesh.position.z = MAX_SIZE;
    } else if (mesh.position.z < -MAX_SIZE) {
      mesh.position.z = -MAX_SIZE;
    }
  }

  focus() {
    if (!this.target) {
      return;
    }
    if (this.target === this._origin) {
      this.focusBoundingInfo = new BoundingInfo(Vector3.ZeroReadOnly, Vector3.ZeroReadOnly, this.target.computeWorldMatrix(true));
      this.renderables.forEach((renderable) => {
        this.focusBoundingInfo.encapsulateBoundingInfo(
          computeBoundingInfo(
            renderable instanceof CheckpointRenderable ? renderable.avatar ?? renderable.helper : renderable.helper,
            false,
          ),
        );
      });
      this.target.setBoundingInfo(this.focusBoundingInfo);
      this.viewManager.creatorCamera.target.copyFrom(this.focusBoundingInfo.boundingSphere.centerWorld);
      this.viewManager.scene.onAfterRenderObservable.addOnce(() => {
        this.viewManager.creatorCamera.radius = this.focusBoundingInfo.boundingSphere.radiusWorld * 2;
      });
      return;
    }
    this.focusBoundingInfo = computeBoundingInfo(this.target, false);
    this.focusDirection.copyFrom(
      this.target.absoluteRotationQuaternion.conjugate().multiply(FORWARD_QUATERNION.multiply(this.target.absoluteRotationQuaternion)),
    );
    this.focusOffset
      .set(-this.focusDirection.x, 0, this.focusDirection.z)
      .scaleInPlace(this.focusBoundingInfo.boundingSphere.radiusWorld * 2);
    this.viewManager.creatorCamera.target.copyFrom(this.focusBoundingInfo.boundingSphere.centerWorld);
    this.viewManager.scene.onAfterRenderObservable.addOnce(() => {
      this.viewManager.creatorCamera.position = this.focusBoundingInfo.boundingSphere.centerWorld.add(this.focusOffset);
    });
  }

  interact(element: TElement | undefined = undefined) {
    if (!this.viewManager.toolsEnabled) {
      return;
    }
    if (!element) {
      if (this.multiple) {
        return;
      }
      this.target = null;
      this.elements.length = 0;
      this.viewManager.lights.forEach((light) => {
        light.hideHelper();
      });
      this.clearGizmos();
      this.viewManager.appService.setSelectedElements([]);
    } else {
      this.viewManager.lights.forEach((light) => {
        light.hideHelper();
      });
      switch (element.type) {
        case ElementType.Checkpoint:
          this.elements.length = 0;
          ![TransformationMode.Translate, TransformationMode.Rotate].includes(this.viewManager.appService.getTransformationMode()) &&
            this.viewManager.appService.setTransformationMode(TransformationMode.Translate);

          const sceneMatrix = this.scene.getTransformMatrix();
          const viewport = this.scene.activeCamera?.viewport;
          const helper = this.viewManager.appService.getActiveCheckpoint().helper;
          const sceneCoords = helper.absolutePosition;
          const bounds = helper.getHierarchyBoundingVectors();
          const engine = this.scene.getEngine();
          if (viewport && bounds) {
            const pos = Vector3.Project(
              sceneCoords,
              Matrix.IdentityReadOnly,
              sceneMatrix,
              viewport.toGlobal(engine.getRenderWidth(), engine.getRenderHeight()),
            );
            const min = Vector3.Project(
              bounds.min,
              Matrix.IdentityReadOnly,
              sceneMatrix,
              viewport.toGlobal(engine.getRenderWidth(), engine.getRenderHeight()),
            );
            const max = Vector3.Project(
              bounds.max,
              Matrix.IdentityReadOnly,
              sceneMatrix,
              viewport.toGlobal(engine.getRenderWidth(), engine.getRenderHeight()),
            );

            this.viewManager.appService.setMeshScreenCoordinates({
              x: pos.x,
              y: pos.y,
              width: Math.abs(max.x - min.x),
              height: Math.abs(max.y - min.y),
            });
          }
          break;
        case ElementType.Light:
          this.elements.length = 0;
          this.viewManager.lights.find((light) => light.elementId === element.id)?.showHelper();
          break;
        default:
          if (this.renderables.some((renderable) => renderable instanceof CheckpointRenderable || renderable instanceof LightRenderable)) {
            this.elements.length = 0;
          }
          break;
      }
      if (this.multiple) {
        const index = this.elements.findIndex(({ id }) => id === element.id);
        ~index ? this.elements.splice(index, 1) : this.elements.push(element);
      } else {
        this.elements.length = 0;
        this.elements.push(element);
      }
      this.elements.length ? this.setGizmos() : this.clearGizmos();
      this.viewManager.appService.setSelectedElements(this.elements);
    }
    this.update();
  }
}
