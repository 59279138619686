<div
  class="header"
  [ngClass]="{ staging: !isProduction }"
  [ngStyle]="{
    backgroundColor: isProduction && headerType === 'landing' ? 'transparent' : null
  }"
>
  <div class="left">
    <a class="nav-item logo" [routerLink]="headerType === 'landing' ? null : ['']">
      <div class="icon icon-ownxr"></div>
    </a>
    @if (headerType && headerType === 'landing') {
      @if (!isProduction) {
        <div class="badge">{{ configurationBadgeTitle }}</div>
      }
    }
    @if (headerType && headerType === 'oxr') {
      <a class="nav-item" [routerLink]="['/oxr']" routerLinkActive="active">{{ 'header.oxr' | translate | uppercase }}</a>
      <a class="nav-item" [routerLink]="['/market']" routerLinkActive="active">{{ 'header.market' | translate | uppercase }}</a>
      <a class="nav-item" [routerLink]="['/community']" routerLinkActive="active">{{ 'header.community' | translate | uppercase }}</a>
      <a class="nav-item" [routerLink]="['/editor']" routerLinkActive="active">{{ 'header.editor' | translate | uppercase }}</a>
      @if (!isFeature) {
        <a class="nav-item" [routerLink]="['/domain']" routerLinkActive="active">{{ 'header.domain' | translate | uppercase }}</a>
      }
      <a class="nav-item" [routerLink]="['/applications']" routerLinkActive="active">{{ 'header.applications' | translate | uppercase }}</a>
      <a class="nav-item" [routerLink]="['/guide']" routerLinkActive="active">{{ 'header.guide' | translate | uppercase }}</a>
    }
  </div>
  <div class="right">
    @if (headerType && headerType === 'landing') {
      @if (currentLanguage$ | async; as currentLang) {
        <ng-select
          class="custom"
          [searchable]="false"
          [clearable]="false"
          placeholder="{{ 'shared.fields.selectPlaceholder' | translate }}"
          dropdownPosition="bottom"
          [ngModel]="currentLang"
          (ngModelChange)="onLanguageSelect($event)"
        >
          @for (language of languageOptions; track language) {
            <ng-option [value]="language.name">
              <div class="select-option-wrapper">
                <div class="text">{{ 'shared.' + language.displayName | translate | uppercase }}</div>
                <div class="flag {{ language.name }}"></div>
              </div>
            </ng-option>
          }
        </ng-select>
      }
    }
    @if (headerType && headerType === 'oxr') {
      <div class="account" (uiClickOutside)="closePanel()">
        <div class="thumbnail-section" (click)="openPanel($event)">
          @if (!(account$ | async)?.Thumbnail) {
            <div class="icon-wrapper">
              <div class="icon icon-account sm primary"></div>
            </div>
          }
          @if ((account$ | async)?.Thumbnail) {
            <img class="user-thumbnail" [src]="(account$ | async)?.Thumbnail" />
          }
          @if (unviewedNotificationsNumber$ | async) {
            <div class="notification-dot"></div>
          }
        </div>
        <ui-account-panel [class.opened]="isPanelOpened$ | async"></ui-account-panel>
      </div>
    }
  </div>
</div>
