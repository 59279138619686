<div class="title">
  {{ data[keyValue['TITLE']] ? data[keyValue['TITLE']] : ('community.view.contactForm.title' | translate) }}
  <div class="close icon icon-close sm" (click)="modalClose()"></div>
</div>
<div class="body" [formGroup]="form">
  <input
    uiMInput
    type="text"
    placeholder="{{ data[keyValue['PLACEHOLDERFIRST']] ? data[keyValue['PLACEHOLDERFIRST']] : ('shared.fields.name' | translate) }}"
    [formControlName]="keyValue['PLACEHOLDERFIRST']"
  />
  <input
    uiMInput
    type="text"
    placeholder="{{ data[keyValue['PLACEHOLDERSECOND']] ? data[keyValue['PLACEHOLDERSECOND']] : ('shared.fields.contact' | translate) }}"
    [formControlName]="keyValue['PLACEHOLDERSECOND']"
  />
  <input
    uiMInput
    type="text"
    placeholder="{{ data[keyValue['PLACEHOLDERTHIRD']] ? data[keyValue['PLACEHOLDERTHIRD']] : ('shared.fields.email' | translate) }}"
    [formControlName]="keyValue['PLACEHOLDERTHIRD']"
  />
  <textarea
    uiTextarea
    nonResizable
    rows="4"
    placeholder="{{
      data[keyValue['PLACEHOLDERFOURTH']] ? data[keyValue['PLACEHOLDERFOURTH']] : ('community.view.contactForm.inquiries' | translate)
    }}"
    [formControlName]="keyValue['PLACEHOLDERFOURTH']"
  ></textarea>
  @if (currentLanguage$ | async; as language) {
    <ui-check-box class="selection-checkbox xs primary" (checkedChange)="checkedAgree = !checkedAgree">
      @if (language === languageCodes.English) {
        <span>I accept </span>
      }
      <span class="terms-conditions" (click)="showTermsConditions()">{{
        'community.view.contactForm.termsAndConditions' | translate
      }}</span>
      @if (language === languageCodes.Korean) {
        <span>에 동의합니다.</span>
      }
    </ui-check-box>
  }
  <button ui-button sm [disabled]="!form.valid || !isFormValid" (click)="onPreviewSave()">{{ 'shared.actions.save' | translate }}</button>
</div>
