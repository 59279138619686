import { NgModule } from '@angular/core';
import { UiComponent } from './ui.component';
import { HeaderComponent } from './layout/header/header.component';
import { SideBarComponent } from './layout/side-bar/side-bar.component';
import { ButtonComponent } from './components/button/button.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutComponent } from './layout/layout.component';
import { SideBarItemComponent } from './layout/side-bar/side-bar-item/side-bar-item.component';
import { AssetsMenuComponent } from './layout/assets-menu/assets-menu.component';
import { AssetsMenuTabComponent } from './layout/assets-menu/assets-menu-tab/assets-menu-tab.component';
import { AssetsMenuCardListComponent } from './layout/assets-menu/assets-menu-card-list/assets-menu-card-list.component';
import { SearchComponent } from './components/search/search.component';
import { ConfirmationComponent } from './modal/confirmation/confirmation.component';
import { ModalComponent } from './modal/modal.component';
import { ModalDirective } from './modal/modal.directive';
import { TagComponent } from './components/tag/tag.component';
import { BodyComponent } from './layout/body/body.component';
import { SearchBarComponent } from './layout/body/search-bar/search-bar.component';
import { DisplayCardComponent } from './layout/body/display-card/display-card.component';
import { SearchFilterComponent } from './layout/body/search-filter/search-filter.component';
import { DropDownComponent } from './components/drop-down/drop-down.component';
import { ClickOutsideDirective } from './directive/click-outside.directive';
import { CardListComponent } from './layout/body/card-list/card-list.component';
import { DataModule } from 'projects/data/src/public-api';
import { GalleryComponent } from './modal/custom-form/gallery/gallery.component';
import { GuestBookComponent } from './modal/custom-form/guest-book/guest-book.component';
import { TextBoxComponent } from './components/text-box/text-box.component';
import { CheckBoxComponent } from './components/check-box/check-box.component';
import { OxrSettingsComponent } from './layout/oxr-settings/oxr-settings.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { RadioComponent } from './components/radio/radio.component';
import { FileDragDropDirective } from './directive/file-drag-drop.directive';
import { QuillModule } from 'ngx-quill';
import { AutofocusDirective } from './directive/autofocus.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AccountPanelComponent } from './layout/account-panel/account-panel.component';
import { AccountPanelDetailsWrapperComponent } from './layout/account-panel/account-panel-details/account-panel-details-wrapper/account-panel-details-wrapper.component';
import { AccountPanelLanguageComponent } from './layout/account-panel/account-panel-details/account-panel-language/account-panel-language.component';
import { AccountPanelUserInfoComponent } from './layout/account-panel/account-panel-details/account-panel-user-info/account-panel-user-info.component';
import { AccountPanelSecurityComponent } from './layout/account-panel/account-panel-details/account-panel-security/account-panel-security.component';
import { AccountPanelNotificationsComponent } from './layout/account-panel/account-panel-details/account-panel-notifications/account-panel-notifications.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationDetailsComponent } from './layout/account-panel/account-panel-details/account-panel-notifications/notification-details/notification-details.component';
import { ProgressComponent } from './modal/progress/progress.component';
import { WithdrawalComponent } from './layout/account-panel/account-panel-details/account-panel-security/withdrawal/withdrawal.component';
import { InformationComponent } from './modal/information/information.component';
import { ToastComponent } from './components/toast/toast.component';
import { AnchorButtonComponent } from './components/button/anchor-button.component';
import { TooltipDirective } from './directive/tooltip.directive';
import { LoaderComponent } from './components/loader/loader.component';
import { MediaUploadComponent } from './modal/media-upload/media-upload.component';
import { GuideModalComponent } from './modal/guide/guide.component';
import { TextToolComponent } from './modal/scene/text-tool/text-tool.component';
import { ContactformToolComponent } from './modal/scene/contactform-tool/contactform-tool.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { SwitchComponent } from './components/switch/switch.component';
import { GalleryToolComponent } from './modal/scene/gallery-tool/gallery-tool.component';
import { GuestbookToolComponent } from './modal/scene/guestbook-tool/guestbook-tool.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { SaveGalleryComponent } from './modal/scene/gallery-tool/save-gallery/save-gallery.component';
import { VideoToolComponent } from './modal/scene/video-tool/video-tool.component';
import { SaveVideoComponent } from './modal/scene/video-tool/save-video/save-video.component';
import { SafePipe } from './directive/safe.pipe';
import { DragScrollModule } from 'ngx-drag-scroll';
import { DomainCardDescriptionComponent } from './layout/domain-card-description/domain-card-description.component';
import { DomainListComponent } from './layout/domain-list/domain-list.component';
import { AutoSizeInputComponent } from './components/auto-size-input/auto-size-input.component';
import { AutoSizeInputModule } from 'ngx-autosize-input';
import { DomainPaymentComponent } from './layout/domain-payment/domain-payment.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { NgPipesModule } from 'ngx-pipes';
import { MediaStorageInfoComponent } from './components/media-storage-info/media-storage-info.component';
import { AngularSvgIconModule, SvgLoader } from 'angular-svg-icon';
import { LinkToolComponent } from './modal/scene/link-tool/link-tool.component';
import { ComingSoonComponent } from './layout/coming-soon/coming-soon.component';
import { ControlsInfoComponent } from './modal/scene/controls-info/controls-info.component';
import { ReleaseLogComponent } from './modal/release-log/release-log.component';
import { AccountPanelReleaseLogComponent } from './layout/account-panel/account-panel-details/account-panel-release-log/account-panel-release-log.component';
import { ContactFormComponent } from './modal/contact-form/contact-form.component';
import { AlreadyAddedValidatorDirective } from './directive/already-added-validator.directive';
import { BetaWelcomeComponent } from './modal/beta-welcome/beta-welcome.component';
import { LandingComponent } from './layout/landing/landing.component';
import { TrapFocusDirective } from './directive/trapFocus.directive';
import { GoogleFormToolComponent } from './modal/scene/google-form-tool/google-form-tool.component';
import { EditorControlsInfoComponent } from './modal/scene/editor-controls-info/editor-controls-info.component';
import { UiHighlightPipe } from './directive/highlight.pipe';
import { OxrSettingsDetailsWrapperComponent } from './layout/oxr-settings/oxr-settings-details/oxr-settings-details-wrapper/oxr-settings-details-wrapper.component';
import { OxrSpaceInformationComponent } from './layout/oxr-settings/oxr-settings-details/oxr-space-information/oxr-space-information.component';
import { OxrSpaceDescriptionComponent } from './layout/oxr-settings/oxr-settings-details/oxr-space-description/oxr-space-description.component';
import { OxrCollaboratorManagementComponent } from './layout/oxr-settings/oxr-settings-details/oxr-collaborator-management/oxr-collaborator-management.component';
import { ColorPaletteComponent } from './components/color-palette/color-palette.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { ListBoxComponent } from './components/list-box/list-box.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { SliderComponent } from './components/slider/slider.component';
import { PanelsMenuComponent } from '@/ui/src/lib/layout/panels-menu/panels-menu.component';
import { PanelComponent } from '@/ui/src/lib/components/panel/panel.component';
import { AssetVersionsPanelComponent } from './layout/panels-menu/asset-versions-panel/asset-versions-panel.component';
import { AvatarPanelComponent } from './layout/panels-menu/avatar-panel/avatar-panel.component';
import { EffectPanelComponent } from './layout/panels-menu/effect-panel/effect-panel.component';
import { EnvironmentPanelComponent } from '@/ui/src/lib/layout/panels-menu/environment-panel/environment-panel.component';
import { InfoPanelComponent } from './layout/panels-menu/info-panel/info-panel.component';
import { LightPanelComponent } from './layout/panels-menu/light-panel/light-panel.component';
import { MediaPanelComponent } from './layout/panels-menu/media-panel/media-panel.component';
import { PopupContentPanelComponent } from '@/ui/src/lib/layout/panels-menu/popup-content-panel/popup-content-panel.component';
import { SceneInteractionsPanelComponent } from '@/ui/src/lib/layout/panels-menu/scene-interactions-panel/scene-interactions-panel.component';
import { ScreenPanelComponent } from '@/ui/src/lib/layout/panels-menu/screen-panel/screen-panel.component';
import { TextPanelComponent } from '@/ui/src/lib/layout/panels-menu/text-panel/text-panel.component';
import { TransformationPanelComponent } from '@/ui/src/lib/layout/panels-menu/transformation-panel/transformation-panel.component';
import { MaterialsPanelComponent } from '@/ui/src/lib/layout/panels-menu/materials-panel/materials-panel.component';
import { ColorPanelComponent } from '@/ui/src/lib/layout/panels-menu/color-panel/color-panel.component';
import { FooterComponent } from './layout/footer/footer.component';
import { MediaUploadLibraryComponent } from './components/media-upload-library/media-upload-library.component';
import { OnIntersectionObserverDirective } from './directive/on-intersection-observer.directive';
import { QuillEditorMediaComponent } from './components/quill-editor-media/quill-editor-media.component';
import { HttpClient } from '@angular/common/http';
import { SvgBrowserLoader } from './utils/svg-browser.loader';
import { PaginationComponent } from './components/pagination/pagination.component';
import { CollaboratorInvitationModalComponent } from './modal/collaborator-invitation-modal/collaborator-invitation-modal.component';
import { DateDiffPipe } from './directive/date-diff.pipe';
import { OverflowReadmoreDirective } from './directive/overflow-readmore.directive';
import { MobileConfirmationComponent } from './modal/mobile/m-confirmation/m-confirmation.component';
import { MobileInformationComponent } from './modal/mobile/m-information/m-information.component';
import { MobileTermsAndConditionsComponent } from './modal/mobile/m-terms-and-conditions/m-terms-and-conditions.component';
import { MobileWelcomeComponent } from './modal/mobile/m-welcome/m-welcome.component';
import { MobileSubscriptionPlanComponent } from './layout/mobile/m-subscription-plan/m-subscription-plan.component';
import { QuillEditorCustomToolbarComponent } from './components/quill-editor-custom-toolbar/quill-editor-custom-toolbar.component';
import { BannerComponent } from '@/mobile/src/app/banner/banner.component';
import { TextFlowAnimationDirective } from './directive/text-flow-animation.directive';
import { AdBannerComponent } from './components/ad-banner/ad-banner.component';
import { ChatComponent } from './components/chat/chat.component';
import { NgxSliderModule } from 'ngx-slider-v2';
import { AssetsPlacementInfoComponent } from './components/assets-placement-info/assets-placement-info.component';
import { JoystickComponent } from './components/joystick/joystick.component';

export function svgLoaderFactory(http: HttpClient): SvgBrowserLoader {
  return new SvgBrowserLoader(http);
}
@NgModule({
  declarations: [
    UiComponent,
    LandingComponent,
    ReleaseLogComponent,
    AccountPanelReleaseLogComponent,
    ControlsInfoComponent,
    EditorControlsInfoComponent,
    ContextMenuComponent,
    LoaderComponent,
    HeaderComponent,
    SideBarComponent,
    ButtonComponent,
    AnchorButtonComponent,
    LayoutComponent,
    SideBarItemComponent,
    AssetsMenuComponent,
    AssetsMenuTabComponent,
    SearchComponent,
    ConfirmationComponent,
    LinkToolComponent,
    TextToolComponent,
    GalleryToolComponent,
    VideoToolComponent,
    SaveGalleryComponent,
    SaveVideoComponent,
    GuideModalComponent,
    MediaStorageInfoComponent,
    ModalComponent,
    ModalDirective,
    TagComponent,
    BodyComponent,
    SearchBarComponent,
    DisplayCardComponent,
    SearchFilterComponent,
    DropDownComponent,
    UiHighlightPipe,
    AlreadyAddedValidatorDirective,
    ClickOutsideDirective,
    FileDragDropDirective,
    TooltipDirective,
    AutofocusDirective,
    CardListComponent,
    AssetsMenuCardListComponent,
    OxrSettingsComponent,
    OxrSettingsDetailsWrapperComponent,
    OxrSpaceInformationComponent,
    OxrSpaceDescriptionComponent,
    OxrCollaboratorManagementComponent,
    ProgressComponent,
    GalleryComponent,
    GuestBookComponent,
    TextBoxComponent,
    CheckBoxComponent,
    ProgressBarComponent,
    RadioComponent,
    VideoPlayerComponent,
    NotificationComponent,
    AccountPanelComponent,
    AccountPanelDetailsWrapperComponent,
    AccountPanelLanguageComponent,
    AccountPanelUserInfoComponent,
    AccountPanelSecurityComponent,
    AccountPanelNotificationsComponent,
    NotificationDetailsComponent,
    WithdrawalComponent,
    InformationComponent,
    ToastComponent,
    MediaUploadComponent,
    ContactformToolComponent,
    ColorPickerComponent,
    SwitchComponent,
    GuestbookToolComponent,
    SafePipe,
    DomainCardDescriptionComponent,
    DomainListComponent,
    AutoSizeInputComponent,
    DomainPaymentComponent,
    ComingSoonComponent,
    ContactFormComponent,
    BetaWelcomeComponent,
    TrapFocusDirective,
    GoogleFormToolComponent,
    TextareaComponent,
    SliderComponent,
    RadioGroupComponent,
    ListBoxComponent,
    PanelsMenuComponent,
    PanelComponent,
    ColorPaletteComponent,
    AssetVersionsPanelComponent,
    AvatarPanelComponent,
    EffectPanelComponent,
    EnvironmentPanelComponent,
    InfoPanelComponent,
    LightPanelComponent,
    MediaPanelComponent,
    PopupContentPanelComponent,
    SceneInteractionsPanelComponent,
    ScreenPanelComponent,
    TextPanelComponent,
    TransformationPanelComponent,
    MaterialsPanelComponent,
    ColorPanelComponent,
    FooterComponent,
    MediaUploadLibraryComponent,
    OnIntersectionObserverDirective,
    QuillEditorMediaComponent,
    PaginationComponent,
    CollaboratorInvitationModalComponent,
    DateDiffPipe,
    OverflowReadmoreDirective,
    MobileConfirmationComponent,
    MobileInformationComponent,
    MobileTermsAndConditionsComponent,
    MobileWelcomeComponent,
    BannerComponent,
    MobileSubscriptionPlanComponent,
    QuillEditorCustomToolbarComponent,
    TextFlowAnimationDirective,
    AdBannerComponent,
    AssetsPlacementInfoComponent,
    ChatComponent,
    JoystickComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DataModule,
    QuillModule,
    NgSelectModule,
    ColorPickerModule,
    ImageCropperModule,
    DragScrollModule,
    NgxSliderModule,
    AutoSizeInputModule,
    NgPipesModule,
    AngularSvgIconModule.forRoot({
      loader: {
        provide: SvgLoader,
        useFactory: svgLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    ColorPickerModule,
    TranslateModule,
    LandingComponent,
    UiComponent,
    ColorPickerComponent,
    ReleaseLogComponent,
    AccountPanelReleaseLogComponent,
    ControlsInfoComponent,
    EditorControlsInfoComponent,
    ContextMenuComponent,
    LoaderComponent,
    HeaderComponent,
    SideBarComponent,
    ButtonComponent,
    AnchorButtonComponent,
    LayoutComponent,
    UiHighlightPipe,
    AlreadyAddedValidatorDirective,
    ClickOutsideDirective,
    SideBarItemComponent,
    AssetsMenuComponent,
    AssetsMenuTabComponent,
    SearchComponent,
    ConfirmationComponent,
    LinkToolComponent,
    TextToolComponent,
    VideoPlayerComponent,
    GalleryToolComponent,
    VideoToolComponent,
    SaveGalleryComponent,
    SaveVideoComponent,
    GuideModalComponent,
    MediaStorageInfoComponent,
    ModalComponent,
    ModalDirective,
    AutofocusDirective,
    TooltipDirective,
    TagComponent,
    BodyComponent,
    SearchBarComponent,
    DisplayCardComponent,
    ProgressComponent,
    SearchFilterComponent,
    DropDownComponent,
    CardListComponent,
    AssetsMenuCardListComponent,
    OxrSettingsComponent,
    OxrSettingsDetailsWrapperComponent,
    OxrSpaceInformationComponent,
    OxrSpaceDescriptionComponent,
    NotificationComponent,
    GalleryComponent,
    GuestBookComponent,
    TextBoxComponent,
    CheckBoxComponent,
    ProgressBarComponent,
    RadioComponent,
    AccountPanelComponent,
    AccountPanelDetailsWrapperComponent,
    AccountPanelLanguageComponent,
    AccountPanelUserInfoComponent,
    AccountPanelSecurityComponent,
    AccountPanelNotificationsComponent,
    NotificationDetailsComponent,
    NgSelectModule,
    WithdrawalComponent,
    InformationComponent,
    ToastComponent,
    SafePipe,
    DomainCardDescriptionComponent,
    DomainListComponent,
    AutoSizeInputComponent,
    SwitchComponent,
    DomainPaymentComponent,
    NgPipesModule,
    ComingSoonComponent,
    ContactFormComponent,
    BetaWelcomeComponent,
    TrapFocusDirective,
    GoogleFormToolComponent,
    TextareaComponent,
    SliderComponent,
    RadioGroupComponent,
    ListBoxComponent,
    PanelsMenuComponent,
    PanelComponent,
    ColorPaletteComponent,
    AssetVersionsPanelComponent,
    AvatarPanelComponent,
    EffectPanelComponent,
    EnvironmentPanelComponent,
    InfoPanelComponent,
    LightPanelComponent,
    MediaPanelComponent,
    PopupContentPanelComponent,
    SceneInteractionsPanelComponent,
    ScreenPanelComponent,
    TextPanelComponent,
    TransformationPanelComponent,
    MaterialsPanelComponent,
    ColorPanelComponent,
    FooterComponent,
    MediaUploadLibraryComponent,
    OnIntersectionObserverDirective,
    PaginationComponent,
    CollaboratorInvitationModalComponent,
    DateDiffPipe,
    OverflowReadmoreDirective,
    MobileConfirmationComponent,
    MobileInformationComponent,
    MobileTermsAndConditionsComponent,
    MobileWelcomeComponent,
    BannerComponent,
    MobileSubscriptionPlanComponent,
    TextFlowAnimationDirective,
    AdBannerComponent,
    AssetsPlacementInfoComponent,
    ChatComponent,
    JoystickComponent,
  ],
})
export class UiModule {}
