import { Options } from 'ngx-slider-v2';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CustomInputController } from '../custom-input-controller.base';
import { TooltipPosition } from '../../directive/tooltip.directive';

@Component({
  selector: 'ui-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent extends CustomInputController implements AfterViewInit {
  @ViewChild('slider') slider: ElementRef<HTMLInputElement>;
  _value: number = 0;
  _options: Options;
  isSelected = false;
  dirty = false;
  debounceTime: number = 400;
  toolTipPositions = TooltipPosition;

  @Input() label: string = '';
  @Input() showInput: boolean = true;
  @Input() disabled: boolean = false;

  @Input() set options(value: Options) {
    this._options = value;
    this.updateStyle(this._value);
  }

  @Input() set value(val) {
    this._value = Number(val);
    this.updateStyle(val);
  }

  @Output() onChange = new EventEmitter<any>();
  @Output() onChangeEnd = new EventEmitter<any>();

  get options() {
    return this._options;
  }

  constructor() {
    super();
  }

  updateStyle(value) {
    const min = this.options?.floor || 0;
    const max = this.options?.ceil || 0;
    if (this.slider) {
      this.slider.nativeElement.style.backgroundSize = ((value - min) * 100) / (max - min) + '% 100%';
    }
  }

  ngAfterViewInit(): void {
    this.updateStyle(this._value);
  }

  change(event): void {
    this._value = Number(event.target.value || 0);
    this.updateStyle(this._value);
    this.onChange.emit(this._value);
  }

  onInputChange($event) {
    this.debounceChange((value) => {
      if (value) {
        let newVal = Number(value);
        if (this.options.floor !== undefined && newVal < this.options.floor) {
          newVal = this.options.floor;
        } else if (this.options.ceil && newVal > this.options.ceil) {
          newVal = this.options.ceil;
        }

        if (!isNaN(newVal)) {
          this.updateStyle(newVal);
          this._value = newVal;
          this.onChange.emit(newVal);
        }
      }
    }, $event.target.value);
  }

  onInputBlur($event) {
    const val = Number($event.target.value);
    if (!$event.target.value || isNaN(val)) {
      this._value = this.options?.floor ?? 0;
      this.updateStyle(val);
    }
    this.onChangeEnd.emit(this._value);
  }

  userChangeEnd(event) {
    const value = Number(event.target.value || 0);
    this.onChangeEnd.emit(value);
  }
}
