import { privateFieldReplacer } from '@/data/src/lib/utils/json-utils';
import { IModel, ModelType } from '@/data/src/lib/models/data/base';
import { Account } from '@/data/src/lib/models/data/account';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { VerificationStatus, DomainType, DomainStatus } from '@/data/src/lib/enums/domain';

export class DomainMap implements IModel {
  constructor(
    public Path: string, //test.ownxr.com
    public SceneId: string,
    public SslEnabled: boolean = true,
    public DomainType: DomainType,
    public Id: string,
    public SceneModel?: XRScene | undefined,
    public DateCreated?: string | undefined,
    public User?: Account | undefined,
    public UserId?: string | undefined,
    public MobilePath?: string, //test.m.ownxr.com
    public Name?: string, //test-ownxr-com
    public MobileName?: string, //test-m-ownxr-com
    public VerificationStatus?: VerificationStatus | undefined,
    public MobileVerificationStatus?: VerificationStatus | undefined,
    public MobileSslEnabled?: boolean,
    public Status?: DomainStatus | undefined,
    public MobileStatus?: DomainStatus | undefined,
    public DaysLeft?: number | undefined,
  ) {}

  get ModelType(): ModelType {
    return ModelType.Domain;
  }

  setId(id: string): void {
    this.Id = id;
  }

  toJson(): string {
    return JSON.stringify(this, privateFieldReplacer);
  }
}

export class AvailableDomain {
  public Path: string;
  public Available: boolean;
  public Tokens: string;
  public Recommended: number;
  public Extension: string;
}
