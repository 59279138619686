export enum FileType {
  Image = 'Image',
  Video = 'Video',
  Pdf = 'Pdf',
  Document = 'Document',
  Geometry = 'Geometry',
  Undefined = 'Undefined',
  Icon = 'Icon',
}

export enum MediaType {
  Thumbnail = 'Thumbnail',
  Favicon = 'Favicon',
  Profile = 'Profile',
  MediaTab = 'MediaTab',
}
