import { Vector3 } from "@babylonjs/core/Maths/math.vector.js";
/**
 * @internal
 */
export class _GLTFUtilities {
  /**
   * Creates a buffer view based on the supplied arguments
   * @param bufferIndex index value of the specified buffer
   * @param byteOffset byte offset value
   * @param byteLength byte length of the bufferView
   * @param byteStride byte distance between conequential elements
   * @param name name of the buffer view
   * @returns bufferView for glTF
   */
  static _CreateBufferView(bufferIndex, byteOffset, byteLength, byteStride, name) {
    const bufferview = {
      buffer: bufferIndex,
      byteLength: byteLength
    };
    if (byteOffset) {
      bufferview.byteOffset = byteOffset;
    }
    if (name) {
      bufferview.name = name;
    }
    if (byteStride) {
      bufferview.byteStride = byteStride;
    }
    return bufferview;
  }
  /**
   * Creates an accessor based on the supplied arguments
   * @param bufferviewIndex The index of the bufferview referenced by this accessor
   * @param name The name of the accessor
   * @param type The type of the accessor
   * @param componentType The datatype of components in the attribute
   * @param count The number of attributes referenced by this accessor
   * @param byteOffset The offset relative to the start of the bufferView in bytes
   * @param min Minimum value of each component in this attribute
   * @param max Maximum value of each component in this attribute
   * @returns accessor for glTF
   */
  static _CreateAccessor(bufferviewIndex, name, type, componentType, count, byteOffset, min, max) {
    const accessor = {
      name: name,
      bufferView: bufferviewIndex,
      componentType: componentType,
      count: count,
      type: type
    };
    if (min != null) {
      accessor.min = min;
    }
    if (max != null) {
      accessor.max = max;
    }
    if (byteOffset != null) {
      accessor.byteOffset = byteOffset;
    }
    return accessor;
  }
  /**
   * Calculates the minimum and maximum values of an array of position floats
   * @param positions Positions array of a mesh
   * @param vertexStart Starting vertex offset to calculate min and max values
   * @param vertexCount Number of vertices to check for min and max values
   * @returns min number array and max number array
   */
  static _CalculateMinMaxPositions(positions, vertexStart, vertexCount) {
    const min = [Infinity, Infinity, Infinity];
    const max = [-Infinity, -Infinity, -Infinity];
    const positionStrideSize = 3;
    let indexOffset;
    let position;
    let vector;
    if (vertexCount) {
      for (let i = vertexStart, length = vertexStart + vertexCount; i < length; ++i) {
        indexOffset = positionStrideSize * i;
        position = Vector3.FromArray(positions, indexOffset);
        vector = position.asArray();
        for (let j = 0; j < positionStrideSize; ++j) {
          const num = vector[j];
          if (num < min[j]) {
            min[j] = num;
          }
          if (num > max[j]) {
            max[j] = num;
          }
          ++indexOffset;
        }
      }
    }
    return {
      min,
      max
    };
  }
  static _NormalizeTangentFromRef(tangent) {
    const length = Math.sqrt(tangent.x * tangent.x + tangent.y * tangent.y + tangent.z * tangent.z);
    if (length > 0) {
      tangent.x /= length;
      tangent.y /= length;
      tangent.z /= length;
    }
  }
  static _GetDataAccessorElementCount(accessorType) {
    switch (accessorType) {
      case "MAT2" /* AccessorType.MAT2 */:
        return 4;
      case "MAT3" /* AccessorType.MAT3 */:
        return 9;
      case "MAT4" /* AccessorType.MAT4 */:
        return 16;
      case "SCALAR" /* AccessorType.SCALAR */:
        return 1;
      case "VEC2" /* AccessorType.VEC2 */:
        return 2;
      case "VEC3" /* AccessorType.VEC3 */:
        return 3;
      case "VEC4" /* AccessorType.VEC4 */:
        return 4;
    }
  }
}
