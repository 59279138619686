import { privateFieldReplacer } from '../../utils/json-utils';
import { Account } from './account';
import { IModel, ModelType } from './base';
import { XRScene } from './scene';

export enum EnterpriseRole {
  Collaborator = 'Collaborator',
  Owner = 'Owner',
}

export enum EnterpriseRoleNumeric {
  Owner = 1,
  Collaborator = 2,
}

export enum InvitationStatus {
  Send = 'Send',
  Confirmed = 'Confirmed',
  Declined = 'Declined',
}

export class Enterprise {
  Id: string;
  Name: string;
  Type: string;
  CreatedById: string;
  Archived: boolean;
}

export class EnterpriseAccount implements IModel {
  EnterpriseId: string;
  UserId: string;
  Role: `${EnterpriseRole}`;
  User: {
    Alias: string;
    DisplayName: string;
    Subject: string;
    Server: string;
    Id: string;
  };
  EnterpriseContract: EnterpriseContract;
  Id: string;

  constructor(enterpriseAccount: EnterpriseAccount) {
    if (enterpriseAccount) {
      Object.keys(enterpriseAccount).forEach((k) => (this[k] = enterpriseAccount[k]));
    }
  }
  get ModelType(): ModelType {
    return ModelType.Enterprise;
  }

  setId(id: string): void {
    this.Id = id;
  }

  toJson() {
    return JSON.stringify(this, privateFieldReplacer);
  }
}

export interface EnterpriseScene {
  EnterpriseSceneId: string;
  UserId: string;
  EnterpriseScene: {
    EnterpriseId: string;
    SceneId: string;
    CreatedById: string;
    Enterprise: Enterprise;
    Scene: XRScene;
    CreatedBy: Account;
    Id: string;
  };
  Id: string;
}

export interface EnterpriseUser {
  Id: string;
  EnterpriseId: string;
  UserId: string;
  Role: `${EnterpriseRole}`;
  User: User;
  EnterpriseScenes: EnterpriseScene[];
}

export interface EnterpriseSceneUser {
  Id: string;
  UserId: string;
  User: User;
  EnterpriseScene: {
    CreatedById: string;
    EnterpriseId: string;
    SceneId: string;
    Id: string;
  };
  EnterpriseSceneId: string;
}

export interface User {
  Alias: string;
  DisplayName: string;
  Subject: string;
  Server: string;
  Id: string;
  Thumbnail: string;
}

export interface CollaboratorInvitationPayload {
  enterpriseId: string;
  role: EnterpriseRoleNumeric;
  receiverEmail: string;
}

export interface EnterpriseInvitation {
  Id: string;
  EnterpriseId: string;
  Role: `${EnterpriseRole}`;
  ReceiverEmail: string;
  SenderId: string;
  InvitationStatus: `${InvitationStatus}`;
  DateCreated: string;
  EnterpriseContract: EnterpriseContract;
  Sender: {
    Alias: string;
    DisplayName: string;
    Subject: string;
    Id: string;
  };
}

export interface EnterpriseUpdatePayload {
  id: string;
  name?: string;
}

export class EnterpriseContract implements IModel {
  private _dateCreated: string;
  private _enterprise: Enterprise;

  constructor(
    public Id: string,
    DateCreated: string,
    public EnterpriseId: string,
    Enterprise: Enterprise,
    public NumberOfSpaces?: number,
    public ExpirationDateOfSpacesAfterDays?: number,
    public SpaceSavePointDiscardAfterDays?: number,
    public NumberOfCollaborators?: number,
    public MediaStorageCapacity?: number,
    public EditorCapacity?: number,
  ) {
    this._dateCreated = DateCreated;
    this._enterprise = Enterprise;
  }

  get DateCreated() {
    return this._dateCreated;
  }

  get Enterprise() {
    return this._enterprise;
  }

  get ModelType(): ModelType {
    return ModelType.EnterpriseContract;
  }

  toJson(): string {
    return JSON.stringify(this, privateFieldReplacer);
  }

  setId(id: string): void {
    this.Id = id;
  }
}
