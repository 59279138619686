import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from '@/app/src/environments/environment';
import { AccountService } from './account.service';
import { PricingPlanModel, ScenePlanModel, EditorPlanModel } from '../models/data/pricing-plan';
import { ScenePlan, ScenePlanDetail } from '../enums/pricing-plan';

@Injectable({
  providedIn: 'root',
})
export class PricingPlanService {
  private _pricingPlanSubject = new BehaviorSubject<PricingPlanModel | undefined>(undefined);
  public pricingPlan$ = this._pricingPlanSubject.asObservable();

  private headerOption;
  private endPoint!: string;
  private noAuthHttp: HttpClient;

  get scenePlans(): ScenePlanModel | undefined {
    return this._pricingPlanSubject.value?.Scene;
  }

  get editorPlans(): EditorPlanModel | undefined {
    return this._pricingPlanSubject.value?.Editor;
  }

  constructor(
    public http: HttpClient,
    private handler: HttpBackend,
    private _accountService: AccountService,
  ) {
    this.noAuthHttp = new HttpClient(this.handler);
    this.headerOption = new HttpHeaders().set('Content-Type', 'application/json');
    this.endPoint = `${environment[this._accountService.region].crudApiUrl}/PricingPlan`;
  }

  getPricingPlan(): Observable<PricingPlanModel> {
    return this.noAuthHttp.get<PricingPlanModel>(`${this.endPoint}`, { headers: this.headerOption }).pipe(
      map((pricingPlan) => {
        this._pricingPlanSubject.next(pricingPlan);
        return pricingPlan;
      }),
    );
  }

  /**
   * Check if the scene plan has the property restriction
   * @param scenePlan
   * @param property
   * @returns boolean
   */
  hasScenePlanPropertyRestriction(scenePlan: ScenePlan, property: ScenePlanDetail, revert = false): boolean {
    if (!this.scenePlans) {
      throw new Error('scenePlans is not defined. Please call getPricingPlan() first.');
    }

    if (scenePlan === ScenePlan.Enterprise) {
      return false;
    }

    if (typeof this.scenePlans[scenePlan][property] !== 'boolean') {
      throw new Error('Property is not a boolean type. This function is only for boolean type property.');
    }

    const value = !!this.scenePlans[scenePlan][property];
    return revert ? !value : value;
  }
}
