import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AllocatedMedia } from '@/data/src/lib/models/data/scene-file';
import { SceneFileService } from '@/data/src/lib/services/scene-file.service';
import { FileReference } from '@/data/src/lib/models/data/file-reference';
import { XRScene } from '@/data/src/lib/models/data/scene';

@Component({
  selector: 'ui-media-storage-info',
  templateUrl: './media-storage-info.component.html',
  styleUrls: ['./media-storage-info.component.scss'],
})
export class MediaStorageInfoComponent {
  @Input() scene: XRScene;
  @Input() allocatedMedia: AllocatedMedia;
  @Input() checkedMedia: FileReference[] = [];
  @Input() isDeletingMedia = false;

  @Output() delete = new EventEmitter<FileReference[]>();

  get allocatedStorage() {
    return !this.allocatedMedia ? null : this._sceneFileService.getSeparateSceneStorageUsageInfo(this.scene, this.allocatedMedia);
  }

  constructor(private _sceneFileService: SceneFileService) {}

  onDelete() {
    this.delete.emit(this.checkedMedia);
  }
}
