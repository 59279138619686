import { XRAsset, XRAssetVersion } from '../../models/data/asset';
import { ModelType } from '../../models/data/base';
import { deepCopy } from '../../utils/data';

import { Color4, CubeTexture, HDRCubeTexture, Mesh, MeshBuilder, Nullable, StandardMaterial, Texture } from '../../babylon';
import { ViewManager } from '..';
import { EnvironmentType, TElement, TEnvironmentElement } from '../types';
import {
  EDITOR_COLOR,
  ENVIRONMENT_DDS,
  ENVIRONMENT_INTENSITY,
  ENVIRONMENT_LEVEL,
  ENVIRONMENT_ROTATION,
  ENVIRONMENT_TINT,
} from '../constants';
import { ViewManagerUtils } from '../../utils/view-manager-utils';

const URL_REGEXP = /data:[a-zA-Z0-9]+\/([a-zA-Z]+)(?=;base64,)/;
const SKYBOX_DIAMETER = 1024;
const TEXTURE_SIZE = 1024;

export class EnvironmentRenderable {
  public elementId: string;
  public helper: Nullable<Mesh>;
  public assetVersion: XRAssetVersion | undefined;
  public texture: Nullable<CubeTexture | HDRCubeTexture | Texture>;

  private _element: TEnvironmentElement;
  private _url: string;

  constructor(
    element: TEnvironmentElement,
    public viewManager: ViewManager,
  ) {
    this.elementId = element.id;
    this.element = element;
    this.load = this.load.bind(this);
    this.update = this.update.bind(this);
    this.updateBackground = this.updateBackground.bind(this);
    this.updateIllumination = this.updateIllumination.bind(this);
    this.updateElement = this.updateElement.bind(this);
    this.load().then(() => {
      this.update();
    });
  }

  get asset() {
    return this.assetVersion?.asset;
  }

  get element() {
    return this._element as TEnvironmentElement;
  }

  set element(payload) {
    this._element = deepCopy(payload) as TEnvironmentElement;
  }

  get extension(): string {
    const match = this._url?.match(URL_REGEXP);
    if (match) {
      return match[1];
    }
    return `${this._url?.split('.').pop()}`;
  }

  async load() {
    if (!this.element.parameters.assetVersionId) {
      return;
    }
    if (this.element.parameters.assetVersionId.startsWith('data:')) {
      // TODO: Integrate default assetVersionId and remove this check
      this._url = this.element.parameters.assetVersionId;
    } else {
      this.assetVersion = await this.viewManager.appService.apiv2.getAssetVersionById(this.element.parameters.assetVersionId);
      if (!this.asset) {
        return;
      }
      const { url } = await this.viewManager.appService.loadEnvironmentAsset(this.asset, this.asset.Url || this.asset.file);
      this._url = url;
    }
    return new Promise((resolve) => {
      if (['hdr', 'exr'].includes(this.extension)) {
        this.texture = new HDRCubeTexture(
          this._url,
          this.viewManager.scene,
          TEXTURE_SIZE,
          undefined,
          undefined,
          undefined,
          undefined,
          () => {
            resolve(this.texture);
          },
          () => {
            this.viewManager.appService.removeViewElements([this.element]);
          },
        );
      } else if (this.extension === 'dds') {
        const oldValue = this.viewManager.scene.useDelayedTextureLoading;
        this.viewManager.scene.useDelayedTextureLoading = false;
        this.texture = new CubeTexture(
          this._url,
          this.viewManager.scene,
          null,
          false,
          null,
          () => {
            resolve(this.texture);
          },
          () => {
            this.viewManager.appService.removeViewElements([this.element]);
          },
          undefined,
          true,
          null, //'dds',
          true,
        );
        this.viewManager.scene.useDelayedTextureLoading = oldValue;
      } else if (['jpeg', 'jpg', 'png'].includes(this.extension)) {
        this.texture = new Texture(
          this._url,
          this.viewManager.scene,
          undefined,
          false,
          undefined,
          () => {
            resolve(this.texture);
          },
          () => {
            this.viewManager.appService.removeViewElements([this.element]);
          },
        );
      }
    });
  }

  updateBackground() {
    if (!this.texture) {
      this.texture = new Texture(this._url, this.viewManager.scene, undefined, false, undefined);
    }
    if (['hdr', 'exr'].includes(this.extension)) {
      if ([EnvironmentType.Background, EnvironmentType.Combined]) this.helper?.dispose();
      this.viewManager.scene.createDefaultSkybox(this.texture, false, 100, 2);
    } else if (['dds', 'env'].includes(this.extension)) {
      this.helper?.dispose();
      this.viewManager.scene.createDefaultSkybox(this.texture, true, 100, 2);
    } else if (['jpg', 'jpeg', 'png'].includes(this.extension)) {
      if (!this.helper) {
        this.helper = MeshBuilder.CreateSphere(`Sphere-${this.element.id}`, { diameter: SKYBOX_DIAMETER }, this.viewManager.scene);
        this.helper.scaling.x = -1;
        ViewManagerUtils.AttachMetadata(this.helper, { helperOf: this });
        this.helper.isPickable = false;
        this.helper.material = new StandardMaterial(`SphereMaterial-${this.element.id}`, this.viewManager.scene);
        this.helper.material.backFaceCulling = false;
        (this.helper.material as StandardMaterial).disableLighting = true;
      }
      (this.helper.material as StandardMaterial).emissiveTexture = this.texture;
    }
    this.texture.level = this.element.parameters.level ?? ENVIRONMENT_LEVEL;
    (this.texture as Texture).uOffset = this.element.parameters.rotation ?? ENVIRONMENT_ROTATION;
    (this.helper?.material as StandardMaterial)?.emissiveColor.fromArray(this.element.parameters.tint ?? ENVIRONMENT_TINT);
  }

  updateIllumination() {
    if (['jpg', 'jpeg', 'png'].includes(this.extension)) {
      if (this.viewManager.illuminationTexture) {
        this.viewManager.scene.environmentTexture = this.viewManager.illuminationTexture ?? this.viewManager.scene.environmentTexture;
        this.viewManager.scene.environmentIntensity = this.element.parameters.intensity ?? ENVIRONMENT_INTENSITY;
      } else {
        const oldValue = this.viewManager.scene.useDelayedTextureLoading;
        this.viewManager.scene.useDelayedTextureLoading = false;
        const texture = new CubeTexture(
          ENVIRONMENT_DDS,
          this.viewManager.scene,
          null,
          false,
          null,
          () => {
            texture.rotationY = Math.PI;
            this.viewManager.illuminationTexture = texture;
            this.viewManager.scene.environmentTexture = this.viewManager.illuminationTexture ?? this.viewManager.scene.environmentTexture;
            this.viewManager.scene.environmentIntensity = this.element.parameters.intensity ?? ENVIRONMENT_INTENSITY;
            this.viewManager.scene.useDelayedTextureLoading = oldValue;
          },
          null,
          undefined,
          true,
          null,
          true,
        );
      }
    }
  }

  removeBackground() {
    this.helper?.dispose();
    this.helper = null;
    this.texture?.dispose();
    this.texture = null;
  }

  removeIllumination() {
    this.viewManager.scene.environmentIntensity = 0;
    this.viewManager.scene.environmentTexture = null;
    this.viewManager.scene.clearColor.copyFrom(Color4.FromHexString(EDITOR_COLOR));
  }

  update(element?: TElement) {
    if (element) {
      this.element = deepCopy(element as TEnvironmentElement);
    }

    const dirty = !this._url?.startsWith('data:') && this.assetVersion?.Id !== this.element.parameters.assetVersionId;

    if (dirty) {
      this.load().then(() => {
        this.update();
      });
      return;
    }

    switch (this.element.parameters.type) {
      case EnvironmentType.Background:
        this.removeIllumination();
        this.updateBackground();
        break;
      case EnvironmentType.Illumination:
        this.removeBackground();
        this.updateIllumination();
        if (this.element.parameters.tint && !Color4.FromArray(this.element.parameters.tint).equals(this.viewManager.scene.clearColor)) {
          this.viewManager.scene.clearColor = Color4.FromArray(this.element.parameters.tint);
        }
        break;
      case EnvironmentType.Combined:
        this.updateBackground();
        this.updateIllumination();
        break;
      default:
        break;
    }
  }

  updateElement() {
    this.viewManager.scene.onBeforeRenderObservable.addOnce(() => {
      this.viewManager.appService.updateViewElement({
        ...this.element,
        parameters: {
          ...this.element.parameters,
          rotation: this.helper?.rotation.y ?? 0,
        },
      });
    });
  }

  dispose(_hard = false) {
    this.removeBackground();
    this.removeIllumination();
  }
}
