<ui-panel [title]="'oxr.elements.environment' | translate" [collapsing]="true" [panelId]="panelMask.Environment">
  @if (!disableType) {
    <ui-radio-group
      [label]="'oxr.creatingSpace.panels.environment.options' | translate"
      [options]="environmentOptions"
      [value]="element?.parameters?.type"
      (onChecked)="onTypeChange($event)"
    ></ui-radio-group>
    <div class="separator"></div>
  }
  @if (!disableLevel) {
    <ui-slider
      [label]="'oxr.creatingSpace.panels.environment.level' | translate"
      [value]="level"
      [options]="levelOptions"
      (onChange)="onLevelChange($event)"
      (onChangeEnd)="onChangeEnd()"
    ></ui-slider>
    <div class="separator"></div>
  }
  @if (!disableIntensity) {
    <ui-slider
      [label]="'oxr.creatingSpace.panels.environment.intensity' | translate"
      [value]="intensity"
      [options]="intensityOptions"
      (onChange)="onIntensityChange($event)"
      (onChangeEnd)="onChangeEnd()"
    ></ui-slider>
    <div class="separator"></div>
  }
  @if (!disableRotation) {
    <ui-slider
      [label]="'oxr.creatingSpace.panels.environment.rotateEnvironment' | translate"
      [value]="rotation"
      [options]="rotationOptions"
      (onChange)="onRotationChange($event)"
      (onChangeEnd)="onChangeEnd()"
    ></ui-slider>
    <div class="separator"></div>
  }
  <ui-color-picker
    [label]="'oxr.creatingSpace.panels.environment.tint' | translate"
    [defaultColor]="tint"
    (changed)="onColorChange($event)"
    (changeEnd)="onChangeEnd()"
  ></ui-color-picker>
</ui-panel>
