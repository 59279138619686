@if (label) {
  <span class="text">{{ label }}</span>
}
<div class="color-picker-wrapper">
  <div class="color-code">
    <input
      [disabled]="disabled"
      [(colorPicker)]="_defaultColor"
      [style.background]="_defaultColor"
      [style.cursor]="'pointer'"
      [cpPosition]="colorPickerPosition"
      [cpOutputFormat]="outputFormat"
      [cpAlphaChannel]="'disabled'"
      [cpToggle]="showPicker"
      (colorPickerChange)="onChanged()"
      (colorPickerSelect)="onChangeEnd()"
      (colorPickerCancel)="onChangeEnd()"
      (cpSliderChange)="onChanged()"
      (cpSliderDragStart)="onChanged()"
      (cpSliderDragEnd)="onChangeEnd()"
      (mousedown)="onMouseDown()"
      [cpUseRootViewContainer]="true"
    />
    <span>#</span>
    @if (showValue || showTransparency) {
      <input
        uiInput
        type="text"
        class="color-input"
        [ngModel]="_hex"
        (ngModelChange)="onColorInput($event)"
        (keydown)="onKeyDown($event)"
        (blur)="onChangeEnd()"
      />
    }
  </div>
  @if (showTransparency) {
    <input uiInput type="text" [ngModel]="opacity" (input)="onOpacityChange($event)" (focusout)="onChangeEnd()" />
  }
</div>
