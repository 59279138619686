<div class="close icon icon-close sm" (click)="onClose(false)"></div>

<div class="wrapper">
  <textarea
    uiTextarea
    nonResizable
    rows="4"
    [(ngModel)]="newComment"
    placeholder="{{ 'community.view.guestbook.placeholder' | translate }}"
    maxlength="150"
  >
  </textarea>
  <div class="letter-count">{{ newComment.length }}/150</div>
</div>

<div class="footer">
  <button ui-button sm (click)="onClose(true)" [disabled]="!newComment">
    {{ 'community.view.guestbook.success' | translate }}
  </button>
</div>
