@if (notifications$ | async; as notifications) {
  <main>
    @if (notifications.length) {
      <section class="list">
        @for (notification of notifications; track notification) {
          <ui-notification
            [notification]="notification"
            (mouseover)="markViewed(notification)"
            (clicked)="goToDetails(notification)"
            (deleted)="onDelete(notification)"
          ></ui-notification>
        }
      </section>
    }
    @if (!notifications.length) {
      <section class="no-new">
        <div class="notification-bell"></div>
        <span class="no-new-text">{{ 'header.accountPanel.notifications.noNew' | translate }}</span>
      </section>
    }
  </main>
}
