import { _Exporter } from "../glTFExporter.js";
import { PBRMaterial } from "@babylonjs/core/Materials/PBR/pbrMaterial.js";
const NAME = "KHR_materials_sheen";
/**
 * @internal
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class KHR_materials_sheen {
  constructor(exporter) {
    /** Name of this extension */
    this.name = NAME;
    /** Defines whether this extension is enabled */
    this.enabled = true;
    /** Defines whether this extension is required */
    this.required = false;
    this._wasUsed = false;
    this._exporter = exporter;
  }
  dispose() {}
  /** @internal */
  get wasUsed() {
    return this._wasUsed;
  }
  postExportMaterialAdditionalTextures(context, node, babylonMaterial) {
    if (babylonMaterial instanceof PBRMaterial) {
      if (babylonMaterial.sheen.isEnabled && babylonMaterial.sheen.texture) {
        return [babylonMaterial.sheen.texture];
      }
    }
    return [];
  }
  postExportMaterialAsync(context, node, babylonMaterial) {
    return new Promise(resolve => {
      var _a, _b, _c, _d;
      if (babylonMaterial instanceof PBRMaterial) {
        if (!babylonMaterial.sheen.isEnabled) {
          resolve(node);
          return;
        }
        this._wasUsed = true;
        if (node.extensions == null) {
          node.extensions = {};
        }
        const sheenInfo = {
          sheenColorFactor: babylonMaterial.sheen.color.asArray(),
          sheenRoughnessFactor: (_a = babylonMaterial.sheen.roughness) !== null && _a !== void 0 ? _a : 0,
          hasTextures: () => {
            return sheenInfo.sheenColorTexture !== null || sheenInfo.sheenRoughnessTexture !== null;
          }
        };
        if (babylonMaterial.sheen.texture) {
          sheenInfo.sheenColorTexture = (_b = this._exporter._glTFMaterialExporter._getTextureInfo(babylonMaterial.sheen.texture)) !== null && _b !== void 0 ? _b : undefined;
        }
        if (babylonMaterial.sheen.textureRoughness && !babylonMaterial.sheen.useRoughnessFromMainTexture) {
          sheenInfo.sheenRoughnessTexture = (_c = this._exporter._glTFMaterialExporter._getTextureInfo(babylonMaterial.sheen.textureRoughness)) !== null && _c !== void 0 ? _c : undefined;
        } else if (babylonMaterial.sheen.texture && babylonMaterial.sheen.useRoughnessFromMainTexture) {
          sheenInfo.sheenRoughnessTexture = (_d = this._exporter._glTFMaterialExporter._getTextureInfo(babylonMaterial.sheen.texture)) !== null && _d !== void 0 ? _d : undefined;
        }
        node.extensions[NAME] = sheenInfo;
      }
      resolve(node);
    });
  }
}
_Exporter.RegisterExtension(NAME, exporter => new KHR_materials_sheen(exporter));
