import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: `button[ui-button], button[ui-outlined-button], button[ui-text-button]`,
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @HostListener('keydown', ['$event']) handleKeyDownEnter(event: any) {
    if (event.key === 'Enter' || event.key === ' ') {
      if (this.disabled || event.target.classList.contains('disabled')) {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
    }
  }

  @Input() set disabled(value: boolean) {
    if (value) {
      this._host.nativeElement.classList.add('disabled');
    } else {
      this._host.nativeElement.classList.remove('disabled');
    }
  }

  constructor(private _host: ElementRef) {}

  ngOnInit(): void {}
}
