<ng-container *ngIf="!!chatRoom && !!messageSender">
  <ng-container *ngIf="isExpanded; else collapse">
    <div class="expand-mode">
      <article class="header">
        <div class="control-section">
          <div class="room-info">
            <span class="title"> {{ 'chat.title' | translate }}</span>
            <div class="icon icon-users"></div>
            <span class="count">{{ chatRoom.numberOfUsersInChatRoom }}</span>
          </div>
          <div class="action-button">
            <span class="time-stamp">{{ 'chat.timeStamp' | translate }}</span>
            <ui-switch type="small" [checked]="showTimeStamp" (changed)="toggleTimeStamp()"></ui-switch>
            <div class="icon icon-collapse" (click)="onExpand()"></div>
          </div>
        </div>
        <div class="warning">
          <div class="icon icon-warning"></div>
          <span>{{ 'chat.warning' | translate }}</span>
        </div>
      </article>
      <div class="message-section">
        <div class="container" #scroll>
          <ng-container *ngFor="let message of messages">
            <div class="user">
              <ng-container *ngIf="message.isLoggedInUser; else nonLoggedIn">
                <img *ngIf="message.thumbnail" class="thumbnail" [src]="message.thumbnail" alt="" />
                <div *ngIf="!message.thumbnail" class="thumbnail">
                  <div class="icon icon-account primary"></div>
                </div>
              </ng-container>
              <ng-template #nonLoggedIn>
                <div class="thumbnail non-logged-in" [ngStyle]="{ backgroundColor: message.thumbnail }">
                  {{ message.nickName && getThumbnailText(message.nickName) }}
                </div>
              </ng-template>
              <div class="contents">
                <div class="name">
                  {{ message.nickName }}
                  <ng-container *ngIf="message.nickName === (messageSender.nickName || messageSender.alias); else showBlockIcon">
                    <span>&nbsp;{{ 'chat.me' | translate }}</span>
                  </ng-container>
                  <ng-template #showBlockIcon>
                    <div
                      class="block"
                      (click)="onBlock(message.id, message.nickName)"
                      [ngClass]="{ isBlocked: blockedList.includes(message.id) }"
                    >
                      <span>{{ (blockedList.includes(message.id) ? 'chat.isBlocked' : 'chat.block') | translate }}</span>
                      <div class="icon icon-block"></div>
                    </div>
                  </ng-template>
                </div>
                <div class="content">
                  <div class="message">{{ message.message }}</div>
                  <div *ngIf="showTimeStamp" class="time-stamp">{{ message.dateCreated | date: 'h:mm a' }}</div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="message-input-section">
        <div class="user input">
          <ng-container *ngIf="messageSender.isLoggedInUser; else nonLoggedIn">
            <img *ngIf="messageSender.thumbnail" class="thumbnail" [src]="messageSender.thumbnail" alt="" />
            <div *ngIf="!messageSender.thumbnail" class="thumbnail">
              <div class="icon icon-account primary"></div>
            </div>
          </ng-container>
          <ng-template #nonLoggedIn>
            <div class="thumbnail non-logged-in" [ngStyle]="{ backgroundColor: messageSender.thumbnail }">
              {{ messageSender.nickName && getThumbnailText(messageSender.nickName) }}
            </div>
          </ng-template>

          <div class="contents">
            <div class="name">{{ messageSender.nickName ? messageSender.nickName : messageSender.alias }}</div>
            <input
              type="text"
              [maxLength]="120"
              [(ngModel)]="inputMessage"
              (keydown)="onKeyDown($event)"
              placeholder="{{ 'chat.pleaseEnter' | translate }}"
              #inputArea
            />
          </div>
        </div>
        <div class="send-button" [ngClass]="{ active: inputMessage && !inputArea.disabled }" (click)="sendMessage()">
          <div class="icon icon-send"></div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #collapse>
    <div class="collapse-mode">
      <span class="title">{{ 'chat.title' | translate }}</span>
      <div class="icon icon-users"></div>
      <span class="count">{{ chatRoom.numberOfUsersInChatRoom }}</span>
      <div class="icon icon-expand" (click)="onExpand()"></div>
    </div>
  </ng-template>
</ng-container>
