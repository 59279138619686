import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AccountService, LanguageCode } from 'projects/data/src/lib/services/account.service';
import { map, Subject } from 'rxjs';
import { AccountPanelService } from '../account-panel/account-panel.service';
import { Account } from '@/data/src/lib/models/data/account';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '@/app/src/environments/environment';
import { Configurations } from '@/data/src/lib/enums/configurations';
import { TooltipPosition } from '@/ui/src/lib/directive/tooltip.directive';

@UntilDestroy()
@Component({
  selector: 'ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  isFeature = environment.configuration === Configurations.Feature;
  isProduction = environment.production;
  configurationBadgeTitle = environment.configuration === Configurations.Staging ? 'staging' : 'feature';
  languageOptions = [
    { name: 'ko', displayName: 'korean' },
    { name: 'en', displayName: 'en' },
  ];
  headerType: 'landing' | 'oxr' | null = 'landing';
  currentLanguage$ = this._accountService.language$;
  isPanelOpened$ = this._accountPanelService.isPanelOpened$;
  account$ = this._accountPanelService.account$;
  unviewedNotificationsNumber$ = this._accountPanelService.notifications$.pipe(
    map((items) => items.filter((item) => item.HasBeenViewed === false).length),
  );

  account?: Account;
  tooltipPosition = TooltipPosition;
  public urlChanged = new Subject<{ url: string; state: any }>();

  constructor(
    private _accountPanelService: AccountPanelService,
    private _accountService: AccountService,
    private _router: Router,
    private _cd: ChangeDetectorRef,
    private location: Location,
  ) {
    // First time loading, check the current url to render Header
    if (this._router.url === '/') {
      this.headerType = 'landing';
    } else {
      this.headerType = 'oxr';
    }

    // Listen on URL changes and push changes to Observable
    this.location.onUrlChange((url, state) => {
      this.urlChanged.next({ url, state });
    });

    this.account = this._accountService.account;
  }

  ngOnInit() {
    // Subscribe urlChanged Observable to watch for URL changes
    this.urlChanged.pipe(untilDestroyed(this)).subscribe((data) => {
      if (data.url === '/') {
        this.headerType = 'landing';
      } else {
        this.headerType = 'oxr';
      }
      this._cd.detectChanges();
    });
  }

  onLanguageSelect(language: LanguageCode) {
    this._accountPanelService.saveLanguage(language);
  }

  openPanel(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    this._accountPanelService.openPanel();
  }

  closePanel() {
    this._accountPanelService.closePanel();
  }
}
