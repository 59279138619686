import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReleaseLogService } from 'projects/data/src/lib/services/release-log.service';

@Component({
  selector: 'ui-account-panel-release-log',
  templateUrl: './account-panel-release-log.component.html',
  styleUrls: ['./account-panel-release-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountPanelReleaseLogComponent {
  releaseLog$ = this._releaseLogService.releaseLog$;

  openedVersion: number | null = null;
  currentLanguage = this._translateService.currentLang;

  constructor(private _releaseLogService: ReleaseLogService, private _translateService: TranslateService) {}

  openVersion(i: number) {
    this.openedVersion = this.openedVersion === i ? null : i;
  }
}
