import { environment } from '@/app/src/environments/environment';
import { Color3, PickingInfo, Quaternion, Ray, Vector3 } from '../babylon';
import { EffectType, LightType, MoveMode } from './types';

export const XR_ROOT_PREFIX = '___XRROOT___';

export enum MoveDirection {
  None = 0,
  Forward = 1,
  Backward = 2,
  Left = 4,
  Right = 8,
}

export const UP = Vector3.Up();
export const RIGHT = Vector3.Right();
export const FORWARD = Vector3.Forward();

export const CREATOR_CAMERA_ALPHA = (Math.PI * 3) / 4;
export const CREATOR_CAMERA_BETA = Math.PI / 3;
export const CREATOR_CAMERA_RADIUS = 25;

export const EDITOR_CAMERA_ALPHA = Math.PI / 2;
export const EDITOR_CAMERA_BETA = Math.PI * 0.45;
export const EDITOR_CAMERA_RADIUS = CREATOR_CAMERA_RADIUS;

export const CEILING_RAY = new Ray(Vector3.ZeroReadOnly, Vector3.UpReadOnly, 0.6);
export const FLOOR_RAY = new Ray(Vector3.ZeroReadOnly, Vector3.DownReadOnly, 1);
export const HEAD_RAY = new Ray(Vector3.ZeroReadOnly, Vector3.Forward(), 1);
export const FACING_RAY = new Ray(Vector3.ZeroReadOnly, Vector3.UpReadOnly.scale(1.4), 1);
export const KNEE_RAY = new Ray(Vector3.ZeroReadOnly, Vector3.Normalize(Vector3.FromArray([0, -3, 3])), 1);

export const INFINITE_PICK = new PickingInfo();
INFINITE_PICK.distance = Infinity;
export const MINUS_INFINITE_PICK = new PickingInfo();
MINUS_INFINITE_PICK.distance = -Infinity;

export const LOAD_BUTTON_PNG = `${environment.kr.fileStorageUrl}/public-images/load-button.png`;
export const SOUND_ONLY_PNG = `${environment.kr.fileStorageUrl}/public-images/sound-only.png`;
// TODO: Fix prod ID after migration
export const ANIMATIONS_ASSET_ID = environment.production ? '4511ed09-8e81-41bd-9b7d-1e7c315c47b3' : '48af8444-0ee4-45f5-a38e-5abf014b6caf';

export const RED = Color3.Red();
export const GREEN = Color3.Green();
export const BLUE = Color3.Blue();

export const EDITOR_COLOR = '#1C2329';
export const CUSTOMIZATION_COLOR = Color3.Lerp(Color3.FromHexString('#FF005C'), Color3.BlackReadOnly, 0.25);
export const SELECTION_COLOR = Color3.FromHexString('#007ACC');
export const SHINE_COLOR = Color3.FromHexString('#F7FFC7');
export const BOUNDING_COLOR = Color3.FromHexString('#FF005C');
export const POPUP_COLOR: [number, number, number] = Color3.FromHexString('#17E0CD').asArray() as [number, number, number];

export const GIZMO_SCALE = 1.25;
export const Z_OFFSET = 0.0005;

export const ENVIRONMENT_DDS = '/assets/images/environment.env';
export const ENVIRONMENT_INTENSITY = 0.3;
export const ENVIRONMENT_LEVEL = 1;
export const ENVIRONMENT_ROTATION = 0;
export const ENVIRONMENT_TINT = [0, 0, 0];
export const TRANSPARENT_PNG =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUAAACnej3aAAAAAXRSTlMAQObYZgAAAApJREFUCNdjYAAAAAIAAeIhvDMAAAAASUVORK5CYII=';

export const LANDSCAPE_POSITION: [number, number, number] = [0, -0.1, 0];
export const LANDSCAPE_ROTATION = 0;
export const LANDSCAPE_SCALING: [number, number, number] = [-1, 1, 1];

export const MAX_VERSIONS = 5;
export const MAX_LIGHTS = 4;
export const MAX_FLOAT = Number.MAX_VALUE * 0.1;
export const MAX_SIZE = 250;
export const MAX_POLYGONS = 50000;
export const FORWARD_QUATERNION = new Quaternion(0, 0, 1, 0);

export const LIGHT_INTENSITES: { [key: string]: number } = {
  [LightType.Ambient]: 1,
  [LightType.Directional]: 5,
  [LightType.Point]: 50,
  [LightType.Spot]: 50,
};
export const LIGHT_ANGLE: number = Math.PI * 0.5;
export const LIGHT_BIAS: number = 0.00001;
export const LIGHT_NORMAL_BIAS: number = 0.02;
export const LIGHT_DIFFUSE: [number, number, number] = [1, 1, 1];
export const LIGHT_GROUND: [number, number, number] = [0, 0, 0];
export const LIGHT_POSITION: [number, number, number] = [0, 3, 0];
export const LIGHT_DIRECTIONS: { [key: string]: [number, number, number] } = {
  [LightType.Ambient]: [0, 1, 0],
  [LightType.Directional]: [0, -1, 0],
  [LightType.Point]: [0, 0, 0],
  [LightType.Spot]: [0, -1, 0],
};

export const OBJECT_POSITION: [number, number, number] = [0, 0, 0];
export const OBJECT_QUATERNION: [number, number, number, number] = [0, 0, 0, 1];
export const OBJECT_SCALING: [number, number, number] = [1, 1, 1];

export const SCREEN_SCALING: [number, number] = [1, 1];

export const STAGE_POSITION: [number, number, number] = [0, 0, 0];
export const STAGE_ROTATION = 0;
export const STAGE_SCALING: [number, number, number] = [1, 1, 1];

export const TEXT_POSITION: [number, number, number] = [0, 0, 0];
export const TEXT_QUATERNION: [number, number, number, number] = [0, 0, 0, 1];
export const TEXT_SCALING: [number, number] = [1, 1];
export const TEXT_FONT_WEIGHT: string = '600';
export const TEXT_FONT_SIZE: string = '144px';
export const TEXT_FONT_FAMILY: string = 'Arial';
export const TEXT_LINE_HEIGHT: number = 24;
export const TEXT_LETTER_SPACING: number = 0;
export const TEXT_PADDING: [number, number, number, number] = [40, 40, 40, 40];
export const TEXT_COLOR: [number, number, number, number] = [1, 1, 1, 1];
export const TEXT_BACKGROUND: [number, number, number, number] = [1, 1, 1, 1];

export const EFFECT_DEFAULTS = {
  [EffectType.Blink]: { speed: 1 },
  [EffectType.Bounce]: { speed: 2.5, height: 5 },
  [EffectType.Inflate]: { speed: 0.05, percent: 50 },
  [EffectType.Move]: { speed: 1, distance: 5, mode: MoveMode.SideToSide },
  [EffectType.Roll]: { speed: 1, distance: 5, direction: 0 },
  [EffectType.Shine]: { speed: 1, color: 'rgb(247, 255, 199)' },
  [EffectType.Spin]: { speed: 1 },
};
