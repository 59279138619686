import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccountPanelService } from '@/ui/src/lib/layout/account-panel/account-panel.service';
import { createUsernameExistsValidator } from '@/ui/src/lib/validators/username-exists.validator';
import { UserB2CAttribute } from '@/data/src/lib/enums/user';
import { Account } from '@/data/src/lib/models/data/account';
import { MAX_CHARACTERS } from '@/data/src/lib/enums/max-length';
import { deepCopy } from '@/data/src/lib/utils/data';

@UntilDestroy()
@Component({
  selector: 'ui-account-panel-user-info',
  templateUrl: './account-panel-user-info.component.html',
  styleUrls: ['./account-panel-user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountPanelUserInfoComponent implements OnInit {
  email$ = this._accountPanelService.email$;
  form: UntypedFormGroup;
  account: Account;
  showUsernameHints = false;
  fileUploading = false;
  userB2CAttribute = UserB2CAttribute;
  marketingConsent: boolean;
  loading = false;

  constructor(
    private _accountPanelService: AccountPanelService,
    private _cd: ChangeDetectorRef,
  ) {
    this._accountPanelService.account$.pipe(untilDestroyed(this)).subscribe((account) => {
      this.account = deepCopy(<Account>account);
      const marketingConsentAttribute = this.account?.B2CAttribute.find(
        (attribute) => attribute.AttrName === UserB2CAttribute.MarrketingConsent,
      );
      this.marketingConsent = marketingConsentAttribute?.AttrValue.ValueKind === 'True' ? true : false;
      this._cd.markForCheck();
    });
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      Username: new UntypedFormControl(
        this.account.Username,
        [Validators.required, Validators.maxLength(MAX_CHARACTERS.Default), Validators.pattern('[a-zA-Z0-9_\\-\\.]*')],
        [createUsernameExistsValidator(this._accountPanelService, this._cd)],
      ),
    });
  }

  openDetails(name: string): void {
    this._accountPanelService.openDetails(name);
  }

  async removeThumbnail() {
    this.fileUploading = true;
    this._cd.detectChanges();
    await this._accountPanelService.removeThumbnail();
    this.fileUploading = false;
    this._cd.detectChanges();
  }

  async uploadFile(event) {
    this.fileUploading = true;
    this._cd.detectChanges();
    await this._accountPanelService.uploadFile(event);
    this.fileUploading = false;
    this._cd.detectChanges();
  }

  async onSave() {
    this.loading = true;
    await this._accountPanelService.updateAccount({
      ...this.form.getRawValue(),
      _b2CAttribute: this.account.B2CAttribute,
    });

    this.loading = false;
    this._accountPanelService.closeDetails();
  }

  onChangeB2CAttribute(changedValue: any, b2CAttributeName: string) {
    const attributeIndex = this.account.B2CAttribute.findIndex((attr) => attr.AttrName === b2CAttributeName);

    if (attributeIndex !== -1) {
      this.account.B2CAttribute[attributeIndex].AttrValue.ValueKind = changedValue === true ? 'True' : 'False';
    }
  }
}
