import { Directive, AfterViewInit, ElementRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[uiTrapFocus]',
})
export class TrapFocusDirective implements AfterViewInit {
  constructor(
    private el: ElementRef,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {}

  ngAfterViewInit(): void {
    this.trapFocus(this.el.nativeElement);
  }

  trapFocus(element) {
    const focusableEls1 = element.querySelectorAll(
      'a[href], button, textarea, input[type="text"],' + 'input[type="radio"], input[type="checkbox"], select',
    );
    const focusableEls = Array.from(focusableEls1).filter((el: any) => !el.disabled);
    const firstFocusableEl: any = focusableEls[0];
    const lastFocusableEl: any = focusableEls[focusableEls.length - 1];
    firstFocusableEl?.focus();

    element.addEventListener('keydown', function (this: TrapFocusDirective, e) {
      var isTabPressed = e.keyCode === 9;
      if (!isTabPressed) return;

      if (e.shiftKey) {
        if (this._document.activeElement === firstFocusableEl) {
          /* shift + tab */
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } else {
        if (this._document.activeElement === lastFocusableEl) {
          /* tab */
          firstFocusableEl.focus();
          e.preventDefault();
        }
      }
    });
  }
}
