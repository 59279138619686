import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'ui-m-google-form-tool',
  templateUrl: './m-google-form-tool.component.html',
  styleUrls: ['./m-google-form-tool.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileGoogleFormToolComponent implements OnInit {
  title = '';
  link: string | null = '';

  constructor(private _modalService: ModalService, private _sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (this.link) {
      const link = this.replaceUSP(this.link);
      this.link = this._sanitizer.sanitize(4, link) ?? link;
    }
  }

  close() {
    this._modalService.close(false, this['modalContainerRef']);
  }

  // remove link marking and add embedded marking
  replaceUSP(link: string): string {
    let newLink = link;
    if (link.includes('usp=sf_link') && !link.includes('embedded=true')) {
      newLink = link.replace('usp=sf_link', 'embedded=true');
    } else if (link.includes('usp=sf_link')) {
      newLink = link.replace('usp=sf_link', '');
    }
    return newLink;
  }
}
