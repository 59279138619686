import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Options } from 'ngx-slider-v2';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ENVIRONMENT_INTENSITY,
  ENVIRONMENT_LEVEL,
  ENVIRONMENT_ROTATION,
  ENVIRONMENT_TINT,
  ElementType,
  EnvironmentType,
  TEnvironmentElement,
} from '@/data/src/lib/view-manager';
import { ApplicationService } from '@/view/src/app/app.service';
import { PanelComponent } from '@/ui/src/lib/components/panel/panel.component';
import { ViewManagerUtils } from '@/data/src/lib/utils/view-manager-utils';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'ui-environment-panel',
  templateUrl: './environment-panel.component.html',
  styleUrls: ['./environment-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvironmentPanelComponent extends PanelComponent {
  @Input() disableType = false;
  @Input() disableIntensity = false;
  @Input() disableRotation = false;
  @Input() disableLevel = false;

  element: TEnvironmentElement | undefined;
  intensity = ENVIRONMENT_INTENSITY;
  level = ENVIRONMENT_LEVEL;
  rotation = ENVIRONMENT_ROTATION;
  tint = ViewManagerUtils.GetColorString(ENVIRONMENT_TINT);

  environmentOptions = [
    {
      label: this._translateService.instant('oxr.creatingSpace.panels.environment.background'),
      value: EnvironmentType.Background,
    },
    {
      label: this._translateService.instant('oxr.creatingSpace.panels.environment.illumination'),
      value: EnvironmentType.Illumination,
    },
    {
      label: this._translateService.instant('oxr.creatingSpace.panels.environment.combined'),
      value: EnvironmentType.Combined,
    },
  ];

  intensityOptions: Options = { floor: 0, ceil: 2, step: 0.01, showSelectionBar: true };
  levelOptions: Options = { floor: 0, ceil: 10, step: 0.01, showSelectionBar: true };
  rotationOptions: Options = { floor: 0, ceil: 360, step: 1, showSelectionBar: true };

  constructor(
    protected _appService: ApplicationService,
    private _translateService: TranslateService,
    private _cd: ChangeDetectorRef,
  ) {
    super(_appService);
  }

  ngOnInit() {
    this._appService.viewElementsSubject.pipe(untilDestroyed(this)).subscribe((elements) => {
      this.element = elements.find(({ type }) => type === ElementType.Environment) as TEnvironmentElement;
      if (this.element) {
        this.intensity = this.element.parameters.intensity ?? ENVIRONMENT_INTENSITY;
        this.level = this.element.parameters.level ?? ENVIRONMENT_LEVEL;
        this.rotation = (this.element.parameters.rotation ?? ENVIRONMENT_ROTATION) * 360;
        this.tint = ViewManagerUtils.GetColorString(this.element.parameters.tint ?? ENVIRONMENT_TINT);
      }
      this._cd.detectChanges();
    });
  }

  onTypeChange(type: EnvironmentType) {
    this.element &&
      this._appService.updateViewElement({
        ...this.element,
        parameters: { ...this.element.parameters, type },
      });
  }

  onIntensityChange(intensity: number) {
    this.intensity = intensity;
    this.element &&
      this._appService.updateViewRenderable({
        ...this.element,
        parameters: { ...this.element.parameters, intensity },
      });
  }

  onLevelChange(level: number) {
    this.level = level;
    this.element &&
      this._appService.updateViewRenderable({
        ...this.element,
        parameters: { ...this.element.parameters, level },
      });
  }

  onRotationChange(rotation: number) {
    this.rotation = rotation;
    this.element &&
      this._appService.updateViewRenderable({
        ...this.element,
        parameters: { ...this.element.parameters, rotation: rotation / 360 },
      });
  }

  onColorChange(tint: string) {
    this.tint = tint;
    this.element &&
      this._appService.updateViewRenderable({
        ...this.element,
        parameters: { ...this.element.parameters, tint: ViewManagerUtils.GetColorArray(tint) as TEnvironmentElement['parameters']['tint'] },
      });
  }

  onChangeEnd() {
    if (!this.element) {
      return;
    }
    const actualElement = this.element && this._appService.getViewRenderable(this.element)?.element;
    if (!actualElement) {
      return;
    }
    if (
      this.element.parameters.intensity !== this.intensity ||
      this.element.parameters.level !== this.level ||
      this.element.parameters.rotation !== this.rotation ||
      ViewManagerUtils.GetColorArray(this.tint)
        .slice(0, 3)
        .some((x, i) => this.element?.parameters?.tint?.[i] !== x)
    ) {
      this._appService.updateViewElement(actualElement);
    }
  }

  ngOnDestroy(): void {
    this.onChangeEnd();
    super.ngOnDestroy();
  }
}
