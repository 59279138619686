import { environment } from '@/app/src/environments/environment';
import { DomainService } from '@/data/src/lib/services/domain.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomainMap } from '@/data/src/lib/models/data/domain';
import { DomainType } from '@/data/src/lib/enums/domain';
import { ModalService } from '../../modal/modal.service';
import { ConfirmationComponent } from '../../modal/confirmation/confirmation.component';
import { TranslateService } from '@ngx-translate/core';
import { UrlService } from '@/data/src/lib/services/url.service';
import { AccountService } from '@/data/src/lib/services/account.service';
import { EnterpriseRole } from '@/data/src/lib/models/data/enterprise';
@Component({
  selector: 'ui-domain-list',
  templateUrl: './domain-list.component.html',
  styleUrls: ['./domain-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DomainListComponent implements OnInit {
  _domains: DomainMap[] = [];

  @Input() set domains(value: DomainMap[]) {
    this._domains = value;
  }

  selectedDomain: DomainMap | undefined = undefined;
  loadingArray: any[] = [];

  get domains() {
    return this._domains;
  }

  constructor(
    private _domainService: DomainService,
    private _router: Router,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _urlService: UrlService,
    private _cd: ChangeDetectorRef,
    private _accountService: AccountService,
  ) {}

  ngOnInit(): void {}

  setLoading(value, isLoading) {
    const index = this.loadingArray.findIndex((v) => v === value);
    if (isLoading) {
      index === -1 && this.loadingArray.push(value);
    } else {
      index > -1 && this.loadingArray.splice(index, 1);
    }
    this._cd.detectChanges();
  }

  onClickSetting(domain: DomainMap) {
    if (this._accountService.currentAccountOption.getValue()?.Role !== EnterpriseRole.Collaborator) {
      this.selectedDomain = this.selectedDomain?.Id === domain.Id ? undefined : domain;
    }
  }

  async onClickOption(option: string) {
    if (this._accountService.currentAccountOption.getValue()?.Role !== EnterpriseRole.Collaborator) {
      const selectedDomain = this.selectedDomain;
      if (selectedDomain) {
        switch (option) {
          case 'view':
            this._urlService.windowOpenWithPlatform(`https://${selectedDomain.Path}`);
            break;
          case 'changeSpace':
            this._domainService.setChangeSubject(selectedDomain);
            if (selectedDomain.DomainType === DomainType.Subdomain) {
              this._router.navigate(['domain', 'search', 'setting', 'ownxr']);
            } else {
              this._router.navigate(['domain', 'search', 'setting', 'connection']);
            }
            break;
          case 'extension':
            this._urlService.windowOpenWithPlatform(`${environment.redirectURL}/oxr/subscriptionManagement`);
            break;
          case 'delete':
            const modal = this._modalService.open(ConfirmationComponent);
            if (modal) {
              modal.instance.title = this._translateService.instant('shared.information.information');
              modal.instance.confirmAction = this._translateService.instant('shared.confirmation.yes');
              modal.instance.cancelAction = this._translateService.instant('shared.confirmation.cancel');
              modal.instance.body = this._translateService.instant('shared.confirmation.deleteDomainConfirmationMessage', {
                domain: selectedDomain!.Path,
              });

              await modal.result.then(async (confirmed) => {
                if (confirmed) {
                  this.setLoading(selectedDomain.Id, true);
                  this.selectedDomain = selectedDomain;
                  await this._domainService.delete(selectedDomain!.Path);
                  this.setLoading(selectedDomain.Id, false);
                  const index = this._domains.findIndex((d) => d.Id === selectedDomain.Id);
                  index > -1 && this._domains.splice(index, 1);
                  this._cd.detectChanges();
                }
              });
            }

            break;
          case 'dnsSettings':
            if (selectedDomain.DomainType === 'Owned') {
              //go to dns setting page
            }
            break;
        }
      }
    }
  }
}
