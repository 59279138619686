<div class="popup-content-modal">
  <section class="modal-header">
    <div class="header-title">{{ 'oxr.creatingSpace.Link' | translate }}</div>
    <div class="close icon-wrapper" (click)="close()">
      <div class="icon icon-close sm"></div>
    </div>
  </section>
  <div class="modal-body">
    <div class="text">
      {{ 'oxr.creatingSpace.Link' | translate }}
    </div>
    <div class="input-wrapper">
      <input uiInput placeholder="www.ownxr.com" [(ngModel)]="link" class="mb-16" />
      <div class="warning">{{ 'oxr.creatingSpace.linkWarning' | translate }}</div>
      <div class="checkbox-group">
        <ui-check-box
          class="selection-checkbox white-grey-500 xs"
          (checkedChange)="setDirectRedirect($event)"
          [checked]="openWithoutPrompt"
          [disabled]="isScenePlanPropertyRestriction"
        >
          {{ 'oxr.creatingSpace.directlyGo' | translate }}
        </ui-check-box>
        <i
          class="icon icon-info"
          uiTooltip="{{ 'oxr.creatingSpace.proPlanEnabledFeaturesTooltip' | translate }}"
          [tooltipPosition]="toolTipPositions.Below"
          tooltipClasses="tooltip"
        ></i>
      </div>
      <div class="checkbox-group">
        <ui-check-box
          class="selection-checkbox white-grey-500 xs"
          (checkedChange)="setOpenOnCurrentTab($event)"
          [checked]="openOnCurrentTab"
          [disabled]="isScenePlanPropertyRestriction"
        >
          {{ 'oxr.creatingSpace.openCurrentTab' | translate }}
        </ui-check-box>
        <i
          class="icon icon-info"
          uiTooltip="{{ 'oxr.creatingSpace.proPlanEnabledFeaturesTooltip' | translate }}"
          [tooltipPosition]="toolTipPositions.Below"
          tooltipClasses="tooltip"
        ></i>
      </div>
    </div>
    <section class="footer">
      <button [disabled]="!link" ui-button md (click)="save()">
        {{ 'shared.actions.save' | translate }}
      </button>
    </section>
  </div>
</div>
