import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaComponent implements OnInit {
  @Input() model = '';
  @Input() placeholder = '';
  @Input() value = '';
  @Input() disabled = false;

  @Output() textChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {
    return;
  }

  onChange(value: string) {
    this.textChange.emit(value);
  }
}
