import { Node } from '@babylonjs/core/node';
import { Scene } from '@babylonjs/core/scene';
import { FloatArray, Nullable } from '@babylonjs/core/types';
import { ActionEvent } from '@babylonjs/core/Actions/actionEvent';
import { ActionManager } from '@babylonjs/core/Actions/actionManager';
import { ExecuteCodeAction } from '@babylonjs/core/Actions/directActions';
import { Animation } from '@babylonjs/core/Animations/animation';
import { AnimationGroup } from '@babylonjs/core/Animations/animationGroup';
import { EasingFunction, PowerEase } from '@babylonjs/core/Animations/easing';
import { PointerDragBehavior } from '@babylonjs/core/Behaviors/Meshes/pointerDragBehavior';
import { Skeleton } from '@babylonjs/core/Bones/skeleton';
import { VertexBuffer } from '@babylonjs/core/Buffers/buffer';
import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera';
import { Camera } from '@babylonjs/core/Cameras/camera';
import { ICameraInput } from '@babylonjs/core/Cameras/cameraInputsManager';
import { UniversalCamera } from '@babylonjs/core/Cameras/universalCamera';
import { PickingInfo } from '@babylonjs/core/Collisions/pickingInfo';
import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo';
import { Ray } from '@babylonjs/core/Culling/ray';
import { RayHelper } from '@babylonjs/core/Debug/rayHelper';
import { Constants } from '@babylonjs/core/Engines/constants';
import { Engine } from '@babylonjs/core/Engines/engine';
import { ICanvasRenderingContext } from '@babylonjs/core/Engines/ICanvas';
import { RenderTargetWrapper } from '@babylonjs/core/Engines/renderTargetWrapper';
import { ThinEngine } from '@babylonjs/core/Engines/thinEngine';
import { IMouseEvent } from '@babylonjs/core/Events/deviceInputEvents';
import { KeyboardInfo } from '@babylonjs/core/Events/keyboardEvents';
import { PointerInfo, PointerEventTypes } from '@babylonjs/core/Events/pointerEvents';
import { AxisDragGizmo } from '@babylonjs/core/Gizmos/axisDragGizmo';
import { BoundingBoxGizmo } from '@babylonjs/core/Gizmos/boundingBoxGizmo';
import { Gizmo } from '@babylonjs/core/Gizmos/gizmo';
import { GizmoManager } from '@babylonjs/core/Gizmos/gizmoManager';
import { LightGizmo } from '@babylonjs/core/Gizmos/lightGizmo';
import { PlaneDragGizmo } from '@babylonjs/core/Gizmos/planeDragGizmo';
import { PlaneRotationGizmo } from '@babylonjs/core/Gizmos/planeRotationGizmo';
import { PositionGizmo } from '@babylonjs/core/Gizmos/positionGizmo';
import { RotationGizmo } from '@babylonjs/core/Gizmos/rotationGizmo';
import { ScaleGizmo } from '@babylonjs/core/Gizmos/scaleGizmo';
import { HemisphericLight } from '@babylonjs/core/Lights/hemisphericLight';
import { DirectionalLight } from '@babylonjs/core/Lights/directionalLight';
import { PointLight } from '@babylonjs/core/Lights/pointLight';
import { SpotLight } from '@babylonjs/core/Lights/spotLight';
import { Light } from '@babylonjs/core/Lights/light';
import { ShadowGenerator } from '@babylonjs/core/Lights/Shadows/shadowGenerator';
import { ISceneLoaderAsyncResult, SceneLoader } from '@babylonjs/core/Loading/sceneLoader';
import { Color3, Color4 } from '@babylonjs/core/Maths/math.color';
import { Plane } from '@babylonjs/core/Maths/math.plane';
import { Viewport } from '@babylonjs/core/Maths/math.viewport';
import { Matrix, Quaternion, Vector3 } from '@babylonjs/core/Maths/math.vector';
import { EffectRenderer, EffectWrapper } from '@babylonjs/core/Materials/effectRenderer';
import { Material } from '@babylonjs/core/Materials/material';
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { PBRMaterial } from '@babylonjs/core/Materials/PBR/pbrMaterial';
import { BaseTexture } from '@babylonjs/core/Materials/Textures/baseTexture';
import { InternalTexture } from '@babylonjs/core/Materials/Textures/internalTexture';
import { VideoTexture } from '@babylonjs/core/Materials/Textures/videoTexture';
import { CubeTexture } from '@babylonjs/core/Materials/Textures/cubeTexture';
import { DynamicTexture } from '@babylonjs/core/Materials/Textures/dynamicTexture';
import { HDRCubeTexture } from '@babylonjs/core/Materials/Textures/hdrCubeTexture';
import { Texture } from '@babylonjs/core/Materials/Textures/texture';
import { AbstractMesh } from '@babylonjs/core/Meshes/abstractMesh';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { LinesMesh } from '@babylonjs/core/Meshes/linesMesh';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { Observer, Observable } from '@babylonjs/core/Misc/observable';
import { PrecisionDate } from '@babylonjs/core/Misc/precisionDate';
import { Tools } from '@babylonjs/core/Misc/tools';
import { UtilityLayerRenderer } from '@babylonjs/core/Rendering/utilityLayerRenderer';
// DO NOT REMOVE WebXRHitTestLegacy IMPORT
import { WebXRHitTestLegacy } from '@babylonjs/core/XR/features/WebXRHitTestLegacy';
import { WebXRControllerComponent } from '@babylonjs/core/XR/motionController/webXRControllerComponent';
import { WebXRAbstractMotionController } from '@babylonjs/core/XR/motionController/webXRAbstractMotionController';
import { WebXRCamera } from '@babylonjs/core/XR/webXRCamera';
import { WebXRDefaultExperience } from '@babylonjs/core/XR/webXRDefaultExperience';
import { WebXRInputSource } from '@babylonjs/core/XR/webXRInputSource';
import { WebXRSessionManager } from '@babylonjs/core/XR/webXRSessionManager';
import { WebXRState } from '@babylonjs/core/XR/webXRTypes';
import { GLTF2Export, IExportOptions } from '@babylonjs/serializers/glTF/2.0';

import '@babylonjs/core/Animations/animatable';
import '@babylonjs/core/Collisions/collisionCoordinator';
import '@babylonjs/core/Engines/Extensions/engine.externalTexture';
import '@babylonjs/core/Helpers/sceneHelpers';
import '@babylonjs/core/Lights/Shadows/shadowGeneratorSceneComponent';
import '@babylonjs/core/Loading/Plugins/babylonFileLoader';
import '@babylonjs/core/Materials/Node/Blocks';
import '@babylonjs/core/Materials/Textures/Loaders/ddsTextureLoader';
import '@babylonjs/core/Materials/Textures/Loaders/envTextureLoader';
import '@babylonjs/core/Materials/Textures/Loaders/hdrTextureLoader';
import '@babylonjs/core/Misc/screenshotTools';
import '@babylonjs/core/Rendering/depthPeelingSceneComponent';
import '@babylonjs/core/Rendering/geometryBufferRendererSceneComponent';
import '@babylonjs/core/Rendering/prePassRendererSceneComponent';
import '@babylonjs/core/Rendering/outlineRenderer';
import '@babylonjs/loaders/glTF';

Engine.CollisionsEpsilon = 0.0035;

type IPointerEventData = {
  delta: Vector3;
  dragPlanePoint: Vector3;
  dragPlaneNormal: Vector3;
  dragDistance: number;
  pointerId: number;
  pointerInfo: Nullable<PointerInfo>;
};

export {
  ActionEvent,
  AxisDragGizmo,
  AbstractMesh,
  ActionManager,
  ArcRotateCamera,
  Animation,
  AnimationGroup,
  BaseTexture,
  BoundingBoxGizmo,
  BoundingInfo,
  Camera,
  Color3,
  Color4,
  Constants,
  CubeTexture,
  DirectionalLight,
  DynamicTexture,
  EasingFunction,
  EffectRenderer,
  EffectWrapper,
  Engine,
  ExecuteCodeAction,
  FloatArray,
  Gizmo,
  GizmoManager,
  GLTF2Export,
  HDRCubeTexture,
  HemisphericLight,
  ICameraInput,
  ICanvasRenderingContext,
  IExportOptions,
  IMouseEvent,
  IPointerEventData,
  ISceneLoaderAsyncResult,
  InternalTexture,
  KeyboardInfo,
  Light,
  LightGizmo,
  LinesMesh,
  Material,
  Matrix,
  Mesh,
  MeshBuilder,
  Node,
  Nullable,
  Observable,
  Observer,
  PBRMaterial,
  PickingInfo,
  Plane,
  PlaneDragGizmo,
  PlaneRotationGizmo,
  PointerDragBehavior,
  PointerEventTypes,
  PointerInfo,
  PointLight,
  PositionGizmo,
  PowerEase,
  PrecisionDate,
  Quaternion,
  Ray,
  RayHelper,
  RenderTargetWrapper,
  RotationGizmo,
  ScaleGizmo,
  Scene,
  SceneLoader,
  ShadowGenerator,
  Skeleton,
  SpotLight,
  StandardMaterial,
  Texture,
  ThinEngine,
  Tools,
  TransformNode,
  UniversalCamera,
  UtilityLayerRenderer,
  Vector3,
  VertexBuffer,
  VideoTexture,
  Viewport,
  WebXRAbstractMotionController,
  WebXRCamera,
  WebXRControllerComponent,
  WebXRDefaultExperience,
  WebXRInputSource,
  WebXRSessionManager,
  WebXRState,
};
