export enum MAX_CHARACTERS {
  Default = 20,
  Default_EN = 50,
  Title_EN = 50,
  Title_KO = 30,
  Tag_EN = 40,
  Tag_KO = 20,
  SceneName_EN = 50,
  SceneName_KO = 20,
}
