'use strict';

const ipRegex = require('ip-regex');
const isIp = string => ipRegex({
  exact: true
}).test(string);
isIp.v4 = string => ipRegex.v4({
  exact: true
}).test(string);
isIp.v6 = string => ipRegex.v6({
  exact: true
}).test(string);
isIp.version = string => isIp(string) ? isIp.v4(string) ? 4 : 6 : undefined;
module.exports = isIp;