import { Component, NgZone, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Email, EmailService } from 'projects/data/src/lib/services/email.service';
import { InformationComponent } from '../information/information.component';
import { ModalService } from '../modal.service';
import { interval, take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'ui-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  // Way to get value : this.form.controls['firstName'].value
  form = new FormGroup({
    inquiryType: new FormControl(null, Validators.required),
    company: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    contents: new FormControl('', Validators.required),
  });

  modalRef: ModalService;
  checked = false;

  inquiryType: string[] = ['productInquiry', 'technicalSupport', 'demoRequest', 'businessInquiry', 'etc'];

  selectedInquiryType: any | null = null;

  constructor(
    private _emailService: EmailService,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _ngZone: NgZone,
  ) {}

  ngOnInit(): void {}

  onClose() {
    this.modalRef.close(false);
  }

  async onClick() {
    const email: Email = {
      toEmail: 'support@uroom.io',
      subject: `[플랜 문의] ${this.form.controls['company'].value}의 ${this.form.controls['name'].value}님이 문의하셨습니다.`,
      plainTextContent: '',
      htmlContent: `
      <p>메일 제목 :[플랜 문의] ${this.form.controls['company'].value}의 ${this.form.controls['name'].value}님이 문의하셨습니다.</p>
      <p>&nbsp;</p>
      <p>InquiryType: ${this.selectedInquiryType.$ngOptionLabel}</p>
      <p>Name: ${this.form.controls['name'].value}</p>
      <p>Company: ${this.form.controls['company'].value}</p>
      <p>Email: ${this.form.controls['email'].value}</p>
      <p>Contact: ${this.form.controls['phone'].value}</p>
      <p>Content: ${this.form.controls['contents'].value}</p>
      `,
      fromEmail: 'adika@uroom.io',
    };

    this._emailService.sendEmail(email).then(() => {
      const modal = this._modalService.open(InformationComponent);
      if (modal) {
        modal.instance.message = this._translateService.instant('subscription.guide1');

        this._ngZone.runOutsideAngular(() => {
          interval(2000)
            .pipe(take(1), untilDestroyed(this))
            .subscribe(() => {
              if (modal) {
                modal.instance.close();
                this._ngZone.run(() => {});
              }
            });
        });
      }
    });

    this.modalRef.close(true);
  }
}
