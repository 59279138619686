<ui-panel [title]="'oxr.elements.text' | translate" [collapsing]="true" [panelId]="panelMask.Text">
  <ui-textarea
    [value]="text"
    [placeholder]="'oxr.creatingSpace.panels.text.placeholder' | translate"
    (textChange)="onTextChange($event)"
    (focusout)="onChangeEnd()"
  ></ui-textarea>
  <div class="text-padding">
    <div>
      @if (isPaddingLinked) {
        <div class="padding-row">
          <label for="padding-vertical">
            <div class="icon sm icon-padding-vertical"></div>
          </label>
          <input
            uiInput
            id="padding-vertical"
            type="text"
            [ngModel]="paddingTop"
            (ngModelChange)="onPaddingChange($event, paddingMask.Vertical)"
            (focusout)="onChangeEnd()"
          />
          <label for="padding-horizontal">
            <div class="icon sm icon-padding-horizontal"></div>
          </label>
          <input
            uiInput
            id="padding-horizontal"
            type="text"
            [ngModel]="paddingRight"
            (ngModelChange)="onPaddingChange($event, paddingMask.Horizontal)"
            (focusout)="onChangeEnd()"
          />
        </div>
      } @else {
        <div class="padding-row">
          <label for="padding-top">
            <div class="icon sm icon-padding-top"></div>
          </label>
          <input
            uiInput
            id="padding-top"
            type="text"
            [ngModel]="paddingTop"
            (ngModelChange)="onPaddingChange($event, paddingMask.Top)"
            (focusout)="onChangeEnd()"
          />
          <label for="padding-right">
            <div class="icon sm icon-padding-right"></div>
          </label>
          <input
            uiInput
            id="padding-right"
            type="text"
            [ngModel]="paddingRight"
            (ngModelChange)="onPaddingChange($event, paddingMask.Right)"
            (focusout)="onChangeEnd()"
          />
        </div>
        <div class="padding-row">
          <label for="padding-bottom">
            <div class="icon sm icon-padding-bottom"></div>
          </label>
          <input
            uiInput
            id="padding-bottom"
            type="text"
            [ngModel]="paddingBottom"
            (ngModelChange)="onPaddingChange($event, paddingMask.Bottom)"
            (focusout)="onChangeEnd()"
          />
          <label for="padding-left">
            <div class="icon sm icon-padding-left"></div>
          </label>
          <input
            uiInput
            id="padding-left"
            type="text"
            [ngModel]="paddingLeft"
            (ngModelChange)="onPaddingChange($event, paddingMask.Left)"
            (focusout)="onChangeEnd()"
          />
        </div>
      }
    </div>
    <button ui-button [class.active]="isPaddingLinked" (click)="onPaddingLink()">
      <div class="icon xs" [ngClass]="isPaddingLinked ? 'icon-link-2' : 'icon-unlink'"></div>
    </button>
  </div>
  <ui-drop-down
    class="font-family"
    [data]="fonts"
    [(selection)]="fontFamily"
    [placeholder]="'Select font'"
    [validate]="false"
    (selectionChange)="onFontFamilyChange($event)"
  ></ui-drop-down>
  <div class="font-options">
    <input class="font-size" uiInput type="text" [ngModel]="size" (ngModelChange)="onFontSizeChange($event)" (focusout)="onChangeEnd()" />
    <ui-drop-down
      class="font-weight"
      [data]="weights"
      [(selection)]="weight"
      [placeholder]="'Font weight'"
      [validate]="false"
      (selectionChange)="onFontWeightChange($event)"
    ></ui-drop-down>
  </div>
  <div class="alignment">
    <button ui-button [class.active]="element?.parameters?.alignment === textAlignment.Left" (click)="onAlignment(textAlignment.Left)">
      <div class="icon xxs icon-align-left"></div>
    </button>
    <button ui-button [class.active]="element?.parameters?.alignment === textAlignment.Center" (click)="onAlignment(textAlignment.Center)">
      <div class="icon xxs icon-align-center"></div>
    </button>
    <button ui-button [class.active]="element?.parameters?.alignment === textAlignment.Right" (click)="onAlignment(textAlignment.Right)">
      <div class="icon xxs icon-align-right"></div>
    </button>
  </div>
  <div class="text-options">
    <div
      class="spacing"
      uiTooltip="{{ 'oxr.creatingSpace.panels.text.lineHeight' | translate }}"
      [tooltipPosition]="toolTipPositions.Below"
      tooltipClasses="tooltip"
    >
      <label for="text-line-height">
        <div class="icon sm icon-line-height"></div>
      </label>
    </div>
    <input
      uiInput
      id="text-line-height"
      type="text"
      [ngModel]="lineHeight"
      (ngModelChange)="onLineHeightChange($event)"
      (focusout)="onChangeEnd()"
    />
    <div
      class="spacing"
      uiTooltip="{{ 'oxr.creatingSpace.panels.text.letterSpacing' | translate }}"
      [tooltipPosition]="toolTipPositions.Below"
      tooltipClasses="tooltip"
    >
      <label for="text-letter-spacing">
        <div class="icon sm icon-letter-spacing"></div>
      </label>
    </div>
    <input
      uiInput
      id="text-letter-spacing"
      type="text"
      [ngModel]="letterSpacing"
      (ngModelChange)="onLetterSpacingChange($event)"
      (focusout)="onChangeEnd()"
    />
  </div>

  <div class="separator"></div>

  <div class="color">
    <ui-color-picker
      [label]="'oxr.creatingSpace.panels.text.textColor' | translate"
      [defaultColor]="color"
      (changed)="onTextColorChange($event)"
      (opacityChange)="onTextOpacityChange($event)"
      (changeEnd)="onChangeEnd()"
      [showTransparency]="true"
    ></ui-color-picker>
  </div>

  <div class="separator"></div>

  <div class="background">
    <ui-color-picker
      [label]="'oxr.creatingSpace.BackgroundColor' | translate"
      [defaultColor]="background"
      (changed)="onBackgroundColorChange($event)"
      (opacityChange)="onBackgroundOpacityChange($event)"
      (changeEnd)="onChangeEnd()"
      [showTransparency]="true"
    ></ui-color-picker>
  </div>

  <div class="separator"></div>

  <ui-switch
    class="icon-style-switch"
    [checked]="!!element?.parameters?.doubleSided"
    [label]="'oxr.creatingSpace.panels.text.doubleSided' | translate"
    [onShowContentMode]="true"
    (changed)="onDoubleSideToggle($event)"
  ></ui-switch>
</ui-panel>
