export enum Access {
  Private,
  Public,
}

export enum FileStorageType {
  Assets = 'assets',
  Public = 'public',
  // ...based on Azure DataStorage
}
