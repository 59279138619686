<div class="popup-content-modal">
  <section class="modal-header">
    <div class="header-title">{{ title || 'shared.fields.titlePlaceholder' | translate }}</div>
    <div class="close icon-wrapper" (click)="close()">
      <div class="icon icon-close sm"></div>
    </div>
  </section>
  <div class="modal-body">
    <div class="title" [class.edit]="!isPreviewMode && !isReadonly">
      @if (!isPreviewMode && !isReadonly) {
        <input uiInput fixed type="text" placeholder="{{ 'shared.fields.titlePlaceholder' | translate }}" [(ngModel)]="title" />
      }
      @if (isPreviewMode || isReadonly) {
        <div class="text">
          {{ title }}
        </div>
      }
    </div>
    <div class="google-form-wrapper">
      @if (!isPreviewMode && !isReadonly) {
        <div class="link-area">
          <div class="link-title">
            <div class="icon-google-form"></div>
            {{ 'oxr.creatingSpace.googleForm.linkTitle' | translate }}
          </div>
          <div class="link-input">
            <input
              uiInput
              placeholder="{{ 'oxr.creatingSpace.googleForm.linkPlaceholder' | translate }}"
              [formControl]="linkControl"
              [class.ng-invalid]="linkInvalid"
            />
            <div uiError [class.show]="linkInvalid">{{ 'shared.validation.notValidLink' | translate }}</div>
            <div class="link-hint">
              <div class="icon icon-info xs"></div>
              <div class="text">
                {{ 'oxr.creatingSpace.googleForm.linkHint' | translate }}
              </div>
            </div>
            <div class="link-hint">
              <div class="icon icon-info xs"></div>
              <div class="text">
                {{ 'oxr.creatingSpace.googleForm.doNotUseShortUrl' | translate }}
              </div>
            </div>
          </div>
        </div>
      }
      @if (isPreviewMode || isReadonly) {
        <div class="iframe-area">
          @if (link && !linkInvalid) {
            <iframe [src]="link | safe: 'resourceUrl'" frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe>
          }
        </div>
      }
    </div>
    <section class="footer">
      <div></div>
      <div class="buttons-container">
        @if (!isPreviewMode && !isReadonly) {
          <button ui-button sm secondary (click)="enterPreviewMode()" [disabled]="!link || linkInvalid">
            {{ 'shared.actions.preview' | translate }}
          </button>
        }
        @if (isPreviewMode && !isReadonly) {
          <button ui-button sm (click)="isPreviewMode = false">
            {{ 'shared.actions.edit' | translate }}
          </button>
        }
        @if (!isPreviewMode && !isReadonly) {
          <button ui-button sm (click)="save()" [disabled]="!link || linkInvalid">
            {{ 'shared.actions.save' | translate }}
          </button>
        }
      </div>
    </section>
  </div>
</div>
