import { ModelType } from './base';
import { FileType, MediaType } from './../../enums/file-type';
/**
 * Defines a reference to a file that the active user has uploaded
 */
export class FileReference {
  private _loadingProgress!: number;

  constructor(
    public Id: string,
    public Name: string,
    public Url: string,
    public Access: string,
    public FileType: FileType,
    public MimeType: string,
    public Length: number,
    public Thumbnail: string,
    public DateCreated: string,
    public MediaType?: MediaType,
  ) {}
  get ModelType(): ModelType {
    return ModelType.FileReference;
  }

  get LoadingProgress(): number {
    return this._loadingProgress;
  }

  set LoadingProgress(value: number) {
    this._loadingProgress = value;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  setId(id: string): void {
    this.Id = id;
  }
}
