import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Account } from 'projects/data/src/lib/models/data/account';
import { AccountService } from '@/data/src/lib/services/account.service';
import { AccountPanelService } from '../../../account-panel.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ui-withdrawal',
  templateUrl: './withdrawal.component.html',
  styleUrls: ['./withdrawal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WithdrawalComponent implements OnInit {
  account: Account;
  currentLanguage$ = this._accountService.language$;

  reason: string;
  reasonOptions: string[] = ['changeEmail', 'lackContent', 'lowUsage', 'systemError'];

  constructor(private _accountPanelService: AccountPanelService, private _accountService: AccountService) {
    this._accountPanelService.account$.pipe(untilDestroyed(this)).subscribe((account) => (this.account = <Account>account));
  }

  ngOnInit(): void {}

  sendFeedback(): void {
    this._accountPanelService.sendFeedback(this.reason);
  }

  deleteAccount(): void {
    this._accountPanelService.deleteAccount(this.reason);
  }
}
