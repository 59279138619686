import { PBRMaterial } from "@babylonjs/core/Materials/PBR/pbrMaterial.js";
import { GLTFLoader } from "../glTFLoader.js";
const NAME = "KHR_materials_dispersion";
/**
 * [Specification](https://github.com/KhronosGroup/glTF/blob/87bd64a7f5e23c84b6aef2e6082069583ed0ddb4/extensions/2.0/Khronos/KHR_materials_dispersion/README.md)
 * @experimental
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class KHR_materials_dispersion {
  /**
   * @internal
   */
  constructor(loader) {
    /**
     * The name of this extension.
     */
    this.name = NAME;
    /**
     * Defines a number that determines the order the extensions are applied.
     */
    this.order = 174;
    this._loader = loader;
    this.enabled = this._loader.isExtensionUsed(NAME);
  }
  /** @internal */
  dispose() {
    this._loader = null;
  }
  /**
   * @internal
   */
  loadMaterialPropertiesAsync(context, material, babylonMaterial) {
    return GLTFLoader.LoadExtensionAsync(context, material, this.name, (extensionContext, extension) => {
      const promises = new Array();
      promises.push(this._loader.loadMaterialBasePropertiesAsync(context, material, babylonMaterial));
      promises.push(this._loader.loadMaterialPropertiesAsync(context, material, babylonMaterial));
      promises.push(this._loadDispersionPropertiesAsync(extensionContext, material, babylonMaterial, extension));
      return Promise.all(promises).then(() => {});
    });
  }
  _loadDispersionPropertiesAsync(context, material, babylonMaterial, extension) {
    if (!(babylonMaterial instanceof PBRMaterial)) {
      throw new Error(`${context}: Material type not supported`);
    }
    // If transparency isn't enabled already, this extension shouldn't do anything.
    // i.e. it requires either the KHR_materials_transmission or KHR_materials_translucency extensions.
    if (!babylonMaterial.subSurface.isRefractionEnabled || !extension.dispersion) {
      return Promise.resolve();
    }
    babylonMaterial.subSurface.isDispersionEnabled = true;
    babylonMaterial.subSurface.dispersion = extension.dispersion;
    return Promise.resolve();
  }
}
GLTFLoader.RegisterExtension(NAME, loader => new KHR_materials_dispersion(loader));
