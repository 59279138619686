import { privateFieldReplacer } from '../../utils/json-utils';
import { IModel, ModelType } from './base';
import { FileReference } from './file-reference';
import { XRScene } from './scene';

export class SceneFile implements IModel {
  constructor(
    public Id: string,
    public userid: string,
    public sceneId: string,
    public fileReferenceId: string,
    public scene: XRScene,
    public fileReference: FileReference,
  ) {}

  get ModelType(): ModelType {
    return ModelType.SceneFile;
  }

  toJson(): string {
    return JSON.stringify(this, privateFieldReplacer);
  }

  setId(id: string): void {
    this.Id = id;
  }
}

export interface AllocatedMedia {
  ImagesSpace: number;
  VideosSpace: number;
  TotalUsedSpace: number;
}

export interface SeparateSceneStorageUsageInfo {
  ImagePercentage: number;
  VideoPercentage: number;
  TotalUsedSpace: any;
  TotalSpace: any;
}

export interface IntegratedSceneStorageUsageInfo {
  TotalUsedSpacePercentage: number;
  TotalUsedSpace: any;
  TotalSpace: any;
}
