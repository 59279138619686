import { privateFieldReplacer } from '../../utils/json-utils';
import { IModel, IProperty, ModelType } from './base';
import * as uuid from 'uuid';
import { EnterpriseContract, EnterpriseRole } from './enterprise';

/**
 * Custom account setting that can be append to the user account
 */
export class AccountSetting implements IProperty {
  constructor(
    public Id: string,
    public UserId: string,
    public Name: string,
    public Value: string,
  ) {}
  get ModelType(): ModelType {
    return ModelType.UserSetting;
  }
  toJson(): string {
    return JSON.stringify(this, privateFieldReplacer);
  }
  setId(id: string): void {
    this.Id = id;
  }

  /**
   * Creates an account setting
   * @param userId
   * @param name
   * @param value
   * @returns the account setting
   */
  static create(userId: string, name: string, value: string) {
    return new AccountSetting(uuid.v4(), userId, name, value);
  }
}

/**
 * User PricingPlan
 */
export enum PricingPlan {
  Free = 'Free',
  Pro = 'Pro',
  Enterprise = 'Enterprise',
}

/**
 * User PricingPlan
 */
export interface UserPlan {
  AllocatedStorage: number;
  Id: string;
  Plan: PricingPlan;
  UserId: string;
}

/**
 * User interface
 */
export interface IUser {
  Id: string;
  Username: string;
  DisplayName: string;
  Thumbnail: string;
}

/**
 * User Roles
 */
export interface UserRoles {
  UserId: string;
  Role: string;
  Id: string;
}

/**
 * Active user account information
 */
export class Account implements IModel, IUser {
  private _b2CAttribute: B2CAttribute[] = [];

  constructor(
    public Id: string,
    public Username: string,
    public Server: string,
    public DisplayName: string,
    public Thumbnail: string,
    /**
     * The Identity Provider the provided access to this account
     */
    public IdentityProvider: string,
    public UserRoles: UserRoles[],
    /**
     * The can create flag determines if the active user can create and upload assets
     */
    public CanCreate?: boolean,
    /**
     * The can edit content flag determines if the active user can edit custom content through out the application (support info, pricing plans etc)
     */
    public CanEditContent?: boolean,
    public UserPlan?: UserPlan,
    b2CAttribute?: B2CAttribute[],
  ) {
    // A logic for creating the relevant B2CAttribute for user accounts created prior to its addition, regarding the newly introduced B2CAttribute
    if (b2CAttribute) {
      const requiredAttrNames = ['marketingConsent', 'privacyPolicy', 'selectAll', 'termsOfUse'];
      const existingAttrNames = new Set(b2CAttribute.map((attr) => attr.AttrName));

      for (const attrName of requiredAttrNames) {
        if (!existingAttrNames.has(attrName)) {
          const newAttr = new B2CAttribute(attrName, new AttrValue(attrName === 'marketingConsent' ? 'False' : 'True'));
          b2CAttribute.push(newAttr);
        }
      }

      this.B2CAttribute = b2CAttribute;
    }
  }

  get ModelType(): ModelType {
    return ModelType.Account;
  }

  toJson(): string {
    return JSON.stringify(this, privateFieldReplacer);
  }

  setId(id: string): void {
    this.Id = id;
  }

  get B2CAttribute(): B2CAttribute[] {
    return this._b2CAttribute;
  }

  set B2CAttribute(b2CAttribute: B2CAttribute[]) {
    this._b2CAttribute = b2CAttribute;
  }
}

/**
 * Friend information and status model
 */
export class Friend implements IUser {
  constructor(
    public Id: string,
    public Username: string,
    public DisplayName: string,
    public Thumbnail: string,
    public Status: string,
  ) {}
}

export class B2CAttribute {
  constructor(
    public AttrName: string,
    public AttrValue: AttrValue,
  ) {}
}

export class AttrValue {
  constructor(public ValueKind: string) {}
}

export enum AccountOptionType {
  Individual = 'individual',
  Enterprise = 'enterprise',
}

export interface AccountOption {
  Id: string;
  OptionId: string;
  Type: AccountOptionType;
  Name?: string;
  Role?: `${EnterpriseRole}`;
  EnterpriseContract?: EnterpriseContract;
}
