import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';

import { ButtonComponent } from './button.component';

@Component({
  selector: `a[ui-button], a[ui-outlined-button]`,
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss', './anchor-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnchorButtonComponent extends ButtonComponent {
  @HostListener('click') disabledHandler(event?: Event) {
    if (this.disabled && event) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
}
