import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WINDOW } from '@ng-web-apis/common';
import { lastValueFrom } from 'rxjs';
import { environment } from '@/app/src/environments/environment';
import { AccountService } from '@/data/src/lib/services/account.service';
import { CreatorUser } from '@/data/src/lib/models/data/scene';

export interface SessionParameter {
  PriceId: string;
  SuccessUrl: string;
  FailureUrl: string;
}

export interface SessionResult {
  RedirectUrl: string;
  SessionId: string;
}

export interface Subscription {
  id: string;
  object: string;
  application: any;
  automatic_tax: {
    enabled: boolean;
  };
  billing_cycle_anchor: number;
  cancel_at: number;
  cancel_at_period_end: boolean;
  canceled_at: number;
  collection_method: string;
  created: number;
  currency: string;
  current_period_end: number;
  current_period_start: number;
  customer: string;
  default_payment_method: {
    id: string;
    object: string;
    billing_details: {
      address: {
        country: string;
      };
      email: string;
      name: string;
    };
    card: {
      brand: string;
      checks: {
        cvc_check: string;
      };
      country: string;
      exp_month: number;
      exp_year: number;
      fingerprint: string;
      funding: string;
      last4: number;
      networks: {
        available: [string];
      };
      three_d_secure_usage: {
        supported: boolean;
      };
    };
    created: number;
    customer: string;
    livemode: boolean;
    metadata: {};
    type: string;
  };
  default_source: any;
  default_tax_rates: [];
  discount: {
    id: string;
    object: string;
    coupon: {
      id: string;
      object: string;
      created: number;
      duration: string;
      duration_in_months: number;
      livemode: boolean;
      max_redemptions: number;
      metadata: {};
      name: string;
      percent_off: number;
      times_redeemed: number;
      valid: boolean;
    };
    customer: string;
    end: number;
    promotion_code: string;
    start: number;
    subscription: string;
  };
  items: {
    object: string;
    data: [
      {
        id: string;
        object: string;
        created: number;
        metadata: {};
        plan: {
          id: string;
          object: string;
          active: boolean;
          amount: number;
          amount_decimal: number;
          billing_scheme: string;
          created: number;
          currency: string;
          interval: string;
          interval_count: number;
          livemode: boolean;
          metadata: {};
          product: string;
          usage_type: string;
        };
        price: {
          id: string;
          object: string;
          active: boolean;
          billing_scheme: string;
          created: number;
          currency: string;
          livemode: boolean;
          metadata: {};
          product: string;
          recurring: {
            interval: string;
            interval_count: number;
            usage_type: string;
          };
          tax_behavior: string;
          type: string;
          unit_amount: number;
          unit_amount_decimal: number;
        };
        quantity: number;
        subscription: string;
        tax_rates: [];
      },
    ];
    has_more: boolean;
    url: string;
  };
  latest_invoice: string;
  livemode: boolean;
  metadata: {
    OwnXrSessionId: string;
  };
  on_behalf_of: any;
  payment_settings: {
    save_default_payment_method: string;
  };
  pending_setup_intent: any;
  schedule: any;
  start_date: number;
  status: string;
  test_clock: any;
}

export interface getUserPaymentsResult {
  User: CreatorUser;
  ScenesAndPayments: ScenesAndPayments[];
}

export interface ScenesAndPayments {
  Scene: {
    Archived: boolean;
    CreatorUser: CreatorUser;
    CreatorUserID: string;
    Id: string;
    Model: [];
    Name: string;
  };
  ScenePlan: {
    Expires: string;
    Id: string;
    Plan: string;
    SceneId: string;
  };
  ScenePlanPurchase: {
    Amount: number;
    Id: string;
    PurchaseId: string;
    ScenePlanId: string;
    UserID: string;
  };
  Subscription: Subscription;
}

export interface InvoiceResult {
  id: string;
  object: string;
  account_country: string;
  account_name: string;
  amount_due: number;
  amount_paid: number;
  amount_remaining: number;
  application: any;
  attempt_count: 1;
  attempted: boolean;
  auto_advance: boolean;
  automatic_tax: {
    enabled: boolean;
  };
  billing_reason: string;
  charge: string;
  collection_method: string;
  created: number;
  currency: string;
  customer: string;
  customer_address: {
    country: string;
  };
  customer_email: string;
  customer_name: string;
  customer_tax_exempt: string;
  customer_tax_ids: [];
  default_payment_method: any;
  default_source: any;
  default_tax_rates: [];
  discounts: [];
  ending_balance: number;
  hosted_invoice_url: string;
  invoice_pdf: string;
  latest_revision: any;
  lines: {
    object: string;
    data: [
      {
        id: string;
        object: string;
        amount: 5000;
        amount_excluding_tax: 5000;
        currency: string;
        description: string;
        discount_amounts: [];
        discountable: boolean;
        discounts: [];
        livemode: boolean;
        metadata: {
          OwnXrSessionId: string;
        };
        period: {
          end: number;
          start: number;
        };
        plan: {
          id: string;
          object: string;
          active: boolean;
          amount: number;
          amount_decimal: number;
          billing_scheme: string;
          created: number;
          currency: string;
          interval: string;
          interval_count: number;
          livemode: boolean;
          metadata: {};
          product: string;
          usage_type: string;
        };
        price: {
          id: string;
          object: string;
          active: boolean;
          billing_scheme: string;
          created: number;
          currency: number;
          livemode: boolean;
          metadata: {};
          product: number;
          recurring: {
            interval: number;
            interval_count: number;
            usage_type: number;
          };
          tax_behavior: number;
          type: number;
          unit_amount: number;
          unit_amount_decimal: number;
        };
        proration: number;
        proration_details: {};
        quantity: number;
        subscription: string;
        subscription_item: string;
        tax_amounts: [];
        tax_rates: [];
        type: string;
        unit_amount_excluding_tax: number;
      },
    ];
    has_more: boolean;
    url: string;
  };
  livemode: boolean;
  metadata: {};
  number: string;
  on_behalf_of: any;
  paid: boolean;
  paid_out_of_band: boolean;
  payment_intent: string;
  payment_settings: {};
  period_end: number;
  period_start: number;
  post_payment_credit_notes_amount: number;
  pre_payment_credit_notes_amount: number;
  quote: any;
  starting_balance: number;
  status: string;
  status_transitions: {
    finalized_at: number;
    paid_at: number;
  };
  subscription: string;
  subscription_proration_date: number;
  subtotal: number;
  subtotal_excluding_tax: number;
  test_clock: any;
  total: number;
  total_discount_amounts: [];
  total_excluding_tax: number;
  total_tax_amounts: [];
  webhooks_delivered_at: number;
}
@Injectable({
  providedIn: 'root',
})
export class PaymentStripeService {
  headerOption;

  constructor(
    public http: HttpClient,
    private _accountService: AccountService,
    @Inject(WINDOW) private readonly _window: Window,
  ) {
    if (environment.production) {
      this.headerOption = new HttpHeaders().set('Content-Type', 'application/json');
    } else {
      this.headerOption = new HttpHeaders().set('Content-Type', 'application/json').set('Sender', 'Staging');
    }
  }

  async redirectStripeCheckout(sceneId?: string) {
    const stripeSession: SessionParameter = {
      PriceId: environment.production ? 'price_1MRAMmCo02TfwudWhUhiHxRc' : 'price_1MJC6xCo02TfwudWsMcdM00S',
      SuccessUrl: `${this._window.document.location.href}/success?session_id={CHECKOUT_SESSION_ID}`,
      FailureUrl: `${this._window.document.location.href}/failure?session_id={CHECKOUT_SESSION_ID}`,
    };

    return await lastValueFrom(
      this.http.post<SessionResult>(
        `${environment[this._accountService.region].crudApiUrl}/Payment/create-checkout-session${sceneId ? `?sceneId=${sceneId}` : ''}`,
        JSON.stringify(stripeSession),
        { headers: this.headerOption },
      ),
    );
  }

  async getUserPayments() {
    return await lastValueFrom(
      this.http.post<getUserPaymentsResult>(`${environment[this._accountService.region].crudApiUrl}/Payment/UserPayments`, '', {
        headers: this.headerOption,
      }),
    );
  }

  async getInvoice(invoiceId: string) {
    return await lastValueFrom(
      this.http.get<InvoiceResult>(`${environment[this._accountService.region].crudApiUrl}/Payment/Stripe/Invoice?invoiceId=${invoiceId}`, {
        headers: this.headerOption,
      }),
    );
  }

  async cancelSubscription(subscriptionid: string, immediately: boolean) {
    return await lastValueFrom(
      this.http.post<Subscription>(
        `${
          environment[this._accountService.region].crudApiUrl
        }/Payment/Stripe/CancelSubscription?subscriptionid=${subscriptionid}&immediately=${immediately}`,
        '',
        {
          headers: this.headerOption,
        },
      ),
    );
  }

  async RenewSubscription(subscriptionid: string) {
    return await lastValueFrom(
      this.http.post<Subscription>(
        `${environment[this._accountService.region].crudApiUrl}/Payment/Stripe/RenewSubscription?subscriptionid=${subscriptionid}`,
        '',
        {
          headers: this.headerOption,
        },
      ),
    );
  }
}
