import { v4 as uuid } from 'uuid';
import {
  AdvancedDynamicTexture,
  Button,
  Checkbox,
  Container,
  Control,
  Grid,
  Image,
  InputText,
  InputTextArea,
  Rectangle,
  ScrollViewer,
  StackPanel,
  TextBlock,
  TextWrapping,
  Vector2WithInfo,
} from '@babylonjs/gui';
import {
  AbstractMesh,
  Animation,
  AnimationGroup,
  Color4,
  DynamicTexture,
  EasingFunction,
  ICanvasRenderingContext,
  MeshBuilder,
  Nullable,
  Observable,
  Observer,
  PowerEase,
  Vector3,
} from '../babylon';
import { LOAD_BUTTON_PNG, PopupType, TInteractableElement, TPopup, ViewManager } from '../view-manager';
import { ViewManagerUtils } from './view-manager-utils';
import { MultiLayoutKeyboard } from '../view-manager/auxiliaries/multi-layout-keyboard';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from '@/app/src/environments/environment';
import { SpaceToolLayout } from '@/ui/src/lib/utils/dragDropController';
import { SceneInteractionType } from '../enums/scene-interaction';
import { InteractionEmotion, XRSceneInteraction } from '../models/data/scene';
import { Access } from '../enums/access';
import { deepCopy } from './data';

const FRAME_RATE = 30;
const YOUTUBE_REGEXP = /(embed\/|v=|\/\d{1,2}\/|\/v\/|youtu.be\/)([\w-]{11})/;
const CLICK_DEBOUNCE_DURATION = 250;

const TEXTURE_SIZE = 2048;
const FONT_SIZE = 16;
const LINE_LENGTH = 21;
const MAX_COMMENT_LENGTH = 150;
const BUTTON_WIDTH = 200;
const BUTTON_HEIGHT = 56;
const IMG_IGNORE_NODES = ['a', 'button'];

const TRANSPARENT = '#00000000';
const BLACK = '#000000FF';
const WHITE = '#FFFFFFFF';
const WHITE_40 = '#FFFFFF66';
const WHITE_50 = '#FFFFFF80';
const PRIMARY_400 = '#FF4085FF';
const PRIMARY_500 = '#FF005CFF';
const GREY_300 = '#969FA8FF';
const GREY_400 = '#666F78FF';
const GREY_500 = '#454C53FF';
const GREY_600 = '#222C36FF';
const GREY_700 = '#11171EFF';
const GREY_700_TRANSPARENT = '#11171ECC';
const RED = '#DD0000FF';

export class GuiManager {
  private _selectedElement: TInteractableElement;
  private _parameters: { [key: string]: any } = {};
  private _popupType: PopupType;

  private _host: Nullable<AbstractMesh>;
  private _advancedTexture: Nullable<AdvancedDynamicTexture>;
  private _guiElements: { [key: string]: { [key: string]: any } } = {};

  private _inputData: { [key: string]: any } = {};
  private _interactions: XRSceneInteraction[] = [];
  private _form: UntypedFormGroup;
  private _pages: any[] = [];
  private _currentPage = 0;
  private _pageAnimationGroup: Nullable<AnimationGroup>;
  private _pageAnimation: Nullable<Animation>;
  private _pageEasingFunction: Nullable<EasingFunction>;
  private _pageInterval: NodeJS.Timeout;
  private _clickTimeout: NodeJS.Timeout;
  private _clickDisabled = true;

  private _modalHost: Nullable<AbstractMesh>;
  private _modalAdvancedTexture: Nullable<AdvancedDynamicTexture>;
  private _modalElements: { [key: string]: any } = {};

  private _keyboardAdvancedTexture: Nullable<AdvancedDynamicTexture>;
  private _keyboardHost: Nullable<AbstractMesh>;
  private _keyboard: Nullable<MultiLayoutKeyboard>;
  private _keyboardOffset = Vector3.Zero();

  private _domParser: DOMParser;
  private _document: ChildNode | null | undefined;
  private _measurementContext: ICanvasRenderingContext;

  private _observation: { [key: string]: Nullable<{ observable: Observable<any>; observer: Nullable<Observer<any>> }> } = {};

  get advancedTexture() {
    if (!this._advancedTexture) {
      this._advancedTexture = AdvancedDynamicTexture.CreateForMesh(this.host, TEXTURE_SIZE, TEXTURE_SIZE, true);
      this.host.material!.forceDepthWrite = true;
    }
    return this._advancedTexture;
  }

  get modalAdvancedTexture() {
    if (!this._modalAdvancedTexture) {
      this._modalAdvancedTexture = AdvancedDynamicTexture.CreateForMesh(this.modalHost, TEXTURE_SIZE, TEXTURE_SIZE, true);
      this.modalHost.material!.forceDepthWrite = true;
    }
    return this._modalAdvancedTexture;
  }

  get keyboardAdvancedTexture() {
    if (!this._keyboardAdvancedTexture) {
      this._keyboardAdvancedTexture = AdvancedDynamicTexture.CreateForMesh(this.keyboardHost, TEXTURE_SIZE, TEXTURE_SIZE, true);
      this.keyboardHost.material!.forceDepthWrite = true;
    }
    return this._keyboardAdvancedTexture;
  }

  get host() {
    if (!this._host) {
      this._host = MeshBuilder.CreatePlane(`GuiHost-${uuid()}`, { size: 5 });
      this._host.scaling.x = -1;
      this._host.billboardMode = AbstractMesh.BILLBOARDMODE_ALL;
      ViewManagerUtils.AttachMetadata(this._host, { gui: true });
    }
    return this._host;
  }

  get modalHost() {
    if (!this._modalHost) {
      this._modalHost = MeshBuilder.CreatePlane(`GuiModalHost-${uuid()}`, { size: 5 });
      this._modalHost.parent = this.viewManager.vrCamera;
      this._modalHost.position = Vector3.Forward();
      this._modalHost.scaling.x = -1;
      this._modalHost.billboardMode = AbstractMesh.BILLBOARDMODE_ALL;
      ViewManagerUtils.AttachMetadata(this._modalHost, { gui: true });
    }
    return this._modalHost;
  }

  get keyboardHost() {
    if (!this._keyboardHost) {
      this._keyboardHost = MeshBuilder.CreatePlane(`GuiKeyboardHost-${uuid()}`, { size: 5 });
      ViewManagerUtils.AttachMetadata(this._keyboardHost, { gui: true });
    }
    return this._keyboardHost;
  }

  get keyboard() {
    if (!this._keyboard) {
      this._keyboard = new MultiLayoutKeyboard(this);
      this._keyboard.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
      this._keyboard.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
      this._keyboard.alpha = 0.7;
      this._keyboard.fontFamily = 'paybooc';

      this._keyboard.getDescendants(false).forEach((element) => {
        if (!(element instanceof Button)) {
          return;
        }
        element.cornerRadius = 10;
        element.pointerEnterAnimation = () => {
          element.alpha = 1;
        };
        element.pointerOutAnimation = () => {
          element.alpha = 0.7;
        };
      });

      this.scene.onAfterRenderObservable.addOnce(() => {
        if (!this._keyboard?.parent) {
          return;
        }
        this._keyboard.parent.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
        this._keyboard.parent.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        this._keyboard.parent.widthInPixels = Math.max(...this._keyboard.children.map((c) => c.widthInPixels));
        this._keyboard.parent.heightInPixels = this._keyboard.heightInPixels;
        const widthRatio = this._keyboard.parent.widthInPixels / TEXTURE_SIZE;
        const heightRatio = this._keyboard.parent.heightInPixels / TEXTURE_SIZE;
        this.keyboardHost.scaling.x = widthRatio;
        this.keyboardHost.scaling.y = heightRatio;
        this.keyboardAdvancedTexture.uScale = widthRatio;
        this.keyboardAdvancedTexture.vScale = heightRatio;
      });

      this.keyboardAdvancedTexture.addControl(this._keyboard);
    }
    return this._keyboard;
  }

  get scene() {
    return this.viewManager.scene;
  }

  get domParser() {
    if (!this._domParser) {
      this._domParser = new DOMParser();
    }
    return this._domParser;
  }

  get measurementContext() {
    if (!this._measurementContext) {
      this._measurementContext = new DynamicTexture('GuiMeasurementTexture', 16, this.scene).getContext();
    }
    return this._measurementContext;
  }

  get pageEasingFunction() {
    if (!this._pageEasingFunction) {
      this._pageEasingFunction = new PowerEase();
      this._pageEasingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
    }
    return this._pageEasingFunction;
  }

  constructor(public viewManager: ViewManager) {}

  assign(popup: TPopup, point = Vector3.RightHandedForwardReadOnly) {
    this.clear();
    this._popupType = popup.type;
    popup.parameters.forEach(({ key, value }) => {
      this._parameters[key] = value;
    });
    this.host.setEnabled(true);
    this.host.parent = null;
    this.host.position.copyFrom(point);
  }

  clear() {
    Object.entries(this._observation).forEach(([key, _observation]) => {
      _observation?.observer && _observation.observable.remove(_observation.observer);
      this._observation[key] = null;
      delete this._observation[key];
    });
    clearInterval(this._pageInterval);
    this.clearModal();
    this.clearSubConfirmation();
    this.clearKeyboard();
    this.clearInputData();
    Object.keys(this._parameters).forEach((key) => {
      this._parameters[key] = undefined;
      delete this._parameters[key];
    });
    Object.entries(this._guiElements).forEach(([popupType, value]) => {
      Object.entries(value).forEach(([key, element]) => {
        element.dispose();
        this._guiElements[popupType][key] = undefined;
        delete this._guiElements[popupType][key];
      });
      delete this._guiElements[popupType];
    });
    this._form?.reset();
    this._interactions.length = 0;
    this._pages.length = 0;
    this._currentPage = 0;
    this._pageAnimationGroup?.dispose();
    this._pageAnimationGroup = null;
    this._pageAnimation = null;
    this.host.setEnabled(false);
  }

  clearInputData() {
    Object.keys(this._inputData).forEach((key) => {
      this._inputData[key] = undefined;
      delete this._inputData[key];
    });
  }

  clearModal() {
    Object.entries(this._modalElements).forEach(([key, element]) => {
      element.dispose();
      this._modalElements[key] = undefined;
      delete this._modalElements[key];
    });
    this._observation.modalBeforeRender?.observer &&
      this._observation.modalBeforeRender.observable.remove(this._observation.modalBeforeRender.observer);
    this._observation.modalBeforeRender = null;
    delete this._observation.modalBeforeRender;
    this._modalHost?.setEnabled(false);
  }

  clearSubConfirmation() {
    this._guiElements.subConfirmation &&
      Object.entries(this._guiElements.subConfirmation).forEach(([key, element]) => {
        element.dispose();
        this._guiElements.subConfirmation[key] = undefined;
        delete this._guiElements.subConfirmation[key];
      });
    delete this._guiElements.subConfirmation;
  }

  showModal(message: string) {
    this.clearModal();
    this.modalHost.setEnabled(true);
    this._modalElements.container = this.createRectangle({ width: 900, height: 600, background: GREY_700_TRANSPARENT, cornerRadius: 5 });
    this._modalElements.container.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    this._modalElements.container.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;

    this._modalElements.text = this.createText({ text: message, color: WHITE });
    this._modalElements.body = this.createContainer();
    this._modalElements.body.setPadding(50);
    this._modalElements.body.addControl(this._modalElements.text);
    this._modalElements.body.adaptWidthToChildren = true;
    this._modalElements.body.adaptHeightToChildren = true;
    this._modalElements.topbar = this.createRectangle({ width: '100%', height: 9, background: PRIMARY_500 });
    this._modalElements.topbar.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this._modalElements.topbar.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    this._modalElements.container.addControl(this._modalElements.body);
    this._modalElements.container.addControl(this._modalElements.topbar);
    this._modalElements.container.adaptWidthToChildren = true;
    this._modalElements.container.adaptHeightToChildren = true;
    this.modalAdvancedTexture.addControl(this._modalElements.container);
    const timeout = setTimeout(() => {
      this.clearModal();
      clearTimeout(timeout);
    }, 2000);

    this.scene.onAfterRenderObservable.addOnce(() => {
      const widthRatio = this._modalElements.container.widthInPixels / TEXTURE_SIZE;
      const heightRatio = this._modalElements.container.heightInPixels / TEXTURE_SIZE;
      this.modalHost.scaling.x = -widthRatio;
      this.modalHost.scaling.y = heightRatio;
      this.modalAdvancedTexture.uScale = widthRatio;
      this.modalAdvancedTexture.vScale = heightRatio;
    });

    this.addObserver(
      this.scene.onBeforeRenderObservable,
      () => {
        this.modalHost.position = this.viewManager.vrCamera.getDirection(Vector3.RightHandedBackwardReadOnly);
      },
      'modalBeforeRender',
    );
  }

  clearKeyboard() {
    this._observation.keyboardBeforeRender?.observer &&
      this._observation.keyboardBeforeRender.observable.remove(this._observation.keyboardBeforeRender.observer);
    this._observation.keyboardBeforeRender = null;
    delete this._observation.keyboardBeforeRender;
    this._keyboardHost?.setEnabled(false);
  }

  debounceClick() {
    this._clickDisabled = true;
    this._clickTimeout = setTimeout(() => {
      this._clickDisabled = false;
      clearTimeout(this._clickTimeout);
    }, CLICK_DEBOUNCE_DURATION);
  }

  async showPopup(element: TInteractableElement, point = Vector3.RightHandedForwardReadOnly) {
    this.debounceClick();
    this._selectedElement = deepCopy(element);
    if (!element.parameters?.popups?.length) {
      return;
    }
    this.assign(element.parameters.popups[0], point);

    if (!this._guiElements[this._popupType]) {
      this._guiElements[this._popupType] = {};
    }
    const store = this._guiElements[this._popupType];
    store.container = this.createRectangle({ cornerRadius: 15 });
    store.container.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    store.container.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;

    switch (this._popupType) {
      case PopupType.Contact:
        this.constructContactForm(store);
        break;
      case PopupType.Gallery:
      case PopupType.Video:
        this.constructGallery(store);
        break;
      case PopupType.GuestBook:
        await this.constructGuestBook(store);
        break;
      case PopupType.Link:
      case PopupType.GoogleForm:
        this.constructConfirmationModal(store, {
          header: this.viewManager.appService.translate('guideModal.title'),
          body: this.viewManager.appService.translate('shared.confirmation.goToURL', {
            url: this._parameters.link ?? '',
          }),
          footnote: this.viewManager.appService.translate('shared.confirmation.backgroundLink', {
            rootUrl: environment.redirectURL,
          }),
          acceptText: this.viewManager.appService.translate('shared.actions.open').toUpperCase(),
          acceptAction: () => {
            if (!this._parameters.link.startsWith('http://') && !this._parameters.link.startsWith('https://')) {
              this._parameters.link = 'https://' + this._parameters.link;
            }
            this.viewManager.appService.windowOpenWithPlatform(this._parameters.link, true);
          },
        });
        break;
      case PopupType.Text:
        this.constructText(store);
        break;
      default:
        this.dispose();
        return;
    }

    this.advancedTexture.addControl(store.container);

    this.scene.onAfterRenderObservable.addOnce(() => {
      const widthRatio = store.container.widthInPixels / TEXTURE_SIZE;
      const heightRatio = store.container.heightInPixels / TEXTURE_SIZE;
      this.host.scaling.x = -widthRatio;
      this.host.scaling.y = heightRatio;
      this.advancedTexture.uScale = widthRatio;
      this.advancedTexture.vScale = heightRatio;
    });
  }

  constructContactForm(store: { [key: string]: any }) {
    this._form = new UntypedFormGroup({
      [this._parameters.PLACEHOLDERFIRST || this.viewManager.appService.translate('shared.fields.name')]: new UntypedFormControl(
        '',
        this._parameters.CHECKEDPLACEHOLDERFIRST ? Validators.required : undefined,
      ),
      [this._parameters.PLACEHOLDERSECOND || this.viewManager.appService.translate('shared.fields.contact')]: new UntypedFormControl(
        '',
        this._parameters.CHECKEDPLACEHOLDERSECOND ? Validators.required : undefined,
      ),
      [this._parameters.PLACEHOLDERTHIRD || this.viewManager.appService.translate('shared.fields.email')]: new UntypedFormControl(
        '',
        this._parameters.CHECKEDPLACEHOLDERTHIRD ? Validators.required : undefined,
      ),
      [this._parameters.PLACEHOLDERFOURTH || this.viewManager.appService.translate('oxr.creatingSpace.contactForm.inquiries')]:
        new UntypedFormControl('', Validators.required),
    });

    // Container
    store.container.widthInPixels = 800;
    store.container.background = GREY_700_TRANSPARENT;

    store.stackPanel = this.createStackPanel({ width: '100%' });
    store.stackPanel.fontFamily = 'paybooc';
    store.container.addControl(store.stackPanel);

    // Topbar
    this.constructTopbar(store, store.stackPanel, this._parameters.TITLE || this.viewManager.appService.translate('subscription.quote'));

    // Body
    store.body = this.createStackPanel({ width: '100%' });
    store.body.setPadding(30, 40, 60, 40);
    store.body.spacing = 20;

    store.bodyTitle = this.createText({ text: this._parameters.TITLE ?? '', fontSize: 34 });
    store.body.addControl(store.bodyTitle);
    store.bodyTitle.adaptWidthToChildren = true;

    const checkFormState = () => {
      store.save.isEnabled = this._inputData.termsChecked && this._form.valid;
    };
    [
      this._parameters.PLACEHOLDERFIRST || this.viewManager.appService.translate('shared.fields.name'),
      this._parameters.PLACEHOLDERSECOND || this.viewManager.appService.translate('shared.fields.contact'),
      this._parameters.PLACEHOLDERTHIRD || this.viewManager.appService.translate('shared.fields.email'),
    ].forEach((placeholder, i) => {
      store[`${placeholder}-${i}`] = this.createInputText({
        width: 720,
        height: 60,
        fontSize: 16,
        color: WHITE,
        background: GREY_600,
        placeholder,
        placeholderColor: WHITE,
      });
      store[`${placeholder}-${i}`].margin = '20px';
      this.addObserver(store[`${placeholder}-${i}`].onTextChangedObservable, () => {
        this._inputData[`${PopupType.Contact}${placeholder}`] = store[`${placeholder}-${i}`].text;
        this._form.controls[placeholder].setValue(this._inputData[`${PopupType.Contact}${placeholder}`]);
        checkFormState();
      });
      store.body.addControl(store[`${placeholder}-${i}`]);
    });
    store.textarea = this.createTextarea({
      width: 720,
      height: 136,
      fontSize: 16,
      color: WHITE,
      background: GREY_600,
      placeholder: this._parameters.PLACEHOLDERFOURTH || this.viewManager.appService.translate('oxr.creatingSpace.contactForm.inquiries'),
      placeholderColor: GREY_700,
    });
    store.textarea.placeholderColor = GREY_300;
    store.textarea.margin = '20px';
    this.addObserver(store.textarea.onTextChangedObservable, () => {
      this._inputData[`${PopupType.Contact}Comment`] = store.textarea.text;
      this._form.controls[store.textarea.placeholderText].setValue(this._inputData[`${PopupType.Contact}Comment`]);
      checkFormState();
    });
    store.body.addControl(store.textarea);

    store.checkbox = this.createCheckbox({
      label: this.viewManager.appService.translate('oxr.creatingSpace.contactForm.termsAndConditions'),
      width: 16,
      height: 16,
      color: WHITE,
      fontSize: 22,
    });
    this.addObserver(store.checkbox.children[0].onIsCheckedChangedObservable, (value) => {
      this._inputData.termsChecked = value;
      checkFormState();
    });
    this.assignClick(store.checkbox.children[1], () => {
      this._inputData.termsChecked = !this._inputData.termsChecked;
      store.checkbox.children[0].isChecked = this._inputData.termsChecked;
      checkFormState();
    });
    store.checkbox.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    store.body.addControl(store.checkbox);

    // Footer
    store.footer = this.createContainer();
    store.footer.setPadding(0, 0, 60, 0);
    store.save = this.createButton({
      text: this.viewManager.appService.translate('shared.actions.save').toUpperCase(),
      color: WHITE,
      background: PRIMARY_500,
      cornerRadius: 3,
    });
    this.assignClick(store.save, () => {
      this.viewManager.appService
        .addContactFormEntry(this._selectedElement.id, this._form.getRawValue())
        .then(() => {
          this.showModal(
            this._parameters.SUCCESSMESSAGE || this.viewManager.appService.translate('oxr.creatingSpace.contactForm.appreciate'),
          );
          const timeout = setTimeout(() => {
            this.clear();
            clearTimeout(timeout);
          }, 2000);
        })
        .catch((error) => {
          this.showModal(error?.error?.Title);
        });
    });
    store.save.pointerEnterAnimation = () => {
      if (!store.save.isEnabled) {
        return;
      }
      store.save.background = PRIMARY_400;
    };
    store.save.pointerOutAnimation = () => {
      if (!store.save.isEnabled) {
        return;
      }
      store.save.background = PRIMARY_500;
    };
    store.footer.addControl(store.save);
    store.footer.adaptWidthToChildren = true;
    store.footer.adaptHeightToChildren = true;

    // Size
    store.stackPanel.addControl(store.body);
    store.stackPanel.addControl(store.footer);
    store.container.adaptHeightToChildren = true;
    checkFormState();
  }

  constructEditor(store: { [key: string]: any }, htmlString: string, width: number) {
    const editor = this.createStackPanel({ width });
    editor.fontFamily = 'Arial';
    editor.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;

    this._document = this.domParser.parseFromString(htmlString, 'text/html')?.firstChild?.lastChild;
    if (this._document) {
      this.parseHTMLNode(store, this._document, editor, width);
    }

    return editor;
  }

  constructGallery(store: { [key: string]: any }) {
    for (let i = 0; i < 10; i++) {
      if (!this._parameters[`image${i}`] && !this._parameters[`video${i}`]) {
        continue;
      }
      this._pages.push({
        imageUrl: this._parameters[`image${i}`],
        videoUrl: this._parameters[`video${i}`],
        thumbnail: this._parameters[`thumbnail${i}`],
        description: this._parameters[`description${i}`],
        altText: this._parameters[`alt-text${i}`],
        hyperlink: this._parameters[`hyperlink${i}`],
        layout: this._parameters[`layout${i}`],
        color: this._parameters[`text-colour${i}`],
        background: this._parameters[`background-colour${i}`],
        positions: Object.values(this._parameters[`positions${i}`]),
      });
    }

    // Container
    store.container.widthInPixels = 1200;
    store.container.heightInPixels = 772;
    store.container.background = TRANSPARENT;
    store.container.fontFamily = 'paybooc';

    store.stackPanel = this.createStackPanel({ width: '100%' });
    store.stackPanel.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;

    // Header
    this.constructTopbar(
      store,
      store.stackPanel,
      (this._parameters.title || this.viewManager.appService.translate('shared.fields.titlePlaceholder')) +
        this.viewManager.sceneModel.Plan || '',
    );
    store.container.addControl(store.stackPanel);

    // Body
    store.bodyContainer = this.createStackPanel({ vertical: false });
    store.bodyContainer.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    store.bodyContainer.adaptWidthToChildren = true;
    store.bodyContainer.adaptHeightToChildren = true;
    this._pages.forEach((page, i) => {
      store[`PageContainer${i}`] = this.createContainer({
        width: store.container.widthInPixels,
        height: store.container.heightInPixels,
        background: page.background || WHITE,
      });

      store[`PageScroll${i}`] = this.createScrollViewer({ width: '100%', height: 800 });
      store[`PageScroll${i}`].verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;

      store[`PageStack${i}`] = this.createStackPanel({ width: '100%' });
      store[`PageStack${i}`].setPadding(20, 40, 20, 40);
      store[`PageStack${i}`].descendantsOnlyPadding = true;
      store[`PageStack${i}`].spacing = 12;

      const parseElement = (content: string, layout: SpaceToolLayout, editorWidth: number) => {
        let element: Control | undefined = undefined;
        switch (content) {
          case 'title':
            element = this.createText({ text: this._parameters.title, color: page.color || BLACK, fontSize: 20 });
            store[`PageTitle${i}`] = element;
            break;
          case 'description':
            element = this.createScrollViewer({ width: '100%' });
            element.color = TRANSPARENT;
            element.setPadding(0, 13, 20, 13);
            const editor = this.constructEditor(store, page.description, editorWidth);
            if (layout === SpaceToolLayout.EditorRight) {
              element.heightInPixels = 596;
            } else {
              editor.onAfterDrawObservable.addOnce(() => {
                (element as ScrollViewer).heightInPixels = editor.children.reduce((acc, curr) => acc + curr.heightInPixels, 0) + 50;
              });
            }
            store[`PageEditor${i}`] = editor;
            store[`PageDescription${i}`] = element;
            (element as ScrollViewer).addControl(editor);
            break;
          case 'content':
            const image = this.createImage({ url: getYouTubeThumbnail(page.videoUrl) || page.thumbnail || page.imageUrl });
            image.onImageLoadedObservable.addOnce((e) => {
              (element as any).width = '100%';
              (element as any).heightInPixels = ((element as any).widthInPixels * e.imageHeight) / e.imageWidth;
              (element as any).parent!.adaptHeightToChildren = true;

              if ((element as any).heightInPixels > 544) {
                (element as any).heightInPixels = 544;
                (element as any).fixedRatio = e.imageWidth / e.imageHeight;
                (element as any).fixedRatioMasterIsWidth = false;
              }
            });
            if (page.videoUrl) {
              image.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
              image.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;

              element = this.createContainer({ height: 544 });
              const playButton = this.createImage({ url: LOAD_BUTTON_PNG, width: 100, height: 100 });
              playButton.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
              playButton.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
              playButton.isPointerBlocker = false;

              (element as Container).addControl(image);
              (element as Container).addControl(playButton);
            } else {
              element = image;
            }
            let link = page.hyperlink || page.videoUrl;
            link &&
              this.assignClick(element, () => {
                this.constructConfirmationModal(store, {
                  embedded: true,
                  header: this.viewManager.appService.translate('guideModal.title'),
                  body: this.viewManager.appService.translate('shared.confirmation.goToURL', {
                    url: link,
                  }),
                  footnote: this.viewManager.appService.translate('shared.confirmation.backgroundLink', {
                    rootUrl: environment.redirectURL,
                  }),
                  acceptText: this.viewManager.appService.translate('shared.actions.open').toUpperCase(),
                  acceptAction: () => {
                    if (!link) {
                      return;
                    }
                    if (!link.startsWith('http://') && !link.startsWith('https://')) {
                      link = 'https://' + link;
                    }
                    this.viewManager.appService.windowOpenWithPlatform(link, true);
                  },
                });
              });
            //
            // (page.hyperlink || page.videoUrl) &&
            //   this.assignClick(element, () => {
            //     window.open(page.videoUrl || page.hyperlink, '_blank');
            //   });
            store[`PageImage${i}`] = element;
            break;
          default:
            break;
        }
        return element;
      };

      switch (page.layout) {
        case SpaceToolLayout.EditorBelow:
        case SpaceToolLayout.NoEditor:
          page.positions.forEach((content, j) => {
            const element = parseElement(content, page.layout, store.container.widthInPixels);
            if (!element) {
              return;
            }
            store[`PageStack${i}`].addControl(element);
          });
          break;
        case SpaceToolLayout.EditorRight:
          store[`PageStack${i}`].heightInPixels = 700;

          store[`PageContent${i}`] = new Grid();
          store[`PageContent${i}`].widthInPixels = 1200;
          store[`PageContent${i}`].heightInPixels = 600;
          store[`PageContent${i}`].descendantsOnlyPadding = true;
          store[`PageContent${i}`].spacing = 20;
          let added = false;
          page.positions.forEach((content, j) => {
            const element = parseElement(content, page.layout, 460);
            if (!element) {
              return;
            }
            if (content !== 'title') {
              store[`PageContent${i}`].addColumnDefinition(content === 'content' ? 0.6 : 0.4);
              element.widthInPixels = content === 'content' ? 690 : 460;
            }
            switch (j) {
              case 0:
              case 3:
                store[`PageStack${i}`].addControl(element);
                break;
              case 1:
              case 2:
                store[`PageContent${i}`].addControl(element, 0, j - 1);
                break;
              default:
                return;
            }
            if (!added && j > 0) {
              store[`PageStack${i}`].addControl(store[`PageContent${i}`]);
              added = true;
            }
          });
          break;
        default:
          break;
      }
      store[`PageScroll${i}`].addControl(store[`PageStack${i}`]);
      store[`PageContainer${i}`].addControl(store[`PageScroll${i}`]);
      store.bodyContainer.addControl(store[`PageContainer${i}`]);
    });
    store.stackPanel.addControl(store.bodyContainer);

    // Footer
    store.footerContainer = this.createRectangle({ background: WHITE_50, cornerRadius: 20 });
    store.footerContainer.setPadding(8, 10, 8, 10);
    store.footerContainer.descendantsOnlyPadding = true;
    store.footerContainer.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    store.footerContainer.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    store.footerContainer.adaptWidthToChildren = true;
    store.footerContainer.adaptHeightToChildren = true;
    store.footerContainer.topInPixels = -20;

    store.footer = this.createStackPanel({ vertical: false });
    store.footer.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    store.footer.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    store.footer.adaptWidthToChildren = true;
    store.footer.adaptHeightToChildren = true;
    store.footer.spacing = 10;

    this._pageAnimation = new Animation(uuid(), 'leftInPixels', FRAME_RATE, Animation.ANIMATIONTYPE_FLOAT);
    this._pageAnimation.setKeys(this.getPageAnimationKeys(store.bodyContainer.widthInPixels));
    this._pageAnimation.setEasingFunction(this.pageEasingFunction);
    this._pageAnimationGroup = new AnimationGroup(uuid(), this.scene);
    this._pageAnimationGroup.addTargetedAnimation(this._pageAnimation, store.bodyContainer);
    this._pageAnimationGroup.speedRatio = 1.6;

    store.right = this.createButton({ image: '/media/icon-arrow-right.svg', width: 20, height: 20 });
    store.right.image.widthInPixels = 8;
    store.right.image.heightInPixels = 16;
    store.right.pointerEnterAnimation = undefined;
    store.right.pointerOutAnimation = undefined;

    store.left = store.right.clone();
    store.left.rotation = Math.PI;
    store.left.pointerEnterAnimation = undefined;
    store.left.pointerOutAnimation = undefined;

    const pageCallback = () => {
      this._pageAnimation?.setKeys(this.getPageAnimationKeys(store.bodyContainer.leftInPixels));
      this._pageAnimationGroup?.start(false);
      store.pagination.children[0].text = `${this._currentPage + 1}/${this._pages.length}`;

      if (this._popupType === PopupType.Video) {
        return;
      }

      clearInterval(this._pageInterval);
      if (this._parameters['auto-slide']) {
        this._pageInterval = setInterval(() => {
          this._currentPage = (this._currentPage + 1) % this._pages.length;
          pageCallback();
        }, this._parameters['auto-slide'] * 1000);
      }
    };

    this.assignClick(store.right, () => {
      if (this._currentPage === this._pages.length - 1) {
        return;
      }
      this._currentPage = this._currentPage + 1;
      pageCallback();
    });
    this.assignClick(store.left, () => {
      if (this._currentPage === 0) {
        return;
      }
      this._currentPage = this._currentPage - 1;
      pageCallback();
    });

    store.pagination = this.createText({ text: `${this._currentPage + 1}/${this._pages.length}`, color: GREY_500, fontSize: 14 });
    store.footer.addControl(store.left);
    store.footer.addControl(store.pagination);
    store.footer.addControl(store.right);

    store.footerContainer.addControl(store.footer);
    store.footerContainer.onAfterDrawObservable.addOnce(() => {
      Object.keys(store).forEach((key) => {
        if (!key.startsWith('PageScroll')) {
          return;
        }
        store[key].heightInPixels -= store.footerContainer.heightInPixels - store.footerContainer.topInPixels;
      });
    });
    store.container.addControl(store.footerContainer);

    pageCallback();
  }

  async constructGuestBook(store: { [key: string]: any }) {
    const countEmoji = () => {
      Object.values(InteractionEmotion).forEach((emotion) => {
        if (!emotion) {
          return;
        }
        store[`${emotion}Count`].text = this._interactions.filter((interaction) => interaction.Interaction.Emoji === emotion).length;
      });
    };
    const fetchInteractions = async () => {
      const res = await this.viewManager.appService._sceneService.getInteractions(
        this.viewManager.sceneModel.ModelId,
        SceneInteractionType.Comment,
        this._selectedElement.id,
      );
      this._interactions.length = 0;
      this._interactions.push(...res.filter((e) => !e.Username.includes('Removed@')).sort((a, b) => (a.Date < b.Date ? -1 : 1)));
    };
    await fetchInteractions();

    // Container
    store.container.widthInPixels = 1440;
    store.container.background = '#0F0F0F80';

    store.stackPanel = this.createStackPanel({ width: '100%' });
    store.stackPanel.spacing = 25;
    store.stackPanel.fontFamily = 'paybooc';
    store.stackPanel.setPadding(50);
    store.heading = this.createText({
      text: this.viewManager.appService.translate('oxr.creatingSpace.guestBook.guestBook'),
      color: WHITE,
      fontSize: 35,
    });
    store.title = this.createText({
      text: this._parameters.introduction || this.viewManager.appService.translate('oxr.creatingSpace.guestBook.introductionPlaceholder'),
      color: GREY_300,
      fontSize: 30,
    });
    store.countBox = this.createStackPanel({ vertical: false });
    store.countBox.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    Object.values(InteractionEmotion).forEach((emotion) => {
      if (!emotion) {
        return;
      }
      store[`${emotion}Image`] = this.createImage({ url: `/media/${emotion}-simple.png`, width: 50, height: 50 });
      store[`${emotion}Count`] = this.createText({
        text: this._interactions.filter((interaction) => interaction.Interaction.Emoji === emotion).length.toString(),
        color: WHITE,
        fontSize: 22,
      });
      store.countBox.addControl(store[`${emotion}Image`]);
      store.countBox.addControl(store[`${emotion}Count`]);
    });
    store.countBox.adaptWidthToChildren = true;
    store.countBox.adaptHeightToChildren = true;

    // Body
    store.body = this.createScrollViewer({ width: '100%', height: 490 });
    store.interactions = this.createStackPanel({ vertical: false });
    store.interactions.heightInPixels = 460;
    store.interactions.spacing = 50;
    store.interactions.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    store.interactions.adaptWidthToChildren = true;

    this._interactions.forEach((interaction) => {
      if (!interaction.Interaction.Emoji) {
        return;
      }
      store[`Int-${interaction.Id}`] = this.createRectangle({ width: 360, height: '100%', background: WHITE_50, cornerRadius: 35 });

      store[`IntStack-${interaction.Id}`] = this.createStackPanel({ width: '100%' });
      store[`IntStack-${interaction.Id}`].setPadding(0, 30, 30, 30);
      store[`IntStack-${interaction.Id}`].spacing = 10;
      store[`IntStack-${interaction.Id}`].verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;

      store[`IntEmoji-${interaction.Id}`] = this.createImage({
        url: `/media/${interaction.Interaction.Emoji}-simple.png`,
        width: 200,
        height: 200,
      });
      store[`IntEmoji-${interaction.Id}`].horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;

      store[`IntUsername-${interaction.Id}`] = this.createText({ text: interaction.Username, color: BLACK, fontSize: 20 });
      store[`IntUsername-${interaction.Id}`].horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;

      store[`IntScrollViewer-${interaction.Id}`] = this.createScrollViewer({ width: 280, height: 150 });
      store[`IntScrollViewer-${interaction.Id}`].barSize = 8;
      store[`IntComment-${interaction.Id}`] = this.createText({
        text: interaction.Interaction.Comment,
        color: BLACK,
        fontSize: 20,
        width: 280,
      });
      store[`IntComment-${interaction.Id}`].children.forEach((child) => {
        if (child instanceof TextBlock) {
          child.textWrapping = TextWrapping.WordWrap;
          child.resizeToFit = true;
          child.wordSplittingFunction = wordSplittingFunction;
          child.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        }
      });

      store[`IntComment-${interaction.Id}`].adaptHeightToChildren = true;
      store[`IntScrollViewer-${interaction.Id}`].addControl(store[`IntComment-${interaction.Id}`]);
      store[`IntScrollViewer-${interaction.Id}`].horizontalBar?.parent?.dispose();

      store[`IntDate-${interaction.Id}`] = this.createText({ text: getFormattedDate(interaction.Date), color: '#555555FF', fontSize: 16 });

      store[`IntStack-${interaction.Id}`].addControl(store[`IntEmoji-${interaction.Id}`]);
      store[`IntStack-${interaction.Id}`].addControl(store[`IntUsername-${interaction.Id}`]);
      store[`IntStack-${interaction.Id}`].addControl(store[`IntScrollViewer-${interaction.Id}`]);
      store[`IntStack-${interaction.Id}`].addControl(store[`IntDate-${interaction.Id}`]);
      store[`Int-${interaction.Id}`].addControl(store[`IntStack-${interaction.Id}`]);

      if (
        (!!this.viewManager.appService.activeAccount && this.viewManager.appService.activeAccount?.Id) ||
        interaction.Username === this.viewManager.appService.activeAccount?.Username ||
        this.viewManager.appService.isEnterpriseOrAdmin
      ) {
        store[`IntClose-${interaction.Id}`] = this.createButton({
          image: '/media/icon-close.svg',
          width: 35,
          height: 35,
          background: WHITE_50,
          cornerRadius: 16,
        });
        store[`IntClose-${interaction.Id}`].verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
        store[`IntClose-${interaction.Id}`].horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
        store[`IntClose-${interaction.Id}`].topInPixels = 30;
        store[`IntClose-${interaction.Id}`].leftInPixels = -30;
        store[`IntClose-${interaction.Id}`].image.widthInPixels = 20;
        store[`IntClose-${interaction.Id}`].image.heightInPixels = 20;

        this.assignClick(store[`IntClose-${interaction.Id}`], () => {
          this._guiElements.confirmationElements = {};
          this._guiElements.confirmationElements.backdrop = this.createRectangle({
            width: '100%',
            height: '100%',
            background: GREY_700_TRANSPARENT,
            cornerRadius: 15,
          });
          store.container.addControl(this._guiElements.confirmationElements.backdrop);

          this._guiElements.confirmationElements.container = this.createRectangle({ cornerRadius: 15 });
          this._guiElements.confirmationElements.container.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
          this._guiElements.confirmationElements.container.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;

          this.scene.onAfterRenderObservable.addOnce(() => {
            this._guiElements.confirmationElements.container.topInPixels =
              (store.container.heightInPixels - this._guiElements.confirmationElements.container.heightInPixels) * -0.5;
            this._guiElements.confirmationElements.container.leftInPixels =
              (store.container.widthInPixels - this._guiElements.confirmationElements.container.widthInPixels) * 0.5;
          });

          this.constructConfirmationModal(this._guiElements.confirmationElements, {
            header: this.viewManager.appService.translate('shared.confirmation.notice'),
            body: this.viewManager.appService.translate('shared.confirmation.deletionConfirmationMessage'),
            acceptText: this.viewManager.appService.translate('shared.confirmation.confirm').toUpperCase(),
            acceptAction: async () => {
              await this.viewManager.appService._sceneService.deleteInteraction([interaction.Id]);
              const position = this.host.position.clone();
              this.dispose();
              await this.showPopup(this._selectedElement, position);
            },
            cancelAction: () => {
              this._guiElements.confirmationElements.container.dispose();
              this._guiElements.confirmationElements.backdrop.dispose();
            },
          });
          this.advancedTexture.addControl(this._guiElements.confirmationElements.container);
        });

        store[`Int-${interaction.Id}`].addControl(store[`IntClose-${interaction.Id}`]);
      }

      store.interactions.addControl(store[`Int-${interaction.Id}`]);
    });
    store.body.addControl(store.interactions);

    // Footer
    store.footer = this.createButton({
      text: this.viewManager.appService.translate('oxr.creatingSpace.guestBook.leaveGuestBook').toUpperCase(),
      width: 340,
      height: 67,
      color: WHITE,
      background: PRIMARY_500,
      cornerRadius: 3,
    });
    store.footer.setPadding(10, 0, 0, 0);
    store.footer.pointerEnterAnimation = () => {
      store.footer.background = PRIMARY_400;
    };
    store.footer.pointerOutAnimation = () => {
      store.footer.background = PRIMARY_500;
    };
    this.assignClick(store.footer, () => {
      this._guiElements.commentElements = {};
      this._guiElements.commentElements.backdrop = this.createRectangle({
        width: '100%',
        height: '100%',
        background: GREY_700_TRANSPARENT,
        cornerRadius: 15,
      });
      store.container.addControl(this._guiElements.commentElements.backdrop);

      this._guiElements.commentElements.container = this.createRectangle({ cornerRadius: 15 });
      this._guiElements.commentElements.container.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
      this._guiElements.commentElements.container.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;

      this.constructCommentModal(this._guiElements.commentElements);

      this.scene.onAfterRenderObservable.addOnce(() => {
        this._guiElements.commentElements.container.topInPixels =
          (store.container.heightInPixels - this._guiElements.commentElements.container.heightInPixels) * -0.5;
        this._guiElements.commentElements.container.leftInPixels =
          (store.container.widthInPixels - this._guiElements.commentElements.container.widthInPixels) * 0.5;
      });

      this.advancedTexture.addControl(this._guiElements.commentElements.container);
    });

    store.stackPanel.addControl(store.heading);
    store.stackPanel.addControl(store.title);
    store.stackPanel.addControl(store.countBox);
    store.stackPanel.addControl(store.body);
    store.stackPanel.addControl(store.footer);
    store.stackPanel.adaptHeightToChildren = true;
    store.container.addControl(store.stackPanel);

    store.close = this.createButton({ image: '/media/icon-close.svg', width: 48, height: 48 });
    store.close.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    store.close.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    store.close.topInPixels = 20;
    store.close.rightInPixels = 50;
    this.assignClick(store.close, () => {
      this.dispose();
    });
    store.container.addControl(store.close);
    store.container.adaptHeightToChildren = true;

    if (this._parameters.access === Access.Private) {
      if (!this.viewManager.appService.isEnterpriseOrAdmin) {
        countEmoji();
        return;
      }
      if (this.viewManager.appService.activeAccount?.Id !== this.viewManager.sceneModel.CreatorUser?.Id) {
        this._interactions.length = 0;
        this._interactions.push(...this._interactions.filter((x) => x.Username === this.viewManager.appService.activeAccount?.Username));
      }
    } else {
      this._interactions.length = 0;
    }
    countEmoji();
  }

  constructText(store: { [key: string]: any }) {
    // Container
    store.container.widthInPixels = 1200;
    store.container.heightInPixels = 772;
    store.container.background = TRANSPARENT;

    store.stackPanel = this.createStackPanel({ width: '100%' });
    store.stackPanel.fontFamily = 'paybooc';
    store.stackPanel.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;

    // Header
    this.constructTopbar(
      store,
      store.stackPanel,
      this._parameters.title || this.viewManager.appService.translate('shared.fields.titlePlaceholder'),
    );
    if (this._parameters['title-hidden'] !== 'true' && this._parameters['title-hidden'] !== true) {
      store.headingScroll = this.createScrollViewer({
        width: '100%',
        height: +this._parameters['title-height']?.replaceAll('px', '') || 250,
        background: this._parameters['background-colour'],
      });
      store.headingScroll.setPadding(0, 12, 0, 12);
      store.headingScroll.descendantsOnlyPadding = true;
      store.headingScroll.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;

      store.headingContainer = this.createContainer({ width: '100%' });
      store.headingScroll.addControl(store.headingContainer);

      store.heading = new TextBlock('GuiTextHeading', store.topbarTitle.children[0].text);
      store.heading.width = '100%';
      store.heading.fontSize = '42px';
      store.heading.fontFamily = 'Bai Jamjuree';
      store.heading.fontWeight = 600;
      store.heading.color = this._parameters['text-colour'] || GREY_500;
      store.heading.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
      store.heading.textWrapping = TextWrapping.WordWrap;

      store.headingContainer.addControl(store.heading);
      store.headingScroll.addControl(store.headingContainer);
      store.container.addControl(store.headingScroll);
      this.scene.onAfterRenderObservable.addOnce(() => {
        store.headingScroll.top = store.topbar.height;
        store.heading.heightInPixels = store.heading.computeExpectedHeight();
        store.headingContainer.heightInPixels = Math.max(store.heading.heightInPixels, store.headingScroll.heightInPixels);
        store.bodyContainer.top = store.headingScroll.height;
        store.bodyScroll.heightInPixels -= store.topbar.heightInPixels;
      });
    }
    store.container.addControl(store.stackPanel);

    // Body
    store.bodyScroll = this.createScrollViewer({ width: '100%', height: 772 });

    store.bodyContainer = this.createContainer({ width: '100%', background: WHITE });
    store.bodyContainer.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    store.bodyContainer.setPadding(0, 13, 0, 13);
    store.bodyContainer.descendantsOnlyPadding = true;

    store.editor = this.constructEditor(store, this._parameters.body, 1166);
    this.addObserver(store.bodyContainer.onAfterDrawObservable, () => {
      store.bodyContainer.heightInPixels = Math.max(store.editor.heightInPixels, 760);
    });

    store.bodyContainer.addControl(store.editor);
    store.bodyScroll.addControl(store.bodyContainer);
    store.stackPanel.addControl(store.bodyScroll);
    store.editor.adaptHeightToChildren = true;
  }

  constructTopbar(store: { [key: string]: any }, parent: StackPanel, text: string, onClose: (() => void) | undefined = undefined) {
    store.topbar = this.createContainer({ width: '100%', background: WHITE_40 });
    store.topbarTitle = this.createText({ text, color: GREY_500, alignment: Control.HORIZONTAL_ALIGNMENT_LEFT, fontSize: 14 });
    store.topbarTitle.setPadding(6, 0, 6, 20);
    store.topbarTitle.adaptWidthToChildren = true;
    store.topbarTitle.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    store.close = this.createButton({ image: '/media/icon-close.svg', width: 53, height: 32 });
    store.close.image.widthInPixels = 11;
    store.close.image.heightInPixels = 11;
    store.close.pointerEnterAnimation = () => {
      store.close.background = RED;
    };
    store.close.pointerOutAnimation = () => {
      store.close.background = TRANSPARENT;
    };
    store.close.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.assignClick(store.close, () => {
      if (onClose) {
        onClose();
        return;
      }
      this.dispose();
    });
    store.topbar.addControl(store.topbarTitle);
    store.topbar.addControl(store.close);
    store.topbar.adaptHeightToChildren = true;
    parent.addControl(store.topbar);
    this.scene.onAfterRenderObservable.addOnce(() => {
      store.topbarTitle.children[0].widthInPixels =
        store.topbar.widthInPixels - store.close.widthInPixels - store.topbarTitle.paddingLeftInPixels;
      store.topbarTitle.children[0].textWrapping = TextWrapping.Ellipsis;
    });
  }

  constructConfirmationModal(
    targetStore: { [key: string]: any },
    options: {
      header: string;
      body: string;
      footnote?: string;
      acceptText: string;
      acceptAction: () => void;
      cancelAction?: () => void;
      embedded?: boolean;
    },
  ) {
    // Store and container
    const store = options.embedded
      ? (() => {
          if (!this._guiElements.subConfirmation) {
            this._guiElements.subConfirmation = {
              overlay: this.createRectangle({ cornerRadius: 15, background: GREY_700_TRANSPARENT }),
              container: this.createRectangle({ cornerRadius: 15 }),
            };
          }
          this._guiElements.subConfirmation.overlay.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
          this._guiElements.subConfirmation.overlay.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
          this._guiElements.subConfirmation.container.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
          this._guiElements.subConfirmation.container.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
          return this._guiElements.subConfirmation;
        })()
      : targetStore;
    store.container.widthInPixels = 600;
    store.container.background = '#0F0F0FCC';

    store.stackPanel = this.createStackPanel({ width: '100%' });
    store.stackPanel.fontFamily = 'paybooc';
    store.container.addControl(store.stackPanel);

    // Header
    store.header = this.createText({ text: options.header, color: '#FFB800FF' });
    store.header.setPadding(16, 0, 16, 0);
    store.header.adaptWidthToChildren = true;
    store.header.adaptHeightToChildren = true;

    // Body
    store.body = this.createStackPanel();
    store.body.setPadding(16, 0, 16, 0);
    store.body.width = store.stackPanel.width;
    store.text = this.createText({ text: options.body });
    store.text.width = store.stackPanel.width;
    store.body.addControl(store.text);
    store.body.adaptWidthToChildren = true;
    store.body.adaptHeightToChildren = true;

    // Footnote
    if (options.footnote) {
      store.footnote = this.createText({ text: options.footnote, fontSize: 12, color: GREY_300 });
      store.footnote.setPadding(12, 0, 0, 0);
      store.footnote.width = store.stackPanel.width;
      store.body.addControl(store.footnote);
      store.body.adaptWidthToChildren = true;
      store.body.adaptHeightToChildren = true;
    }

    // Footer
    store.footer = this.createContainer({ width: 416 });
    store.accept = this.createButton({ text: options.acceptText, color: WHITE, background: PRIMARY_500, cornerRadius: 3, thickness: 1 });
    store.accept.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    store.accept.pointerEnterAnimation = () => {
      store.accept.background = PRIMARY_400;
    };
    store.accept.pointerOutAnimation = () => {
      store.accept.background = PRIMARY_500;
    };
    this.assignClick(store.accept, () => {
      options.acceptAction();
      options.embedded && this.clearSubConfirmation();
    });
    store.cancel = this.createButton({
      text: this.viewManager.appService.translate('shared.actions.cancel').toUpperCase(),
      color: PRIMARY_500,
      background: TRANSPARENT,
      cornerRadius: 3,
      fontSize: 20,
      thickness: 1,
    });
    store.cancel.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    store.cancel.pointerEnterAnimation = () => {
      store.cancel.color = PRIMARY_400;
    };
    store.cancel.pointerOutAnimation = () => {
      store.cancel.color = PRIMARY_500;
    };
    this.assignClick(store.cancel, () => {
      if (options.cancelAction) {
        options.cancelAction();
        options.embedded && this.clearSubConfirmation();
        return;
      }
      options.embedded ? this.clearSubConfirmation() : this.dispose();
    });
    store.footer.setPadding(16, 0, 16, 0);
    store.footer.addControl(store.accept);
    store.footer.addControl(store.cancel);
    store.footer.adaptHeightToChildren = true;

    // Size
    store.stackPanel.addControl(store.header);
    store.stackPanel.addControl(store.body);
    store.stackPanel.addControl(store.footer);
    store.container.adaptHeightToChildren = true;

    if (options.embedded) {
      this.debounceClick();
      store.overlay.addControl(store.container);
      targetStore.container.addControl(store.overlay);
    }
  }

  constructCommentModal(store: { [key: string]: any }) {
    // Container
    store.container.widthInPixels = 800;
    store.container.background = GREY_700;

    store.stackPanel = this.createStackPanel({ width: '100%' });
    store.stackPanel.fontFamily = 'paybooc';
    store.container.addControl(store.stackPanel);

    // Topbar
    this.constructTopbar(store, store.stackPanel, this.viewManager.appService.translate('oxr.creatingSpace.guestBook.guestBook'), () => {
      Object.entries(store).forEach(([key, element]) => {
        element.dispose();
        store[key] = undefined;
        delete store[key];
      });
    });

    // Body
    store.body = this.createStackPanel({ width: '100%' });
    store.body.setPadding(30, 86, 60, 86);
    store.body.spacing = 20;

    store.bodyTitle = this.createText({
      text: this.viewManager.appService.translate('oxr.creatingSpace.guestBook.guestBook'),
      fontSize: 34,
    });
    store.body.addControl(store.bodyTitle);
    store.bodyTitle.adaptWidthToChildren = true;

    store.emojiBox = this.createStackPanel({ vertical: false });
    const checkButtonState = () => {
      Object.values(InteractionEmotion).forEach((emotion) => {
        if (!emotion) {
          return;
        }
        store[`${emotion}Button`].image.widthInPixels = emotion === this._inputData.selectedEmoji ? 200 : 135;
        store[`${emotion}Button`].image.heightInPixels = emotion === this._inputData.selectedEmoji ? 200 : 135;
      });
    };
    Object.values(InteractionEmotion).forEach((emotion) => {
      if (!emotion) {
        return;
      }
      store[`${emotion}Button`] = this.createButton({ image: `/media/${emotion}-simple.png`, width: 135, height: 135 });
      store[`${emotion}Button`].pointerEnterAnimation = () => {
        store[`${emotion}Button`].image.widthInPixels = 200;
        store[`${emotion}Button`].image.heightInPixels = 200;
      };
      store[`${emotion}Button`].pointerOutAnimation = () => {
        if (this._inputData.selectedEmoji === emotion) {
          return;
        }
        store[`${emotion}Button`].image.widthInPixels = 135;
        store[`${emotion}Button`].image.heightInPixels = 135;
      };
      this.assignClick(store[`${emotion}Button`], () => {
        this._inputData.selectedEmoji = emotion;
        checkFormState();
        this.scene.onAfterRenderObservable.addOnce(() => {
          checkButtonState();
        });
      });
      store.emojiBox.addControl(store[`${emotion}Button`]);
    });
    store.emojiBox.adaptWidthToChildren = true;
    store.emojiBox.adaptHeightToChildren = true;
    store.body.addControl(store.emojiBox);

    store.commentContainer = this.createContainer({ width: '100%', height: 250, background: GREY_600 });
    store.commentContainer.setPadding(24);
    store.commentContainer.descendantsOnlyPadding = true;

    const checkFormState = () => {
      store.save.isEnabled = !!this._inputData[`${PopupType.GuestBook}Comment`]?.length && !!this._inputData.selectedEmoji;
    };
    store.textarea = this.createTextarea({
      width: '100%',
      fontSize: 16,
      color: WHITE,
      background: TRANSPARENT,
      placeholder: this.viewManager.appService.translate('oxr.creatingSpace.guestBook.placeholder'),
      placeholderColor: GREY_400,
    });
    store.textarea.focusedBackground = TRANSPARENT;
    store.textarea.margin = '10px';
    this.addObserver(store.textarea.onTextChangedObservable, () => {
      if (store.textarea.text > MAX_COMMENT_LENGTH) {
        store.textarea.text = this._inputData[`${PopupType.GuestBook}Comment`];
      }
      this._inputData[`${PopupType.GuestBook}Comment`] = store.textarea.text;
      store.commentCounter.text = `${store.textarea.text.length}/${MAX_COMMENT_LENGTH}`;
      store.textarea.width = '100%';
      checkFormState();
    });
    store.commentContainer.addControl(store.textarea);

    store.commentCounter = this.createText({
      text: `${store.textarea.text.length}/${MAX_COMMENT_LENGTH}`,
      width: '100%',
      color: GREY_400,
      fontSize: 16,
    });
    store.commentCounter.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    store.commentCounter.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    store.commentCounter.children[0].horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    store.commentCounter.children[0].textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    store.commentCounter.adaptWidthToChildren = true;
    store.commentContainer.addControl(store.commentCounter);

    store.body.addControl(store.commentContainer);

    // Footer
    store.footer = this.createContainer();
    store.footer.setPadding(0, 0, 60, 0);
    store.save = this.createButton({
      text: this.viewManager.appService.translate('oxr.creatingSpace.guestBook.leave').toUpperCase(),
      color: WHITE,
      background: PRIMARY_500,
      cornerRadius: 3,
    });
    this.assignClick(store.save, () => {
      this.viewManager.appService
        .addGuestbookEntry(this._selectedElement.id, {
          Comment: this._inputData[`${PopupType.GuestBook}Comment`],
          Emoji: this._inputData.selectedEmoji,
        })
        .finally(() => {
          const position = this.host.position.clone();
          this.dispose();
          this.showPopup(this._selectedElement, position);
        });
    });
    store.save.pointerEnterAnimation = () => {
      if (!store.save.isEnabled) {
        return;
      }
      store.save.background = PRIMARY_400;
    };
    store.save.pointerOutAnimation = () => {
      if (!store.save.isEnabled) {
        return;
      }
      store.save.background = PRIMARY_500;
    };
    store.footer.addControl(store.save);
    store.footer.adaptWidthToChildren = true;
    store.footer.adaptHeightToChildren = true;

    // Size
    store.stackPanel.addControl(store.body);
    store.stackPanel.addControl(store.footer);
    store.container.adaptHeightToChildren = true;
    checkFormState();
  }

  createButton(
    options: {
      text?: string;
      image?: string;
      width?: number | string;
      height?: number | string;
      color?: string;
      background?: string;
      fontSize?: number;
      cornerRadius?: number;
      thickness?: number;
    } = {},
  ) {
    let button: Button;
    if (options.image && options.text) {
      button = Button.CreateImageButton(`GuiButton-${uuid()}`, options.text, options.image);
    } else if (options.image) {
      button = Button.CreateImageOnlyButton(`GuiButton-${uuid()}`, options.image);
    } else {
      button = Button.CreateSimpleButton(`GuiButton-${uuid()}`, options.text || '');
    }
    if (button.image) {
      button.image.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    }
    setWidth(button, options.width ?? BUTTON_WIDTH);
    setHeight(button, options.height ?? BUTTON_HEIGHT);
    button.color = options.color ?? button.color;
    button.background = options.background ?? button.background;
    button.fontSize = options.fontSize ?? FONT_SIZE;
    button.thickness = options.thickness ?? 0;
    button.cornerRadius = options.cornerRadius ?? 0;
    button.disabledColor = GREY_400;
    this.addObserver(button.onEnabledStateChangedObservable, (value) => {
      button.color = value ? options.color ?? button.color : GREY_300;
    });
    return button;
  }

  createCheckbox(
    options: {
      label?: string;
      width?: number | string;
      height?: number | string;
      color?: string;
      background?: string;
      fontSize?: number;
      isChecked?: boolean;
    } = {},
  ) {
    const stackPanel = this.createStackPanel({ vertical: false, width: '100%' });
    stackPanel.heightInPixels = 20;

    const checkbox = new Checkbox(`GuiCheckbox-${uuid()}`);
    checkbox.widthInPixels = 20;
    checkbox.heightInPixels = 20;
    checkbox.color = options.color ?? checkbox.color;
    checkbox.background = options.background ?? checkbox.background;
    checkbox.fontSize = options.fontSize ?? FONT_SIZE;
    checkbox.isChecked = options.isChecked ?? false;
    checkbox.thickness = 0;

    const label = this.createText({ text: options.label, color: options.color ?? WHITE, fontSize: options.fontSize ?? FONT_SIZE });
    stackPanel.spacing = 8;
    stackPanel.addControl(checkbox);
    stackPanel.addControl(label);
    stackPanel.adaptWidthToChildren = true;
    return stackPanel;
  }

  createContainer(options: { width?: number | string; height?: number | string; color?: string; background?: string } = {}) {
    const container = new Container(`GuiContainer-${uuid()}`);
    setWidth(container, options.width ?? container.width);
    setHeight(container, options.height ?? container.height);
    container.background = options.background ?? container.background;
    return container;
  }

  createImage(options: { url?: string; width?: number | string; height?: number | string } = {}) {
    const image = new Image(`GuiImage-${uuid()}`, options.url ?? '');
    setWidth(image, options.width ?? image.width);
    setHeight(image, options.height ?? image.height);
    return image;
  }

  createInputText(
    options: {
      width?: number | string;
      height?: number | string;
      color?: string;
      background?: string;
      fontSize?: number;
      placeholder?: string;
      placeholderColor?: string;
      thickness?: number;
    } = {},
  ) {
    const input = new InputText(`GuiInputText-${uuid()}`);
    setWidth(input, options.width ?? input.width);
    setHeight(input, options.height ?? input.height);
    input.color = options.color ?? input.color;
    input.background = options.background ?? input.background;
    input.fontSize = options.fontSize ?? FONT_SIZE;
    input.placeholderText = options.placeholder ?? '';
    if (options.placeholderColor) {
      input.placeholderColor = options.placeholderColor;
    } else {
      const color = Color4.FromHexString(input.color);
      color.a *= 0.5;
      input.placeholderColor = color.toHexString();
    }
    input.focusedBackground = input.background;
    input.thickness = options.thickness ?? 0;
    this.bindKeyboard(input);
    return input;
  }

  createTextarea(
    options: {
      width?: number | string;
      height?: number | string;
      color?: string;
      background?: string;
      fontSize?: number;
      placeholder?: string;
      placeholderColor?: string;
      thickness?: number;
    } = {},
  ) {
    const input = new InputTextArea(`GuiTextArea-${uuid()}`);
    setWidth(input, options.width ?? input.width);
    options.height && setHeight(input, options.height);
    input.color = options.color ?? input.color;
    input.background = options.background ?? input.background;
    input.fontSize = options.fontSize ?? FONT_SIZE;
    input.placeholderText = options.placeholder ?? '';
    if (options.placeholderColor) {
      input.placeholderColor = options.placeholderColor;
    } else {
      const color = Color4.FromHexString(input.color);
      color.a *= 0.5;
      input.placeholderColor = color.toHexString();
    }
    input.focusedBackground = input.background;
    input.thickness = options.thickness ?? 0;
    this.bindKeyboard(input);
    return input;
  }

  createRectangle(
    options: { width?: number | string; height?: number | string; color?: string; background?: string; cornerRadius?: number } = {},
  ) {
    const rectangle = new Rectangle(`GuiRectangle-${uuid()}`);
    setWidth(rectangle, options.width ?? rectangle.width);
    setHeight(rectangle, options.height ?? rectangle.height);
    rectangle.color = options.color ?? rectangle.color;
    rectangle.background = options.background ?? rectangle.background;
    rectangle.cornerRadius = options.cornerRadius ?? 0;
    rectangle.thickness = 0;
    return rectangle;
  }

  createScrollViewer(options: { width?: number | string; height?: number | string; color?: string; background?: string } = {}) {
    const scrollViewer = new ScrollViewer(`GuiScrollViewer-${uuid()}`);
    setWidth(scrollViewer, options.width ?? scrollViewer.width);
    setHeight(scrollViewer, options.height ?? scrollViewer.height);
    scrollViewer.color = options.color ?? TRANSPARENT;
    scrollViewer.background = options.background ?? scrollViewer.background;
    scrollViewer.barColor = GREY_300;
    scrollViewer.scrollBackground = BLACK;
    scrollViewer.barSize = 12;
    scrollViewer.thickness = 0;
    if (scrollViewer.horizontalBar) {
      scrollViewer.horizontalBar.notRenderable = true;
      scrollViewer.horizontalBar.parent!.notRenderable = true;
    }
    return scrollViewer;
  }

  createStackPanel(options: { name?: string; width?: number | string; fontSize?: string; background?: string; vertical?: boolean } = {}) {
    const stackPanel = new StackPanel(options.name ?? `GuiStackPanel-${uuid()}`);
    stackPanel.isVertical = options.vertical ?? true;
    stackPanel.fontSize = `${options.fontSize ?? FONT_SIZE}px`;
    setWidth(stackPanel, options.width ?? stackPanel.width);
    stackPanel.background = options.background ?? stackPanel.background;
    return stackPanel;
  }

  createText(options: { text?: string; width?: number | string; color?: string; fontSize?: number; alignment?: number } = {}) {
    const stackPanel = this.createStackPanel({ name: `GuiText-${uuid()}`, fontSize: `${options.fontSize ?? FONT_SIZE}px` });
    const context = this.advancedTexture.getContext();
    context.font = `${options.fontSize ?? FONT_SIZE}px paybooc`;
    let maxWidth = 0;
    options.text
      ?.replaceAll('<br />', '\n')
      .split('\n')
      .map((line) => line.trim())
      .forEach((line) => {
        if (!line) {
          return;
        }
        const textBlock = new TextBlock(`GuiTextBlock-${uuid()}`, line);
        textBlock.height = `${(options.fontSize ?? FONT_SIZE) * 1.5}px`;
        textBlock.color = options.color ?? WHITE;
        textBlock.textHorizontalAlignment = options.alignment ?? textBlock.textHorizontalAlignment;
        stackPanel.addControl(textBlock);
        // @ts-expect-error
        maxWidth = Math.max(context.measureText(line).width - parseFloat(context.letterSpacing.replace('px', '')), maxWidth);
      });
    setWidth(stackPanel, options.width ?? maxWidth);
    return stackPanel;
  }

  addObserver(observable: Observable<any>, callback: (eventState: any) => void, name = uuid()) {
    this._observation[name] = { observable, observer: observable.add(callback) };
    return this._observation[name];
  }

  assignClick(element: Control, callback: () => void) {
    let upObserver: Observer<Vector2WithInfo>;
    this.addObserver(element.onPointerDownObservable, () => {
      if (this._clickDisabled) {
        return;
      }
      upObserver = element.onPointerUpObservable.addOnce(() => {
        callback();
        this.debounceClick();
      });
      element.onPointerOutObservable.addOnce(() => {
        upObserver && element.onPointerUpObservable.remove(upObserver);
      });
    });
  }

  bindKeyboard(input: InputText | InputTextArea) {
    input.onFocusObservable.makeObserverBottomPriority(
      this.addObserver(input.onFocusObservable, () => {
        this.keyboard.connect(input);
        this.setKeyboardPosition();
      })!.observer as Observer<Control>,
    );
    input.onBlurObservable.makeObserverTopPriority(
      this.addObserver(input.onBlurObservable, () => {
        this.keyboard.disconnect();
        this.clearKeyboard();
      })!.observer as Observer<Control>,
    );
  }

  setKeyboardPosition() {
    this.keyboardHost.setEnabled(true);
    this._keyboardOffset.copyFrom(
      this.viewManager.vrCamera.getDirection(Vector3.RightHandedBackwardReadOnly).scale(1.5).subtractFromFloats(0, 0.8, 0),
    );
    this.addObserver(
      this.scene.onBeforeRenderObservable,
      () => {
        this.keyboardHost.position.copyFrom(this.viewManager.vrCamera.globalPosition.add(this._keyboardOffset));
      },
      'keyboardBeforeRender',
    );
    this.scene.onAfterRenderObservable.addOnce(() => {
      this.keyboardHost.lookAt(this.viewManager.vrCamera.globalPosition);
    });
  }

  getPageAnimationKeys(initial: number) {
    return [
      { frame: 0, value: initial },
      { frame: FRAME_RATE, value: -this._currentPage * 1200 },
    ];
  }

  parseHTMLNode(store: { [key: string]: any }, node: ChildNode, parent: any, width = 1166) {
    let element: Button | Container | Image | StackPanel | TextBlock | undefined = undefined;
    switch (node.nodeName.toLowerCase()) {
      case 'a':
        const childImage = (node as HTMLLinkElement).querySelector('img');
        if (childImage) {
          element = Button.CreateImageOnlyButton(`GuiNodeButton-${uuid()}`, childImage.src);
          (element as Button).image?.onImageLoadedObservable.addOnce((image) => {
            (element as Button).heightInPixels =
              ((element as Button).widthInPixels * (image as Image).imageHeight) / (image as Image).imageWidth || 1024;
          });
        } else if (node.textContent) {
          element = this.createButton({ text: ' ', color: '#06C' });
          if ((element as Button).textBlock) {
            (element as Button).textBlock!.underline = true;
          }
        }
        if (element) {
          element.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
          let link = (node as HTMLLinkElement)?.getAttribute('href');
          link &&
            this.assignClick(element, () => {
              this.constructConfirmationModal(store, {
                embedded: true,
                header: this.viewManager.appService.translate('guideModal.title'),
                body: this.viewManager.appService.translate('shared.confirmation.goToURL', {
                  url: link,
                }),
                footnote: this.viewManager.appService.translate('shared.confirmation.backgroundLink', {
                  rootUrl: environment.redirectURL,
                }),
                acceptText: this.viewManager.appService.translate('shared.actions.open').toUpperCase(),
                acceptAction: () => {
                  if (!link) {
                    return;
                  }
                  if (!link.startsWith('http://') && !link.startsWith('https://')) {
                    link = 'https://' + link;
                  }
                  this.viewManager.appService.windowOpenWithPlatform(link, true);
                },
              });
            });
        }
        break;
      case 'br':
        element = this.createText({ text: '_' });
        element.alpha = 0;
        element.fontSize = parent.fontSize;
        element.fontWeight = parent.fontWeight;
        element.fontFamily = parent.fontFamily;
        break;
      case 'h1':
        element = this.createStackPanel({ width });
        (element as StackPanel).fontSize = 28;
        (element as StackPanel).fontWeight = '600';
        element.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        element.adaptWidthToChildren = true;
        element.adaptHeightToChildren = true;
        break;
      case 'h2':
        element = this.createStackPanel({ width });
        (element as StackPanel).fontSize = 21;
        (element as StackPanel).fontWeight = '600';
        element.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        element.adaptWidthToChildren = true;
        element.adaptHeightToChildren = true;
        break;
      case 'h3':
        element = this.createStackPanel({ width });
        (element as StackPanel).fontSize = 16.4;
        (element as StackPanel).fontWeight = '600';
        element.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        element.adaptWidthToChildren = true;
        element.adaptHeightToChildren = true;
        break;
      case 'blockquote':
      case 'li':
      case 'p':
        element = this.createStackPanel({ vertical: false, width });
        (element as StackPanel).fontSize = 14;
        (element as StackPanel).fontWeight = '400';
        element.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        element.adaptWidthToChildren = true;
        element.adaptHeightToChildren = true;
        break;
      case 'img':
        if (node.parentNode && IMG_IGNORE_NODES.includes(node.parentNode.nodeName.toLowerCase())) {
          break;
        }
        element = this.createImage({ url: (node as HTMLImageElement).src, width: 50, height: 50 });
        element.onImageLoadedObservable.addOnce((e) => {
          (element as Image).widthInPixels = Math.min(e.imageWidth, width);
          (element as Image).heightInPixels = Math.min(e.imageHeight, width);
        });
        break;
      case 'span':
        element = this.createContainer();
        element.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        element.adaptWidthToChildren = true;
        element.adaptHeightToChildren = true;

        element.fontSize = parent.fontSize;
        element.fontWeight = parent.fontWeight;
        element.fontFamily = parent.fontFamily;
        break;
      case '#text':
        if (!node.textContent) {
          break;
        }
        const parentNode = node.parentNode;
        const parentType = parentNode?.nodeName.toLowerCase();

        element = new TextBlock(`GuiNodeText-${uuid()}`, node.textContent);
        element.textWrapping = TextWrapping.WordWrap;
        element.color = BLACK;
        element.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
        element.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;

        element.fontSize = parent.fontSize;
        element.fontWeight = parent.fontWeight;
        element.fontFamily = parent.fontFamily;

        let padding = 0;
        if (parentNode) {
          if (parentType === 'strong') {
            element.fontWeight = '600';
          } else if (parentType === 'em') {
            element.fontStyle = 'italic';
          } else if (parentType === 's') {
            element.lineThrough = true;
          } else if (parentType === 'u') {
            element.underline = true;
          } else if (parentType === 'li') {
            if (node.parentElement?.parentElement) {
              if (node.parentElement.parentElement.nodeName.toLowerCase() === 'ol') {
                const children: HTMLLIElement[] = [];
                node.parentElement.parentElement.querySelectorAll('li').forEach((li) => {
                  children.push(li);
                });
                const i = children.indexOf(node.parentElement as HTMLLIElement) + 1;
                element.text = `${i}. ${element.text}`;
              } else if (node.parentElement.parentElement.nodeName.toLowerCase() === 'ul') {
                element.text = `• ${element.text}`;
              }
              padding = 21;
            }
          } else if (parentType === 'blockquote') {
            const bar = this.createRectangle({ width: 4, height: '100%', background: GREY_300 });
            parent.setPadding(0, 0, 5, 0);
            parent.addControl(bar);
            padding = 16;
          }
          if (node.parentElement?.classList.contains('ql-align-center')) {
            element.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
          } else if (node.parentElement?.classList.contains('ql-align-right')) {
            element.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
          }
        }
        element.paddingLeftInPixels = padding;
        const ctx = this.advancedTexture.getContext();
        ctx.font = `${element.fontWeight} ${element.fontSize} ${element.fontFamily || 'Arial'} ${element.fontStyle || ''}`.trim();
        element.widthInPixels = Math.min(ctx.measureText(element.text).width + padding + 2, width - 40);
        element.resizeToFit = true;
        if (parentType === 'a') {
          parent.width = element.width;
          element.color = parent.color;
          element.underline = true;
        }
        break;
      default:
        break;
    }
    node.childNodes.forEach((child) => {
      this.parseHTMLNode(store, child, element || parent, width);
    });
    if (!element) {
      return;
    }
    const parentStyle = ((node as HTMLElement).parentNode as HTMLElement)?.style;
    if (parentStyle) {
      if (parentStyle.color) {
        element.color = parentStyle.color;
      }
      if (parentStyle.backgroundColor && !(element instanceof Image) && !(element instanceof TextBlock)) {
        element.background = parentStyle.backgroundColor;
      }
    }
    const elementStyle = (node as HTMLElement)?.style;
    if (elementStyle) {
      if (elementStyle.color) {
        element.color = elementStyle.color;
      }
      if (elementStyle.backgroundColor && !(element instanceof Image) && !(element instanceof TextBlock)) {
        element.background = elementStyle.backgroundColor;
      }
    }
    parent.addControl(element);
    store[`Node${node.nodeName}-${uuid()}`] = element;
  }

  dispose() {
    this.clear();
    this._advancedTexture?.dispose();
    this._advancedTexture = null;
    this._host?.dispose();
    this._host = null;

    this._modalAdvancedTexture?.dispose();
    this._modalAdvancedTexture = null;
    this._modalHost?.dispose();
    this._modalHost = null;

    this._keyboardAdvancedTexture?.dispose();
    this._keyboardAdvancedTexture = null;
    this._keyboard?.dispose();
    this._keyboard = null;
    this._keyboardHost?.dispose();
    this._keyboardHost = null;
  }
}

function setWidth(element: Control, value: string | number) {
  if (typeof value === 'number') {
    element.widthInPixels = value;
  } else if (typeof value === 'string') {
    element.width = value;
  }
}

function setHeight(element: Control, value: string | number) {
  if (typeof value === 'number') {
    element.heightInPixels = value;
  } else if (typeof value === 'string') {
    element.height = value;
  }
}

function getFormattedDate(date: string) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }
  return [d.getFullYear(), month, day].join('.');
}

function getYouTubeThumbnail(url: string) {
  if (!url) {
    return '';
  }
  const match = url.match(YOUTUBE_REGEXP);
  return match ? `https://ctwfopstia.cloudimg.io/https://img.youtube.com/vi/${match.at(-1)}/0.jpg?width=1120` : '';
}

function wordSplittingFunction(line: string) {
  const words = line.split(' ');
  const result: string[] = [];
  let currentLine = '';
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (currentLine.length + word.length < LINE_LENGTH) {
      currentLine += (currentLine.length > 0 ? ' ' : '') + word;
    } else {
      const splitWord = splitLongWord(word);
      if (currentLine.length > 0) {
        result.push(currentLine);
      }
      result.push(...splitWord);
      currentLine = '';
    }
  }
  if (currentLine.length > 0) {
    result.push(currentLine);
  }
  return result;
}

function splitLongWord(word: string): string[] {
  const result: string[] = [];
  let currentLine = '';
  for (let i = 0; i < word.length; i++) {
    const char = word[i];
    if (currentLine.length + char.length < LINE_LENGTH) {
      currentLine += char;
    } else {
      result.push(currentLine);
      currentLine = char;
    }
  }
  if (currentLine.length > 0) {
    result.push(currentLine);
  }
  return result;
}
