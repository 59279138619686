import { HttpBackend, HttpClient, HttpEventType } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WINDOW } from '@ng-web-apis/common';
import { inflate } from 'pako';
import * as uuid from 'uuid';
import { interval, lastValueFrom, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { environment } from '@/app/src/environments/environment';
import { GLTF2Export, IExportOptions } from '@/data/src/lib/babylon';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { InformationComponent } from '@/ui/src/lib/modal/information/information.component';
import { ResultCallback } from '../models/callbacks/callbacks';
import { Access, FileStorageType } from '../enums/access';
import { FileReference } from '../models/data/file-reference';
import { ModelType } from '../models/data/base';
import { BaseApiService } from './base-api.service';
import { AccountService } from './account.service';
import { IFileItem } from '../models/interfaces/ifile-item';
import { FileType, MediaType } from '../enums/file-type';
import { ViewManager } from '../view-manager';

export enum FilePermission {
  Read = 'read',
  Write = 'write',
  Delete = 'delete',
}

/**
 * Tracks and manages the state of file reference models, creates shared access signature for file access, uploads and downloads data and tracks progress
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class FileService extends BaseApiService<FileReference> {
  private noAuthHttp: HttpClient;
  private supportedFiles: Map<string, string[]> = new Map<string, string[]>([['Image', ['.jpeg', '.jpg', '.gif', '.png']]]);
  private compressionURLPrefix = 'https://ctwfopstia.cloudimg.io/';

  constructor(
    public http: HttpClient,
    accountService: AccountService,
    private _handler: HttpBackend,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _ngZone: NgZone,
    @Inject(WINDOW) private readonly _window: Window,
  ) {
    super(http, accountService, ModelType.FileReference);
    this.noAuthHttp = new HttpClient(_handler);
  }

  /**
   * Gets a shared access signature for a file which enables access for private blobs
   * @param filename the name of the blob
   * @param permission Access permission (Read, Write, Delete)
   * @param container name of the container the blob is in
   * @returns the shared access signature
   */
  public async getSharedAccessSignature(
    filename: string,
    permission: FilePermission = FilePermission.Read,
    container = 'assets',
  ): Promise<string> {
    if (filename.startsWith('http')) {
      return filename;
    } else if (!this.accountService.trialOnly) {
      return await lastValueFrom(
        this.http
          .get(
            `${
              environment[this.accountService.region].functionApiUrl
            }/GetSasUrl?container=${container}&blobName=${filename}&permission=${permission}`,
            { headers: { 'Content-Type': 'text/plain' }, responseType: 'text' },
          )
          .pipe(
            map((res) => {
              return res.toString();
            }),
          ),
      ).catch((res) => {
        return '';
      });
    } else {
      return '';
    }
  }

  /**
   * Prepends the root url for the input filename and container which allows access to public blobs only
   * @param filename name of the file
   * @param container blob container
   * @returns full file path
   */
  public async getFilePath(filename: string, container = 'assets'): Promise<string> {
    if (filename.startsWith('http')) {
      return filename;
    } else if (!this.accountService.trialOnly) {
      return `${environment[this.accountService.region].fileStorageUrl}/${container ? container + '/' : ''}${filename}`;
    } else {
      return '';
    }
  }

  /**
   * Downloads a file from the input url and tracks the progress
   * @param filePath Full path of file to download
   * @param downloadProgress progress callback, progress ranges from 0 to 100
   * @returns blob
   */
  public async downloadFile(filePath: string, downloadProgress?: ResultCallback<number>) {
    return new Promise<Blob | undefined>((resolve) => {
      this.noAuthHttp
        .get(filePath, {
          reportProgress: true,
          responseType: 'blob',
          observe: 'events',
        })
        .pipe(
          catchError(() => {
            return of(undefined);
          }),
        )
        .subscribe((res) => {
          if (res) {
            if (downloadProgress && res.type == HttpEventType.DownloadProgress) {
              downloadProgress(undefined, res.total ? (res.loaded / res.total) * 100 : res.loaded);
            } else if (res.type == HttpEventType.Response) {
              resolve(res.body as Blob);
            } else if (res.type !== HttpEventType.Sent && res.type !== HttpEventType.ResponseHeader) {
              resolve(undefined);
            }
          } else {
            resolve(undefined);
          }
        });
    });
  }

  public checkFormat(file: Blob, fileType: FileType): boolean {
    const extension = (file as any).name ? '.' + (file as any).name.split('.').pop() : this.getFileExtenstion(file.type);

    if (!this.supportedFiles.get(fileType)?.includes(extension)) {
      let formats = '';
      this.supportedFiles.get(fileType)?.forEach((e) => {
        formats += e + ' / ';
      });
      formats = formats.substring(0, formats.length - 3);
      const modal = this._modalService.open(InformationComponent);
      if (modal) {
        modal.instance.message = this._translateService.instant('shared.information.fileUploadGuide', { formats: formats });

        this._ngZone.runOutsideAngular(() => {
          interval(3000)
            .pipe(take(1), untilDestroyed(this))
            .subscribe(() => {
              if (modal) {
                modal.instance.close();
                this._ngZone.run(() => {});
              }
            });
        });
      }
      return false;
    } else return true;
  }

  /**
   * Uploads a file and tracks the upload progress
   * @param file blob to upload
   * @param access sets the access of the file after it has been uploaded
   * @param uploadProgress upload progress callback
   * @param filename file name to give the uploaded blob, if undefined creates a globally unique file name
   * @param createFileRef set to true to create a file reference to track the file
   * @param typeRefType sets the file type if the createFileRef is set to true, if undefined determines the file type from the file mime type
   */
  public async uploadFile(
    file: Blob,
    fileStorageType: FileStorageType,
    uploadProgress?: ResultCallback<number>,
    filename?: string,
    createFileRef = true,
    refFileType?: FileType,
    sceneId?: string, // if uploading to scene
    access: Access = Access.Public,
    refMediaType?: MediaType,
  ) {
    const extension = (file as any).name ? '.' + (file as any).name.split('.').pop() : this.getFileExtenstion(file.type);

    const name = filename ?? (file as any).name;

    const blobName = uuid.v4() + extension.toLowerCase();
    const reader = new FileReader();
    const sas = await this.getSharedAccessSignature(blobName, FilePermission.Write, fileStorageType);
    if (sas) {
      await new Promise<void>((resolve) => {
        reader.onload = () => {
          const progressSubscription = this.noAuthHttp
            .put<string>(sas, reader.result, {
              headers: {
                'Content-Type': file.type,
                'x-ms-blob-type': 'BlockBlob',
              },
              observe: 'events',
              reportProgress: true,
            })
            .subscribe((event) => {
              switch (event.type) {
                case HttpEventType.Sent:
                  break;
                case HttpEventType.ResponseHeader:
                  break;
                case HttpEventType.UploadProgress:
                  const progress = Math.round((event.loaded / (event.total ? event.total : 1)) * 100);

                  if (uploadProgress) uploadProgress('', progress);
                  break;
                case HttpEventType.Response:
                  if (uploadProgress) uploadProgress('', 100);

                  progressSubscription?.unsubscribe();
                  resolve();
              }
            });
        };

        reader.onerror = () => {};
        reader.readAsArrayBuffer(file);
      });
    }
    const url =
      fileStorageType == FileStorageType.Public
        ? environment[this.accountService.region].fileStorageUrl + '/public/' + blobName
        : 'assets/' + blobName;
    if (createFileRef) {
      const thumbnail = await this.getThumbnail(file);
      let thumbnailUrl = '';
      if (thumbnail) {
        const { url: resultUrl } = await this.uploadFile(thumbnail, FileStorageType.Public, undefined, undefined, false);
        thumbnailUrl = resultUrl;
      }
      const fileRefId = await this.createFileReference(name, url, access, file.type, file.size, thumbnailUrl, refFileType, refMediaType);
      if (sceneId) {
        await this.postSceneFile([{ sceneId, fileId: fileRefId }]);
      }

      return { Id: fileRefId, name, url, access, type: file.type, size: file.size, thumbnailUrl, refFileType, refMediaType };
    }
    return { url };
  }

  /**
   * Post the information about the scene and file
   * @param sceneId Id of the scene
   * @param fileId Id of the FileReference
   * @returns null
   */
  async postSceneFile(body: [{ sceneId: string; fileId: string }]): Promise<null> {
    return await lastValueFrom(this.http.post<null>(`${environment[this.accountService.region].crudApiUrl}/SceneFile`, body));
  }

  /**
   * Gets a list of media details with given list of media file IDs
   * @param body Array of media file IDs
   * @returns List of media details
   */
  async getByIds(body: string[]): Promise<FileReference[]> {
    return await lastValueFrom(
      this.noAuthHttp.post<FileReference[]>(`${environment[this.accountService.region].crudApiUrl}/FileReference/GetByIds`, body),
    );
  }

  /**
   * Resizes an image file so the max of the length or width does not excedd the max_size
   * @param file image file to resize
   * @param max_size max image dimension
   * @returns the resized image blob
   */
  async resizeImage(file: IFileItem | Blob, max_size = 1200) {
    return new Promise<Blob | null>((resolve) => {
      const image = new Image();
      image.onload = (imageEvent) => {
        // Resize the image
        let canvas = this._window.document.createElement('canvas'),
          width = image.width,
          height = image.height;
        if (width > height) {
          if (width > max_size) {
            height *= max_size / width;
            width = max_size;
          }
        } else {
          if (height > max_size) {
            width *= max_size / height;
            height = max_size;
          }
        }
        if (canvas) {
          canvas.width = width;
          canvas.height = height;
          canvas.getContext('2d')?.drawImage(image, 0, 0, width, height);
          canvas.toBlob((resizedImage) => {
            canvas.remove();
            resolve(resizedImage);
          }, 'image/png');
        }
      };
      if (file instanceof Blob) {
        image.src = URL.createObjectURL(file);
      } else {
        image.src = file.Value as string;
      }
    });
  }

  /**
   * Converts a blob to a base64 data url
   * @param blob image blob to convert
   * @returns base 64 string/data url
   */
  async blobToBase64(blob: Blob): Promise<IFileItem> {
    const reader = new FileReader();

    return new Promise<IFileItem>((resolve) => {
      reader.onload = () => {
        resolve({ Value: reader.result as string });
      };

      reader.onerror = () => {
        resolve({ Value: '' });
      };
      reader.readAsDataURL(blob);
    });
  }

  /**
   * Converts a base64 string to a blob
   * @param dataURL base 64 string
   * @param resizeIfImage set to true to resize an image to the default size
   * @returns the blob
   */
  async base64ToBlob(dataURL: IFileItem, resizeIfImage = true) {
    if (typeof dataURL.Value == 'string') {
      const BASE64_MARKER = ';base64,';
      const baseMarkerIndex = dataURL.Value.indexOf(BASE64_MARKER);
      if (baseMarkerIndex == -1) {
        const parts = dataURL.Value.split(',');
        const contentType = parts[0].split(':')[1];
        const raw = parts[1];

        return new Blob([raw], { type: contentType });
      }
      const firstPart = dataURL.Value.substr(0, baseMarkerIndex);
      const contentType = firstPart.split(':')[1];

      if (resizeIfImage && contentType.toLowerCase().startsWith('image')) {
        return await this.resizeImage(dataURL);
      }
      const raw = this._window.atob(dataURL.Value.substr(firstPart.length + BASE64_MARKER.length));
      const rawLength = raw.length;

      const uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], { type: contentType });
    } else {
      return dataURL.Value;
    }
  }

  /**
   * Uploads a file from a base 64 string/data url
   * @param file file to upload
   * @param access sets the access of the file after it has been uploaded
   * @param onLoad callback when the file hase uploaded
   * @param filename filename, create a globally unique filename if undefined
   * @returns the upload file path
   */
  public async uploadFileFromBase64(
    file: IFileItem,
    fileStorageType: FileStorageType,
    onLoad: ResultCallback<string>,
    filename?: string,
    access: Access = Access.Public,
  ) {
    filename = filename ?? uuid.v4();
    const sas = await this.getSharedAccessSignature(filename, FilePermission.Write, fileStorageType);
    if (sas) {
      const blob = await this.base64ToBlob(file, true);
      if (blob) {
        this.http
          .put<string>(sas, blob, {
            headers: {
              'Content-Type': blob.type,
              'x-ms-blob-type': 'BlockBlob',
            },
          })
          .subscribe((res) => {
            onLoad(undefined, res);
          });
        const url =
          fileStorageType == FileStorageType.Public
            ? environment[this.accountService.region].fileStorageUrl + '/public/' + filename
            : filename;

        const thumbnail = await this.getThumbnail(blob);
        let thumbnailUrl = '';
        if (thumbnail) {
          const { url: resultUrl } = await this.uploadFile(thumbnail, FileStorageType.Public, undefined, undefined, false);
          thumbnailUrl = resultUrl;
        }
        this.createFileReference(filename, url, access, blob.type, blob.size, thumbnailUrl);

        return url;
      }
    }
    return undefined;
  }

  /**
   * Exports the scene as a glb file and uploads it as a blob
   * @param scene Scene to upload
   * @param filename name of uploaded glb file, create a globally unique filename if undefined
   * @param uploadProgress upload progress
   * @param options export options
   */
  public async uploadScene(viewManager: ViewManager, filename?: string, uploadProgress?: ResultCallback<number>, options?: IExportOptions) {
    filename = filename ? filename : uuid.v4() + '.glb';
    const file = await GLTF2Export.GLBAsync(viewManager.scene, filename, options);

    return await this.uploadFile(
      file.glTFFiles[filename] as Blob,
      FileStorageType.Assets,
      uploadProgress,
      filename,
      false,
      undefined,
      undefined,
      Access.Private,
    );
  }

  /**
   * creates a file reference to track an uploaded file and assigns it to the active user
   * @param name name of file reference
   * @param url url of file to track/map
   * @param access file access, public or private
   * @param mimeType mime type of tracked file
   * @param length file size in bytes
   * @param thumbnail thumbnail of file for previewing
   * @param type file type used for filtering
   */
  private async createFileReference(
    name: string,
    url: string,
    access: Access,
    mimeType: string,
    length: number,
    thumbnail: string,
    filetype?: FileType,
    mediaType?: MediaType,
  ) {
    const date = new Date();
    const fileRef = new FileReference(
      uuid.v4(),
      name,
      url,
      access == Access.Public ? 'Public' : 'Private',
      filetype ?? this.getFileType(mimeType),
      mimeType,
      length,
      thumbnail,
      date.toISOString(),
      mediaType,
    );

    await this.post(fileRef);

    this.attach(fileRef);

    return fileRef.Id;
  }

  /**
   * Converts a FBX file to a glb file
   * @param files array of files including fbx, image textures, buffers and materials
   * @returns a glb blob
   */
  async convertFBX(files: File[]) {
    const formData = new FormData();
    for (const file of files) {
      formData.append(file.name, file, file.name);
    }
    const result = await this.http
      .post(`${environment[this.accountService.region].functionApiUrl}/ConvertFBX`, formData, { responseType: 'blob' })
      .toPromise();

    return result;
  }

  /**
   * Converts an obj file to a glb file
   * @param files array of files including obj, image textures and materials
   * @returns a glb blob
   */
  async convertObj(files: File[]) {
    const formData = new FormData();
    for (const file of files) {
      formData.append(file.name, file, file.name);
    }
    const result = await this.http
      .post(`${environment[this.accountService.region].functionApiUrl}/ConvertOBJ`, formData, { responseType: 'text' })
      .toPromise();

    return result;
  }

  /**
   * Gets the accepted mime types for the input file type
   * @param fileType File type
   * @returns string of mime types
   */
  acceptedMimeTypes(fileType: FileType | string) {
    switch (fileType) {
      case FileType.Image:
        return 'image/jpg, image/jpeg, image/png, image/gif';
      case FileType.Video:
        return 'video/mp4, application/x-mpegURL, video/MP2T, video/x-flv, video/3gpp, video/quicktime, video/x-msvideo, video/x-ms-wmv';
      case FileType.Pdf:
        return 'application/pdf, text/pdf, text/x-pdf, application/acrobat';
      case FileType.Geometry:
        return 'model/gltf-binary';
      case FileType.Icon:
        return 'image/jpg, image/jpeg, image/png, image/gif, image/x-icon';
      default:
        return '';
    }
  }

  /**
   * Converts a blob thumbnail to a base64 string
   * @param blob blob to convert
   * @returns base 64 string
   */
  async getThumbnailAsBase64(blob: Blob) {
    try {
      const data = await this.getThumbnail(blob);
      if (data) return this.blobToBase64(data);
      return {
        Value: '',
      };
    } catch (err) {
      throw Error('Error when getting thumbnail !');
    }
  }

  /**
   * Creates an image or video blob to a thumbnail
   * @param blob blob to create thumbnail for
   * @param type File type
   * @returns thumbnail blob
   */
  async getThumbnail(blob: Blob, type?: FileType) {
    try {
      type = type ?? this.getFileType(blob.type);
      switch (type) {
        case FileType.Video:
          return await this.createThumbnailFromVideo(blob);
        case FileType.Image:
          return await this.resizeImage(blob, 160);
        default:
          return undefined;
      }
    } catch (err) {
      throw Error('Error when generating thumbnail !');
    }
  }

  /**
   * Creates a thumbnail from the first frame of a video file
   * @param blob video blob
   * @param max_size max size of thumbnail
   * @returns video thumbnail
   */
  async createThumbnailFromVideo(videoSource: Blob | string, max_size = 160) {
    const video = this._window.document.createElement('video');
    const canvas = this._window.document.createElement('canvas');
    const result = await new Promise<Blob | null>((resolve, reject) => {
      video.addEventListener(
        'loadeddata',
        function () {
          loadFirstFrame();
        },
        false,
      );

      video.addEventListener(
        'seeked',
        async () => {
          if (video.width > video.height) {
            canvas.width = max_size;
            canvas.height = (max_size * video.videoHeight) / video.videoWidth;
          } else {
            canvas.height = max_size;
            canvas.width = (max_size * video.videoWidth) / video.videoHeight;
          }

          const context = canvas.getContext('2d');
          if (context) {
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            canvas.toBlob((thumbnail) => {
              resolve(thumbnail);
            }, 'image/png');
          } else {
            resolve(null);
          }
        },
        false,
      );

      video.addEventListener(
        'error',
        function () {
          reject(null);
        },
        false,
      );

      video.src = typeof videoSource === 'string' ? videoSource : URL.createObjectURL(videoSource);

      function loadFirstFrame() {
        if (!isNaN(video.duration)) {
          video.currentTime = 0;
        }
      }
    });
    video.remove();
    canvas.remove();
    return result;
  }

  /**
   * Gets the file type from the mime type
   * @param mimeType
   * @returns the file type
   */
  getFileType(mimeType: string) {
    switch (mimeType) {
      case 'image/x-icon':
        return FileType.Icon;
      case 'image/jpg':
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
        return FileType.Image;
      case 'video/mp4':
      case 'application/x-mpegURL':
      case 'video/MP2T':
      case 'video/x-flv':
      case 'video/3gpp':
      case 'video/quicktime':
      case 'video/x-msvideo':
      case 'video/x-ms-wmv':
        return FileType.Video;
      case 'application/pdf':
      case 'text/pdf':
      case 'text/x-pdf':
      case 'application/acrobat':
        return FileType.Pdf;
      case 'model/gltf-binary':
        return FileType.Geometry;
      default:
        return FileType.Undefined;
    }
  }

  /**
   * gets the default extension for the mime type
   * @param type mime type
   * @returns filename extension
   */
  getFileExtenstion(type: string) {
    switch (type) {
      case 'image/x-icon':
        return '.ico';
      case 'image/png':
        return '.png';
      case 'image/jpg':
        return '.jpg';
      case 'image/jpeg':
        return '.jpeg';
      case 'application/octet-stream':
        return '.glb';
      default:
        return '.glb';
    }
  }

  getCompressedImageUrl(url: string, options: { width?: number; height?: number } = {}) {
    if (!url) {
      return url;
    }
    let compressed = url.startsWith(this.compressionURLPrefix) ? url : `${this.compressionURLPrefix}${url}`;
    const parameters = compressed.match(/[?&]([^=#]+)=([^&#]*)/g)?.[0];
    if (parameters) {
      compressed = compressed.replace(parameters, '');
    }
    if (!options.width && !options.height) {
      return compressed;
    }
    return compressed + Object.keys(options).length
      ? `?${Object.entries(options)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')}`
      : '';
  }
}
