import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-assets-menu',
  templateUrl: './assets-menu.component.html',
  styleUrls: ['./assets-menu.component.scss'],
})
export class AssetsMenuComponent implements OnInit {
  @Input() set isEditor(value: boolean) {
    this.classEditor = value;
  }
  @Output() close = new EventEmitter<void>();

  @HostBinding('class.editor') classEditor = false;

  constructor() {}

  ngOnInit(): void {}

  onClose(): void {
    this.close.emit();
  }
}
