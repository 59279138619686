import { deepCopy } from '../../utils/data';

import {
  Mesh,
  MeshBuilder,
  Nullable,
  Observer,
  PlaneDragGizmo,
  Quaternion,
  Scene,
  StandardMaterial,
  Texture,
  Vector3,
} from '../../babylon';
import { ViewManager } from '..';
import { TPopup, TInteractableElement, ViewMode, TEffect, InteractionTrigger } from '../types';
import { FORWARD, GIZMO_SCALE, POPUP_COLOR, RED } from '../constants';
import { ViewManagerUtils } from '../../utils/view-manager-utils';
import { Effect } from '../auxiliaries/effect';

export class InteractableRenderable {
  public elementId: string;
  public helper: Mesh;
  public box: Mesh;
  public effect?: Effect;
  public boundingDimensions = Vector3.Zero();
  public boundingVectors: { min: Vector3; max: Vector3 };

  protected _element: TInteractableElement;

  private _badge: Nullable<Mesh>;
  private _badgeAnchor: Nullable<Mesh>;
  private _badgeGizmo: Nullable<PlaneDragGizmo>;
  private _badgeMaterial: Nullable<StandardMaterial>;
  private _badgeTexture: Nullable<Texture>;

  private _popupDescr?: TPopup;
  private _tempPopupDescr?: TPopup;
  private _effectDescr?: TEffect;
  private _tempEffectDescr?: TEffect;

  private collisionsEnabled: Nullable<boolean> = null;
  private shadowsEnabled: Nullable<boolean> = null;

  private observers: {
    badgeRender?: Nullable<Observer<Scene>>;
  } = {};

  constructor(
    element: TInteractableElement,
    public viewManager: ViewManager,
  ) {
    this.elementId = element.id;
    this.element = element;
    this.dispose = this.dispose.bind(this);
  }

  get element() {
    return this._element as TInteractableElement;
  }

  set element(payload) {
    this._element = deepCopy(payload) as TInteractableElement;
  }

  get badge() {
    if (!this._badge) {
      this._badge = MeshBuilder.CreatePlane(
        `Badge-${this.elementId}`,
        { size: 0.09, sideOrientation: Mesh.DOUBLESIDE },
        this.viewManager.badgeScene,
      );
      this._badge.scaling.scaleInPlace(GIZMO_SCALE * 0.25);
      this._badge.renderOverlay = true;
      this._badge.overlayAlpha = 1;
    }
    return this._badge;
  }

  get badgeMaterial() {
    if (!this._badgeMaterial) {
      this._badgeMaterial = new StandardMaterial(`BadgeMaterial-${this.elementId}`, this.viewManager.badgeScene);
      this._badgeMaterial.backFaceCulling = false;
      this._badgeMaterial.disableLighting = true;
    }
    return this._badgeMaterial;
  }

  update() {
    if (this.helper) {
      this._tempEffectDescr = deepCopy(this.element.parameters.effects?.[0]);
      if (this._tempEffectDescr) {
        if (this.effect) {
          ViewManagerUtils.PropsDiff(this._effectDescr ?? {}, this._tempEffectDescr).length &&
            this.effect.setup(this, this._tempEffectDescr);
        } else {
          this.effect = new Effect(this, this._tempEffectDescr);
        }
      } else {
        this.disposeEffect();
      }
      this._effectDescr = this._tempEffectDescr;

      this._tempPopupDescr = deepCopy(this.element.parameters.popups?.[0]);
      if (this._tempPopupDescr?.thumbnail) {
        if (this._tempPopupDescr.thumbnail === this._popupDescr?.thumbnail) {
          this.updateBadge();
        } else {
          this._badgeTexture?.dispose();
          this._badgeTexture = new Texture(
            this._tempPopupDescr.thumbnail,
            this.viewManager.badgeScene,
            undefined,
            undefined,
            Texture.NEAREST_SAMPLINGMODE,
            () => {
              this.badgeMaterial.diffuseTexture = this._badgeTexture;
              this.badgeMaterial.opacityTexture = this._badgeTexture;
              this.updateBadge();
            },
            () => {
              this.disposeBadge();
            },
          );
        }
      } else {
        this.disposeBadge();
      }
      this._popupDescr = this._tempPopupDescr;

      if (this.collisionsEnabled !== this.element.parameters.collisions) {
        this.collisionsEnabled = !!this.element.parameters.collisions;
        this.setCollisions(this.collisionsEnabled);
      }
      if (this.element.parameters.shadows !== this.shadowsEnabled) {
        this.shadowsEnabled = !!this.element.parameters.shadows;
        this.viewManager.lights.forEach((light) => {
          light.update();
        });
      }
    } else {
      this.dispose();
    }

    if (this.viewManager.targetMode === ViewMode.Player) {
      this.effect?.trigger === InteractionTrigger.Load && !this.effect?.isPlaying && this.effect.play();
    } else {
      this.effect?.reset();
    }
  }

  updateBadge() {
    this.badge.overlayColor.fromArray(this._popupDescr?.color ?? POPUP_COLOR);
    this.badge.material = this.badgeMaterial;

    if (!this._badgeAnchor) {
      this._badgeAnchor = new Mesh(`BadgeAnchor-${this.elementId}`);
    }

    if (!this._badgeGizmo) {
      this._badgeGizmo = new PlaneDragGizmo(FORWARD, RED, this.viewManager.badgeLayer);
      this._badgeGizmo.customRotationQuaternion = new Quaternion(0, 1, 0, 0);
      this._badgeGizmo.updateGizmoPositionToMatchAttachedMesh = true;
      this._badgeGizmo.updateGizmoRotationToMatchAttachedMesh = false;
      this._badgeGizmo._rootMesh.getChildMeshes()[0].position.addInPlace(Vector3.Down().scale(0.5));
      this._badgeGizmo._rootMesh.getChildMeshes()[0].scaling.scaleInPlace(GIZMO_SCALE);
      this._badgeGizmo._rootMesh.billboardMode = Mesh.BILLBOARDMODE_ALL;
      this._badgeGizmo.attachedMesh = this._badgeAnchor;
      this._badgeGizmo.setCustomMesh(this.badge);
    }

    this.observers.badgeRender && this.viewManager.scene.onBeforeRenderObservable.remove(this.observers.badgeRender);
    this.observers.badgeRender = this.viewManager.scene.onBeforeRenderObservable.add(() => {
      this.boundingVectors = this.helper.getHierarchyBoundingVectors();
      this._badgeAnchor?.position.set(
        this.helper.position.x,
        Math.max(this.boundingVectors.max.y * 1.1, this.boundingVectors.max.y + 1),
        this.helper.position.z,
      );
    });
  }

  setCollisions(flag: boolean) {
    if (this.box) {
      this.box.checkCollisions = flag;
    } else {
      this.helper
        ?.getChildMeshes(false)
        .concat(this.helper)
        .forEach((mesh) => {
          mesh.checkCollisions = flag && !!mesh.getTotalIndices();
        });
    }
  }

  setPicking(flag: boolean) {
    if (this.box) {
      this.box.isPickable = flag;
    } else {
      this.helper
        ?.getChildMeshes(false)
        .concat(this.helper)
        .forEach((mesh) => {
          mesh.isPickable = flag;
        });
    }
  }

  disposeBadge() {
    this._popupDescr = undefined;
    this._tempPopupDescr = undefined;
    this._badge?.dispose();
    this._badgeAnchor?.dispose();
    this._badgeGizmo?.dispose();
    this._badgeTexture?.dispose();
    this._badgeMaterial?.dispose();
    this._badge = null;
    this._badgeAnchor = null;
    this._badgeGizmo = null;
    this._badgeTexture = null;
    this._badgeMaterial = null;
    this.observers.badgeRender && this.viewManager.scene.onBeforeRenderObservable.remove(this.observers.badgeRender);
  }

  disposeEffect() {
    this._effectDescr = undefined;
    this._tempEffectDescr = undefined;
    this.effect?.dispose();
    this.effect = undefined;
  }

  dispose() {
    this.disposeBadge();
    this.disposeEffect();
    this.box?.dispose();
  }
}
