import { ApplicationService } from '@/view/src/app/app.service';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { POPUP_COLOR, TElement, TInteractableElement } from '@/data/src/lib/view-manager';
import { ViewManagerUtils } from '@/data/src/lib/utils/view-manager-utils';
import { deepCopy } from '@/data/src/lib/utils/data';
import { PanelComponent } from '@/ui/src/lib/components/panel/panel.component';

@Component({
  selector: 'ui-popup-content-panel',
  templateUrl: './popup-content-panel.component.html',
  styleUrls: ['./popup-content-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupContentPanelComponent extends PanelComponent implements OnInit {
  @Input() allowAdd = false;

  _element: TInteractableElement;
  color = ViewManagerUtils.GetColorString(POPUP_COLOR);

  selectedIcon?: string;
  iconType = 'solid';
  icons = ['text', 'image', 'video', 'link', 'guestbook', 'contact', 'info', 'help'];

  @Input() set element(input: TElement | undefined) {
    if (!input) {
      return;
    }
    this._element = deepCopy(input) as TInteractableElement;
    this.selectedIcon = this.popup?.thumbnail;
    this.iconType = this.selectedIcon ? ['solid', 'line'].find((type) => this.selectedIcon!.includes(type)) ?? 'solid' : 'solid';
    this.color = ViewManagerUtils.GetColorString(this.popup?.color ?? POPUP_COLOR);
  }

  get element(): TInteractableElement {
    return this._element as TInteractableElement;
  }

  get popup() {
    return this.element?.parameters?.popups?.[0];
  }

  constructor(protected _appService: ApplicationService) {
    super(_appService);
  }

  ngOnInit(): void {}

  onEdit() {
    this.popup && this._appService.showPopup(this.popup, this.element, true);
  }

  onDelete() {
    this.element &&
      this._appService.updateViewElement({
        ...this.element,
        parameters: {
          ...this.element.parameters,
          popups: [],
        },
      } as TInteractableElement);
  }

  onColorChanged(color: string) {
    this.color = color;
    this.element &&
      this._appService.updateViewRenderable({
        ...this.element,
        parameters: {
          ...this.element.parameters,
          popups: this.element.parameters.popups
            ? [
                {
                  ...this.popup,
                  color: ViewManagerUtils.GetColorArray(this.color).slice(0, 3) ?? POPUP_COLOR,
                },
                ...this.element.parameters.popups?.slice(1),
              ]
            : [{ ...this.popup, color: ViewManagerUtils.GetColorArray(color) }],
        },
      } as TInteractableElement);
  }

  onThumbnailSelected(thumbnail?: string) {
    this.selectedIcon = thumbnail;
    this.element &&
      this._appService.updateViewElement(
        {
          ...this.element,
          parameters: {
            ...this.element.parameters,
            popups: this.element.parameters.popups
              ? [
                  {
                    ...this.popup,
                    thumbnail: thumbnail ? `ui/assets/icons/icon-${thumbnail}-${this.iconType}.svg` : undefined,
                    color: ViewManagerUtils.GetColorArray(this.color).slice(0, 3),
                  },
                  ...this.element.parameters.popups?.slice(1),
                ]
              : [],
          },
        } as TInteractableElement,
        true,
      );
  }

  onThumbnailTypeChanged(flag: boolean) {
    this.iconType = flag ? 'solid' : 'line';
    if (this.popup?.thumbnail && !this.popup.thumbnail.includes(this.iconType)) {
      this._appService.updateViewElement(
        {
          ...this.element,
          parameters: {
            ...this.element.parameters,
            popups: this.element.parameters.popups
              ? [
                  {
                    ...this.popup,
                    thumbnail: `ui/assets/icons/icon-${this.icons.find((icon) => this.popup!.thumbnail!.includes(icon))}-${
                      this.iconType
                    }.svg`,
                  },
                  ...this.element.parameters.popups?.slice(1),
                ]
              : [],
          },
        } as TInteractableElement,
        true,
      );
    }
  }

  onChangeEnd() {
    if (!this.element) {
      return;
    }
    const actualElement = this.element && this._appService.getViewRenderable(this.element)?.element;
    if (!actualElement) {
      return;
    }
    if (
      ViewManagerUtils.GetColorArray(this.color)
        .slice(0, 3)
        .some((x, i) => this.popup?.color?.[i] !== x)
    ) {
      this._appService.updateViewElement(actualElement);
    }
  }

  ngOnDestroy() {
    this.onChangeEnd();
    super.ngOnDestroy();
  }
}
