import { Component, Inject, Input, OnInit } from '@angular/core';
import { environment } from '@/app/src/environments/environment';
import { WINDOW } from '@ng-web-apis/common';
import { ScenePlan, ScenePlanDetail } from '@/data/src/lib/enums/pricing-plan';
import { PricingPlanService } from '@/data/src/lib/services/pricing-plan.service';

@Component({
  selector: 'ui-ad-banner',
  templateUrl: './ad-banner.component.html',
  styleUrls: ['./ad-banner.component.scss'],
})
export class AdBannerComponent implements OnInit {
  _isScenePlanPropertyRestriction = true;

  @Input() isMobile = false;
  @Input() set isScenePlanPropertyRestriction(scenePlan: ScenePlan) {
    this._isScenePlanPropertyRestriction = this._pricingPlanService.hasScenePlanPropertyRestriction(
      scenePlan,
      ScenePlanDetail.OwnXrAdDisplay,
    );
  }

  constructor(
    @Inject(WINDOW) private readonly _window: Window,
    private _pricingPlanService: PricingPlanService,
  ) {}

  ngOnInit(): void {}

  goToOxr() {
    this._window.open(environment.redirectURL, '_blank');
  }
}
