export enum CardRequestPaymentParam {
  OrderId = 'orderId',
  PaymentKey = 'paymentKey',
  Amount = 'amount',
  Code = 'code',
  Message = 'message',
}

export enum PaymentEndPoint {
  PaymentApproval = 'https://api.tosspayments.com/v1/payments/confirm', // (Post)
  PaymentCancel = 'https://api.tosspayments.com/v1/payments/', // (Post)
  CardNumberPayment = 'https://api.tosspayments.com/v1/payments/key-in', // (Post)
  AutomaticPaymentBillingByCustomerKey = 'https://api.tosspayments.com/v1/billing/authorizations/card', // (Post)
  AutomaticPaymentApproval = 'https://api.tosspayments.com/v1/billing/', // (Post)
  PaymentInquiryByPaymentKey = 'https://api.tosspayments.com/v1/payments/', // (Get)
  PaymentInquiryByOrderId = 'https://api.tosspayments.com/v1/payments/orders/', // (Get)
}

export enum StripeSubscriptionState {
  Active = 'active',
  Canceled = 'canceled',
}
