import { _Exporter } from "../glTFExporter.js";
import { PBRMaterial } from "@babylonjs/core/Materials/PBR/pbrMaterial.js";
import { Color3 } from "@babylonjs/core/Maths/math.color.js";
const NAME = "KHR_materials_volume";
/**
 * [Specification](https://github.com/KhronosGroup/glTF/blob/main/extensions/2.0/Khronos/KHR_materials_volume/README.md)
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class KHR_materials_volume {
  constructor(exporter) {
    /** Name of this extension */
    this.name = NAME;
    /** Defines whether this extension is enabled */
    this.enabled = true;
    /** Defines whether this extension is required */
    this.required = false;
    this._wasUsed = false;
    this._exporter = exporter;
  }
  dispose() {}
  /** @internal */
  get wasUsed() {
    return this._wasUsed;
  }
  postExportMaterialAdditionalTextures(context, node, babylonMaterial) {
    const additionalTextures = [];
    if (babylonMaterial instanceof PBRMaterial) {
      if (this._isExtensionEnabled(babylonMaterial)) {
        if (babylonMaterial.subSurface.thicknessTexture) {
          additionalTextures.push(babylonMaterial.subSurface.thicknessTexture);
        }
        return additionalTextures;
      }
    }
    return additionalTextures;
  }
  _isExtensionEnabled(mat) {
    // This extension must not be used on a material that also uses KHR_materials_unlit
    if (mat.unlit) {
      return false;
    }
    const subs = mat.subSurface;
    // this extension requires either the KHR_materials_transmission or KHR_materials_translucency extensions.
    if (!subs.isRefractionEnabled && !subs.isTranslucencyEnabled) {
      return false;
    }
    return subs.maximumThickness != undefined && subs.maximumThickness != 0 || subs.tintColorAtDistance != undefined && subs.tintColorAtDistance != Number.POSITIVE_INFINITY || subs.tintColor != undefined && subs.tintColor != Color3.White() || this._hasTexturesExtension(mat);
  }
  _hasTexturesExtension(mat) {
    return mat.subSurface.thicknessTexture != null;
  }
  postExportMaterialAsync(context, node, babylonMaterial) {
    return new Promise(resolve => {
      var _a;
      if (babylonMaterial instanceof PBRMaterial && this._isExtensionEnabled(babylonMaterial)) {
        this._wasUsed = true;
        const subs = babylonMaterial.subSurface;
        const thicknessFactor = subs.maximumThickness == 0 ? undefined : subs.maximumThickness;
        const thicknessTexture = (_a = this._exporter._glTFMaterialExporter._getTextureInfo(subs.thicknessTexture)) !== null && _a !== void 0 ? _a : undefined;
        const attenuationDistance = subs.tintColorAtDistance == Number.POSITIVE_INFINITY ? undefined : subs.tintColorAtDistance;
        const attenuationColor = subs.tintColor.equalsFloats(1.0, 1.0, 1.0) ? undefined : subs.tintColor.asArray();
        const volumeInfo = {
          thicknessFactor: thicknessFactor,
          thicknessTexture: thicknessTexture,
          attenuationDistance: attenuationDistance,
          attenuationColor: attenuationColor,
          hasTextures: () => {
            return this._hasTexturesExtension(babylonMaterial);
          }
        };
        node.extensions = node.extensions || {};
        node.extensions[NAME] = volumeInfo;
      }
      resolve(node);
    });
  }
}
_Exporter.RegisterExtension(NAME, exporter => new KHR_materials_volume(exporter));
