import { ChangeDetectorRef, ChangeDetectionStrategy, Component, Input, NgZone } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { interval, take } from 'rxjs';
import { ViewManagerUtils } from '@/data/src/lib/utils/view-manager-utils';
import { PanelComponent } from '@/ui/src/lib/components/panel/panel.component';
import { ApplicationService, AssetStatus } from '@/view/src/app/app.service';
import {
  COLLIDABLE_TYPES,
  ElementType,
  MAX_FLOAT,
  STAGE_ROTATION,
  TElement,
  TInteractableElement,
  TLandscapeElement,
  TScreenElement,
  TStageElement,
} from '@/data/src/lib/view-manager';
import { deepCopy } from '@/data/src/lib/utils/data';

const DECIMALS = 4;

@Component({
  selector: 'ui-transformation-panel',
  templateUrl: './transformation-panel.component.html',
  styleUrls: ['./transformation-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransformationPanelComponent extends PanelComponent {
  _element: TInteractableElement | TScreenElement | TStageElement | TLandscapeElement;
  position?: number[];
  rotation?: number[];
  scaling?: number[];
  _position: number[] = [];
  _rotation: number[] = [];
  _scaling: number[] = [];
  bounds?: number[];
  lockScaling = false;

  elementTypes = ElementType;

  @Input() set element(input: TElement | undefined) {
    if (!input) {
      return;
    }
    this._element = deepCopy(input);
    if (this._element) {
      this.bounds = this._appService.getRenderableBounds(this._element);
    }
    this.position = deepCopy(this.element?.parameters.position)?.map((p) => Number(p.toFixed(DECIMALS)));
    if (this.element?.parameters.quaternion) {
      this.rotation = ViewManagerUtils.ToEuler(this.element?.parameters.quaternion).map((r) =>
        Number(((r * 180) / Math.PI).toFixed(DECIMALS)),
      );
    } else if (COLLIDABLE_TYPES.includes(this.element.type)) {
      // Also covers landscape. Stage and landscape will be deprecated in the future
      this.rotation = [
        0,
        Number(((((this.element as TStageElement).parameters?.rotation ?? STAGE_ROTATION) * 180) / Math.PI).toFixed(DECIMALS)),
        0,
      ];
    }
    this.scaling = deepCopy(this.element?.parameters.scaling)?.map((p) => Number(p.toFixed(DECIMALS)));

    this._position.length = 0;
    this._rotation.length = 0;
    this._scaling.length = 0;
    this.position && this._position.push(...this.position);
    this.rotation && this._rotation.push(...this.rotation);
    this.scaling && this._scaling.push(...this.scaling);

    this._cd.detectChanges();
  }

  get element(): TInteractableElement {
    return this._element as TInteractableElement;
  }

  get isCheckpoint() {
    return this.element.type === ElementType.Checkpoint;
  }

  get isScreen() {
    return this.element.type === ElementType.Screen;
  }

  get isText() {
    return this.element.type === ElementType.Text;
  }

  get isStage() {
    return this.element.type === ElementType.Stage;
  }

  get isLandscape() {
    return this.element.type === ElementType.Landscape;
  }

  get fields() {
    return ['position', 'rotation'].concat(this.isCheckpoint ? [] : ['scaling']);
  }

  get bounding() {
    return this.bounds ? `X: ${this.bounds[0].toFixed(2)}m Y: ${this.bounds[1].toFixed(2)}m Z: ${this.bounds[2].toFixed(2)}m` : '';
  }

  constructor(
    protected _appService: ApplicationService,
    private _cd: ChangeDetectorRef,
    private _ngZone: NgZone,
  ) {
    super(_appService);
    this.onChangeEnd = this.onChangeEnd.bind(this);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._appService.assetStatusSubject.pipe(untilDestroyed(this)).subscribe((map) => {
      if (!!map.size && [...map.values()].some((status) => status === AssetStatus.Ready)) {
        this._ngZone.runOutsideAngular(() => {
          interval(100)
            .pipe(take(1), untilDestroyed(this))
            .subscribe(() => {
              this.bounds = this._appService.getRenderableBounds(this.element);
              this._cd.detectChanges();
              this._ngZone.run(() => {});
            });
        });
      }
    });
  }

  getIndexOfDimension(dimension: string) {
    switch (dimension) {
      case 'x':
        return 0;
      case 'y':
        return 1;
      case 'z':
        return 2;
      default:
        return -1;
    }
  }

  getDimensions(field: string) {
    if (this.isCheckpoint) {
      if (field === 'rotation') {
        return ['y'];
      } else if (field === 'scaling') {
        return [];
      }
    } else if (this.isStage || this.isLandscape) {
      if (field === 'rotation') {
        return ['y'];
      }
    } else if ((this.isText || this.isScreen) && field === 'scaling') {
      return ['x', 'y'];
    }
    return ['x', 'y', 'z'];
  }

  getField(field: string, dimension: string) {
    let index = this.getIndexOfDimension(dimension);

    switch (field) {
      case 'position':
        return this._position?.[index] ?? NaN;
      case 'rotation':
        return this._rotation?.[index] ?? NaN;
      case 'scaling':
        return this._scaling?.[index] ?? NaN;
      default:
        return;
    }
  }

  onFieldChange(event: any, field: string, dimension: string, offset = 0) {
    const value = Number((event.target as HTMLInputElement).value) + offset;
    if (isNaN(value) || !this[field]) {
      return;
    }

    let index = this.getIndexOfDimension(dimension);

    switch (field) {
      case 'position':
        if (!this._position) {
          return;
        }
        if (index === 1 && this.isCheckpoint && value < 0) {
          return;
        }
        this._position[index] = Math.max(Math.min(value, 500), -500);
        break;
      case 'rotation':
        if (!this._rotation) {
          return;
        }
        this._rotation[index] = Math.max(Math.min(value, MAX_FLOAT), -MAX_FLOAT);
        break;
      case 'scaling':
        if (!this._scaling) {
          return;
        }
        if (this.lockScaling) {
          this._scaling.fill(Math.max(Math.min(value, 100), -100));
        } else {
          this._scaling[index] = Math.max(Math.min(value, 100), -100);
        }
        break;
      default:
        return;
    }

    this.element &&
      this._appService.updateViewRenderable({
        ...this.element,
        parameters: {
          ...this.element.parameters,
          position: this._position,
          ...(this._rotation
            ? COLLIDABLE_TYPES.includes(this.element.type)
              ? { rotation: (this._rotation[1] * Math.PI) / 180 }
              : { quaternion: ViewManagerUtils.ToQuaternion(this._rotation.map((d) => (d * Math.PI) / 180) as [number, number, number]) }
            : {}),
          ...(this._scaling ? { scaling: this._scaling } : {}),
        },
      } as TInteractableElement);
  }

  onKeyDown(event: KeyboardEvent, field: string, dimension: string) {
    if (event.code !== 'ArrowUp' && event.code !== 'ArrowDown') {
      return;
    }
    let offset = 0;
    switch (event.code) {
      case 'ArrowUp':
        offset += 1;
        break;
      case 'ArrowDown':
        offset -= 1;
        break;
      default:
        break;
    }
    this.onFieldChange(event as Event, field, dimension, offset);
  }

  onScalingLockToggle(lock: boolean) {
    this.lockScaling = lock;
  }

  onChangeEnd() {
    if (!this.element) {
      return;
    }
    const actualElement = this._appService.getViewRenderable(this.element)?.element as TInteractableElement;
    if (!actualElement) {
      return;
    }
    if (
      actualElement.parameters.position?.some((v, i) => v !== this.element.parameters.position?.[i]) ||
      actualElement.parameters.quaternion?.some((v, i) => v !== this.element.parameters.quaternion?.[i]) ||
      actualElement.parameters.scaling?.some((v, i) => v !== this.element.parameters.scaling?.[i]) ||
      (actualElement as TStageElement).parameters.rotation !== (this.element as TStageElement).parameters.rotation
    ) {
      this._appService.updateViewElement(actualElement);
    }
  }

  ngOnDestroy(): void {
    this.onChangeEnd();
    super.ngOnDestroy();
  }

  trackDimension(field: string, _index: number, dimension: string) {
    return field + dimension;
  }

  trackField(index: number) {
    return index;
  }
}
