import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ModalService } from '../modal.service';

@Component({
  selector: 'ui-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationComponent implements OnInit {
  public title = '';
  public body = '';
  public confirmAction = 'shared.confirmation.yes';
  public cancelAction = 'shared.confirmation.no';
  public showCancelAction = true;

  constructor(private _modalService: ModalService) {}

  ngOnInit(): void {}

  close(result: boolean) {
    this._modalService.close(result, this['modalContainerRef']);
  }
}
