import { Inject, Injectable } from '@angular/core';
import { SessionStorageKeys } from '../enums/storage-keys';
import { SESSION_STORAGE } from '@ng-web-apis/common';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor(@Inject(SESSION_STORAGE) private readonly _sessionStorage: Storage) {}

  get length(): number {
    return this._sessionStorage.length;
  }

  clear() {
    this._sessionStorage.clear();
  }

  getItem(key: SessionStorageKeys) {
    const jsonValue = this._sessionStorage.getItem(key);
    if (jsonValue) {
      try {
        return JSON.parse(jsonValue);
      } catch (error) {
        console.log(error);
      }
    }

    return null;
  }

  key(index: number): string | null {
    return this._sessionStorage.key(index);
  }

  removeItem(key: SessionStorageKeys) {
    this._sessionStorage.removeItem(key);
  }

  setItem(key: SessionStorageKeys, value: any) {
    try {
      const jsonValue = JSON.stringify(value);
      this._sessionStorage.setItem(key, jsonValue);
    } catch (error) {
      console.log(error);
    }
  }
}
