import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, debounceTime } from 'rxjs';
import { AccountService, LanguageCode } from '@/data/src/lib/services/account.service';
import { ApplicationService } from '@/view/src/app/app.service';
import { ModalService } from '../../modal.service';
import { INTERACTABLE_TYPES, PopupType, TInteractableElement } from '@/data/src/lib/view-manager';

@UntilDestroy()
@Component({
  selector: 'ui-google-form-tool',
  templateUrl: './google-form-tool.component.html',
  styleUrls: ['./google-form-tool.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleFormToolComponent implements OnInit {
  title = '';
  link: string | null = '';

  isPreviewMode = false;
  isReadonly = false;
  titleLimit = 30; //ko 30 en 50

  linkControl = new FormControl('');
  linkInvalid = false;
  selectedElement?: TInteractableElement;

  constructor(
    private _modalService: ModalService,
    private _appService: ApplicationService,
    private _accountService: AccountService,
    private _sanitizer: DomSanitizer,
    private _cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.link) {
      const link = this.replaceUSP(this.link);
      this.linkControl.setValue(link);
      this.link = this._sanitizer.sanitize(4, link) ?? link;
    }

    this._appService.viewElementsSubject.pipe(untilDestroyed(this)).subscribe((elements) => {
      this.selectedElement = elements.find(({ id }) => id === this.selectedElement?.id) as TInteractableElement;
      this._cd.detectChanges();
    });

    this._appService.selectedElementsSubject.pipe(untilDestroyed(this)).subscribe((elements) => {
      this.selectedElement =
        elements.length === 1 && INTERACTABLE_TYPES.includes(elements[0].type) ? (elements[0] as TInteractableElement) : undefined;
      this._cd.detectChanges();
    });

    this._accountService.language$
      .pipe(
        map((lang) => (lang === LanguageCode.English ? 50 : 30)),
        untilDestroyed(this),
      )
      .subscribe((titleLimit) => {
        this.titleLimit = titleLimit;
        this._cd.markForCheck();
      });

    this.linkControl.valueChanges.pipe(debounceTime(300), untilDestroyed(this)).subscribe((value) => {
      const trimmedValue = value?.trim();
      this.linkInvalid = !!(
        trimmedValue &&
        !trimmedValue.startsWith('https://forms.gle/') &&
        !trimmedValue.startsWith('https://docs.google.com/forms/')
      );
      if (trimmedValue && !this.linkInvalid) {
        const link = this.replaceUSP(trimmedValue);
        this.link = this._sanitizer.sanitize(4, link) ?? link;
      }
      this._cd.markForCheck();
    });
  }

  close() {
    this._modalService.close(false, this['modalContainerRef']);
  }

  enterPreviewMode() {
    this.isPreviewMode = true;
  }

  save() {
    const oldData = this.selectedElement?.parameters?.popups?.length ? this.selectedElement.parameters.popups[0] : {};
    this.selectedElement &&
      this._appService.updateViewElement(
        {
          ...this.selectedElement,
          parameters: {
            ...this.selectedElement.parameters,
            popups: [
              {
                ...oldData,
                type: PopupType.GoogleForm,
                parameters: [
                  { key: 'link', value: this.link },
                  { key: 'title', value: this.title },
                  { key: 'isReadonly', value: this.isReadonly },
                ],
              },
            ],
          },
        } as TInteractableElement,
        true,
      );
    this._modalService.close({ title: this.title, link: this.link }, this['modalContainerRef']);
  }

  // remove link marking and add embedded marking
  replaceUSP(link: string): string {
    let newLink = link;
    if (link.includes('usp=sf_link') && !link.includes('embedded=true')) {
      newLink = link.replace('usp=sf_link', 'embedded=true');
    } else if (link.includes('usp=sf_link')) {
      newLink = link.replace('usp=sf_link', '');
    }
    return newLink;
  }
}
