@if (viewMode === guestbookMode.Edit) {
  <div class="popup-content-modal">
    <section class="modal-header">
      <div class="header-title">{{ 'oxr.creatingSpace.guestBook.guestBook' | translate }}</div>
      <div class="close icon-wrapper" (click)="closeModal()">
        <div class="icon icon-close sm"></div>
      </div>
    </section>
    <div class="modal-body container-edit" [formGroup]="editForm">
      <div class="title">
        {{ 'oxr.creatingSpace.guestBook.guestBook' | translate }}
      </div>
      <input
        uiInput
        placeholder="{{ 'oxr.creatingSpace.guestBook.enterTheIntroduction' | translate }}"
        formControlName="introduction"
        required
        uiAutofocus
      />
      <div class="description">
        {{ 'oxr.creatingSpace.guestBook.pleaseChooseWhetherToRevealTheGuestBookOrNot' | translate }}
      </div>
      <ui-switch
        size="lg"
        onContent="{{ 'oxr.creatingSpace.guestBook.public' | translate }}"
        offContent="{{ 'oxr.creatingSpace.guestBook.private' | translate }}"
        [checked]="accessChecked"
        [onShowContentMode]="true"
        (changed)="onSwitchChanged($event)"
      ></ui-switch>
      <section class="footer">
        <button ui-button md [disabled]="!editForm.valid" (click)="saveSettings()">{{ 'shared.actions.save' | translate }}</button>
      </section>
    </div>
  </div>
}

@if (viewMode === guestbookMode.Preview) {
  <div class="container-preview" [class.empty]="!interactions || !interactions.length">
    <div class="close icon icon-close xl" (click)="closeModal()"></div>
    <div class="header">
      <div class="title">{{ 'oxr.creatingSpace.guestBook.guestBook' | translate }}</div>
      <div class="introduction">{{ data['introduction'] || ('oxr.creatingSpace.guestBook.introductionPlaceholder' | translate) }}</div>
      <div class="count-box">
        <ng-container *ngFor="let emoji of emojis">
          <div class="simple-image" [ngClass]="emoji"></div>
          {{ emojiCounts[emoji] }}
        </ng-container>
      </div>
    </div>
    <div class="body">
      @if (interactions.length! > 0) {
        <drag-scroll>
          <div class="contents">
            @for (item of interactions; track item; let i = $index) {
              <div class="wrapper">
                @if (
                  (!!activeAccount && activeAccount.Id === selectedScene?.CreatorUser?.Id) ||
                  item.Username === activeAccount?.Username ||
                  isEnterpriseOrAdmin
                ) {
                  <div class="close" (click)="deleteComment(item.Id)">
                    <div class="icon icon-close xs"></div>
                  </div>
                }
                <div class="simple-image" [ngClass]="item.Interaction.Emoji"></div>
                <div class="userName">{{ item.Username | uppercase }}</div>
                <!-- <textarea
                  class="comment"
                  uiTextarea
                  nonResizable
                  rows="4"
                  [(ngModel)]="item.Interaction.Comment"
                  [disabled]="true"
                ></textarea> -->
                <p class="comment">{{ item.Interaction.Comment }}</p>
                <div class="date">{{ item.Date | date: 'yyyy.MM.dd' }}</div>
              </div>
            }
          </div>
        </drag-scroll>
      }
      @if (interactions.length! === 0) {
        <div class="none-contents">
          {{ 'oxr.creatingSpace.guestBook.thereIsNoGuestbookWrittenYet' | translate }}
        </div>
      }
    </div>
    <div class="footer">
      @if (isEditMode) {
        <button ui-button md (click)="openSettingModal()">
          {{ 'oxr.creatingSpace.guestBook.edit' | translate | uppercase }}
        </button>
      } @else {
        <button ui-button md (click)="openCommentModal()">
          {{ 'oxr.creatingSpace.guestBook.leaveGuestBook' | translate }}
        </button>
      }
      <ng-template #leaveComment>
        <button ui-button md (click)="openCommentModal()">
          {{ 'oxr.creatingSpace.guestBook.leaveGuestBook' | translate }}
        </button>
      </ng-template>
    </div>
  </div>
}
@if (viewMode === guestbookMode.Comment) {
  <div class="popup-content-modal container-comment">
    <section class="modal-header">
      <div class="header-title">{{ 'oxr.creatingSpace.guestBook.guestBook' | translate }}</div>
      <div class="close icon-wrapper" (click)="closeModal()">
        <div class="icon icon-close sm"></div>
      </div>
    </section>
    <div class="modal-body body">
      <div class="title">
        {{ 'oxr.creatingSpace.guestBook.guestBook' | translate }}
      </div>
      <div class="emoji">
        <ng-container *ngFor="let emoji of emojis">
          <div class="img-container">
            <input type="checkbox" #checkbox (change)="onSelectionChanged(emoji)" />
            <div class="animated-image {{ emoji }}" [ngClass]="{ selected: selectedEmoji === emoji }" (click)="checkbox.click()"></div>
            <div class="simple-image {{ emoji }}" [ngClass]="{ selected: selectedEmoji === emoji }"></div>
          </div>
        </ng-container>
      </div>
      <div class="wrapper">
        <textarea
          uiTextarea
          nonResizable
          rows="4"
          [(ngModel)]="newComment"
          placeholder="{{ 'oxr.creatingSpace.guestBook.placeholder' | translate }}"
          maxlength="150"
        >
        </textarea>
        <div class="letter-count">{{ newComment.length }}/150</div>
      </div>
      <section class="footer">
        <button ui-button md (click)="leaveComment()" [disabled]="!selectedEmoji || !newComment">
          {{ 'oxr.creatingSpace.guestBook.leave' | translate }}
        </button>
      </section>
    </div>
  </div>
}
