<div class="header">{{ title }}</div>
<div class="body">
  <div class="message-container">
    <div class="message" [innerHtml]="message | translate"></div>
  </div>
  @if (showCheckbox) {
    <div class="checkbox-container">
      <ui-check-box class="checkbox background-white grey xs" [checked]="isChecked" (checkedChange)="toggleSelection($event)">{{
        checkboxMessage
      }}</ui-check-box>
    </div>
  }
</div>
<div class="footer">
  <button ui-button md uiAutofocus (click)="close(isChecked)">{{ actionMessage }}</button>
</div>
