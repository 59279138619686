import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface ContextMenuItem {
  name: string;
  shortcut: string;
  value?: string;
}

@Component({
  selector: 'ui-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenuComponent implements OnInit {
  @Input() data: ContextMenuItem[] = [];
  @Output() onSelect = new EventEmitter<ContextMenuItem>();
  @Output() hide = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  setSelection(value: ContextMenuItem) {
    this.onSelect.emit(value);
  }

  onHide(event) {
    this.hide.emit(event);
  }
}
