import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFilterComponent implements OnInit {
  _checkedTags: string[] = [];
  @Input() tags: string[] = [];
  @Input() isResettable: boolean = true;
  @Input() singleSelection: boolean = false;
  @Input() set checkedTags(value: string[]) {
    this._checkedTags = value;
    this._cd.detectChanges();
  }
  @Output() tagsChecked = new EventEmitter<string[]>();
  get checkedTags() {
    return this._checkedTags;
  }
  constructor(private _cd: ChangeDetectorRef) {}

  ngOnInit(): void {}

  tagClicked(tag: string) {
    if (this._checkedTags.includes(tag)) {
      this._checkedTags = this._checkedTags.filter((item) => item !== tag);
    } else {
      if (this.singleSelection) {
        this._checkedTags.length = 0;
      }
      this._checkedTags.push(tag);
    }
    this.tagsChecked.emit([...this._checkedTags]);
  }

  reset() {
    this._checkedTags = [];
    this.tagsChecked.emit([]);
  }
}
