import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { map } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LanguageCode } from '@/data/src/lib/services/account.service';
import { AccountService } from '@/data/src/lib/services/account.service';
import { ReleaseLog, ReleaseLogService } from '@/data/src/lib/services/release-log.service';
import { AccountOptionType } from '@/data/src/lib/models/data/account';
import { EnterpriseService } from '@/data/src/lib/services/enterprise.service';
import { LocalStorageService } from '@/data/src/lib/services/local-storage.service';
import { UrlService } from '@/data/src/lib/services/url.service';
import { AccountPanelService } from './account-panel.service';
import { TooltipPosition } from '../../directive/tooltip.directive';
import { LocalStorageKeys } from '@/data/src/lib/enums/storage-keys';

@UntilDestroy()
@Component({
  selector: 'ui-account-panel',
  templateUrl: './account-panel.component.html',
  styleUrls: ['./account-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountPanelComponent implements OnInit {
  languageCode = LanguageCode;
  accountOptionType = AccountOptionType;
  fileUploading = false;
  hasEnterprise = false;
  toolTipPositions = TooltipPosition;
  isAccountOptionDropdownDisabled: boolean;

  isPanelOpened$ = this._accountPanelService.isPanelOpened$;
  isDetailsPanelOpened$ = this._accountPanelService.isDetailsPanelOpened$;
  openedDetails$ = this._accountPanelService.openedDetails$;
  currentLang$ = this._accountPanelService.currentLang$;
  account$ = this._accountPanelService.account$;
  email$ = this._accountPanelService.email$;

  latestReleaseVersion$ = this._releaseLogService.releaseLog$.pipe(map((log: ReleaseLog[]) => log[0].VersionNumber));

  unviewedNotificationsNumber$ = this._accountPanelService.notifications$.pipe(
    map((items) => items.filter((item) => item.HasBeenViewed === false).length),
  );

  get accountOptions() {
    return this._accountService.accountOptions.getValue();
  }

  get myAccountOption() {
    const { OptionId } = this._accountService.currentAccountOption.getValue() || {};
    if (OptionId) {
      return this.accountOptions.find((opt) => opt.OptionId === OptionId);
    }
    return null;
  }

  constructor(
    private _accountPanelService: AccountPanelService,
    private _accountService: AccountService,
    private _releaseLogService: ReleaseLogService,
    private _msalService: MsalService,
    private _cd: ChangeDetectorRef,
    private _router: Router,
    private _enterpriseService: EnterpriseService,
    private _localStorageService: LocalStorageService,
    private _urlService: UrlService,
  ) {
    this.setAccountOptionDropdownState(this._router.url);
  }

  async ngOnInit() {
    this._router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setAccountOptionDropdownState(event.url);
      }
    });

    this._enterpriseService.data$.pipe(untilDestroyed(this)).subscribe(() => {
      const enterprises = this._enterpriseService.getCached();
      if (enterprises.length > 0) {
        this.hasEnterprise = true;
      }

      this._accountService.setAccountOptions(enterprises);
      const optionId = this._localStorageService.getItem(LocalStorageKeys.SELECTED_ACCOUNT_OPTION_ID);
      if (optionId && optionId !== `${this.accountOptionType.Individual}`) {
        this._accountService.setCurrentAccount(optionId);
      }

      this._cd.detectChanges();
    });
  }

  setAccountOptionDropdownState(url: string) {
    if (
      url.startsWith('/oxr/space/') ||
      url.startsWith('/editor/asset/') ||
      url.startsWith('/domain/search/setting/ownxr') ||
      url.startsWith('/domain/search/setting/connection')
    ) {
      this.isAccountOptionDropdownDisabled = true;
    } else {
      this.isAccountOptionDropdownDisabled = false;
    }
  }

  changeAccount(optionId: any) {
    this._accountService.setCurrentAccount(optionId);
  }

  openDetails(name: string): void {
    this._accountPanelService.openDetails(name);
  }

  onRedirect(name: string) {
    this._router.navigate(['oxr', name]);
  }

  closeDetails(): void {
    this._accountPanelService.closeDetails();
    this._cd.markForCheck();
  }

  onClose(): void {
    this._accountPanelService.closePanel();
  }

  onLogout(): void {
    this._accountPanelService.logout();
  }

  onLogin(): void {
    this._msalService.loginRedirect();
  }

  onSignUp(): void {
    this._msalService.loginRedirect();
  }

  showTermsOfUse(): void {
    this._urlService.windowOpenWithPlatform('/termsOfUse');
  }

  showPrivacyPolicy(): void {
    this._urlService.windowOpenWithPlatform('/privacyPolicy');
  }

  async uploadFile(event) {
    this.fileUploading = true;
    this._cd.detectChanges();
    await this._accountPanelService.uploadFile(event);
    this.fileUploading = false;
    this._cd.detectChanges();
  }
  trackByFn(item) {
    return item && item.Id;
  }
}
