import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUserPermission, ModelType } from '../models/data/base';
import { UserScene } from '../models/data/user-scene';
import { BaseApiService } from './base-api.service';
import { AccountService } from './account.service';

/**
 * Tracks and manages the state of user scene models and commits changes to the backend
 */
@Injectable({
  providedIn: 'root',
})
export class UserSceneService extends BaseApiService<UserScene> {
  constructor(public http: HttpClient, accountService: AccountService) {
    super(http, accountService, ModelType.UserScene);
  }

  /**
   * Gets all the users that have access to the input scene
   * @param sceneId id of asset
   * @returns array of user permissions
   */
  async getBySceneId(sceneId: string): Promise<IUserPermission[]> {
    return await this.get(`ByScene/${sceneId}`);
  }
}
