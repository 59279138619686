<ui-panel [title]="'oxr.creatingSpace.panels.common.color' | translate" [collapsing]="true" [panelId]="panelMask.Colors">
  <div class="color">
    <ui-color-picker
      [label]="'oxr.creatingSpace.panels.customization.diffuseColor' | translate"
      [defaultColor]="diffuseColor"
      (changed)="onColorChange($event, 'diffuseColor')"
      (changeEnd)="onChangeEnd()"
    ></ui-color-picker>
  </div>
  <br />
  <div class="color">
    <ui-color-picker
      [label]="'oxr.creatingSpace.panels.customization.emissiveColor' | translate"
      [defaultColor]="emissiveColor"
      (changed)="onColorChange($event, 'emissiveColor')"
      (changeEnd)="onChangeEnd()"
    ></ui-color-picker>
  </div>
  <br />
  <div class="color">
    <ui-color-picker
      [label]="'oxr.creatingSpace.panels.customization.ambientColor' | translate"
      [defaultColor]="ambientColor"
      (changed)="onColorChange($event, 'ambientColor')"
      (changeEnd)="onChangeEnd()"
    ></ui-color-picker>
  </div>
  <div class="separator"></div>
  <ui-switch
    class="icon-style-switch"
    [checked]="unlit"
    [label]="'oxr.creatingSpace.panels.customization.unlit' | translate"
    [onShowContentMode]="true"
    (changed)="onUnlitToggle($event)"
  ></ui-switch>
</ui-panel>
