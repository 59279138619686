@if (notification) {
  <div class="notification" [class.viewed]="notification.HasBeenViewed">
    <div class="left" (click)="onClick()">
      @if (notification.Type === notificationTypes.Announcement) {
        <div class="thumbnail announcement">
          <div class="image"></div>
        </div>
        <div class="content announcement">
          <p>{{ notification.Message }}</p>
        </div>
      } @else {
        <div class="thumbnail">
          <div class="image"></div>
          <div class="type-image-wrapper">
            @switch (notification.Type) {
              @case (notificationTypes.Message) {
                <div class="type-image message"></div>
              }
              @case (notificationTypes.MarketLike || notificationTypes.SceneLike) {
                <div class="type-image like"></div>
              }
              @default {
                <div class="type-image default"></div>
              }
            }
          </div>
        </div>
        <div class="content">
          <div class="title">{{ notification.FromUserName | uppercase }}</div>
          <div class="subtitle">{{ notification.Message }}</div>
        </div>
      }
    </div>
    <div class="right">
      @if (!notification.HasBeenViewed) {
        <div class="notification-dot"></div>
      }
      <div (click)="onDelete()" class="icon icon-delete xs"></div>
    </div>
  </div>
}
