import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { EnterpriseService } from '@/data/src/lib/services/enterprise.service';
import { AccountService } from '@/data/src/lib/services/account.service';
import { AccountOption } from '@/data/src/lib/models/data/account';
import { EnterpriseRole, EnterpriseRoleNumeric, User } from '@/data/src/lib/models/data/enterprise';
import { forkJoin, map } from 'rxjs';
import { OxrSettingsService } from '../../oxr-settings.service';

@UntilDestroy()
@Component({
  selector: 'oxr-collaborator-management',
  templateUrl: './oxr-collaborator-management.component.html',
  styleUrls: ['./oxr-collaborator-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OxrCollaboratorManagementComponent implements OnInit {
  @Input() scene!: XRScene;

  searchText: string;
  showTitle = false;

  loading = true;
  selectedAccountOption: AccountOption | null = null;
  activeCollaborators: User[] = [];
  inActiveCollaborators: User[] = [];
  selectedActiveCollaboratoreId = '';
  selectedInActiveCollaboratoreId = '';

  get isOwner() {
    return this.selectedAccountOption?.Role === EnterpriseRole.Owner || false;
  }

  constructor(
    private _enterpriseService: EnterpriseService,
    private _accountService: AccountService,
    private _cd: ChangeDetectorRef,
    private _oxrSettingsService: OxrSettingsService,
  ) {}

  ngOnInit(): void {
    this.selectedAccountOption = this._accountService.currentAccountOption.value;

    if (this.selectedAccountOption?.EnterpriseContract?.EnterpriseId) {
      forkJoin([
        this._enterpriseService.getEnterpriseUsers(this.selectedAccountOption.EnterpriseContract?.EnterpriseId).pipe(
          untilDestroyed(this),
          map((res) => {
            return res.filter((e) => e.Role === EnterpriseRole.Collaborator);
          }),
        ),
        this._enterpriseService
          .getEnterpriseSceneUsers(
            this.selectedAccountOption.EnterpriseContract?.EnterpriseId,
            this.scene.Id,
            EnterpriseRoleNumeric.Collaborator,
          )
          .pipe(untilDestroyed(this)),
      ]).subscribe(([allCollaborators, activeCollaborators]) => {
        const allUsers = allCollaborators.map((enterpriseUser) => enterpriseUser.User);
        const activeUsers = activeCollaborators.map((enterpriseSceneUser) => enterpriseSceneUser.User);
        let inactiveUsers: User[] = [];

        if (activeUsers.length === 0) {
          inactiveUsers = allUsers;
        } else {
          inactiveUsers = allUsers.filter((user) => !activeUsers.some((activeUser) => activeUser.Id === user.Id));
        }

        this.activeCollaborators = activeUsers;
        this.inActiveCollaborators = inactiveUsers;

        this.loading = false;
        this._cd.detectChanges();
      });
    }
  }

  addCollaborator(user: User) {
    this.activeCollaborators = [...this.activeCollaborators, user];
    this.inActiveCollaborators = this.inActiveCollaborators.filter((e) => e.Id !== user.Id);
  }

  removeCollaborator(user: User) {
    this.activeCollaborators = this.activeCollaborators.filter((e) => e.Id !== user.Id);
    this.inActiveCollaborators = [...this.inActiveCollaborators, user];
  }

  onSave() {
    this.loading = true;
    this._cd.detectChanges();

    const activeCollaboratorsId = this.activeCollaborators.map((e) => e.Id);
    const inActiveCollaboratorsId = this.inActiveCollaborators.map((e) => e.Id);

    forkJoin([
      this._enterpriseService.addUserToEnterpriseScene(this.scene.Id, activeCollaboratorsId).pipe(untilDestroyed(this)),
      this._enterpriseService.deleteUserFromEnterpriseScene(this.scene.Id, inActiveCollaboratorsId).pipe(untilDestroyed(this)),
    ]).subscribe(() => {
      this.loading = false;
      this._cd.detectChanges();
      this._oxrSettingsService.closePanel();
    });
  }
}
