<section class="window">
  <div class="logo">
    <img class="ownxr" src="./../../../../assets/images/img-icon-ownxr-logo.png" />
    <div class="subtitle">{{ 'shared.information.vrControlsGuide' | translate }}</div>
  </div>

  <div class="row-1">
    <div class="move action">
      <div class="image-container">
        <img class="icon" src="./../../../../assets/images/img-icon-move.png" />
      </div>
      <div class="text">{{ 'shared.actions.move' | translate }}</div>
    </div>
    <div class="jump action">
      <div class="image-container">
        <div class="key">SPACE</div>
      </div>
      <div class="text">{{ 'shared.actions.jump' | translate }}</div>
    </div>
    <div class="run action">
      <div class="image-container">
        <div class="key">SHIFT</div>
      </div>
      <div class="text">{{ 'shared.actions.run' | translate }}</div>
    </div>
  </div>
  <div class="row-2">
    <div class="rotate action">
      <div class="image-container">
        <img class="icon" src="./../../../../assets/images/img-icon-rotate.png" />
      </div>
      <div class="text">{{ 'shared.actions.rotate' | translate }}</div>
    </div>
    <div class="select action">
      <div class="image-container">
        <img class="icon" src="./../../../../assets/images/img-icon-click.png" />
      </div>
      <div class="text">{{ 'shared.actions.select' | translate }}</div>
    </div>
    <div class="pause action">
      <div class="image-container">
        <div class="key">ESC</div>
      </div>
      <div class="text">{{ 'shared.actions.pause' | translate }}</div>
    </div>
    <div class="fullscreen action">
      <div class="image-container">
        <div class="key">F11</div>
      </div>
      <div class="text">{{ 'shared.actions.fullscreen' | translate }}</div>
    </div>
    <div class="refresh action">
      <div class="image-container">
        <div class="key">R</div>
      </div>
      <div class="text">{{ 'shared.actions.refreshView' | translate }}</div>
    </div>
  </div>
  <div class="guide">
    <div>{{ 'shared.information.controlGuide1' | translate }}</div>
    <div class="icon icon-crosshair xl"></div>
    <div>{{ 'shared.information.controlGuide2' | translate }}</div>
    <div class="icon icon-crosshair xl primary"></div>
    <div>{{ 'shared.information.controlGuide3' | translate }}</div>
  </div>
</section>

<button ui-button [style.visibility]="displayButton ? 'visible' : 'hidden'" (click)="close()">
  {{ 'shared.actions.continue' | translate }}
</button>
