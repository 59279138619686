import { Component, AfterViewInit, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, NgZone } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { AccountService, LanguageCode } from '../../../../../../data/src/lib/services/account.service';
import { XRSceneInteraction } from '@/data/src/lib/models/data/scene';
import { SceneInteractionType } from '@/data/src/lib/enums/scene-interaction';
import { ApplicationService } from '@/view/src/app/app.service';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { INTERACTABLE_TYPES, TInteractableElement } from '@/data/src/lib/view-manager';
import { MobileInformationComponent } from '@/ui/src/lib/modal/mobile/m-information/m-information.component';
import { MobileTermsAndConditionsComponent } from '@/ui/src/lib/modal/mobile/m-terms-and-conditions/m-terms-and-conditions.component';
import { interval, take } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'ui-m-contact-form-tool',
  templateUrl: './m-contact-form-tool.component.html',
  styleUrls: ['./m-contact-form-tool.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ModalService],
})
export class MobileContactFormToolComponent implements OnInit, AfterViewInit {
  languageCodes = LanguageCode;
  currentLanguage$ = this._accountService.language$;

  form = new UntypedFormGroup({
    placeholderFirst: new UntypedFormControl(''),
    placeholderSecond: new UntypedFormControl(''),
    placeholderThird: new UntypedFormControl(''),
    placeholderFourth: new UntypedFormControl(''),
  });

  checkedAgree: boolean;

  keyValue: { [key: string]: string };
  data: { [key: string]: string };
  modalRef: ModalService;
  email;
  selectedElement?: TInteractableElement;
  sceneId?: string;

  get isFormValid() {
    if (
      (!!+this.data[this.keyValue['CHECKEDPLACEHOLDERFIRST']] && !this.form.get('placeholderFirst')?.value) ||
      (!!+this.data[this.keyValue['CHECKEDPLACEHOLDERSECOND']] && !this.form.get('placeholderSecond')?.value) ||
      (!!+this.data[this.keyValue['CHECKEDPLACEHOLDERTHIRD']] && !this.form.get('placeholderThird')?.value) ||
      (!!+this.data[this.keyValue['CHECKEDPLACEHOLDERFOURTH']] && !this.form.get('placeholderFourth')?.value)
    ) {
      return false;
    } else {
      return true;
    }
  }

  constructor(
    private _appService: ApplicationService,
    private _accountService: AccountService,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _sceneService: SceneService,
    private _cd: ChangeDetectorRef,
    private _ngZone: NgZone,
  ) {}

  async ngOnInit(): Promise<void> {
    this.email = this._accountService.getEmail();

    this._appService.viewManagerSubject.pipe(untilDestroyed(this)).subscribe((viewManager) => {
      this.sceneId = viewManager?.sceneModel.Id;
    });

    this._appService.viewElementsSubject.pipe(untilDestroyed(this)).subscribe((elements) => {
      this.selectedElement = elements.find(({ id }) => id === this.selectedElement?.id) as TInteractableElement;
    });
  }

  ngAfterViewInit() {
    if (!!+this.data[this.keyValue['CHECKEDPLACEHOLDERFIRST']]) {
      this.form.get('placeholderFirst')?.addValidators(Validators.required);
    }
    if (!!+this.data[this.keyValue['CHECKEDPLACEHOLDERSECOND']]) {
      this.form.get('placeholderSecond')?.addValidators(Validators.required);
    }
    if (!!+this.data[this.keyValue['CHECKEDPLACEHOLDERTHIRD']]) {
      this.form.get('placeholderThird')?.addValidators(Validators.required);
    }
    if (!!+this.data[this.keyValue['CHECKEDPLACEHOLDERFOURTH']]) {
      this.form.get('placeholderFourth')?.addValidators(Validators.required);
    }
    this.form.updateValueAndValidity();
    this._cd.markForCheck();
  }

  modalClose() {
    this.modalRef.close(false);
  }

  onCheckedChange(result: any, key: string) {
    this.data[this.keyValue[key]] = result;
    switch (key) {
      case 'ALRAMOPTIONALLCHECK':
        if (this.data[this.keyValue[key]] == '1') {
          this.data[this.keyValue['ALRAMOPTIONOWNXR']] = '1';
          this.data[this.keyValue['ALRAMOPTIONEMAIL']] = '1';
        } else {
          this.data[this.keyValue['ALRAMOPTIONOWNXR']] = '0';
          this.data[this.keyValue['ALRAMOPTIONEMAIL']] = '0';
        }
        break;

      case 'ALRAMOPTIONOWNXR':
        this.data[this.keyValue['ALRAMOPTIONALLCHECK']] = '0';
        break;

      case 'ALRAMOPTIONEMAIL':
        this.data[this.keyValue['ALRAMOPTIONALLCHECK']] = '0';
        break;
    }
  }

  isRequired(value: string): boolean {
    return !!value;
  }

  onPreviewSave() {
    const modalRef = this._modalService.open(MobileInformationComponent);
    if (modalRef) {
      modalRef.instance.message = this.checkedAgree
        ? this.data[this.keyValue['SUCCESSMESSAGE']]
          ? this.data[this.keyValue['SUCCESSMESSAGE']]
          : this._translateService.instant('community.view.contactForm.appreciate')
        : this._translateService.instant('community.view.contactForm.checkAgree');

      this._ngZone.runOutsideAngular(() => {
        interval(2000)
          .pipe(take(1), untilDestroyed(this))
          .subscribe(() => {
            if (modalRef) {
              this._modalService.close(false, modalRef.instance['modalContainerRef']);
              this._ngZone.run(() => {});
            }
          });
      });
    }

    if (this.checkedAgree) {
      this.addComment(this.form.getRawValue());
      this.modalRef.close(true);
    }
  }

  showTermsConditions(): void {
    const modalRef = this._modalService.open(MobileTermsAndConditionsComponent);
    if (modalRef) {
      modalRef.instance.modalContainerRef = modalRef.modalContainerRef;
    }
  }

  async addComment(data: any) {
    if (!(this.sceneId && this.selectedElement)) {
      return;
    }
    const interaction = new XRSceneInteraction(this.sceneId, this.selectedElement.id, SceneInteractionType.Contact, {
      PlaceholderFirst: data['placeholderFirst'],
      PlaceholderSecond: data['placeholderSecond'],
      PlaceholderThird: data['placeholderThird'],
      PlaceholderFourth: data['placeholderFourth'],
      IsRead: 'false',
      IsDelete: 'false',
      Email: this.email['__zone_symbol__value'],
    });
    await this._sceneService.postInteraction(interaction);
  }
}
