import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'ui-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent implements OnInit {
  @ViewChild('input') inputEl;
  @Input() editMode = false;
  @Input() lengthLimit = 0;
  @Input() colour = 'var(--white)';
  @Input() bgColour = 'var(--grey-500)';
  _tagsString: string;
  @Input() set tagsString(value: string) {
    this._tagsString = value || '';
    this.tagsArray = this.getTags();
  }

  @Output() tagSelected: EventEmitter<string> = new EventEmitter();
  @Output() tagsChanged: EventEmitter<string> = new EventEmitter();

  tagsArray: string[] = [];
  tag: string;
  onChange = false;

  get displayedTags() {
    return this.tagsArray.map((tag) =>
      this.lengthLimit > 0 && tag.length > this.lengthLimit ? tag.slice(0, this.lengthLimit - 1) + '...' : tag,
    );
  }

  constructor() {}

  ngOnInit(): void {}

  getTags() {
    const result: string[] = [];
    const tags = this._tagsString?.split(/[,]/).filter((t) => !!t) ?? [];
    for (let i = 0; i < tags.length; i++) {
      result.push(tags[i]);
    }
    return result;
  }

  changeArraytoString() {
    this._tagsString = '';
    for (let index = 0; index < this.tagsArray.length; index++) {
      this._tagsString += this.tagsArray[index].replace('#', '') + ',';
    }
  }

  selectTag(tag: string) {
    this.tagSelected.emit(tag);
  }

  checkValidity(value: string) {
    this.tag = value.replace(/[^a-z0-9\u3131-\uD79D_, \_\-\.]/gi, '');
    this.inputEl.nativeElement.value = this.tag;
  }

  appendTag(input: HTMLInputElement) {
    if (this.tag !== '' && !input.classList.contains('ng-invalid')) {
      this.tagsArray.push(this.tag);
      this.tag = '';
      this.changeArraytoString();
      this.tagsChanged.emit(this._tagsString);
    }
  }

  deleteTag(tagIndex: number, event) {
    event.preventDefault();
    event.stopPropagation();
    this.tagsArray.splice(tagIndex, 1);
    this.changeArraytoString();
    this.tagsChanged.emit(this._tagsString);
  }

  onKeydown(event: any, input: HTMLInputElement) {
    {
      if (event.code === 'Enter' || event.code === 'Space' || event.code === 'Comma') {
        event.preventDefault();
        event.stopPropagation();
        this.appendTag(input);
      }
    }
  }
}
