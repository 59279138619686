import moment from 'moment/moment';
import { IModel, ModelType } from './base';
import { privateFieldReplacer } from '../../utils/json-utils';
import { ScenePlan } from '../../enums/pricing-plan';
import { Permission } from '../../enums/permission';
import { SceneInteractionType } from '../../enums/scene-interaction';
import { UserScenes } from './user-scene';
import { UserRoles } from './account';
import { TElement } from '../../view-manager';

/**
 * API Interface of a scene
 */
export interface IScene extends IModel {
  Name: string;
  SceneElements: any[];
  Tags: string;
  Thumbnail: string;
  DateCreated: string;
  DateModified: string;
}

export interface CreatorUser {
  Alias: string;
  AvatarId: string;
  DisplayName: string;
  Id: string;
  Server: 'kr' | 'us';
  Subject: string;
  Thumbnail: string;
  UserRoles: UserRoles[];
  UserScenes: XRScene[];
}

export enum InteractionEmotion {
  None = '',
  Happy = 'happy',
  Surprise = 'surprise',
  Congrats = 'congrats',
  Sad = 'sad',
  Awesome = 'awesome',
}

export class XRSceneInteraction {
  constructor(
    public SceneId: string,
    public SceneElementId: string,
    public InteractionType: SceneInteractionType,
    public Interaction: { [key: string]: string },
  ) {}

  public Id!: string;
  public Username!: string;
  public Thumbnail?: string;
  public Date!: string;
  public Emoji!: string;
}

/**
 * Defines a scene
 */
export class XRScene implements IScene {
  private _elements: TElement[];
  private _plan: ScenePlan;
  private _permission: Permission;
  private _dateModified: string;
  private _dateCreated: string;
  private _username!: string;
  // private _loadingProgress!: number;
  private _sceneId: string;
  private _referencedByteLength: number;
  private _views: number;
  private _likes: number;
  private _favicon: string;
  private _creatorUser: CreatorUser | undefined;
  private _expires: string | undefined;
  private _users: UserScenes[];
  private _isExpired = false;
  private _isPublished: boolean;
  private _isAccessible: boolean;

  constructor(
    SceneElements: TElement[],
    public Name: string,
    public Description: string,
    public Thumbnail: string,
    public Company: string,
    public BrandId: string,
    public ThemeId: string,
    public Id: string,
    public Tags: string,
    public DefaultVersion: string,
    Plan: ScenePlan,
    Permission: Permission,
    DateModified: string,
    DateCreated: string,
    Username: string,
    SceneId: string,
    ReferencedByteLength: number,
    Likes: number,
    Views: number,
    public Favicon: string,
    Users: UserScenes[],
    IsPublished: boolean,
    IsAccessible: boolean,
    public CreatorUserId?: string,
    CreatorUser?: CreatorUser,
    Expires?: string,
    public RecommendedSortOrder?: number,
    public DisplayConcurrentUsers?: boolean,
    public ChatOn?: boolean,
    public Interactions?: XRSceneInteraction[],
  ) {
    this._elements = SceneElements;
    this._plan = Plan;
    this._permission = Permission;
    this._dateModified = DateModified;
    this._dateCreated = DateCreated;
    this._username = Username;
    this._sceneId = SceneId;
    this._referencedByteLength = ReferencedByteLength;
    this._views = Views;
    this._likes = Likes;
    this._favicon = Favicon;
    this._creatorUser = CreatorUser;
    this._expires = Expires;
    this._users = Users;
    this._isExpired = Expires && moment(Expires)?.unix() <= moment().unix() ? true : false;
    this._isPublished = IsPublished;
    this._isAccessible = IsAccessible;
  }
  get ModelId(): string {
    return this.SceneId ?? this.Id;
  }

  public get Users(): UserScenes[] {
    return this._users;
  }

  public get isExpired(): boolean {
    return this._isExpired;
  }

  public get SceneElements() {
    return this._elements;
  }

  public get ReferencedByteLength() {
    return this._referencedByteLength;
  }

  public set ReferencedByteLength(value: number) {
    this._referencedByteLength = value;
  }

  public get Views() {
    return this._views;
  }

  public get Likes() {
    return this._likes;
  }

  public set Likes(value: number) {
    this._likes = value;
  }

  public get SceneId(): string {
    return this._sceneId;
  }

  public get Mode(): number {
    return this.Plan == ScenePlan.Free ? 0 : 1;
  }

  init: () => void = () => {};

  get CategoryId() {
    return this.ThemeId ?? '';
  }

  set CategoryId(value: string) {
    this.ThemeId = value;
  }

  public get Readonly() {
    return false;
  }

  public get GeometryPath(): string {
    return '';
  }

  public get Plan(): ScenePlan {
    return this._plan;
  }

  public set Plan(plan: ScenePlan) {
    this._plan = plan;
  }

  public get Permission(): Permission {
    return this._permission;
  }

  public get IsPublished(): boolean {
    return this._isPublished;
  }

  public get IsAccessible(): boolean {
    return this._isAccessible;
  }

  public set IsAccessible(isAccessible: boolean) {
    this._isAccessible = isAccessible;
  }
  public get DateModified(): string {
    return this._dateModified;
  }

  public get DateCreated(): string {
    return this._dateCreated;
  }

  public get Username() {
    return this._username;
  }

  public get CreatorUser() {
    return this._creatorUser;
  }

  public get Expires() {
    return this._expires;
  }

  get ModelType() {
    return ModelType.Scene;
  }

  toJson() {
    return JSON.stringify(this, privateFieldReplacer);
  }

  setId(id: string): void {
    this.Id = id;
  }

  setPropertyValue(key: string, value: any) {
    this[key] = value;
  }
}

export interface PopupContentUsage {
  SceneId: string;
  SceneName: string;
  TextCount: number;
  VideoCount: number;
  ContactCount: number;
  GalleryCount: number;
  LinkCount: number;
  GuestBookCount: number;
  GoogleFormCount: number;
}
