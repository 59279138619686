@if (effect) {
  <ui-panel [title]="'sidenav.effect' | translate" [collapsing]="true" [panelId]="panelMask.Effect">
    <div class="tool-header">
      <div class="tool-header-title">
        <span class="icon icon-arrow-down"></span>
        {{ 'oxr.creatingSpace.panels.effect.options.' + effect.type | translate | uppercase }}
      </div>
      <div class="tool-header-action"></div>
    </div>
    <div class="tool-body">
      <ui-slider
        [label]="'oxr.creatingSpace.panels.effect.speed' | translate"
        [value]="parameters.speed"
        [options]="speedOptions"
        (onChangeEnd)="onOptionChange($event, 'speed')"
        (userChangeEnd)="onChangeEnd()"
        [disabled]="_isScenePlanPropertyRestriction"
      ></ui-slider>
      @switch (effect.type) {
        @case (effectType.Bounce) {
          <div class="separator"></div>
          <ui-slider
            [label]="'oxr.creatingSpace.panels.effect.height' | translate"
            [value]="parameters.height"
            [options]="bounceHeightOptions"
            (onChangeEnd)="onOptionChange($event, 'height')"
            (userChangeEnd)="onChangeEnd()"
            [disabled]="_isScenePlanPropertyRestriction"
          ></ui-slider>
        }
        @case (effectType.Inflate) {
          <div class="separator"></div>
          <ui-slider
            [label]="'oxr.creatingSpace.panels.effect.size' | translate"
            [value]="parameters.percent"
            [options]="inflatePercentOptions"
            (onChangeEnd)="onOptionChange($event, 'percent')"
            (userChangeEnd)="onChangeEnd()"
            [disabled]="_isScenePlanPropertyRestriction"
          ></ui-slider>
        }
        @case (effectType.Roll) {
          <div class="separator"></div>
          <ui-slider
            [label]="'oxr.creatingSpace.panels.effect.distance' | translate"
            [value]="parameters.distance"
            [options]="rollDistanceOptions"
            (onChangeEnd)="onOptionChange($event, 'distance')"
            (userChangeEnd)="onChangeEnd()"
            [disabled]="_isScenePlanPropertyRestriction"
          ></ui-slider>
          <div class="separator"></div>
          <ui-slider
            [label]="'oxr.creatingSpace.panels.effect.direction' | translate"
            [value]="parameters.direction"
            [options]="rollDirectionOptions"
            (onChangeEnd)="onOptionChange($event, 'direction')"
            (userChangeEnd)="onChangeEnd()"
            [disabled]="_isScenePlanPropertyRestriction"
          ></ui-slider>
        }
        @case (effectType.Shine) {
          <div class="separator"></div>
          <ui-color-picker
            [label]="'oxr.creatingSpace.panels.common.color' | translate"
            [defaultColor]="shineColor"
            (changeEnd)="onOptionChange($event, 'color')"
            [showTransparency]="false"
          ></ui-color-picker>
        }
        @case (effectType.Move) {
          <div class="direction-container">
            <span>{{ 'oxr.creatingSpace.panels.effect.mode' | translate }}</span>
            <div class="directions">
              <div
                class="direction"
                [class.selected]="parameters.mode === moveMode.SideToSide"
                (click)="parameters.mode = moveMode.SideToSide"
              >
                <div class="icon icon-left-to-right"></div>
              </div>
              <div
                class="direction"
                [class.selected]="parameters.mode === moveMode.ForwardBack"
                (click)="parameters.mode = moveMode.ForwardBack"
              >
                <div class="icon icon-front-to-back"></div>
              </div>
              <div class="direction disabled" [class.selected]="parameters.mode === 'circle'" (click)="parameters.mode = 'circle'">
                <div class="icon icon-circle-movement"></div>
              </div>
              <div class="direction disabled" [class.selected]="parameters.mode === 'z'" (click)="parameters.mode = 'z'">
                <div class="icon icon-z-movement"></div>
              </div>
            </div>
          </div>
          <div class="separator"></div>
          <ui-slider
            [label]="'oxr.creatingSpace.panels.effect.distance' | translate"
            [value]="parameters.distance"
            [options]="rollDistanceOptions"
            (onChangeEnd)="onOptionChange($event, 'distance')"
            (userChangeEnd)="onChangeEnd()"
            [disabled]="_isScenePlanPropertyRestriction"
          ></ui-slider>
        }
      }
      <div class="separator"></div>
      <ui-radio-group
        [label]="'oxr.creatingSpace.panels.effect.actionSettings' | translate"
        [options]="effectActionOptions"
        [value]="effect.trigger"
        (onChecked)="onTriggerChange($event)"
      ></ui-radio-group>
      <div class="separator"></div>
      <ui-switch
        class="icon-style-switch"
        [checked]="parameters.activeOnEdit"
        [disabled]="true"
        [label]="'oxr.creatingSpace.panels.effect.activeOnEdit' | translate"
        [onShowContentMode]="false"
        (changed)="parameters.activeOnEdit = $event"
      ></ui-switch>
    </div>
  </ui-panel>
}
