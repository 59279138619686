import { Component, OnInit, Type, ViewChild } from '@angular/core';
import { ModalDirective } from './modal.directive';

@Component({
  selector: 'ui-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @ViewChild(ModalDirective, { static: true }) modalContainerRef!: ModalDirective;
  componentRef;

  hasBackdrop = true;
  closeOnBackdropClick = true;
  closeOnDestroy = true;
  allowHeaderClick = false;
  allowBackgroundClick = false;
  onClose?: () => void;
  zIndex = 5000;

  constructor() {}

  ngOnInit(): void {}

  loadModal<T>(modal: Type<T>, componentOptions = {}) {
    this.componentRef = this.modalContainerRef.viewContainerRef.createComponent<T>(modal);
    for (const [key, value] of Object.entries(componentOptions)) {
      this.componentRef.instance[key] = value;
    }
    return this.componentRef;
  }

  onContainerClick(event: any) {
    if (this.closeOnBackdropClick && event.target?.classList?.contains('backdrop')) {
      event.preventDefault();
      event.stopPropagation();
      this.componentRef.instance.close();
    }
  }
}
