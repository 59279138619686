import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, catchError, lastValueFrom, throwError } from 'rxjs';
import { environment } from '@/app/src/environments/environment';
import { ScenePlan } from '@/data/src/lib/enums/pricing-plan';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { ConfirmationComponent } from '@/ui/src/lib/modal/confirmation/confirmation.component';
import { InformationComponent } from '@/ui/src/lib/modal/information/information.component';
import { FileType, MediaType } from '../enums/file-type';
import { ModelType } from '../models/data/base';
import { AccountService } from './account.service';
import { BaseApiService } from './base-api.service';
import { AllocatedMedia, IntegratedSceneStorageUsageInfo, SceneFile, SeparateSceneStorageUsageInfo } from '../models/data/scene-file';
import { FileReference } from '../models/data/file-reference';
import { FileService } from './file.service';
import { Access, FileStorageType } from '../enums/access';
import { convertBytesToGBOrMBOrKB } from '../utils/convert-utils';
import { PricingPlanService } from './pricing-plan.service';

@Injectable({
  providedIn: 'root',
})
export class SceneFileService extends BaseApiService<SceneFile> {
  private allocatedMediaSubject: BehaviorSubject<AllocatedMedia | null> = new BehaviorSubject<AllocatedMedia | null>(null);
  allocatedMedia$ = this.allocatedMediaSubject.asObservable();

  private noAuthHttp: HttpClient;

  public freeSpaceMediaLimitCapacity = this._pricingPlanService.scenePlans?.Free.MediaStorageCapacity!;
  public proSpaceMediaLimitCapacity = this._pricingPlanService.scenePlans?.Pro.MediaStorageCapacity!;
  public fileConditions = {
    [FileType.Image]: {
      accept: 'image/jpeg, image/png, image/gif',
      types: ['image/jpeg', 'image/png', 'image/gif'],
      maxSize: 100000000,
      info: {
        textDrop: 'oxr.creatingSpace.gallery.fileDrop',
        textRequirements1: 'oxr.creatingSpace.gallery.fileFormat',
        textRequirements2: 'oxr.creatingSpace.gallery.fileRequirements',
      },
    },
    [FileType.Video]: {
      accept: 'video/mp4, application/x-mpegURL, video/MP2T, video/x-flv, video/3gpp, video/quicktime, video/x-msvideo, video/x-ms-wmv',
      types: ['video/mp4'],
      maxSize: 100000000,
      info: {
        textDrop: 'oxr.creatingSpace.video.fileDrop',
        textRequirements1: 'oxr.creatingSpace.video.fileJPGOnly',
        textRequirements2: 'oxr.creatingSpace.video.fileRequirements',
      },
    },
  };

  constructor(
    public http: HttpClient,
    public accountService: AccountService,
    private handler: HttpBackend,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _fileService: FileService,
    private _pricingPlanService: PricingPlanService,
  ) {
    super(http, accountService, ModelType.SceneFile);
    this.noAuthHttp = new HttpClient(this.handler);
  }

  /**
   * Gets the allocated media space for the scene
   *
   * @param sceneId Id of the scene
   * @returns The allocated media space
   */
  async getAllocatedMediaFiles(sceneId: string): Promise<AllocatedMedia> {
    try {
      const response = await lastValueFrom(
        this.http
          .get<AllocatedMedia>(`${environment[this.accountService.region].crudApiUrl}/SceneFile/UserSceneFilesSpace/${sceneId}`)
          .pipe(
            catchError((error) => {
              if (error.status === 0) {
                console.error('Network connection error:', error.message);
                // Network connection error handling
                return throwError('Network connection error. Please check your internet connection.');
              }
              // Handling other HTTP errors
              console.error('HTTP request error:', error);
              return throwError('An error occurred while processing your request. Please try again later.');
            }),
          ),
      );

      this.allocatedMediaSubject.next(response);

      return response;
    } catch (error) {
      // Handling other exceptional situations
      console.error('Unexpected error:', error);
      throw new Error('An unexpected error occurred. Please try again later.');
    }
  }

  /**
   * Checks the storage limit for scene media files.
   *
   * @param activeScene The active XR scene for which the storage limit needs to be checked.
   * @param toBeUploadedFiles An array of files to be uploaded to the scene's media storage.
   * @returns A Promise that resolves to a boolean indicating whether the storage limit is valid or not.
   */
  private async checkSceneMediaStorageLimit(activeScene: XRScene | undefined, toBeUploadedFiles: File[]): Promise<boolean> {
    const selectedAccountOption = this.accountService.currentAccountOption.getValue();
    const toBeUploadedFilesSize = toBeUploadedFiles.reduce((accumulator, file) => accumulator + file.size, 0);
    const allocatedMedia = await this.getAllocatedMediaFiles(activeScene?.Id!);
    const currentUsedSpace = allocatedMedia?.TotalUsedSpace ?? 0;
    const totalSize = currentUsedSpace + toBeUploadedFilesSize;
    let isValidStorageSize = false;

    if (activeScene?.Plan === ScenePlan.Enterprise) {
      isValidStorageSize =
        !selectedAccountOption?.EnterpriseContract?.MediaStorageCapacity ||
        totalSize < selectedAccountOption?.EnterpriseContract?.MediaStorageCapacity!;
    } else {
      isValidStorageSize =
        activeScene?.Plan === ScenePlan.Pro ? totalSize < this.proSpaceMediaLimitCapacity : totalSize < this.freeSpaceMediaLimitCapacity;
    }

    if (!isValidStorageSize) {
      let overSize = 0;
      if (activeScene?.Plan === ScenePlan.Enterprise) {
        overSize = totalSize - selectedAccountOption?.EnterpriseContract?.MediaStorageCapacity!;
      } else {
        overSize =
          activeScene?.Plan === ScenePlan.Pro ? totalSize - this.proSpaceMediaLimitCapacity : totalSize - this.freeSpaceMediaLimitCapacity;
      }

      const overSizeInMB = Math.floor(overSize / (1024 * 1024));
      const modal = this._modalService.open(ConfirmationComponent);
      if (modal) {
        modal.instance.title = this._translateService.instant('shared.confirmation.warning');
        modal.instance.body = this._translateService.instant('shared.confirmation.notEnoughSpace', { size: overSizeInMB });
        modal.instance.confirmAction = this._translateService.instant('shared.confirmation.confirm');
        modal.instance.showCancelAction = false;
      }
    }

    return isValidStorageSize;
  }

  /**
   * Checks if the provided file is valid for uploading based on the specified upload type.
   *
   * @param file The file to be checked for validity.
   * @param uploadType The type of upload for which the file validity needs to be checked.
   * @returns A boolean indicating whether the file is valid for uploading or not.
   */
  private checkValidFile(file: File, uploadType: FileType): boolean {
    const conditions = this.fileConditions[uploadType];

    if (!conditions) return false;

    const { types, maxSize } = conditions;

    // Check if the file type and size meet the conditions
    const isValidType = types.includes(file.type);
    const isValidSize = file.size <= maxSize;

    if (!isValidType) {
      console.log('Type Error', file.type);
    }
    if (!isValidSize) {
      console.log('Size Error', file.size);
    }
    return isValidType && isValidSize;
  }

  /**
   * Uploads files to the server for the given scene, checking storage limits and file validity.
   *
   * @param activeScene The XRScene object representing the scene to which the files will be uploaded.
   * @param toBeUploadedFiles An array of File objects representing the files to be uploaded.
   * @param fileType The type of files being uploaded.
   */
  async uploadFiles(activeScene: XRScene | undefined, toBeUploadedFiles: File[], fileType: FileType) {
    try {
      const isValidStorageSize = await this.checkSceneMediaStorageLimit(activeScene, toBeUploadedFiles);

      if (isValidStorageSize) {
        const promises = toBeUploadedFiles.map(async (file: File) => {
          try {
            const isValidFile = this.checkValidFile(file, fileType);

            if (!isValidFile) throw Error('Invalid file type');

            const fileRef = new FileReference('', file.name, '', '', fileType, '', 0, '', '');

            const { Id, name, url, access, type, size, thumbnailUrl, refFileType, refMediaType } = await this._fileService.uploadFile(
              file,
              FileStorageType.Public,
              (error, progress) => {
                fileRef.LoadingProgress = progress;
              },
              file.name,
              true,
              fileType,
              activeScene?.Id,
              Access.Public,
              MediaType.MediaTab,
            );
            return { succeeded: true };
          } catch (err) {
            return { succeeded: false };
          }
        });

        const results = await Promise.all(promises);
        const succeededFiles = results.filter((result) => result.succeeded).length;
        const errorFiles = results.length - succeededFiles;

        if (succeededFiles) {
          await this.getAllocatedMediaFiles(activeScene?.Id!);
        }

        if (errorFiles) {
          const modal = this._modalService.open(InformationComponent);
          if (modal) {
            modal.instance.classList = 'warning-message';
            modal.instance.message = this._translateService.instant(`shared.information.multipleFilesInvalidFormat.${fileType}`, {
              n: errorFiles,
            });
          }
        }
      }
    } catch (err) {
      console.error('Error while checking storage limit:', err);
    }
  }

  /**
   * Retrieves the storage capacity limit for the given XRScene.
   * If the scene's plan is Enterprise, returns the storage capacity of the current selected account option's Enterprise.
   * Otherwise, if the plan is Pro, returns proSpaceMediaLimitCapacity; otherwise, returns freeSpaceMediaLimitCapacity.
   * @param activeScene The currently active XRScene object.
   * @returns The storage capacity limit.
   */
  getSpaceMediaLimitCapacity(activeScene: XRScene): number {
    if (activeScene.Plan === ScenePlan.Enterprise) {
      const selectedAccountOption = this.accountService.currentAccountOption.getValue();
      return selectedAccountOption?.EnterpriseContract?.MediaStorageCapacity!;
    } else {
      return activeScene.Plan === ScenePlan.Pro ? this.proSpaceMediaLimitCapacity : this.freeSpaceMediaLimitCapacity;
    }
  }

  /**
   * Retrieves separate scene storage usage information for the given XRScene and allocated media.
   * @param activeScene The currently active XRScene object.
   * @param allocatedMedia Allocated media information.
   * @returns Separate scene storage usage information.
   */
  getSeparateSceneStorageUsageInfo(activeScene: XRScene, allocatedMedia: AllocatedMedia): SeparateSceneStorageUsageInfo {
    const TotalAvailableStorage = this.getSpaceMediaLimitCapacity(activeScene);
    const ImagePercentage = TotalAvailableStorage ? (allocatedMedia.ImagesSpace / TotalAvailableStorage) * 100 : 0;
    const VideoPercentage = TotalAvailableStorage ? (allocatedMedia.VideosSpace / TotalAvailableStorage) * 100 : 0;
    const TotalUsedSpace = convertBytesToGBOrMBOrKB(allocatedMedia.TotalUsedSpace, 1);
    const TotalSpace = TotalAvailableStorage ? convertBytesToGBOrMBOrKB(TotalAvailableStorage, 1) : undefined;

    return { ImagePercentage, VideoPercentage, TotalUsedSpace, TotalSpace };
  }

  /**
   * Retrieves integrated scene storage usage information for the given XRScene and allocated media.
   * @param activeScene The currently active XRScene object.
   * @param allocatedMedia Allocated media information.
   * @returns Integrated scene storage usage information.
   */
  getIntegratedSceneStorageUsageInfo(activeScene: XRScene, allocatedMedia: AllocatedMedia): IntegratedSceneStorageUsageInfo {
    const TotalAvailableStorage = this.getSpaceMediaLimitCapacity(activeScene);
    const TotalUsedSpacePercentage = TotalAvailableStorage
      ? ((allocatedMedia ? allocatedMedia.TotalUsedSpace : 0) / TotalAvailableStorage) * 100
      : 0;
    const TotalUsedSpace = convertBytesToGBOrMBOrKB(allocatedMedia ? allocatedMedia.TotalUsedSpace : 0, 1);
    const TotalSpace = TotalAvailableStorage ? convertBytesToGBOrMBOrKB(TotalAvailableStorage, 1) : undefined;

    return { TotalUsedSpacePercentage, TotalUsedSpace, TotalSpace };
  }
}
