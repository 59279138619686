export enum PricingPlan {
  Scene = 'Scene',
  Editor = 'Editor',
}

export enum ScenePlan {
  Free = 'Free',
  Pro = 'Pro',
  Enterprise = 'Enterprise',
}

export enum ScenePlanDetail {
  IsSavePointEnabled = 'IsSavePointEnabled',
  SavepointPeriod = 'SavepointPeriod',
  MediaStorageCapacity = 'MediaStorageCapacity',
  UploadAssetUsageLimit = 'UploadAssetUsageLimit',
  RestrictedContentWithinSpaceUsage = 'RestrictedContentWithinSpaceUsage',
  PublishSpace = 'PublishSpace',
  LiveChat = 'LiveChat',
  ConnectOwnXrSubdomain = 'ConnectOwnXrSubdomain',
  ConnectOwnedDomain = 'ConnectOwnedDomain',
  OwnXrAdDisplay = 'OwnXrAdDisplay',
}
