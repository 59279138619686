import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment/moment';

@Pipe({
  name: 'dateDiff',
})
export class DateDiffPipe implements PipeTransform {
  constructor() {}

  transform(value: moment.MomentInput, lang = 'en') {
    const diff = moment.utc(value).local().locale(lang).fromNow();
    return diff;
  }
}
