<div class="popup-content-modal">
  <section class="modal-header">
    <div class="header-title">{{ data['title'] || 'shared.fields.titlePlaceholder' | translate }}</div>
    <div class="close icon-wrapper" (click)="closeModal()">
      <div class="icon icon-close sm"></div>
    </div>
  </section>
  <div class="modal-body">
    @if (isEditMode) {
      <div class="title">
        <input
          uiInput
          fixed
          type="text"
          placeholder="{{ 'oxr.creatingSpace.contactForm.contactFormTitle' | translate }}"
          [(ngModel)]="data[keyValue['TITLE']]"
        />
      </div>
      <div class="contact-form">
        <div class="left">
          <div class="row">
            <div class="col-1">
              <div class="font-500 text-18">{{ 'oxr.creatingSpace.contactForm.enterPlaceholder' | translate }}</div>
            </div>
            <div class="col-2">
              <div class="font-500 text-16">{{ 'oxr.creatingSpace.contactForm.required' | translate }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-1">
              <div class="input-wrapper" [class.edit]="!!formAction['PLACEHOLDERFIRST'].editMode">
                <input
                  type="text"
                  placeholder="{{ 'shared.fields.name' | translate }}"
                  [(ngModel)]="data[keyValue['PLACEHOLDERFIRST']]"
                  uiAutofocus
                  [disabled]="!formAction['PLACEHOLDERFIRST'].editMode"
                />
                <div class="action-button-group">
                  @if (formAction['PLACEHOLDERFIRST'].editMode) {
                    <div (click)="cancelEdit('PLACEHOLDERFIRST')">{{ 'shared.actions.cancel' | translate }}</div>
                    <div class="divider"></div>
                    <div (click)="confirmEdit('PLACEHOLDERFIRST')">{{ 'shared.confirmation.confirm' | translate }}</div>
                  }
                  @if (!formAction['PLACEHOLDERFIRST'].editMode) {
                    <div (click)="enableEditMode('PLACEHOLDERFIRST')">{{ 'shared.actions.edit' | translate }}</div>
                  }
                </div>
              </div>
            </div>
            <div class="col-2">
              <ui-check-box
                class="selection-checkbox white-grey-500 md"
                [checked]="!!data[keyValue['CHECKEDPLACEHOLDERFIRST']] && data[keyValue['CHECKEDPLACEHOLDERFIRST']] !== '0'"
                (checkedChange)="onCheckedChange($event, 'CHECKEDPLACEHOLDERFIRST')"
              ></ui-check-box>
            </div>
          </div>
          <div class="row">
            <div class="col-1">
              <div class="input-wrapper" [class.edit]="!!formAction['PLACEHOLDERSECOND'].editMode">
                <input
                  type="text"
                  placeholder="{{ 'shared.fields.contact' | translate }}"
                  [(ngModel)]="data[keyValue['PLACEHOLDERSECOND']]"
                  uiAutofocus
                  [disabled]="!formAction['PLACEHOLDERSECOND'].editMode"
                />
                <div class="action-button-group">
                  @if (formAction['PLACEHOLDERSECOND'].editMode) {
                    <div (click)="cancelEdit('PLACEHOLDERSECOND')">{{ 'shared.actions.cancel' | translate }}</div>
                    <div class="divider"></div>
                    <div (click)="confirmEdit('PLACEHOLDERSECOND')">{{ 'shared.confirmation.confirm' | translate }}</div>
                  }
                  @if (!formAction['PLACEHOLDERSECOND'].editMode) {
                    <div (click)="enableEditMode('PLACEHOLDERSECOND')">{{ 'shared.actions.edit' | translate }}</div>
                  }
                </div>
              </div>
            </div>
            <div class="col-2">
              <ui-check-box
                class="selection-checkbox white-grey-500 md"
                [checked]="!!data[keyValue['CHECKEDPLACEHOLDERSECOND']] && data[keyValue['CHECKEDPLACEHOLDERSECOND']] !== '0'"
                (checkedChange)="onCheckedChange($event, 'CHECKEDPLACEHOLDERSECOND')"
              ></ui-check-box>
            </div>
          </div>
          <div class="row">
            <div class="col-1">
              <div class="input-wrapper" [class.edit]="!!formAction['PLACEHOLDERTHIRD'].editMode">
                <input
                  type="text"
                  placeholder="{{ 'shared.fields.email' | translate }}"
                  [(ngModel)]="data[keyValue['PLACEHOLDERTHIRD']]"
                  uiAutofocus
                  [disabled]="!formAction['PLACEHOLDERTHIRD'].editMode"
                />
                <div class="action-button-group">
                  @if (formAction['PLACEHOLDERTHIRD'].editMode) {
                    <div (click)="cancelEdit('PLACEHOLDERTHIRD')">{{ 'shared.actions.cancel' | translate }}</div>
                    <div class="divider"></div>
                    <div (click)="confirmEdit('PLACEHOLDERTHIRD')">{{ 'shared.confirmation.confirm' | translate }}</div>
                  }
                  @if (!formAction['PLACEHOLDERTHIRD'].editMode) {
                    <div (click)="enableEditMode('PLACEHOLDERTHIRD')">{{ 'shared.actions.edit' | translate }}</div>
                  }
                </div>
              </div>
            </div>
            <div class="col-2">
              <ui-check-box
                class="selection-checkbox white-grey-500 md"
                [checked]="!!data[keyValue['CHECKEDPLACEHOLDERTHIRD']] && data[keyValue['CHECKEDPLACEHOLDERTHIRD']] !== '0'"
                (checkedChange)="onCheckedChange($event, 'CHECKEDPLACEHOLDERTHIRD')"
              ></ui-check-box>
            </div>
          </div>
          <div class="row">
            <div class="col-1">
              <div class="input-wrapper" [class.edit]="!!formAction['PLACEHOLDERFOURTH'].editMode">
                <input
                  type="text"
                  placeholder="{{ 'shared.fields.contentOfInquiry' | translate }}"
                  [(ngModel)]="data[keyValue['PLACEHOLDERFOURTH']]"
                  uiAutofocus
                  [disabled]="!formAction['PLACEHOLDERFOURTH'].editMode"
                />
                <div class="action-button-group">
                  @if (formAction['PLACEHOLDERFOURTH'].editMode) {
                    <div (click)="cancelEdit('PLACEHOLDERFOURTH')">{{ 'shared.actions.cancel' | translate }}</div>
                    <div class="divider"></div>
                    <div (click)="confirmEdit('PLACEHOLDERFOURTH')">{{ 'shared.confirmation.confirm' | translate }}</div>
                  }
                  @if (!formAction['PLACEHOLDERFOURTH'].editMode) {
                    <div (click)="enableEditMode('PLACEHOLDERFOURTH')">{{ 'shared.actions.edit' | translate }}</div>
                  }
                </div>
              </div>
            </div>
            <div class="col-2">
              <ui-check-box
                class="selection-checkbox white-grey-500 md"
                [checked]="!!data[keyValue['CHECKEDPLACEHOLDERFOURTH']] && data[keyValue['CHECKEDPLACEHOLDERFOURTH']] !== '0'"
                (checkedChange)="onCheckedChange($event, 'CHECKEDPLACEHOLDERFOURTH')"
                [disabled]="true"
              ></ui-check-box>
            </div>
          </div>
          <div class="font-500 text-18 row mt-76">
            <div>{{ 'oxr.creatingSpace.contactForm.successMessage' | translate }}</div>
          </div>
          <div class="row">
            <div class="col-1">
              <div class="input-wrapper" [class.edit]="!!formAction['SUCCESSMESSAGE'].editMode">
                <input
                  type="text"
                  placeholder="{{ 'shared.fields.thankYou' | translate }}"
                  [(ngModel)]="data[keyValue['SUCCESSMESSAGE']]"
                  uiAutofocus
                  [disabled]="!formAction['SUCCESSMESSAGE'].editMode"
                />
                <div class="action-button-group">
                  @if (formAction['SUCCESSMESSAGE'].editMode) {
                    <div (click)="cancelEdit('SUCCESSMESSAGE')">{{ 'shared.actions.cancel' | translate }}</div>
                    <div class="divider"></div>
                    <div (click)="confirmEdit('SUCCESSMESSAGE')">{{ 'shared.confirmation.confirm' | translate }}</div>
                  }
                  @if (!formAction['SUCCESSMESSAGE'].editMode) {
                    <div (click)="enableEditMode('SUCCESSMESSAGE')">{{ 'shared.actions.edit' | translate }}</div>
                  }
                </div>
              </div>
            </div>
            <div class="col-2"></div>
          </div>
        </div>
        <div class="right">
          <div class="text-title">{{ 'oxr.creatingSpace.contactForm.whereToReceiveContact' | translate }}</div>
          <div class="contents">
            <ui-check-box
              class="selection-checkbox white-grey-500 xs"
              [checked]="!!data[keyValue['ALRAMOPTIONALLCHECK']] && data[keyValue['ALRAMOPTIONALLCHECK']] !== '0'"
              (checkedChange)="onCheckedChange($event, 'ALRAMOPTIONALLCHECK')"
            >
            </ui-check-box>
            <div class="text">{{ 'shared.fields.selectAll' | translate }}</div>
          </div>
          <div class="contents">
            <ui-check-box
              class="selection-checkbox white-grey-500 xs"
              [checked]="!!data[keyValue['ALRAMOPTIONOWNXR']] && data[keyValue['ALRAMOPTIONOWNXR']] !== '0'"
              (checkedChange)="onCheckedChange($event, 'ALRAMOPTIONOWNXR')"
            ></ui-check-box>
            <div class="text">{{ 'oxr.creatingSpace.contactForm.ownXRNotification' | translate }}</div>
          </div>
          <div class="contents">
            <ui-check-box
              class="selection-checkbox white-grey-500 xs"
              [checked]="!!data[keyValue['ALRAMOPTIONEMAIL']] && data[keyValue['ALRAMOPTIONEMAIL']] !== '0'"
              (checkedChange)="onCheckedChange($event, 'ALRAMOPTIONEMAIL')"
            ></ui-check-box>
            <div class="text">
              <span
                >{{ 'shared.fields.email' | translate }}
                <i class="help icon-help-fill xxs" uiTooltip="{{ 'oxr.creatingSpace.contactForm.emailTooltip' | translate }}"></i>
                <br />
                ({{ (email | async) || '-' }})</span
              >
            </div>
          </div>
        </div>
      </div>
    } @else {
      <div class="preview-mode">
        <div class="title mb-40">
          <div class="text">{{ data[keyValue['TITLE']] }}</div>
        </div>
        <div class="form" [formGroup]="form">
          <input
            type="text"
            placeholder="{{ data[keyValue['PLACEHOLDERFIRST']] ? data[keyValue['PLACEHOLDERFIRST']] : ('shared.fields.name' | translate) }}"
            [required]="convertStringToBoolean(data[keyValue['CHECKEDPLACEHOLDERFIRST']])"
            [formControlName]="keyValue['PLACEHOLDERFIRST']"
            [maxLength]="25"
          />
          <input
            type="text"
            placeholder="{{
              data[keyValue['PLACEHOLDERSECOND']] ? data[keyValue['PLACEHOLDERSECOND']] : ('shared.fields.contact' | translate)
            }}"
            [required]="convertStringToBoolean(data[keyValue['CHECKEDPLACEHOLDERSECOND']])"
            [formControlName]="keyValue['PLACEHOLDERSECOND']"
            [maxLength]="25"
          />
          <input
            type="text"
            placeholder="{{
              data[keyValue['PLACEHOLDERTHIRD']] ? data[keyValue['PLACEHOLDERTHIRD']] : ('shared.fields.email' | translate)
            }}"
            [required]="convertStringToBoolean(data[keyValue['CHECKEDPLACEHOLDERTHIRD']])"
            [formControlName]="keyValue['PLACEHOLDERTHIRD']"
            [maxLength]="30"
          />
          <textarea
            uiTextarea
            nonResizable
            rows="4"
            placeholder="{{
              data[keyValue['PLACEHOLDERFOURTH']]
                ? data[keyValue['PLACEHOLDERFOURTH']]
                : ('oxr.creatingSpace.contactForm.inquiries' | translate)
            }}"
            [required]="convertStringToBoolean(data[keyValue['CHECKEDPLACEHOLDERFOURTH']])"
            [formControlName]="keyValue['PLACEHOLDERFOURTH']"
          ></textarea>
          <ui-check-box class="selection-checkbox white-grey-500 xs" (checkedChange)="checkedAgree = !checkedAgree">
            {{ 'oxr.creatingSpace.contactForm.termsAndConditions' | translate }}
          </ui-check-box>
          <div class="submit">
            <button ui-button [disabled]="!form.valid || !checkedAgree" (click)="onPreviewSave()">
              {{ 'shared.actions.save' | translate }}
            </button>
          </div>
        </div>
      </div>
    }
    <!-- Preview Mode -->
    <ng-template #preview>
      <div class="preview-mode">
        <div class="title mb-40">
          <div class="text">{{ data[keyValue['TITLE']] }}</div>
        </div>
        <div class="form" [formGroup]="form">
          <input
            type="text"
            placeholder="{{ data[keyValue['PLACEHOLDERFIRST']] ? data[keyValue['PLACEHOLDERFIRST']] : ('shared.fields.name' | translate) }}"
            [required]="convertStringToBoolean(data[keyValue['CHECKEDPLACEHOLDERFIRST']])"
            [formControlName]="keyValue['PLACEHOLDERFIRST']"
            [maxLength]="25"
          />
          <input
            type="text"
            placeholder="{{
              data[keyValue['PLACEHOLDERSECOND']] ? data[keyValue['PLACEHOLDERSECOND']] : ('shared.fields.contact' | translate)
            }}"
            [required]="convertStringToBoolean(data[keyValue['CHECKEDPLACEHOLDERSECOND']])"
            [formControlName]="keyValue['PLACEHOLDERSECOND']"
            [maxLength]="25"
          />
          <input
            type="text"
            placeholder="{{
              data[keyValue['PLACEHOLDERTHIRD']] ? data[keyValue['PLACEHOLDERTHIRD']] : ('shared.fields.email' | translate)
            }}"
            [required]="convertStringToBoolean(data[keyValue['CHECKEDPLACEHOLDERTHIRD']])"
            [formControlName]="keyValue['PLACEHOLDERTHIRD']"
            [maxLength]="30"
          />
          <textarea
            uiTextarea
            nonResizable
            rows="4"
            placeholder="{{
              data[keyValue['PLACEHOLDERFOURTH']]
                ? data[keyValue['PLACEHOLDERFOURTH']]
                : ('oxr.creatingSpace.contactForm.inquiries' | translate)
            }}"
            [required]="convertStringToBoolean(data[keyValue['CHECKEDPLACEHOLDERFOURTH']])"
            [formControlName]="keyValue['PLACEHOLDERFOURTH']"
          ></textarea>
          <ui-check-box class="selection-checkbox white-grey-500 xs" (checkedChange)="checkedAgree = !checkedAgree">
            {{ 'oxr.creatingSpace.contactForm.termsAndConditions' | translate }}
          </ui-check-box>
          <div class="submit">
            <button ui-button [disabled]="!form.valid || !checkedAgree" (click)="onPreviewSave()">
              {{ 'shared.actions.save' | translate }}
            </button>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- Footer -->
    @if (!isPlayerMode) {
      <section class="footer">
        <div></div>
        <div class="buttons-container">
          @if (isEditMode) {
            <button ui-button secondary sm (click)="openPreview()">{{ 'shared.actions.preview' | translate }}</button>
            <button ui-button sm (click)="onEditSave()">{{ 'shared.actions.save' | translate }}</button>
          }
          @if (!isEditMode) {
            <button ui-button sm (click)="isEditMode = true">
              {{ 'shared.actions.edit' | translate }}
            </button>
          }
        </div>
      </section>
    }
  </div>
</div>
