import { Component, Input, OnInit, Output, EventEmitter, ElementRef } from '@angular/core';

@Component({
  selector: 'ui-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements OnInit {
  @Input() set checked(value: boolean) {
    this._checked = !!value;
    this.setContent();
  }
  get checked() {
    return this._checked;
  }
  @Input() label = '';
  @Input() onContent = '';
  @Input() offContent = '';
  @Input() size = 'sm';
  @Input() type = 'large';
  @Input() disabled = false;
  @Input() onShowContentMode = false; //show both 'onContent' and 'offContent' on the switch

  private _checked = false;

  @Output() changed: EventEmitter<boolean> = new EventEmitter();

  constructor(private elRef: ElementRef) {}

  ngOnInit(): void {
    this.setContent();
  }

  onChanged() {
    this.checked = !this.checked;
    this.setContent();
    this.changed.emit(this.checked);
  }

  setContent() {
    if (this.onShowContentMode) {
      this.elRef.nativeElement.style.setProperty('--slider-content-left', this.checked ? '4px' : 'unset');
      this.elRef.nativeElement.style.setProperty('--slider-content-right', this.checked ? 'unset' : '4px');
    }
  }
}
