import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';

const DEFAULT_ANIMATION_SPEED = 4.5;
const DEFAULT_ANIMATION_VALUE = 'my-animation 8s linear infinite';

@Directive({
  selector: '[uiTextFlowAnimation]',
})
export class TextFlowAnimationDirective implements AfterViewInit {
  @Input() uiTextFlowAnimation: boolean = true;
  @Input() contentLength: number = 0;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    const element = this.el.nativeElement as HTMLElement;
    if (this.uiTextFlowAnimation) {
      element.classList.add('text-flow-animation');
      const time = this.contentLength / DEFAULT_ANIMATION_SPEED;
      element.style.animation =
        element.style['-moz-animation'] =
        element.style['-webkit-animation'] =
          time ? `my-animation ${time}s linear infinite` : DEFAULT_ANIMATION_VALUE;
    }
  }
}
