import {
  ActionEvent,
  ActionManager,
  DirectionalLight,
  GizmoManager,
  HemisphericLight,
  Light,
  LightGizmo,
  Mesh,
  MeshBuilder,
  Nullable,
  Observer,
  PlaneDragGizmo,
  PointLight,
  PointerInfo,
  ShadowGenerator,
  SpotLight,
  Vector3,
} from '../../babylon';
import { ViewManager } from '..';
import { SelectTool } from '../tools';
import { LightType, TElement, TLightElement } from '../types';
import { Interaction } from '../auxiliaries/interaction';
import {
  BLUE,
  FORWARD,
  GIZMO_SCALE,
  GREEN,
  LIGHT_ANGLE,
  LIGHT_BIAS,
  LIGHT_DIFFUSE,
  LIGHT_DIRECTIONS,
  LIGHT_GROUND,
  LIGHT_INTENSITES,
  LIGHT_NORMAL_BIAS,
  LIGHT_POSITION,
  MAX_SIZE,
  RED,
  RIGHT,
  UP,
} from '../constants';
import { deepCopy } from '../../utils/data';

type ILightObserver = {
  dragPlanePoint: Vector3;
  pointerId: number;
  pointerInfo: Nullable<PointerInfo>;
};

export class LightRenderable {
  public elementId: string;
  public helper: Mesh;
  public light: DirectionalLight | HemisphericLight | PointLight | SpotLight;
  public gizmo: LightGizmo;
  public gizmoManager: GizmoManager;
  public shadowGenerator: ShadowGenerator;

  private xPlaneGizmo: PlaneDragGizmo;
  private yPlaneGizmo: PlaneDragGizmo;
  private zPlaneGizmo: PlaneDragGizmo;
  private observers: {
    xPosition?: Nullable<Observer<ILightObserver>>;
    xRotation?: Nullable<Observer<ILightObserver>>;
    xPlane?: Nullable<Observer<ILightObserver>>;
    yPosition?: Nullable<Observer<ILightObserver>>;
    yRotation?: Nullable<Observer<ILightObserver>>;
    yPlane?: Nullable<Observer<ILightObserver>>;
    zPosition?: Nullable<Observer<ILightObserver>>;
    zRotation?: Nullable<Observer<ILightObserver>>;
    zPlane?: Nullable<Observer<ILightObserver>>;
    utilityClick?: Nullable<Observer<Light>>;
  } = {};

  private clickInteraction: Interaction;
  private isShown: boolean;
  private gizmosRescaled = false;

  constructor(
    public element: TLightElement,
    public viewManager: ViewManager,
  ) {
    this.elementId = element.id;
    switch (element.parameters.type) {
      case LightType.Point:
        this.light = new PointLight(element.name, Vector3.Zero(), viewManager.scene);
        this.shadowGenerator = new ShadowGenerator(1024, this.light);
        break;
      case LightType.Directional:
        this.light = new DirectionalLight(element.name, Vector3.Zero(), viewManager.scene);
        this.shadowGenerator = new ShadowGenerator(1024, this.light);
        break;
      case LightType.Ambient:
        this.light = new HemisphericLight(element.name, Vector3.Zero(), viewManager.scene);
        break;
      case LightType.Spot:
        this.light = new SpotLight(element.name, Vector3.Zero(), Vector3.Down(), LIGHT_ANGLE, 10, viewManager.scene);
        this.shadowGenerator = new ShadowGenerator(1024, this.light);
        break;
      default:
        break;
    }
    this.light.range = MAX_SIZE;
    if (this.shadowGenerator) {
      this.shadowGenerator.transparencyShadow = true;
      this.shadowGenerator.enableSoftTransparentShadow = true;
      this.shadowGenerator.usePercentageCloserFiltering = true;
      this.shadowGenerator.filteringQuality = this.viewManager.isHandHeldDevice
        ? ShadowGenerator.QUALITY_MEDIUM
        : ShadowGenerator.QUALITY_HIGH;
      this.shadowGenerator.useContactHardeningShadow = true;
      this.shadowGenerator.contactHardeningLightSizeUVRatio = 0.05;
    }

    this.update = this.update.bind(this);
    this.createHelper = this.createHelper.bind(this);
    this.showHelper = this.showHelper.bind(this);
    this.hideHelper = this.hideHelper.bind(this);
    this.updateElement = this.updateElement.bind(this);
    viewManager.toolsEnabled && this.createHelper();
    this.update();
  }

  update(element?: TElement) {
    if (element) {
      this.element = deepCopy(element as TLightElement);
    }

    this.light.intensity = this.element.parameters.intensity ?? LIGHT_INTENSITES[this.element.parameters.type];
    this.light.direction?.set(...(this.element.parameters.direction ?? LIGHT_DIRECTIONS[this.element.parameters.type]));

    // TODO: Inject position setter to hemisperic light
    if (this.viewManager.toolsEnabled) {
      this.gizmo?.attachedMesh?.position.fromArray(this.element.parameters.position ?? LIGHT_POSITION);
    } else if (!(this.light instanceof HemisphericLight)) {
      this.light.position?.fromArray(this.element.parameters.position ?? LIGHT_POSITION);
    }

    this.light.diffuse.fromArray(this.element.parameters.color ?? LIGHT_DIFFUSE);

    if (this.light instanceof SpotLight) {
      this.light.angle = this.element.parameters.angle ?? LIGHT_ANGLE;
    } else if (this.light instanceof HemisphericLight) {
      this.light.groundColor.fromArray(this.element.parameters.groundColor ?? LIGHT_GROUND);
    }

    if (this.shadowGenerator) {
      this.shadowGenerator.bias = this.element.parameters.bias ?? LIGHT_BIAS;
      this.shadowGenerator.normalBias = this.element.parameters.normalBias ?? LIGHT_NORMAL_BIAS;
      this.viewManager.interactables.forEach((renderable) => {
        if (renderable.helper) {
          renderable.element.parameters.shadows
            ? this.shadowGenerator.addShadowCaster(renderable.helper, true)
            : this.shadowGenerator.removeShadowCaster(renderable.helper, true);
        }
      });
      [...this.viewManager.landscapes, ...this.viewManager.stages].forEach((renderable) => {
        renderable.helper && this.shadowGenerator.addShadowCaster(renderable.helper, true);
      });
      this.viewManager.checkpoints.forEach((checkpoint) => {
        checkpoint.avatar && this.shadowGenerator.addShadowCaster(checkpoint.avatar, true);
      });
    }
  }

  createHelper() {
    this.gizmo = new LightGizmo(this.viewManager.gizmoLayer);
    this.gizmo.light = this.light;

    this.gizmoManager = new GizmoManager(this.viewManager.scene, undefined, this.viewManager.gizmoLayer);
    this.gizmoManager.boundingBoxGizmoEnabled = false;
    this.gizmoManager.usePointerToAttachGizmos = false;
    this.gizmoManager.attachToMesh(this.gizmo.attachedMesh);

    this.xPlaneGizmo = new PlaneDragGizmo(RIGHT, RED, this.gizmoManager.utilityLayer);
    this.yPlaneGizmo = new PlaneDragGizmo(UP, GREEN, this.gizmoManager.utilityLayer);
    this.zPlaneGizmo = new PlaneDragGizmo(FORWARD, BLUE, this.gizmoManager.utilityLayer);
    this.xPlaneGizmo.attachedMesh = this.gizmo.attachedMesh;
    this.yPlaneGizmo.attachedMesh = this.gizmo.attachedMesh;
    this.zPlaneGizmo.attachedMesh = this.gizmo.attachedMesh;

    this.xPlaneGizmo._rootMesh.setEnabled(false);
    this.yPlaneGizmo._rootMesh.setEnabled(false);
    this.zPlaneGizmo._rootMesh.setEnabled(false);

    this.gizmo._rootMesh.getChildMeshes()[0].scaling.scaleInPlace(GIZMO_SCALE);

    this.helper = MeshBuilder.CreateIcoSphere(`Helper-${this.element.id}`, { radius: 0.02 * GIZMO_SCALE }, this.viewManager.scene);
    this.helper.parent = this.gizmo._rootMesh;
    this.gizmo._rootMesh.metadata = this.gizmo._rootMesh.metadata ?? { helperOf: this };
    this.helper.metadata = this.helper.metadata ?? { helperOf: this };
    this.helper.visibility = 0.001;

    this.clickInteraction = new Interaction(
      this.viewManager.scene,
      this.helper,
      ActionManager.OnLeftPickTrigger,
      (_event: ActionEvent) => {
        if (this.viewManager.tool instanceof SelectTool && !this.viewManager.tool.elements.some(({ id }) => id === this.elementId)) {
          this.viewManager.tool?.forceElement(this.element);
        }
      },
      true,
    );
  }

  showHelper() {
    if (this.isShown) {
      return;
    }
    this.observers.utilityClick = this.gizmo.onClickedObservable.add(() => {
      if (this.viewManager.tool instanceof SelectTool) {
        this.viewManager.tool.forceElement(this.element);
      }
    });
    this.viewManager.gizmoLayer.pickingEnabled = true;
    this.gizmoManager.positionGizmoEnabled = true;
    switch (this.element.parameters.type) {
      case LightType.Ambient:
      case LightType.Directional:
      case LightType.Spot:
        this.gizmoManager.rotationGizmoEnabled = true;
        this.gizmoManager.gizmos.rotationGizmo?.zGizmo._rootMesh?.setEnabled(false);
        break;
      case LightType.Point:
        this.gizmoManager.rotationGizmoEnabled = false;
        break;
      default:
        break;
    }
    this.observers.xPosition = this.gizmoManager.gizmos.positionGizmo?.xGizmo.dragBehavior.onDragEndObservable.add(this.updateElement);
    this.observers.xRotation = this.gizmoManager.gizmos.rotationGizmo?.xGizmo.dragBehavior.onDragEndObservable.add(this.updateElement);
    this.observers.xPlane = this.xPlaneGizmo.dragBehavior.onDragEndObservable.add(this.updateElement);
    this.observers.yPosition = this.gizmoManager.gizmos.positionGizmo?.yGizmo.dragBehavior.onDragEndObservable.add(this.updateElement);
    this.observers.yRotation = this.gizmoManager.gizmos.rotationGizmo?.yGizmo.dragBehavior.onDragEndObservable.add(this.updateElement);
    this.observers.yPlane = this.yPlaneGizmo.dragBehavior.onDragEndObservable.add(this.updateElement);
    this.observers.zPosition = this.gizmoManager.gizmos.positionGizmo?.zGizmo.dragBehavior.onDragEndObservable.add(this.updateElement);
    this.observers.zRotation = this.gizmoManager.gizmos.rotationGizmo?.zGizmo.dragBehavior.onDragEndObservable.add(this.updateElement);
    this.observers.zPlane = this.zPlaneGizmo.dragBehavior.onDragEndObservable.add(this.updateElement);

    this.xPlaneGizmo._rootMesh.setEnabled(true);
    this.yPlaneGizmo._rootMesh.setEnabled(true);
    this.zPlaneGizmo._rootMesh.setEnabled(true);

    if (!this.gizmosRescaled) {
      [
        this.gizmoManager.gizmos.positionGizmo?.xGizmo._rootMesh.getChildMeshes()[0],
        this.gizmoManager.gizmos.positionGizmo?.yGizmo._rootMesh.getChildMeshes()[0],
        this.gizmoManager.gizmos.positionGizmo?.zGizmo._rootMesh.getChildMeshes()[0],
        this.gizmoManager.gizmos.rotationGizmo?.xGizmo._rootMesh.getChildMeshes()[0],
        this.gizmoManager.gizmos.rotationGizmo?.yGizmo._rootMesh.getChildMeshes()[0],
        this.gizmoManager.gizmos.rotationGizmo?.zGizmo._rootMesh.getChildMeshes()[0],
      ].forEach((mesh) => {
        mesh?.scaling.scaleInPlace(GIZMO_SCALE);
      });
      this.gizmosRescaled = true;
    }

    this.isShown = true;
  }

  hideHelper() {
    if (!this.isShown) {
      return;
    }
    this.xPlaneGizmo._rootMesh.setEnabled(false);
    this.yPlaneGizmo._rootMesh.setEnabled(false);
    this.zPlaneGizmo._rootMesh.setEnabled(false);

    this.observers.xPosition &&
      this.gizmoManager.gizmos.positionGizmo?.xGizmo.dragBehavior.onDragEndObservable.remove(this.observers.xPosition);
    this.observers.xRotation &&
      this.gizmoManager.gizmos.rotationGizmo?.xGizmo.dragBehavior.onDragEndObservable.remove(this.observers.xRotation);
    this.observers.xPlane && this.xPlaneGizmo.dragBehavior.onDragEndObservable.remove(this.observers.xPlane);
    this.observers.yPosition &&
      this.gizmoManager.gizmos.positionGizmo?.yGizmo.dragBehavior.onDragEndObservable.remove(this.observers.yPosition);
    this.observers.yRotation &&
      this.gizmoManager.gizmos.rotationGizmo?.yGizmo.dragBehavior.onDragEndObservable.remove(this.observers.yRotation);
    this.observers.yPlane && this.yPlaneGizmo.dragBehavior.onDragEndObservable.remove(this.observers.yPlane);
    this.observers.zPosition &&
      this.gizmoManager.gizmos.positionGizmo?.zGizmo.dragBehavior.onDragEndObservable.remove(this.observers.zPosition);
    this.observers.zRotation &&
      this.gizmoManager.gizmos.rotationGizmo?.zGizmo.dragBehavior.onDragEndObservable.remove(this.observers.zRotation);
    this.observers.zPlane && this.zPlaneGizmo.dragBehavior.onDragEndObservable.remove(this.observers.zPlane);

    this.gizmoManager.boundingBoxGizmoEnabled = false;
    this.gizmoManager.positionGizmoEnabled = false;
    this.gizmoManager.rotationGizmoEnabled = false;
    this.gizmoManager.scaleGizmoEnabled = false;
    this.isShown = false;
  }

  updateElement() {
    this.viewManager.appService.updateViewElement({
      ...this.element,
      parameters: {
        ...this.element.parameters,
        position: [...this.helper.computeWorldMatrix(true).asArray().slice(12, 15)] as TLightElement['parameters']['position'],
        direction: (this.light.direction?.asArray() ??
          LIGHT_DIRECTIONS[this.element.parameters.type]) as TLightElement['parameters']['direction'],
        ...(this.light instanceof SpotLight ? { angle: this.light.angle ?? LIGHT_ANGLE } : {}),
      },
    });
  }

  dispose() {
    this.hideHelper();
    this.clickInteraction?.unregister();
    this.light?.dispose();
    this.gizmo?.dispose();
    // this.gizmoManager?.dispose();
    this.helper?.dispose();
    this.observers?.utilityClick && this.gizmo?.onClickedObservable.remove(this.observers.utilityClick);
  }
}
