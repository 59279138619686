import { PBRMaterial } from "@babylonjs/core/Materials/PBR/pbrMaterial.js";
import { GLTFLoader } from "../glTFLoader.js";
const NAME = "KHR_materials_volume";
/**
 * [Specification](https://github.com/KhronosGroup/glTF/blob/main/extensions/2.0/Khronos/KHR_materials_volume/README.md)
 * @since 5.0.0
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class KHR_materials_volume {
  /**
   * @internal
   */
  constructor(loader) {
    /**
     * The name of this extension.
     */
    this.name = NAME;
    /**
     * Defines a number that determines the order the extensions are applied.
     */
    this.order = 173;
    this._loader = loader;
    this.enabled = this._loader.isExtensionUsed(NAME);
    if (this.enabled) {
      // We need to disable instance usage because the attenuation factor depends on the node scale of each individual mesh
      this._loader._disableInstancedMesh++;
    }
  }
  /** @internal */
  dispose() {
    if (this.enabled) {
      this._loader._disableInstancedMesh--;
    }
    this._loader = null;
  }
  /**
   * @internal
   */
  loadMaterialPropertiesAsync(context, material, babylonMaterial) {
    return GLTFLoader.LoadExtensionAsync(context, material, this.name, (extensionContext, extension) => {
      const promises = new Array();
      promises.push(this._loader.loadMaterialBasePropertiesAsync(context, material, babylonMaterial));
      promises.push(this._loader.loadMaterialPropertiesAsync(context, material, babylonMaterial));
      promises.push(this._loadVolumePropertiesAsync(extensionContext, material, babylonMaterial, extension));
      return Promise.all(promises).then(() => {});
    });
  }
  _loadVolumePropertiesAsync(context, material, babylonMaterial, extension) {
    if (!(babylonMaterial instanceof PBRMaterial)) {
      throw new Error(`${context}: Material type not supported`);
    }
    // If transparency isn't enabled already, this extension shouldn't do anything.
    // i.e. it requires either the KHR_materials_transmission or KHR_materials_translucency extensions.
    if (!babylonMaterial.subSurface.isRefractionEnabled && !babylonMaterial.subSurface.isTranslucencyEnabled || !extension.thicknessFactor) {
      return Promise.resolve();
    }
    // IOR in this extension only affects interior.
    babylonMaterial.subSurface.volumeIndexOfRefraction = babylonMaterial.indexOfRefraction;
    const attenuationDistance = extension.attenuationDistance !== undefined ? extension.attenuationDistance : Number.MAX_VALUE;
    babylonMaterial.subSurface.tintColorAtDistance = attenuationDistance;
    if (extension.attenuationColor !== undefined && extension.attenuationColor.length == 3) {
      babylonMaterial.subSurface.tintColor.copyFromFloats(extension.attenuationColor[0], extension.attenuationColor[1], extension.attenuationColor[2]);
    }
    babylonMaterial.subSurface.minimumThickness = 0.0;
    babylonMaterial.subSurface.maximumThickness = extension.thicknessFactor;
    babylonMaterial.subSurface.useThicknessAsDepth = true;
    if (extension.thicknessTexture) {
      extension.thicknessTexture.nonColorData = true;
      return this._loader.loadTextureInfoAsync(`${context}/thicknessTexture`, extension.thicknessTexture).then(texture => {
        babylonMaterial.subSurface.thicknessTexture = texture;
        babylonMaterial.subSurface.useGltfStyleTextures = true;
      });
    } else {
      return Promise.resolve();
    }
  }
}
GLTFLoader.RegisterExtension(NAME, loader => new KHR_materials_volume(loader));
