// Additional key definition possible
export enum LocalStorageKeys {
  VERSION = 'Version',
  USER_ID = 'UserId',
  REGION = 'Region',
  CARD_REQUEST_PAYMENT = 'CardRequestPayment',
  SHOW_DESKTOP_WELCOME = 'ShowDesktopWelcome',
  SHOW_MOBILE_WELCOME = 'ShowMobileWelcome',
  SHOW_MOBILE_COMMUNITY_GUIDE = 'ShowMobileCommunityGuide',
  NEVER_SHOW_AGAIN_EDIT_MANUAL = 'NeverShowAgainEditManual',
  SELECTED_ACCOUNT_OPTION_ID = 'SelectedAccountOptionId',
  SHOW_MOBILE_OWNXR_INTRO_BANNER = 'ShowMobileOwnxrIntroBanner',
  SELECTED_DASHBOARD_SCENE_INFO = 'SelectedDashboardSceneInfo',
}

// Additional key definition possible
export enum SessionStorageKeys {
  PLATFORM = 'Platform',
}

export enum PlatformDetail {
  DESKTOP = 'Desktop',
  MOBILE = 'Mobile',
}
