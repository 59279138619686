import { InternalTexture, InternalTextureSource } from "../../Materials/Textures/internalTexture.js";
import { Logger } from "../../Misc/logger.js";
import { ThinEngine } from "../thinEngine.js";
import { WebGLRenderTargetWrapper } from "../WebGL/webGLRenderTargetWrapper.js";
ThinEngine.prototype._createHardwareRenderTargetWrapper = function (isMulti, isCube, size) {
  const rtWrapper = new WebGLRenderTargetWrapper(isMulti, isCube, size, this, this._gl);
  this._renderTargetWrapperCache.push(rtWrapper);
  return rtWrapper;
};
ThinEngine.prototype.createRenderTargetTexture = function (size, options) {
  var _a, _b;
  const rtWrapper = this._createHardwareRenderTargetWrapper(false, false, size);
  let generateDepthBuffer = true;
  let generateStencilBuffer = false;
  let noColorAttachment = false;
  let colorAttachment = undefined;
  let samples = 1;
  if (options !== undefined && typeof options === "object") {
    generateDepthBuffer = (_a = options.generateDepthBuffer) !== null && _a !== void 0 ? _a : true;
    generateStencilBuffer = !!options.generateStencilBuffer;
    noColorAttachment = !!options.noColorAttachment;
    colorAttachment = options.colorAttachment;
    samples = (_b = options.samples) !== null && _b !== void 0 ? _b : 1;
  }
  const texture = colorAttachment || (noColorAttachment ? null : this._createInternalTexture(size, options, true, InternalTextureSource.RenderTarget));
  const width = size.width || size;
  const height = size.height || size;
  const currentFrameBuffer = this._currentFramebuffer;
  const gl = this._gl;
  // Create the framebuffer
  const framebuffer = gl.createFramebuffer();
  this._bindUnboundFramebuffer(framebuffer);
  rtWrapper._depthStencilBuffer = this._setupFramebufferDepthAttachments(generateStencilBuffer, generateDepthBuffer, width, height);
  // No need to rebind on every frame
  if (texture && !texture.is2DArray) {
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture._hardwareTexture.underlyingResource, 0);
  }
  this._bindUnboundFramebuffer(currentFrameBuffer);
  rtWrapper._framebuffer = framebuffer;
  rtWrapper._generateDepthBuffer = generateDepthBuffer;
  rtWrapper._generateStencilBuffer = generateStencilBuffer;
  rtWrapper.setTextures(texture);
  this.updateRenderTargetTextureSampleCount(rtWrapper, samples);
  return rtWrapper;
};
ThinEngine.prototype.createDepthStencilTexture = function (size, options, rtWrapper) {
  if (options.isCube) {
    const width = size.width || size;
    return this._createDepthStencilCubeTexture(width, options, rtWrapper);
  } else {
    return this._createDepthStencilTexture(size, options, rtWrapper);
  }
};
ThinEngine.prototype._createDepthStencilTexture = function (size, options, rtWrapper) {
  const gl = this._gl;
  const layers = size.layers || 0;
  const target = layers !== 0 ? gl.TEXTURE_2D_ARRAY : gl.TEXTURE_2D;
  const internalTexture = new InternalTexture(this, InternalTextureSource.DepthStencil);
  if (!this._caps.depthTextureExtension) {
    Logger.Error("Depth texture is not supported by your browser or hardware.");
    return internalTexture;
  }
  const internalOptions = Object.assign({
    bilinearFiltering: false,
    comparisonFunction: 0,
    generateStencil: false
  }, options);
  this._bindTextureDirectly(target, internalTexture, true);
  this._setupDepthStencilTexture(internalTexture, size, internalOptions.generateStencil, internalOptions.comparisonFunction === 0 ? false : internalOptions.bilinearFiltering, internalOptions.comparisonFunction, internalOptions.samples);
  if (internalOptions.depthTextureFormat !== undefined) {
    if (internalOptions.depthTextureFormat !== 15 && internalOptions.depthTextureFormat !== 16 && internalOptions.depthTextureFormat !== 17 && internalOptions.depthTextureFormat !== 13 && internalOptions.depthTextureFormat !== 14 && internalOptions.depthTextureFormat !== 18) {
      Logger.Error("Depth texture format is not supported.");
      return internalTexture;
    }
    internalTexture.format = internalOptions.depthTextureFormat;
  } else {
    internalTexture.format = internalOptions.generateStencil ? 13 : 16;
  }
  const hasStencil = internalTexture.format === 17 || internalTexture.format === 13 || internalTexture.format === 18;
  rtWrapper._depthStencilTexture = internalTexture;
  rtWrapper._depthStencilTextureWithStencil = hasStencil;
  let type = gl.UNSIGNED_INT;
  if (internalTexture.format === 15) {
    type = gl.UNSIGNED_SHORT;
  } else if (internalTexture.format === 17 || internalTexture.format === 13) {
    type = gl.UNSIGNED_INT_24_8;
  } else if (internalTexture.format === 14) {
    type = gl.FLOAT;
  } else if (internalTexture.format === 18) {
    type = gl.FLOAT_32_UNSIGNED_INT_24_8_REV;
  }
  const format = hasStencil ? gl.DEPTH_STENCIL : gl.DEPTH_COMPONENT;
  let internalFormat = format;
  if (this.webGLVersion > 1) {
    if (internalTexture.format === 15) {
      internalFormat = gl.DEPTH_COMPONENT16;
    } else if (internalTexture.format === 16) {
      internalFormat = gl.DEPTH_COMPONENT24;
    } else if (internalTexture.format === 17 || internalTexture.format === 13) {
      internalFormat = gl.DEPTH24_STENCIL8;
    } else if (internalTexture.format === 14) {
      internalFormat = gl.DEPTH_COMPONENT32F;
    } else if (internalTexture.format === 18) {
      internalFormat = gl.DEPTH32F_STENCIL8;
    }
  }
  if (internalTexture.is2DArray) {
    gl.texImage3D(target, 0, internalFormat, internalTexture.width, internalTexture.height, layers, 0, format, type, null);
  } else {
    gl.texImage2D(target, 0, internalFormat, internalTexture.width, internalTexture.height, 0, format, type, null);
  }
  this._bindTextureDirectly(target, null);
  this._internalTexturesCache.push(internalTexture);
  // Dispose previous depth/stencil render buffers and clear the corresponding attachment.
  // Next time this framebuffer is bound, the new depth/stencil texture will be attached.
  const glRtWrapper = rtWrapper;
  if (glRtWrapper._depthStencilBuffer) {
    const currentFrameBuffer = this._currentFramebuffer;
    this._bindUnboundFramebuffer(glRtWrapper._framebuffer);
    gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.DEPTH_STENCIL_ATTACHMENT, gl.RENDERBUFFER, null);
    gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.RENDERBUFFER, null);
    gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.STENCIL_ATTACHMENT, gl.RENDERBUFFER, null);
    this._bindUnboundFramebuffer(currentFrameBuffer);
    gl.deleteRenderbuffer(glRtWrapper._depthStencilBuffer);
    glRtWrapper._depthStencilBuffer = null;
  }
  return internalTexture;
};
ThinEngine.prototype.updateRenderTargetTextureSampleCount = function (rtWrapper, samples) {
  if (this.webGLVersion < 2 || !rtWrapper || !rtWrapper.texture) {
    return 1;
  }
  if (rtWrapper.samples === samples) {
    return samples;
  }
  const gl = this._gl;
  samples = Math.min(samples, this.getCaps().maxMSAASamples);
  // Dispose previous render buffers
  if (rtWrapper._depthStencilBuffer) {
    gl.deleteRenderbuffer(rtWrapper._depthStencilBuffer);
    rtWrapper._depthStencilBuffer = null;
  }
  if (rtWrapper._MSAAFramebuffer) {
    gl.deleteFramebuffer(rtWrapper._MSAAFramebuffer);
    rtWrapper._MSAAFramebuffer = null;
  }
  const hardwareTexture = rtWrapper.texture._hardwareTexture;
  hardwareTexture.releaseMSAARenderBuffers();
  if (samples > 1 && typeof gl.renderbufferStorageMultisample === "function") {
    const framebuffer = gl.createFramebuffer();
    if (!framebuffer) {
      throw new Error("Unable to create multi sampled framebuffer");
    }
    rtWrapper._MSAAFramebuffer = framebuffer;
    this._bindUnboundFramebuffer(rtWrapper._MSAAFramebuffer);
    const colorRenderbuffer = this._createRenderBuffer(rtWrapper.texture.width, rtWrapper.texture.height, samples, -1 /* not used */, this._getRGBABufferInternalSizedFormat(rtWrapper.texture.type, rtWrapper.texture.format, rtWrapper.texture._useSRGBBuffer), gl.COLOR_ATTACHMENT0, false);
    if (!colorRenderbuffer) {
      throw new Error("Unable to create multi sampled framebuffer");
    }
    hardwareTexture.addMSAARenderBuffer(colorRenderbuffer);
  } else {
    this._bindUnboundFramebuffer(rtWrapper._framebuffer);
  }
  rtWrapper.texture.samples = samples;
  rtWrapper._samples = samples;
  rtWrapper._depthStencilBuffer = this._setupFramebufferDepthAttachments(rtWrapper._generateStencilBuffer, rtWrapper._generateDepthBuffer, rtWrapper.texture.width, rtWrapper.texture.height, samples);
  this._bindUnboundFramebuffer(null);
  return samples;
};
