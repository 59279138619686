<main>
  <button
    ui-button
    secondary
    class="language-button ko"
    [class.active]="selectedLang === languageCode.Korean"
    (click)="selectedLang = languageCode.Korean"
  >
    {{ 'shared.korean' | translate | uppercase }}
  </button>
  <button
    ui-button
    secondary
    class="language-button en"
    [class.active]="selectedLang === languageCode.English"
    (click)="selectedLang = languageCode.English"
  >
    {{ 'shared.english' | translate | uppercase }}
  </button>
</main>
<section class="footer">
  <button ui-button fixed (click)="onChangeLanguage()">
    @if (loading) {
      <ui-loader sm [loaderColor]="'white'"></ui-loader>
    }
    @if (!loading) {
      <span>{{ 'shared.actions.change' | translate }}</span>
    }
  </button>
</section>
