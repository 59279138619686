import { EnterpriseInvitation, EnterpriseRole, InvitationStatus } from '@/data/src/lib/models/data/enterprise';
import { EnterpriseService } from '@/data/src/lib/services/enterprise.service';
import { waitFor } from '@/data/src/lib/utils/async-utils';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { ModalService } from '../modal.service';

@Component({
  selector: 'ui-collaborator-invitation-modal',
  templateUrl: './collaborator-invitation-modal.component.html',
  styleUrls: ['./collaborator-invitation-modal.component.scss'],
})
export class CollaboratorInvitationModalComponent implements OnInit {
  _invitations: EnterpriseInvitation[] = [];
  invitation: EnterpriseInvitation;
  isLoading = false;
  isInvited = false;

  @Input() set invitations(value: EnterpriseInvitation[]) {
    this._invitations = value;
    if (value.length) {
      this.invitation = value[0];
    }
  }

  get showDialog() {
    return !!this._invitations.length;
  }
  constructor(
    private _translateService: TranslateService,
    private _enterPriseService: EnterpriseService,
    private _modalService: ModalService,
  ) {}

  ngOnInit(): void {}

  getRoleName(role: `${EnterpriseRole}`) {
    return this._translateService.instant(`collaborator.invitationRole${role}`) || '';
  }

  async updateInvitationStatus(isAccept) {
    if (this.invitation.Id) {
      this.isInvited = true;
      this.isLoading = true;
      const payload = {
        enterpriseInvitationId: this.invitation.Id,
        invitationStatus: isAccept ? InvitationStatus.Confirmed : InvitationStatus.Declined,
      };

      const result = await this._enterPriseService.answerToInvitation(payload);
      if (result) {
        const index = this._invitations.findIndex((i) => i.Id === this.invitation.Id);
        this._invitations.splice(index, 1);
        if (this._invitations.length) {
          this.invitation = this._invitations[0];
        } else {
          waitFor(this._enterPriseService.getOwnedEnterprises());
        }
      } else {
        //When the enterprise reaches its maximum user limit and a user accepts an invitation
        const modal = this._modalService.open(ConfirmationComponent);
        if (modal) {
          modal.instance.title = this._translateService.instant('shared.confirmation.warning');
          modal.instance.body = this._translateService.instant('shared.information.acceptanceLimitReached');
          modal.instance.confirmAction = this._translateService.instant('shared.confirmation.confirm');
          modal.instance.showCancelAction = false;

          modal.result.then((result) => {
            if (result) {
              this.updateInvitationStatus(false);
            }
          });
        }
      }
      this.isLoading = false;
    }
  }
}
