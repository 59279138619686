<ng-container>
  <div class="loading-progress" *ngIf="!!progress && showProgressBar">
    <div class="loading-bar" [style.width.%]="progress"></div>
  </div>
</ng-container>
<ng-container #loading *ngIf="!isSceneReady">
  <div class="container" (click)="$event.stopPropagation(); $event.preventDefault()">
    <img class="animation" src="assets/images/loading-cubes-background.gif" />
  </div>
</ng-container>
<meta *ngIf="isHandHeldDevice" name="viewport" content="initial-scale=1, viewport-fit=cover, user-scalable=no" />
<canvas #viewCanvas id="viewCanvas" (resize)="onResize()" (window:resize)="onResize()" (touchmove)="onMove($event)"></canvas>
