import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { alreadyAddedValidator } from '../validators/already-added.validator';

@Directive({
  selector: '[alreadyAddedValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: AlreadyAddedValidatorDirective, multi: true }],
})
export class AlreadyAddedValidatorDirective implements Validator {
  @Input('alreadyAddedValidator') alreadyAdded: string[] = [];

  validate(control: AbstractControl): ValidationErrors | null {
    return control.value && this.alreadyAdded.length ? alreadyAddedValidator(this.alreadyAdded)(control) : null;
  }
}
