import { ChangeDetectionStrategy, Component, ComponentRef } from '@angular/core';
import { LocalStorageService } from 'projects/data/src/lib/services/local-storage.service';
import { ModalComponent } from '../modal.component';
import { ModalService } from '../modal.service';
import { LocalStorageKeys } from '@/data/src/lib/enums/storage-keys';

@Component({
  selector: 'ui-beta-welcome',
  templateUrl: './beta-welcome.component.html',
  styleUrls: ['./beta-welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BetaWelcomeComponent {
  public modalContainerRef: ComponentRef<ModalComponent> | undefined;
  checkboxChecked = false;
  listOpen = false;

  constructor(private _modalService: ModalService, private _localStorageService: LocalStorageService) {}

  onClick() {
    this._localStorageService.setItem(LocalStorageKeys.SHOW_DESKTOP_WELCOME, this.checkboxChecked ? 'false' : 'true');
    this._modalService.close('', this.modalContainerRef);
  }
}
