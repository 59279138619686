<div class="confirmation">
  <div class="header">{{ title || 'shared.confirmation.confirmAction' | translate }}</div>
  <div class="body" [innerHtml]="body | translate"></div>
  <div class="footer">
    <button ui-button w-200 (click)="close(true)">{{ confirmAction | translate }}</button>
    @if (showCancelAction) {
      <button uiAutofocus ui-outlined-button w-200 (click)="close(false)">{{ cancelAction | translate }}</button>
    }
  </div>
</div>
