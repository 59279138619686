import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { generateComponentId } from '../../utils/component-utils';

export interface IDropDownItem {
  name: string;
  id: string;
}

@Component({
  selector: 'ui-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss'],
})
export class DropDownComponent implements OnInit {
  @ViewChild('itemList') itemListRef!: ElementRef;

  @Input() placeholder = '';
  @Input() validate = false;
  @Output() selectionChange = new EventEmitter<string>();

  public text = '';
  public dropDownData: IDropDownItem[] = [];
  public id: string = generateComponentId();

  private _selection = '';
  private _data: (string | IDropDownItem)[] = [];

  @Input() set selection(value: string) {
    this._selection = value;
  }

  get selection() {
    return this._selection;
  }

  @Input() set data(value: (string | IDropDownItem)[]) {
    this._data = value;
    this.selection && this.arrangeData();
  }

  get data() {
    return this._data;
  }

  constructor() {
    this.arrangeData = this.arrangeData.bind(this);
  }

  arrangeData() {
    this.dropDownData = this.data.map((d: string | IDropDownItem) => {
      if (typeof d == 'string') {
        return {
          id: d,
          name: d,
        };
      } else {
        return d;
      }
    });
  }

  ngOnInit(): void {
    this.arrangeData();
    if (this.selection) {
      const value = this.data.filter((d: string | IDropDownItem) => {
        if (typeof d == 'string') {
          return d == this.selection;
        } else {
          return this.selection == d.id;
        }
      })[0];
      if (typeof value === 'string') {
        this.text = value;
        this.selectionChange.emit(value);
      } else {
        this.text = value.name;
        this.selectionChange.emit(value.id);
      }
    }
  }

  expand() {
    if (this.itemListRef && !this.itemListRef.nativeElement.classList.contains('show')) {
      this.itemListRef.nativeElement.classList.add('show');
    } else {
      this.itemListRef.nativeElement.classList.remove('show');
    }
  }

  hide() {
    if (this.itemListRef && this.itemListRef.nativeElement.classList.contains('show')) {
      this.itemListRef.nativeElement.classList.remove('show');
    }
  }

  setSelection(event: MouseEvent, value: IDropDownItem | string) {
    event.stopPropagation();
    if (typeof value === 'string') {
      this.text = value;
      this.selectionChange.emit(value);
    } else {
      this.text = value.name;
      this.selectionChange.emit(value.id);
    }
    this.hide();
  }
}
