@if (label) {
  <div class="label">{{ label | translate }}</div>
}
@for (option of options; track trackByFn($index, option)) {
  <div class="ui-radio-input" [class.disabled]="option?.disabled">
    <input
      type="radio"
      [name]="group"
      [id]="option.value"
      [checked]="_value === option.value"
      (change)="onChange(option.value)"
      [disabled]="option?.disabled"
    />
    <label [for]="option.value">
      <div class="toggle">
        <div class="radio">
          <div class="selected"></div>
        </div>
      </div>
      <span class="option-value">
        {{ option.label }}
      </span>
    </label>
  </div>
}
