<div class="popup-content-modal popup-video" #popupWrapper>
  <section class="modal-header">
    <div class="header-title">{{ title || 'shared.fields.titlePlaceholder' | translate }}</div>
    <div class="close icon-wrapper" (click)="close()">
      <div class="icon icon-close sm"></div>
    </div>
  </section>
  <section class="modal-body">
    <div class="scroll-container" #container>
      <div class="carousel">
        @for (item of displayedVideos; track item; let i = $index) {
          <div class="carousel-item" [class.readonly]="isPreviewMode || isReadonly" [style.backgroundColor]="item.color.background">
            <div
              class="layout-container drag-drop-container"
              [ngClass]="{
                editing: !isPreviewMode && !isReadonly,
                'horizontal-layout': item.layout === layouts.EditorBelow,
                'vertical-layout': item.layout === layouts.EditorRight,
                'horizontal-layout-no-edit': item.layout === layouts.NoEditor
              }"
            >
              @if (!item.videoUrl && currentPage - 1 === i) {
                <div class="position-1">
                  <ng-container
                    [ngTemplateOutlet]="titleTemplate"
                    [ngTemplateOutletContext]="{ $implicit: item, slot: 0, index: i }"
                  ></ng-container>
                </div>
                @if (!isPreviewMode && !isReadonly && !item.videoUrl) {
                  <section class="video-upload">
                    <ui-media-upload-library
                      (onSelect)="loadVideo($event)"
                      [videos]="mediaVideos"
                      [uploadType]="uploadFileType"
                    ></ui-media-upload-library>
                    <div class="yt-upload">
                      <div class="yt-upload-title"><i class="icon icon-youtube-label"></i></div>
                      <input
                        #ytLink
                        uiInput
                        placeholder="{{ 'oxr.creatingSpace.video.pasteLink' | translate }}"
                        type="text"
                        [(ngModel)]="item.yLink"
                      />
                    </div>
                    <div class="note">{{ 'oxr.creatingSpace.video.note' | translate }}</div>
                  </section>
                }
              } @else {
                <div class="position-1" #position1>
                  <div
                    class="drop-section"
                    (drop)="onDrop($event, 'position1', currentVideo)"
                    (dragover)="allowDrop($event)"
                    (dragenter)="onDragEnter($event, 'position1')"
                    (dragleave)="onDragLeave($event)"
                  ></div>
                  <ng-container
                    [ngTemplateOutlet]="
                      item.positions['position1'] === 'title'
                        ? titleTemplate
                        : item.positions['position1'] === 'content'
                          ? content
                          : item.positions['position1'] === 'description'
                            ? description
                            : null
                    "
                    [ngTemplateOutletContext]="{ $implicit: item, slot: 'position1', index: i }"
                  ></ng-container>
                </div>
                @if (item.layout === layouts.EditorRight) {
                  <div class="editor-right-container">
                    <ng-container [ngTemplateOutlet]="midContent"></ng-container>
                  </div>
                }
                @if (item.layout !== layouts.EditorRight) {
                  <ng-container [ngTemplateOutlet]="midContent"></ng-container>
                }
                <ng-template #midContent>
                  <div class="position-2" #position2>
                    <div
                      class="drop-section"
                      (drop)="onDrop($event, 'position2', currentVideo)"
                      (dragover)="allowDrop($event)"
                      (dragenter)="onDragEnter($event, 'position2')"
                      (dragleave)="onDragLeave($event)"
                    ></div>
                    <ng-container
                      [ngTemplateOutlet]="
                        item.positions['position2'] === 'title'
                          ? titleTemplate
                          : item.positions['position2'] === 'content'
                            ? content
                            : item.positions['position2'] === 'description'
                              ? description
                              : null
                      "
                      [ngTemplateOutletContext]="{ $implicit: item, slot: 'position2', index: i }"
                    ></ng-container>
                  </div>
                  <div class="position-3" #position3>
                    <ng-container
                      [ngTemplateOutlet]="
                        item.positions['position3'] === 'title'
                          ? titleTemplate
                          : item.positions['position3'] === 'content'
                            ? content
                            : item.positions['position3'] === 'description'
                              ? description
                              : null
                      "
                      [ngTemplateOutletContext]="{ $implicit: item, slot: 'position3', index: i }"
                    ></ng-container>
                    <div
                      class="drop-section"
                      (drop)="onDrop($event, 'position3', currentVideo)"
                      (dragover)="allowDrop($event)"
                      (dragenter)="onDragEnter($event, 'position3')"
                      (dragleave)="onDragLeave($event)"
                    ></div>
                  </div>
                </ng-template>
                <div class="position-4" #position4>
                  <ng-container
                    [ngTemplateOutlet]="
                      item.positions['position4'] === 'title'
                        ? titleTemplate
                        : item.positions['position4'] === 'content'
                          ? content
                          : item.positions['position4'] === 'description'
                            ? description
                            : null
                    "
                    [ngTemplateOutletContext]="{ $implicit: item, slot: 'position4', index: i }"
                  ></ng-container>
                  <div
                    class="drop-section"
                    (drop)="onDrop($event, 'position4', currentVideo)"
                    (dragover)="allowDrop($event)"
                    (dragenter)="onDragEnter($event, 'position4')"
                    (dragleave)="onDragLeave($event)"
                  ></div>
                </div>
              }
              <ng-template #layout>
                <div class="position-1" #position1>
                  <div
                    class="drop-section"
                    (drop)="onDrop($event, 'position1', currentVideo)"
                    (dragover)="allowDrop($event)"
                    (dragenter)="onDragEnter($event, 'position1')"
                    (dragleave)="onDragLeave($event)"
                  ></div>
                  <ng-container
                    [ngTemplateOutlet]="
                      item.positions['position1'] === 'title'
                        ? titleTemplate
                        : item.positions['position1'] === 'content'
                          ? content
                          : item.positions['position1'] === 'description'
                            ? description
                            : null
                    "
                    [ngTemplateOutletContext]="{ $implicit: item, slot: 'position1', index: i }"
                  ></ng-container>
                </div>
                @if (item.layout === layouts.EditorRight) {
                  <div class="editor-right-container">
                    <ng-container [ngTemplateOutlet]="midContent"></ng-container>
                  </div>
                }
                @if (item.layout !== layouts.EditorRight) {
                  <ng-container [ngTemplateOutlet]="midContent"></ng-container>
                }
                <ng-template #midContent>
                  <div class="position-2" #position2>
                    <div
                      class="drop-section"
                      (drop)="onDrop($event, 'position2', currentVideo)"
                      (dragover)="allowDrop($event)"
                      (dragenter)="onDragEnter($event, 'position2')"
                      (dragleave)="onDragLeave($event)"
                    ></div>
                    <ng-container
                      [ngTemplateOutlet]="
                        item.positions['position2'] === 'title'
                          ? titleTemplate
                          : item.positions['position2'] === 'content'
                            ? content
                            : item.positions['position2'] === 'description'
                              ? description
                              : null
                      "
                      [ngTemplateOutletContext]="{ $implicit: item, slot: 'position2', index: i }"
                    ></ng-container>
                  </div>
                  <div class="position-3" #position3>
                    <ng-container
                      [ngTemplateOutlet]="
                        item.positions['position3'] === 'title'
                          ? titleTemplate
                          : item.positions['position3'] === 'content'
                            ? content
                            : item.positions['position3'] === 'description'
                              ? description
                              : null
                      "
                      [ngTemplateOutletContext]="{ $implicit: item, slot: 'position3', index: i }"
                    ></ng-container>
                    <div
                      class="drop-section"
                      (drop)="onDrop($event, 'position3', currentVideo)"
                      (dragover)="allowDrop($event)"
                      (dragenter)="onDragEnter($event, 'position3')"
                      (dragleave)="onDragLeave($event)"
                    ></div>
                  </div>
                </ng-template>
                <div class="position-4" #position4>
                  <ng-container
                    [ngTemplateOutlet]="
                      item.positions['position4'] === 'title'
                        ? titleTemplate
                        : item.positions['position4'] === 'content'
                          ? content
                          : item.positions['position4'] === 'description'
                            ? description
                            : null
                    "
                    [ngTemplateOutletContext]="{ $implicit: item, slot: 'position4', index: i }"
                  ></ng-container>
                  <div
                    class="drop-section"
                    (drop)="onDrop($event, 'position4', currentVideo)"
                    (dragover)="allowDrop($event)"
                    (dragenter)="onDragEnter($event, 'position4')"
                    (dragleave)="onDragLeave($event)"
                  ></div>
                </div>
              </ng-template>
            </div>
          </div>
        }
      </div>
    </div>
    <section class="footer">
      @if (!!contextMenuValue) {
        <div class="buttons-container"></div>
        <div class="buttons-container alt-input">
          <input
            uiInput
            fixed
            type="text"
            placeholder="{{ contextMenuValue.name | translate }}"
            [value]="currentVideo[contextMenuValue.value || '']"
            #altTextInput
          />
          <button ui-button sm secondary (click)="cancelAltInput()">
            {{ 'shared.actions.cancel' | translate }}
          </button>
          <button ui-button sm (click)="setCurrentVideoAltValue(altTextInput)">
            {{ 'shared.actions.save' | translate }}
          </button>
        </div>
      } @else {
        <div class="buttons-container">
          @if (!isPreviewMode && !isReadonly) {
            <div class="alignment-btn-group">
              @for (layout of spaceLayouts; track layout) {
                <div
                  [ngClass]="layout.icon"
                  (click)="setCurrentVideoSpaceLayout(layout.value)"
                  [class.selected]="currentVideo.layout === layout.value"
                ></div>
              }
            </div>
            <ui-color-palette
              [type]="'icon'"
              (onChange)="setCurrentVideoBackgroundColor($event)"
              [color]="currentVideo.color.background"
              [isScenePlanPropertyRestriction]="scenePlan"
            >
            </ui-color-palette>
          }
        </div>
        <div class="pagination">
          <div class="icon icon-arrow-left-black left-arrow" (click)="previousItem()" [class.disabled]="!canScrollLeft"></div>
          <div class="counter">{{ currentPage + '/' + displayedVideos.length }}</div>
          <div class="icon icon-arrow-right-black right-arrow" [class.disabled]="!canScrollRight" (click)="nextItem()"></div>
        </div>
        <div class="buttons-container">
          @if (!isPreviewMode && !isReadonly && currentVideo.videoUrl) {
            <button [disabled]="!isVideoUploaded" ui-button sm secondary (click)="enterPreviewMode()">
              {{ 'shared.actions.preview' | translate }}
            </button>
          }
          @if (isPreviewMode && !isReadonly && currentVideo.videoUrl) {
            <button ui-button sm (click)="isPreviewMode = false">
              {{ 'shared.actions.edit' | translate }}
            </button>
          }
          @if (!isPreviewMode && !isReadonly && currentVideo.videoUrl) {
            <button [disabled]="!isVideoUploaded" ui-button sm (click)="save()">
              {{ 'shared.actions.save' | translate }}
            </button>
          }
          @if (!isPreviewMode && !isReadonly && !currentVideo.videoUrl) {
            <button [disabled]="(!currentVideo.yLink && !currentVideo.videoUrl) || videoLoading" ui-button sm (click)="next()">
              {{ 'shared.actions.next' | translate }}
            </button>
          }
        </div>
      }
      <ng-template #footer>
        <div class="buttons-container">
          @if (!isPreviewMode && !isReadonly) {
            <div class="alignment-btn-group">
              @for (layout of spaceLayouts; track layout) {
                <div
                  [ngClass]="layout.icon"
                  (click)="setCurrentVideoSpaceLayout(layout.value)"
                  [class.selected]="currentVideo.layout === layout.value"
                ></div>
              }
            </div>
            <ui-color-palette
              [type]="'icon'"
              (onChange)="setCurrentVideoBackgroundColor($event)"
              [color]="currentVideo.color.background"
              [isScenePlanPropertyRestriction]="scenePlan"
            >
            </ui-color-palette>
          }
        </div>
        <div class="pagination">
          <div class="icon icon-arrow-left-black left-arrow" (click)="previousItem()" [class.disabled]="!canScrollLeft"></div>
          <div class="counter">{{ currentPage + '/' + displayedVideos.length }}</div>
          <div class="icon icon-arrow-right-black right-arrow" [class.disabled]="!canScrollRight" (click)="nextItem()"></div>
        </div>
        <div class="buttons-container">
          @if (!isPreviewMode && !isReadonly && currentVideo.videoUrl) {
            <button [disabled]="!isVideoUploaded" ui-button sm secondary (click)="enterPreviewMode()">
              {{ 'shared.actions.preview' | translate }}
            </button>
          }
          @if (isPreviewMode && !isReadonly && currentVideo.videoUrl) {
            <button ui-button sm (click)="isPreviewMode = false">
              {{ 'shared.actions.edit' | translate }}
            </button>
          }
          @if (!isPreviewMode && !isReadonly && currentVideo.videoUrl) {
            <button [disabled]="!isVideoUploaded" ui-button sm (click)="save()">
              {{ 'shared.actions.save' | translate }}
            </button>
          }
          @if (!isPreviewMode && !isReadonly && !currentVideo.videoUrl) {
            <button [disabled]="(!currentVideo.yLink && !currentVideo.videoUrl) || videoLoading" ui-button sm (click)="next()">
              {{ 'shared.actions.next' | translate }}
            </button>
          }
        </div>
      </ng-template>
    </section>
  </section>
</div>
<ng-template #titleTemplate let-item let-slot="slot">
  <!-- Title view mode -->
  @if ((isPreviewMode || isReadonly) && !!title) {
    <div class="title-wrapper drag-item" [style.color]="item.color.text || 'transparent'">
      <div class="text none-text-transform" uiOverflowReadmore>
        {{ title }}
      </div>
    </div>
  }
  <!-- Title Edit mode  -->
  @if (!isPreviewMode && !isReadonly) {
    <div
      class="title-wrapper drag-item"
      [class.edit]="!isPreviewMode && !isReadonly"
      [draggable]="!isPreviewMode && !isReadonly && !!sectionDraggable['title']"
      (dragstart)="onDrag($event, item.layout, slot)"
      (dragend)="onDragend($event)"
    >
      @if (!!item.videoUrl) {
        <div class="drag-button" (mousedown)="sectionDraggable['title'] = true" (mouseup)="sectionDraggable['title'] = false">
          <i class="icon-drag"></i>
        </div>
      }
      <ui-color-palette
        [type]="'icon'"
        [color]="item.color.text"
        (onChange)="setCurrentVideoTextColor($event)"
        [color]="item.color.text"
        [position]="slot === 'position4' || slot === 'position3' ? 'top' : 'bottom'"
        [isScenePlanPropertyRestriction]="scenePlan"
      >
      </ui-color-palette>
      <input
        class="none-text-transform"
        uiInput
        fixed
        type="text"
        placeholder="{{ 'shared.fields.titlePlaceholder' | translate }}"
        [(ngModel)]="title"
        [maxlength]="80"
        [style.color]="item.color.text"
      />
    </div>
  }
</ng-template>
<ng-template #content let-item let-slot="slot" let-i="index">
  <!-- Content View Mode -->
  @if (isPreviewMode || isReadonly) {
    <div class="content drag-item">
      <div class="content-view-wrapper">
        @if (item.isYouTube && i === currentPage - 1) {
          <iframe
            [src]="item.iframeSrc | safe: 'url'"
            title="{{ item.altText }}"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        }
        @if (item.videoUrl && !item.isYouTube && i === currentPage - 1) {
          <ui-video-player [videoData]="item" [isIntoView]="i === currentPage - 1" [autoplay]="autoplay"></ui-video-player>
        }
      </div>
    </div>
  }
  <!-- Content Edit mode -->
  @if (!isPreviewMode && !isReadonly) {
    <div
      class="content drag-item"
      [draggable]="!isPreviewMode && !isReadonly && !!sectionDraggable['content']"
      (dragstart)="onDrag($event, item.layout, slot)"
      (dragend)="onDragend($event)"
    >
      <div class="drag-button" (mousedown)="sectionDraggable['content'] = true" (mouseup)="sectionDraggable['content'] = false">
        <i class="icon-drag"></i>
      </div>
      @if (item.videoUrl) {
        <div class="content-editor-wrapper">
          @if (!isDragging) {
            <div class="content-remove-button" (click)="item.videoUrl = ''">
              <div class="icon icon-close xs"></div>
            </div>
            @if (item.isYouTube) {
              <iframe
                [src]="item.videoUrl | safe: 'url'"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            }
            @if (item.videoUrl && !item.isYouTube) {
              <ui-video-player [videoData]="item" [autoplay]="false"></ui-video-player>
            }
            @if (item.showContextMenu) {
              <div class="context-menu" [style.top]="contextMenuPosition.y + 'px'" [style.left]="contextMenuPosition.x + 'px'">
                <ui-context-menu
                  [data]="contextMenuOptions"
                  (onSelect)="onContextMenuSelect($event)"
                  (hide)="item.showContextMenu = false"
                ></ui-context-menu>
              </div>
            }
            @if (item.showAltText) {
              <div class="alt-text" (uiClickOutside)="item.showAltText = false">
                <input uiInput uiAutofocus fixed type="text" [(ngModel)]="item.altText" />
              </div>
            }
          }
        </div>
      }
    </div>
  }
</ng-template>
<ng-template #description let-item let-slot="slot">
  @if (item.layout !== layouts.NoEditor) {
    @if ((isPreviewMode || isReadonly) && item.description) {
      <div class="editor-container drag-item description">
        <quill-view [preserveWhitespace]="true" class="w-100 view-mode" [content]="item.description"> </quill-view>
      </div>
    }
    @if (!isPreviewMode && !isReadonly) {
      <div
        class="editor-container drag-item description"
        [draggable]="!isPreviewMode && !isReadonly && !!sectionDraggable['description']"
        (dragstart)="onDrag($event, item.layout, slot)"
        (dragend)="onDragend($event)"
      >
        <div class="drag-button" (mousedown)="sectionDraggable['description'] = true" (mouseup)="sectionDraggable['description'] = false">
          <i class="icon-drag"></i>
        </div>
        <quill-editor
          class="w-100"
          [preserveWhitespace]="true"
          [(ngModel)]="item.description"
          [placeholder]="'oxr.creatingSpace.descriptionPlaceholder' | translate"
        >
          <ui-quill-editor-custom-toolbar quill-editor-toolbar [hide]="['link', 'image', 'video']"></ui-quill-editor-custom-toolbar>
        </quill-editor>
      </div>
    }
  }
</ng-template>
