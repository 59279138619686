import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { OxrSettingsService } from '../../oxr-settings.service';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { ScenePlan, ScenePlanDetail } from '@/data/src/lib/enums/pricing-plan';
import { PricingPlanService } from '@/data/src/lib/services/pricing-plan.service';

@Component({
  selector: 'oxr-space-description',
  templateUrl: './oxr-space-description.component.html',
  styleUrls: ['./oxr-space-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OxrSpaceDescriptionComponent implements OnInit {
  @Input() scene!: XRScene;

  isLoading = false;
  isAccessible = false;
  displayConcurrentUsers = false;
  chatOn = false;

  get isScenePlanChatPropertyRestriction() {
    return this._pricingPlanService.hasScenePlanPropertyRestriction(this.scene.Plan, ScenePlanDetail.LiveChat, true);
  }

  get isScenePlanPublishPropertyRestriction() {
    return this._pricingPlanService.hasScenePlanPropertyRestriction(this.scene.Plan, ScenePlanDetail.PublishSpace, true);
  }

  constructor(
    private _settingsService: OxrSettingsService,
    private _sceneService: SceneService,
    private _pricingPlanService: PricingPlanService,
  ) {}

  ngOnInit(): void {
    this.isAccessible = !!this.scene?.IsAccessible;
    this.displayConcurrentUsers = !!this.scene?.DisplayConcurrentUsers;
    this.chatOn = !!this.scene?.ChatOn;
  }

  onAccessibilityChange(isAccessible = false) {
    this.isAccessible = isAccessible;
  }

  async onSave() {
    this.isLoading = true;

    this.isAccessible ? await this._sceneService.publishScene(this.scene.Id) : await this._sceneService.unPublishScene(this.scene.Id);
    this.scene.IsAccessible = this.isAccessible;

    await this._settingsService.saveScene({
      DisplayConcurrentUsers: this.displayConcurrentUsers,
      ChatOn: this.chatOn,
    });

    this.isLoading = false;
    this._settingsService.closeDetails();
  }
}
