import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'ui-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckBoxComponent<T extends boolean | string> implements OnInit, OnChanges {
  @Input() checked!: T;
  @Input() disabled = false;
  @Output() checkedChange = new EventEmitter<T>();

  public id: string;
  public isChecked!: boolean;
  private isString = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.checked) {
      this.setIsChecked();
    }
  }

  ngOnInit(): void {
    this.id = uuid();
    this.isString = typeof this.checked === 'string';
    this.setIsChecked();
  }

  setIsChecked(): void {
    this.isChecked = this.isString ? this.checked.toString() == 'true' || this.checked.toString() !== '0' : (this.checked as boolean);
  }

  checkValue(event: boolean) {
    this.checkedChange.emit((this.isString ? (event ? '1' : '0') : event) as T);
  }
}
