import { __decorate } from "@babylonjs/core/tslib.es6.js";
import { serializeAsColor4, serializeAsVector3, serializeAsTexture, serialize, expandToProperty, serializeAsColor3, SerializationHelper } from "@babylonjs/core/Misc/decorators.js";
import { Vector3, TmpVectors } from "@babylonjs/core/Maths/math.vector.js";
import { MaterialDefines } from "@babylonjs/core/Materials/materialDefines.js";
import { MaterialHelper } from "@babylonjs/core/Materials/materialHelper.js";
import { PushMaterial } from "@babylonjs/core/Materials/pushMaterial.js";
import { VertexBuffer } from "@babylonjs/core/Buffers/buffer.js";
import { RegisterClass } from "@babylonjs/core/Misc/typeStore.js";
import { Color3, Color4 } from "@babylonjs/core/Maths/math.color.js";
import "./shaders/fluent.vertex.js";
import "./shaders/fluent.fragment.js";
/** @internal */
export class FluentMaterialDefines extends MaterialDefines {
  constructor() {
    super();
    this.INNERGLOW = false;
    this.BORDER = false;
    this.HOVERLIGHT = false;
    this.TEXTURE = false;
    this.rebuild();
  }
}
/**
 * Class used to render controls with fluent design
 */
export class FluentMaterial extends PushMaterial {
  /**
   * Creates a new Fluent material
   * @param name defines the name of the material
   * @param scene defines the hosting scene
   */
  constructor(name, scene) {
    super(name, scene);
    /**
     * Gets or sets inner glow intensity. A value of 0 means no glow (default is 0.5)
     */
    this.innerGlowColorIntensity = 0.5;
    /**
     * Gets or sets the inner glow color (white by default)
     */
    this.innerGlowColor = new Color3(1.0, 1.0, 1.0);
    /**
     * Gets or sets the albedo color (Default is Color3(0.3, 0.35, 0.4))
     */
    this.albedoColor = new Color3(0.3, 0.35, 0.4);
    /**
     * Gets or sets a boolean indicating if borders must be rendered (default is false)
     */
    this.renderBorders = false;
    /**
     * Gets or sets border width (default is 0.5)
     */
    this.borderWidth = 0.5;
    /**
     * Gets or sets a value indicating the smoothing value applied to border edges (0.02 by default)
     */
    this.edgeSmoothingValue = 0.02;
    /**
     * Gets or sets the minimum value that can be applied to border width (default is 0.1)
     */
    this.borderMinValue = 0.1;
    /**
     * Gets or sets a boolean indicating if hover light must be rendered (default is false)
     */
    this.renderHoverLight = false;
    /**
     * Gets or sets the radius used to render the hover light (default is 0.01)
     */
    this.hoverRadius = 0.01;
    /**
     * Gets or sets the color used to render the hover light (default is Color4(0.3, 0.3, 0.3, 1.0))
     */
    this.hoverColor = new Color4(0.3, 0.3, 0.3, 1.0);
    /**
     * Gets or sets the hover light position in world space (default is Vector3.Zero())
     */
    this.hoverPosition = Vector3.Zero();
  }
  needAlphaBlending() {
    return this.alpha !== 1.0;
  }
  needAlphaTesting() {
    return false;
  }
  getAlphaTestTexture() {
    return null;
  }
  isReadyForSubMesh(mesh, subMesh) {
    if (this.isFrozen) {
      if (subMesh.effect && subMesh.effect._wasPreviouslyReady) {
        return true;
      }
    }
    if (!subMesh.materialDefines) {
      subMesh.materialDefines = new FluentMaterialDefines();
    }
    const scene = this.getScene();
    const defines = subMesh.materialDefines;
    if (!this.checkReadyOnEveryCall && subMesh.effect) {
      if (defines._renderId === scene.getRenderId()) {
        return true;
      }
    }
    if (defines._areTexturesDirty) {
      defines.INNERGLOW = this.innerGlowColorIntensity > 0;
      defines.BORDER = this.renderBorders;
      defines.HOVERLIGHT = this.renderHoverLight;
      if (this._albedoTexture) {
        if (!this._albedoTexture.isReadyOrNotBlocking()) {
          return false;
        } else {
          defines.TEXTURE = true;
        }
      } else {
        defines.TEXTURE = false;
      }
    }
    const engine = scene.getEngine();
    // Get correct effect
    if (defines.isDirty) {
      defines.markAsProcessed();
      scene.resetCachedMaterial();
      //Attributes
      const attribs = [VertexBuffer.PositionKind];
      attribs.push(VertexBuffer.NormalKind);
      attribs.push(VertexBuffer.UVKind);
      const shaderName = "fluent";
      const uniforms = ["world", "viewProjection", "innerGlowColor", "albedoColor", "borderWidth", "edgeSmoothingValue", "scaleFactor", "borderMinValue", "hoverColor", "hoverPosition", "hoverRadius", "textureMatrix"];
      const samplers = ["albedoSampler"];
      const uniformBuffers = [];
      MaterialHelper.PrepareUniformsAndSamplersList({
        uniformsNames: uniforms,
        uniformBuffersNames: uniformBuffers,
        samplers: samplers,
        defines: defines,
        maxSimultaneousLights: 4
      });
      const join = defines.toString();
      subMesh.setEffect(scene.getEngine().createEffect(shaderName, {
        attributes: attribs,
        uniformsNames: uniforms,
        uniformBuffersNames: uniformBuffers,
        samplers: samplers,
        defines: join,
        fallbacks: null,
        onCompiled: this.onCompiled,
        onError: this.onError,
        indexParameters: {
          maxSimultaneousLights: 4
        }
      }, engine), defines, this._materialContext);
    }
    if (!subMesh.effect || !subMesh.effect.isReady()) {
      return false;
    }
    defines._renderId = scene.getRenderId();
    subMesh.effect._wasPreviouslyReady = true;
    return true;
  }
  bindForSubMesh(world, mesh, subMesh) {
    const scene = this.getScene();
    const defines = subMesh.materialDefines;
    if (!defines) {
      return;
    }
    const effect = subMesh.effect;
    if (!effect) {
      return;
    }
    this._activeEffect = effect;
    // Matrices
    this.bindOnlyWorldMatrix(world);
    this._activeEffect.setMatrix("viewProjection", scene.getTransformMatrix());
    if (this._mustRebind(scene, effect)) {
      this._activeEffect.setColor4("albedoColor", this.albedoColor, this.alpha);
      if (defines.INNERGLOW) {
        this._activeEffect.setColor4("innerGlowColor", this.innerGlowColor, this.innerGlowColorIntensity);
      }
      if (defines.BORDER) {
        this._activeEffect.setFloat("borderWidth", this.borderWidth);
        this._activeEffect.setFloat("edgeSmoothingValue", this.edgeSmoothingValue);
        this._activeEffect.setFloat("borderMinValue", this.borderMinValue);
        mesh.getBoundingInfo().boundingBox.extendSize.multiplyToRef(mesh.scaling, TmpVectors.Vector3[0]);
        this._activeEffect.setVector3("scaleFactor", TmpVectors.Vector3[0]);
      }
      if (defines.HOVERLIGHT) {
        this._activeEffect.setDirectColor4("hoverColor", this.hoverColor);
        this._activeEffect.setFloat("hoverRadius", this.hoverRadius);
        this._activeEffect.setVector3("hoverPosition", this.hoverPosition);
      }
      if (defines.TEXTURE && this._albedoTexture) {
        this._activeEffect.setTexture("albedoSampler", this._albedoTexture);
        const matrix = this._albedoTexture.getTextureMatrix();
        this._activeEffect.setMatrix("textureMatrix", matrix);
      }
    }
    this._afterBind(mesh, this._activeEffect);
  }
  getActiveTextures() {
    const activeTextures = super.getActiveTextures();
    return activeTextures;
  }
  hasTexture(texture) {
    if (super.hasTexture(texture)) {
      return true;
    }
    return false;
  }
  dispose(forceDisposeEffect) {
    super.dispose(forceDisposeEffect);
  }
  clone(name) {
    return SerializationHelper.Clone(() => new FluentMaterial(name, this.getScene()), this);
  }
  serialize() {
    const serializationObject = super.serialize();
    serializationObject.customType = "BABYLON.GUI.FluentMaterial";
    return serializationObject;
  }
  getClassName() {
    return "FluentMaterial";
  }
  // Statics
  static Parse(source, scene, rootUrl) {
    return SerializationHelper.Parse(() => new FluentMaterial(source.name, scene), source, scene, rootUrl);
  }
}
__decorate([serialize(), expandToProperty("_markAllSubMeshesAsTexturesDirty")], FluentMaterial.prototype, "innerGlowColorIntensity", void 0);
__decorate([serializeAsColor3()], FluentMaterial.prototype, "innerGlowColor", void 0);
__decorate([serializeAsColor3()], FluentMaterial.prototype, "albedoColor", void 0);
__decorate([serialize(), expandToProperty("_markAllSubMeshesAsTexturesDirty")], FluentMaterial.prototype, "renderBorders", void 0);
__decorate([serialize()], FluentMaterial.prototype, "borderWidth", void 0);
__decorate([serialize()], FluentMaterial.prototype, "edgeSmoothingValue", void 0);
__decorate([serialize()], FluentMaterial.prototype, "borderMinValue", void 0);
__decorate([serialize(), expandToProperty("_markAllSubMeshesAsTexturesDirty")], FluentMaterial.prototype, "renderHoverLight", void 0);
__decorate([serialize()], FluentMaterial.prototype, "hoverRadius", void 0);
__decorate([serializeAsColor4()], FluentMaterial.prototype, "hoverColor", void 0);
__decorate([serializeAsVector3()], FluentMaterial.prototype, "hoverPosition", void 0);
__decorate([serializeAsTexture("albedoTexture")], FluentMaterial.prototype, "_albedoTexture", void 0);
__decorate([expandToProperty("_markAllSubMeshesAsTexturesAndMiscDirty")], FluentMaterial.prototype, "albedoTexture", void 0);
RegisterClass("BABYLON.GUI.FluentMaterial", FluentMaterial);
