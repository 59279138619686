import { FileService } from '@/data/src/lib/services/file.service';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { UrlService } from '@/data/src/lib/services/url.service';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { GalleryToolComponent } from '@/ui/src/lib/modal/scene/gallery-tool/gallery-tool.component';
import { ApplicationService } from '@/view/src/app/app.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'ui-m-gallery-tool',
  templateUrl: './m-gallery-tool.component.html',
  styleUrls: ['./m-gallery-tool.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileGalleryToolComponent extends GalleryToolComponent implements OnInit, OnDestroy {
  @HostBinding('class.tablet') isTablet = false;
  containerWidth = 420;
  landscape: MediaQueryList | null = null;

  constructor(
    private _deviceService: DeviceDetectorService,
    _cd: ChangeDetectorRef,
    _modalService: ModalService,
    _appService: ApplicationService,
    public fileService: FileService,
    _urlService: UrlService,
    _sceneService: SceneService,
    _ngZone: NgZone,
    @Inject(WINDOW) private readonly _window: Window,
  ) {
    super(_modalService, _appService, _cd, fileService, _urlService, _sceneService, _ngZone);
    this.isTablet = this._deviceService.isTablet(); // otherwise it's mobile
  }

  defaultTouch = { x: 0, y: 0, time: 0 };

  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('touchcancel', ['$event'])
  handleTouch(event) {
    let touch = event.touches[0] || event.changedTouches[0];

    // check the events
    if (event.type === 'touchstart') {
      this.defaultTouch.x = touch.pageX;
      this.defaultTouch.y = touch.pageY;
      this.defaultTouch.time = event.timeStamp;
    } else if (event.type === 'touchend') {
      let deltaX = touch.pageX - this.defaultTouch.x;
      let deltaY = touch.pageY - this.defaultTouch.y;
      let deltaTime = event.timeStamp - this.defaultTouch.time;

      // simulte a swipe -> less than 500 ms and more than 60 px
      if (deltaTime < 500) {
        // touch movement lasted less than 500 ms
        if (Math.abs(deltaX) > 60) {
          // delta x is at least 60 pixels
          if (deltaX > 0) {
            this.doSwipeRight(event);
          } else {
            this.doSwipeLeft(event);
          }
        }
      }
    }
  }

  ngAfterViewInit() {
    this.container.nativeElement.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
    this.containerWidth = this.container.nativeElement.clientWidth;

    this._window.onresize = (event) => {
      this.containerWidth = this.container.nativeElement.clientWidth;
      this.scrollToPage(this.currentPage, 'auto');
    };
  }

  doSwipeLeft(event) {
    this.nextItem();
  }

  doSwipeRight(event) {
    this.previousItem();
  }

  get isPortrait() {
    this._deviceService.setDeviceInfo(); // update info
    return this._deviceService.orientation;
  }

  triggerAutoSlide(autoSlide) {}

  preventDefault(e) {
    e.preventDefault();
  }
}
