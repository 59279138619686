import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ModalService } from '@/ui/src/lib/modal/modal.service';

@Component({
  selector: 'ui-m-terms-and-conditions',
  templateUrl: './m-terms-and-conditions.component.html',
  styleUrls: ['./m-terms-and-conditions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileTermsAndConditionsComponent implements OnInit {
  modalContainerRef;

  constructor(private _modalService: ModalService) {}

  ngOnInit(): void {}

  onClose() {
    this._modalService.close('', this.modalContainerRef);
  }
}
