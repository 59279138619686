import { Component, Input, NgZone, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { InformationComponent } from '@/ui/src/lib/modal/information/information.component';
import { AccountService } from '@/data/src/lib/services/account.service';
import { ApplicationService } from '@/view/src/app/app.service';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PopupType, TInteractableElement } from '@/data/src/lib/view-manager';
import { interval, take } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'ui-contactform-tool',
  templateUrl: './contactform-tool.component.html',
  styleUrls: ['./contactform-tool.component.scss'],
  providers: [ModalService],
})
export class ContactformToolComponent implements OnInit {
  @Input() isEditMode = true; //To control close of PreviewMode on EditMode.
  @Input() isPlayerMode = false;

  form = new UntypedFormGroup({
    placeholderFirst: new UntypedFormControl(''),
    placeholderSecond: new UntypedFormControl(''),
    placeholderThird: new UntypedFormControl(''),
    placeholderFourth: new UntypedFormControl(''),
  });

  checkedAgree: boolean;

  keyValue: { [key: string]: string };
  data: { [key: string]: string };
  modalRef: ModalService;
  email: Promise<string | undefined>;
  selectedElement?: TInteractableElement;

  formAction = {
    PLACEHOLDERFIRST: {
      editMode: false,
      previousValue: '',
    },
    PLACEHOLDERSECOND: {
      editMode: false,
      previousValue: '',
    },
    PLACEHOLDERTHIRD: {
      editMode: false,
      previousValue: '',
    },
    PLACEHOLDERFOURTH: {
      editMode: false,
      previousValue: '',
    },
    SUCCESSMESSAGE: {
      editMode: false,
      previousValue: '',
    },
  };

  constructor(
    private _appService: ApplicationService,
    private _accountService: AccountService,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _sceneService: SceneService,
    private _ngZone: NgZone,
  ) {}

  async ngOnInit(): Promise<void> {
    this.email = this._accountService.getEmail();
  }

  closeModal() {
    this.modalRef.close(false);
  }

  onCheckedChange(result: any, key: string) {
    this.data[this.keyValue[key]] = result ? '1' : '0';
    if (key === 'ALRAMOPTIONALLCHECK') {
      if (result) {
        this.data[this.keyValue['ALRAMOPTIONOWNXR']] = '1';
        this.data[this.keyValue['ALRAMOPTIONEMAIL']] = '1';
        return;
      }
      this.data[this.keyValue['ALRAMOPTIONOWNXR']] = '0';
      this.data[this.keyValue['ALRAMOPTIONEMAIL']] = '0';
      return;
    }

    if (result) {
      this.data[this.keyValue['ALRAMOPTIONOWNXR']] === '1' && this.data[this.keyValue['ALRAMOPTIONEMAIL']] === '1'
        ? (this.data[this.keyValue['ALRAMOPTIONALLCHECK']] = '1')
        : null;
    } else {
      this.data[this.keyValue['ALRAMOPTIONALLCHECK']] = '0';
    }
  }

  convertStringToBoolean(value: string): boolean {
    if (!value || value === '0') {
      return false;
    } else {
      return true;
    }
  }

  openPreview() {
    this.isEditMode = false;
    this.checkedAgree = false;
    this.form.get(this.keyValue['PLACEHOLDERFIRST'])?.setValue('');
    this.form.get(this.keyValue['PLACEHOLDERSECOND'])?.setValue('');
    this.form.get(this.keyValue['PLACEHOLDERTHIRD'])?.setValue('');
    this.form.get(this.keyValue['PLACEHOLDERFOURTH'])?.setValue('');
  }

  async onPreviewSave() {
    const modalRef = this._modalService.open(InformationComponent);

    if (modalRef) {
      modalRef.instance.message = this.checkedAgree
        ? this.data[this.keyValue['SUCCESSMESSAGE']]
          ? this.data[this.keyValue['SUCCESSMESSAGE']]
          : this._translateService.instant('oxr.creatingSpace.contactForm.appreciate')
        : this._translateService.instant('oxr.creatingSpace.contactForm.checkAgree');

      this._ngZone.runOutsideAngular(() => {
        interval(2000)
          .pipe(take(1), untilDestroyed(this))
          .subscribe(() => {
            if (modalRef) {
              this._modalService.close(false, modalRef.instance['modalContainerRef']);
              this._ngZone.run(() => {});
            }
          });
      });
    }

    if (!this.isEditMode && this.isPlayerMode && this.checkedAgree) {
      await this.addComment(this.form.getRawValue());
      this._modalService.close(true, this['modalContainerRef']);
    }
  }

  onEditSave() {
    const oldData = this.selectedElement?.parameters?.popups?.length ? this.selectedElement.parameters.popups[0] : {};
    this.selectedElement &&
      this._appService.updateViewElement(
        {
          ...this.selectedElement,
          parameters: {
            ...this.selectedElement.parameters,
            popups: [
              {
                ...oldData,
                type: PopupType.Contact,
                parameters: [
                  ...Object.entries(this.data).map(([key, value]) => ({ key, value })),
                  ...Object.entries(this.keyValue).map(([key, value]) => ({ key, value: this.data[value] })),
                ],
              },
            ],
          },
        } as TInteractableElement,
        true,
      );
    this._modalService.close(true, this['modalContainerRef']);
  }

  async addComment(data: any) {
    this.selectedElement && this._appService.addContactFormEntry(this.selectedElement.id, data);
  }

  enableEditMode(key) {
    this.formAction[key].editMode = true;
    this.formAction[key].previousValue = this.data[this.keyValue[key]];
  }

  cancelEdit(key) {
    this.formAction[key].editMode = false;
    this.data[this.keyValue[key]] = this.formAction[key].previousValue;
  }

  confirmEdit(key) {
    this.formAction[key].editMode = false;
  }
}
