<section class="window">
  <div class="logo">
    <img class="ownxr" src="./../../../../assets/images/img-icon-ownxr-logo.png" />
    <div class="subtitle">{{ 'shared.information.editorControlsGuide' | translate }}</div>
  </div>

  <div class="row-1">
    <div class="move action">
      <div class="image-container">
        <img class="icon" src="./../../../../assets/images/img-icon-move-neonblue.png" />
      </div>
      <div class="text">{{ 'shared.actions.move' | translate }}</div>
    </div>
    <div class="zoom action">
      <div class="image-container">
        <img class="icon" src="./../../../../assets/images/img-icon-zoom-neonblue.png" />
      </div>
      <div class="text">{{ 'shared.actions.zoom' | translate }}</div>
    </div>
  </div>
  <div class="row-2">
    <div class="rotate action">
      <div class="image-container">
        <img class="icon" src="./../../../../assets/images/img-icon-rotate-neonblue.png" />
      </div>
      <div class="text">{{ 'shared.actions.rotate' | translate }}</div>
    </div>
    <div class="select action">
      <div class="image-container">
        <img class="icon" src="./../../../../assets/images/img-icon-select-neonblue.png" />
      </div>
      <div class="text">{{ 'shared.actions.select' | translate }}</div>
    </div>
  </div>
</section>

<button ui-button (click)="close()">
  {{ 'shared.actions.continue' | translate }}
</button>
