import { _Exporter } from "../glTFExporter.js";
import { PBRMaterial } from "@babylonjs/core/Materials/PBR/pbrMaterial.js";
const NAME = "KHR_materials_specular";
/**
 * [Specification](https://github.com/KhronosGroup/glTF/blob/main/extensions/2.0/Khronos/KHR_materials_specular/README.md)
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class KHR_materials_specular {
  constructor(exporter) {
    /** Name of this extension */
    this.name = NAME;
    /** Defines whether this extension is enabled */
    this.enabled = true;
    /** Defines whether this extension is required */
    this.required = false;
    this._wasUsed = false;
    this._exporter = exporter;
  }
  dispose() {}
  /** @internal */
  get wasUsed() {
    return this._wasUsed;
  }
  postExportMaterialAdditionalTextures(context, node, babylonMaterial) {
    const additionalTextures = [];
    if (babylonMaterial instanceof PBRMaterial) {
      if (this._isExtensionEnabled(babylonMaterial)) {
        if (babylonMaterial.metallicReflectanceTexture) {
          additionalTextures.push(babylonMaterial.metallicReflectanceTexture);
        }
        if (babylonMaterial.reflectanceTexture) {
          additionalTextures.push(babylonMaterial.reflectanceTexture);
        }
        return additionalTextures;
      }
    }
    return additionalTextures;
  }
  _isExtensionEnabled(mat) {
    // This extension must not be used on a material that also uses KHR_materials_unlit
    if (mat.unlit) {
      return false;
    }
    return mat.metallicF0Factor != undefined && mat.metallicF0Factor != 1.0 || mat.metallicReflectanceColor != undefined && !mat.metallicReflectanceColor.equalsFloats(1.0, 1.0, 1.0) || this._hasTexturesExtension(mat);
  }
  _hasTexturesExtension(mat) {
    return mat.metallicReflectanceTexture != null || mat.reflectanceTexture != null;
  }
  postExportMaterialAsync(context, node, babylonMaterial) {
    return new Promise(resolve => {
      var _a, _b;
      if (babylonMaterial instanceof PBRMaterial && this._isExtensionEnabled(babylonMaterial)) {
        this._wasUsed = true;
        node.extensions = node.extensions || {};
        const metallicReflectanceTexture = (_a = this._exporter._glTFMaterialExporter._getTextureInfo(babylonMaterial.metallicReflectanceTexture)) !== null && _a !== void 0 ? _a : undefined;
        const reflectanceTexture = (_b = this._exporter._glTFMaterialExporter._getTextureInfo(babylonMaterial.reflectanceTexture)) !== null && _b !== void 0 ? _b : undefined;
        const metallicF0Factor = babylonMaterial.metallicF0Factor == 1.0 ? undefined : babylonMaterial.metallicF0Factor;
        const metallicReflectanceColor = babylonMaterial.metallicReflectanceColor.equalsFloats(1.0, 1.0, 1.0) ? undefined : babylonMaterial.metallicReflectanceColor.asArray();
        const specularInfo = {
          specularFactor: metallicF0Factor,
          specularTexture: metallicReflectanceTexture,
          specularColorFactor: metallicReflectanceColor,
          specularColorTexture: reflectanceTexture,
          hasTextures: () => {
            return this._hasTexturesExtension(babylonMaterial);
          }
        };
        node.extensions[NAME] = specularInfo;
      }
      resolve(node);
    });
  }
}
_Exporter.RegisterExtension(NAME, exporter => new KHR_materials_specular(exporter));
