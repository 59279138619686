<ui-panel
  [title]="'oxr.creatingSpace.panels.sceneInteractions.title' | translate"
  [collapsing]="true"
  [panelId]="panelMask.SceneInteractions"
>
  <ui-switch
    class="icon-style-switch"
    [checked]="collisionsEnabled"
    [label]="'oxr.creatingSpace.panels.sceneInteractions.collisions' | translate"
    [onShowContentMode]="true"
    (changed)="onCollisionToggle($event)"
  ></ui-switch>
  <div class="separator"></div>
  <ui-switch
    class="icon-style-switch"
    [checked]="shadowsEnabled"
    [label]="'oxr.creatingSpace.panels.sceneInteractions.shadows' | translate"
    [onShowContentMode]="true"
    (changed)="onShadowToggle($event)"
  ></ui-switch>
</ui-panel>
