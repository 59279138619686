import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { ModalService } from '../modal.service';

@Component({
  selector: 'ui-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationComponent implements OnInit {
  message = '';

  classList = '';
  @HostBinding('class') get class() {
    return this.classList;
  }

  constructor(private modalRef: ModalService) {}

  ngOnInit(): void {}

  close() {
    this.modalRef.close(1);
  }
}
