import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, lastValueFrom, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@/app/src/environments/environment';
import { ConfirmationComponent } from '@/ui/src/lib/modal/confirmation/confirmation.component';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { ModelType } from '../models/data/base';
import { PopupContentUsage, XRScene, XRSceneInteraction } from '../models/data/scene';
import { privateFieldReplacer } from '../utils/json-utils';
import { ViewManagerUtils } from '../utils/view-manager-utils';
import { ScenePlan } from '../enums/pricing-plan';
import { SceneInteractionType } from '../enums/scene-interaction';
import { newModel } from '../factories/model-factory';
import { TElement } from '../view-manager';
import { BaseApiService } from './base-api.service';
import { AccountService } from './account.service';
import { CommunityListSortType } from './../enums/sort-type';

export interface PaginationResData {
  PaginationInfo: {
    Page: number;
    PageDataAmount: number;
    TotalDataCount: number;
    TotalPages: number;
  };
  SceneViewModelList: XRScene[];
}

/**
 * Tracks and manages the state of scene models and commits changes to the backend
 */
@Injectable({
  providedIn: 'root',
})
export class SceneService extends BaseApiService<XRScene> {
  private _likes$ = new BehaviorSubject<string[]>([]);
  public likes$ = this._likes$.asObservable();
  private _views$ = new BehaviorSubject<string[]>([]);
  public views$ = this._views$.asObservable();
  private _publicScenes$ = new BehaviorSubject<XRScene[]>([]);
  public publicScenes$ = this._publicScenes$.asObservable();
  private _interaction$ = new BehaviorSubject<XRSceneInteraction[]>([]);
  public interaction$ = this._interaction$.asObservable();
  private _popupContentsUsage$ = new BehaviorSubject<any>([]);
  public popupContentsUsage$ = this._popupContentsUsage$.asObservable();

  private noAuthHttp: HttpClient;
  private publicScenes!: XRScene[];

  constructor(
    public http: HttpClient,
    private handler: HttpBackend,
    accountService: AccountService,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _router: Router,
  ) {
    super(http, accountService, ModelType.Scene);
    this.noAuthHttp = new HttpClient(this.handler);
  }

  async getPublicScenesByPagination(sortType: CommunityListSortType, page: number, pageDataAmount: number, searchText?: string) {
    let searchQuery = searchText ? `keyword=${searchText}` : '';

    return await lastValueFrom(
      this.noAuthHttp
        .get<PaginationResData>(
          `${environment[this.accountService.region].crudApiUrl}/${
            this.modelType
          }/Public/PaginatedData?${searchQuery}&sort=${sortType}&page=${page}&pageDataAmount=${pageDataAmount}`,
        )
        .pipe(
          map((res: PaginationResData) => {
            this._publicScenes$.next(res.SceneViewModelList);
            return res;
          }),
        ),
    );
  }

  /**
   * Gets the scene mapped to current web address domain (uses origin address of http request)
   * path : test.ownxr.com
   * @returns the mapped scene if it exists
   */
  async getByDomain(path: string) {
    try {
      return await lastValueFrom(this.noAuthHttp.get<XRScene>(`${this.endPoint}/Domain/${path}`, { headers: this.httpHeaders }));
    } catch {
      return undefined;
    }
  }

  /**
   * Gets the chat session associated with the input scene
   * @param sceneId scene id
   * @returns the id of the chat session
   */
  async getSession(sceneId: string) {
    return this.http
      .get<string>(`${environment[this.accountService.region].crudApiUrl}/${this.modelType}/Session/${sceneId}`)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(() => {
          return of(undefined);
        }),
      )
      .toPromise();
  }

  async getById(id: string, noShowErrorMessage?: boolean): Promise<XRScene | undefined> {
    const result = this.modelState.get(id);
    if (result?.data) {
      this.selectedSubject.next(result.data);
    }
    if (!this.accountService.account) {
      return lastValueFrom(of(undefined));
    }
    return lastValueFrom(
      this.http.get<XRScene>(`${environment[this.accountService.region].crudApiUrl}/${this.modelType}/${id}`).pipe(
        map((data) => {
          if (data) {
            data = newModel(data, this.modelType) as XRScene;
            this.attach(data);

            this.updateDataSubject(data);
            this.selectedSubject.next(data);
          }
          return data;
        }),
      ),
    ).catch((error: HttpErrorResponse) => {
      if (!noShowErrorMessage) {
        const errorMsg = error.error && typeof error.error === 'string' ? error.error : '';
        if (error.status === 400 && error.error && errorMsg && errorMsg.toLowerCase().includes('expir')) {
          const modal = this._modalService.open(ConfirmationComponent);
          if (modal) {
            modal.instance.title = this._translateService.instant('shared.confirmation.sceneExpired');
            modal.instance.body = this._translateService.instant('shared.confirmation.thisSceneExpired');
            modal.instance.confirmAction = this._translateService.instant('shared.confirmation.goToOxr');
            modal.instance.showCancelAction = false;
          }

          modal.result.then(() => {
            this._router.navigate(['/', 'oxr']);
          });
        }
      }
      return undefined;
    });
  }

  /**
   * Gets all the publicly listed scenes
   * @param forceHttp - forces request to server
   * @returns array of public scenes
   */
  async getPublicScenes(forceHttp = false) {
    if (!this.publicScenes || forceHttp) {
      this.publicScenes = await lastValueFrom(
        this.noAuthHttp.get<XRScene[]>(`${environment[this.accountService.region].crudApiUrl}/${this.modelType}/Public`),
      );
      this._publicScenes$.next(this.publicScenes);
    }
    return this.publicScenes;
  }

  /**
   * Gets the version of a scene by the version id
   * @param id scene version id
   * @returns the scene
   */
  async getByVersionId(id: string): Promise<XRScene | undefined> {
    const state = this.modelState.get(id);
    if (state) {
      this.selectedSubject.next(state.data);
      return state.data;
    } else {
      const http = lastValueFrom(
        this.noAuthHttp.get<XRScene>(`${this.endPoint}/Version/${id}`).pipe(
          map((scene) => {
            const newItem = newModel(scene, this.modelType);
            newItem.setId(id);
            this.attach(newItem);

            this.selectedSubject.next(newItem);
            return newItem;
          }),
        ),
      );

      return http;
    }
  }

  /**
   * Gets the latest version of a scene by the scene id
   * @param id scene id
   * @returns the scene
   */
  async getLatestVersion(sceneId: string): Promise<XRScene | undefined> {
    return lastValueFrom(
      this.noAuthHttp.get<XRScene>(`${this.endPoint}/LatestVersion/${sceneId}`).pipe(
        map((scene) => {
          const newItem = newModel(scene, this.modelType);
          this.attach(newItem);
          this.selectedSubject.next(newItem);
          return newItem;
        }),
        catchError((error: HttpErrorResponse) => {
          const errorMsg = error.error && typeof error.error === 'string' ? error.error : '';
          if (error.status === 400 && error.error && errorMsg && errorMsg.toLowerCase().includes('expir')) {
            const modal = this._modalService.open(ConfirmationComponent);
            if (modal) {
              modal.instance.title = this._translateService.instant('shared.confirmation.sceneExpired');
              modal.instance.body = this._translateService.instant('shared.confirmation.thisSceneExpired');
              modal.instance.confirmAction = this._translateService.instant('shared.confirmation.goToCommunity');
              modal.instance.showCancelAction = false;
            }

            modal.result.then(() => {
              this._router.navigate(['/', 'community']);
            });
          }
          return of(undefined);
        }),
      ),
    );
  }

  async post(data: XRScene | XRScene[]) {
    if (data) {
      if (Array.isArray(data)) {
        for (const scene of data) {
          await this.post(scene);
        }
      } else {
        const result = await lastValueFrom(
          this.http.post(
            `${environment[this.accountService.region].crudApiUrl}/${this.modelType}`,
            JSON.stringify(data, privateFieldReplacer),
            this.postOptions,
          ),
        );
        if (result) {
          this.updateDataSubject(data);
        }
      }
    }
    return undefined;
  }

  async put(data: XRScene | XRScene[]) {
    if (data) {
      if (Array.isArray(data)) {
        data.forEach((d) => this.put(d));
      } else {
        const result = await lastValueFrom(
          this.http.put<XRScene>(
            `${environment[this.accountService.region].crudApiUrl}/${this.modelType}`,
            JSON.stringify(data, privateFieldReplacer),
            this.postOptions,
          ),
        );
        if (result) {
          data.setPropertyValue('_dateModified', result.DateModified);
          this.updateDataSubject(data);
        }
      }
    }
    return undefined;
  }

  async putName(data: XRScene) {
    if (data) {
      const result = await lastValueFrom(
        this.http.put(`${environment[this.accountService.region].crudApiUrl}/${this.modelType}/${data.Id}`, null, {
          ...this.postOptions,
          params: { sceneName: data.Name },
        }),
      );
      if (result) {
        this.updateDataSubject(data);
      }
    }
    return undefined;
  }

  /**
   * Gets the access of the scene, public or private
   * @param sceneId id of scene
   * @returns Public or Private
   */
  async getSceneAccess(sceneId: string) {
    const url = `${environment[this.accountService.region].crudApiUrl}/${this.modelType}/Accessibility/${sceneId}`;

    const result = await lastValueFrom(this.noAuthHttp.get<string>(url));
    return result;
  }

  /**
   * Creates a snapshot version
   * @param scene scene
   * @returns Public or Private
   */
  createNewVersion(sceneId: string): Observable<any> {
    return this.http.post(
      `${environment[this.accountService.region].crudApiUrl}/${this.modelType}/Version/${sceneId}`,
      null,
      this.postOptions,
    );
  }

  postSceneElements(sceneId: string, sceneElements: TElement[]) {
    return this.http.post(
      `${this.endPoint}/SceneElements`,
      {
        id: sceneId,
        sceneId,
        sceneElements: sceneElements.map((element) => ViewManagerUtils.FormatElementFromManager(sceneId, element)),
      },
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
  }

  /**
   * unPublishes a scene
   * @param sceneId id of scene to publish
   * @returns success or failure
   */
  async unPublishScene(sceneId: string) {
    const url = `${environment[this.accountService.region].crudApiUrl}/${this.modelType}/UnPublish/${sceneId}`;
    const result = await lastValueFrom(this.http.post(url, ''));
    return result;
  }

  /**
   * Publishes a scene
   * @param sceneId id of scene to publish
   * @returns the scene version id
   */
  async publishScene(sceneId: string) {
    const url = `${environment[this.accountService.region].crudApiUrl}/${this.modelType}/Publish/${sceneId}`;
    const result = await lastValueFrom(this.http.post(url, ''));
    return result;
  }

  /**
   * Gets a trial of the input plan
   * @param plan plan to get trial for
   * @returns the created trial scene
   */
  async getTrial(plan: ScenePlan) {
    const sceneId = await lastValueFrom(
      this.http.get<string>(`${environment[this.accountService.region].crudApiUrl}/${this.modelType}/Trial/${plan}`),
    );

    if (sceneId) {
      const scene = await this.getById(sceneId);
      return scene;
    } else {
      return undefined;
    }
  }

  /**
   * Changes the plan of the input scene
   * @param scene scene to set plan
   * @param plan new plan to associate with the scene
   * @returns the updated scene
   */
  async setTrial(scene: XRScene, plan: ScenePlan) {
    const result = await lastValueFrom(
      this.http.post<string>(`${environment[this.accountService.region].crudApiUrl}/${this.modelType}/Trial/${plan}`, scene.toJson()),
    );

    if (result) {
      scene.Plan = plan;
      return scene;
    } else {
      return undefined;
    }
  }

  /**
   * Get enterprise plan space of the input enterpriseId
   * @param plan enterprise plan to associate with the scene
   * @param enterpriseId id of the enterprise to create scene
   * @returns the created enterprise scene
   */
  async setEnterpriseTrial(enterpriseId: string) {
    return await lastValueFrom(
      this.http
        .post<XRScene>(
          `${environment[this.accountService.region].crudApiUrl}/${this.modelType}/CreateScene/Enterprise?enterpriseId=${enterpriseId}`,
          {},
        )
        .pipe(map(async (data) => (data.Id ? await this.getById(data.Id) : undefined))),
    );
  }

  /**
   * Gets the ids of the scenes the current user has liked
   * @returns array of scene ids
   */
  async getLikes() {
    return await lastValueFrom(
      this.http.get<string[]>(`${this.endPoint}/Likes`).pipe(
        map((data) => {
          this._likes$.next(data);
          return data;
        }),
      ),
    );
  }

  /**
   * Post an array of scene ids the user has liked
   * @param likes array of scene ids to like
   */
  async postLikes(likes: string[]) {
    return lastValueFrom(
      this.http
        .post(`${this.endPoint}/Likes`, JSON.stringify(likes), {
          headers: { 'Content-Type': 'application/json' },
        })
        .pipe(
          map(() => {
            this._likes$.next([...this._likes$.value, ...likes]);
          }),
        ),
    );
  }

  /**
   * Remove a like
   * @param like id of scene to like
   */
  async deleteLike(like: string) {
    return lastValueFrom(
      this.http.delete(`${this.endPoint}/Likes/${like}`).pipe(
        map(() => {
          const data = this._likes$.value;
          const index = data.indexOf(like);
          if (index >= 0) {
            data.splice(index, 1);
            this._likes$.next(data);
          }
        }),
      ),
    );
  }

  /**
   * Gets the ids of the scenes the current user has viewed
   * @returns array of scene ids
   */
  async getViews() {
    return await lastValueFrom(
      this.http.get<string[]>(`${this.endPoint}/Views`).pipe(
        map((data) => {
          this._likes$.next(data);
          return data;
        }),
      ),
    );
  }

  /**
   * Post an array of scene ids the user has viewed
   * @param likes array of scene ids to view
   */
  async postViews(views: string[]) {
    const account = await this.accountService.getActiveAccount();

    if (account) {
      return lastValueFrom(
        this.http
          .post(`${this.endPoint}/Views`, JSON.stringify(views), {
            headers: { 'Content-Type': 'application/json' },
          })
          .pipe(
            map(() => {
              // this._likes$.next([...this._likes$.value, ...views]);
            }),
          ),
      );
    } else {
      return lastValueFrom(
        this.noAuthHttp
          .post(`${this.endPoint}/Views`, JSON.stringify(views), {
            headers: { 'Content-Type': 'application/json' },
          })
          .pipe(
            map(() => {
              // this._likes$.next([...this._likes$.value, ...views]);
            }),
          ),
      );
    }
  }

  /**
   * Gets all recorded user interactions made with the scene
   * @param sceneId id of scene
   * @param instanceId id of instance the interaction has an association with
   * @param type type of interaction
   * @returns an array of user interactions
   */
  getInteractions(sceneId: string, type: SceneInteractionType, sceneElementId?: string) {
    return lastValueFrom(
      this.noAuthHttp
        .get<XRSceneInteraction[]>(
          `${this.endPoint}/Interaction/${sceneId}?sceneElementId=${sceneElementId ? sceneElementId : ''}&type=${type}`,
        )
        .pipe(
          map((data) => {
            this._interaction$.next(data);
            return data;
          }),
        ),
    );
  }

  /**
   * Post a user scene interaction
   * @param interaction the user interaction with the scene
   */
  async postInteraction(interaction: XRSceneInteraction) {
    const account = await this.accountService.getActiveAccount();
    if (account) {
      await lastValueFrom(
        this.http.post<XRSceneInteraction>(`${this.endPoint}/Interaction`, JSON.stringify(interaction), {
          headers: { 'Content-Type': 'application/json' },
        }),
      );
    } else {
      await lastValueFrom(
        this.noAuthHttp.post<XRSceneInteraction>(`${this.endPoint}/Interaction`, JSON.stringify(interaction), {
          headers: { 'Content-Type': 'application/json' },
        }),
      );
    }
  }

  /**
   * Delete a user scene interaction
   * The administrator of the scene has all delete rights. Visitors to the scene have the right to delete only what they have created.
   * @param interactionIds interactionId array
   */
  async deleteInteraction(interactionIds: string[]) {
    await lastValueFrom(
      this.http.post<XRSceneInteraction>(`${this.endPoint}/Interaction/Delete`, JSON.stringify(interactionIds), {
        headers: { 'Content-Type': 'application/json' },
      }),
    );
  }

  /**
   * Patch a user scene interaction
   * @param interaction the user interaction with the scene
   */
  async patchInteraction(interaction: XRSceneInteraction) {
    await lastValueFrom(
      this.http.patch<XRSceneInteraction>(`${this.endPoint}/Interaction`, JSON.stringify(interaction), {
        headers: { 'Content-Type': 'application/json' },
      }),
    );
  }

  async CheckCommentOfInteraction(comment: string) {
    return await lastValueFrom(
      this.noAuthHttp.get(`${this.endPoint}/Interaction/CheckComment?comment=${comment}`, { responseType: 'text' }),
    );
  }

  async getSubscriptionId(sceneIds: string[]) {
    return await lastValueFrom(
      this.http.post<{ SceneId: string; SubscriptionId: string }[]>(`${this.endPoint}/Subscriptions`, sceneIds, {
        headers: { 'Content-Type': 'application/json' },
      }),
    );
  }

  /**
   * Gets the latest version of a scene by the scene id
   * @param id scene id
   * @returns the scene
   */
  async getPublishedVersion(sceneId: string): Promise<XRScene | undefined> {
    return lastValueFrom(
      this.noAuthHttp.get<XRScene>(`${this.endPoint}/GetLatestPublishedVersion/${sceneId}`).pipe(
        map((scene) => {
          const newItem = newModel(scene, this.modelType);
          this.attach(newItem);
          this.selectedSubject.next(newItem);
          return newItem;
        }),
        catchError((error: HttpErrorResponse) => {
          const errorMsg = error.error && typeof error.error === 'string' ? error.error : '';
          if (error.status === 400 && error.error && errorMsg && errorMsg.toLowerCase().includes('expir')) {
            const modal = this._modalService.open(ConfirmationComponent);
            if (modal) {
              modal.instance.title = this._translateService.instant('shared.confirmation.sceneExpired');
              modal.instance.body = this._translateService.instant('shared.confirmation.thisSceneExpired');
              modal.instance.confirmAction = this._translateService.instant('shared.confirmation.goToCommunity');
              modal.instance.showCancelAction = false;
            }

            modal.result.then(() => {
              this._router.navigate(['/', 'community']);
            });
          }
          return of(undefined);
        }),
      ),
    );
  }

  getPopupContentsUsage(sceneId: string) {
    return lastValueFrom(
      this.http.get<PopupContentUsage>(`${this.endPoint}/${sceneId}/PopupContentUsage`).pipe(
        map((data) => {
          this._popupContentsUsage$.next(data);
          return data;
        }),
      ),
    );
  }

  getSceneInqueries(sceneId: string) {
    return this.http.get(`${this.endPoint}/${sceneId}/Inquiries`);
  }
}
