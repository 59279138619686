import { v4 as uuid } from 'uuid';
import { environment as env } from '@/app/src/environments/environment';
import { DataCacheService } from '@/data/src/lib/services/data-cache.service';
import { TransactionService } from '@/data/src/lib/services/transaction.service';
import { ApplicationService } from '@/view/src/app/app.service';
import { BasePlugin } from '@/view/src/app/plugins/base-plugin';
import { PlayerService } from '@/view/src/app/services/player.service';
import { ElementsTransaction } from '@/view/src/app/transactions/elements-transaction';
import { XRAsset } from '@/data/src/lib/models/data/asset';
import { ModelType } from '@/data/src/lib/models/data/base';
import { IScene } from '@/data/src/lib/models/data/scene';
import { IAsset } from '@/data/src/lib/models/data/asset';
import { ViewManagerUtils } from '@/data/src/lib/utils/view-manager-utils';
import { BoundingHelper } from '@/data/src/lib/utils/geometry-utils';
import {
  CheckpointRenderable,
  EnvironmentRenderable,
  LandscapeRenderable,
  LightRenderable,
  ObjectRenderable,
  ScreenRenderable,
  StageRenderable,
  TextRenderable,
} from './renderables';
import { XRScene } from '../models/data/scene';
import {
  AbstractMesh,
  AnimationGroup,
  ArcRotateCamera,
  Color4,
  Constants,
  CubeTexture,
  DynamicTexture,
  Engine,
  ICanvasRenderingContext,
  Mesh,
  MeshBuilder,
  Nullable,
  Observable,
  Observer,
  PBRMaterial,
  Plane,
  Scene,
  StandardMaterial,
  Texture,
  TransformNode,
  UniversalCamera,
  UtilityLayerRenderer,
  Vector3,
  Viewport,
  WebXRCamera,
  WebXRDefaultExperience,
  WebXRSessionManager,
} from '../babylon';
import {
  ElementType,
  MediaControl,
  TCheckpointElement,
  TElement,
  TEnvironmentElement,
  TLandscapeElement,
  TLightElement,
  TObjectElement,
  TRenderable,
  TScreenElement,
  TStageElement,
  TTextElement,
  ViewMode,
  ViewTool,
} from './types';
import { InteractableRenderable } from './renderables';
import { CustomizeTool, InsertTool, SelectTool, SnapTool } from './tools';
import { CreatorCameraInput, CreatorModeControls, PlayerModeControls, VrModeControls } from './controls';
import {
  ANIMATIONS_ASSET_ID,
  CREATOR_CAMERA_ALPHA,
  CREATOR_CAMERA_BETA,
  CREATOR_CAMERA_RADIUS,
  EDITOR_CAMERA_ALPHA,
  EDITOR_CAMERA_BETA,
  EDITOR_CAMERA_RADIUS,
  EDITOR_COLOR,
  LOAD_BUTTON_PNG,
  MAX_SIZE,
  SELECTION_COLOR,
} from './constants';

export class ViewManager {
  public scene: Scene;
  public engine: Engine;

  private tools: Map<ViewTool, CustomizeTool | SelectTool | InsertTool | SnapTool>;
  private activeTool: Nullable<CustomizeTool | SelectTool | InsertTool | SnapTool>;
  private beforeRenderObserver: Nullable<Observer<Scene>>;
  private _creatorCamera: ArcRotateCamera;
  private _playerCamera: UniversalCamera;
  private _vrCamera: WebXRCamera;

  public creatorModeControls: CreatorModeControls;
  public playerModeControls: PlayerModeControls;
  public vrModeControls: VrModeControls;

  public elements: TElement[] = [];
  public activeMode: ViewMode;
  public targetMode: ViewMode;
  public sceneModel: XRScene;

  public checkpoints: CheckpointRenderable[] = [];
  public environments: EnvironmentRenderable[] = [];
  public landscapes: LandscapeRenderable[] = [];
  public lights: LightRenderable[] = [];
  public objects: ObjectRenderable[] = [];
  public screens: ScreenRenderable[] = [];
  public stages: StageRenderable[] = [];
  public texts: TextRenderable[] = [];

  public boundary: Mesh;
  public boundaryEnabled: boolean;
  public drawingPlane: Mesh;
  public toolsEnabled: boolean;
  public utilityEnabled: boolean;

  public supportsXR: boolean;
  public xrExperience: Nullable<WebXRDefaultExperience>;

  public illuminationTexture: CubeTexture;
  public _textMeasurementContext: ICanvasRenderingContext;
  public _badgeLayer: UtilityLayerRenderer;
  public _gizmoLayer: UtilityLayerRenderer;
  public _boundingHelper: Nullable<BoundingHelper>;
  public _animationAsset?: XRAsset;
  public _avatarAnimationGroups: AnimationGroup[];
  private _dummyNode: TransformNode;

  public _occlusionMaterial: Nullable<StandardMaterial>;
  public _checkpointMaterial: Nullable<PBRMaterial>;
  public _screenMaterial: Nullable<StandardMaterial>;
  public _loadButtonMaterial: Nullable<StandardMaterial>;
  public _durationContainerMaterial: Nullable<StandardMaterial>;
  public _durationBarMaterial: Nullable<StandardMaterial>;
  public _videoControllerMaterial: Nullable<StandardMaterial>;
  public _volumeContainerMaterial: Nullable<StandardMaterial>;
  public _volumeBarMaterial: Nullable<StandardMaterial>;
  public _buttonMaterials: {
    [key: string]: Nullable<StandardMaterial>;
  } = {};

  public plugins: BasePlugin[] = [];
  public undoStack: ElementsTransaction[] = [];
  public redoStack: ElementsTransaction[] = [];
  public onTransaction: Observable<ElementsTransaction>;
  public onRollback!: Nullable<Observable<ElementsTransaction>>;

  constructor(
    public canvas: HTMLCanvasElement,
    public model: IAsset | IScene,
    public appService: ApplicationService,
    public isHandHeldDevice: boolean,
  ) {
    this.dispose = this.dispose.bind(this);
    this.initialize = this.initialize.bind(this);
    this.recountPolygons = this.recountPolygons.bind(this);
    this.setMode = this.setMode.bind(this);
    this.setTool = this.setTool.bind(this);
    this.updateRenderable = this.updateRenderable.bind(this);
  }

  initialize() {
    this.engine = new Engine(this.canvas, true, {
      preserveDrawingBuffer: true,
      stencil: true,
      antialias: !this.isHandHeldDevice,
    });
    this.loadXRPolyfill().finally(() => {
      WebXRSessionManager.IsSessionSupportedAsync('immersive-vr').then((supported) => {
        this.supportsXR = supported;
        this.appService.xrSupportSubject.next(this.supportsXR);
      });
    });

    this.scene = new Scene(this.engine);
    this.scene.useRightHandedSystem = true;
    this.scene.clearColor.copyFrom(Color4.FromHexString(EDITOR_COLOR));

    this.scene.setRenderingAutoClearDepthStencil(1, false);
    this.scene.setRenderingAutoClearDepthStencil(2, false);
    this.scene.setRenderingAutoClearDepthStencil(3, false);

    this.onTransaction = new Observable<ElementsTransaction>(() => {});
    this.onRollback = new Observable<ElementsTransaction>(() => {});

    this.utilityEnabled = true;

    this.sceneModel = this.appService.getActiveModel() as XRScene;

    this.tools = new Map();

    if (location.href.includes('/oxr/space/')) {
      this.toolsEnabled = true;
      this.boundaryEnabled = true;
      this.setMode(ViewMode.Creator);
      // TODO: Set when requested
      this.tools.set(ViewTool.Customize, new CustomizeTool(this));
      this.tools.set(ViewTool.Insert, new InsertTool(this));
      this.tools.set(ViewTool.Select, new SelectTool(this));
      this.tools.set(ViewTool.Snap, new SnapTool(this));
    } else if (location.href.includes('/editor/')) {
      this.toolsEnabled = true;
      this.setMode(ViewMode.Editor);
      // this.tools.set(ViewTool.Customize, new CustomizeTool(this));
      this.tools.set(ViewTool.Select, new SelectTool(this));
    } else if (location.href.includes('/market/')) {
      this.setMode(ViewMode.Editor);
    } else {
      this.boundaryEnabled = true;
      this.setMode(ViewMode.Player);
    }

    if (this.targetMode === ViewMode.Creator) {
      this.drawingPlane = MeshBuilder.CreatePlane('ManagerDrawingPlane', { size: 1000, sourcePlane: new Plane(0, 1, 0, 0) }, this.scene);
      ViewManagerUtils.AttachMetadata(this.drawingPlane, { helperOf: this });
      this.drawingPlane.visibility = 0.00001;
      this.drawingPlane.checkCollisions = true;
      this.drawingPlane.collisionMask = 2 ** this.drawingPlane.uniqueId;
      this.drawingPlane.collisionGroup = this.drawingPlane.uniqueId;
    }

    if (this.boundaryEnabled) {
      this.boundary = MeshBuilder.CreateBox('ViewManagerBoundary', { size: MAX_SIZE * 2, sideOrientation: Mesh.BACKSIDE }, this.scene);
      ViewManagerUtils.AttachMetadata(this.boundary, { helperOf: this });
      this.boundary.visibility = 0.00001;
      this.boundary.isPickable = false;
      this.boundary.position.y = MAX_SIZE + 0.05;
      this.boundary.checkCollisions = true;
      this.boundary.collisionMask = 2 ** this.boundary.uniqueId;
      this.boundary.collisionGroup = this.boundary.uniqueId;
    }

    this.beforeRenderObserver = this.scene.onBeforeRenderObservable.add(() => {
      this.updateView(this.appService.getViewElements(), this.elements);
    });

    this.scene.onBeforeRenderObservable.addOnce(() => {
      this.tool?.interact();
      this.setMode(this.activeMode);
    });
    this.scene.useDelayedTextureLoading = false;

    this.toolsEnabled && this.setTool(ViewTool.Select);
  }

  updateView(targetElements: TElement[], currentElements: TElement[] = []) {
    let occurence: TElement | undefined;
    let diff: string[];
    targetElements.forEach((element) => {
      occurence = currentElements.find(({ id }) => id === element.id);
      if (occurence) {
        diff = ViewManagerUtils.PropsDiff(element, occurence);
        if (diff.length) {
          this.updateRenderable(element);
        }
      } else {
        this.createRenderable(element);
      }
      return;
    });

    currentElements.forEach((element) => {
      if (!targetElements.find(({ id }) => id === element.id)) {
        this.removeRenderable(element);
      }
    });

    currentElements.length = 0;
    currentElements.push(...targetElements);
  }

  refresh() {
    switch (this.activeMode) {
      case ViewMode.Creator:
        this.creatorCamera.restoreState();
        break;
      case ViewMode.Editor:
        if (this.objects[0]) {
          ViewManagerUtils.FocusOnRenderable(this.objects[0]);
        } else if (this.environments[0]) {
          this.creatorCamera.target.setAll(0);
          this.creatorCamera.radius = EDITOR_CAMERA_RADIUS;
        }
        this.creatorCamera.alpha = EDITOR_CAMERA_ALPHA;
        this.creatorCamera.beta = EDITOR_CAMERA_BETA;
        break;
      case ViewMode.Player:
      case ViewMode.VR:
        this.elements.forEach(this.updateRenderable);
        break;
    }
  }

  createRenderable(element: TElement, addInPlace = true, source: File | string | Mesh | undefined = undefined) {
    // TODO: Cache 'removed' elements and reuse
    switch (element.type) {
      case ElementType.Checkpoint:
        const checkpointRenderable = new CheckpointRenderable(element as TCheckpointElement, this);
        addInPlace && this.checkpoints.push(checkpointRenderable);
        return checkpointRenderable;
      case ElementType.Environment:
        const environmentRenderable = new EnvironmentRenderable(element as TEnvironmentElement, this);
        addInPlace && this.environments.push(environmentRenderable);
        return environmentRenderable;
      case ElementType.Landscape:
        const landscapeRenderable = new LandscapeRenderable(element as TLandscapeElement, this);
        addInPlace && this.landscapes.push(landscapeRenderable);
        return landscapeRenderable;
      case ElementType.Light:
        const lightRenderable = new LightRenderable(element as TLightElement, this);
        addInPlace && this.lights.push(lightRenderable);
        return lightRenderable;
      case ElementType.Object:
        const objectRenderable = new ObjectRenderable(element as TObjectElement, this, source);
        addInPlace && this.objects.push(objectRenderable);
        return objectRenderable;
      case ElementType.Screen:
        const screenRenderable = new ScreenRenderable(element as TScreenElement, this);
        addInPlace && this.screens.push(screenRenderable);
        return screenRenderable;
      case ElementType.Stage:
        const stageRenderable = new StageRenderable(element as TStageElement, this);
        addInPlace && this.stages.push(stageRenderable);
        return stageRenderable;
      case ElementType.Text:
        const textRenderable = new TextRenderable(element as TTextElement, this);
        addInPlace && this.texts.push(textRenderable);
        return textRenderable;
      default:
        console.log(`Failed creating ${element.name} of type ${element.type}`);
        return undefined;
    }
  }

  updateRenderable(element: TElement) {
    let renderable: TRenderable | undefined = undefined;
    switch (element.type) {
      case ElementType.Checkpoint:
        renderable = this.checkpoints.find((checkpoint) => checkpoint.elementId === element.id);
        break;
      case ElementType.Environment:
        renderable = this.environments.find((environment) => environment.elementId === element.id);
        break;
      case ElementType.Landscape:
        renderable = this.landscapes.find((landscape) => landscape.elementId === element.id);
        break;
      case ElementType.Light:
        renderable = this.lights.find((light) => light.elementId === element.id);
        break;
      case ElementType.Object:
        renderable = this.objects.find((obj) => obj.elementId === element.id);
        break;
      case ElementType.Screen:
        renderable = this.screens.find((screen) => screen.elementId === element.id);
        break;
      case ElementType.Stage:
        renderable = this.stages.find((stage) => stage.elementId === element.id);
        break;
      case ElementType.Text:
        renderable = this.texts.find((text) => text.elementId === element.id);
        break;
      default:
        throw new Error(`Failed updating ${element.name} of type ${element.type}`);
    }
    if (renderable) {
      renderable.update(element);
    }
  }

  removeRenderable(element: TElement) {
    switch (element.type) {
      case ElementType.Checkpoint:
        const c = this.checkpoints.findIndex((renderable) => renderable.elementId === element.id);
        const checkpoint = this.checkpoints[c];
        if (checkpoint) {
          checkpoint.dispose();
          this.checkpoints.splice(c, 1);
        }
        break;
      case ElementType.Environment:
        const e = this.environments.findIndex((renderable) => renderable.elementId === element.id);
        const environment = this.environments[e];
        if (environment) {
          environment.dispose();
          this.environments.splice(e, 1);
        }
        break;
      case ElementType.Landscape:
        const ls = this.landscapes.findIndex((renderable) => renderable.elementId === element.id);
        const landscape = this.landscapes[ls];
        if (landscape) {
          landscape.dispose();
          this.landscapes.splice(ls, 1);
        }
        break;
      case ElementType.Light:
        const l = this.lights.findIndex((renderable) => renderable.elementId === element.id);
        const light = this.lights[l];
        if (light) {
          light.dispose();
          this.lights.splice(l, 1);
        }
        break;
      case ElementType.Object:
        const o = this.objects.findIndex((renderable) => renderable.elementId === element.id);
        const obj = this.objects[o];
        if (obj) {
          obj.dispose();
          this.objects.splice(o, 1);
        }
        break;
      case ElementType.Screen:
        const sc = this.screens.findIndex((renderable) => renderable.elementId === element.id);
        const screen = this.screens[sc];
        if (screen) {
          screen.dispose();
          this.screens.splice(sc, 1);
        }
        break;
      case ElementType.Stage:
        const st = this.stages.findIndex((renderable) => renderable.elementId === element.id);
        const stage = this.stages[st];
        if (stage) {
          stage.dispose();
          this.stages.splice(st, 1);
        }
        break;
      case ElementType.Text:
        const t = this.texts.findIndex((renderable) => renderable.elementId === element.id);
        const text = this.texts[t];
        if (text) {
          text.dispose();
          this.texts.splice(t, 1);
        }
        break;
      default:
        break;
    }
  }

  setMode(mode: ViewMode) {
    this.targetMode = mode;
    if (this.targetMode === this.activeMode) {
      return;
    }

    switch (this.targetMode) {
      case ViewMode.Creator:
        this.elements.forEach(this.updateRenderable);
        this.setTool(ViewTool.Select);
        this._playerCamera && this.playerModeControls?.detach();
        this._vrCamera && this.vrModeControls?.detach();
        if (!this.creatorModeControls) {
          this.creatorModeControls = new CreatorModeControls(this);
        }
        this.creatorModeControls?.attach();
        this.checkpoints.forEach((checkpoint) => {
          checkpoint.showHelper();
        });
        this.scene.collisionsEnabled = false;
        break;
      case ViewMode.Editor:
        this.elements.forEach(this.updateRenderable);
        this._playerCamera && this.playerModeControls?.detach();
        this._vrCamera && this.vrModeControls?.detach();
        if (!this.creatorModeControls) {
          this.creatorModeControls = new CreatorModeControls(this);
        }
        this.creatorModeControls?.attach();
        this.checkpoints.forEach((checkpoint) => {
          checkpoint?.hideHelper();
        });
        this.scene.collisionsEnabled = false;
        break;
      case ViewMode.Player:
        this.activeMode !== ViewMode.VR && this.elements.forEach(this.updateRenderable);
        this.activeTool?.clear();
        this.activeTool = null;
        this._creatorCamera && this.creatorModeControls?.detach();
        this._vrCamera && this.vrModeControls?.detach();
        if (!this.playerModeControls) {
          this.playerModeControls = new PlayerModeControls(this);
        }
        this.playerModeControls.attach();
        [...this.checkpoints, ...this.lights].forEach((renderable) => {
          renderable?.hideHelper();
        });
        this.scene.collisionsEnabled = true;
        if (this.boundary) {
          this.boundary.position.y = Math.max(
            [...this.landscapes, ...this.stages]
              .map((r) => r.helper?.getBoundingInfo().boundingBox.minimumWorld.y)
              .reduce((p, c) => Math.max(p, c), -Infinity),
            this.checkpoints.map((checkpoint) => checkpoint.helper?.position.y).reduce((p, c) => Math.max(p, c), -Infinity) - 1,
            this.boundary.position.y,
          );
        }
        break;
      case ViewMode.VR:
        this.activeMode !== ViewMode.Player && this.elements.forEach(this.updateRenderable);
        this._creatorCamera && this.creatorModeControls?.detach();
        this._playerCamera && this.playerModeControls?.detach();
        if (!this.vrModeControls) {
          this.vrModeControls = new VrModeControls(this);
        }
        this.vrModeControls.attach();
        break;
      default:
        break;
    }

    this.activeMode = mode;
  }

  setTool(tool: ViewTool, payload: TElement[] = []) {
    if (tool === this.activeTool?.name || (tool === ViewTool.Snap && payload.some(({ type }) => type === ElementType.Checkpoint))) {
      return;
    }
    this.tool?.clear();
    const selectedTool = this.tools.get(tool);
    if (selectedTool) {
      this.activeTool = selectedTool;
      this.activeTool.setup(payload);
    }
    this.appService.setViewManager(this);
  }

  focus() {
    if (this.tool instanceof SelectTool || this.tool instanceof CustomizeTool) {
      this.tool.focus();
    }
  }

  getMediaButtonMaterial(name: MediaControl) {
    if (!this._buttonMaterials[name]) {
      this._buttonMaterials[name] = new StandardMaterial(uuid(), this.scene);

      this._buttonMaterials[name]!.alpha = 1;
      if (this._buttonMaterials[name]!.diffuseTexture) {
        this._buttonMaterials[name]!.diffuseTexture!.hasAlpha = true;
      }
      if (this._buttonMaterials[name]!.useAlphaFromDiffuseTexture) {
        this._buttonMaterials[name]!.useAlphaFromDiffuseTexture = false;
      }

      let url = '';
      switch (name) {
        case MediaControl.Play:
          url = env.kr.fileStorageUrl + '/public/icon-play.png';
          break;
        case MediaControl.Pause:
          url = env.kr.fileStorageUrl + '/public/icon-pause.png';
          break;
        case MediaControl.Stop:
          url = env.kr.fileStorageUrl + '/public/icon-stop.png';
          break;
        case MediaControl.FastForward:
          url = env.kr.fileStorageUrl + '/public/icon-skip.png';
          break;
        case MediaControl.SoundMax:
          url = env.kr.fileStorageUrl + '/public/icon-sound-max.png';
          break;
        case MediaControl.SoundMin:
          url = env.kr.fileStorageUrl + '/public/icon-sound-min.png';
          break;
        case MediaControl.SoundZero:
          url = env.kr.fileStorageUrl + '/public/icon-sound-zero.png';
          break;
        case MediaControl.Unmute:
          url = env.kr.fileStorageUrl + '/public/icon-sound-mute.png';
          break;
        case MediaControl.Fullscreen:
          url = env.kr.fileStorageUrl + '/public/icon-zoom-in.png';
          break;
        default:
          break;
      }
      const texture = new Texture(url, this.scene, undefined, undefined, undefined, () => {
        this._buttonMaterials[name]!.diffuseTexture = texture;
        this._buttonMaterials[name]!.opacityTexture = texture;
        this._buttonMaterials[name]!.emissiveColor.set(1, 1, 1);
        this._buttonMaterials[name]!.specularColor.set(0, 0, 0);
        this._buttonMaterials[name]!.backFaceCulling = true;
        this._buttonMaterials[name]!.disableLighting = true;
        this._buttonMaterials[name]!.alpha = 0.5;
      });
    }
    return this._buttonMaterials[name];
  }

  getMeshBounds(mesh: AbstractMesh) {
    this.boundingHelper.attachedMesh = mesh;
    return this.boundingHelper.boundingDimensions;
  }

  recountPolygons() {
    this.appService.polygonCountSubject.next(
      this.renderables
        .map((renderable) => renderable.helper?.getChildMeshes(false).concat(renderable.helper))
        .flat()
        .filter((mesh) => mesh && !mesh.metadata?.isOverlay)
        .reduce((total, mesh) => total + (mesh ? mesh.getTotalIndices() : 0), 0) / 3,
    );
  }

  forceElement(element: TElement, source: File | string | Mesh) {
    this.appService.addViewElement(element);
    this.elements.push(element);
    element.type === ElementType.Object && !(source instanceof Mesh) && this.createRenderable(element, true, source);
  }

  async setAvatarAnimations(checkpoint: CheckpointRenderable, mesh: AbstractMesh | Mesh | undefined = undefined) {
    if (!this._avatarAnimationGroups) {
      if (!this._animationAsset) {
        this._animationAsset = await this.appService.apiv2.getAssetById(ANIMATIONS_ASSET_ID);
        if (!this._animationAsset) {
          return;
        }
      }
      this._avatarAnimationGroups = (await this.appService.loadObjectAsset(this._animationAsset))?.animationGroups ?? [];
    }
    if (!(mesh || checkpoint.avatar)) {
      return;
    }
    if (this._avatarAnimationGroups) {
      const nodes = (mesh ?? checkpoint.avatar!).getChildTransformNodes();
      this._avatarAnimationGroups.forEach((group) => {
        group.stop();
        checkpoint.addAnimationGroup(
          group.name,
          group.clone(
            `${group.name}-${uuid()}`,
            (oldTarget) => nodes.find((node) => node.name.split('.').slice(-1)[0] === oldTarget.name) || this.dummyNode,
          ),
        );
      });
    }
  }

  revertBadges() {
    this._badgeLayer?.utilityLayerScene.meshes.forEach((mesh) => {
      mesh.scaling.x *= -1;
    });
  }

  loadXRPolyfill() {
    return new Promise<void>((resolve) => {
      if (navigator.xr) {
        return resolve();
      }
      const WebXRPolyfill = require('webxr-polyfill');
      new WebXRPolyfill();
      resolve();
    });
  }

  disposeXR() {
    this.xrExperience?.dispose();
    this.xrExperience = null;
  }

  dispose() {
    this.scene.onBeforeRenderObservable.remove(this.beforeRenderObserver);
    this.disposeXR();

    for (const plugin of this.plugins.slice(0)) {
      plugin.dispose();
    }

    this.scene.environmentTexture?.dispose();
    this.onTransaction?.clear();
    this.onRollback?.clear();
    this.model && this.dataCache.detach(this.model);
    // this.assetMesh.forEach(f => f?.unsubscribe());
    this.appService.clearAssetMeshMap();
    this.undoStack.length = 0;
    this.redoStack.length = 0;

    this.creatorModeControls?.dispose();
    this._creatorCamera && this.scene.removeCamera(this._creatorCamera);
    this.playerModeControls?.dispose();
    this._playerCamera && this.scene.removeCamera(this._playerCamera);
    this.vrModeControls?.dispose();
    this._vrCamera && this.scene.removeCamera(this._vrCamera);

    this.tools.forEach((tool) => {
      tool.clear();
    });

    this._occlusionMaterial?.dispose();
    this._checkpointMaterial?.dispose();
    this._loadButtonMaterial?.dispose();
    this._durationContainerMaterial?.dispose();
    this._durationBarMaterial?.dispose();
    this._videoControllerMaterial?.dispose();
    this._volumeContainerMaterial?.dispose();
    this._volumeBarMaterial?.dispose();
    this._occlusionMaterial = null;
    this._checkpointMaterial = null;
    this._durationContainerMaterial = null;
    this._durationBarMaterial = null;
    this._videoControllerMaterial = null;
    this._volumeContainerMaterial = null;
    this._volumeBarMaterial = null;

    this._boundingHelper?.dispose();

    Object.entries(this._buttonMaterials).forEach(([key, material]) => {
      material?.dispose();
      this._buttonMaterials[key] = null;
    });

    this.renderables.forEach((renderable) => {
      renderable.dispose();
    });
    this.checkpoints.length = 0;
    this.environments.length = 0;
    this.landscapes.length = 0;
    this.lights.length = 0;
    this.objects.length = 0;
    this.screens.length = 0;
    this.stages.length = 0;
    this.texts.length = 0;

    this.elements.length = 0;
    this.renderables.length = 0;
    this.appService.setViewElements([]);
    this.appService.clearAssetStatusMap();

    this.scene.dispose();
    this.engine.dispose();
  }

  get transactionService(): TransactionService {
    return this.appService.transactionService;
  }

  get playerService(): PlayerService {
    return this.appService.playerService;
  }

  get dataCache(): DataCacheService {
    return this.appService.dataCache;
  }

  get renderables(): TRenderable[] {
    return [
      ...this.checkpoints,
      ...this.environments,
      ...this.landscapes,
      ...this.lights,
      ...this.objects,
      ...this.screens,
      ...this.stages,
      ...this.texts,
    ];
  }

  get interactables(): InteractableRenderable[] {
    return [...this.objects, ...this.texts];
  }

  get creatorCamera() {
    if (!this._creatorCamera) {
      this._creatorCamera = new ArcRotateCamera(
        'CreatorCamera',
        CREATOR_CAMERA_ALPHA,
        CREATOR_CAMERA_BETA,
        CREATOR_CAMERA_RADIUS,
        Vector3.Zero(),
        this.scene,
      );
      ViewManagerUtils.AttachMetadata(this._creatorCamera, { helperOf: this });
      this._creatorCamera.minZ = 0.1;
      this._creatorCamera.maxZ = 1500;
      this._creatorCamera.fov = 1;
      this._creatorCamera.lowerRadiusLimit = 1;
      this._creatorCamera.angularSensibilityX = 1000;
      this._creatorCamera.angularSensibilityY = 1000;
      this._creatorCamera.wheelPrecision = 3;
      this._creatorCamera.speed = 1.5;
      this._creatorCamera.inertia = 0;
      this._creatorCamera.allowUpsideDown = false;
      this._creatorCamera.useBouncingBehavior = false;
      this._creatorCamera.useInputToRestoreState = false;
      this._creatorCamera.zoomToMouseLocation = true;
      this._creatorCamera.inputs.add(new CreatorCameraInput(this._creatorCamera));
      this._creatorCamera.storeState();
    }
    return this._creatorCamera;
  }

  get playerCamera() {
    if (!this._playerCamera) {
      this._playerCamera = new UniversalCamera('PlayerCamera', new Vector3(0, 0.5, -0.5), this.scene);
      ViewManagerUtils.AttachMetadata(this._playerCamera, { helperOf: this });
      this._playerCamera.minZ = 0.1;
      this._playerCamera.maxZ = 875;
      this._playerCamera.fov = 1;
      this._playerCamera.viewport = new Viewport(0, 0, 1, 1);
      this._playerCamera.touchMoveSensibility = 0.1;
      this._playerCamera.speed = 0.75;
      this._playerCamera.inertia = 0.5;
      this._playerCamera.applyGravity = false;
      this._playerCamera.ignoreParentScaling = true;
    }
    return this._playerCamera;
  }

  get vrCamera() {
    return this._vrCamera;
  }

  set vrCamera(camera: WebXRCamera) {
    this._vrCamera = camera;
  }

  get occlusionMaterial() {
    if (!this._occlusionMaterial) {
      this._occlusionMaterial = new StandardMaterial('OcclusionMaterial', this.scene);
      this._occlusionMaterial.alpha = 0.5;
      this._occlusionMaterial.diffuseColor.copyFrom(SELECTION_COLOR);
      this._occlusionMaterial.emissiveColor.copyFrom(SELECTION_COLOR);
      this._occlusionMaterial.backFaceCulling = false;
      this._occlusionMaterial.disableLighting = true;
      this._occlusionMaterial.wireframe = true;
      this._occlusionMaterial.depthFunction = Constants.GREATER;
      this._occlusionMaterial.disableDepthWrite = true;
      this._occlusionMaterial.backFaceCulling = false;
      this._occlusionMaterial.alphaMode = Constants.ALPHA_ADD;
    }
    return this._occlusionMaterial;
  }

  get checkpointMaterial() {
    if (!this._checkpointMaterial) {
      this._checkpointMaterial = new PBRMaterial(`CheckpointMaterial-${this.sceneModel.Id}`, this.scene);
      this._checkpointMaterial.emissiveColor.set(0.8, 0.0328, 0.005);
      this._checkpointMaterial.metallic = 0;
      this._checkpointMaterial.roughness = 0.5;
      this._checkpointMaterial.backFaceCulling = true;
      this._checkpointMaterial.disableLighting = true;
      this._checkpointMaterial.freeze();
    }
    return this._checkpointMaterial;
  }

  get screenMaterial() {
    if (!this._screenMaterial) {
      this._screenMaterial = new StandardMaterial('ScreenMaterial', this.scene);
      this._screenMaterial.backFaceCulling = true;
      this._screenMaterial.alpha = 0.5;
      this._screenMaterial.emissiveColor.set(0.7, 0.7, 0.7);
      this._screenMaterial.diffuseColor.set(0.7, 0.7, 0.7);
      this._screenMaterial.ambientColor.set(0.7, 0.7, 0.7);
    }
    return this._screenMaterial;
  }

  get badgeLayer() {
    if (!this._badgeLayer) {
      this._badgeLayer = new UtilityLayerRenderer(this.scene);
    }
    return this._badgeLayer;
  }

  get badgeScene() {
    return this.badgeLayer?.utilityLayerScene;
  }

  get gizmoLayer() {
    if (!this._gizmoLayer) {
      this._gizmoLayer = new UtilityLayerRenderer(this.scene);
      this._gizmoLayer.setRenderCamera(this.creatorCamera);
    }
    return this._gizmoLayer;
  }

  get gizmoScene() {
    return this.gizmoLayer?.utilityLayerScene;
  }

  get boundingHelper() {
    if (!this._boundingHelper) {
      this._boundingHelper = new BoundingHelper();
    }
    return this._boundingHelper;
  }

  get loadButtonMaterial() {
    if (!this._loadButtonMaterial) {
      this._loadButtonMaterial = new StandardMaterial(uuid(), this.scene);

      this._loadButtonMaterial!.alpha = 1;
      if (this._loadButtonMaterial!.diffuseTexture) {
        this._loadButtonMaterial!.diffuseTexture!.hasAlpha = true;
      }
      if (this._loadButtonMaterial!.useAlphaFromDiffuseTexture) {
        this._loadButtonMaterial!.useAlphaFromDiffuseTexture = false;
      }
      this._loadButtonMaterial!.backFaceCulling = true;

      const texture = new Texture(
        LOAD_BUTTON_PNG,
        this.scene,
        undefined,
        undefined,
        undefined,
        () => {
          this._loadButtonMaterial!.diffuseTexture = texture;
          this._loadButtonMaterial!.opacityTexture = texture;
          this._loadButtonMaterial!.emissiveColor.set(1, 1, 1);
          this._loadButtonMaterial!.specularColor.set(0, 0, 0);
          this._loadButtonMaterial!.backFaceCulling = true;
          this._loadButtonMaterial!.disableLighting = true;
        },
        () => {},
      );
    }
    return this._loadButtonMaterial;
  }

  get durationContainerMaterial() {
    if (!this._durationContainerMaterial) {
      this._durationContainerMaterial = new StandardMaterial(`DurationContainerMaterial-${this.sceneModel.Id}`, this.scene);
      (this._durationContainerMaterial as StandardMaterial).diffuseColor.set(0, 0, 0);
      this._durationContainerMaterial.alpha = 0.7;
      this._durationContainerMaterial.backFaceCulling = true;
      this._durationContainerMaterial.disableLighting = true;
    }
    return this._durationContainerMaterial;
  }

  get durationBarMaterial() {
    if (!this._durationBarMaterial) {
      this._durationBarMaterial = new StandardMaterial(`DurationBarMaterial-${this.sceneModel.Id}`, this.scene);
      (this._durationBarMaterial as StandardMaterial).emissiveColor.set(1, 0, 0.36);
      this._durationBarMaterial.backFaceCulling = true;
      this._durationBarMaterial.disableLighting = true;
    }
    return this._durationBarMaterial;
  }

  get videoControllerMaterial() {
    if (!this._videoControllerMaterial) {
      this._videoControllerMaterial = new StandardMaterial(`VideoControllerMaterial-${this.sceneModel.Id}`, this.scene);
      (this._videoControllerMaterial as StandardMaterial).diffuseColor.set(0, 0, 0);
      this._videoControllerMaterial.alpha = 0.7;
      this._videoControllerMaterial.backFaceCulling = true;
      this._videoControllerMaterial.disableLighting = true;
      this._videoControllerMaterial.needDepthPrePass = true;
    }
    return this._videoControllerMaterial;
  }

  get volumeContainerMaterial() {
    if (!this._volumeContainerMaterial) {
      this._volumeContainerMaterial = new StandardMaterial(`VolumeContainerMaterial-${this.sceneModel.Id}`, this.scene);
      (this._volumeContainerMaterial as StandardMaterial).emissiveColor.set(0.271, 0.298, 0.325);
      this._volumeContainerMaterial.backFaceCulling = true;
      this._volumeContainerMaterial.disableLighting = true;
    }
    return this._volumeContainerMaterial;
  }

  get volumeBarMaterial() {
    if (!this._volumeBarMaterial) {
      this._volumeBarMaterial = new StandardMaterial(`VolumeBarMaterial-${this.sceneModel.Id}`, this.scene);
      (this._volumeBarMaterial as StandardMaterial).emissiveColor.set(0.682, 0.714, 0.749);
      this._volumeBarMaterial.backFaceCulling = true;
      this._volumeBarMaterial.disableLighting = true;
    }
    return this._volumeBarMaterial;
  }

  get textMeasurementContext() {
    if (!this._textMeasurementContext) {
      this._textMeasurementContext = new DynamicTexture('TextMeasurementTexture', 16, this.scene).getContext();
    }
    return this._textMeasurementContext;
  }

  get dummyNode() {
    if (!this._dummyNode) {
      this._dummyNode = new TransformNode(`Dummy-${uuid()}`, this.scene);
    }
    return this._dummyNode;
  }

  get tool() {
    return this.activeTool;
  }
}
