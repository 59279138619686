import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../modal/modal.service';
import { FileType } from '@/data/src/lib/enums/file-type';
import { FileService } from '@/data/src/lib/services/file.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { groupData } from '@/data/src/lib/utils/collection';
import { FileReference } from '@/data/src/lib/models/data/file-reference';
import { QuillEditorMediaService } from './quill-editor-media.service';

@Component({
  selector: 'ui-quill-editor-media',
  templateUrl: './quill-editor-media.component.html',
  styleUrls: ['./quill-editor-media.component.scss'],
})
@UntilDestroy()
export class QuillEditorMediaComponent implements OnInit {
  hasBackdrop = true;
  closeOnBackdropClick = false;
  allowHeaderClick = false;
  zIndex = 5000;
  mediaType: FileType;
  images!: FileReference[];
  videos!: FileReference[];

  constructor(private _quillEditorMediaService: QuillEditorMediaService, private _fileSerivce: FileService) {
    this._fileSerivce.data$.pipe(untilDestroyed(this)).subscribe((res) => {
      const files = groupData(res, 'FileType');
      // TODO:after work shope about datastorage managing of private data
      // this.images = files.get(FileType.Image)?.filter((e) => e.Access === 'Private') ?? [];
      this.images = files.get(FileType.Image) ?? [];
      this.videos = files.get(FileType.Video) ?? [];
      // TODO:after work shope about datastorage managing of private data
      // this.videos = files.get(FileType.Video)?.filter((e) => e.Access === 'Private') ?? [];
    });
  }

  ngOnInit(): void {}

  selectImage(file: FileReference) {
    this._quillEditorMediaService.close(file.Url);
  }

  onContainerClick(event: any) {
    if (this.closeOnBackdropClick && event.target?.classList?.contains('backdrop')) {
      event.preventDefault();
      event.stopPropagation();
      this._quillEditorMediaService.close(false);
    }
  }

  close() {
    this._quillEditorMediaService.close(false);
  }
}
