@if (editMode && guestBook) {
  <div class="mt-4">Please select whether or not to make the guestbook public.</div>
  <div class="mt-7">
    <ui-radio [checked]="guestBook.isPrivate" (checkedChange)="guestBook.isPrivate = true">Private</ui-radio>
  </div>
  <div class="mt-4">
    <ui-radio [checked]="!guestBook.isPrivate" (checkedChange)="guestBook.isPrivate = false">Public</ui-radio>
  </div>
}
@if (!editMode && guestBook) {
  <div class="vertical-scroll">
    <table class="guest-table">
      @for (interaction of interactions; track interaction) {
        <tr>
          <td>{{ interaction.Username }}</td>
          @if (!guestBook.isPrivate) {
            <td>
              {{ interaction.Interaction.Comment }}
            </td>
          }
          @if (guestBook.isPrivate) {
            <td>This is a private post</td>
          }
          <td>{{ interaction.Date | date: 'short' }}</td>
        </tr>
      }
    </table>
  </div>
  <div class="button-group">
    <ui-text-box class="md w-100" [(model)]="newComment" placeholder="Please leave a comment" (keydown)="onKeyDown($event)"> </ui-text-box>
    <button ui-button md (click)="addComment()">Add</button>
  </div>
}
