<div class="popup-content-modal">
  <section class="modal-header">
    <div class="header-title">{{ data['title'] || 'shared.fields.titlePlaceholder' | translate }}</div>
    <div class="close icon-wrapper" (click)="close()">
      <div class="icon icon-close sm"></div>
    </div>
  </section>
  <div class="modal-body">
    <div class="title-section" [style.display]="!isPreviewMode && !isReadonly ? 'flex' : 'none'">
      <div class="title-edit" [style.visibility]="isTitleHidden ? 'hidden' : 'visible'">
        <ui-color-palette
          [type]="'icon'"
          [color]="data['text-colour']"
          (onChange)="setTextColor($event)"
          position="bottom"
          [isScenePlanPropertyRestriction]="scenePlan"
        >
        </ui-color-palette>
        @if (!isPreviewMode && !isReadonly) {
          <input
            uiInput
            fixed
            type="text"
            placeholder="{{ 'shared.fields.titlePlaceholder' | translate }}"
            [(ngModel)]="data['title']"
            [maxlength]="80"
          />
        }
      </div>
      @if (!isReadonly) {
        <textarea
          #textarea
          readonly="true"
          [attr.maxlength]="titleLimit"
          [style.height]="data['title-height'] || '250px'"
          [style.visibility]="isTitleHidden ? 'hidden' : 'visible'"
          [style.minHeight]="TITLE_MIN_HEIGHT"
          [style.backgroundColor]="data['background-colour']"
          [style.paddingTop]="setTextareaPadding(textarea)"
        ></textarea>
      }
      <i class="icon-extend"></i>
      <div class="buttons-container">
        <ui-color-palette
          [type]="'icon'"
          [color]="data['background-colour']"
          (onChange)="setBackgroundColor($event)"
          position="bottom"
          [isScenePlanPropertyRestriction]="scenePlan"
        >
        </ui-color-palette>
        <div class="hide-btn" (click)="setTitleHidden()">
          <div class="icon" [ngClass]="{ 'icon-view': !isTitleHidden, 'icon-hide': isTitleHidden }"></div>
        </div>
      </div>
    </div>
    <div class="title-section view" [style.display]="(isPreviewMode || isReadonly) && !isTitleHidden ? 'flex' : 'none'">
      <div
        class="title-preview"
        [style.backgroundColor]="data['background-colour']"
        [style.height]="data['title-height'] || TITLE_MIN_HEIGHT"
        [attr.maxlength]="titleLimit"
      >
        <span [style.color]="data['text-colour']">{{ data['title'] || '' }}</span>
      </div>
    </div>
    <div class="editor-container" #editorWrapper [style.height]="isPreviewMode || isReadonly ? 'unset' : contentHeight + 'px'">
      @if (!isPreviewMode && !isReadonly) {
        <quill-editor
          #quillEditor
          [ngStyle]="{ display: !isPreviewMode && !isReadonly ? 'inline' : 'block' }"
          class="w-100"
          [ngModel]="data['body']"
          [preserveWhitespace]="true"
          (ngModelChange)="onModelChange($event)"
          (onEditorCreated)="getEditorInstance($event)"
        >
          <ui-quill-editor-custom-toolbar quill-editor-toolbar></ui-quill-editor-custom-toolbar>
        </quill-editor>
      }
      @if (isPreviewMode || isReadonly) {
        <quill-view [preserveWhitespace]="true" class="w-100" [content]="data['body']"> </quill-view>
      }
    </div>
  </div>
  @if (!isReadonly) {
    <section class="footer">
      <div></div>
      <div class="buttons-container">
        @if (!isPreviewMode && !isReadonly) {
          <button ui-button sm secondary (click)="enablePreviewMode(true)">
            {{ 'shared.actions.preview' | translate }}
          </button>
        }
        @if (isPreviewMode && !isReadonly) {
          <button ui-button sm (click)="enablePreviewMode(false)">
            {{ 'shared.actions.edit' | translate }}
          </button>
        }
        @if (!isPreviewMode && !isReadonly) {
          <button ui-button sm (click)="save()">{{ 'shared.actions.save' | translate }}</button>
        }
      </div>
    </section>
  }
</div>
