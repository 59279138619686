import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], term: any | undefined, keys?: string[], take?: number): any {
    if (items) {
      if (term !== undefined && term !== null) {
        return FilterPipe.filter(items, term, keys).slice(0, take);
      } else {
        return items.slice(0, take);
      }
    }
    return undefined;
  }

  static filter(items: any[], term: any, keys?: string[]) {
    if (typeof term === 'string') {
      term = term ? term.toLowerCase() : '';
      const result = term
        ? items.filter((i) => {
            if (keys?.length) {
              let contains = false;
              keys.forEach((key) => {
                if (FilterPipe.contains(i[key], term)) {
                  contains = true;
                }
              });
              return contains;
            } else {
              return FilterPipe.contains(i, term);
            }
          })
        : items;
      return result;
    } else if (term === undefined) {
      return items;
    } else {
      const result = items.filter((i) => {
        if (keys?.length) {
          let equals = false;
          keys.forEach((key) => {
            if (FilterPipe.equals(i[key], term)) {
              equals = true;
            }
          });
          return equals;
        } else {
          return FilterPipe.equals(i, term);
        }
      });
      return result;
    }
  }

  static filterExact(items: any[], term: any, key?: string) {
    if (typeof term === 'string') {
      term = term ? term.toLowerCase() : '';
      let result = term ? items.filter((i) => FilterPipe.equals(key ? i[key] : i, term)) : [];
      return result;
    } else if (Array.isArray(term)) {
      return key ? items.filter((i) => term === i[key]) : items.filter((i) => term === i);
    } else if (term === undefined) {
      return items;
    } else {
      let result = items.filter((i) => FilterPipe.equals(key ? i[key] : i, term));
      return result;
    }
  }

  static equals(item: any, term: any): boolean {
    if (typeof item === 'object') {
      let result = false;
      for (const property in item) {
        result = result || FilterPipe.equals(item[property], term);
      }
      return result;
    } else {
      return item === term;
    }
  }

  static contains(item: any, term: string): boolean {
    if (typeof item === 'object') {
      let result = false;
      for (const property in item) {
        result = result || FilterPipe.contains(item[property], term);
      }
      return result;
    } else {
      return item && item.toString() && item.toString().toLowerCase().includes(term);
    }
  }
}
