import { ApplicationService } from '@/view/src/app/app.service';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'ui-panels-menu',
  templateUrl: './panels-menu.component.html',
  styleUrls: ['./panels-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanelsMenuComponent {
  @HostBinding('class.opened') isBarOpened = false;

  @Input() canOpen = true;

  constructor(private _appService: ApplicationService) {
    this._appService.openPanelsMenu$.subscribe((isOpen) => {
      this.isBarOpened = isOpen;
    });
  }

  onOpenClose() {
    this.isBarOpened = !this.isBarOpened;
    !this.isBarOpened && this._appService.focusOnCanvas();
  }
}
