<main>
  @if (!isSso) {
    <button ui-button fixed (click)="changePassword()">
      {{ 'header.accountPanel.securitySettings.changePassword' | translate }}
    </button>
  }
  <button ui-outlined-button fixed class="outline" (click)="navigateToWithdrawal()">
    {{ 'header.accountPanel.securitySettings.withdrawal.title' | translate }}
  </button>
</main>
