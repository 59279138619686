@if (showDialog) {
  <div class="collaborator-modal-wrapper">
    <div class="collaborator-modal">
      <div class="header">
        {{ 'oxr.creatingSpace.panels.info.title' | translate }}
      </div>
      <div class="body">
        @if (!isLoading) {
          <span
            [innerHTML]="
              'collaborator.invitationMessage'
                | translate
                  : {
                      userName: invitation.Sender.DisplayName,
                      role: getRoleName(invitation.Role),
                      enterpriseName: invitation.EnterpriseContract.Enterprise.Name
                    }
            "
          ></span>
        } @else {
          <ui-loader></ui-loader>
        }
      </div>
      <div class="footer">
        <button ui-button primary class="action-button" (click)="updateInvitationStatus(true)" [disabled]="isLoading">
          {{ 'shared.actions.accept' | translate }}
        </button>
        <button ui-outlined-button primary outline class="action-button" (click)="updateInvitationStatus(false)" [disabled]="isLoading">
          {{ 'shared.actions.decline' | translate }}
        </button>
      </div>
    </div>
  </div>
}
