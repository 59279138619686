import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { v4 } from 'uuid';

@Component({
  selector: 'ui-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
})
export class RadioGroupComponent implements OnInit {
  _value: any;

  @Input() options: Array<{ label: string; value: any; disabled?: boolean }> = [];
  @Input() label: string;
  @Input() group: string = v4();
  @Input() set value(val) {
    this._value = val;
    this._cd.detectChanges();
  }

  @Output() onChecked = new EventEmitter();

  constructor(private _cd: ChangeDetectorRef) {}

  ngOnInit(): void {}

  onChange(value) {
    this.onChecked.emit(value);
  }

  trackByFn(item, index) {
    return item.value;
  }
}
