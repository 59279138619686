import { _Exporter } from "../glTFExporter.js";
import { PBRMaterial } from "@babylonjs/core/Materials/PBR/pbrMaterial.js";
const NAME = "KHR_materials_dispersion";
/**
 * [Specification](https://github.com/KhronosGroup/glTF/blob/87bd64a7f5e23c84b6aef2e6082069583ed0ddb4/extensions/2.0/Khronos/KHR_materials_dispersion/README.md)
 * @experimental
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class KHR_materials_dispersion {
  /** Constructor */
  constructor() {
    /** Name of this extension */
    this.name = NAME;
    /** Defines whether this extension is enabled */
    this.enabled = true;
    /** Defines whether this extension is required */
    this.required = false;
    this._wasUsed = false;
  }
  /** */
  dispose() {}
  /** @internal */
  get wasUsed() {
    return this._wasUsed;
  }
  _isExtensionEnabled(mat) {
    // This extension must not be used on a material that also uses KHR_materials_unlit
    if (mat.unlit) {
      return false;
    }
    const subs = mat.subSurface;
    // this extension requires refraction to be enabled.
    if (!subs.isRefractionEnabled && !subs.isDispersionEnabled) {
      return false;
    }
    return true;
  }
  postExportMaterialAsync(context, node, babylonMaterial) {
    return new Promise(resolve => {
      if (babylonMaterial instanceof PBRMaterial && this._isExtensionEnabled(babylonMaterial)) {
        this._wasUsed = true;
        const subs = babylonMaterial.subSurface;
        const dispersion = subs.dispersion;
        const dispersionInfo = {
          dispersion: dispersion
        };
        node.extensions = node.extensions || {};
        node.extensions[NAME] = dispersionInfo;
      }
      resolve(node);
    });
  }
}
_Exporter.RegisterExtension(NAME, () => new KHR_materials_dispersion());
