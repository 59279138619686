import { ChangeDetectorRef } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';

export function createUsernameExistsValidator(accountPanelService: any, cdRef?: ChangeDetectorRef): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> => {
    return accountPanelService.isUsernameAvailable(control.value).then((result) => {
      cdRef?.markForCheck();
      return result ? null : { usernameExists: true };
    });
  };
}
