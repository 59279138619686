import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AccountPanelService } from '../../account-panel.service';

@Component({
  selector: 'ui-account-panel-details-wrapper',
  templateUrl: './account-panel-details-wrapper.component.html',
  styleUrls: ['./account-panel-details-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountPanelDetailsWrapperComponent {
  constructor(private _accountPanelService: AccountPanelService) {}

  onBack(): void {
    this._accountPanelService.closeDetails();
  }

  onClose(): void {
    this._accountPanelService.closePanel();
  }
}
