<div class="container">
  <section class="header">
    <div class="icon-wrapper" (click)="onBack()">
      <div class="icon icon-back xs"></div>
    </div>
    <div class="text"><ng-content select="[header]"></ng-content></div>
    <div class="icon-wrapper" (click)="onClose()">
      <div class="icon icon-close xs"></div>
    </div>
  </section>
  <section class="main">
    <ng-content></ng-content>
  </section>
</div>
