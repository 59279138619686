import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../modal.service';

@Component({
  selector: 'ui-guide-modal',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuideModalComponent implements OnInit {
  title = this._translateService.instant('guideModal.title');
  checkboxMessage = this._translateService.instant('guideModal.dontShowAgain');
  actionMessage = this._translateService.instant('guideModal.confirm');
  message = '';
  showCheckbox = true;

  isChecked = false;

  constructor(private modalRef: ModalService, private _translateService: TranslateService) {}

  ngOnInit(): void {}

  toggleSelection(value: boolean) {
    this.isChecked = value;
  }

  close(value = false) {
    this.modalRef.close(value);
  }
}
