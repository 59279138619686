<div class="container" [class.media]="isMedia">
  @if (showSearch) {
    <ui-search sm [canClear]="true" [(search)]="searchText"></ui-search>
  }
  @if (sort) {
    <div class="card-list-sorting-wrapper">
      <div class="total-items" [innerHTML]="'shared.list.inTotal' | translate: { total: numberOfItems }"></div>
      <div>
        <ui-search-filter>
          <ng-select
            class="small"
            [searchable]="false"
            [clearable]="false"
            placeholder="{{ 'shared.list.orderBy.placeholder' | translate }}"
            [ngModel]="orderBy"
            (ngModelChange)="changeOrderBy($event)"
          >
            @for (option of orderingOptions; track option) {
              <ng-option [value]="option">
                <div class="select-option-wrapper">
                  {{ 'shared.list.orderBy.' + option.name | translate }}
                </div>
              </ng-option>
            }
          </ng-select>
        </ui-search-filter>
      </div>
    </div>
  }
  <div #cardList class="card-list" [class.lights]="isLight" (scroll)="updateListIndices()">
    @if (showSelectNone) {
      <div class="card none" (click)="setNone()">
        <div class="sm icon" [ngClass]="showInitialize ? 'icon-refresh' : 'icon-close-circle'"></div>
        {{ (showInitialize ? 'sidenav.card.initialize' : 'sidenav.card.none') | translate }}
      </div>
    }
    @if (showAddNew) {
      <div class="card new" [class.disabled]="!allowAdd" (click)="addNew()">
        @if (!isMedia) {
          <div class="icon icon-purchase-box sm"></div>
          @if (allowAdd) {
            <div>{{ 'shared.actions.purchaseMore' | translate }}</div>
          }
        } @else {
          <div class="icon sm" [ngClass]="isImages ? 'icon-image' : 'icon-video'"></div>
          @if (allowAdd) {
            <div>{{ (isImages ? 'shared.actions.addPhoto' : 'shared.actions.addVideo') | translate }}</div>
          }
        }
        <ng-template #media>
          <div class="icon sm" [ngClass]="isImages ? 'icon-image' : 'icon-video'"></div>
          @if (allowAdd) {
            <div>{{ (isImages ? 'shared.actions.addPhoto' : 'shared.actions.addVideo') | translate }}</div>
          }
        </ng-template>
        @if (!allowAdd) {
          <div class="icon icon-union sm"></div>
        }
        @if (!allowAdd) {
          <div class="coming-soon">{{ 'shared.fields.comingSoon' | translate }}</div>
        }
      </div>
    }
    @for (
      item of sort ? (items | filter: (searchText | removeComma | removeHash) : filterByFields | orderByImpure: orderBy.value) : items;
      track item;
      let i = $index
    ) {
      <div class="card-wrapper" #myVar>
        <div
          class="card"
          [ngClass]="{
            disabled: isImporting || item.disabled || disableSelection,
            selected: !!selectedMap?.get(item.Id ?? item.Name)
          }"
          [title]="item.Name"
          (mouseenter)="hoveredCard = item"
          (mouseleave)="hoveredCard = null"
          (click)="item.disabled ? null : toggleSelection(item)"
        >
          @if (!isLight) {
            @if (item.Owner && ($account | async) && item.Owner.Id === ($account | async)?.Id) {
              <div class="owner-avatar">
                @if (item?.Owner?.Thumbnail) {
                  <img [src]="item.Owner.Thumbnail" />
                } @else {
                  <i class="icon icon-account"></i>
                }
              </div>
            }
            <div class="item-wrapper">
              @if (item.disabled) {
                <div class="icon icon-union sm"></div>
              }
              @if (item.disabled) {
                <div class="coming-soon">{{ 'shared.fields.comingSoon' | translate }}</div>
              }
              <div class="text">
                @if (i > endIndex) {
                } @else {
                  @if (item.Thumbnail || isMedia) {
                    <img [src]="item.Thumbnail || SOUND_ONLY_PNG" />
                  }
                }
              </div>
              <div class="name" [class.hidden]="hoveredCard?.Id !== item.Id && !searchText">
                <div
                  #animated
                  [innerHTML]="item.Name | uiHighlight: searchText"
                  [uiTextFlowAnimation]="item.Name.length > 13"
                  [contentLength]="item.Name.length"
                ></div>
              </div>
            </div>
          } @else {
            <div class="lights">
              @if (item.Name !== 'lights.removeAll') {
                <div class="icon-wrapper">
                  <div class="icon" [ngClass]="item.Icon"></div>
                </div>
                <div class="text">{{ 'oxr.creatingSpace.' + item.Name | translate }}</div>
                <div class="text">({{ item.Count }} {{ 'oxr.subscription.ea' | translate }})</div>
              }
              @if (item.Name == 'lights.removeAll') {
                <div class="text">{{ 'oxr.creatingSpace.' + item.Name | translate }}</div>
                <div class="text">({{ item.Count }}/{{ maxLights }})</div>
              }
            </div>
          }
          @if (canCheckMultiple) {
            <div
              class="checkbox"
              (click)="checkboxClick($event)"
              [style.display]="!isItemChecked(item) && hoveredCard?.Id !== item.Id ? 'none' : 'flex'"
            >
              <ui-check-box class="xs primary" [checked]="isItemChecked(item)" (checkedChange)="onItemCheck($event, item)"></ui-check-box>
            </div>
          }
          @if (canDelete) {
            <div
              class="delete"
              [style.display]="!isItemChecked(item) && hoveredCard?.Id !== item.Id ? 'none' : 'flex'"
              (click)="onDelete($event, item)"
            >
              <div class="icon icon-delete"></div>
            </div>
          }
          @if (!!progressMap[item.AssetId] || !!progressMap[item.Name]) {
            <div class="progress">
              <div class="loading-bar" [style.width.%]="progressMap[item.AssetId] || progressMap[item.Name] || 0"></div>
            </div>
          }
        </div>
        @if (searchText && getTags(item).length) {
          <div class="tags-container">
            @for (tag of getTags(item); track tag) {
              @if (isIncludeSearch(tag) || showMoreTags.includes(item.Id)) {
                <div class="tag" [innerHTML]="tag | uiHighlight: searchText"></div>
              }
            }
            <div
              class="show-more-tags icon icon-arrow-down"
              [class.expanded]="showMoreTags.includes(item.Id)"
              (click)="toggleShowMore(item.Id)"
            ></div>
          </div>
        }
      </div>
    }
  </div>
</div>
<div class="bottom-section">
  <ng-content></ng-content>
</div>
