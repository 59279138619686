import { Directive, ElementRef, NgZone, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { interval, take } from 'rxjs';
@UntilDestroy()
@Directive({
  selector: '[uiAutofocus]',
})
export class AutofocusDirective implements OnInit {
  constructor(
    private host: ElementRef,
    private _ngZone: NgZone,
  ) {}

  ngOnInit() {
    this._ngZone.runOutsideAngular(() => {
      interval(0)
        .pipe(take(1), untilDestroyed(this))
        .subscribe(() => {
          this.host.nativeElement.focus();
          this._ngZone.run(() => {});
        });
    });
  }
}
