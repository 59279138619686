<div (uiClickOutside)="hide()">
  <input type="text" [placeholder]="placeholder" readonly (click)="expand()" [(ngModel)]="selection" />
  <div class="icon icon-arrow-down"></div>
  <div #itemList class="item-list">
    @for (item of dropDownData; track item) {
      <div class="item" (click)="setSelection($event, item)">
        {{ item?.name ?? item }}
      </div>
    }
  </div>
</div>
