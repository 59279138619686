import { AfterContentInit, Directive, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';

@Directive({
  selector: '[uiOverflowReadmore]',
})
export class OverflowReadmoreDirective implements AfterContentInit, OnDestroy {
  seeMoreButton: HTMLSpanElement | null = null;
  parentElement: HTMLElement | null;
  portrait: MediaQueryList | null = null;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Inject(WINDOW) private readonly _window: Window,
  ) {}

  reset() {
    this.el.nativeElement.classList.remove('text-overflow-wrap');
    this.el.nativeElement.classList.remove('text-overflow-hidden');
    if (this.seeMoreButton) {
      this.seeMoreButton.remove();
    }
  }

  checkReadmore() {
    const parentElement = this.el.nativeElement.parentElement;
    if (parentElement) {
      this.parentElement = parentElement;
      const parentWidth = parentElement.getBoundingClientRect().width || 0;
      const currentElWidth = this.el.nativeElement.offsetWidth;
      if (currentElWidth > parentWidth) {
        // Add Text overflow hidden class
        this.el.nativeElement.classList.add('text-overflow-hidden');
        // Create see button element
        this.seeMoreButton = this.renderer.createElement('span');
        if (this.seeMoreButton) {
          // Add See more button
          this.seeMoreButton.classList.add('see-more-text');
          this.seeMoreButton.onclick = () => {
            this.el.nativeElement.classList.remove('text-overflow-hidden');
            if (!this.el.nativeElement.classList.contains('text-overflow-wrap')) {
              this.el.nativeElement.classList.add('text-overflow-wrap');
            }
            if (this.seeMoreButton) {
              this.seeMoreButton.remove();
              this.seeMoreButton = null;
            }
          };
          this.renderer.appendChild(parentElement, this.seeMoreButton);
        }
      }
    }
  }

  onOrientationChange() {
    this.reset();
    this.checkReadmore();
  }
  ngAfterContentInit() {
    this.portrait = this._window.matchMedia('(orientation: portrait)');

    this.portrait.addEventListener('change', this.onOrientationChange.bind(this));
    this.checkReadmore();
  }

  ngOnDestroy(): void {
    if (this.parentElement) {
      const el = this.parentElement.querySelector('.see-more-text');
      el && el.remove();
      this.el.nativeElement.classList.remove('text-overflow-hidden');
      this.el.nativeElement.style.textWrap = 'unset';
    }
    if (!!this.portrait) {
      this.portrait.removeEventListener('change', this.onOrientationChange);
    }
  }
}
