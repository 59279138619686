import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountSetting } from '../models/data/account';
import { ModelType } from '../models/data/base';
import { BaseApiService } from './base-api.service';
import { AccountService } from './account.service';

/**
 * Tracks and manages the state of user setting models and commits changes to the backend
 */
@Injectable({
  providedIn: 'root',
})
export class UserSettingsService extends BaseApiService<AccountSetting> {
  constructor(
    public http: HttpClient,
    accountService: AccountService,
  ) {
    super(http, accountService, ModelType.UserSetting);
  }

  /**
   * Returns first found setting by name, if not found - creates new setting with a default value
   * @param name: string
   * @param defaultValue: string, default ''
   * @param cached: boolean - Look in cached, default true
   */
  async getSettingByName(name: string, defaultValue = '', cached = true): Promise<AccountSetting> {
    return await this.getAll(cached).then((data: AccountSetting[]) => {
      let setting = data.find((setting: AccountSetting) => setting.Name === name);
      if (!setting) {
        setting = AccountSetting.create(this.accountService.getAccountId(), name, defaultValue);
        this.add(setting);
        this.commit();
      }
      return setting;
    });
  }
}
